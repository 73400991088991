@import "~core/components/breadcrumb";

// Selectively apply padding to page-designer component driven breadcrumbs
.breadcrumb-wrapper {
    @include media-breakpoint-down(sm) {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
    @include media-breakpoint-up(md) {
        padding-top: $spacer * 4;
        padding-bottom: $spacer * 2;
    }
    // PLP Override
    .plp & {
        padding-top: $spacer * 2;
        padding-bottom: 0;
    }
}

.breadcrumb {
    flex-wrap: wrap;
    
    .breadcrumb-item {
        align-items: center;
        
        a,
        h2 {
            font-family: $primary-font;
            line-height: $breadcrumb-line-height;
            font-style: normal;
            font-size: inherit;
            font-weight: inherit;
            letter-spacing: inherit;
            margin: 0;
        }

        &.breadcrumb-product h2 {
            font-family: $primary-font-bold;
        }

        .no-link-breadcrumb h2 {
            opacity: .5;
        }
    }

    .breadcrumb-item + .breadcrumb-item:before {
        padding: 0 4px;
        content: '>';
    }

    .breadcrumb-item:not(.breadcrumb-product) > * {
        text-decoration: underline;
    }
}
