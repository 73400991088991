@import "~core/product/grid";

// Search tabs
.search-nav {
    border-bottom: 1px solid $grey-30;
}

.product-grid {
    .product-tile-full-width {
        .experience-region {
            @include media-breakpoint-down(md) {
                max-width: 100%;

                .hero {
                    min-height: 398px;
                }
            }
        }
    }
}

.search-results {
    .product-grid {
        @include media-breakpoint-down(md) {
            margin-left: -30px;
            margin-right: -30px;
        }
    }

    .grid-header {
        @include media-breakpoint-down(md) {
            border-top: 1px solid $grey-20;
            border-bottom: 1px solid $grey-20;
            margin-left: 0px;
            margin-right: 0px;
        }
        .grid-header-filter-group {
            border-top: 1px solid $grey-20;
            border-bottom: 1px solid $grey-20;

            .grid-header-filter {
                border-right: 1px solid $grey-20;
            }
        }

        .filter-results {
            [data-asset-id="FilterIconSvg"] {
                display: inline-block;
                transform: translateY(-2px);
            }
        }

        .filter-sort-container {
            @include media-breakpoint-down(md) {
                flex-basis: 100%;
                max-width: 100%;
                padding-left: 0px;
                padding-right: 0px;
            }
        }
        .filter-sort-button {
            font-size: 12px;
            font-weight: 500;
            border: 1px solid $grey-20;
            border-radius: 50px;
            padding: 0 20px;

            svg {
                height: 18px;
            }
            @include media-breakpoint-down(md) {
                width: 100%;
                background: $black;
                color: $white;
                padding: 6px;
                margin-top: $spacer-xl;
                margin-bottom: $spacer-xl;
                font-size: 14px;
                svg {
                    display: none;
                }
            }
        }
    }

    .result-count {
        font-size: 12px;
        line-height: 1;
        color: $grey-30;
    }

    .grid-header-filter-btn {
        border: none;
        font-family: $secondary-font;
        font-size: 16px;
        line-height: 40px;
        color: $grey-30;
        text-transform: initial;
        border-radius: 0;
        display: flex;
        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
    }

    .grid-header-filter-btn,
    .custom-select[name="sort-order"] {
        position: relative;
        @include media-breakpoint-up(lg) {
            border: $select-border-secondary;
        }

        @include icon($icon-arrow-down, after, 10px, $grey-30);
        &::after {
            right: 0;
            padding-right: 20px;
            position: absolute;
        }
    }

    .grid-header-filter-btn {
        background-color: transparent;

        &:open {
            transform: rotate(180deg);
            @include icon($icon-arrow-up, after, 10px, $grey-30);
        }
    }

    .category-seocontent {
        margin-top: 40px;
    }

    // PLP Desktop Sort Button
    .sort-order-menu .custom-select {
        @include paragraph-font-size(large);
        padding: 10px 35px 12px 20px;
    }
}

.refinement-bar {
    @include media-breakpoint-down(md) {
        .row [class*="col-"] & {
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }

        .refinements-container {
            .card-title {
                font-family: $secondary-font;
                font-size: 16px;
                font-weight: 500;
            }

            .card-body {
                padding: 0;
            }
        }
    }

    .desktop-search-refinements {
        padding-right: 42px;
    }

    .header-bar-title {
        padding: 24px 0;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;
        z-index: 301;
        background-color: $white;

        .h3 {
            margin-bottom: 0;
        }

        &::after {
            content: '';
            display: inline-block;
            width: calc(100% + 48px);
            height: 1px;
            background-color: $grey-20;
            position: absolute;
            bottom: 0;
            left: -$spacer * 2;
        }
    }

    .header-bar {
        background-color: $white;
        color: $black;
    }

    .secondary-bar {
        .secondary-bar-links {
            font-size: $card-body-font-size;
        }
    }

    .search-result-count {
        margin-bottom: $spacer;
    }

    .card.aside {
        border: none;
    }

    button {
        color: $black;
    }
}

.filter-limit-more {
    color: $grey-30;
    cursor: pointer;

    .refinement-color & {
        flex-basis: 100%;
    }
}

.filter-sort-button {
    color: $black;
}

.filter-apply-message {
    transition: 0.25s;
    position: fixed;
    bottom: 0;
    background-color: $success;
    z-index: 301;
    left: 0;
    right: 0;
    text-align: center;
    color: $white;
    padding: 20px;
    font-size: 16px;
    font-weight: 700;

    span.icon-checkmark {
        background-color: $white;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 20px;
    }
}

.sticky {
    position: -webkit-sticky;
	position: sticky;
	top: 0;
    overflow-y: scroll;
    height: 100vh;
    padding-right: $spacer / 2;
}

#desktop-vertical-search-refinements {
    .refinements {
        .refinement-categories {
            .values.content {
                display: block;
            }
        }
    }
}

.mobile-search-refinements {
    .refinements {
        .mobile-sort-body {
            line-height: $spacer * 2;
        }
    }
}
.category-refinement-item {
    margin-left: $spacer;
}
.refinement-category-scroll {
    padding-bottom: $spacer-xl;
    position: relative;
    @include media-breakpoint-up(md) {
        padding-right: 150px;
        padding-top: 10px;
        overflow: hidden;
    }

    &:not(.swiper-container-initialized ) {
        .controls {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }
        }
    }

    .category-scroll-values {
        list-style: none;
        display: flex;
        padding-left: 0;

        .category-slide-link {
            @include paragraph-font-size(large);
            width: 100%;

            .selected {
                font-weight: 700;
            }
        }

        a {
            text-decoration: none;

            span {
                white-space: nowrap;
            }
        }

        .category-slide-link:hover {
            span:first-child {
                position: relative;
                &::after {
                    content: '';
                    width: 100%;
                    height: 0;
                    border-bottom: 1px solid $black;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                }
            }
        }

        .selected {
            position: relative;
            &::after {
                content: '';
                width: 100%;
                height: 0;
                border-bottom: 1px solid $black;
                position: absolute;
                left: 0;
                bottom: 0;
            }
        }
    }

    .controls {
        position: absolute;
        top: 0;
        right: 0;
        display: none;
        @include media-breakpoint-up(md) {
            display: flex;
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            background-color: $white;
            border: 1px solid $black;
            display: flex;
            text-align: center;
            transition: background-color 200ms ease-out;
            font-size: 0;
            padding: 0;
            user-select: none;
            z-index: 1;
            &.swiper-button-disabled {
                border: 1px solid $grey-20;
                &::before {
                    color: $grey-20;
                }
            }
            &:not(.swiper-button-disabled):hover {
                border: $slider-light-button-border-hover;
                background-color: $yellow-green;
                &::before {
                    color: $white;
                }
            }
            &::before {
                width: 100%;
                align-self: center;
                transition: color 200ms ease-out;
            }
        }

        .swiper-button-prev {
            @include icon($slider-button-prev-icon, before, $slider-button-icon-size, $black);
        }

        .swiper-button-next {
            @include icon($slider-button-next-icon, before, $slider-button-icon-size, $black);
            margin-left: 20px;
        }
    }
}

.filter-header {
    margin-left: 0;
    margin-right: 0;

    .result-count {
        color: $grey-30;
    }
}

.sticky-filters { 
    @include media-breakpoint-down(md) {
        left: 0;
    }
}