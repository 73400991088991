.help-center {
    .form-control,
    textarea {
        border-radius: $input-border-radius;
    }

    @include media-breakpoint-down(md) {
        .action,
        .purchase-date {
            margin-top: 24px;
        }
    }

    .store-locator-form {
        @include media-breakpoint-up(lg) {
            border-top: 1px solid $grey-20;
        }

        button {
            font-family: $tertiary-font;
            text-transform: none;
            letter-spacing: normal;
            font-weight: 500;
        }
    }

    .input-hidden {
        height: 0;
        width: 0;
        visibility: hidden;
        padding: 0;
        margin: 0;
        border: none;
    }

    .is-invalid {
        border-color: $red!important;
        border-width: 2px!important;
    }
}
