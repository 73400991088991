@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap");
@import url(~video.js/dist/video-js.min.css);
@font-face {
  font-family: archia-regular;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/archia-regular.eot"), url("../fonts/archia-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/archia-regular.woff2") format("woff2"), url("../fonts/archia-regular.woff") format("woff"), url("../fonts/archia-regular.ttf") format("truetype"), url("../fonts/archia-regular.svg#archia-regular") format("svg"); }

@font-face {
  font-family: archia-medium;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/archia-medium.eot"), url("../fonts/archia-medium.eot?#iefix") format("embedded-opentype"), url("../fonts/archia-medium.woff2") format("woff2"), url("../fonts/archia-medium.woff") format("woff"), url("../fonts/archia-medium.ttf") format("truetype"), url("../fonts/archia-medium.svg#archia-medium") format("svg"); }

@font-face {
  font-family: archia-bold;
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/archia-bold.eot"), url("../fonts/archia-bold.eot?#iefix") format("embedded-opentype"), url("../fonts/archia-bold.woff2") format("woff2"), url("../fonts/archia-bold.woff") format("woff"), url("../fonts/archia-bold.ttf") format("truetype"), url("../fonts/archia-bold.svg#archia-bold") format("svg"); }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9bo02z");
  src: url("../fonts/icomoon.eot?9bo02z#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9bo02z") format("truetype"), url("../fonts/icomoon.woff?9bo02z") format("woff"), url("../fonts/icomoon.svg?9bo02z#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-nav:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-box:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-cog:before {
  content: ""; }

.icon-drop:before {
  content: ""; }

.icon-key:before {
  content: ""; }

.icon-location-pin:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-magnifing-glass:before {
  content: ""; }

.icon-ruler:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-truck:before {
  content: ""; }

.icon-notification:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-check-circle:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-tiktok:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-play:before {
  content: ""; }

/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BUTTONS
*/
/*
* FORMs
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* TABS - PDP
*/
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
/*
* PRELOADER
*/
.styleguide .main__section > .row {
  background: #FFF; }
  .styleguide .main__section > .row .brand-logo {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo.svg); }
  .styleguide .main__section > .row .brand-logo-stacked {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo-stacked.svg); }
  .styleguide .main__section > .row .brand-logo-horizontal {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo-horizontal.svg); }
  .styleguide .main__section > .row .brand-logo-vertical {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo-vertical.svg); }
  .styleguide .main__section > .row .brand-logo-small {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo-small.svg); }
  .styleguide .main__section > .row .brand-logo-type {
    height: 200px;
    max-width: 200px;
    background-image: url(../images/logo-type.svg); }
  .styleguide .main__section > .row .sg__header1, .styleguide .main__section > .row .sg__header2, .styleguide .main__section > .row .sg__header3, .styleguide .main__section > .row .sg__header4 {
    color: rgba(0, 0, 0, 0.75); }
  .styleguide .main__section > .row .font--primary::after {
    content: "archia-medium, sans-serif"; }
  .styleguide .main__section > .row .font--secondary::after {
    content: "Roboto, sans-serif"; }
  .styleguide .main__section > .row .font--tertiary::after {
    content: "Roboto, sans-serif"; }
  .styleguide .main__section > .row .color-value.background--brand-01::after {
    content: "#0C0A08"; }
  .styleguide .main__section > .row .color-value.background--brand-02::after {
    content: "#40403F"; }
  .styleguide .main__section > .row .color-value.background--brand-03::after {
    content: "#99FF00"; }
  .styleguide .main__section > .row .color-value.background--secondary-01::after {
    content: "#ECEEE4"; }
  .styleguide .main__section > .row .color-value.background--secondary-02::after {
    content: "#D8DDC9"; }
  .styleguide .main__section > .row .color-value.background--secondary-03::after {
    content: "#B9BFA7"; }
  .styleguide .main__section > .row .color-value.background--white::after {
    content: "#FFF"; }
  .styleguide .main__section > .row .color-value.background--grey-10::after {
    content: "#F8F8F8"; }
  .styleguide .main__section > .row .color-value.background--grey-20::after {
    content: "#D6D6D6"; }
  .styleguide .main__section > .row .color-value.background--grey-30::after {
    content: "#595959"; }
  .styleguide .main__section > .row .color-value.background--grey-40::after {
    content: "#2B2B2A"; }
  .styleguide .main__section > .row .color-value.background--black::after {
    content: "#000"; }
  .styleguide .main__section > .row .color-value.background--green::after {
    content: "#00865A"; }
  .styleguide .main__section > .row .color-value.background--yellow::after {
    content: "#FC0"; }
  .styleguide .main__section > .row .color-value.background--red::after {
    content: "#BF242B"; }
  .styleguide .main__section > .row .color-value.background--blue::after {
    content: "#3091E7"; }
  .styleguide .main__section > .row .color-value.background--success::after {
    content: "#00865A"; }
  .styleguide .main__section > .row .color-value.background--warning::after {
    content: "#FC0"; }
  .styleguide .main__section > .row .color-value.background--danger::after {
    content: "#BF242B"; }
  .styleguide .main__section > .row .color-value.background--info::after {
    content: "#3091E7"; }
  .styleguide .main__section > .row .color-value.background--brand-primary::after {
    content: "#0C0A08"; }
  .styleguide .main__section > .row .color-value.background--brand-secondary::after {
    content: "#2B2B2A"; }
  .styleguide .main__section > .row .color-value.background--brand-tertiary::after {
    content: "#40403F"; }
  .styleguide .main__section > .row .color-value.background--brand-goat-green::after {
    content: "#99FF00"; }
  .styleguide .main__section > .row .color-value.background--off-white::after {
    content: "#F8F8F8"; }
  .styleguide .main__section > .row .color-value.background--light-gray::after {
    content: "#D6D6D6"; }
  .styleguide .main__section > .row .color-value.background--dark-gray::after {
    content: "#595959"; }
  .styleguide .main__section > .row .color-value.background--scuff01::after {
    content: "#ECEEE4"; }
  .styleguide .main__section > .row .color-value.background--scuff02::after {
    content: "#D8DDC9"; }
  .styleguide .main__section > .row .color-value.background--scuff03::after {
    content: "#B9BFA7"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-xs::after {
    content: "0 - 768px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-md::after {
    content: "769px - 1023px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-lg::after {
    content: "1024px - 1439px"; }
  .styleguide .main__section > .row .grid-columns__breakpoint-xl::after {
    content: "1440px +"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xs::after {
    content: "24px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-md::after {
    content: "24px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-lg::after {
    content: "24px"; }
  .styleguide .main__section > .row .grid-columns__gutter-size-xl::after {
    content: "48px"; }
  .styleguide .main__section > .row .grid-columns__xs {
    padding-right: 6px;
    padding-left: 6px; }
    .styleguide .main__section > .row .grid-columns__xs .row {
      margin-right: -6px;
      margin-left: -6px; }
    .styleguide .main__section > .row .grid-columns__xs .col {
      padding-right: 6px;
      padding-left: 6px; }
  .styleguide .main__section > .row .grid-columns__md {
    padding-right: 6px;
    padding-left: 6px; }
    .styleguide .main__section > .row .grid-columns__md .row {
      margin-right: -6px;
      margin-left: -6px; }
    .styleguide .main__section > .row .grid-columns__md .col {
      padding-right: 6px;
      padding-left: 6px; }
  .styleguide .main__section > .row .grid-columns__xl {
    padding-right: 12px;
    padding-left: 12px; }
    .styleguide .main__section > .row .grid-columns__xl .row {
      margin-right: -12px;
      margin-left: -12px; }
    .styleguide .main__section > .row .grid-columns__xl .col {
      padding-right: 12px;
      padding-left: 12px; }

.styleguide .main .section--typography .bp--mobile::after {
  content: "0 - 768px"; }

.styleguide .main .section--typography .bp--tablet::after {
  content: "769px - 1023px"; }

.styleguide .main .section--typography .bp--mobile-tablet::after {
  content: "0 - 1023px"; }

.styleguide .main .section--typography .bp--desktop::after {
  content: "1024px +"; }

.styleguide .main .section--typography .h1--mobile .h1 {
  font-size: 35px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h1--desktop .h1 {
  font-size: 42px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--mobile .h2 {
  font-size: 29px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h2--desktop .h2 {
  font-size: 35px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--mobile .h3 {
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h3--desktop .h3 {
  font-size: 29px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--mobile .h4 {
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h4--desktop .h4 {
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--mobile .h5 {
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h5--desktop .h5 {
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--mobile .h6 {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .h6--desktop .h6 {
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }

.styleguide .main .section--typography .p--mobile .p--small {
  font-size: 12px;
  line-height: 1.5em; }

.styleguide .main .section--typography .p--mobile .p {
  font-size: 14px;
  line-height: 1.5em; }

.styleguide .main .section--typography .p--mobile .p--large {
  font-size: 16px;
  line-height: 1.5em; }

.styleguide .main .section--typography .p--desktop .p--small {
  font-size: 12px;
  line-height: 1.5em; }

.styleguide .main .section--typography .p--desktop .p {
  font-size: 14px;
  line-height: 1.5em; }

.styleguide .main .section--typography .p--desktop .p--large {
  font-size: 16px;
  line-height: 1.5em; }

:root {
  --blue: #3091E7;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #BF242B;
  --orange: #fd7e14;
  --yellow: #FC0;
  --green: #00865A;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #FFF;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --brand-01: #0C0A08;
  --brand-02: #40403F;
  --brand-03: #99FF00;
  --secondary-01: #ECEEE4;
  --secondary-02: #D8DDC9;
  --secondary-03: #B9BFA7;
  --white: #FFF;
  --grey-10: #F8F8F8;
  --grey-20: #D6D6D6;
  --grey-30: #595959;
  --grey-40: #2B2B2A;
  --black: #000;
  --green: #00865A;
  --yellow: #FC0;
  --red: #BF242B;
  --blue: #3091E7;
  --success: #00865A;
  --warning: #FC0;
  --danger: #BF242B;
  --info: #3091E7;
  --brand-primary: #0C0A08;
  --brand-secondary: #2B2B2A;
  --brand-tertiary: #40403F;
  --brand-goat-green: #99FF00;
  --off-white: #F8F8F8;
  --light-gray: #D6D6D6;
  --dark-gray: #595959;
  --scuff01: #ECEEE4;
  --scuff02: #D8DDC9;
  --scuff03: #B9BFA7;
  --primary: #3091E7;
  --secondary: #6c757d;
  --success: #00865A;
  --info: #3091E7;
  --warning: #FC0;
  --danger: #BF242B;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 544px;
  --breakpoint-md: 769px;
  --breakpoint-lg: 1024px;
  --breakpoint-xl: 1440px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #FFF; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 6px; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0C0A08;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: black;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 6px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.1875rem; }

h2, .h2 {
  font-size: 1.8125rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.0625rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 12px;
  margin-bottom: 12px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 12px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #FFF;
  border: 1px solid #dee2e6;
  border-radius: 0px;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 6px;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-break: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #FFF;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 769px) {
    .container {
      max-width: 1024px; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1440px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1920px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 544px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 769px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 12px;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #D6D6D6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #D6D6D6; }
  .table tbody + tbody {
    border-top: 2px solid #D6D6D6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #D6D6D6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #D6D6D6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #c5e0f8; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #93c6f3; }

.table-hover .table-primary:hover {
  background-color: #aed4f5; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #aed4f5; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #d6d8db; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #b3b7bb; }

.table-hover .table-secondary:hover {
  background-color: #c8cbcf; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #c8cbcf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #b8ddd1; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #7ac0a9; }

.table-hover .table-success:hover {
  background-color: #a7d5c6; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #a7d5c6; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c5e0f8; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #93c6f3; }

.table-hover .table-info:hover {
  background-color: #aed4f5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #aed4f5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fff1b8; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffe47a; }

.table-hover .table-warning:hover {
  background-color: #ffec9f; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffec9f; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #edc2c4; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #de8d91; }

.table-hover .table-danger:hover {
  background-color: #e7aeb1; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #e7aeb1; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c6c8ca; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #95999c; }

.table-hover .table-dark:hover {
  background-color: #b9bbbe; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b9bbbe; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #FFF;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #D6D6D6; }

.table-dark {
  color: #FFF;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #FFF;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 543.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 768.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 1023.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid #D6D6D6;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #495057;
    background-color: #FFF;
    border-color: #a2cef4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .form-control::placeholder {
    color: #595959;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #FFF; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #00865A; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: #00865a;
  border-radius: 0; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #00865A;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300865A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #00865A;
    box-shadow: 0 0 0 0.2rem rgba(0, 134, 90, 0.25); }
  .was-validated .form-control:valid ~ .valid-feedback,
  .was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
  .form-control.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .was-validated select.form-control:valid,
.was-validated select:valid, .custom-select.is-valid,
select.is-valid {
  border-color: #00865A;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300865A' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .was-validated select.form-control:valid:focus,
  .was-validated select:valid:focus, .custom-select.is-valid:focus,
  select.is-valid:focus {
    border-color: #00865A;
    box-shadow: 0 0 0 0.2rem rgba(0, 134, 90, 0.25); }
  .was-validated .custom-select:valid ~ .valid-feedback, .was-validated select.form-control:valid ~ .valid-feedback, .was-validated select:valid ~ .valid-feedback,
  .was-validated .custom-select:valid ~ .valid-tooltip,
  .was-validated select.form-control:valid ~ .valid-tooltip,
  .was-validated select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback, select.is-valid.form-control ~ .valid-feedback, select.is-valid ~ .valid-feedback,
  .custom-select.is-valid ~ .valid-tooltip,
  select.is-valid.form-control ~ .valid-tooltip,
  select.is-valid ~ .valid-tooltip {
    display: block; }

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #00865A; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #00865A; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #00865A; }

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #00b97c;
  background-color: #00b97c; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 134, 90, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #00865A; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #00865A; }

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #00865A;
  box-shadow: 0 0 0 0.2rem rgba(0, 134, 90, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #BF242B; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.6875rem;
  line-height: 1.5;
  color: #FFF;
  background-color: #bf242b;
  border-radius: 0; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #BF242B;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23BF242B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23BF242B' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #BF242B;
    box-shadow: 0 0 0 0.2rem rgba(191, 36, 43, 0.25); }
  .was-validated .form-control:invalid ~ .invalid-feedback,
  .was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
  .form-control.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .was-validated select.form-control:invalid,
.was-validated select:invalid, .custom-select.is-invalid,
select.is-invalid {
  border-color: #BF242B;
  padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23BF242B' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23BF242B' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #FFF no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .was-validated select.form-control:invalid:focus,
  .was-validated select:invalid:focus, .custom-select.is-invalid:focus,
  select.is-invalid:focus {
    border-color: #BF242B;
    box-shadow: 0 0 0 0.2rem rgba(191, 36, 43, 0.25); }
  .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated select.form-control:invalid ~ .invalid-feedback, .was-validated select:invalid ~ .invalid-feedback,
  .was-validated .custom-select:invalid ~ .invalid-tooltip,
  .was-validated select.form-control:invalid ~ .invalid-tooltip,
  .was-validated select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback, select.is-invalid.form-control ~ .invalid-feedback, select.is-invalid ~ .invalid-feedback,
  .custom-select.is-invalid ~ .invalid-tooltip,
  select.is-invalid.form-control ~ .invalid-tooltip,
  select.is-invalid ~ .invalid-tooltip {
    display: block; }

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #BF242B; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #BF242B; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #BF242B; }

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #da3c43;
  background-color: #da3c43; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(191, 36, 43, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #BF242B; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #BF242B; }

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #BF242B;
  box-shadow: 0 0 0 0.2rem rgba(191, 36, 43, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 544px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select,
    .form-inline select.form-control,
    .form-inline select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0px; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 544px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 769px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 1024px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 6px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #FFF;
    text-decoration: none;
    background-color: #3091E7; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > select.form-control,
  .input-group > select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + select.form-control,
    .input-group > .form-control + select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + select.form-control,
    .input-group > .form-control-plaintext + select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > select.form-control + .form-control,
    .input-group > select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > select.form-control + .custom-select,
    .input-group > select + .custom-select,
    .input-group > .custom-select + select.form-control,
    .input-group > select.form-control + select.form-control,
    .input-group > select + select.form-control,
    .input-group > .custom-select + select,
    .input-group > select.form-control + select,
    .input-group > select + select,
    .input-group > .custom-select + .custom-file,
    .input-group > select.form-control + .custom-file,
    .input-group > select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + select.form-control,
    .input-group > .custom-file + select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > select.form-control:focus,
  .input-group > select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child),
  .input-group > select.form-control:not(:last-child),
  .input-group > select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child),
  .input-group > select.form-control:not(:first-child),
  .input-group > select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #D6D6D6;
  border-radius: 8px; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }


.input-group-lg > .custom-select,
.input-group-lg > select.form-control,
.input-group-lg > select,
.input-group-sm > .custom-select,
.input-group-sm > select.form-control,
.input-group-sm > select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #FFF;
    border-color: #3091E7;
    background-color: #3091E7; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #a2cef4; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #FFF;
    background-color: #d0e6fa;
    border-color: #d0e6fa; }
  .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #e9ecef; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #FFF;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23FFF' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #3091E7;
  background-color: #3091E7; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23FFF' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(48, 145, 231, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #FFF;
    transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(48, 145, 231, 0.5); }

.custom-select, select.form-control,
select {
  display: inline-block;
  width: 100%;
  height: 48px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 0px;
  appearance: none; }
  .custom-select:focus,
  select:focus {
    border-color: #a2cef4;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-select:focus::-ms-value,
    select:focus::-ms-value {
      color: #495057;
      background-color: #FFF; }
  .custom-select[multiple],
  select[multiple], .custom-select[size]:not([size="1"]),
  select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .custom-select:disabled,
  select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand, select.form-control::-ms-expand,
  select::-ms-expand {
    display: none; }

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 48px;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 48px;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #a2cef4;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #e9ecef; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 48px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 8px; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 0.75rem);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 8px 8px 0; }

.custom-range {
  width: 100%;
  height: calc(1rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #FFF, 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #3091E7;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #d0e6fa; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label, .custom-select, select.form-control,
select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label, .custom-select, select.form-control,
    select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #FFF;
    border-color: #dee2e6 #dee2e6 #FFF; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0px; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #FFF;
  background-color: #3091E7; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px; }
  .navbar > .container,
  .navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 12px;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0px; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 543.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 544px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 768.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 769px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 1023.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1024px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #FFF; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #FFF; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #FFF; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #FFF; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #FFF; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: border-box;
  border: 1px solid #D6D6D6;
  border-radius: 0px; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px; }

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid #D6D6D6; }
  .card-header:first-child {
    border-radius: calc(0px - 1px) calc(0px - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid #D6D6D6; }
  .card-footer:last-child {
    border-radius: 0 0 calc(0px - 1px) calc(0px - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img {
  width: 100%;
  border-radius: calc(0px - 1px); }

.card-img-top {
  width: 100%;
  border-top-left-radius: calc(0px - 1px);
  border-top-right-radius: calc(0px - 1px); }

.card-img-bottom {
  width: 100%;
  border-bottom-right-radius: calc(0px - 1px);
  border-bottom-left-radius: calc(0px - 1px); }

.card-deck {
  display: flex;
  flex-direction: column; }
  .card-deck .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px; }
      .card-deck .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px; } }

.card-group {
  display: flex;
  flex-direction: column; }
  .card-group > .card {
    margin-bottom: 15px; }
  @media (min-width: 544px) {
    .card-group {
      flex-flow: row wrap; }
      .card-group > .card {
        flex: 1 0 0%;
        margin-bottom: 0; }
        .card-group > .card + .card {
          margin-left: 0;
          border-left: 0; }
        .card-group > .card:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-top,
          .card-group > .card:not(:last-child) .card-header {
            border-top-right-radius: 0; }
          .card-group > .card:not(:last-child) .card-img-bottom,
          .card-group > .card:not(:last-child) .card-footer {
            border-bottom-right-radius: 0; }
        .card-group > .card:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-top,
          .card-group > .card:not(:first-child) .card-header {
            border-top-left-radius: 0; }
          .card-group > .card:not(:first-child) .card-img-bottom,
          .card-group > .card:not(:first-child) .card-footer {
            border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 544px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0; }
  .accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0; }
  .accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card .card-header {
    margin-bottom: -1px; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0px; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0C0A08;
  background-color: #FFF;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: black;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px; }

.page-item:last-child .page-link {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px; }

.page-item.active .page-link {
  z-index: 1;
  color: #FFF;
  background-color: #3091E7;
  border-color: #3091E7; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #FFF;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #FFF;
  background-color: #3091E7; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #FFF;
    background-color: #1877cc; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.5); }

.badge-secondary {
  color: #FFF;
  background-color: #6c757d; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #FFF;
    background-color: #545b62; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5); }

.badge-success {
  color: #FFF;
  background-color: #00865A; }
  a.badge-success:hover, a.badge-success:focus {
    color: #FFF;
    background-color: #005338; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 134, 90, 0.5); }

.badge-info {
  color: #FFF;
  background-color: #3091E7; }
  a.badge-info:hover, a.badge-info:focus {
    color: #FFF;
    background-color: #1877cc; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(48, 145, 231, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #FC0; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #cca300; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 204, 0, 0.5); }

.badge-danger {
  color: #FFF;
  background-color: #BF242B; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #FFF;
    background-color: #941c21; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(191, 36, 43, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #FFF;
  background-color: #343a40; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #FFF;
    background-color: #1d2124; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(52, 58, 64, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem; }
  @media (min-width: 544px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0px; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #194b78;
  background-color: #d6e9fa;
  border-color: #c5e0f8; }
  .alert-primary hr {
    border-top-color: #aed4f5; }
  .alert-primary .alert-link {
    color: #10314e; }

.alert-secondary {
  color: #383d41;
  background-color: #e2e3e5;
  border-color: #d6d8db; }
  .alert-secondary hr {
    border-top-color: #c8cbcf; }
  .alert-secondary .alert-link {
    color: #202326; }

.alert-success {
  color: #00462f;
  background-color: #cce7de;
  border-color: #b8ddd1; }
  .alert-success hr {
    border-top-color: #a7d5c6; }
  .alert-success .alert-link {
    color: #00130d; }

.alert-info {
  color: #194b78;
  background-color: #d6e9fa;
  border-color: #c5e0f8; }
  .alert-info hr {
    border-top-color: #aed4f5; }
  .alert-info .alert-link {
    color: #10314e; }

.alert-warning {
  color: #856a00;
  background-color: #fff5cc;
  border-color: #fff1b8; }
  .alert-warning hr {
    border-top-color: #ffec9f; }
  .alert-warning .alert-link {
    color: #524100; }

.alert-danger {
  color: #631316;
  background-color: #f2d3d5;
  border-color: #edc2c4; }
  .alert-danger hr {
    border-top-color: #e7aeb1; }
  .alert-danger .alert-link {
    color: #380b0c; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1b1e21;
  background-color: #d6d8d9;
  border-color: #c6c8ca; }
  .alert-dark hr {
    border-top-color: #b9bbbe; }
  .alert-dark .alert-link {
    color: #040505; }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0px; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #FFF;
  text-align: center;
  white-space: nowrap;
  background-color: #3091E7;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  margin-bottom: -1px;
  background-color: #FFF;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px; }
  .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #FFF; }
  .list-group-item.active {
    z-index: 2;
    color: #FFF;
    background-color: #3091E7;
    border-color: #3091E7; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0; }
    .list-group-horizontal .list-group-item:first-child {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 0; }
    .list-group-horizontal .list-group-item:last-child {
      margin-right: 0;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0; }

@media (min-width: 544px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0; }
      .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0; } }

@media (min-width: 769px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0; }
      .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1024px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0; }
      .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item {
      margin-right: -1px;
      margin-bottom: 0; }
      .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 0; }
      .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0; } }

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:last-child {
    margin-bottom: -1px; }

.list-group-flush:first-child .list-group-item:first-child {
  border-top: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0; }

.list-group-item-primary {
  color: #194b78;
  background-color: #c5e0f8; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #194b78;
    background-color: #aed4f5; }
  .list-group-item-primary.list-group-item-action.active {
    color: #FFF;
    background-color: #194b78;
    border-color: #194b78; }

.list-group-item-secondary {
  color: #383d41;
  background-color: #d6d8db; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #383d41;
    background-color: #c8cbcf; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #FFF;
    background-color: #383d41;
    border-color: #383d41; }

.list-group-item-success {
  color: #00462f;
  background-color: #b8ddd1; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #00462f;
    background-color: #a7d5c6; }
  .list-group-item-success.list-group-item-action.active {
    color: #FFF;
    background-color: #00462f;
    border-color: #00462f; }

.list-group-item-info {
  color: #194b78;
  background-color: #c5e0f8; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #194b78;
    background-color: #aed4f5; }
  .list-group-item-info.list-group-item-action.active {
    color: #FFF;
    background-color: #194b78;
    border-color: #194b78; }

.list-group-item-warning {
  color: #856a00;
  background-color: #fff1b8; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856a00;
    background-color: #ffec9f; }
  .list-group-item-warning.list-group-item-action.active {
    color: #FFF;
    background-color: #856a00;
    border-color: #856a00; }

.list-group-item-danger {
  color: #631316;
  background-color: #edc2c4; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #631316;
    background-color: #e7aeb1; }
  .list-group-item-danger.list-group-item-action.active {
    color: #FFF;
    background-color: #631316;
    border-color: #631316; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #FFF;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1b1e21;
  background-color: #c6c8ca; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe; }
  .list-group-item-dark.list-group-item-action.active {
    color: #FFF;
    background-color: #1b1e21;
    border-color: #1b1e21; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #FFF;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 10px 20px 9px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 10px 20px 9px;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid transparent;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 544px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 1024px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1440px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 1; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #FFF;
  text-align: center;
  background-color: #000;
  border-radius: 0; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 300px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.75rem;
  word-wrap: break-word;
  background-color: #FFF;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0px; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc((0.5rem + 1px) * -1); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #FFF; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #FFF; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc((0.5rem + 1px) * -1); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #FFF; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.05); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #FFF; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: 0s 0.6s opacity; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #FFF;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #FFF;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23FFF' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #FFF;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #FFF;
  text-align: center; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #3091E7 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #1877cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #00865A !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #005338 !important; }

.bg-info {
  background-color: #3091E7 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1877cc !important; }

.bg-warning {
  background-color: #FC0 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #cca300 !important; }

.bg-danger {
  background-color: #BF242B !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #941c21 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #FFF !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #D6D6D6 !important; }

.border-top {
  border-top: 1px solid #D6D6D6 !important; }

.border-right {
  border-right: 1px solid #D6D6D6 !important; }

.border-bottom {
  border-bottom: 1px solid #D6D6D6 !important; }

.border-left {
  border-left: 1px solid #D6D6D6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #3091E7 !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #00865A !important; }

.border-info {
  border-color: #3091E7 !important; }

.border-warning {
  border-color: #FC0 !important; }

.border-danger {
  border-color: #BF242B !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #FFF !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0px !important; }

.rounded-top {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important; }

.rounded-right {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important; }

.rounded-bottom {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.rounded-left {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 544px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 769px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1024px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 544px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 769px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 1024px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 544px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 769px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 1024px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 3px !important; }

.mt-1,
.my-1 {
  margin-top: 3px !important; }

.mr-1,
.mx-1 {
  margin-right: 3px !important; }

.mb-1,
.my-1 {
  margin-bottom: 3px !important; }

.ml-1,
.mx-1 {
  margin-left: 3px !important; }

.m-2 {
  margin: 6px !important; }

.mt-2,
.my-2 {
  margin-top: 6px !important; }

.mr-2,
.mx-2 {
  margin-right: 6px !important; }

.mb-2,
.my-2 {
  margin-bottom: 6px !important; }

.ml-2,
.mx-2 {
  margin-left: 6px !important; }

.m-3 {
  margin: 12px !important; }

.mt-3,
.my-3 {
  margin-top: 12px !important; }

.mr-3,
.mx-3 {
  margin-right: 12px !important; }

.mb-3,
.my-3 {
  margin-bottom: 12px !important; }

.ml-3,
.mx-3 {
  margin-left: 12px !important; }

.m-4 {
  margin: 18px !important; }

.mt-4,
.my-4 {
  margin-top: 18px !important; }

.mr-4,
.mx-4 {
  margin-right: 18px !important; }

.mb-4,
.my-4 {
  margin-bottom: 18px !important; }

.ml-4,
.mx-4 {
  margin-left: 18px !important; }

.m-5 {
  margin: 36px !important; }

.mt-5,
.my-5 {
  margin-top: 36px !important; }

.mr-5,
.mx-5 {
  margin-right: 36px !important; }

.mb-5,
.my-5 {
  margin-bottom: 36px !important; }

.ml-5,
.mx-5 {
  margin-left: 36px !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 3px !important; }

.pt-1,
.py-1 {
  padding-top: 3px !important; }

.pr-1,
.px-1 {
  padding-right: 3px !important; }

.pb-1,
.py-1 {
  padding-bottom: 3px !important; }

.pl-1,
.px-1 {
  padding-left: 3px !important; }

.p-2 {
  padding: 6px !important; }

.pt-2,
.py-2 {
  padding-top: 6px !important; }

.pr-2,
.px-2 {
  padding-right: 6px !important; }

.pb-2,
.py-2 {
  padding-bottom: 6px !important; }

.pl-2,
.px-2 {
  padding-left: 6px !important; }

.p-3 {
  padding: 12px !important; }

.pt-3,
.py-3 {
  padding-top: 12px !important; }

.pr-3,
.px-3 {
  padding-right: 12px !important; }

.pb-3,
.py-3 {
  padding-bottom: 12px !important; }

.pl-3,
.px-3 {
  padding-left: 12px !important; }

.p-4 {
  padding: 18px !important; }

.pt-4,
.py-4 {
  padding-top: 18px !important; }

.pr-4,
.px-4 {
  padding-right: 18px !important; }

.pb-4,
.py-4 {
  padding-bottom: 18px !important; }

.pl-4,
.px-4 {
  padding-left: 18px !important; }

.p-5 {
  padding: 36px !important; }

.pt-5,
.py-5 {
  padding-top: 36px !important; }

.pr-5,
.px-5 {
  padding-right: 36px !important; }

.pb-5,
.py-5 {
  padding-bottom: 36px !important; }

.pl-5,
.px-5 {
  padding-left: 36px !important; }

.m-n1 {
  margin: -3px !important; }

.mt-n1,
.my-n1 {
  margin-top: -3px !important; }

.mr-n1,
.mx-n1 {
  margin-right: -3px !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -3px !important; }

.ml-n1,
.mx-n1 {
  margin-left: -3px !important; }

.m-n2 {
  margin: -6px !important; }

.mt-n2,
.my-n2 {
  margin-top: -6px !important; }

.mr-n2,
.mx-n2 {
  margin-right: -6px !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -6px !important; }

.ml-n2,
.mx-n2 {
  margin-left: -6px !important; }

.m-n3 {
  margin: -12px !important; }

.mt-n3,
.my-n3 {
  margin-top: -12px !important; }

.mr-n3,
.mx-n3 {
  margin-right: -12px !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -12px !important; }

.ml-n3,
.mx-n3 {
  margin-left: -12px !important; }

.m-n4 {
  margin: -18px !important; }

.mt-n4,
.my-n4 {
  margin-top: -18px !important; }

.mr-n4,
.mx-n4 {
  margin-right: -18px !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -18px !important; }

.ml-n4,
.mx-n4 {
  margin-left: -18px !important; }

.m-n5 {
  margin: -36px !important; }

.mt-n5,
.my-n5 {
  margin-top: -36px !important; }

.mr-n5,
.mx-n5 {
  margin-right: -36px !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -36px !important; }

.ml-n5,
.mx-n5 {
  margin-left: -36px !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 544px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 3px !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 3px !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 3px !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 3px !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 3px !important; }
  .m-sm-2 {
    margin: 6px !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 6px !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 6px !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 6px !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 6px !important; }
  .m-sm-3 {
    margin: 12px !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 12px !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 12px !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 12px !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 12px !important; }
  .m-sm-4 {
    margin: 18px !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 18px !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 18px !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 18px !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 18px !important; }
  .m-sm-5 {
    margin: 36px !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 36px !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 36px !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 36px !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 36px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 3px !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 3px !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 3px !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 3px !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 3px !important; }
  .p-sm-2 {
    padding: 6px !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 6px !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 6px !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 6px !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 6px !important; }
  .p-sm-3 {
    padding: 12px !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 12px !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 12px !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 12px !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 12px !important; }
  .p-sm-4 {
    padding: 18px !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 18px !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 18px !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 18px !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 18px !important; }
  .p-sm-5 {
    padding: 36px !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 36px !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 36px !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 36px !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 36px !important; }
  .m-sm-n1 {
    margin: -3px !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -3px !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -3px !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -3px !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -3px !important; }
  .m-sm-n2 {
    margin: -6px !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -6px !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -6px !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -6px !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -6px !important; }
  .m-sm-n3 {
    margin: -12px !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -12px !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -12px !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -12px !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -12px !important; }
  .m-sm-n4 {
    margin: -18px !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -18px !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -18px !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -18px !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -18px !important; }
  .m-sm-n5 {
    margin: -36px !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -36px !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -36px !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -36px !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -36px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 769px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 3px !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 3px !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 3px !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 3px !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 3px !important; }
  .m-md-2 {
    margin: 6px !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 6px !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 6px !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 6px !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 6px !important; }
  .m-md-3 {
    margin: 12px !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 12px !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 12px !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 12px !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 12px !important; }
  .m-md-4 {
    margin: 18px !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 18px !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 18px !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 18px !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 18px !important; }
  .m-md-5 {
    margin: 36px !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 36px !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 36px !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 36px !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 36px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 3px !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 3px !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 3px !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 3px !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 3px !important; }
  .p-md-2 {
    padding: 6px !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 6px !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 6px !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 6px !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 6px !important; }
  .p-md-3 {
    padding: 12px !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 12px !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 12px !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 12px !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 12px !important; }
  .p-md-4 {
    padding: 18px !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 18px !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 18px !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 18px !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 18px !important; }
  .p-md-5 {
    padding: 36px !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 36px !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 36px !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 36px !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 36px !important; }
  .m-md-n1 {
    margin: -3px !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -3px !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -3px !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -3px !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -3px !important; }
  .m-md-n2 {
    margin: -6px !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -6px !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -6px !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -6px !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -6px !important; }
  .m-md-n3 {
    margin: -12px !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -12px !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -12px !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -12px !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -12px !important; }
  .m-md-n4 {
    margin: -18px !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -18px !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -18px !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -18px !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -18px !important; }
  .m-md-n5 {
    margin: -36px !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -36px !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -36px !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -36px !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -36px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1024px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 3px !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 3px !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 3px !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 3px !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 3px !important; }
  .m-lg-2 {
    margin: 6px !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 6px !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 6px !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 6px !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 6px !important; }
  .m-lg-3 {
    margin: 12px !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 12px !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 12px !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 12px !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 12px !important; }
  .m-lg-4 {
    margin: 18px !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 18px !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 18px !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 18px !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 18px !important; }
  .m-lg-5 {
    margin: 36px !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 36px !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 36px !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 36px !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 36px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 3px !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 3px !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 3px !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 3px !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 3px !important; }
  .p-lg-2 {
    padding: 6px !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 6px !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 6px !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 6px !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 6px !important; }
  .p-lg-3 {
    padding: 12px !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 12px !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 12px !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 12px !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 12px !important; }
  .p-lg-4 {
    padding: 18px !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 18px !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 18px !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 18px !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 18px !important; }
  .p-lg-5 {
    padding: 36px !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 36px !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 36px !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 36px !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 36px !important; }
  .m-lg-n1 {
    margin: -3px !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -3px !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -3px !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -3px !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -3px !important; }
  .m-lg-n2 {
    margin: -6px !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -6px !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -6px !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -6px !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -6px !important; }
  .m-lg-n3 {
    margin: -12px !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -12px !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -12px !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -12px !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -12px !important; }
  .m-lg-n4 {
    margin: -18px !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -18px !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -18px !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -18px !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -18px !important; }
  .m-lg-n5 {
    margin: -36px !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -36px !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -36px !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -36px !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -36px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 3px !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 3px !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 3px !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 3px !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 3px !important; }
  .m-xl-2 {
    margin: 6px !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 6px !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 6px !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 6px !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 6px !important; }
  .m-xl-3 {
    margin: 12px !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 12px !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 12px !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 12px !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 12px !important; }
  .m-xl-4 {
    margin: 18px !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 18px !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 18px !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 18px !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 18px !important; }
  .m-xl-5 {
    margin: 36px !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 36px !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 36px !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 36px !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 36px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 3px !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 3px !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 3px !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 3px !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 3px !important; }
  .p-xl-2 {
    padding: 6px !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 6px !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 6px !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 6px !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 6px !important; }
  .p-xl-3 {
    padding: 12px !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 12px !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 12px !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 12px !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 12px !important; }
  .p-xl-4 {
    padding: 18px !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 18px !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 18px !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 18px !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 18px !important; }
  .p-xl-5 {
    padding: 36px !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 36px !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 36px !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 36px !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 36px !important; }
  .m-xl-n1 {
    margin: -3px !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -3px !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -3px !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -3px !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -3px !important; }
  .m-xl-n2 {
    margin: -6px !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -6px !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -6px !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -6px !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -6px !important; }
  .m-xl-n3 {
    margin: -12px !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -12px !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -12px !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -12px !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -12px !important; }
  .m-xl-n4 {
    margin: -18px !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -18px !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -18px !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -18px !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -18px !important; }
  .m-xl-n5 {
    margin: -36px !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -36px !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -36px !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -36px !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -36px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 544px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 769px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 1024px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #FFF !important; }

.text-primary {
  color: #3091E7 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #156ab5 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #00865A !important; }

a.text-success:hover, a.text-success:focus {
  color: #003a27 !important; }

.text-info {
  color: #3091E7 !important; }

a.text-info:hover, a.text-info:focus {
  color: #156ab5 !important; }

.text-warning {
  color: #FC0 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #b38f00 !important; }

.text-danger {
  color: #BF242B !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #7f181d !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 1024px !important; }
  .container {
    min-width: 1024px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #FFF !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #D6D6D6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #D6D6D6; } }

dt {
  color: #495057;
  font-weight: normal; }

a {
  color: inherit; }
  a:hover {
    color: inherit; }

i {
  font-style: normal;
  display: inline-block; }

button {
  background: transparent;
  border: none;
  padding: 0;
  letter-spacing: inherit; }

ul {
  padding-left: 12px; }

figure {
  margin: 0; }

.modal-footer > :not(:first-child) {
  margin-left: 0; }

.modal-footer > :not(:last-child) {
  margin-right: 0; }

.tooltip {
  font-family: inherit;
  font-size: inherit;
  font-style: inherit;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit; }

.text-muted {
  color: #595959 !important; }

.row:not(.no-gutters) {
  margin-right: -6px;
  margin-left: -6px; }

.row:not(.no-gutters) > .col,
.row:not(.no-gutters) > [class*="col-"] {
  padding-right: 6px;
  padding-left: 6px; }

.container {
  padding-right: 24px;
  padding-left: 24px; }
  @media (min-width: 769px) {
    .container {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 1024px) {
    .container {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 1440px) {
    .container {
      padding-right: 48px;
      padding-left: 48px; } }

.container-fluid {
  padding-right: 24px;
  padding-left: 24px; }
  @media (min-width: 769px) {
    .container-fluid {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 1024px) {
    .container-fluid {
      padding-right: 24px;
      padding-left: 24px; } }
  @media (min-width: 1440px) {
    .container-fluid {
      padding-right: 48px;
      padding-left: 48px; } }

:focus, button:focus {
  outline: 2px solid #595959; }

:focus:not(.focus-visible) {
  outline: none; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.tns-outer {
  padding: 0 !important; }
  .tns-outer [hidden] {
    display: none !important; }
  .tns-outer [aria-controls], .tns-outer [data-action] {
    cursor: pointer; }

.tns-slider {
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  transition: all 0s; }
  .tns-slider > .tns-item {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }

.tns-horizontal.tns-subpixel {
  white-space: nowrap; }
  .tns-horizontal.tns-subpixel > .tns-item {
    display: inline-block;
    vertical-align: top;
    white-space: normal; }

.tns-horizontal.tns-no-subpixel:after {
  content: '';
  display: table;
  clear: both; }

.tns-horizontal.tns-no-subpixel > .tns-item {
  float: left; }

.tns-horizontal.tns-carousel.tns-no-subpixel > .tns-item {
  margin-right: -100%; }

.tns-no-calc {
  position: relative;
  left: 0; }

.tns-gallery {
  position: relative;
  left: 0;
  min-height: 1px; }
  .tns-gallery > .tns-item {
    position: absolute;
    left: -100%;
    -webkit-transition: transform 0s, opacity 0s;
    -moz-transition: transform 0s, opacity 0s;
    transition: transform 0s, opacity 0s; }
  .tns-gallery > .tns-slide-active {
    position: relative;
    left: auto !important; }
  .tns-gallery > .tns-moving {
    -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    transition: all 0.25s; }

.tns-autowidth {
  display: inline-block; }

.tns-lazy-img {
  -webkit-transition: opacity 0.6s;
  -moz-transition: opacity 0.6s;
  transition: opacity 0.6s;
  opacity: 0.6; }
  .tns-lazy-img.tns-complete {
    opacity: 1; }

.tns-ah {
  -webkit-transition: height 0s;
  -moz-transition: height 0s;
  transition: height 0s; }

.tns-ovh {
  overflow: hidden; }

.tns-visually-hidden {
  position: absolute;
  left: -10000em; }

.tns-transparent {
  opacity: 0;
  visibility: hidden; }

.tns-fadeIn {
  opacity: 1;
  filter: alpha(opacity=100);
  z-index: 0; }

.tns-normal, .tns-fadeOut {
  opacity: 0;
  filter: alpha(opacity=0);
  z-index: -1; }

.tns-vpfix {
  white-space: nowrap; }
  .tns-vpfix > div, .tns-vpfix > li {
    display: inline-block; }

.tns-t-subp2 {
  margin: 0 auto;
  width: 310px;
  position: relative;
  height: 10px;
  overflow: hidden; }

.tns-t-ct {
  width: calc(100% * 70 / 3);
  position: absolute;
  right: 0; }
  .tns-t-ct:after {
    content: '';
    display: table;
    clear: both; }
  .tns-t-ct > div {
    width: calc(100% / 70);
    height: 10px;
    float: left; }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fade-in-long {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-long {
  0% {
    transform: scale(0);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-long {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-long {
  0% {
    transform: rotateX(60deg) translateY(-120px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-long {
  0% {
    transform: rotateX(-60deg) translateY(120px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-long {
  0% {
    transform: translateY(-100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-long {
  0% {
    transform: translateX(-100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-long {
  0% {
    transform: translateX(100%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-long {
  0% {
    transform: translateY(100%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-medium {
  0% {
    opacity: 0.33; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-medium {
  0% {
    transform: scale(0.33);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-medium {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-medium {
  0% {
    transform: rotateX(60deg) translateY(-80px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-medium {
  0% {
    transform: rotateX(-60deg) translateY(80px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-medium {
  0% {
    transform: translateY(-66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-medium {
  0% {
    transform: translateX(-66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-medium {
  0% {
    transform: translateX(66%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-medium {
  0% {
    transform: translateY(66%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes fade-in-short {
  0% {
    opacity: 0.66; }
  100% {
    opacity: 1; } }

@keyframes scale-in-center-short {
  0% {
    transform: scale(0.66);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

@keyframes hinge-drop-short {
  0% {
    transform: rotateX(-90deg) translateZ(0);
    opacity: 0; }
  10% {
    opacity: 1; }
  100% {
    transform: rotateX(0deg) translateZ(0);
    opacity: 1; } }

@keyframes flip-in-hor-top-short {
  0% {
    transform: rotateX(60deg) translateY(-40px);
    opacity: 0; }
  100% {
    transform: rotateX(0deg) translateY(0);
    opacity: 1; } }

@keyframes flip-in-hor-bottom-short {
  0% {
    transform: rotateX(-60deg) translateY(40px);
    opacity: 0; }
  100% {
    transform: rotateX(0) translateY(0px);
    opacity: 1; } }

@keyframes slide-in-top-short {
  0% {
    transform: translateY(-33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-left-short {
  0% {
    transform: translateX(-33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-right-short {
  0% {
    transform: translateX(33%);
    opacity: 0; }
  100% {
    transform: translateX(0);
    opacity: 1; } }

@keyframes slide-in-bottom-short {
  0% {
    transform: translateY(33%);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-up {
  0% {
    transform: translateY(0); }
  100% {
    transform: translateY(-100%); } }

@keyframes slide-down {
  0% {
    transform: translateY(-100%); }
  100% {
    transform: translateY(0); } }

.swatch-circle-beige {
  width: 20px;
  height: 20px;
  background-color: #f5f5dc;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-black {
  width: 20px;
  height: 20px;
  background-color: #000;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-blue {
  width: 20px;
  height: 20px;
  background-color: #00559c;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-brown {
  width: 20px;
  height: 20px;
  background-color: #9a8d7a;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-green {
  width: 20px;
  height: 20px;
  background-color: #5d624e;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-grey {
  width: 20px;
  height: 20px;
  background-color: #606263;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-navy {
  width: 20px;
  height: 20px;
  background-color: #000080;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-orange {
  width: 20px;
  height: 20px;
  background-color: #f05829;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-pink {
  width: 20px;
  height: 20px;
  background-color: #ff7263;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-purple {
  width: 20px;
  height: 20px;
  background-color: #592b37;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-red {
  width: 20px;
  height: 20px;
  background-color: #ff1027;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-white {
  width: 20px;
  height: 20px;
  background-color: #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-yellow {
  width: 20px;
  height: 20px;
  background-color: #d7ff2f;
  border-radius: 50%;
  display: inline-block;
  position: relative; }

.swatch-circle-miscellaneous {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 1px solid #D6D6D6;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transform: rotate(35deg); }
  .swatch-circle-miscellaneous.swatch-mark.color-value.selected::after {
    transform: translate(50%, -50%) rotate(-35deg); }

.swatch-circle-white:not(.swatch-thumbnail),
.swatch[data-displayvalue="Sugar"]:not(.swatch-thumbnail),
.swatch[data-displayvalue="White"]:not(.swatch-thumbnail) {
  border: 1px solid #D6D6D6; }

.swatch-circle-multi {
  background: linear-gradient(0deg, #821e91 0, #821e91 25%, #edd134 25%, yellow 50%, #edd134 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, #0e5cd1 0, #0e5cd1 50%, #e20b0b 50%, #e20b0b 100%);
  background-repeat: repeat-y, repeat;
  background-size: 50% 100%, 100% 100%;
  border: 1px solid #D6D6D6;
  border-radius: 50%;
  display: inline-block;
  height: 20px;
  width: 20px;
  position: relative;
  transform: rotate(35deg); }
  .swatch-circle-multi.swatch-mark.color-value.selected::after {
    transform: translate(50%, -50%) rotate(-35deg); }

.text--blue {
  color: #3091E7; }

.text--indigo {
  color: #6610f2; }

.text--purple {
  color: #6f42c1; }

.text--pink {
  color: #e83e8c; }

.text--red {
  color: #BF242B; }

.text--orange {
  color: #fd7e14; }

.text--yellow {
  color: #FC0; }

.text--green {
  color: #00865A; }

.text--teal {
  color: #20c997; }

.text--cyan {
  color: #17a2b8; }

.text--white {
  color: #FFF; }

.text--gray {
  color: #6c757d; }

.text--gray-dark {
  color: #343a40; }

.text--brand-01 {
  color: #0C0A08; }

.text--brand-02 {
  color: #40403F; }

.text--brand-03 {
  color: #99FF00; }

.text--secondary-01 {
  color: #ECEEE4; }

.text--secondary-02 {
  color: #D8DDC9; }

.text--secondary-03 {
  color: #B9BFA7; }

.text--white {
  color: #FFF; }

.text--grey-10 {
  color: #F8F8F8; }

.text--grey-20 {
  color: #D6D6D6; }

.text--grey-30 {
  color: #595959; }

.text--grey-40 {
  color: #2B2B2A; }

.text--black {
  color: #000; }

.text--green {
  color: #00865A; }

.text--yellow {
  color: #FC0; }

.text--red {
  color: #BF242B; }

.text--blue {
  color: #3091E7; }

.text--success {
  color: #00865A; }

.text--warning {
  color: #FC0; }

.text--danger {
  color: #BF242B; }

.text--info {
  color: #3091E7; }

.text--brand-primary {
  color: #0C0A08; }

.text--brand-secondary {
  color: #2B2B2A; }

.text--brand-tertiary {
  color: #40403F; }

.text--brand-goat-green {
  color: #99FF00; }

.text--off-white {
  color: #F8F8F8; }

.text--light-gray {
  color: #D6D6D6; }

.text--dark-gray {
  color: #595959; }

.text--scuff01 {
  color: #ECEEE4; }

.text--scuff02 {
  color: #D8DDC9; }

.text--scuff03 {
  color: #B9BFA7; }

.background--blue {
  background-color: #3091E7; }

.background--indigo {
  background-color: #6610f2; }

.background--purple {
  background-color: #6f42c1; }

.background--pink {
  background-color: #e83e8c; }

.background--red {
  background-color: #BF242B; }

.background--orange {
  background-color: #fd7e14; }

.background--yellow {
  background-color: #FC0; }

.background--green {
  background-color: #00865A; }

.background--teal {
  background-color: #20c997; }

.background--cyan {
  background-color: #17a2b8; }

.background--white {
  background-color: #FFF; }

.background--gray {
  background-color: #6c757d; }

.background--gray-dark {
  background-color: #343a40; }

.background--brand-01 {
  background-color: #0C0A08; }

.background--brand-02 {
  background-color: #40403F; }

.background--brand-03 {
  background-color: #99FF00; }

.background--secondary-01 {
  background-color: #ECEEE4; }

.background--secondary-02 {
  background-color: #D8DDC9; }

.background--secondary-03 {
  background-color: #B9BFA7; }

.background--white {
  background-color: #FFF; }

.background--grey-10 {
  background-color: #F8F8F8; }

.background--grey-20 {
  background-color: #D6D6D6; }

.background--grey-30 {
  background-color: #595959; }

.background--grey-40 {
  background-color: #2B2B2A; }

.background--black {
  background-color: #000; }

.background--green {
  background-color: #00865A; }

.background--yellow {
  background-color: #FC0; }

.background--red {
  background-color: #BF242B; }

.background--blue {
  background-color: #3091E7; }

.background--success {
  background-color: #00865A; }

.background--warning {
  background-color: #FC0; }

.background--danger {
  background-color: #BF242B; }

.background--info {
  background-color: #3091E7; }

.background--brand-primary {
  background-color: #0C0A08; }

.background--brand-secondary {
  background-color: #2B2B2A; }

.background--brand-tertiary {
  background-color: #40403F; }

.background--brand-goat-green {
  background-color: #99FF00; }

.background--off-white {
  background-color: #F8F8F8; }

.background--light-gray {
  background-color: #D6D6D6; }

.background--dark-gray {
  background-color: #595959; }

.background--scuff01 {
  background-color: #ECEEE4; }

.background--scuff02 {
  background-color: #D8DDC9; }

.background--scuff03 {
  background-color: #B9BFA7; }

.border--blue {
  border-color: #3091E7; }

.border--indigo {
  border-color: #6610f2; }

.border--purple {
  border-color: #6f42c1; }

.border--pink {
  border-color: #e83e8c; }

.border--red {
  border-color: #BF242B; }

.border--orange {
  border-color: #fd7e14; }

.border--yellow {
  border-color: #FC0; }

.border--green {
  border-color: #00865A; }

.border--teal {
  border-color: #20c997; }

.border--cyan {
  border-color: #17a2b8; }

.border--white {
  border-color: #FFF; }

.border--gray {
  border-color: #6c757d; }

.border--gray-dark {
  border-color: #343a40; }

.border--brand-01 {
  border-color: #0C0A08; }

.border--brand-02 {
  border-color: #40403F; }

.border--brand-03 {
  border-color: #99FF00; }

.border--secondary-01 {
  border-color: #ECEEE4; }

.border--secondary-02 {
  border-color: #D8DDC9; }

.border--secondary-03 {
  border-color: #B9BFA7; }

.border--white {
  border-color: #FFF; }

.border--grey-10 {
  border-color: #F8F8F8; }

.border--grey-20 {
  border-color: #D6D6D6; }

.border--grey-30 {
  border-color: #595959; }

.border--grey-40 {
  border-color: #2B2B2A; }

.border--black {
  border-color: #000; }

.border--green {
  border-color: #00865A; }

.border--yellow {
  border-color: #FC0; }

.border--red {
  border-color: #BF242B; }

.border--blue {
  border-color: #3091E7; }

.border--success {
  border-color: #00865A; }

.border--warning {
  border-color: #FC0; }

.border--danger {
  border-color: #BF242B; }

.border--info {
  border-color: #3091E7; }

.border--brand-primary {
  border-color: #0C0A08; }

.border--brand-secondary {
  border-color: #2B2B2A; }

.border--brand-tertiary {
  border-color: #40403F; }

.border--brand-goat-green {
  border-color: #99FF00; }

.border--off-white {
  border-color: #F8F8F8; }

.border--light-gray {
  border-color: #D6D6D6; }

.border--dark-gray {
  border-color: #595959; }

.border--scuff01 {
  border-color: #ECEEE4; }

.border--scuff02 {
  border-color: #D8DDC9; }

.border--scuff03 {
  border-color: #B9BFA7; }

.font-size--0 {
  font-size: 0px !important; }

.font-size--1 {
  font-size: 1px !important; }

.font-size--2 {
  font-size: 2px !important; }

.font-size--3 {
  font-size: 3px !important; }

.font-size--4 {
  font-size: 4px !important; }

.font-size--5 {
  font-size: 5px !important; }

.font-size--6 {
  font-size: 6px !important; }

.font-size--7 {
  font-size: 7px !important; }

.font-size--8 {
  font-size: 8px !important; }

.font-size--9 {
  font-size: 9px !important; }

.font-size--10 {
  font-size: 10px !important; }

.font-size--11 {
  font-size: 11px !important; }

.font-size--12 {
  font-size: 12px !important; }

.font-size--13 {
  font-size: 13px !important; }

.font-size--14 {
  font-size: 14px !important; }

.font-size--15 {
  font-size: 15px !important; }

.font-size--16 {
  font-size: 16px !important; }

.font-size--17 {
  font-size: 17px !important; }

.font-size--18 {
  font-size: 18px !important; }

.font-size--19 {
  font-size: 19px !important; }

.font-size--20 {
  font-size: 20px !important; }

.font-size--21 {
  font-size: 21px !important; }

.font-size--22 {
  font-size: 22px !important; }

.font-size--23 {
  font-size: 23px !important; }

.font-size--24 {
  font-size: 24px !important; }

.font-size--25 {
  font-size: 25px !important; }

.font-size--26 {
  font-size: 26px !important; }

.font-size--27 {
  font-size: 27px !important; }

.font-size--28 {
  font-size: 28px !important; }

.font-size--29 {
  font-size: 29px !important; }

.font-size--30 {
  font-size: 30px !important; }

.font-size--31 {
  font-size: 31px !important; }

.font-size--32 {
  font-size: 32px !important; }

.font-size--33 {
  font-size: 33px !important; }

.font-size--34 {
  font-size: 34px !important; }

.font-size--35 {
  font-size: 35px !important; }

.font-size--36 {
  font-size: 36px !important; }

.font-size--37 {
  font-size: 37px !important; }

.font-size--38 {
  font-size: 38px !important; }

.font-size--39 {
  font-size: 39px !important; }

.font-size--40 {
  font-size: 40px !important; }

.font-size--41 {
  font-size: 41px !important; }

.font-size--42 {
  font-size: 42px !important; }

.font-size--43 {
  font-size: 43px !important; }

.font-size--44 {
  font-size: 44px !important; }

.font-size--45 {
  font-size: 45px !important; }

.font-size--46 {
  font-size: 46px !important; }

.font-size--47 {
  font-size: 47px !important; }

.font-size--48 {
  font-size: 48px !important; }

.font-size--49 {
  font-size: 49px !important; }

.font-size--50 {
  font-size: 50px !important; }

.font-size--51 {
  font-size: 51px !important; }

.font-size--52 {
  font-size: 52px !important; }

.font-size--53 {
  font-size: 53px !important; }

.font-size--54 {
  font-size: 54px !important; }

.font-size--55 {
  font-size: 55px !important; }

.font-size--56 {
  font-size: 56px !important; }

.font-size--57 {
  font-size: 57px !important; }

.font-size--58 {
  font-size: 58px !important; }

.font-size--59 {
  font-size: 59px !important; }

.font-size--60 {
  font-size: 60px !important; }

.line-height--0 {
  line-height: 0px !important; }

.line-height--1 {
  line-height: 1px !important; }

.line-height--2 {
  line-height: 2px !important; }

.line-height--3 {
  line-height: 3px !important; }

.line-height--4 {
  line-height: 4px !important; }

.line-height--5 {
  line-height: 5px !important; }

.line-height--6 {
  line-height: 6px !important; }

.line-height--7 {
  line-height: 7px !important; }

.line-height--8 {
  line-height: 8px !important; }

.line-height--9 {
  line-height: 9px !important; }

.line-height--10 {
  line-height: 10px !important; }

.line-height--11 {
  line-height: 11px !important; }

.line-height--12 {
  line-height: 12px !important; }

.line-height--13 {
  line-height: 13px !important; }

.line-height--14 {
  line-height: 14px !important; }

.line-height--15 {
  line-height: 15px !important; }

.line-height--16 {
  line-height: 16px !important; }

.line-height--17 {
  line-height: 17px !important; }

.line-height--18 {
  line-height: 18px !important; }

.line-height--19 {
  line-height: 19px !important; }

.line-height--20 {
  line-height: 20px !important; }

.line-height--21 {
  line-height: 21px !important; }

.line-height--22 {
  line-height: 22px !important; }

.line-height--23 {
  line-height: 23px !important; }

.line-height--24 {
  line-height: 24px !important; }

.line-height--25 {
  line-height: 25px !important; }

.line-height--26 {
  line-height: 26px !important; }

.line-height--27 {
  line-height: 27px !important; }

.line-height--28 {
  line-height: 28px !important; }

.line-height--29 {
  line-height: 29px !important; }

.line-height--30 {
  line-height: 30px !important; }

.line-height--31 {
  line-height: 31px !important; }

.line-height--32 {
  line-height: 32px !important; }

.line-height--33 {
  line-height: 33px !important; }

.line-height--34 {
  line-height: 34px !important; }

.line-height--35 {
  line-height: 35px !important; }

.line-height--36 {
  line-height: 36px !important; }

.line-height--37 {
  line-height: 37px !important; }

.line-height--38 {
  line-height: 38px !important; }

.line-height--39 {
  line-height: 39px !important; }

.line-height--40 {
  line-height: 40px !important; }

.line-height--41 {
  line-height: 41px !important; }

.line-height--42 {
  line-height: 42px !important; }

.line-height--43 {
  line-height: 43px !important; }

.line-height--44 {
  line-height: 44px !important; }

.line-height--45 {
  line-height: 45px !important; }

.line-height--46 {
  line-height: 46px !important; }

.line-height--47 {
  line-height: 47px !important; }

.line-height--48 {
  line-height: 48px !important; }

.line-height--49 {
  line-height: 49px !important; }

.line-height--50 {
  line-height: 50px !important; }

.line-height--51 {
  line-height: 51px !important; }

.line-height--52 {
  line-height: 52px !important; }

.line-height--53 {
  line-height: 53px !important; }

.line-height--54 {
  line-height: 54px !important; }

.line-height--55 {
  line-height: 55px !important; }

.line-height--56 {
  line-height: 56px !important; }

.line-height--57 {
  line-height: 57px !important; }

.line-height--58 {
  line-height: 58px !important; }

.line-height--59 {
  line-height: 59px !important; }

.line-height--60 {
  line-height: 60px !important; }

.font--primary {
  font-family: "archia-medium", sans-serif; }

.font--secondary {
  font-family: "Roboto", sans-serif; }

.font--tertiary {
  font-family: "Roboto", sans-serif; }

.font--header {
  font-family: "archia-medium", sans-serif; }

.font--body {
  font-family: "Roboto", sans-serif; }

h1, .h1 {
  font-family: "archia-medium", sans-serif;
  font-size: 35px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h1, .h1 {
      font-size: 42px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

h2, .h2 {
  font-family: "archia-medium", sans-serif;
  font-size: 29px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h2, .h2 {
      font-size: 35px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

h3, .h3 {
  font-family: "archia-medium", sans-serif;
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h3, .h3 {
      font-size: 29px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

h4, .h4 {
  font-family: "archia-medium", sans-serif;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h4, .h4 {
      font-size: 24px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

h5, .h5 {
  font-family: "archia-medium", sans-serif;
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h5, .h5 {
      font-size: 20px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

h6, .h6 {
  font-family: "archia-medium", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    h6, .h6 {
      font-size: 16px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

p,
.p {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0;
  margin-bottom: 12px;
  line-height: 1.5em; }
  @media (min-width: 1024px) {
    p,
    .p {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  @media (min-width: 1024px) {
    p,
    .p {
      line-height: 1.5em; } }
  p--small,
  .p--small {
    letter-spacing: 0;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0;
    line-height: 1.5em; }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0; } }
    @media (min-width: 1024px) {
      p--small,
      .p--small {
        line-height: 1.5em; } }
  p--large,
  .p--large {
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0; }
    @media (min-width: 1024px) {
      p--large,
      .p--large {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0; } }

small {
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0; }
  @media (min-width: 1024px) {
    small {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0; } }

.lead {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0; }
  @media (min-width: 1024px) {
    .lead {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; } }

a,
.text-link {
  color: #0C0A08;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer; }
  a:hover,
  .text-link:hover {
    color: #2B2B2A;
    text-decoration: underline; }
  .dark-theme a, .dark-theme
  .text-link {
    color: #FFF; }
    .dark-theme a:hover, .dark-theme
    .text-link:hover {
      color: #FFF; }

.pull-left {
  float: left; }

.pull-right {
  float: right; }

.header-link {
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0; }
  .header-link:hover {
    text-decoration: none; }

.navigation-link {
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0; }
  .navigation-link:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .navigation-link {
      font-size: 16px; } }

.visually-hidden {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  height: 1px;
  width: 1px; }

.lock-scroll {
  overflow: hidden;
  top: 0;
  width: 100%;
  height: 100vh; }

.leading-lines {
  overflow: hidden;
  margin: 0 0 12px; }
  .leading-lines label {
    background-color: #FFF; }
  .leading-lines p {
    margin-bottom: 0; }
  .leading-lines .start-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .start-lines span {
      position: relative;
      background-color: #FFF;
      z-index: 2;
      display: inline-block; }
      .leading-lines .start-lines span:only-child, .leading-lines .start-lines span:last-child {
        padding-right: 12px; }
    .leading-lines .start-lines::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100vw;
      white-space: nowrap;
      content: "";
      border-bottom: 0 dotted transparent;
      margin-top: 1px;
      z-index: 1; }
  .leading-lines .end-lines {
    padding: 0;
    align-self: center; }
    .leading-lines .end-lines span {
      position: relative;
      background-color: #FFF;
      z-index: 2;
      display: inline-block; }
      .leading-lines .end-lines span:only-child, .leading-lines .end-lines span:last-child {
        padding-left: 12px;
        padding-right: 12px;
        margin-right: -12px; }

.email-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.email-signup-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .email-signup-alert.show {
    display: block; }
  .email-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .email-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .email-signup-alert.alert-dismissible .close {
      padding: 12px; }

.swatch-mark.color-value {
  transition: box-shadow 200ms ease-out; }
  .swatch-mark.color-value:hover {
    box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #D6D6D6; }
  .swatch-mark.color-value.selected {
    box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #000; }
    .swatch-mark.color-value.selected:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 7px;
      color: #FFF;
      font-weight: normal; }
    .swatch-mark.color-value.selected[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.color-value.selected.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .swatch-mark.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.color-value.selected.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.color-value.selected[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.color-value.selected::after {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      display: none;
      background: rgba(0, 0, 0, 0.7);
      width: 14px;
      height: 14px;
      line-height: 14px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      z-index: 1; }
  .swatch-mark.color-value.unavailable {
    pointer-events: none;
    pointer-events: auto;
    cursor: not-allowed;
    position: relative; }
    .swatch-mark.color-value.unavailable:before, .swatch-mark.color-value.unavailable:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.color-value.unavailable:after {
      background-color: #D6D6D6; }
    .swatch-mark.color-value.unavailable:before {
      background-color: #FFF;
      margin-right: 1px;
      box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
      width: 2px; }
  .swatch-mark.color-value.unavailable {
    pointer-events: none;
    pointer-events: auto;
    cursor: not-allowed;
    position: relative; }
    .swatch-mark.color-value.unavailable:before, .swatch-mark.color-value.unavailable:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.color-value.unavailable:after {
      background-color: #D6D6D6; }
    .swatch-mark.color-value.unavailable:before {
      background-color: #FFF;
      margin-right: 1px;
      box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
      width: 2px; }

.swatch-mark.non-color-swatch-value {
  transition: border 200ms ease-out, box-shadow 200ms ease-out; }
  .swatch-mark.non-color-swatch-value:not(.unavailable):hover {
    border-color: transparent;
    box-shadow: none; }
  .swatch-mark.non-color-swatch-value.selected, .swatch-mark.non-color-swatch-value.selected:hover {
    background: #FFF;
    color: #000;
    border-color: transparent;
    box-shadow: none; }
    .swatch-mark.non-color-swatch-value.selected:after, .swatch-mark.non-color-swatch-value.selected:hover:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 8px;
      color: #FFF;
      font-weight: normal; }
    .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .swatch-mark.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
      .swatch-mark.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
        display: none; }
    .swatch-mark.non-color-swatch-value.selected::after, .swatch-mark.non-color-swatch-value.selected:hover::after {
      position: absolute;
      top: -5.33333px;
      right: -5.33333px;
      background: #0C0A08;
      width: 16px;
      height: 16px;
      line-height: 16px;
      padding-left: 1px;
      text-align: center;
      border-radius: 50%;
      display: none; }
  .swatch-mark.non-color-swatch-value.unavailable {
    pointer-events: none;
    pointer-events: auto;
    position: relative; }
    .swatch-mark.non-color-swatch-value.unavailable:not(.bis-enabled):before, .swatch-mark.non-color-swatch-value.unavailable:not(.bis-enabled):after {
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%) rotate(45deg);
      height: calc(100% + 15px);
      width: 1px; }
    .swatch-mark.non-color-swatch-value.unavailable:not(.bis-enabled):after {
      background-color: #D6D6D6; }
    .swatch-mark.non-color-swatch-value.unavailable:not(.bis-enabled):before {
      background-color: #FFF;
      margin-right: 1px;
      box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
      width: 2px; }

.disabled,
:disabled,
[disabled] {
  pointer-events: none; }

.vertical-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }

.horizontal-center {
  position: absolute;
  right: 50%;
  transform: translateX(50%); }

.absolute-center {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%); }

.fade-in {
  animation-name: fadeIn;
  animation-timing-function: ease-out; }

.fade-out {
  animation-name: fadeOut;
  animation-timing-function: ease-out; }

.zoom-in {
  animation-name: scale-in-center-long;
  animation-timing-function: ease-out; }

[data-animation]:not(.disable-mobile-animation) {
  will-change: transform, opacity; }
  [data-animation]:not(.disable-mobile-animation) > [style*="animation-delay"],
  [data-animation]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    will-change: transform, opacity; }

@media (min-width: 1024px) {
  [data-animation].disable-mobile-animation {
    will-change: transform, opacity; }
    [data-animation].disable-mobile-animation > [style*="animation-delay"],
    [data-animation].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      will-change: transform, opacity; } }

.animation-initial-state:not(.disable-mobile-animation) {
  opacity: 0; }

@media (min-width: 1024px) {
  .animation-initial-state.disable-mobile-animation {
    opacity: 1; } }

.scale-in-center--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--short.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--medium.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.scale-in-center--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.scale-in-center--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .scale-in-center--long.disable-mobile-animation > [style*="animation-delay"],
  .scale-in-center--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hinge-drop--short:not(.disable-mobile-animation) {
  perspective: 1000px; }
  .hinge-drop--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--short.disable-mobile-animation {
    perspective: 1000px; }
    .hinge-drop--short.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 400ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--medium:not(.disable-mobile-animation) {
  perspective: 750px; }
  .hinge-drop--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--medium.disable-mobile-animation {
    perspective: 750px; }
    .hinge-drop--medium.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 1200ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.hinge-drop--long:not(.disable-mobile-animation) {
  perspective: 500px; }
  .hinge-drop--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hinge-drop--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
    transform-origin: 0px 0px 0px; }

@media (min-width: 1024px) {
  .hinge-drop--long.disable-mobile-animation {
    perspective: 500px; }
    .hinge-drop--long.disable-mobile-animation > [style*="animation-delay"],
    .hinge-drop--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 2000ms cubic-bezier(0.2, 1, 0.2, 1) both;
      transform-origin: 0px 0px 0px; } }

.flip-in-hor-top--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-top--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-top--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-top--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-top--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-top--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--short:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--short.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--short.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--medium:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--medium.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.flip-in-hor-bottom--long:not(.disable-mobile-animation) {
  perspective: 2000px; }
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
  .flip-in-hor-bottom--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .flip-in-hor-bottom--long.disable-mobile-animation {
    perspective: 2000px; }
    .flip-in-hor-bottom--long.disable-mobile-animation > [style*="animation-delay"],
    .flip-in-hor-bottom--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.slide-in-top--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-top--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-top--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-top--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-top--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-left--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-left--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-left--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-left--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-right--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-right--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-right--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-right--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--short.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--medium.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.slide-in-bottom--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.slide-in-bottom--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .slide-in-bottom--long.disable-mobile-animation > [style*="animation-delay"],
  .slide-in-bottom--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.fade-in--short:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--short:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--short.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--short.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--medium:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--medium:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--medium.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--medium.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.fade-in--long:not(.disable-mobile-animation) > [style*="animation-delay"],
.fade-in--long:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .fade-in--long.disable-mobile-animation > [style*="animation-delay"],
  .fade-in--long.disable-mobile-animation > .container > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="scale-in-center"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="scale-in-center"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short {
  animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--short > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium {
  animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--medium > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long {
  animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"]:not(.disable-mobile-animation).scale-in-center--long > .container > .row > [style*="animation-delay"] {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short {
    animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--short > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium {
    animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--medium > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long {
    animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="scale-in-center"].disable-mobile-animation.scale-in-center--long > .container > .row > [style*="animation-delay"] {
      animation: scale-in-center-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="hinge-drop"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="hinge-drop"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short {
  animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--short > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium {
  animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--medium > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long {
  animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"]:not(.disable-mobile-animation).hinge-drop--long > .container > .row > [style*="animation-delay"] {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short {
    animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--short > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-short 200ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium {
    animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--medium > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-medium 600ms cubic-bezier(0.2, 1, 0.2, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long {
    animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="hinge-drop"].disable-mobile-animation.hinge-drop--long > .container > .row > [style*="animation-delay"] {
      animation: hinge-drop-long 1000ms cubic-bezier(0.2, 1, 0.2, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-top"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short {
  animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--short > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium {
  animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--medium > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long {
  animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"]:not(.disable-mobile-animation).flip-in-hor-top--long > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short {
    animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--short > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium {
    animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--medium > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long {
    animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-top"].disable-mobile-animation.flip-in-hor-top--long > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-top-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="flip-in-hor-bottom"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short {
  animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--short > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium {
  animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--medium > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long {
  animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"]:not(.disable-mobile-animation).flip-in-hor-bottom--long > .container > .row > [style*="animation-delay"] {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short {
    animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--short > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-short 200ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium {
    animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--medium > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-medium 600ms cubic-bezier(0.65, 0, 0.35, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long {
    animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="flip-in-hor-bottom"].disable-mobile-animation.flip-in-hor-bottom--long > .container > .row > [style*="animation-delay"] {
      animation: flip-in-hor-bottom-long 1000ms cubic-bezier(0.65, 0, 0.35, 1) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-top"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-top"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short {
  animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium {
  animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long {
  animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"]:not(.disable-mobile-animation).slide-in-top--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short {
    animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium {
    animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long {
    animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-top"].disable-mobile-animation.slide-in-top--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-top-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-left"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-left"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short {
  animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium {
  animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long {
  animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"]:not(.disable-mobile-animation).slide-in-left--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short {
    animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium {
    animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long {
    animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-left"].disable-mobile-animation.slide-in-left--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-left-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-right"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-right"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short {
  animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium {
  animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long {
  animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"]:not(.disable-mobile-animation).slide-in-right--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short {
    animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium {
    animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long {
    animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-right"].disable-mobile-animation.slide-in-right--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-right-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="slide-in-bottom"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short {
  animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--short > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium {
  animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--medium > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long {
  animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"]:not(.disable-mobile-animation).slide-in-bottom--long > .container > .row > [style*="animation-delay"] {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short {
    animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--short > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-short 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium {
    animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--medium > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-medium 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long {
    animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="slide-in-bottom"].disable-mobile-animation.slide-in-bottom--long > .container > .row > [style*="animation-delay"] {
      animation: slide-in-bottom-long 1000ms cubic-bezier(0.25, 0.46, 0.45, 0.94) both; } }

.hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) {
  opacity: 0; }
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > [style*="animation-delay"],
  .hero-has-animation .tns-item [data-animation^="fade-in"]:not(.disable-mobile-animation) > .container > .row > [style*="animation-delay"] {
    opacity: 0;
    animation: unset; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation {
    opacity: 0; }
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > [style*="animation-delay"],
    .hero-has-animation .tns-item [data-animation^="fade-in"].disable-mobile-animation > .container > .row > [style*="animation-delay"] {
      opacity: 0;
      animation: unset; } }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short {
  animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--short > .container > .row > [style*="animation-delay"] {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium {
  animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--medium > .container > .row > [style*="animation-delay"] {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

.hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long {
  animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > [style*="animation-delay"],
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"]:not(.disable-mobile-animation).fade-in--long > .container > .row > [style*="animation-delay"] {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }

@media (min-width: 1024px) {
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short {
    animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--short > .container > .row > [style*="animation-delay"] {
      animation: fade-in-short 200ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium {
    animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--medium > .container > .row > [style*="animation-delay"] {
      animation: fade-in-medium 600ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
  .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long {
    animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; }
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > [style*="animation-delay"],
    .hero-has-animation .tns-item.tns-slide-active [data-animation^="fade-in"].disable-mobile-animation.fade-in--long > .container > .row > [style*="animation-delay"] {
      animation: fade-in-long 1000ms cubic-bezier(0.39, 0.575, 0.565, 1) both; } }

.btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: "archia-medium", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.42px;
  line-height: 1.5em;
  padding: 12px 20px 12px 20px; }
  .btn[class*="icon-"], .btn[class^="icon-"] {
    padding-left: 40px; }
    .btn[class*="icon-"]:before, .btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .btn-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    color: #FFF;
    background-color: #000;
    border-width: 1px;
    border-radius: 50px;
    border-color: #000;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    padding: 12px 20px 12px 20px; }
    .btn-primary:hover, .btn-primary:focus, .btn-primary[aria-describedby*="popover"] {
      color: #FFF;
      background-color: #000;
      border-color: #FFF;
      text-decoration: none; }
    .btn-primary[class*="icon-"], .btn-primary[class^="icon-"] {
      padding-left: 40px; }
      .btn-primary[class*="icon-"]:before, .btn-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-secondary, .btn-outline-primary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    color: #000;
    border-width: 1px;
    border-radius: 50px;
    background-color: #FFF;
    border-color: #D6D6D6;
    text-decoration: none;
    text-align: center;
    font-weight: 500;
    padding: 12px 20px 12px 20px; }
    .btn-secondary:hover, .btn-secondary:focus, .btn-secondary[aria-describedby*="popover"], .btn-outline-primary:hover, .btn-outline-primary:focus, .btn-outline-primary[aria-describedby*="popover"] {
      color: #000;
      background-color: #FFF;
      border-color: #000;
      text-decoration: none;
      opacity: 1; }
    .btn-secondary[class*="icon-"], .btn-secondary[class^="icon-"], .btn-outline-primary[class*="icon-"], .btn-outline-primary[class^="icon-"] {
      padding-left: 40px; }
      .btn-secondary[class*="icon-"]:before, .btn-secondary[class^="icon-"]:before, .btn-outline-primary[class*="icon-"]:before, .btn-outline-primary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-tertiary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    font-family: "Roboto", sans-serif;
    text-transform: none;
    color: #0C0A08;
    background-color: transparent;
    border-radius: 0;
    border-width: 0;
    border-color: transparent;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
    padding: 0 0 7px 0; }
    .btn-tertiary:hover, .btn-tertiary:focus, .btn-tertiary[aria-describedby*="popover"] {
      color: #40403F;
      background-color: transparent;
      border-color: transparent;
      text-decoration: underline; }
    .btn-tertiary[class*="icon-"], .btn-tertiary[class^="icon-"] {
      padding-left: 40px; }
      .btn-tertiary[class*="icon-"]:before, .btn-tertiary[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-addtocart {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #0C0A08;
    background-color: #99FF00;
    border-color: #2B2B2A;
    border-radius: 9px;
    border-width: 1px; }
    .btn-addtocart:hover, .btn-addtocart:focus, .btn-addtocart[aria-describedby*="popover"] {
      background-color: #99FF00; }
    .btn-addtocart[class*="icon-"], .btn-addtocart[class^="icon-"] {
      padding-left: 40px; }
      .btn-addtocart[class*="icon-"]:before, .btn-addtocart[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
    .btn-addtocart.disabled, .btn-addtocart:disabled, .btn-addtocart[disabled] {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: "archia-medium", sans-serif;
      text-transform: none;
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: 50px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.42px;
      line-height: 1.5em;
      padding: 12px 20px 12px 20px;
      color: #595959;
      background-color: #FFF;
      border-color: #D6D6D6;
      border-radius: 9px;
      border-width: 1px;
      opacity: 1; }
      .btn-addtocart.disabled[class*="icon-"], .btn-addtocart.disabled[class^="icon-"], .btn-addtocart:disabled[class*="icon-"], .btn-addtocart:disabled[class^="icon-"], .btn-addtocart[disabled][class*="icon-"], .btn-addtocart[disabled][class^="icon-"] {
        padding-left: 40px; }
        .btn-addtocart.disabled[class*="icon-"]:before, .btn-addtocart.disabled[class^="icon-"]:before, .btn-addtocart:disabled[class*="icon-"]:before, .btn-addtocart:disabled[class^="icon-"]:before, .btn-addtocart[disabled][class*="icon-"]:before, .btn-addtocart[disabled][class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
  .btn-addtocartdisabled {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #595959;
    background-color: #FFF;
    border-color: #D6D6D6;
    border-radius: 9px;
    border-width: 1px;
    opacity: 1; }
    .btn-addtocartdisabled[class*="icon-"], .btn-addtocartdisabled[class^="icon-"] {
      padding-left: 40px; }
      .btn-addtocartdisabled[class*="icon-"]:before, .btn-addtocartdisabled[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-viewdetails {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #0C0A08;
    background-color: #FFF;
    border-color: #D6D6D6;
    border-radius: 8px;
    border-width: 1px; }
    .btn-viewdetails:hover, .btn-viewdetails:focus, .btn-viewdetails[aria-describedby*="popover"] {
      border-color: #0C0A08; }
    .btn-viewdetails[class*="icon-"], .btn-viewdetails[class^="icon-"] {
      padding-left: 40px; }
      .btn-viewdetails[class*="icon-"]:before, .btn-viewdetails[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-primary-sm {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #FFF;
    padding: 8px 20px 8px 20px;
    background-color: #000;
    border-color: #000;
    border-radius: 50px; }
    .btn-primary-sm:hover, .btn-primary-sm:focus, .btn-primary-sm[aria-describedby*="popover"] {
      color: #FFF;
      background-color: #40403F;
      border-color: #40403F; }
    .btn-primary-sm[class*="icon-"], .btn-primary-sm[class^="icon-"] {
      padding-left: 40px; }
      .btn-primary-sm[class*="icon-"]:before, .btn-primary-sm[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-secondary-sm {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #0C0A08;
    padding: 8px 20px 8px 20px;
    background-color: #FFF;
    border-color: #FFF;
    border-radius: 50px; }
    .btn-secondary-sm:hover, .btn-secondary-sm:focus, .btn-secondary-sm[aria-describedby*="popover"] {
      color: #40403F;
      background-color: #FFF;
      border-color: #FFF; }
    .btn-secondary-sm[class*="icon-"], .btn-secondary-sm[class^="icon-"] {
      padding-left: 40px; }
      .btn-secondary-sm[class*="icon-"]:before, .btn-secondary-sm[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
  .btn-slider-left {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    font-family: "icomoon";
    line-height: 40px;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    padding: 0; }
    .btn-slider-left::after {
      display: inline-block;
      content: "";
      font-size: 20px;
      text-align: center; }
    .btn-slider-left[class*="icon-"], .btn-slider-left[class^="icon-"] {
      padding-left: 40px; }
      .btn-slider-left[class*="icon-"]:before, .btn-slider-left[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
    .btn-slider-left.disabled {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: "archia-medium", sans-serif;
      text-transform: none;
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: 50px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.42px;
      line-height: 1.5em;
      padding: 12px 20px 12px 20px;
      font-family: "icomoon";
      line-height: 40px;
      border-radius: 40px;
      border-color: #D6D6D6;
      padding: 0; }
      .btn-slider-left.disabled::after {
        color: #D6D6D6;
        font-size: 20px;
        text-align: center; }
      .btn-slider-left.disabled[class*="icon-"], .btn-slider-left.disabled[class^="icon-"] {
        padding-left: 40px; }
        .btn-slider-left.disabled[class*="icon-"]:before, .btn-slider-left.disabled[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
  .btn-slider-right {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    font-family: "icomoon";
    line-height: 40px;
    border-radius: 40px;
    width: 40px;
    height: 40px;
    padding: 0; }
    .btn-slider-right::after {
      display: inline-block;
      content: "";
      font-size: 20px;
      text-align: center; }
    .btn-slider-right[class*="icon-"], .btn-slider-right[class^="icon-"] {
      padding-left: 40px; }
      .btn-slider-right[class*="icon-"]:before, .btn-slider-right[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
    .btn-slider-right.disabled {
      display: inline-block;
      position: relative;
      cursor: pointer;
      user-select: none;
      border-width: 1px;
      border-style: solid;
      font-family: "archia-medium", sans-serif;
      text-transform: none;
      transition: all 200ms ease-out;
      text-decoration: none;
      border-radius: 50px;
      text-align: center;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: -0.42px;
      line-height: 1.5em;
      padding: 12px 20px 12px 20px;
      font-family: "icomoon";
      line-height: 40px;
      border-radius: 40px;
      border-color: #D6D6D6;
      padding: 0; }
      .btn-slider-right.disabled::after {
        color: #D6D6D6;
        font-size: 20px;
        text-align: center; }
      .btn-slider-right.disabled[class*="icon-"], .btn-slider-right.disabled[class^="icon-"] {
        padding-left: 40px; }
        .btn-slider-right.disabled[class*="icon-"]:before, .btn-slider-right.disabled[class^="icon-"]:before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
  .btn-block {
    display: block;
    width: 100%; }

.subtitle-heavy {
  font-family: "archia-medium", sans-serif;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 700; }
  @media (min-width: 1024px) {
    .subtitle-heavy {
      font-family: "archia-medium", sans-serif;
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 0;
      text-transform: uppercase;
      font-style: normal; } }

.subtitle-light {
  font-family: "archia-medium", sans-serif;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500; }
  @media (min-width: 1024px) {
    .subtitle-light {
      font-family: "archia-medium", sans-serif;
      font-size: 15px;
      line-height: 17px;
      letter-spacing: 0;
      text-transform: uppercase;
      font-style: normal; } }

.action-text {
  font-family: "archia-medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  text-transform: uppercase; }

@media (min-width: 1024px) {
  .bottom-short-line-light::after {
    content: "";
    width: 50px;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    vertical-align: bottom;
    margin-top: 20px;
    background-color: #FFF; } }

@media (min-width: 1024px) {
  .bottom-short-line-dark::after {
    content: "";
    width: 50px;
    height: 1px;
    position: absolute;
    left: 0;
    right: 0;
    vertical-align: bottom;
    margin-top: 20px;
    background-color: #000; } }

@media (max-width: 768.98px) {
  .no-vertical-gutters-mobile .region {
    margin-top: 0 !important;
    margin-bottom: 0 !important; } }

.list-style-dash ul {
  list-style: none; }
  .list-style-dash ul li::before {
    content: '\2014';
    position: absolute;
    margin-left: -30px; }

.text-stroke-black-1px {
  -webkit-text-stroke: 1px #000000; }

.text-stroke-black-2px {
  -webkit-text-stroke: 2px #000000; }

.text-link.text-link--light {
  color: #595959; }

.klarna-prefooter {
  margin-bottom: -48px; }

.pull-left-xs {
  float: left; }

.pull-right-xs {
  float: right; }

@media (min-width: 544px) and (max-width: 768.98px) {
  .pull-left-sm {
    float: left; }
  .pull-right-sm {
    float: right; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .pull-left-md {
    float: left; }
  .pull-right-md {
    float: right; } }

@media (min-width: 1024px) and (max-width: 1439.98px) {
  .pull-left-lg {
    float: left; }
  .pull-right-lg {
    float: right; } }

@media (min-width: 1440px) {
  .pull-left-xl {
    float: left; }
  .pull-right-xl {
    float: right; } }

@media (max-width: 543.98px) {
  .hidden-xs-down {
    display: none !important; } }

@media (max-width: 768.98px) {
  .hidden-sm-down {
    display: none !important; } }

@media (max-width: 1023.98px) {
  .hidden-md-down {
    display: none !important; } }

@media (max-width: 1439.98px) {
  .hidden-lg-down {
    display: none !important; } }

.hidden-xl-down {
  display: none !important; }

.row.equal-height > [class^="col"] {
  display: flex; }

.mt-6 {
  margin-top: 56px !important; }

.mb-6 {
  margin-bottom: 56px !important; }

.mb-7 {
  margin-bottom: 72px !important; }

@media (min-width: 1024px) {
  .px-lg-6 {
    padding-left: 56px !important;
    padding-right: 56px !important; }
  .mt-lg-6 {
    margin-top: 56px !important; }
  .mb-lg-6 {
    margin-bottom: 56px !important; }
  .mt-lg-7 {
    margin-top: 64px !important; }
  .mt-lg-8 {
    margin-top: 72px !important; }
  .mb-lg-8 {
    margin-bottom: 72px !important; } }

.flag-icon-background, .flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  position: relative;
  display: inline-block;
  width: 1.33333em;
  line-height: 1em; }
  .flag-icon:before {
    content: '\00a0'; }
  .flag-icon.flag-icon-squared {
    width: 1em; }

.flag-icon-ad {
  background-image: url(../fonts/flags//4x3/ad.svg); }
  .flag-icon-ad.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ad.svg); }

.flag-icon-ae {
  background-image: url(../fonts/flags//4x3/ae.svg); }
  .flag-icon-ae.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ae.svg); }

.flag-icon-af {
  background-image: url(../fonts/flags//4x3/af.svg); }
  .flag-icon-af.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/af.svg); }

.flag-icon-ag {
  background-image: url(../fonts/flags//4x3/ag.svg); }
  .flag-icon-ag.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ag.svg); }

.flag-icon-ai {
  background-image: url(../fonts/flags//4x3/ai.svg); }
  .flag-icon-ai.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ai.svg); }

.flag-icon-al {
  background-image: url(../fonts/flags//4x3/al.svg); }
  .flag-icon-al.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/al.svg); }

.flag-icon-am {
  background-image: url(../fonts/flags//4x3/am.svg); }
  .flag-icon-am.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/am.svg); }

.flag-icon-ao {
  background-image: url(../fonts/flags//4x3/ao.svg); }
  .flag-icon-ao.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ao.svg); }

.flag-icon-aq {
  background-image: url(../fonts/flags//4x3/aq.svg); }
  .flag-icon-aq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aq.svg); }

.flag-icon-ar {
  background-image: url(../fonts/flags//4x3/ar.svg); }
  .flag-icon-ar.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ar.svg); }

.flag-icon-as {
  background-image: url(../fonts/flags//4x3/as.svg); }
  .flag-icon-as.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/as.svg); }

.flag-icon-at {
  background-image: url(../fonts/flags//4x3/at.svg); }
  .flag-icon-at.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/at.svg); }

.flag-icon-au {
  background-image: url(../fonts/flags//4x3/au.svg); }
  .flag-icon-au.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/au.svg); }

.flag-icon-aw {
  background-image: url(../fonts/flags//4x3/aw.svg); }
  .flag-icon-aw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/aw.svg); }

.flag-icon-ax {
  background-image: url(../fonts/flags//4x3/ax.svg); }
  .flag-icon-ax.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ax.svg); }

.flag-icon-az {
  background-image: url(../fonts/flags//4x3/az.svg); }
  .flag-icon-az.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/az.svg); }

.flag-icon-ba {
  background-image: url(../fonts/flags//4x3/ba.svg); }
  .flag-icon-ba.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ba.svg); }

.flag-icon-bb {
  background-image: url(../fonts/flags//4x3/bb.svg); }
  .flag-icon-bb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bb.svg); }

.flag-icon-bd {
  background-image: url(../fonts/flags//4x3/bd.svg); }
  .flag-icon-bd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bd.svg); }

.flag-icon-be {
  background-image: url(../fonts/flags//4x3/be.svg); }
  .flag-icon-be.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/be.svg); }

.flag-icon-bf {
  background-image: url(../fonts/flags//4x3/bf.svg); }
  .flag-icon-bf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bf.svg); }

.flag-icon-bg {
  background-image: url(../fonts/flags//4x3/bg.svg); }
  .flag-icon-bg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bg.svg); }

.flag-icon-bh {
  background-image: url(../fonts/flags//4x3/bh.svg); }
  .flag-icon-bh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bh.svg); }

.flag-icon-bi {
  background-image: url(../fonts/flags//4x3/bi.svg); }
  .flag-icon-bi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bi.svg); }

.flag-icon-bj {
  background-image: url(../fonts/flags//4x3/bj.svg); }
  .flag-icon-bj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bj.svg); }

.flag-icon-bl {
  background-image: url(../fonts/flags//4x3/bl.svg); }
  .flag-icon-bl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bl.svg); }

.flag-icon-bm {
  background-image: url(../fonts/flags//4x3/bm.svg); }
  .flag-icon-bm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bm.svg); }

.flag-icon-bn {
  background-image: url(../fonts/flags//4x3/bn.svg); }
  .flag-icon-bn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bn.svg); }

.flag-icon-bo {
  background-image: url(../fonts/flags//4x3/bo.svg); }
  .flag-icon-bo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bo.svg); }

.flag-icon-bq {
  background-image: url(../fonts/flags//4x3/bq.svg); }
  .flag-icon-bq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bq.svg); }

.flag-icon-br {
  background-image: url(../fonts/flags//4x3/br.svg); }
  .flag-icon-br.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/br.svg); }

.flag-icon-bs {
  background-image: url(../fonts/flags//4x3/bs.svg); }
  .flag-icon-bs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bs.svg); }

.flag-icon-bt {
  background-image: url(../fonts/flags//4x3/bt.svg); }
  .flag-icon-bt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bt.svg); }

.flag-icon-bv {
  background-image: url(../fonts/flags//4x3/bv.svg); }
  .flag-icon-bv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bv.svg); }

.flag-icon-bw {
  background-image: url(../fonts/flags//4x3/bw.svg); }
  .flag-icon-bw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bw.svg); }

.flag-icon-by {
  background-image: url(../fonts/flags//4x3/by.svg); }
  .flag-icon-by.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/by.svg); }

.flag-icon-bz {
  background-image: url(../fonts/flags//4x3/bz.svg); }
  .flag-icon-bz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/bz.svg); }

.flag-icon-ca {
  background-image: url(../fonts/flags//4x3/ca.svg); }
  .flag-icon-ca.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ca.svg); }

.flag-icon-cc {
  background-image: url(../fonts/flags//4x3/cc.svg); }
  .flag-icon-cc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cc.svg); }

.flag-icon-cd {
  background-image: url(../fonts/flags//4x3/cd.svg); }
  .flag-icon-cd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cd.svg); }

.flag-icon-cf {
  background-image: url(../fonts/flags//4x3/cf.svg); }
  .flag-icon-cf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cf.svg); }

.flag-icon-cg {
  background-image: url(../fonts/flags//4x3/cg.svg); }
  .flag-icon-cg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cg.svg); }

.flag-icon-ch {
  background-image: url(../fonts/flags//4x3/ch.svg); }
  .flag-icon-ch.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ch.svg); }

.flag-icon-ci {
  background-image: url(../fonts/flags//4x3/ci.svg); }
  .flag-icon-ci.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ci.svg); }

.flag-icon-ck {
  background-image: url(../fonts/flags//4x3/ck.svg); }
  .flag-icon-ck.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ck.svg); }

.flag-icon-cl {
  background-image: url(../fonts/flags//4x3/cl.svg); }
  .flag-icon-cl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cl.svg); }

.flag-icon-cm {
  background-image: url(../fonts/flags//4x3/cm.svg); }
  .flag-icon-cm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cm.svg); }

.flag-icon-cn {
  background-image: url(../fonts/flags//4x3/cn.svg); }
  .flag-icon-cn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cn.svg); }

.flag-icon-co {
  background-image: url(../fonts/flags//4x3/co.svg); }
  .flag-icon-co.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/co.svg); }

.flag-icon-cr {
  background-image: url(../fonts/flags//4x3/cr.svg); }
  .flag-icon-cr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cr.svg); }

.flag-icon-cu {
  background-image: url(../fonts/flags//4x3/cu.svg); }
  .flag-icon-cu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cu.svg); }

.flag-icon-cv {
  background-image: url(../fonts/flags//4x3/cv.svg); }
  .flag-icon-cv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cv.svg); }

.flag-icon-cw {
  background-image: url(../fonts/flags//4x3/cw.svg); }
  .flag-icon-cw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cw.svg); }

.flag-icon-cx {
  background-image: url(../fonts/flags//4x3/cx.svg); }
  .flag-icon-cx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cx.svg); }

.flag-icon-cy {
  background-image: url(../fonts/flags//4x3/cy.svg); }
  .flag-icon-cy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cy.svg); }

.flag-icon-cz {
  background-image: url(../fonts/flags//4x3/cz.svg); }
  .flag-icon-cz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/cz.svg); }

.flag-icon-de {
  background-image: url(../fonts/flags//4x3/de.svg); }
  .flag-icon-de.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/de.svg); }

.flag-icon-dj {
  background-image: url(../fonts/flags//4x3/dj.svg); }
  .flag-icon-dj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dj.svg); }

.flag-icon-dk {
  background-image: url(../fonts/flags//4x3/dk.svg); }
  .flag-icon-dk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dk.svg); }

.flag-icon-dm {
  background-image: url(../fonts/flags//4x3/dm.svg); }
  .flag-icon-dm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dm.svg); }

.flag-icon-do {
  background-image: url(../fonts/flags//4x3/do.svg); }
  .flag-icon-do.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/do.svg); }

.flag-icon-dz {
  background-image: url(../fonts/flags//4x3/dz.svg); }
  .flag-icon-dz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/dz.svg); }

.flag-icon-ec {
  background-image: url(../fonts/flags//4x3/ec.svg); }
  .flag-icon-ec.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ec.svg); }

.flag-icon-ee {
  background-image: url(../fonts/flags//4x3/ee.svg); }
  .flag-icon-ee.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ee.svg); }

.flag-icon-eg {
  background-image: url(../fonts/flags//4x3/eg.svg); }
  .flag-icon-eg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eg.svg); }

.flag-icon-eh {
  background-image: url(../fonts/flags//4x3/eh.svg); }
  .flag-icon-eh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eh.svg); }

.flag-icon-er {
  background-image: url(../fonts/flags//4x3/er.svg); }
  .flag-icon-er.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/er.svg); }

.flag-icon-es {
  background-image: url(../fonts/flags//4x3/es.svg); }
  .flag-icon-es.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es.svg); }

.flag-icon-et {
  background-image: url(../fonts/flags//4x3/et.svg); }
  .flag-icon-et.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/et.svg); }

.flag-icon-fi {
  background-image: url(../fonts/flags//4x3/fi.svg); }
  .flag-icon-fi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fi.svg); }

.flag-icon-fj {
  background-image: url(../fonts/flags//4x3/fj.svg); }
  .flag-icon-fj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fj.svg); }

.flag-icon-fk {
  background-image: url(../fonts/flags//4x3/fk.svg); }
  .flag-icon-fk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fk.svg); }

.flag-icon-fm {
  background-image: url(../fonts/flags//4x3/fm.svg); }
  .flag-icon-fm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fm.svg); }

.flag-icon-fo {
  background-image: url(../fonts/flags//4x3/fo.svg); }
  .flag-icon-fo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fo.svg); }

.flag-icon-fr {
  background-image: url(../fonts/flags//4x3/fr.svg); }
  .flag-icon-fr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/fr.svg); }

.flag-icon-ga {
  background-image: url(../fonts/flags//4x3/ga.svg); }
  .flag-icon-ga.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ga.svg); }

.flag-icon-gb {
  background-image: url(../fonts/flags//4x3/gb.svg); }
  .flag-icon-gb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb.svg); }

.flag-icon-gd {
  background-image: url(../fonts/flags//4x3/gd.svg); }
  .flag-icon-gd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gd.svg); }

.flag-icon-ge {
  background-image: url(../fonts/flags//4x3/ge.svg); }
  .flag-icon-ge.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ge.svg); }

.flag-icon-gf {
  background-image: url(../fonts/flags//4x3/gf.svg); }
  .flag-icon-gf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gf.svg); }

.flag-icon-gg {
  background-image: url(../fonts/flags//4x3/gg.svg); }
  .flag-icon-gg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gg.svg); }

.flag-icon-gh {
  background-image: url(../fonts/flags//4x3/gh.svg); }
  .flag-icon-gh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gh.svg); }

.flag-icon-gi {
  background-image: url(../fonts/flags//4x3/gi.svg); }
  .flag-icon-gi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gi.svg); }

.flag-icon-gl {
  background-image: url(../fonts/flags//4x3/gl.svg); }
  .flag-icon-gl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gl.svg); }

.flag-icon-gm {
  background-image: url(../fonts/flags//4x3/gm.svg); }
  .flag-icon-gm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gm.svg); }

.flag-icon-gn {
  background-image: url(../fonts/flags//4x3/gn.svg); }
  .flag-icon-gn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gn.svg); }

.flag-icon-gp {
  background-image: url(../fonts/flags//4x3/gp.svg); }
  .flag-icon-gp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gp.svg); }

.flag-icon-gq {
  background-image: url(../fonts/flags//4x3/gq.svg); }
  .flag-icon-gq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gq.svg); }

.flag-icon-gr {
  background-image: url(../fonts/flags//4x3/gr.svg); }
  .flag-icon-gr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gr.svg); }

.flag-icon-gs {
  background-image: url(../fonts/flags//4x3/gs.svg); }
  .flag-icon-gs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gs.svg); }

.flag-icon-gt {
  background-image: url(../fonts/flags//4x3/gt.svg); }
  .flag-icon-gt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gt.svg); }

.flag-icon-gu {
  background-image: url(../fonts/flags//4x3/gu.svg); }
  .flag-icon-gu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gu.svg); }

.flag-icon-gw {
  background-image: url(../fonts/flags//4x3/gw.svg); }
  .flag-icon-gw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gw.svg); }

.flag-icon-gy {
  background-image: url(../fonts/flags//4x3/gy.svg); }
  .flag-icon-gy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gy.svg); }

.flag-icon-hk {
  background-image: url(../fonts/flags//4x3/hk.svg); }
  .flag-icon-hk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hk.svg); }

.flag-icon-hm {
  background-image: url(../fonts/flags//4x3/hm.svg); }
  .flag-icon-hm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hm.svg); }

.flag-icon-hn {
  background-image: url(../fonts/flags//4x3/hn.svg); }
  .flag-icon-hn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hn.svg); }

.flag-icon-hr {
  background-image: url(../fonts/flags//4x3/hr.svg); }
  .flag-icon-hr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hr.svg); }

.flag-icon-ht {
  background-image: url(../fonts/flags//4x3/ht.svg); }
  .flag-icon-ht.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ht.svg); }

.flag-icon-hu {
  background-image: url(../fonts/flags//4x3/hu.svg); }
  .flag-icon-hu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/hu.svg); }

.flag-icon-id {
  background-image: url(../fonts/flags//4x3/id.svg); }
  .flag-icon-id.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/id.svg); }

.flag-icon-ie {
  background-image: url(../fonts/flags//4x3/ie.svg); }
  .flag-icon-ie.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ie.svg); }

.flag-icon-il {
  background-image: url(../fonts/flags//4x3/il.svg); }
  .flag-icon-il.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/il.svg); }

.flag-icon-im {
  background-image: url(../fonts/flags//4x3/im.svg); }
  .flag-icon-im.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/im.svg); }

.flag-icon-in {
  background-image: url(../fonts/flags//4x3/in.svg); }
  .flag-icon-in.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/in.svg); }

.flag-icon-io {
  background-image: url(../fonts/flags//4x3/io.svg); }
  .flag-icon-io.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/io.svg); }

.flag-icon-iq {
  background-image: url(../fonts/flags//4x3/iq.svg); }
  .flag-icon-iq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/iq.svg); }

.flag-icon-ir {
  background-image: url(../fonts/flags//4x3/ir.svg); }
  .flag-icon-ir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ir.svg); }

.flag-icon-is {
  background-image: url(../fonts/flags//4x3/is.svg); }
  .flag-icon-is.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/is.svg); }

.flag-icon-it {
  background-image: url(../fonts/flags//4x3/it.svg); }
  .flag-icon-it.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/it.svg); }

.flag-icon-je {
  background-image: url(../fonts/flags//4x3/je.svg); }
  .flag-icon-je.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/je.svg); }

.flag-icon-jm {
  background-image: url(../fonts/flags//4x3/jm.svg); }
  .flag-icon-jm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jm.svg); }

.flag-icon-jo {
  background-image: url(../fonts/flags//4x3/jo.svg); }
  .flag-icon-jo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jo.svg); }

.flag-icon-jp {
  background-image: url(../fonts/flags//4x3/jp.svg); }
  .flag-icon-jp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/jp.svg); }

.flag-icon-ke {
  background-image: url(../fonts/flags//4x3/ke.svg); }
  .flag-icon-ke.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ke.svg); }

.flag-icon-kg {
  background-image: url(../fonts/flags//4x3/kg.svg); }
  .flag-icon-kg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kg.svg); }

.flag-icon-kh {
  background-image: url(../fonts/flags//4x3/kh.svg); }
  .flag-icon-kh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kh.svg); }

.flag-icon-ki {
  background-image: url(../fonts/flags//4x3/ki.svg); }
  .flag-icon-ki.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ki.svg); }

.flag-icon-km {
  background-image: url(../fonts/flags//4x3/km.svg); }
  .flag-icon-km.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/km.svg); }

.flag-icon-kn {
  background-image: url(../fonts/flags//4x3/kn.svg); }
  .flag-icon-kn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kn.svg); }

.flag-icon-kp {
  background-image: url(../fonts/flags//4x3/kp.svg); }
  .flag-icon-kp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kp.svg); }

.flag-icon-kr {
  background-image: url(../fonts/flags//4x3/kr.svg); }
  .flag-icon-kr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kr.svg); }

.flag-icon-kw {
  background-image: url(../fonts/flags//4x3/kw.svg); }
  .flag-icon-kw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kw.svg); }

.flag-icon-ky {
  background-image: url(../fonts/flags//4x3/ky.svg); }
  .flag-icon-ky.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ky.svg); }

.flag-icon-kz {
  background-image: url(../fonts/flags//4x3/kz.svg); }
  .flag-icon-kz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/kz.svg); }

.flag-icon-la {
  background-image: url(../fonts/flags//4x3/la.svg); }
  .flag-icon-la.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/la.svg); }

.flag-icon-lb {
  background-image: url(../fonts/flags//4x3/lb.svg); }
  .flag-icon-lb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lb.svg); }

.flag-icon-lc {
  background-image: url(../fonts/flags//4x3/lc.svg); }
  .flag-icon-lc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lc.svg); }

.flag-icon-li {
  background-image: url(../fonts/flags//4x3/li.svg); }
  .flag-icon-li.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/li.svg); }

.flag-icon-lk {
  background-image: url(../fonts/flags//4x3/lk.svg); }
  .flag-icon-lk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lk.svg); }

.flag-icon-lr {
  background-image: url(../fonts/flags//4x3/lr.svg); }
  .flag-icon-lr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lr.svg); }

.flag-icon-ls {
  background-image: url(../fonts/flags//4x3/ls.svg); }
  .flag-icon-ls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ls.svg); }

.flag-icon-lt {
  background-image: url(../fonts/flags//4x3/lt.svg); }
  .flag-icon-lt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lt.svg); }

.flag-icon-lu {
  background-image: url(../fonts/flags//4x3/lu.svg); }
  .flag-icon-lu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lu.svg); }

.flag-icon-lv {
  background-image: url(../fonts/flags//4x3/lv.svg); }
  .flag-icon-lv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/lv.svg); }

.flag-icon-ly {
  background-image: url(../fonts/flags//4x3/ly.svg); }
  .flag-icon-ly.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ly.svg); }

.flag-icon-ma {
  background-image: url(../fonts/flags//4x3/ma.svg); }
  .flag-icon-ma.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ma.svg); }

.flag-icon-mc {
  background-image: url(../fonts/flags//4x3/mc.svg); }
  .flag-icon-mc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mc.svg); }

.flag-icon-md {
  background-image: url(../fonts/flags//4x3/md.svg); }
  .flag-icon-md.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/md.svg); }

.flag-icon-me {
  background-image: url(../fonts/flags//4x3/me.svg); }
  .flag-icon-me.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/me.svg); }

.flag-icon-mf {
  background-image: url(../fonts/flags//4x3/mf.svg); }
  .flag-icon-mf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mf.svg); }

.flag-icon-mg {
  background-image: url(../fonts/flags//4x3/mg.svg); }
  .flag-icon-mg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mg.svg); }

.flag-icon-mh {
  background-image: url(../fonts/flags//4x3/mh.svg); }
  .flag-icon-mh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mh.svg); }

.flag-icon-mk {
  background-image: url(../fonts/flags//4x3/mk.svg); }
  .flag-icon-mk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mk.svg); }

.flag-icon-ml {
  background-image: url(../fonts/flags//4x3/ml.svg); }
  .flag-icon-ml.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ml.svg); }

.flag-icon-mm {
  background-image: url(../fonts/flags//4x3/mm.svg); }
  .flag-icon-mm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mm.svg); }

.flag-icon-mn {
  background-image: url(../fonts/flags//4x3/mn.svg); }
  .flag-icon-mn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mn.svg); }

.flag-icon-mo {
  background-image: url(../fonts/flags//4x3/mo.svg); }
  .flag-icon-mo.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mo.svg); }

.flag-icon-mp {
  background-image: url(../fonts/flags//4x3/mp.svg); }
  .flag-icon-mp.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mp.svg); }

.flag-icon-mq {
  background-image: url(../fonts/flags//4x3/mq.svg); }
  .flag-icon-mq.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mq.svg); }

.flag-icon-mr {
  background-image: url(../fonts/flags//4x3/mr.svg); }
  .flag-icon-mr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mr.svg); }

.flag-icon-ms {
  background-image: url(../fonts/flags//4x3/ms.svg); }
  .flag-icon-ms.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ms.svg); }

.flag-icon-mt {
  background-image: url(../fonts/flags//4x3/mt.svg); }
  .flag-icon-mt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mt.svg); }

.flag-icon-mu {
  background-image: url(../fonts/flags//4x3/mu.svg); }
  .flag-icon-mu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mu.svg); }

.flag-icon-mv {
  background-image: url(../fonts/flags//4x3/mv.svg); }
  .flag-icon-mv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mv.svg); }

.flag-icon-mw {
  background-image: url(../fonts/flags//4x3/mw.svg); }
  .flag-icon-mw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mw.svg); }

.flag-icon-mx {
  background-image: url(../fonts/flags//4x3/mx.svg); }
  .flag-icon-mx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mx.svg); }

.flag-icon-my {
  background-image: url(../fonts/flags//4x3/my.svg); }
  .flag-icon-my.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/my.svg); }

.flag-icon-mz {
  background-image: url(../fonts/flags//4x3/mz.svg); }
  .flag-icon-mz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/mz.svg); }

.flag-icon-na {
  background-image: url(../fonts/flags//4x3/na.svg); }
  .flag-icon-na.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/na.svg); }

.flag-icon-nc {
  background-image: url(../fonts/flags//4x3/nc.svg); }
  .flag-icon-nc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nc.svg); }

.flag-icon-ne {
  background-image: url(../fonts/flags//4x3/ne.svg); }
  .flag-icon-ne.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ne.svg); }

.flag-icon-nf {
  background-image: url(../fonts/flags//4x3/nf.svg); }
  .flag-icon-nf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nf.svg); }

.flag-icon-ng {
  background-image: url(../fonts/flags//4x3/ng.svg); }
  .flag-icon-ng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ng.svg); }

.flag-icon-ni {
  background-image: url(../fonts/flags//4x3/ni.svg); }
  .flag-icon-ni.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ni.svg); }

.flag-icon-nl {
  background-image: url(../fonts/flags//4x3/nl.svg); }
  .flag-icon-nl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nl.svg); }

.flag-icon-no {
  background-image: url(../fonts/flags//4x3/no.svg); }
  .flag-icon-no.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/no.svg); }

.flag-icon-np {
  background-image: url(../fonts/flags//4x3/np.svg); }
  .flag-icon-np.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/np.svg); }

.flag-icon-nr {
  background-image: url(../fonts/flags//4x3/nr.svg); }
  .flag-icon-nr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nr.svg); }

.flag-icon-nu {
  background-image: url(../fonts/flags//4x3/nu.svg); }
  .flag-icon-nu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nu.svg); }

.flag-icon-nz {
  background-image: url(../fonts/flags//4x3/nz.svg); }
  .flag-icon-nz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/nz.svg); }

.flag-icon-om {
  background-image: url(../fonts/flags//4x3/om.svg); }
  .flag-icon-om.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/om.svg); }

.flag-icon-pa {
  background-image: url(../fonts/flags//4x3/pa.svg); }
  .flag-icon-pa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pa.svg); }

.flag-icon-pe {
  background-image: url(../fonts/flags//4x3/pe.svg); }
  .flag-icon-pe.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pe.svg); }

.flag-icon-pf {
  background-image: url(../fonts/flags//4x3/pf.svg); }
  .flag-icon-pf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pf.svg); }

.flag-icon-pg {
  background-image: url(../fonts/flags//4x3/pg.svg); }
  .flag-icon-pg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pg.svg); }

.flag-icon-ph {
  background-image: url(../fonts/flags//4x3/ph.svg); }
  .flag-icon-ph.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ph.svg); }

.flag-icon-pk {
  background-image: url(../fonts/flags//4x3/pk.svg); }
  .flag-icon-pk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pk.svg); }

.flag-icon-pl {
  background-image: url(../fonts/flags//4x3/pl.svg); }
  .flag-icon-pl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pl.svg); }

.flag-icon-pm {
  background-image: url(../fonts/flags//4x3/pm.svg); }
  .flag-icon-pm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pm.svg); }

.flag-icon-pn {
  background-image: url(../fonts/flags//4x3/pn.svg); }
  .flag-icon-pn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pn.svg); }

.flag-icon-pr {
  background-image: url(../fonts/flags//4x3/pr.svg); }
  .flag-icon-pr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pr.svg); }

.flag-icon-ps {
  background-image: url(../fonts/flags//4x3/ps.svg); }
  .flag-icon-ps.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ps.svg); }

.flag-icon-pt {
  background-image: url(../fonts/flags//4x3/pt.svg); }
  .flag-icon-pt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pt.svg); }

.flag-icon-pw {
  background-image: url(../fonts/flags//4x3/pw.svg); }
  .flag-icon-pw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/pw.svg); }

.flag-icon-py {
  background-image: url(../fonts/flags//4x3/py.svg); }
  .flag-icon-py.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/py.svg); }

.flag-icon-qa {
  background-image: url(../fonts/flags//4x3/qa.svg); }
  .flag-icon-qa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/qa.svg); }

.flag-icon-re {
  background-image: url(../fonts/flags//4x3/re.svg); }
  .flag-icon-re.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/re.svg); }

.flag-icon-ro {
  background-image: url(../fonts/flags//4x3/ro.svg); }
  .flag-icon-ro.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ro.svg); }

.flag-icon-rs {
  background-image: url(../fonts/flags//4x3/rs.svg); }
  .flag-icon-rs.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rs.svg); }

.flag-icon-ru {
  background-image: url(../fonts/flags//4x3/ru.svg); }
  .flag-icon-ru.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ru.svg); }

.flag-icon-rw {
  background-image: url(../fonts/flags//4x3/rw.svg); }
  .flag-icon-rw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/rw.svg); }

.flag-icon-sa {
  background-image: url(../fonts/flags//4x3/sa.svg); }
  .flag-icon-sa.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sa.svg); }

.flag-icon-sb {
  background-image: url(../fonts/flags//4x3/sb.svg); }
  .flag-icon-sb.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sb.svg); }

.flag-icon-sc {
  background-image: url(../fonts/flags//4x3/sc.svg); }
  .flag-icon-sc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sc.svg); }

.flag-icon-sd {
  background-image: url(../fonts/flags//4x3/sd.svg); }
  .flag-icon-sd.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sd.svg); }

.flag-icon-se {
  background-image: url(../fonts/flags//4x3/se.svg); }
  .flag-icon-se.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/se.svg); }

.flag-icon-sg {
  background-image: url(../fonts/flags//4x3/sg.svg); }
  .flag-icon-sg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sg.svg); }

.flag-icon-sh {
  background-image: url(../fonts/flags//4x3/sh.svg); }
  .flag-icon-sh.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sh.svg); }

.flag-icon-si {
  background-image: url(../fonts/flags//4x3/si.svg); }
  .flag-icon-si.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/si.svg); }

.flag-icon-sj {
  background-image: url(../fonts/flags//4x3/sj.svg); }
  .flag-icon-sj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sj.svg); }

.flag-icon-sk {
  background-image: url(../fonts/flags//4x3/sk.svg); }
  .flag-icon-sk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sk.svg); }

.flag-icon-sl {
  background-image: url(../fonts/flags//4x3/sl.svg); }
  .flag-icon-sl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sl.svg); }

.flag-icon-sm {
  background-image: url(../fonts/flags//4x3/sm.svg); }
  .flag-icon-sm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sm.svg); }

.flag-icon-sn {
  background-image: url(../fonts/flags//4x3/sn.svg); }
  .flag-icon-sn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sn.svg); }

.flag-icon-so {
  background-image: url(../fonts/flags//4x3/so.svg); }
  .flag-icon-so.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/so.svg); }

.flag-icon-sr {
  background-image: url(../fonts/flags//4x3/sr.svg); }
  .flag-icon-sr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sr.svg); }

.flag-icon-ss {
  background-image: url(../fonts/flags//4x3/ss.svg); }
  .flag-icon-ss.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ss.svg); }

.flag-icon-st {
  background-image: url(../fonts/flags//4x3/st.svg); }
  .flag-icon-st.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/st.svg); }

.flag-icon-sv {
  background-image: url(../fonts/flags//4x3/sv.svg); }
  .flag-icon-sv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sv.svg); }

.flag-icon-sx {
  background-image: url(../fonts/flags//4x3/sx.svg); }
  .flag-icon-sx.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sx.svg); }

.flag-icon-sy {
  background-image: url(../fonts/flags//4x3/sy.svg); }
  .flag-icon-sy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sy.svg); }

.flag-icon-sz {
  background-image: url(../fonts/flags//4x3/sz.svg); }
  .flag-icon-sz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/sz.svg); }

.flag-icon-tc {
  background-image: url(../fonts/flags//4x3/tc.svg); }
  .flag-icon-tc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tc.svg); }

.flag-icon-td {
  background-image: url(../fonts/flags//4x3/td.svg); }
  .flag-icon-td.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/td.svg); }

.flag-icon-tf {
  background-image: url(../fonts/flags//4x3/tf.svg); }
  .flag-icon-tf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tf.svg); }

.flag-icon-tg {
  background-image: url(../fonts/flags//4x3/tg.svg); }
  .flag-icon-tg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tg.svg); }

.flag-icon-th {
  background-image: url(../fonts/flags//4x3/th.svg); }
  .flag-icon-th.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/th.svg); }

.flag-icon-tj {
  background-image: url(../fonts/flags//4x3/tj.svg); }
  .flag-icon-tj.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tj.svg); }

.flag-icon-tk {
  background-image: url(../fonts/flags//4x3/tk.svg); }
  .flag-icon-tk.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tk.svg); }

.flag-icon-tl {
  background-image: url(../fonts/flags//4x3/tl.svg); }
  .flag-icon-tl.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tl.svg); }

.flag-icon-tm {
  background-image: url(../fonts/flags//4x3/tm.svg); }
  .flag-icon-tm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tm.svg); }

.flag-icon-tn {
  background-image: url(../fonts/flags//4x3/tn.svg); }
  .flag-icon-tn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tn.svg); }

.flag-icon-to {
  background-image: url(../fonts/flags//4x3/to.svg); }
  .flag-icon-to.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/to.svg); }

.flag-icon-tr {
  background-image: url(../fonts/flags//4x3/tr.svg); }
  .flag-icon-tr.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tr.svg); }

.flag-icon-tt {
  background-image: url(../fonts/flags//4x3/tt.svg); }
  .flag-icon-tt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tt.svg); }

.flag-icon-tv {
  background-image: url(../fonts/flags//4x3/tv.svg); }
  .flag-icon-tv.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tv.svg); }

.flag-icon-tw {
  background-image: url(../fonts/flags//4x3/tw.svg); }
  .flag-icon-tw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tw.svg); }

.flag-icon-tz {
  background-image: url(../fonts/flags//4x3/tz.svg); }
  .flag-icon-tz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/tz.svg); }

.flag-icon-ua {
  background-image: url(../fonts/flags//4x3/ua.svg); }
  .flag-icon-ua.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ua.svg); }

.flag-icon-ug {
  background-image: url(../fonts/flags//4x3/ug.svg); }
  .flag-icon-ug.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ug.svg); }

.flag-icon-um {
  background-image: url(../fonts/flags//4x3/um.svg); }
  .flag-icon-um.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/um.svg); }

.flag-icon-us {
  background-image: url(../fonts/flags//4x3/us.svg); }
  .flag-icon-us.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/us.svg); }

.flag-icon-uy {
  background-image: url(../fonts/flags//4x3/uy.svg); }
  .flag-icon-uy.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uy.svg); }

.flag-icon-uz {
  background-image: url(../fonts/flags//4x3/uz.svg); }
  .flag-icon-uz.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/uz.svg); }

.flag-icon-va {
  background-image: url(../fonts/flags//4x3/va.svg); }
  .flag-icon-va.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/va.svg); }

.flag-icon-vc {
  background-image: url(../fonts/flags//4x3/vc.svg); }
  .flag-icon-vc.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vc.svg); }

.flag-icon-ve {
  background-image: url(../fonts/flags//4x3/ve.svg); }
  .flag-icon-ve.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ve.svg); }

.flag-icon-vg {
  background-image: url(../fonts/flags//4x3/vg.svg); }
  .flag-icon-vg.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vg.svg); }

.flag-icon-vi {
  background-image: url(../fonts/flags//4x3/vi.svg); }
  .flag-icon-vi.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vi.svg); }

.flag-icon-vn {
  background-image: url(../fonts/flags//4x3/vn.svg); }
  .flag-icon-vn.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vn.svg); }

.flag-icon-vu {
  background-image: url(../fonts/flags//4x3/vu.svg); }
  .flag-icon-vu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/vu.svg); }

.flag-icon-wf {
  background-image: url(../fonts/flags//4x3/wf.svg); }
  .flag-icon-wf.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/wf.svg); }

.flag-icon-ws {
  background-image: url(../fonts/flags//4x3/ws.svg); }
  .flag-icon-ws.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ws.svg); }

.flag-icon-ye {
  background-image: url(../fonts/flags//4x3/ye.svg); }
  .flag-icon-ye.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/ye.svg); }

.flag-icon-yt {
  background-image: url(../fonts/flags//4x3/yt.svg); }
  .flag-icon-yt.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/yt.svg); }

.flag-icon-za {
  background-image: url(../fonts/flags//4x3/za.svg); }
  .flag-icon-za.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/za.svg); }

.flag-icon-zm {
  background-image: url(../fonts/flags//4x3/zm.svg); }
  .flag-icon-zm.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zm.svg); }

.flag-icon-zw {
  background-image: url(../fonts/flags//4x3/zw.svg); }
  .flag-icon-zw.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/zw.svg); }

.flag-icon-es-ct {
  background-image: url(../fonts/flags//4x3/es-ct.svg); }
  .flag-icon-es-ct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/es-ct.svg); }

.flag-icon-eu {
  background-image: url(../fonts/flags//4x3/eu.svg); }
  .flag-icon-eu.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/eu.svg); }

.flag-icon-gb-eng {
  background-image: url(../fonts/flags//4x3/gb-eng.svg); }
  .flag-icon-gb-eng.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-eng.svg); }

.flag-icon-gb-nir {
  background-image: url(../fonts/flags//4x3/gb-nir.svg); }
  .flag-icon-gb-nir.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-nir.svg); }

.flag-icon-gb-sct {
  background-image: url(../fonts/flags//4x3/gb-sct.svg); }
  .flag-icon-gb-sct.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-sct.svg); }

.flag-icon-gb-wls {
  background-image: url(../fonts/flags//4x3/gb-wls.svg); }
  .flag-icon-gb-wls.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/gb-wls.svg); }

.flag-icon-un {
  background-image: url(../fonts/flags//4x3/un.svg); }
  .flag-icon-un.flag-icon-squared {
    background-image: url(../fonts/flags//1x1/un.svg); }

.container.container-primary {
  width: 100%;
  max-width: 1824px;
  padding: 0 24px;
  margin: 0 auto; }
  @media (min-width: 1024px) {
    .container.container-primary {
      padding: 0 48px; } }

.toast-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }
  .toast-messages .alert {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    text-align: center; }
    .toast-messages .alert.show {
      display: block; }
    .toast-messages .alert:not(.alert-dismissible) {
      animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
    .toast-messages .alert.alert-dismissible {
      animation: fadeIn 200ms;
      padding-right: 50px; }
      .toast-messages .alert.alert-dismissible .close {
        padding: 12px; }

.alert {
  border-radius: 0px;
  padding: 12px; }
  .alert-success {
    background: #e8f4f0;
    border-color: #e8f4f0;
    color: #005338; }
    .alert-success a, .alert-success a:hover {
      color: #002015; }
    .alert-success .close {
      color: #005338; }
  .alert-warning {
    background: #fcf8e9;
    border-color: #fcf8e9;
    color: #997a00; }
    .alert-warning a, .alert-warning a:hover {
      color: #665200; }
    .alert-warning .close {
      color: #997a00; }
  .alert-danger {
    background: white;
    border-color: white;
    color: #941c21; }
    .alert-danger a, .alert-danger a:hover {
      color: #691418; }
    .alert-danger .close {
      color: #941c21; }
  .alert-info {
    background: #d3e6f6;
    border-color: #d3e6f6;
    color: #0a355a; }
    .alert-info a, .alert-info a:hover {
      color: #051a2c; }
    .alert-info .close {
      color: #0a355a; }
  .alert-dismissible {
    padding-right: calc(24px + 12px); }
  .alert .close {
    padding: 12px;
    line-height: 22px; }
    .alert .close:before {
      position: static;
      transform: none;
      margin: 0;
      font-size: 12px; }

.badge-product-container {
  z-index: 1;
  margin: 1px;
  display: flex;
  gap: 6px;
  align-items: flex-start;
  pointer-events: none;
  top: 6px;
  left: 6px;
  min-width: 91px; }
  .badge-product-container.badge-image-over {
    position: absolute;
    flex-direction: column; }
    .product-detail .badge-product-container.badge-image-over {
      position: static;
      flex-direction: row; }

.badge-product {
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0;
  background: #FFF;
  border: 1px solid;
  color: #000;
  margin: 0;
  padding: 3px 12px;
  text-align: center;
  width: 100%; }
  @media (min-width: 1024px) {
    .badge-product {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  .product-detail .badge-product {
    color: #FFF;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-align: center;
    width: unset;
    font-size: 14px;
    margin: 0;
    line-height: 14px;
    border: 1px solid #000;
    padding: 4px 16px;
    border-radius: 3px;
    background: #000; }
    .product-detail .badge-product.badge_limitedEdition, .product-detail .badge-product.badge_backInStock, .product-detail .badge-product.badge_sale, .product-detail .badge-product.badge_new, .product-detail .badge-product.badge_specialEdition, .product-detail .badge-product.badge_bestSeller {
      color: #FFF; }
    .product-detail .badge-product.badge_light {
      color: #000;
      background-color: #FFF; }

.badge-product.badge_limitedEdition {
  color: #818C82;
  border-color: #818C82; }

.badge-product.badge_backInStock {
  color: #25497C;
  border-color: #25497C; }

.badge-product.badge_sale {
  color: #BF242B;
  border-color: #BF242B; }

.badge-product.badge_new {
  color: #000;
  border-color: #000; }

.badge-product.badge_specialEdition {
  color: #B1873D;
  border-color: #B1873D; }

.badge-product.badge_bestSeller {
  color: #000;
  border-color: #000; }

.badge-product.badge_onlineExclusive {
  color: #99FF00;
  background-color: #000;
  border-color: #F8F8F8;
  /* Preserve existing PDP border color. Looks wrong, when != black.
    TODO: Remove this override when refactoring badges */ }
  .product-detail .badge-product.badge_onlineExclusive {
    border-color: #000; }

.blog-tile {
  display: block;
  height: 100%;
  position: relative; }
  .blog-tile .blog-tile-image {
    width: 100%; }
  .blog-tile .blog-tile-content {
    bottom: 0;
    color: #FFF;
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    padding: 24px;
    position: absolute;
    width: 100%; }
    .blog-tile .blog-tile-content::before, .blog-tile .blog-tile-content::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      transition: all 200ms; }
    .blog-tile .blog-tile-content::before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 11.5%, rgba(0, 0, 0, 0.17) 51%, rgba(0, 0, 0, 0.6) 80%);
      opacity: 1; }
    .blog-tile .blog-tile-content::after {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 80%, rgba(0, 0, 0, 0.6) 80%);
      opacity: 0; }
    @media (min-width: 769px) {
      .blog-tile .blog-tile-content:hover::before {
        opacity: 0; }
      .blog-tile .blog-tile-content:hover::after {
        opacity: 1; } }
    .blog-tile .blog-tile-content .blog-tile-header {
      display: flex;
      flex-basis: 100%;
      height: fit-content; }
    .blog-tile .blog-tile-content .blog-tile-text {
      flex-basis: 100%;
      margin-bottom: 0;
      margin-top: auto; }
    .blog-tile .blog-tile-content .blog-tile-title {
      margin-bottom: 0;
      position: relative; }
    .blog-tile .blog-tile-content .blog-flag-icon {
      margin-left: auto; }
    .blog-tile .blog-tile-content .blog-tile-title,
    .blog-tile .blog-tile-content .blog-tile-folder,
    .blog-tile .blog-tile-content .blog-tile-excerpt,
    .blog-tile .blog-tile-content .blog-flag-icon {
      z-index: 2; }
    .blog-tile .blog-tile-content .blog-tile-folder,
    .blog-tile .blog-tile-content .blog-tile-excerpt {
      bottom: 0;
      max-width: calc(100% - (12px * 4));
      padding-bottom: 24px;
      position: absolute;
      transition: opacity 200ms; }
    .blog-tile .blog-tile-content .blog-tile-folder {
      opacity: 1; }
    .blog-tile .blog-tile-content .blog-tile-excerpt {
      opacity: 0; }
  @media (min-width: 769px) {
    .blog-tile:hover .blog-tile-content:has(.blog-tile-excerpt) .blog-tile-excerpt {
      opacity: 1; }
    .blog-tile:hover .blog-tile-content:has(.blog-tile-excerpt) .blog-tile-folder {
      opacity: 0; } }

.blog.page-designer .blog-title-container {
  display: flex;
  justify-content: left;
  align-items: left; }
  .blog.page-designer .blog-title-container .page-header {
    padding-bottom: 0px; }

.blog.page-designer .blog-title {
  max-width: 600px;
  margin-bottom: 24px; }

.blog.page-designer .blog-detail-header {
  width: 696px;
  padding: 0 24px; }
  .blog.page-designer .blog-detail-header .page-header-subtitle {
    display: flex; }
  .blog.page-designer .blog-detail-header.text-center .page-header-subtitle {
    justify-content: center; }
  .blog.page-designer .blog-detail-header .page-header-title {
    margin-top: 10px;
    line-height: 100%; }
  .blog.page-designer .blog-detail-header .blog-category {
    color: #595959; }
    .blog.page-designer .blog-detail-header .blog-category ~ .blog-creation-date::before {
      content: "|";
      color: #595959;
      padding-left: 10px;
      padding-right: 10px; }
  .blog.page-designer .blog-detail-header .blog-creation-date {
    color: #595959; }
  .blog.page-designer .blog-detail-header .page-header-author {
    margin-top: 10px;
    color: #595959; }

.blog.page-designer .editorial-container {
  max-width: 696px;
  padding-left: 24px;
  padding-right: 24px; }
  .blog.page-designer .editorial-container .editorial-content {
    padding-top: 24px; }
    .blog.page-designer .editorial-container .editorial-content img {
      width: 100%; }
  .blog.page-designer .editorial-container .page-header-author {
    width: 100%;
    padding-top: 24px;
    border-top: 1px solid #D6D6D6;
    font-size: 16px;
    display: block;
    color: #2B2B2A;
    line-height: 100%;
    padding-bottom: 12px; }
  .blog.page-designer .editorial-container .editorial-date {
    display: none; }

.blog .blog-body:has(.grid) {
  padding-top: 0; }

.blog .grid .grid-items {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 12px;
  padding: 0; }
  @media (min-width: 1024px) {
    .blog .grid .grid-items {
      gap: 48px 12px;
      padding: 48px 0 72px; } }

.blog .grid .grid-items .item {
  flex: 0 0 100%; }
  @media (min-width: 544px) and (max-width: 768.98px) {
    .blog .grid .grid-items .item {
      flex-basis: calc((100% - 12px) / 2); } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .blog .grid .grid-items .item {
      flex-basis: calc((100% - (12px * 2)) / 3); } }
  @media (min-width: 1024px) {
    .blog .grid .grid-items .item {
      flex-basis: calc((100% - (12px * 3)) / 4); } }

.blog .blog-header {
  position: relative;
  display: block; }
  .blog .blog-header .blog-image ~ .blog-header-content {
    position: absolute; }
  .blog .blog-header .blog-header-content {
    align-content: end;
    bottom: 0;
    height: 100%;
    padding: 48px 0;
    width: 100dvw; }
    .blog .blog-header .blog-header-content.light {
      color: #000; }
      .blog .blog-header .blog-header-content.light .blog-filter {
        border-color: #D6D6D6;
        color: #000; }
        .blog .blog-header .blog-header-content.light .blog-filter a {
          color: #000; }
    .blog .blog-header .blog-header-content.dark {
      color: #FFF; }
      .blog .blog-header .blog-header-content.dark .blog-filter {
        border-color: #FFF;
        color: #FFF; }
        .blog .blog-header .blog-header-content.dark .blog-filter a {
          color: #FFF; }

.blog .blog-body {
  padding-top: 48px; }
  @media (min-width: 1024px) {
    .blog .blog-body {
      padding-top: 96px; } }
  .blog .blog-body .enhanced-slider-container {
    padding: 0 0 120px; }
    .blog .blog-body .enhanced-slider-container:last-of-type {
      padding-bottom: 96px; }

.blog .blog-image img {
  width: 100dvw; }

.blog .blog-category {
  margin-bottom: 24px; }
  @media (min-width: 1024px) {
    .blog .blog-category {
      margin-bottom: 48px; } }

.blog .blog-filter {
  background-color: transparent;
  margin-bottom: 0; }

.blog select.blog-filter {
  padding: 12px 24px; }

.blog div.blog-filter {
  border: 1px solid #D6D6D6;
  width: fit-content; }
  .blog div.blog-filter .btn {
    border: 0;
    padding: 12px 24px; }
  .blog div.blog-filter .blog-active {
    text-decoration: underline; }

.category-slider-wrapper {
  display: flex;
  padding: 0 48px 24px;
  align-items: flex-start;
  gap: 4px;
  overflow-y: auto; }
  @media (max-width: 1023.98px) {
    .category-slider-wrapper {
      padding-left: 24px; } }
  .category-slider-wrapper a {
    display: flex;
    height: 100%;
    text-decoration: none; }
  .category-slider-wrapper .enhanced-category-link {
    text-decoration: underline; }
  .category-slider-wrapper .slide {
    width: 100%; }
  .category-slider-wrapper .enhanced-category-image {
    aspect-ratio: 1;
    height: 100%;
    object-fit: cover; }
  .category-slider-wrapper .enhanced-category-text {
    padding: 24px 12px;
    display: flex;
    flex-direction: column;
    width: 200px; }
  .category-slider-wrapper .category-slider-container {
    align-self: stretch;
    background: #ECEEE4; }
  .category-slider-wrapper .display-type-0 .enhanced-category-image {
    width: 150px; }
  .category-slider-wrapper .display-type-0 .enhanced-category-text {
    justify-content: space-between; }
  .category-slider-wrapper .display-type-1 .enhanced-category-subtitle,
  .category-slider-wrapper .display-type-1 .enhanced-category-link {
    display: none; }
  .category-slider-wrapper .display-type-1 .enhanced-category-image {
    width: 100px; }
  .category-slider-wrapper .display-type-1 .enhanced-category-text {
    justify-content: center; }
  .category-slider-wrapper .display-type-2 .enhanced-category-image,
  .category-slider-wrapper .display-type-2 .enhanced-category-link {
    display: none; }
  .category-slider-wrapper .display-type-2 .enhanced-category-text {
    justify-content: space-between;
    padding-left: 24px;
    padding-right: 24px; }
  .category-slider-wrapper .display-type-3 {
    background-color: transparent;
    border: 1px solid #D6D6D6;
    border-radius: 3px; }
    .category-slider-wrapper .display-type-3 .enhanced-category-image,
    .category-slider-wrapper .display-type-3 .enhanced-category-subtitle,
    .category-slider-wrapper .display-type-3 .enhanced-category-link {
      display: none; }
    .category-slider-wrapper .display-type-3 .enhanced-category-text {
      justify-content: center;
      padding-left: 24px;
      padding-right: 24px; }
  .category-slider-wrapper .enhanced-category-title,
  .category-slider-wrapper .enhanced-category-subtitle,
  .category-slider-wrapper .enhanced-category-link {
    margin-bottom: 0; }

.athlete-container .athlete-content {
  padding-top: 48px; }
  .athlete-container .athlete-content img {
    width: 100%; }

.athlete-container .page-header-author {
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #D6D6D6;
  font-size: 16px;
  display: block;
  color: #2B2B2A;
  line-height: 100%;
  padding-bottom: 12px; }

.athlete-container .athlete-label {
  color: #8F8F8F;
  line-height: 100%;
  font-size: 16px;
  padding-bottom: 12px; }

.athlete-container .athlete-description {
  font-weight: bold;
  line-height: 100%;
  margin-bottom: 0px; }

.athlete-container .athlete-name {
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  color: #000;
  padding-bottom: 24px;
  border-bottom: 1px solid #D6D6D6; }

.athlete-container .athlete-desc {
  padding-bottom: 24px;
  border-bottom: 1px solid #D6D6D6; }

.athlete-container .athlete-flag {
  padding: 18px 0 12px 0; }

.athlete-container .athlete-flag-icon {
  border: 1px solid #D6D6D6;
  width: 30px;
  height: 100%;
  line-height: inherit; }

.athlete-container .discipline-box {
  display: inline-block;
  background-color: #000;
  margin-right: 10px;
  padding-left: 12px;
  padding-right: 12px;
  border: 1px solid #000;
  font-size: 16px;
  border-radius: 6px;
  margin-bottom: 12px; }

.athlete-container .discipline-box-black {
  color: #FFF; }

.athlete-container .social-icon-container {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  background: #ECEEE4;
  text-align: center;
  display: inline-block;
  margin-top: 24px; }
  .athlete-container .social-icon-container a {
    font-weight: 500; }

.athlete-container .social-icon-left {
  margin-left: 24px; }

.athlete-container .social-icon {
  padding-top: 4px; }

.athlete-container .discipline-box-white {
  background-color: white; }

.athlete-container .discipline-container {
  padding-bottom: 24px;
  padding-top: 24px; }

.athlete-container .athlete-image {
  width: 100%;
  padding-bottom: 24px; }

.athlete-container .athlete-tablet,
.athlete-container .athlete-desktop,
.athlete-container .athlete-hd {
  display: none; }

@media (min-width: 769px) {
  .athlete-container .athlete-mobile,
  .athlete-container .athlete-desktop,
  .athlete-container .athlete-hd {
    display: none; }
  .athlete-container .athlete-tablet {
    display: block; } }

@media (min-width: 1024px) {
  .athlete-container .athlete-mobile,
  .athlete-container .athlete-tablet,
  .athlete-container .athlete-hd {
    display: none; }
  .athlete-container .athlete-desktop {
    display: block; } }

@media (min-width: 1440px) {
  .athlete-container .athlete-desktop {
    display: none; }
  .athlete-container .athlete-hd {
    display: block; } }

.breadcrumb {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin: 0;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0; }
  .breadcrumb li {
    padding: 0; }
  .breadcrumb .breadcrumb-item a {
    color: #0C0A08;
    font-weight: 500;
    text-decoration: none; }
    .breadcrumb .breadcrumb-item a:hover {
      color: #2B2B2A;
      text-decoration: underline; }
  .breadcrumb .breadcrumb-item + .breadcrumb-item {
    display: flex; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
      content: "/";
      color: #0C0A08;
      font-weight: 500;
      padding: 0 6px; }
    .breadcrumb .breadcrumb-item + .breadcrumb-item:last-of-type a {
      color: #2B2B2A; }
  .breadcrumb .breadcrumb-item h1 {
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    margin: 0; }

@media (max-width: 768.98px) {
  .breadcrumb-wrapper {
    padding-top: 24px;
    padding-bottom: 24px; } }

@media (min-width: 769px) {
  .breadcrumb-wrapper {
    padding-top: 48px;
    padding-bottom: 24px; } }

.plp .breadcrumb-wrapper {
  padding-top: 24px;
  padding-bottom: 0; }

.breadcrumb {
  flex-wrap: wrap; }
  .breadcrumb .breadcrumb-item {
    align-items: center; }
    .breadcrumb .breadcrumb-item a,
    .breadcrumb .breadcrumb-item h2 {
      font-family: "archia-medium", sans-serif;
      line-height: 1.5em;
      font-style: normal;
      font-size: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      margin: 0; }
    .breadcrumb .breadcrumb-item.breadcrumb-product h2 {
      font-family: "archia-bold", sans-serif; }
    .breadcrumb .breadcrumb-item .no-link-breadcrumb h2 {
      opacity: .5; }
  .breadcrumb .breadcrumb-item + .breadcrumb-item:before {
    padding: 0 4px;
    content: '>'; }
  .breadcrumb .breadcrumb-item:not(.breadcrumb-product) > * {
    text-decoration: underline; }

.card {
  border: none;
  border-radius: 0px;
  margin-bottom: 12px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px;
  background-color: transparent; }
  .card .card-header {
    background-color: #FFF;
    color: #0C0A08;
    border: 1px solid #D6D6D6;
    border-bottom: none;
    padding: 0;
    flex-basis: auto; }
    .card .card-header .pull-right {
      color: #0C0A08;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 24px;
      padding-top: 17px;
      padding-right: 20px; }
  .card .card-title,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header .checkout-card-header,
  .card h2.card-header {
    text-decoration: none;
    position: relative;
    margin-bottom: 0;
    padding-top: 17px;
    padding-left: 0px;
    padding-right: 20px;
    padding-bottom: 22px; }
  .card .card-header .card-header-custom {
    margin-bottom: 0; }
  .card .card-body {
    background-color: #FFF;
    color: #000;
    padding: 20px;
    border: 1px solid #D6D6D6;
    border-bottom: none;
    border-top: none; }
    .card .card-body:first-child {
      border-top: 1px solid #D6D6D6; }
    .card .card-body:last-child {
      border-bottom: 1px solid #D6D6D6;
      border-radius: 0 0 calc(0px - 1px) calc(0px - 1px); }
  .card .card-header ~ .card-body {
    padding: 0 20px 20px 0px; }
  .card .card-footer {
    background-color: #FFF;
    color: #0C0A08;
    padding: 17px 20px 22px 20px;
    border-left: 1px solid #D6D6D6;
    border-right: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    border-top: 0; }
    .card .card-footer a {
      color: #0C0A08; }
  .card.ghost {
    opacity: 0.5; }
  .card.aside {
    border: 1px solid #D6D6D6;
    border-width: 1px 0; }
    .card.aside .card-header {
      border: none; }
      .card.aside .card-header .card-title {
        font-size: 13px;
        font-weight: bold;
        line-height: 18px;
        padding-top: 20px;
        padding-left: 0;
        padding-right: 20px;
        padding-bottom: 20px; }
    .card.aside .card-body {
      padding: 0;
      border: none;
      z-index: 1; }
    .card.aside .card-footer {
      border: none; }
  .card.no-borders .card-header,
  .card.no-borders .card-title,
  .card.no-borders .card-body,
  .card.no-borders .card-footer {
    border: none;
    padding-left: 0;
    padding-right: 0; }

.card {
  border: none;
  border-radius: 0px;
  margin-bottom: 12px;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 24px; }
  .card .card-header {
    background-color: #FFF;
    color: #0C0A08;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-bottom: none;
    padding: 0;
    flex-basis: auto; }
    .card .card-header .pull-right {
      color: #0C0A08;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 24px;
      padding-top: 17px;
      padding-right: 20px; }
  .card .card-body {
    background-color: #FFF;
    color: #000;
    padding: 20px;
    border-top: none;
    border-right: none;
    border-bottom: 1px solid #D6D6D6;
    border-left: none;
    padding-left: 0px; }
    .card .card-body:first-child {
      border-top: 1px solid #D6D6D6; }
    .card .card-body:last-child {
      border-radius: 0 0 calc(0px - 1px) calc(0px - 1px); }
    .card .card-body .customer-information-block {
      text-align: left; }

.carousel .icon-prev,
.carousel .icon-next {
  background-color: #FFF;
  font-size: 18px;
  padding: 12px;
  color: #000; }

.carousel .icon-prev:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-prev[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .carousel .icon-prev[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .carousel .icon-prev[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-prev.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-prev[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-prev[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .icon-next:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.carousel .icon-next[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .carousel .icon-next[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .carousel .icon-next[class*="btn-"]:not(.title).btn-sm, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .carousel .icon-next[class*="btn-"]:not(.title).btn-sm:before, .carousel .btn-group-sm > .icon-next.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .carousel .icon-next[class*="btn-"]:not(.title) [class*="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) [class^="icon-"],
  .carousel .icon-next[class*="btn-"]:not(.title) .fa {
    display: none; }

.carousel .carousel-control-prev {
  justify-content: flex-start; }

.carousel .carousel-control-next {
  justify-content: flex-end; }

.category-tile {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .category-tile:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .category-tile.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .category-tile.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .category-tile .image-cropper {
    border-radius: 0px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  .category-tile .image-wrapper {
    width: 100%;
    position: relative; }
    .category-tile .image-wrapper .background-image {
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .category-tile .image-wrapper-custom .background-image-tablet,
    .category-tile .image-wrapper-custom .background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .category-tile .image-wrapper-custom .background-image-mobile,
      .category-tile .image-wrapper-custom .background-image-tablet {
        display: none; }
      .category-tile .image-wrapper-custom .background-image-desktop {
        display: block; } }
    .category-tile .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .category-tile .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .category-tile .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .category-tile .image-text-block-text .primary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 16px; }
  .category-tile .image-text-block-text .secondary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    color: orange;
    font-size: 16px; }
  .category-tile.text-placement-below .category-tile-text {
    margin-top: 12px; }
  .category-tile.text-placement-inside::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px; }
  .category-tile.text-placement-inside .category-tile-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFF;
    z-index: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6); }
  .category-tile.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .category-tile.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1.5 * 100%); }
  .category-tile.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .category-tile.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1.5 / 1 * 100%); }

body {
  font-family: "Roboto", sans-serif;
  color: #000;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0;
  background-color: #FFF; }

.page {
  background-color: #FFF;
  overflow: hidden; }

.error-page {
  padding-bottom: 12px;
  padding-top: 12px; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 50px;
  margin-bottom: 50px; }

.error.continue-shopping {
  margin-bottom: 100px; }

/*
* ORIGINAL CSS
* (everything under this comment was copied from Base and eventually
* should be removed or moved above this comment if in use)
*/
.modal-background {
  background-color: #000;
  display: none;
  height: 100vh;
  position: fixed;
  opacity: 0.5;
  width: 100vw;
  top: 0;
  left: 0;
  transform: translateZ(0);
  z-index: 201; }

input[placeholder] {
  text-overflow: ellipsis; }

.hide-order-discount {
  display: none; }

.hide-shipping-discount {
  display: none; }

.order-discount {
  color: #00865A; }

.giftcertificate-discount {
  color: #00865A; }

.shipping-discount {
  color: #00865A; }

.error-messaging {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1; }

.error-message {
  margin-top: 3.125rem;
  margin-bottom: 3.125rem; }

.error.continue-shopping {
  margin-bottom: 6.25em; }

.error-unassigned-category {
  color: #BF242B; }

.skip {
  position: absolute;
  left: 0;
  top: -4.2em;
  overflow: hidden;
  padding: 1em 1.5em;
  background: #FFF;
  transition: all 200ms ease-in-out; }

a.skip:active,
a.skip:focus,
a.skip:hover {
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 10000000;
  background: #FFF;
  transition: all 200ms ease-in-out; }

.modal-background.geo-modal {
  z-index: 1025; }

.mobile-menu-in .modal-background {
  z-index: 2; }

.order-discount {
  color: #BF242B; }

.giftcertificate-discount {
  color: #BF242B; }

.shipping-discount {
  color: #BF242B;
  flex: 1;
  width: 100%; }

.total-shipping-line-through {
  text-decoration: line-through; }

.cart-shipping-total {
  flex: 1; }

.shipping-discount-cart {
  color: #BF242B;
  width: 100%; }

.shipping-cart-padding {
  margin-right: 6px; }

.esw-shipping-message {
  padding-left: 6px; }

button.close-button,
.close-button {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #000;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  border: 0;
  align-self: center;
  opacity: 1;
  text-shadow: none; }
  button.close-button[aria-hidden=true],
  .close-button[aria-hidden=true] {
    display: none; }
  button.close-button:before,
  .close-button:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: #FFF;
    font-weight: normal; }
  button.close-button[class*="btn-"]:not(.title),
  .close-button[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    button.close-button[class*="btn-"]:not(.title):before,
    .close-button[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #FFF;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    button.close-button[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > button.close-button.btn[class*="btn-"]:not(.title),
    .close-button[class*="btn-"]:not(.title).btn-sm,
    .btn-group-sm > .close-button.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      button.close-button[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > button.close-button.btn[class*="btn-"]:not(.title):before,
      .close-button[class*="btn-"]:not(.title).btn-sm:before,
      .btn-group-sm > .close-button.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    button.close-button[class*="btn-"]:not(.title) [class*="icon-"],
    button.close-button[class*="btn-"]:not(.title) [class^="icon-"],
    button.close-button[class*="btn-"]:not(.title) .fa,
    .close-button[class*="btn-"]:not(.title) [class*="icon-"],
    .close-button[class*="btn-"]:not(.title) [class^="icon-"],
    .close-button[class*="btn-"]:not(.title) .fa {
      display: none; }
  button.close-button::before,
  .close-button::before {
    height: inherit;
    width: inherit;
    align-content: center;
    text-align: center;
    line-height: 24px; }
  button.close-button:not(:disabled):not(.disabled):hover, button.close-button:not(:disabled):not(.disabled):focus,
  .close-button:not(:disabled):not(.disabled):hover,
  .close-button:not(:disabled):not(.disabled):focus {
    opacity: 0.75;
    color: #FFF; }
  .alert button.close-button, .alert
  .close-button {
    display: flex;
    width: 24px;
    height: 24px;
    background-color: #FFF;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    border: 0;
    align-self: center;
    opacity: 1;
    text-shadow: none; }
    .alert button.close-button[aria-hidden=true], .alert
    .close-button[aria-hidden=true] {
      display: none; }
    .alert button.close-button:before, .alert
    .close-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .alert button.close-button[class*="btn-"]:not(.title), .alert
    .close-button[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .alert button.close-button[class*="btn-"]:not(.title):before, .alert
      .close-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .alert button.close-button[class*="btn-"]:not(.title).btn-sm, .alert .btn-group-sm > button.close-button.btn[class*="btn-"]:not(.title), .alert .close-button[class*="btn-"]:not(.title).btn-sm, .alert .btn-group-sm > .close-button.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .alert button.close-button[class*="btn-"]:not(.title).btn-sm:before, .alert .btn-group-sm > button.close-button.btn[class*="btn-"]:not(.title):before, .alert .close-button[class*="btn-"]:not(.title).btn-sm:before, .alert .btn-group-sm > .close-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .alert button.close-button[class*="btn-"]:not(.title) [class*="icon-"],
      .alert button.close-button[class*="btn-"]:not(.title) [class^="icon-"],
      .alert button.close-button[class*="btn-"]:not(.title) .fa, .alert
      .close-button[class*="btn-"]:not(.title) [class*="icon-"],
      .alert
      .close-button[class*="btn-"]:not(.title) [class^="icon-"],
      .alert
      .close-button[class*="btn-"]:not(.title) .fa {
        display: none; }
    .alert button.close-button::before, .alert
    .close-button::before {
      height: inherit;
      width: inherit;
      align-content: center;
      text-align: center;
      line-height: 24px; }
    .alert button.close-button:not(:disabled):not(.disabled):hover, .alert button.close-button:not(:disabled):not(.disabled):focus, .alert
    .close-button:not(:disabled):not(.disabled):hover, .alert
    .close-button:not(:disabled):not(.disabled):focus {
      opacity: 0.75;
      color: #000; }

.content-grid + .content-grid {
  margin-top: 24px; }

@media (max-width: 768.98px) {
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-12'] {
    margin-top: 24px; }
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n),
  .content-grid .content-column[class*='col-md'] + .content-column[class*='col-6']:nth-child(4n-1) {
    margin-top: 24px; } }

.content-tile {
  display: block;
  text-decoration: none;
  margin-bottom: 0px; }
  @media (min-width: 769px) {
    .content-tile .content-tile-image {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .content-tile:hover .content-tile-image {
      transform: scale(1.2); } }
  .content-tile .content-tile-image-wrapper {
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: 9px; }
  .content-tile .content-tile-full-image-wrapper {
    float: left;
    width: 675px;
    height: 400px;
    display: block;
    overflow: hidden;
    position: relative;
    margin-bottom: 9px; }
  .content-tile .content-tile-full-body {
    margin-left: 24px;
    height: 400px;
    display: flex;
    align-items: center;
    padding-left: 24px; }
  .content-tile .content-tile-image {
    display: block; }
    .content-tile .content-tile-image::after {
      display: block;
      content: "";
      clear: both; }
    .content-tile .content-tile-image::before {
      content: "";
      display: block;
      width: 1px;
      margin-left: -1px;
      float: left;
      height: 0;
      padding-top: calc(1 / 1 * 100%); }
  .content-tile .content-tile-body {
    display: block; }
  .content-tile .content-tile-category {
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    font-weight: 500;
    text-decoration: none;
    color: #595959;
    margin-bottom: 7px; }
  .content-tile .content-tile-name {
    font-family: "archia-medium", sans-serif;
    font-size: 20px;
    font-weight: 500;
    text-decoration: none;
    color: #000;
    margin-bottom: 10px; }
  .content-tile .content-tile-snippet {
    display: block;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #000; }
  .content-tile .content-tile-date {
    display: inline-block;
    font-family: "archia-medium", sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    margin-bottom: 7px;
    color: #8F8F8F; }
  .content-tile .blog-article-tag {
    display: inline-block;
    border: 1px solid #D6D6D6;
    padding-left: 6px;
    padding-right: 6px;
    line-height: 17px;
    text-align: center;
    margin-bottom: 24px;
    border-radius: 6px;
    font-size: 10px;
    background-color: #000;
    color: #FFF; }
  .content-tile .contentTileFull {
    width: 100%; }
  .content-tile .content-tile-description {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    color: #000; }
  .content-tile:hover {
    text-decoration: none; }
    .content-tile:hover .content-tile-image-wrapper::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      pointer-events: none;
      box-shadow: inset 0px -2px 0px #000; }
    .content-tile:hover .content-tile-name {
      text-decoration: none;
      color: #000; }
    .content-tile:hover .content-tile-description {
      text-decoration: none;
      color: #000; }

.content-tile-blog-wrapper {
  max-width: 100%;
  height: 400px;
  margin-bottom: 24px; }
  @media (min-width: 769px) {
    .content-tile-blog-wrapper {
      display: none; } }
  @media (min-width: 1024px) {
    .content-tile-blog-wrapper {
      display: block; } }
  @media (max-width: 768.98px) {
    .content-tile-blog-wrapper {
      display: none; } }

@media (min-width: 1024px) {
  .blog-top-article {
    display: none; } }

@media (max-width: 1023.98px) {
  .blog-top-article {
    display: block;
    max-width: 100%;
    padding: 0px; } }

.card-number-wrapper {
  position: relative; }
  .card-number-wrapper input {
    padding-right: calc(12px + 48px); }
  .card-number-wrapper::after {
    content: '';
    position: absolute;
    top: calc(24px - 15px);
    right: 6px;
    background-repeat: no-repeat;
    background-image: url("../../images/credit.png");
    background-size: contain;
    width: 48px;
    height: 30px; }
  .card-number-wrapper[data-type="visa"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -162px -110px; }
  .card-number-wrapper[data-type="mastercard"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -295px -110px; }
  .card-number-wrapper[data-type="amex"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -230px -15px; }
  .card-number-wrapper[data-type="discover"]::after {
    background-image: url("../../images/payment-types.png");
    background-size: auto;
    background-position: -95px -110px; }

.cc-type.icon {
  display: inline-block;
  width: 30px;
  height: 28px;
  margin-right: 3px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }
  .cc-type.icon.visa {
    background-image: url("../../images/visa.png"); }
  .cc-type.icon.mc {
    background-image: url("../../images/mc.png"); }
  .cc-type.icon.maestro {
    background-image: url("../../images/maestro.png"); }
  .cc-type.icon.discover {
    background-image: url("../../images/discover.png"); }
  .cc-type.icon.diners {
    background-image: url("../../images/diners.png"); }
  .cc-type.icon.amex {
    background-image: url("../../images/amex.png"); }
  .cc-type.icon.cartebancaire {
    background-image: url("../../images/cartebancaire.png"); }

.dropdown-toggle:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.dropdown-toggle[class*="btn-"]:not(.title) {
  padding-right: 40px; }
  .dropdown-toggle[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .btn-group-sm > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
  .dropdown-toggle[class*="btn-"]:not(.title) .fa {
    display: none; }

.dropdown-toggle:after {
  border: none;
  vertical-align: top;
  margin-left: 5px;
  position: relative; }

.dropdown.show .dropdown-toggle:after,
.custom-dropdown.show .dropdown-toggle:after {
  transform: rotate(180deg); }

.dropdown-toggle:not(a):not(button) {
  cursor: default; }

.dropdown-menu {
  margin: 0;
  padding: 0;
  border-radius: 0;
  border: 1px solid transparent;
  animation: fadeIn 200ms; }
  .dropdown-menu .dropdown-item {
    text-decoration: none;
    padding: 0; }
    .dropdown-menu .dropdown-item + .dropdown-item {
      border-top: 1px solid transparent; }
    .dropdown-menu .dropdown-item:hover {
      background: transparent;
      color: #2B2B2A;
      text-decoration: none; }
    .dropdown-menu .dropdown-item .dropdown-link {
      display: block;
      padding: 12px;
      color: #0C0A08; }
      .dropdown-menu .dropdown-item .dropdown-link:hover {
        color: #2B2B2A; }
  .dropdown-menu [class*="sub-asset-"] + .dropdown-item {
    border-top: 1px solid transparent; }

.dropdown-menu .sub-asset-1 + .dropdown-item {
  border-top: 1px solid transparent; }

.asg-hero {
  position: relative;
  overflow: hidden;
  display: flex; }
  .asg-hero-container {
    width: 100%; }
    .asg-hero-container > a,
    .asg-hero-container > picture {
      width: 100%; }
    @media (min-width: 769px) {
      .asg-hero-container {
        display: flex;
        height: auto; } }
  .asg-hero-image {
    width: 100%; }
  .asg-hero-badge {
    font-family: "archia-medium", sans-serif;
    font-size: 17px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal;
    border: 1px solid;
    font-size: 14px;
    margin-bottom: 12px;
    padding: 6px 12px;
    width: fit-content; }
    @media (min-width: 1024px) {
      .asg-hero-badge {
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }
    @media (min-width: 769px) {
      .asg-hero-badge {
        font-size: 16px;
        margin-bottom: 24px;
        padding: 12px 24px; } }
    .asg-hero-badge.highlight:not(:is(.sale, .flashsale)) {
      background-color: #99FF00; }
    .asg-hero-badge.sale, .asg-hero-badge.flashsale {
      background-color: #BF242B;
      border-color: #000;
      color: #FFF; }
      @media (min-width: 769px) {
        .asg-hero-badge.sale, .asg-hero-badge.flashsale {
          border-color: #FFF; } }
  .asg-hero-text {
    padding: 24px; }
    @media (max-width: 768.98px) {
      .mobile-1r-1c .asg-hero-text {
        padding: 24px 0; } }
    @media (min-width: 769px) {
      .asg-hero-text {
        padding: 48px;
        position: absolute;
        bottom: 0; } }
  .asg-hero-buttons {
    display: inline-flex;
    gap: 10px; }
    .asg-hero-buttons .video-container {
      position: absolute; }
    .asg-hero-buttons .video-player {
      z-index: -1; }
  .asg-hero-title > * {
    font-family: "archia-medium", sans-serif;
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 1024px) {
      .asg-hero-title > * {
        font-size: 29px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }
  .asg-hero-title.title-only {
    margin-bottom: 24px; }
  .asg-hero-subtitle > * {
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0;
    margin: 24px 0 24px; }
    @media (min-width: 1024px) {
      .asg-hero-subtitle > * {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  @media (min-width: 769px) {
    .asg-hero-subtitle {
      max-width: 500px; } }
  .asg-hero .dark-theme .btn-primary:hover {
    border-color: #000; }
  .asg-hero .dark-theme .btn-secondary:hover {
    color: #000; }
  .asg-hero .dark-theme .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
    background-color: #000;
    border-color: #D6D6D6;
    color: #FFF; }
    @media (min-width: 769px) {
      .asg-hero .dark-theme .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
        border: none; } }
  @media (min-width: 769px) {
    .asg-hero .dark-theme .asg-hero-title,
    .asg-hero .dark-theme .asg-hero-subtitle {
      color: #FFF; }
    .asg-hero .dark-theme .btn-primary {
      border-color: #FFF; }
      .asg-hero .dark-theme .btn-primary:hover {
        color: #000;
        border-color: #FFF;
        background-color: #FFF; }
    .asg-hero .dark-theme .btn-secondary {
      color: #FFF;
      background-color: transparent; }
      .asg-hero .dark-theme .btn-secondary:hover {
        border-color: #FFF;
        background-color: #FFF; } }
  .asg-hero .light-theme .btn-primary:hover {
    border-color: #000; }
  .asg-hero .light-theme .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
    background-color: #FFF;
    border-color: #595959;
    color: #000; }
    @media (min-width: 769px) {
      .asg-hero .light-theme .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
        border: none; } }
  @media (min-width: 769px) {
    .asg-hero .light-theme .btn-primary {
      border-color: #000; }
      .asg-hero .light-theme .btn-primary:hover {
        color: #000;
        border-color: #FFF;
        background-color: #FFF; }
    .asg-hero .light-theme .btn-secondary {
      color: #000;
      background-color: transparent; }
      .asg-hero .light-theme .btn-secondary:hover {
        color: #000;
        border-color: #FFF;
        background-color: #FFF; } }

.image-block {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .image-block:hover {
    text-decoration: none; }
  .image-block .image-cropper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  .image-block .image-wrapper {
    width: 100%;
    position: relative; }
    .image-block .image-wrapper .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block; }
    .image-block .image-wrapper .background-image-tablet,
    .image-block .image-wrapper .background-image-desktop,
    .image-block .image-wrapper .background-image-hd {
      display: none; }
    @media (min-width: 769px) {
      .image-block .image-wrapper .background-image-mobile,
      .image-block .image-wrapper .background-image-desktop {
        display: none; }
      .image-block .image-wrapper .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .image-block .image-wrapper .background-image-mobile,
      .image-block .image-wrapper .background-image-tablet {
        display: none; }
      .image-block .image-wrapper .background-image-desktop {
        display: block; } }
    @media (min-width: 1440px) {
      .image-block .image-wrapper .background-image-desktop {
        display: none; }
      .image-block .image-wrapper .background-image-hd {
        display: block; } }
  .image-block .image-block-text {
    text-align: left;
    position: absolute;
    bottom: 0;
    padding: 24px; }
    @media (min-width: 1024px) {
      .image-block .image-block-text {
        padding: 48px; } }
    .image-block .image-block-text .primary-text > * {
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      line-height: inherit;
      text-transform: inherit;
      letter-spacing: inherit;
      color: inherit;
      margin-bottom: 0; }
    .image-block .image-block-text .primary-text {
      color: inherit;
      font-family: "archia-medium", sans-serif;
      font-size: 20px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; }
      @media (min-width: 1024px) {
        .image-block .image-block-text .primary-text {
          font-size: 24px;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
          font-style: normal; } }
      .image-block .image-block-text .primary-text:not(:only-child) {
        margin-bottom: 24px; }
    .image-block .image-block-text .image-text-block-buttons {
      margin-bottom: -8px; }
    .image-block .image-block-text .image-text-block-button {
      text-shadow: none;
      font-family: "Roboto", sans-serif; }
      .image-block .image-block-text .image-text-block-button:first-child {
        margin-right: 8px; }
      .image-block .image-block-text .image-text-block-button .btn,
      .image-block .image-block-text .image-text-block-button .btn-primary,
      .image-block .image-block-text .image-text-block-button .btn-secondary {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 1em;
        text-align: center;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 8px; }
      .image-block .image-block-text .image-text-block-button .btn-secondary {
        background-color: transparent; }
  .image-block .image-block-image-link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  .image-block.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-block.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .image-block.dark-theme .image-wrapper {
    background-color: #000; }
  .image-block.dark-theme .image-block-text .primary-text {
    color: #FFF; }
  .image-block.dark-theme .image-text-block-button .btn-primary {
    background-color: #FFF;
    border-color: #FFF;
    color: #000; }
  .image-block.dark-theme .image-text-block-button .btn-secondary {
    color: #FFF;
    border-color: #595959; }
  .image-block.light-theme .image-block-text .primary-text {
    color: #000; }
  .image-block.light-theme .image-text-block-button .btn-primary {
    background-color: #000;
    color: #FFF; }
  .image-block.light-theme .image-text-block-button .btn-secondary {
    border-color: #000; }

.add-giftcertificate-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.check-balance .balancemsg {
  font-weight: bold;
  font-size: 18px;
  margin-top: 12px;
  justify-content: center; }

.remove-giftcertificate-pi {
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  cursor: pointer;
  z-index: 1;
  background-color: #FFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  padding: 0px 5px; }
  .remove-giftcertificate-pi:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .remove-giftcertificate-pi[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .remove-giftcertificate-pi[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .remove-giftcertificate-pi.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class*="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) [class^="icon-"],
    .remove-giftcertificate-pi[class*="btn-"]:not(.title) .fa {
      display: none; }

.navbar {
  padding: 0; }
  @media (min-width: 1024px) {
    .navbar.bg-inverse {
      position: static; }
      .navbar.bg-inverse .navbar-nav {
        flex-wrap: nowrap; }
        .navbar.bg-inverse .navbar-nav + .nav-item {
          margin-left: 0; }
        .navbar.bg-inverse .navbar-nav .nav-link {
          padding: 12px 18px;
          white-space: nowrap; }
      .logo-center .navbar.bg-inverse .nav-item > .nav-link {
        color: #FFF; }
      .logo-center .navbar.bg-inverse .nav-item .nav-link > .nav-link, .logo-center .navbar.bg-inverse .nav-item .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item .nav-link:focus, .logo-center .navbar.bg-inverse .nav-item.show > .nav-link, .logo-center .navbar.bg-inverse .nav-item.show:hover, .logo-center .navbar.bg-inverse .nav-item.show:focus {
        background-color: #FFF;
        color: #2B2B2A; }
      .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link {
        color: #0C0A08; }
        .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:hover, .logo-center .navbar.bg-inverse .nav-item.highlight .nav-link:focus {
          background-color: #FFF;
          color: #2B2B2A; } }

.multilevel-dropdown .dropdown-menu {
  top: 100%;
  border: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0; }
  @media (min-width: 1024px) {
    .multilevel-dropdown .dropdown-menu {
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
      left: auto; } }

.multilevel-dropdown .dropdown-item.custom-dropdown > .dropdown-toggle::after {
  transform: rotate(-90deg); }

@media (min-width: 1024px) {
  .multilevel-dropdown .dropdown-menu > .custom-dropdown > .dropdown-menu {
    top: 0;
    left: 99%; } }

.multilevel-dropdown .navbar > .close-menu {
  display: none; }

.multilevel-dropdown .close-menu .back .caret-left:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 10px;
  color: inherit;
  font-weight: normal; }

.multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) {
  padding-right: 40px; }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title).btn-sm:after, .multilevel-dropdown .close-menu .back .btn-group-sm > .caret-left.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class*="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) [class^="icon-"],
  .multilevel-dropdown .close-menu .back .caret-left[class*="btn-"]:not(.title) .fa {
    display: none; }

.multilevel-dropdown .dropdown-menu.show > .dropdown-item.show > .dropdown-toggle::after {
  transform: rotate(90deg); }

.multilevel-dropdown .menu-group {
  width: 100%; }

.multilevel-dropdown .menu-subcategories {
  padding-left: 0; }

.multilevel-dropdown .last-item {
  display: none; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu {
    position: static; }
    .navbar-nav .megamenu > .dropdown-menu {
      position: absolute;
      right: 50%;
      transform: translateX(50%);
      left: auto;
      top: auto;
      width: 100vw;
      padding: 0;
      box-shadow: none;
      background: #FFF;
      z-index: 0;
      justify-content: center;
      border-top: 1px solid #dfdfdf;
      border-bottom: 1px solid #dfdfdf; }
      .navbar-nav .megamenu > .dropdown-menu .container {
        max-width: 1920px; }
      .navbar-nav .megamenu > .dropdown-menu.mega-left {
        justify-content: flex-start; }
      .navbar-nav .megamenu > .dropdown-menu.show {
        display: flex;
        flex-direction: column; }
      .navbar-nav .megamenu > .dropdown-menu:before {
        content: '';
        position: absolute;
        right: 50%;
        transform: translateX(50%);
        left: auto;
        top: 0;
        bottom: 0;
        width: 100vw;
        z-index: -1;
        background: #FFF;
        box-shadow: none; }
        .logo-left .navbar-nav .megamenu > .dropdown-menu:before {
          border-top: 1px solid transparent; }
      .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
        display: flex;
        align-self: flex-start; } }
      @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories {
          flex-grow: 1; } }

@media (min-width: 1024px) {
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item {
          padding: 30px 48px; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item:first-child {
            padding-left: 0; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories > .dropdown-item:last-child {
            padding-right: 0; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-toggle:after {
          display: none; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item {
          width: auto;
          border: none;
          white-space: normal; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-item:hover {
            background: none; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .featured-item {
          border-right: 1px solid #dfdfdf; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link {
          font-family: "Roboto", sans-serif;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          text-transform: none;
          color: #0C0A08; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .nav-link:hover {
            color: #0C0A08; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-link {
          padding: 10px 5px 10px 5px;
          display: inline-block; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu {
          margin-left: 2px;
          position: static;
          display: flex;
          box-shadow: none;
          border-radius: 0;
          background: transparent;
          flex-flow: row wrap;
          align-content: flex-start; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item {
            border: none;
            flex-basis: 100%; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.custom-dropdown {
              flex-basis: 25%; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item {
              display: block;
              order: 1000;
              padding-top: 12px; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item a {
                font-weight: bold;
                text-transform: uppercase; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item.last-item:before {
                content: '';
                position: absolute;
                border-top: 1px solid #D6D6D6;
                width: 36px;
                left: calc(12px + 2px + 5px); }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: 500;
                text-transform: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item {
              padding-top: unset; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
                font-weight: 500;
                text-transform: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
                display: none; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link {
            color: #2B2B2A;
            font-family: "Roboto", sans-serif;
            font-size: 14px;
            font-weight: 500;
            text-transform: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link:hover {
              color: #40403F; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-link.mega-left-title {
              font-weight: 500;
              font-size: 16px; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories .dropdown-menu .dropdown-menu {
            flex-flow: column; }
        .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left {
          flex: 1 1 calc((100% - 15%)/2); }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset {
            flex: 1 1 calc(100% - 15%); }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset + [class*="megamenu-asset-"] {
              display: none; }
              .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left.has-sub-asset + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
                display: none; }
          .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left > .dropdown-item {
            display: none; }
            .navbar-nav .megamenu > .dropdown-menu .menu-subcategories.mega-left > .dropdown-item.active-list {
              display: flex; }
      .navbar-nav .megamenu > .dropdown-menu > li > a.title {
        margin-bottom: 30px;
        padding: 0 5px;
        text-decoration: none; }
      .navbar-nav .megamenu > .dropdown-menu .megamenu-asset-only {
        width: 100%; }
      .navbar-nav .megamenu > .dropdown-menu .left-nav {
        margin-top: 30px;
        margin-bottom: 30px;
        flex: 0 0 15%;
        border-right: 1px solid #F8F8F8; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav li {
          border-top: none;
          padding: 0; }
          .navbar-nav .megamenu > .dropdown-menu .left-nav li:hover {
            background: unset; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav a {
          padding: 10px 15px;
          font-size: 18px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0;
          border-right: 1px solid #F8F8F8;
          margin-right: -1px; }
          .navbar-nav .megamenu > .dropdown-menu .left-nav a.active {
            color: #2B2B2A;
            border-right: 1px solid #2B2B2A; } }

.navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
  padding: 12px;
  border-top: 1px solid transparent; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
      padding: 30px 12px;
      margin: 0 auto;
      padding-left: 12px;
      border-top: none;
      border-right: none;
      flex-basis: 25%; } }

.navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
  padding: 12px;
  border-top: 1px solid transparent; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
      padding: 0;
      margin: 0 auto;
      padding-left: 12px;
      border-top: none;
      border-left: none;
      flex-basis: 25%; }
      .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 img {
        margin-bottom: -30px;
        margin-top: -30px;
        margin-left: 12px;
        margin-right: -12px; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left .active-list + [class*="sub-asset-"] {
    display: flex;
    padding: 0;
    flex: 0 0 50%; } }

.navbar-nav .megamenu .mega-left .sub-asset-1,
.navbar-nav .megamenu .mega-left .sub-asset-2 {
  display: none;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  list-style: none; }
  .navbar-nav .megamenu .mega-left .sub-asset-1 .page-designer,
  .navbar-nav .megamenu .mega-left .sub-asset-2 .page-designer {
    flex: 0 0 100%;
    padding-right: 12px; }

.navbar-nav .megamenu .mega-left .sub-asset-1.sub-asset-2 .page-designer {
  flex: 0 0 50%; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-2, .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/2);
    padding: 30px 12px 30px 0; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-1,
  .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-2 {
    flex: 1 1 calc((100% - 15%)/4); } }

@media (max-width: 1023.98px) {
  .navbar-nav .megamenu ul {
    display: none; }
  .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] {
    display: none; }
    .navbar-nav .megamenu.show [class*="has-asset-"] .thirdCatOpen + [class*="megamenu-asset-"] + [class*="megamenu-asset-"] {
      display: none; }
  .navbar-nav .megamenu.show ul {
    display: block; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories {
    flex-grow: 1; }
    .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
    .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
      flex-basis: 33.33%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 50%; } }

@media (min-width: 1440px) {
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories > .dropdown-item,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 20%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 33.33%; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left {
    position: fixed;
    transform: translateX(100%);
    transition: transform 200ms ease-out;
    top: 60px;
    right: 0;
    display: block;
    width: 348px;
    height: calc(100vh - 60px);
    background-color: #FFF;
    z-index: 300;
    overflow-x: hidden;
    overflow-y: auto; }
    .main-menu.menu-toggleable-left.in {
      transform: translateX(0); }
      .main-menu.menu-toggleable-left.in .show > .dropdown-menu {
        transform: translateX(0);
        overflow: auto;
        display: flex;
        flex-direction: column; }
    .main-menu.menu-toggleable-left > .container {
      height: 100%;
      padding-left: 0;
      padding-right: 0;
      flex-grow: 1; }
    .main-menu.menu-toggleable-left .search-mobile .search-field {
      padding: 0 12px;
      border-radius: 0;
      border-left: none;
      border-right: none;
      font-size: 16px;
      line-height: normal; }
    .main-menu.menu-toggleable-left .search-mobile .fa-close,
    .main-menu.menu-toggleable-left .search-mobile .fa-search {
      right: 12px; }
    .main-menu.menu-toggleable-left .search-mobile .reset-button {
      display: none !important; }
    .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper {
      background-color: #D6D6D6;
      overflow: hidden; }
      .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions {
        position: static;
        width: 100%;
        margin-bottom: 12px;
        box-shadow: none; }
        .main-menu.menu-toggleable-left .search-mobile .suggestions-wrapper .suggestions > .container {
          max-width: 348px;
          margin-left: 0; }
    .main-menu.menu-toggleable-left .dropdown-menu {
      position: fixed;
      transform: translateX(-100%);
      transition: transform 200ms ease-out;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: hidden; }
    .main-menu.menu-toggleable-left .dropdown-toggle:after {
      transform: rotate(-90deg); }
    .main-menu.menu-toggleable-left .dropdown-item:hover {
      background: transparent; }
    .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories {
      border-top: 1px solid transparent;
      padding-left: 12px;
      padding-right: 12px; }
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item > .dropdown-link {
        padding-left: 0; }
      .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category {
        margin-bottom: -1px;
        border-bottom: 1px solid transparent; }
        .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item {
          margin-left: 12px;
          margin-right: 12px;
          width: auto; }
          .main-menu.menu-toggleable-left .top-category ~ .menu-subcategories > .dropdown-item .top-category ~ .dropdown-item > .dropdown-link {
            padding-left: 0; }
    .main-menu.menu-toggleable-left .top-category .user-message {
      display: none; }
    .main-menu.menu-toggleable-left .support .top-category {
      border-bottom: 1px solid transparent; }
    .main-menu.menu-toggleable-left .nav-item ~ .nav-item {
      border-top: 1px solid transparent; }
    .main-menu.menu-toggleable-left .nav-item .nav-link {
      color: #0C0A08; }
      .main-menu.menu-toggleable-left .nav-item .nav-link.dropdown-toggle:hover {
        color: #2B2B2A; }
    .main-menu.menu-toggleable-left .nav-item .nav-link,
    .main-menu.menu-toggleable-left .nav-item .dropdown-link {
      padding: 12px 24px; }
    .main-menu.menu-toggleable-left .nav-item.highlight .nav-link {
      color: #BF242B; }
    .main-menu.menu-toggleable-left .close-menu {
      background-color: #D6D6D6; }
      .main-menu.menu-toggleable-left .close-menu button {
        display: block;
        width: 100%;
        padding: 12px 24px;
        text-align: left; }
      .main-menu.menu-toggleable-left .close-menu .icon {
        float: left;
        display: block;
        margin-top: 3px;
        margin-right: 10px; }
    .main-menu.menu-toggleable-left .flag-icon {
      margin-right: 5px; } }

@media (max-width: 768.98px) {
  .main-menu.menu-toggleable-left {
    height: calc(100vh - 42px);
    top: 42px; } }

@media (max-width: 543.98px) {
  .main-menu.menu-toggleable-left {
    width: calc(100% - 50px);
    max-width: 348px; } }

.navbar {
  align-items: flex-start; }

@media (min-width: 1024px) {
  .navbar-nav .megamenu > .dropdown-menu {
    justify-content: center; }
    .navbar-nav .megamenu > .dropdown-menu .left-nav {
      padding-left: 48px;
      margin-right: 65px; }
      .navbar-nav .megamenu > .dropdown-menu .left-nav li {
        border-top: none;
        padding: 10px 0; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav li:hover {
          background: unset; }
      .navbar-nav .megamenu > .dropdown-menu .left-nav a {
        padding: 0 15px;
        font-size: 18px;
        text-transform: uppercase !important;
        border-right: none;
        margin-right: -1px; }
        .navbar-nav .megamenu > .dropdown-menu .left-nav a.active {
          color: #2B2B2A;
          border-right: none; }
          .navbar-nav .megamenu > .dropdown-menu .left-nav a.active span {
            border-bottom: 1px solid #2B2B2A; }
  .navbar-nav .megamenu .menu-subcategories.mega-left {
    flex: 1 1 calc((100% - 15%)/2); }
    .navbar-nav .megamenu .menu-subcategories.mega-left .nav-link {
      font-weight: 500; }
    .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-link {
      padding: 10px 5px 10px 0; }
      .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-link:not(.mega-left-title) {
        line-height: 28px;
        font-size: 14px;
        font-weight: 400; }
    .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu {
      display: flex;
      flex-flow: row wrap;
      align-content: flex-start; }
      .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item {
        flex-basis: 100%; }
        .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item.last-item {
          display: block;
          order: 1000;
          padding-top: 12px; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item.last-item a {
            font-weight: bold;
            text-transform: uppercase; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item.last-item:before {
            content: '';
            position: absolute;
            border-top: 1px solid #D6D6D6;
            width: 36px;
            left: 12px; }
        .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item {
          padding-top: unset; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
            font-weight: 500;
            text-transform: none; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
            display: none; }
        .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item {
          padding-top: unset; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item:before {
            display: none; }
          .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-item + .dropdown-item.custom-dropdown + .dropdown-item.custom-dropdown .dropdown-item.last-item a {
            font-weight: 500;
            text-transform: none; }
      .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-link.mega-left-title {
        line-height: 34px; }
      .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-link:not(.mega-left-title) {
        padding: 0; }
      .navbar-nav .megamenu .menu-subcategories.mega-left .dropdown-menu .dropdown-menu {
        flex-flow: column; }
    .navbar-nav .megamenu .menu-subcategories.mega-left.mega-left {
      flex: 1 1 auto; }
      .navbar-nav .megamenu .menu-subcategories.mega-left.mega-left.has-sub-asset + .megamenu-asset-1 {
        display: none; }
      .navbar-nav .megamenu .menu-subcategories.mega-left.mega-left > .dropdown-item {
        display: none; }
        .navbar-nav .megamenu .menu-subcategories.mega-left.mega-left > .dropdown-item.active-list {
          display: flex; }
  .navbar-nav .megamenu .mega-left {
    list-style: none; }
    .navbar-nav .megamenu .mega-left > .dropdown-item {
      display: none; }
      .navbar-nav .megamenu .mega-left > .dropdown-item.active-list {
        display: flex; }
    .navbar-nav .megamenu .mega-left.show::before {
      border-bottom: 1px solid #D6D6D6; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar-nav .megamenu .mega-left .active-list + [class*="sub-asset-"] {
      display: flex;
      padding: 0;
      flex: 0 0 50%; } }

@media (min-width: 1024px) {
    .navbar-nav .megamenu .mega-left .sub-asset-1,
    .navbar-nav .megamenu .mega-left .sub-asset-2 {
      display: none;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 0;
      list-style: none; }
      .navbar-nav .megamenu .mega-left .sub-asset-1 .page-designer,
      .navbar-nav .megamenu .mega-left .sub-asset-2 .page-designer {
        flex: 0 0 100%;
        padding-right: 12px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-1,
    .navbar-nav .megamenu .mega-left.has-asset-1 .megamenu-asset-2, .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-1,
    .navbar-nav .megamenu .mega-left.has-asset-2 .megamenu-asset-2 {
      flex: 1 1 calc((100% - 15%)/2);
      padding: 30px 12px 30px 0; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-1,
    .navbar-nav .megamenu .mega-left.has-asset-1.has-asset-2 .megamenu-asset-2 {
      flex: 1 1 calc((100% - 15%)/4); } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .fourth-level-menu:not(:last-child) {
    padding-right: 32px; } }

.navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
  padding: 30px 12px;
  border-top: 1px solid transparent;
  list-style: none; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-1 .megamenu-asset-1 {
      padding: 30px 24px;
      margin: 0 auto;
      padding-left: 12px;
      border-top: none;
      border-right: none;
      flex-basis: 25%; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .has-asset-1.mega-left .megamenu-asset-1 {
    flex-basis: 50%; } }

.navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
  list-style: none;
  padding: 30px 12px; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .has-asset-2 .megamenu-asset-2 {
      padding: 30px 24px;
      width: initial;
      flex-basis: 25%; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .has-asset-1 .menu-subcategories,
  .navbar-nav .megamenu .has-asset-2 .menu-subcategories {
    flex-basis: 50%; }
  .navbar-nav .megamenu .has-asset-1.has-asset-2 .menu-subcategories > .dropdown-item {
    flex-basis: 100%; } }

.navbar-nav .megamenu .featured-link-container,
.navbar-nav .megamenu .product-help-container {
  max-width: 100%; }
  .navbar-nav .megamenu .featured-link-container > .container,
  .navbar-nav .megamenu .product-help-container > .container {
    align-items: flex-start; }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .featured-link-container,
    .navbar-nav .megamenu .product-help-container {
      max-width: 100vw; } }
  .navbar-nav .megamenu .featured-link-container a,
  .navbar-nav .megamenu .product-help-container a {
    text-decoration: none; }

.navbar-nav .megamenu .featured-link-container {
  border-top: 1px solid #dfdfdf;
  padding: 18px 24px; }
  @media (max-width: 1023.98px) {
    .navbar-nav .megamenu .featured-link-container {
      background: #D8DDC9; } }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .featured-link-container {
      padding: 18px 0; } }
  .navbar-nav .megamenu .featured-link-container .featured-link {
    position: relative; }
    @media (max-width: 1023.98px) {
      .navbar-nav .megamenu .featured-link-container .featured-link {
        text-align: left;
        width: 100%; } }
    .navbar-nav .megamenu .featured-link-container .featured-link a {
      padding-right: 30px; }
      .navbar-nav .megamenu .featured-link-container .featured-link a:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 16px;
        color: inherit;
        font-weight: normal; }
      .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title).btn-sm, .navbar-nav .megamenu .featured-link-container .featured-link .btn-group-sm > a.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title).btn-sm:after, .navbar-nav .megamenu .featured-link-container .featured-link .btn-group-sm > a.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title) [class*="icon-"],
        .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title) [class^="icon-"],
        .navbar-nav .megamenu .featured-link-container .featured-link a[class*="btn-"]:not(.title) .fa {
          display: none; }
      .navbar-nav .megamenu .featured-link-container .featured-link a::after {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); }

.navbar-nav .megamenu .product-help-container {
  border-top: 1px solid #dfdfdf;
  background-color: #F8F8F8;
  padding: 24px; }
  @media (max-width: 1023.98px) {
    .navbar-nav .megamenu .product-help-container {
      flex-grow: 1;
      padding-bottom: 80px; } }
  @media (min-width: 1024px) {
    .navbar-nav .megamenu .product-help-container {
      padding: 24px 0; } }
  .navbar-nav .megamenu .product-help-container .product-help-header {
    margin-bottom: 20px; }
  .navbar-nav .megamenu .product-help-container button.product-help-link {
    display: block;
    margin-bottom: 16px;
    text-align: left; }
    @media (min-width: 1024px) {
      .navbar-nav .megamenu .product-help-container button.product-help-link {
        display: inline-block;
        border: 1px solid #cdcdcd;
        border-radius: 5px;
        margin-right: 12px;
        margin-bottom: 12px;
        padding: 4px 12px;
        background-color: #FFF; } }

.multilevel-dropdown .last-item {
  display: block; }

@media (max-width: 543.98px) {
  .main-menu.menu-toggleable-left {
    width: calc(100% - 42px); } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .last-item::before {
    content: '';
    position: absolute;
    border-top: 1px solid #D6D6D6;
    width: 36px;
    left: 12px; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left ul.dropdown-menu {
    height: 100%;
    overflow: visible; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .last-item {
      order: 1000; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .third-level-menu {
      display: flex;
      flex-direction: column; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .third-level-menu-asset-group {
      order: 1001;
      padding-left: 0; }
      .main-menu.menu-toggleable-left ul.dropdown-menu .third-level-menu-asset-group .third-level-menu-asset {
        max-width: 564px; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: inherit;
      font-weight: normal; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title).btn-sm, .main-menu.menu-toggleable-left ul.dropdown-menu .btn-group-sm > .dropdown-header.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title).btn-sm:after, .main-menu.menu-toggleable-left ul.dropdown-menu .btn-group-sm > .dropdown-header.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title) [class*="icon-"],
      .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title) [class^="icon-"],
      .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header[class*="btn-"]:not(.title) .fa {
        display: none; }
    .main-menu.menu-toggleable-left ul.dropdown-menu .dropdown-header:after {
      border: none;
      vertical-align: top;
      margin-left: 5px;
      position: relative; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .mega-left.thirdCatOpen .dropdown-item.show [class*="sub-asset-"] {
    display: flex;
    flex-direction: column; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .nav-item .nav-link,
  .main-menu.menu-toggleable-left .dropdown-item .dropdown-link:not(.shop-all-category-link) {
    display: flex;
    justify-content: space-between; } }

.main-menu.menu-toggleable-left .top-category .mega-left-title {
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  text-transform: none;
  font-size: 22px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0; }
  .main-menu.menu-toggleable-left .top-category .mega-left-title:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .main-menu.menu-toggleable-left .top-category .mega-left-title {
      font-size: 16px; } }
  @media (max-width: 1023.98px) {
    .main-menu.menu-toggleable-left .top-category .mega-left-title {
      margin-top: 12px;
      margin-bottom: 20px; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .menu-subcategories .dropdown-item .nav-link:not(.mega-left-title),
  .main-menu.menu-toggleable-left .menu-subcategories .dropdown-item .dropdown-link:not(.mega-left-title) {
    font-size: 16px;
    color: #2B2B2A;
    padding-top: 0; }
  .main-menu.menu-toggleable-left .second-level-menu-group .container {
    padding: 0;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .main-menu.menu-toggleable-left .mobile-nav-content-wrapper {
    padding: 0 24px; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .dropdown-item {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    white-space: normal; }
    .main-menu.menu-toggleable-left .dropdown-item  .dropdown-toggle {
      white-space: normal; }
    .main-menu.menu-toggleable-left .dropdown-item .fourth-level-menu {
      margin-right: 12px;
      margin-left: 12px;
      width: auto; }
      .main-menu.menu-toggleable-left .dropdown-item .fourth-level-menu .mega-left-title {
        padding-left: 0; } }

@media (max-width: 1023.98px) {
  .main-menu.menu-toggleable-left .close-menu {
    background-color: transparent;
    border-bottom: 1px solid #d6d6d6; }
    .main-menu.menu-toggleable-left .close-menu button {
      font-size: 16px;
      font-weight: 500;
      color: #000; }
    .main-menu.menu-toggleable-left .close-menu .icon-arrow-left-thin {
      font-size: 7.63px;
      margin-top: 0;
      line-height: 22px; } }

.main-menu.menu-toggleable-left .mobile-menu-additional-links .nav-link {
  font-family: "Roboto", sans-serif;
  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  line-height: 28px;
  text-transform: normal;
  color: #595959;
  padding: 4px 16px; }

@media (max-width: 1023.98px) {
  .navbar-nav .megamenu .mega-left .second-level-menu {
    display: flex;
    flex-direction: column; } }

@media (min-width: 1024px) {
  .navbar-nav .megamenu .mega-left .third-level-menu .third-level-menu-asset-group {
    padding-left: 0; } }

@media (max-width: 1023.98px) {
  .navbar-nav .megamenu .mega-left .third-level-menu .dropdown-link:not(.mega-left-title) {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 15px;
    line-height: 33px;
    padding: 5px 0; }
  .navbar-nav .megamenu .mega-left .third-level-menu .third-level-menu-asset.sub-asset-1, .navbar-nav .megamenu .mega-left .third-level-menu .third-level-menu-asset.sub-asset-2 {
    padding-top: 30px;
    padding-right: 12px;
    padding-bottom: 30px;
    padding-left: 12px;
    margin-right: 12px;
    margin-left: 12px; }
    .navbar-nav .megamenu .mega-left .third-level-menu .third-level-menu-asset.sub-asset-1 .page-designer, .navbar-nav .megamenu .mega-left .third-level-menu .third-level-menu-asset.sub-asset-2 .page-designer {
      padding-right: 0; } }

.header-banner {
  background-color: #FFF;
  text-align: center;
  color: #40403F;
  font-size: 11px;
  line-height: 22px;
  position: relative;
  padding-bottom: 12px;
  padding-top: 12px; }
  .header-banner .header-banner-column-2 .content {
    width: 100%; }
  .header-banner .header-banner-column-2 .close-button .close {
    opacity: 1;
    color: #FFF;
    font-size: 10px;
    padding: 0 12px 0 3px; }
  .header-banner .header-banner-column-3 .pull-right {
    margin-right: 12px; }
  .header-banner .dropdown-toggle:after {
    font-size: 10px; }
  .header-banner .nav-link {
    color: #40403F;
    padding: 0; }

.header-nav {
  position: relative;
  z-index: 300;
  background: #FFF;
  border-bottom: 1px solid #F8F8F8; }
  .header-nav.fixed {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    transition: all 400ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: 400; }
    .mobile-menu-in .header-nav.fixed {
      box-shadow: none; }
    .header-nav.fixed .brand .logo-home {
      background: url(../images/logo.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header-nav.fixed .brand .logo-home {
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header-nav.fixed .brand .logo-home {
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
  @media (min-width: 1024px) {
    .header-nav.nav-center .navbar-nav {
      justify-content: center;
      text-align: center; } }
  @media (min-width: 1024px) {
    .logo-center .header-nav {
      border-bottom-width: 0; } }

.sticky-header .fixed-header-enhanced:not(.transparent-header) {
  padding-bottom: var(--header-height); }

.sticky-header .fixed-header-enhanced .header-nav {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 400;
  will-change: transform;
  transform: translateY(-100%);
  animation: slide-up 400ms ease-out forwards; }

.sticky-header.scroll-direction-up .fixed-header-enhanced .header-nav {
  animation: slide-down 400ms ease-out forwards; }

.header {
  position: relative;
  height: 42px; }
  .checkout-header .header {
    height: 60px; }
    @media (max-width: 1023.98px) {
      .checkout-header .header {
        height: 42px; } }
  @media (min-width: 769px) {
    .header {
      height: 60px; } }
  @media (min-width: 1024px) {
    .header {
      height: 83px; } }
  .header .row {
    align-items: center; }
    .header .row .col {
      display: flex;
      height: 100%;
      align-items: center; }
  .header > .row {
    flex-wrap: nowrap; }
  .header .header-nav-row {
    height: 100%; }
  .header .header-column-1 > .pull-left:not(:empty) {
    margin-left: 12px; }
  .header .header-column-2 {
    flex-grow: 0; }
  .header .header-column-3 {
    justify-content: flex-end; }
    .header .header-column-3 > .pull-right {
      margin-right: 12px; }
      .header .header-column-3 > .pull-right.minicart {
        margin-right: 0; }
        @media (max-width: 1023.98px) {
          .header .header-column-3 > .pull-right.minicart {
            padding: 0;
            width: 41px;
            height: 41px;
            border-left: 1px solid #dfdfdf;
            border-radius: 0;
            display: flex;
            align-items: flex-end;
            justify-content: center; } }
  .header .nav-link,
  .header .country-selector {
    color: #0C0A08;
    transition: color 200ms ease-out; }
    .header .nav-link:hover,
    .header .country-selector:hover {
      color: #000;
      transition: color 200ms ease-out; }
  .header .nav-link {
    padding: 5px; }
  .header .brand {
    height: 100%; }
    @media (max-width: 1023.98px) {
      .header .brand {
        width: 42px;
        border-right: 1px solid #dfdfdf; } }
    .header .brand .logo-home {
      width: 21px;
      height: 100%;
      margin: 0 auto;
      background: url(../images/logo.svg) no-repeat center;
      background-size: contain; }
      @media (min-width: 769px) {
        .header .brand .logo-home {
          width: 25px;
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
      @media (min-width: 1024px) {
        .header .brand .logo-home {
          width: 25px;
          background: url(../images/logo.svg) no-repeat center;
          background-size: contain; } }
  .header .minicart .minicart-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 22px;
    color: #0C0A08;
    font-weight: normal; }
  .header .minicart .minicart-icon[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .header .minicart .minicart-icon[class*="btn-"]:not(.title).btn-sm:before, .header .minicart .btn-group-sm > .minicart-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .header .minicart .minicart-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .header .minicart .minicart-icon:hover:before {
    color: #000; }
  .header .minicart .minicart-total {
    position: relative; }
  .header .minicart .minicart-quantity {
    position: absolute;
    top: -2px;
    right: -9px;
    background-color: transparent;
    color: #000;
    border-radius: 50%;
    width: 16px;
    height: 16px;
    line-height: 16px;
    display: inline-block;
    text-align: center;
    font-size: 9px; }
  .header .custom-dropdown.show .nav-link {
    color: #000; }

@media (min-width: 1024px) {
  .logo-left .header-banner {
    overflow: visible; }
    .logo-left .header-banner-column-3 .dropdown-menu {
      left: auto;
      right: 0; }
  .logo-left .main-menu {
    background: transparent; }
  .logo-left .header-column-1 {
    padding-left: 0;
    padding-right: 0;
    position: static; }
  .logo-left .nav-center .header-column-1 {
    justify-content: center;
    text-align: center; }
  .logo-left .header-column-2 {
    padding-right: 12px;
    flex-basis: 100px; } }
  @media (min-width: 1024px) and (min-width: 1024px) {
    .logo-left .header-column-2 {
      min-width: calc(25px + (12px * 2)); } }
  @media (min-width: 1024px) and (min-width: 1440px) {
    .logo-left .header-column-2 {
      padding-right: 30px;
      min-width: calc(25px + 12px + 30px); } }

@media (min-width: 1024px) {
  .logo-left .header-column-3 {
    flex-grow: 0; } }
  @media screen and (min-width: 1024px) and (-ms-high-contrast: active), (min-width: 1024px) and (-ms-high-contrast: none) {
    .logo-left .header-column-3 {
      flex: 1; } }

@media (min-width: 1024px) {
    .logo-left .header-column-3 .pull-right {
      margin-left: 12px;
      margin-right: 12px; }
      .logo-left .header-column-3 .pull-right.search {
        margin-right: 0; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link {
    padding-top: calc((83px - 20px) / 2);
    padding-bottom: calc((83px - 20px) / 2);
    position: relative;
    font-size: 16px; }
    .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:before {
      content: '';
      display: block;
      height: 1px;
      background-color: #0C0A08;
      position: absolute;
      top: 100%;
      transition: right 200ms ease-out;
      left: 18px;
      right: 100%;
      z-index: 1001; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item > .nav-link:hover:before,
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.show .nav-link:before {
    right: 18px; }
  .logo-left .navbar.bg-inverse .navbar-nav > .nav-item.highlight > .nav-link {
    color: #BF242B; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item:not(:hover) .nav-link {
    color: #595959;
    transition: color 200ms ease-out; }
  .logo-left .navbar.bg-inverse .navbar-nav.nav-item-hover > .nav-item.highlight .nav-link:hover {
    color: #2B2B2A; } }

.main-menu {
  background-color: #2B2B2A;
  height: 100%; }
  .main-menu .navbar {
    height: 100%; }
    .main-menu .navbar .menu-group,
    .main-menu .navbar .nav.navbar-nav {
      height: 100%; }
      @media (max-width: 1023.98px) {
        .main-menu .navbar .menu-group,
        .main-menu .navbar .nav.navbar-nav {
          flex-wrap: nowrap; } }
    .main-menu .navbar .close-menu button,
    .main-menu .navbar .close-button button {
      background-color: transparent;
      border: 0;
      -webkit-appearance: none; }
    .main-menu .navbar .close-button button:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: inherit;
      font-weight: normal; }
    .main-menu .navbar .close-button button[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .main-menu .navbar .close-button button[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .close-button .btn-group-sm > button.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class*="icon-"],
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title) [class^="icon-"],
      .main-menu .navbar .close-button button[class*="btn-"]:not(.title) .fa {
        display: none; }
    .main-menu .navbar .close-button button span {
      display: none; }
    @media (min-width: 1024px) {
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle:after {
        content: none;
        font-family: "icomoon";
        display: inline-block;
        font-size: 10px;
        color: inherit;
        font-weight: normal; }
      .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title).btn-sm:after, .main-menu .navbar .btn-group-sm.nav-item.custom-dropdown > .dropdown-toggle.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class*="icon-"],
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) [class^="icon-"],
        .main-menu .navbar .nav-item.custom-dropdown > .dropdown-toggle[class*="btn-"]:not(.title) .fa {
          display: none; } }
  .main-menu .dropdown-link,
  .main-menu .nav-link {
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    text-transform: none;
    font-size: 22px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0; }
    .main-menu .dropdown-link:hover,
    .main-menu .nav-link:hover {
      text-decoration: none; }
    @media (min-width: 769px) {
      .main-menu .dropdown-link,
      .main-menu .nav-link {
        font-size: 16px; } }

.slide-up {
  transition-duration: 0.5s;
  transition-timing-function: ease-in;
  max-height: 100px;
  overflow: hidden; }
  .slide-up.hide {
    max-height: 0;
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }

.cookie-warning-messaging.cookie-warning {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
  display: none; }

.valid-cookie-warning {
  background-color: #3091E7;
  color: #FFF;
  white-space: nowrap; }
  .valid-cookie-warning p {
    margin-top: 0;
    margin-bottom: 0.2em;
    padding-right: 2em; }

.checkout-header .header-locale-container .country-selector {
  display: block; }

.checkout-header .header-support-container .support {
  display: block; }

.checkout-header .header-account-container {
  display: block; }

.checkout-header .minicart {
  display: block; }

@media (min-width: 1024px) {
  header .user .dropdown-menu,
  header .support .dropdown-menu {
    border: none;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08); }
    header .user .dropdown-menu .dropdown-item + .dropdown-item,
    header .support .dropdown-menu .dropdown-item + .dropdown-item {
      border: none; }
    header .user .dropdown-menu .dropdown-item .header-link,
    header .support .dropdown-menu .dropdown-item .header-link {
      text-align: center;
      padding: 8px; }
  header .user .nav,
  header .support .nav {
    padding: 16px 0;
    display: block; }
  header.logo-center .header-link {
    font-size: 14px; }
  header.logo-center .support .dropdown-toggle {
    padding: calc((83px - 20px) / 2) 0; }
  header.logo-center .support .dropdown-menu-right {
    right: auto;
    max-width: 225px; }
  header.logo-center .header-column-2 {
    min-width: 25px; } }

html:not(.sticky-header) .transparent-header .header-nav {
  width: 100%;
  transition: background-color 200ms linear; }
  html:not(.sticky-header) .transparent-header .header-nav:not(.fixed) {
    position: absolute; }
  html:not(.sticky-header) .transparent-header .header-nav.fixed .header {
    background-color: transparent; }
  html:not(.sticky-header) .transparent-header .header-nav.transparent-nav {
    transition: color 200ms ease-in-out, box-shadow 200ms ease-in-out; }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark {
      color: #000; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #000;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .brand a {
        filter: brightness(0); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before {
        color: #000; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 40px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .minicart .minicart-quantity {
        color: #FFF;
        background-color: #BF242B; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .nav-link:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .fa-search:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search input {
          color: #000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .site-search .search-field::placeholder {
          color: #000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
          color: #000; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link:before {
            background-color: #000; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-dark:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover .nav-link {
          color: #000; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light {
      color: #FFF; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 18px;
        color: #FFF;
        font-weight: normal; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .brand a {
        filter: brightness(0) invert(1); }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before {
        color: #FFF; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before:before {
          content: "";
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: color;
          font-weight: normal; }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 20px;
            color: color;
            font-weight: normal;
            position: absolute;
            transform: translateX(-30px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title) {
            padding-left: 40px; }
            html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title).btn-sm:before, html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .btn-group-sm > .minicart-icon.btn::before[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 22px;
              color: color;
              font-weight: normal;
              position: absolute;
              transform: translateX(-32px); }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class*="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) [class^="icon-"],
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-icon::before[class*="btn-"]:not(.title) .fa {
            display: none; }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .minicart .minicart-quantity {
        color: #FFF;
        background-color: #000; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .nav-link:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .fa-search:before,
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search input {
          color: #FFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .site-search .search-field::placeholder {
          color: #FFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
          color: #FFF; }
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link:before {
            background-color: #FFF; } }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav.transparent-nav-light:not(.fixed) .navbar .menu-group .nav.navbar-nav > .nav-item:hover .nav-link {
          color: #FFF; } }
    html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) {
      background: transparent;
      border-bottom: transparent;
      transition: background-color 200ms linear;
      width: 100%;
      position: absolute; }
      @media (min-width: 769px) {
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .main-menu {
          background-color: transparent;
          transition: background-color 200ms linear; } }
      html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header {
        transition: color 200ms linear;
        background: transparent; }
        @media (min-width: 769px) {
          html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .navbar .menu-group .nav.navbar-nav > .nav-item:not(:hover) .nav-link {
            transition: color 200ms ease-out; } }
        html:not(.sticky-header) .transparent-header .header-nav.transparent-nav:not(.fixed) .header .site-search {
          border: transparent; }

.header-new#top-header .sticky-search {
  height: auto;
  padding: 12px 24px; }

.header-new#top-header .logo-home {
  margin: 0; }

.header-new#top-header .brand {
  width: auto; }

.header-new#top-header .search-modal form {
  float: initial;
  display: initial; }

.header-new#top-header .search-header {
  cursor: pointer; }

.header-new#top-header .header {
  height: 67px; }
  @media (max-width: 1023.98px) {
    .header-new#top-header .header {
      border-bottom: 1px solid #F8F8F8; } }
  .header-new#top-header .header .brand,
  .header-new#top-header .header .navbar-toggler {
    border: 0; }
  .header-new#top-header .header .navbar-toggler {
    aspect-ratio: 1;
    background-color: #FFF;
    order: 0;
    height: 24px;
    width: 24px;
    border: 0; }
    .header-new#top-header .header .navbar-toggler:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 18px;
      color: #000;
      font-weight: normal; }
    .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .header-new#top-header .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .header-new#top-header .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
      .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
      .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
        display: none; }
    .header-new#top-header .header .navbar-toggler::after, .header-new#top-header .header .navbar-toggler::before {
      border-color: #000; }
    .header-new#top-header .header .navbar-toggler::before {
      border: 0;
      font-size: 23px;
      height: initial;
      width: initial;
      display: block; }
    .mobile-menu-in .header-new#top-header .header .navbar-toggler {
      background-color: #FFF;
      border-radius: 50%; }
      .mobile-menu-in .header-new#top-header .header .navbar-toggler:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 16px;
        color: #000;
        font-weight: normal; }
      .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm, .mobile-menu-in .header-new#top-header .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title).btn-sm:before, .mobile-menu-in .header-new#top-header .header .btn-group-sm > .navbar-toggler.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) [class*="icon-"],
        .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) [class^="icon-"],
        .mobile-menu-in .header-new#top-header .header .navbar-toggler[class*="btn-"]:not(.title) .fa {
          display: none; }
      .mobile-menu-in .header-new#top-header .header .navbar-toggler::before {
        align-content: center;
        position: initial;
        transform: initial;
        display: block;
        font-size: 23px; }
      .mobile-menu-in .header-new#top-header .header .navbar-toggler::after {
        display: none; }
  @media (max-width: 1023.98px) {
    .header-new#top-header .header.container {
      padding: 0 24px; } }
  @media (max-width: 768.98px) {
    .header-new#top-header .header .brand .logo-home {
      width: 34px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .header-new#top-header .header .brand .logo-home {
      width: 34px; } }
  @media (min-width: 1024px) {
    .header-new#top-header .header .brand .logo-home {
      width: 34px; } }

.header-new#top-header .search-mobile .site-search {
  border: 0;
  padding: 0; }

.header-new#top-header .site-search .search-field {
  top: 67px; }

.header-new#top-header .header-nav-row .search-header,
.header-new#top-header .search-mobile .search-facade {
  background-color: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 2px;
  display: flex;
  height: auto;
  padding: 12px;
  width: 100%; }
  @media (min-width: 1024px) {
    .header-new#top-header .header-nav-row .search-header,
    .header-new#top-header .search-mobile .search-facade {
      width: fit-content; } }
  .header-new#top-header .header-nav-row .search-header .fa-search,
  .header-new#top-header .search-mobile .search-facade .fa-search {
    height: 24px;
    padding: 0;
    position: initial;
    transform: initial;
    width: 24px; }
    .header-new#top-header .header-nav-row .search-header .fa-search::before,
    .header-new#top-header .search-mobile .search-facade .fa-search::before {
      font-size: 20px;
      margin: 0;
      padding: 3px 2px;
      vertical-align: middle; }
  .header-new#top-header .header-nav-row .search-header .search-placeholder,
  .header-new#top-header .search-mobile .search-facade .search-placeholder {
    align-self: center;
    color: #000;
    flex-grow: 1;
    flex-shrink: 0; }
    @media (min-width: 1024px) {
      .header-new#top-header .header-nav-row .search-header .search-placeholder,
      .header-new#top-header .search-mobile .search-facade .search-placeholder {
        padding-right: 48px; } }

.header-new#top-header .header-nav-row {
  border: 0; }
  .header-new#top-header .header-nav-row .col {
    flex-grow: 1;
    margin: 0;
    padding: 0; }
  .header-new#top-header .header-nav-row .header-column-1 {
    flex: 0 1 0;
    order: 3;
    padding-left: 24px; }
    @media (min-width: 769px) {
      .header-new#top-header .header-nav-row .header-column-1 {
        order: 1;
        flex: 1 1 0;
        padding-left: 0; } }
  .header-new#top-header .header-nav-row .header-column-2 {
    flex-grow: 0;
    justify-content: center;
    min-width: unset;
    order: 1; }
    @media (min-width: 769px) {
      .header-new#top-header .header-nav-row .header-column-2 {
        order: 2; } }
  .header-new#top-header .header-nav-row .header-column-3 {
    gap: 24px;
    order: 2; }
    @media (min-width: 769px) {
      .header-new#top-header .header-nav-row .header-column-3 {
        order: 3; } }
  .header-new#top-header .header-nav-row .site-search {
    height: auto; }
  .header-new#top-header .header-nav-row .header-account-link {
    display: block;
    text-decoration: none; }
  .header-new#top-header .header-nav-row .nav-column-1 .account-link {
    display: none;
    padding-top: 24px; }
    .header-new#top-header .header-nav-row .nav-column-1 .account-link a {
      color: #595959; }
    .header-new#top-header .header-nav-row .nav-column-1 .account-link .icon-account {
      padding: 0; }
    .header-new#top-header .header-nav-row .nav-column-1 .account-link .account-text {
      margin: auto 0;
      padding-left: 12px;
      text-decoration: underline; }
  .header-new#top-header .header-nav-row .icon-account {
    font-size: 26px; }
  .header-new#top-header .header-nav-row .minicart .minicart-quantity {
    right: 4px;
    top: 9px; }
  .header-new#top-header .header-nav-row .minicart-icon {
    display: block;
    line-height: initial;
    padding: 0.25px 0.5px;
    width: 24px;
    height: 24px; }
    .header-new#top-header .header-nav-row .minicart-icon::before {
      font-size: 23px;
      line-height: 23px;
      vertical-align: middle;
      width: 24px;
      height: 24px; }

.header-new#top-header .nav-column.nav-column-1 {
  overflow-y: auto;
  padding-top: 48px; }
  .header-new#top-header .nav-column.nav-column-1 .nav-items {
    display: flex;
    flex-direction: column;
    gap: 12px; }

.header-new#top-header .nav-column.nav-column-2 {
  overflow-y: auto;
  max-height: 100dvh; }
  @media (max-width: 1023.98px) {
    .header-new#top-header .nav-column.nav-column-2 {
      height: calc(100dvh - 67px - 70px); } }

.header-new#top-header .nav-column {
  height: 100%;
  padding: 48px 24px; }
  @media (min-width: 1024px) {
    .header-new#top-header .nav-column {
      padding: 48px;
      border-right: 1px solid #D6D6D6;
      width: 385px; } }

.header-new#top-header .nav-column > .dropdown-item:not(.featured-item) {
  border-bottom: 1px solid #D6D6D6;
  padding: 24px 0; }

.header-new#top-header .nav-column > .dropdown-item:first-of-type {
  padding-top: 0; }

.header-new#top-header .nav-column > .dropdown-item:last-of-type {
  border-bottom: 0;
  padding-bottom: 0; }

.header-new#top-header .nav-column > .dropdown-item.direct-category-link {
  border: 0;
  padding: 0; }
  @media (min-width: 1024px) {
    .header-new#top-header .nav-column > .dropdown-item.direct-category-link {
      padding-bottom: 12px; } }

.header-new#top-header .nav-column > .dropdown-item.featured-item + .direct-category-link {
  padding-top: 24px; }

@media (max-width: 1023.98px) {
  .header-new#top-header .dropdown-toggle::after {
    display: none; } }

.header-new#top-header .dropdown-menu {
  top: 0;
  margin: 0;
  max-width: fit-content;
  min-width: unset;
  right: initial;
  display: flex;
  overflow: auto; }
  @media (max-width: 1023.98px) {
    .header-new#top-header .dropdown-menu .dropdown-menu {
      display: flex;
      flex-direction: column;
      position: relative;
      left: 100%; }
      .header-new#top-header .dropdown-menu .dropdown-menu.show {
        transform: translateX(-100%); }
        .header-new#top-header .dropdown-menu .dropdown-menu.show .nav-menu {
          display: none; }
    .header-new#top-header .dropdown-menu .dropdown-link {
      padding-left: 0;
      padding-right: 0; }
    .header-new#top-header .dropdown-menu .dropdown-toggle {
      padding-bottom: 0; } }
  @media (min-width: 1024px) {
    .header-new#top-header .dropdown-menu {
      border: 0;
      gap: 12px;
      visibility: hidden;
      justify-content: start;
      transition: transform 200ms ease-in-out;
      height: calc(100dvh - 67px); }
      .header-new#top-header .dropdown-menu:not(.show) {
        left: 0;
        transform: translateX(-100%); }
      .header-new#top-header .dropdown-menu.show {
        visibility: initial;
        left: 100%;
        transform: translateX(0);
        z-index: -1; }
        .header-new#top-header .dropdown-menu.show .dropdown-menu {
          visibility: initial; }
      .header-new#top-header .dropdown-menu .dropdown-menu {
        height: auto;
        transform: translateX(0); } }
  .header-new#top-header .dropdown-menu .featured-item .dropdown-toggle + .dropdown-menu {
    padding-top: 12px; }
    @media (min-width: 1024px) {
      .header-new#top-header .dropdown-menu .featured-item .dropdown-toggle + .dropdown-menu {
        padding-top: 24px; } }
    .header-new#top-header .dropdown-menu .featured-item .dropdown-toggle + .dropdown-menu .dropdown-link {
      padding-bottom: 0;
      letter-spacing: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0; }
      @media (min-width: 1024px) {
        .header-new#top-header .dropdown-menu .featured-item .dropdown-toggle + .dropdown-menu .dropdown-link {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0; } }
  .header-new#top-header .dropdown-menu::before {
    width: auto; }

.header-new#top-header .dropdown-link {
  text-wrap: nowrap; }
  @media (min-width: 1024px) {
    .header-new#top-header .dropdown-link {
      padding: 0; } }

.header-new#top-header .featured-item > .dropdown-link {
  font-family: "archia-medium", sans-serif;
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    .header-new#top-header .featured-item > .dropdown-link {
      font-size: 20px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

.header-new#top-header .custom-dropdown:not(.featured-item) > .dropdown-link {
  margin-bottom: 24px; }

.header-new#top-header .nav-link.highlight,
.header-new#top-header .nav-item.highlight > .dropdown-toggle {
  color: #BF242B; }
  .header-new#top-header .nav-link.highlight .current-category-arrow,
  .header-new#top-header .nav-item.highlight > .dropdown-toggle .current-category-arrow {
    color: initial; }

.header-new#top-header .nav-item a {
  margin-bottom: 0; }
  .header-new#top-header .nav-item a:not(.shop-all) {
    text-decoration: none; }

.header-new#top-header .shop-all {
  text-decoration: underline; }

.header-new#top-header .extra-links {
  padding-top: 96px; }
  .header-new#top-header .extra-links a {
    color: #595959; }

.header-new#top-header .category-thumbnails {
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  padding-top: 48px; }
  .header-new#top-header .category-thumbnails:only-child {
    padding-top: 0; }
  .header-new#top-header .category-thumbnails .category-thumbnail .category-name {
    text-wrap: nowrap; }
  .header-new#top-header .category-thumbnails .category-image img {
    aspect-ratio: 1;
    height: 48px;
    margin-right: 12px;
    width: 48px; }

.header-new#top-header .nav-item.show .current-category-arrow {
  visibility: visible; }

.header-new#top-header .current-category-arrow {
  margin: auto 0;
  visibility: hidden; }
  .header-new#top-header .current-category-arrow::before {
    font-size: 24px; }

.header-new#top-header .top-category {
  display: none; }

@media (min-width: 1024px) {
  .header-new#top-header .dropdown-item.top-category,
  .header-new#top-header .nav-menu {
    display: none; } }

.header-new#top-header .featured-item {
  border-right: 0; }
  .header-new#top-header .featured-item .dropdown-menu {
    gap: 16px; }
  .header-new#top-header .featured-item .dropdown-link:not(.dropdown-toggle) {
    color: #595959; }

.header-new#top-header .featured-item:has(+ .dropdown-item:not(.d-lg-none)) {
  padding-bottom: 24px; }

@media (max-width: 1023.98px) {
  .header-new#top-header .dropdown-menu,
  .header-new#top-header .main-menu {
    max-width: initial;
    width: 100%; } }

@media (min-width: 1024px) {
  .header-new#top-header .main-menu .menu-group {
    border-top: 1px solid #D6D6D6;
    height: calc(100dvh - 67px);
    transition: transform 200ms ease-in-out;
    position: fixed;
    transform: translateX(-100%);
    top: 67px;
    left: 0;
    max-width: fit-content; }
  .header-new#top-header .main-menu.in .menu-group {
    transform: translateX(0); }
  .header-new#top-header .main-menu.in .navbar-toggler {
    display: block; } }

.header-new#top-header .navbar-nav {
  background-color: #FFF;
  flex-direction: column; }

@media (max-width: 1023.98px) {
  .header-new#top-header .mobile-nav-content-wrapper {
    padding: 0; } }

.header-new#top-header .desktop-category-links {
  display: none; }
  @media (min-width: 1024px) {
    .header-new#top-header .desktop-category-links {
      cursor: pointer;
      display: flex;
      margin: auto;
      order: 1;
      padding-left: 24px;
      height: 100%; }
      .header-new#top-header .desktop-category-links .category {
        margin-bottom: 0;
        padding: 0 24px; }
        .header-new#top-header .desktop-category-links .category .category-name {
          position: relative;
          height: 100%;
          align-content: center; }
          .header-new#top-header .desktop-category-links .category .category-name:before {
            content: '';
            display: block;
            height: 1px;
            background-color: #0C0A08;
            position: absolute;
            transition: width 200ms ease-out;
            z-index: 1001;
            bottom: -1px;
            width: 0; }
        .header-new#top-header .desktop-category-links .category:hover .category-name:before {
          width: 100%; }
        .header-new#top-header .desktop-category-links .category:first-of-type {
          margin-left: -24px; }
        .header-new#top-header .desktop-category-links .category:last-of-type {
          margin-right: -24px; } }
  @media (min-width: 1440px) {
    .header-new#top-header .desktop-category-links {
      padding-left: 48px; } }

.header-new#top-header .menu-subcategories {
  padding: 0; }
  @media (max-width: 1023.98px) {
    .header-new#top-header .menu-subcategories {
      border-top: 0; } }
  @media (min-width: 1024px) {
    .header-new#top-header .menu-subcategories {
      height: 100%;
      width: min-content; } }

.header-new#top-header .close-menu button {
  display: flex;
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0; }
  @media (min-width: 1024px) {
    .header-new#top-header .close-menu button {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  @media (max-width: 1023.98px) {
    .header-new#top-header .close-menu button {
      padding: 24px; } }
  .header-new#top-header .close-menu button .icon-arrow-left {
    align-self: center;
    float: initial;
    margin: 0;
    padding-right: 24px; }

.header-new#top-header .header-utility-mobile {
  padding-top: 0;
  margin: 0; }
  .header-new#top-header .header-utility-mobile::before {
    display: none; }
  .header-new#top-header .header-utility-mobile .additional-link {
    margin-bottom: 16px; }
  .header-new#top-header .header-utility-mobile a {
    font-size: unset; }

@media (max-width: 1023.98px) {
  .header-new#top-header .main-menu.menu-toggleable-left {
    height: calc(100vh - 67px);
    top: 67px; }
    .header-new#top-header .main-menu.menu-toggleable-left.in + .navbar-toggler::after, .header-new#top-header .main-menu.menu-toggleable-left.in + .navbar-toggler::before {
      left: 0;
      border-bottom: 0; }
    .header-new#top-header .main-menu.menu-toggleable-left.in .show > .dropdown-menu .dropdown-menu {
      transform: translateX(-100%); }
      .header-new#top-header .main-menu.menu-toggleable-left.in .show > .dropdown-menu .dropdown-menu .nav-menu {
        display: none; } }

.header-utility {
  background-color: #F8F8F8; }
  .header-utility .header-account-container,
  .header-utility .header-utility-links a {
    color: #595959;
    text-decoration: none;
    font-size: 10px;
    font-weight: 400; }
  .header-utility .header-utility-links {
    display: flex; }
    .header-utility .header-utility-links .utility-link {
      padding: 4px 20px;
      border-right: 1px solid #d2d2d2; }

.header-utility-mobile {
  position: relative;
  padding-top: 12px;
  margin: 12px 0; }
  .header-utility-mobile::before {
    content: '';
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: #D6D6D6;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%); }
  .header-utility-mobile .header-utility-links .utility-link,
  .header-utility-mobile .mobile-menu-additional-links .additional-link {
    margin-bottom: 12px; }
  .header-utility-mobile a {
    text-decoration: none;
    font-size: 13px; }

.header-banner {
  background-color: #F8F8F8; }
  .header-banner:has(.countdown-timer) {
    max-height: 100%; }

.header-banner .countdown-timer {
  margin-bottom: -12px;
  margin-top: -12px; }

.header-account-container .dropdown-toggle::after {
  display: none; }

.header .minicart .minicart-quantity {
  top: 8px;
  right: 3px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px; }

.header-support-container a {
  text-decoration: none;
  color: #595959; }

.header-nav {
  border-bottom: 1px solid #D6D6D6; }
  @media (max-width: 1023.98px) {
    .header-nav .header.container {
      padding: 0; } }
  .header-nav .header-nav-row {
    border-bottom: 1px solid #F8F8F8; }

#maincontent {
  margin-bottom: 48px; }

@media (min-width: 769px) {
  .megamenu-open #maincontent {
    opacity: .5; } }

.dropdown-country-selector {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08); }
  .dropdown-country-selector .dropdown-item:last-child {
    padding-bottom: 48px;
    margin-bottom: 48px; }
    @media (min-width: 1024px) {
      .dropdown-country-selector .dropdown-item:last-child {
        padding-bottom: 0;
        margin-bottom: 0; } }

.header-account-container {
  display: inline-flex;
  margin: auto 0; }
  .header-account-container a {
    color: inherit;
    text-decoration: inherit; }
  .header-account-container .account-link {
    padding: 4px 20px; }
  .header-account-container .logout {
    border-left: 1px solid #d2d2d2; }

.header .navbar-toggler {
  padding: 0;
  width: 41px;
  height: 41px;
  border-left: 1px solid #D6D6D6;
  border-radius: 0;
  position: relative; }
  .header .navbar-toggler::before, .header .navbar-toggler::after {
    content: '';
    display: inline-block;
    width: 21px;
    height: 6px;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .header .navbar-toggler::after {
    display: none; }
  .mobile-menu-in .header .navbar-toggler {
    background-color: #000; }
    .mobile-menu-in .header .navbar-toggler::before, .mobile-menu-in .header .navbar-toggler::after {
      display: inline-block;
      height: 1px;
      border-color: #FFF;
      left: 25%; }
    .mobile-menu-in .header .navbar-toggler::before {
      transform: rotate(45deg); }
    .mobile-menu-in .header .navbar-toggler::after {
      transform: rotate(-45deg); }

@media (max-width: 768.98px) {
  #dropdownCountrySelector:after, #dropdownCountrySelectorMobile:after {
    content: ""; }
  .dropdown-country-selector {
    overflow: scroll;
    max-height: 90vh; }
  .header .header-column-3 > .pull-right {
    margin-right: 4px; } }

@media (max-width: 1023.98px) {
  .checkout-header .header-nav {
    height: unset; } }

.locator {
  margin-left: 0; }

.locator-icon {
  height: 20px;
  position: relative;
  top: -2px; }

.sticky-header .fixed-header-enhanced .header-nav.header-near-top {
  animation: slide-down 400ms ease-out forwards; }

.site-search {
  position: relative;
  width: 100%; }
  @media (min-width: 1024px) {
    .site-search form {
      display: flex;
      align-items: center; } }
  .site-search .search-field {
    border-top: none; }
  .site-search .fa-close,
  .site-search .fa-search {
    position: absolute;
    border: none;
    padding: 0;
    background-color: transparent; }
  .site-search .fa-close:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: #0C0A08;
    font-weight: normal; }
  .site-search .fa-close[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .site-search .fa-close[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-close[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .site-search .fa-close[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-close.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-close[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-close[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .fa-search:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: #0C0A08;
    font-weight: normal; }
  .site-search .fa-search[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .site-search .fa-search[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .site-search .fa-search[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .site-search .fa-search[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .fa-search.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .site-search .fa-search[class*="btn-"]:not(.title) [class*="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) [class^="icon-"],
    .site-search .fa-search[class*="btn-"]:not(.title) .fa {
      display: none; }
  .site-search .reset-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    right: 3px;
    padding: 0 5px;
    z-index: 1; }
    .site-search .reset-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #0C0A08;
      font-weight: normal; }
    .site-search .reset-button[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .site-search .reset-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .site-search .reset-button[class*="btn-"]:not(.title).btn-sm, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .site-search .reset-button[class*="btn-"]:not(.title).btn-sm:before, .site-search .btn-group-sm > .reset-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #0C0A08;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .site-search .reset-button[class*="btn-"]:not(.title) [class*="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) [class^="icon-"],
      .site-search .reset-button[class*="btn-"]:not(.title) .fa {
        display: none; }
  .site-search input {
    height: 40px;
    padding-right: 40px;
    appearance: none; }
    @media (min-width: 1024px) {
      .site-search input {
        border-radius: 0; } }
    .site-search input:hover {
      box-shadow: none; }
  @media (min-width: 1024px) {
    .site-search {
      float: right;
      display: block;
      height: 83px;
      border-width: 0 1px 0 1px;
      border-color: transparent;
      border-style: solid; }
      .site-search form {
        float: right; }
      .site-search .search-field {
        font-family: "Roboto", sans-serif;
        text-decoration: none;
        text-transform: none;
        font-size: 22px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0;
        border: none;
        text-align: left;
        color: #0C0A08;
        padding: 8px 10px;
        transition: width 200ms ease-out;
        width: 164px;
        background: transparent;
        text-decoration: none; }
        .site-search .search-field:hover {
          text-decoration: none; } }
    @media (min-width: 1024px) and (min-width: 769px) {
      .site-search .search-field {
        font-size: 16px; } }
  @media (min-width: 1024px) {
        .site-search .search-field::placeholder {
          color: #0C0A08; }
        .site-search .search-field:focus {
          box-shadow: none; }
        .site-search .search-field:hover {
          text-decoration: none; }
      .site-search .fa-close {
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .site-search .fa-close::before,
      .site-search .fa-search::before,
      .site-search .reset-button::before {
        font-size: 22px; } }
  @media (min-width: 1440px) {
    .site-search .search-field:focus {
      width: 164px; } }

.search-icon-only .site-search {
  border-color: transparent;
  border-style: solid;
  border-width: 0 1px 0 1px;
  transition: border 200ms ease-out;
  width: auto;
  padding-left: 18px; }
  .search-icon-only .site-search .search-field {
    width: 0;
    display: none; }

.search-icon-only .site-search .search-field {
  width: 100%;
  display: inline-block; }
  @media (max-width: 1023.98px) {
    .search-icon-only .site-search .search-field {
      width: calc(100vw - 36px);
      padding: 0 20px;
      background: #FFF; } }

@media (min-width: 1024px) {
  .search-icon-only .site-search.expanded {
    border-color: transparent; }
    .search-icon-only .site-search.expanded .search-field {
      width: 164px; }
    .search-icon-only .site-search.expanded button {
      color: #2B2B2A; }
  .logo-center .search-icon-only .site-search {
    height: 83px;
    border-bottom: 0;
    border-top: 0; } }

@media (min-width: 1440px) {
  .search-icon-only .site-search.expanded .search-field {
    width: 164px; } }

.search-modal {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1050;
  height: 100vh;
  background-color: #FFF;
  width: 564px; }

.search-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.5;
  z-index: 300; }

.search-field-wrapper {
  position: relative; }

.suggestions-wrapper .suggestions-header {
  font-family: "archia-medium", sans-serif;
  font-size: 15px;
  margin-bottom: 5px;
  color: #000;
  text-transform: none;
  padding-top: 12px;
  background: transparent; }
  .suggestions-wrapper .suggestions-header:not(:first-child) {
    border-top: 1px solid #D6D6D6; }

.suggestions-wrapper .suggestions-items {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  padding-bottom: 12px; }

.suggestions-wrapper .suggestions-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-top: 3px;
  padding-bottom: 3px; }

.suggestions-wrapper .suggestions-item .swatch-thumbnail {
  width: 30px;
  height: 30px;
  background-color: #FFF;
  background-size: cover;
  display: block;
  position: relative;
  text-align: center;
  border-radius: 0;
  margin-right: 12px; }
  .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value {
    transition: box-shadow 200ms ease-out; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value:hover:not(.unavailable) {
      box-shadow: inset 0px -2px 0px #000; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected {
      box-shadow: inset 0px -2px 0px #000; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 11px;
        color: #FFF;
        font-weight: normal; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.color-value.selected.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected[class*="btn-"]:not(.title) .fa {
          display: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.selected::after {
        position: absolute;
        top: -7.33333px;
        right: -7.33333px;
        display: none;
        background: rgba(0, 0, 0, 0.7);
        width: 22px;
        height: 22px;
        line-height: 22px;
        padding-left: 1px;
        text-align: center;
        border-radius: 50%;
        z-index: 1; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable {
      pointer-events: none;
      pointer-events: auto;
      position: relative; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 15px);
        width: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:after {
        background-color: #D6D6D6; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:before {
        background-color: #FFF;
        margin-right: 1px;
        box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
        width: 2px; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable {
      pointer-events: none;
      pointer-events: auto;
      cursor: not-allowed;
      position: relative; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 15px);
        width: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:after {
        background-color: #D6D6D6; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.color-value.unavailable:before {
        background-color: #FFF;
        margin-right: 1px;
        box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
        width: 2px; }
  .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value {
    transition: border 200ms ease-out, box-shadow 200ms ease-out; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value:not(.unavailable):hover {
      border-color: transparent;
      box-shadow: none; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover {
      background: #FFF;
      color: #000;
      border-color: transparent;
      box-shadow: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 8px;
        color: #FFF;
        font-weight: normal; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .suggestions-wrapper .suggestions-item .btn-group-sm > .swatch-thumbnail.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
        .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
          display: none; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected::after, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.selected:hover::after {
        position: absolute;
        top: -5.33333px;
        right: -5.33333px;
        background: #0C0A08;
        width: 16px;
        height: 16px;
        line-height: 16px;
        padding-left: 1px;
        text-align: center;
        border-radius: 50%;
        display: none; }
    .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable {
      pointer-events: none;
      pointer-events: auto;
      position: relative; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable:not(.bis-enabled):before, .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable:not(.bis-enabled):after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: calc(100% + 15px);
        width: 1px; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable:not(.bis-enabled):after {
        background-color: #D6D6D6; }
      .suggestions-wrapper .suggestions-item .swatch-thumbnail.non-color-swatch-value.unavailable:not(.bis-enabled):before {
        background-color: #FFF;
        margin-right: 1px;
        box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
        width: 2px; }

.suggestions-wrapper .suggestions-item .name {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.suggestions-wrapper .suggestions-item .category-parent {
  color: #595959; }

.suggestions-wrapper .suggestions-item.selected {
  background-color: transparent; }

@media (min-width: 1024px) {
  .site-search form {
    height: 100%; } }

.site-search .search-field-wrapper .search-field {
  padding-left: 32px;
  padding-right: 32px; }

.site-search .search-field {
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0;
  color: #595959;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  background-color: #F8F8F8;
  padding-left: 32px;
  padding-right: 32px;
  width: 164px; }
  @media (min-width: 1024px) {
    .site-search .search-field {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  @media (max-width: 1023.98px) {
    .site-search .search-field {
      left: 6px;
      right: 0;
      top: 42px;
      padding: 0; } }

.site-search .fa-close-icon {
  background: url(../images/close-icon.svg) no-repeat center; }
  @media (max-width: 1023.98px) {
    .site-search .fa-close-icon {
      width: 41px;
      height: 41px;
      right: 0;
      position: absolute;
      margin-right: 22px; } }

@media (min-width: 1024px) {
  .site-search .fa-search,
  .site-search .fa-close {
    top: 54%;
    left: 6px;
    transform: translateY(-50%); } }

@media (max-width: 1023.98px) {
  .site-search .fa-search,
  .site-search .fa-close {
    width: 41px;
    height: 41px;
    right: 0;
    left: unset;
    padding-right: 55px; } }

.sticky-search {
  padding: 12px 0;
  background: #FFF;
  height: 64px; }
  .sticky-search.row {
    height: auto; }
  .sticky-search .search-field {
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    background: transparent; }

.header .navbar-toggler-search {
  padding-left: 0; }
  .header .navbar-toggler-search:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: #0C0A08;
    font-weight: normal; }
  .header .navbar-toggler-search[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .header .navbar-toggler-search[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .header .navbar-toggler-search[class*="btn-"]:not(.title).btn-sm, .header .btn-group-sm > .navbar-toggler-search.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .header .navbar-toggler-search[class*="btn-"]:not(.title).btn-sm:before, .header .btn-group-sm > .navbar-toggler-search.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .header .navbar-toggler-search[class*="btn-"]:not(.title) [class*="icon-"],
    .header .navbar-toggler-search[class*="btn-"]:not(.title) [class^="icon-"],
    .header .navbar-toggler-search[class*="btn-"]:not(.title) .fa {
      display: none; }

.mobile-menu-in .header .navbar-toggler.navbar-toggler-search:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #0C0A08;
  font-weight: normal; }

.mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #0C0A08;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title).btn-sm, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.navbar-toggler-search.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title).btn-sm:before, .mobile-menu-in .header .btn-group-sm > .navbar-toggler.navbar-toggler-search.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title) [class*="icon-"],
  .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title) [class^="icon-"],
  .mobile-menu-in .header .navbar-toggler.navbar-toggler-search[class*="btn-"]:not(.title) .fa {
    display: none; }

.search-mobile .fa-search::before {
  font-size: 22px;
  margin-top: 4px; }

@media (max-width: 1439.98px) {
  .main-menu.menu-toggleable-left .search-mobile .form-control.search-field {
    border-color: #D6D6D6;
    border-top: 1px solid #D6D6D6; } }

.form-control.search-field {
  font-size: 16px;
  color: #595959;
  background-color: #FFF; }
  @media (max-width: 1439.98px) {
    .form-control.search-field {
      padding-right: 44px; } }

.search-modal-header, .suggestions-header {
  font-family: "archia-medium", sans-serif;
  font-size: 15px;
  text-transform: none;
  font-size: 17px;
  font-weight: 600; }

.search-modal-header {
  position: fixed;
  top: 0;
  right: -564px;
  width: 100dvw;
  padding: 24px;
  border-bottom: 1px solid #D6D6D6;
  background-color: #FFF;
  z-index: 1051;
  transition: right 0.3s linear; }
  @media (min-width: 1024px) {
    .search-modal-header {
      width: 564px;
      padding: 24px 48px; } }
  .search-modal-header.active {
    right: 0; }

.search-modal {
  overflow-y: scroll;
  margin-top: 72px;
  width: 100dvw;
  height: calc(100dvh - 72px);
  right: -564px;
  transition: right 0.3s linear; }
  @media (min-width: 1024px) {
    .search-modal {
      width: 564px; } }
  .search-modal.active {
    right: 0; }
  .search-modal .form-control.search-field {
    width: 100%;
    border: 1px solid #D6D6D6;
    border-radius: 20px;
    padding-right: 48px; }
  .search-modal .search-field-wrapper {
    padding: 12px 24px;
    border-bottom: 1px solid #D6D6D6; }
    .search-modal .search-field-wrapper input.search-field::placeholder {
      color: #595959;
      opacity: 1; }
    @media (min-width: 1024px) {
      .search-modal .search-field-wrapper {
        padding: 12px 48px; } }
  .search-modal .fa-search {
    top: 50%;
    left: unset;
    transform: translateY(-50%); }
    @media (max-width: 1023.98px) {
      .search-modal .fa-search {
        padding-right: 66px; } }
    @media (min-width: 1024px) {
      .search-modal .fa-search {
        top: 54%;
        right: 72px; } }
  .search-modal .suggestions-header {
    padding: 24px 24px 12px 24px;
    margin: 0; }
    .search-modal .suggestions-header:not(:first-child) {
      border-top: 1px solid #D6D6D6; }
    @media (min-width: 1024px) {
      .search-modal .suggestions-header {
        padding: 24px 48px 12px; } }
    .search-modal .suggestions-header .search-show-more,
    .search-modal .suggestions-header .search-show-less,
    .search-modal .suggestions-header .suggestions-view-all {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px; }
  .search-modal a.name {
    text-decoration: none; }
  .search-modal .search-show-more, .search-modal .search-show-less {
    cursor: pointer;
    font-size: 12px;
    user-select: none; }
  .search-modal .suggestions-submit {
    color: #000;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline; }
  .search-modal .suggestions-items {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 4px 24px 24px;
    border-bottom: 1px solid #D6D6D6; }
    @media (min-width: 1024px) {
      .search-modal .suggestions-items {
        padding: 4px 48px 24px; } }
    .search-modal .suggestions-items.row {
      margin: 0;
      padding: 12px 6px; }
    .search-modal .suggestions-items.category-suggestions {
      overflow: hidden;
      height: calc(1.5em + (12px * 3));
      margin-bottom: 18px;
      border-bottom: unset; }
      .search-modal .suggestions-items.category-suggestions.expanded {
        height: auto;
        margin-bottom: 0; }
    .search-modal .suggestions-items.suggestions-gap {
      gap: 8px; }
    .search-modal .suggestions-items.suggestions-tiles {
      display: grid;
      grid-template-columns: 50% 50%;
      gap: 24px 6px;
      padding-top: 12px; }
      .search-modal .suggestions-items.suggestions-tiles .suggestions-item {
        flex-basis: 45%;
        flex-grow: 1;
        flex-shrink: 1; }
      .search-modal .suggestions-items.suggestions-tiles [id^="product"] img {
        padding: 12px; }
    .search-modal .suggestions-items div.suggestions-item {
      transition: opacity 0.3s linear;
      opacity: 1; }
      .search-modal .suggestions-items div.suggestions-item[id^="phrase"], .search-modal .suggestions-items div.suggestions-item[id^="category"], .search-modal .suggestions-items div.suggestions-item.pill {
        padding: 12px;
        border: 1px solid #D6D6D6;
        border-radius: 4px; }
      .search-modal .suggestions-items div.suggestions-item[id^="category"].transparent {
        opacity: 0; }
      .search-modal .suggestions-items div.suggestions-item[id^="category"].hidden {
        height: 0;
        padding: 0; }
    .search-modal .suggestions-items a {
      padding: 0;
      display: block; }
      .search-modal .suggestions-items a .product-name, .search-modal .suggestions-items a .content-name {
        white-space: normal;
        padding: 12px 0; }
    .search-modal .suggestions-items .suggestion-image {
      background-color: #F8F8F8;
      width: 100%;
      text-align: center; }
    .search-modal .suggestions-items .price {
      font-family: "Roboto", sans-serif;
      font-weight: bold;
      line-height: 20px;
      margin-bottom: 12px; }
      .search-modal .suggestions-items .price:last-child {
        margin-bottom: 0; }
    .search-modal .suggestions-items .available-colors {
      color: #595959; }
  .search-modal .no-results {
    display: none;
    padding: 24px; }
    @media (min-width: 1024px) {
      .search-modal .no-results {
        padding: 24px 48px; } }

.suggestions {
  padding: 0; }

.hero {
  position: relative;
  overflow: hidden;
  display: flex; }
  .hero .hero-container {
    display: flex;
    padding: 12px;
    height: 100%;
    width: 100%; }
    @media (min-width: 769px) {
      .hero .hero-container {
        padding: 12px; } }
    @media (min-width: 1024px) {
      .hero .hero-container {
        padding: 50px; } }
    @media (min-width: 1440px) {
      .full-bleed .hero .hero-container {
        max-width: 1920px;
        margin-left: auto;
        margin-right: auto; } }
    .hero .hero-container .hero-background-link {
      z-index: 0;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
      .hero .hero-container .hero-background-link .video-container {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
        .hero .hero-container .hero-background-link .video-container .video-player {
          display: none; }
  .hero .embedded-component {
    width: 100%; }
    .hero .embedded-component:empty {
      display: none; }
    .hero .embedded-component .experience-region:empty {
      display: none; }
  .hero.sm-short {
    min-height: 150px; }
  .hero.sm-medium {
    min-height: 250px; }
  .hero.sm-tall {
    min-height: 600px; }
  .hero.sm-screen-height {
    min-height: calc(100vh - 42px - 1px - 22px - 12px - 12px); }
    .transparent-header ~ div .hero.sm-screen-height {
      min-height: calc(100vh - 22px - 12px - 12px); }
  @media (min-width: 769px) {
    .hero.md-short {
      min-height: 80px; }
    .hero.md-medium {
      min-height: 300px; }
    .hero.md-tall {
      min-height: 650px; }
    .hero.md-screen-height {
      min-height: calc(100vh - 60px - 1px - 22px - 12px - 12px); }
      .transparent-header ~ div .hero.md-screen-height {
        min-height: calc(100vh - 22px - 12px - 12px); } }
  @media (min-width: 1024px) {
    .hero.lg-short {
      min-height: 80px; }
    .hero.lg-medium {
      min-height: 350px; }
    .hero.lg-tall {
      min-height: 700px; }
    .hero.lg-screen-height {
      min-height: calc(100vh - 83px - 1px - 22px - 12px - 12px); }
      .transparent-header ~ div .hero.lg-screen-height {
        min-height: calc(100vh - 22px - 12px - 12px); } }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero {
      height: 0; }
      .hero.no-image {
        height: auto; } }
  .hero.short {
    min-height: 150px; }
    @media (min-width: 769px) {
      .hero.short {
        min-height: 80px; } }
    @media (min-width: 1024px) {
      .hero.short {
        min-height: 80px; } }
  .hero.medium {
    min-height: 250px; }
    @media (min-width: 769px) {
      .hero.medium {
        min-height: 300px; } }
    @media (min-width: 1024px) {
      .hero.medium {
        min-height: 350px; } }
  .hero.tall {
    min-height: 600px; }
    @media (min-width: 769px) {
      .hero.tall {
        min-height: 650px; } }
    @media (min-width: 1024px) {
      .hero.tall {
        min-height: 700px; } }
  .hero.screen-height {
    min-height: calc(100vh - 42px - 1px - 22px - 12px - 12px); }
    @media (min-width: 769px) {
      .hero.screen-height {
        min-height: calc(100vh - 60px - 1px - 22px - 12px - 12px); } }
    @media (min-width: 1024px) {
      .hero.screen-height {
        min-height: calc(100vh - 83px - 1px - 22px - 12px - 12px); } }
    .transparent-header ~ div .hero.screen-height {
      min-height: calc(100vh - 22px - 12px - 12px); }
      @media (min-width: 769px) {
        .transparent-header ~ div .hero.screen-height {
          min-height: calc(100vh - 22px - 12px - 12px); } }
      @media (min-width: 1024px) {
        .transparent-header ~ div .hero.screen-height {
          min-height: calc(100vh - 22px - 12px - 12px); } }

.hero-media {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center; }
  .full-bleed .hero-media {
    width: calc(100vw + 2px); }
  .hero-media.hero-media-tablet, .hero-media.hero-media-desktop, .hero-media.hero-media-hd {
    display: none; }
  @media (min-width: 769px) {
    .hero-media.hero-media-mobile .hero-media.hero-media-desktop {
      display: none; }
    .hero-media.hero-media-tablet {
      display: block; } }
  @media (min-width: 1024px) {
    .hero-media.hero-media-mobile, .hero-media.hero-media-tablet {
      display: none; }
    .hero-media.hero-media-desktop {
      display: block; } }
  @media (min-width: 1440px) {
    .hero-media.hero-media-desktop {
      display: none; }
    .hero-media.hero-media-hd {
      display: block; } }
  .hero-media.video-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    right: auto;
    transform: none;
    transition: opacity 200ms ease-out; }
    .hero-media.video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-16-9 {
        padding-top: calc(9 / 16 * 100%);
        padding-left: calc(16 / 9 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-9-16 {
        padding-top: calc(16 / 9 * 100%);
        padding-left: calc(9 / 16 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-4-3 {
        padding-top: calc(3 / 4 * 100%);
        padding-left: calc(4 / 3 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-3-4 {
        padding-top: calc(4 / 3 * 100%);
        padding-left: calc(3 / 4 * 100vh); }
      .hero-media.video-container .video-aspect-ratio.aspect-ratio-1-1 {
        padding-top: calc(1 / 1 * 100%);
        padding-left: calc(1 / 1 * 100vh); }
      .hero-media.video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .hero-media.video-container .video-aspect-ratio iframe,
      .hero-media.video-container .video-aspect-ratio video {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .hero-media.video-container .video-aspect-ratio iframe.video-player,
        .hero-media.video-container .video-aspect-ratio video.video-player {
          position: absolute; }
    .hero-media.video-container ~ .replay-video {
      z-index: 1;
      position: absolute;
      opacity: 0;
      transition: opacity 200ms ease-out; }
      .align-items-start .hero-media.video-container ~ .replay-video,
      .align-items-center .hero-media.video-container ~ .replay-video {
        bottom: 12px; }
      .align-items-end .hero-media.video-container ~ .replay-video {
        top: 12px; }
        .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
          top: 54px; }
          @media (min-width: 769px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 72px; } }
          @media (min-width: 1024px) {
            .transparent-header ~ div .align-items-end .hero-media.video-container ~ .replay-video {
              top: 95px; } }
      .justify-content-md-start .hero-media.video-container ~ .replay-video {
        right: 12px; }
      .justify-content-md-end .hero-media.video-container ~ .replay-video,
      .justify-content-md-center .hero-media.video-container ~ .replay-video {
        left: 12px; }
    .hero-media.video-container.video-stopped {
      opacity: 0; }
      .hero-media.video-container.video-stopped ~ .replay-video {
        transition: opacity 200ms ease-out 600ms;
        opacity: 1; }
  .hero-media .hero-banner-image {
    width: 100%; }

.hero-text {
  overflow: visible;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  z-index: 100; }
  @media (min-width: 769px) {
    .hero-text.border-left-white-desktop {
      padding-left: 86px;
      border-left: 1px solid #FFF; } }
  .text-in-card .hero-text {
    padding: 12px;
    border-width: 1px;
    border-style: solid; }
    @media (min-width: 769px) {
      .text-in-card .hero-text {
        padding: 12px; } }
    @media (min-width: 1024px) {
      .text-in-card .hero-text {
        padding: 12px; } }
    .text-in-card .hero-text:not([class*='border--']) {
      border-color: transparent; }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).dark-theme {
      background: rgba(0, 0, 0, 0.7); }
    .text-in-card .hero-text.semitransparent:not([class*='background--']).light-theme {
      background: rgba(255, 255, 255, 0.7); }
    .text-in-card .hero-text.opaque:not([class*='background--']).dark-theme {
      background: #000; }
    .text-in-card .hero-text.opaque:not([class*='background--']).light-theme {
      background: #FFF; }
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .hero-text.text-center {
      align-items: center; }
    .hero-text.text-right {
      align-items: flex-end; } }
  @media (min-width: 769px) {
    .hero-text.width-1-4 {
      max-width: 25%; }
    .hero-text.width-1-3 {
      max-width: 33.3333%; }
    .hero-text.width-1-2 {
      max-width: 50%; }
    .hero-text.width-2-3 {
      max-width: 66.6666%; }
    .hero-text.width-3-4 {
      max-width: 75%; }
    .hero-text.width-1 {
      max-width: 100%; } }
  .hero-text .hero-subtitle > *,
  .hero-text .hero-title > *,
  .hero-text .hero-body > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .hero-text .hero-body ul:not(.list-style-dash) {
    list-style: disc;
    list-style-position: outside;
    margin-left: 0; }
  .hero-text .hero-body.list-style-dash ul {
    list-style: none; }
    .hero-text .hero-body.list-style-dash ul li::before {
      content: '\2014';
      position: absolute;
      margin-left: -30px; }
  @media (min-width: 544px) {
    .hero-text .hero-body ul {
      columns: 1; } }
  .hero-text .hero-subtitle + .hero-title,
  .hero-text .hero-title + .hero-body,
  .hero-text .hero-embedded-component + .hero-body,
  .hero-text .hero-body + .hero-buttons .hero-button,
  .hero-text .hero-embedded-component .experience-region > * {
    margin-top: 12px; }
  .hero-text .hero-buttons {
    font-size: 0; }
  .hero-text .hero-button {
    margin-top: 12px;
    display: inline-block;
    position: relative; }
  .hero-text.text-center .hero-button:not(:only-child) {
    margin-left: 6px;
    margin-right: 6px; }
  .hero-text.text-left .hero-button:not(:only-child) {
    margin-right: 12px; }
  .hero-text.text-right .hero-button:not(:only-child) {
    margin-left: 12px; }
  .hero-text .megamenu-link-list ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    columns: 1; }
    .hero-text .megamenu-link-list ul li {
      padding: 0; }
    .hero-text .megamenu-link-list ul li a {
      font-family: "Roboto", sans-serif;
      text-decoration: none;
      text-transform: none;
      font-size: 22px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0;
      display: block;
      padding: 10px 5px 10px 5px; }
      .hero-text .megamenu-link-list ul li a:hover {
        text-decoration: none; }
      @media (min-width: 769px) {
        .hero-text .megamenu-link-list ul li a {
          font-size: 16px; } }

.hero-collapse .hero-collapse-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  border: 1px solid #FFF;
  border-radius: 50%;
  box-shadow: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  bottom: 0;
  right: 0;
  margin-right: 16px;
  margin-bottom: 16px;
  background-color: #FFF; }
  .hero-collapse .hero-collapse-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 10px;
    color: #FFF;
    font-weight: normal; }
  .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #FFF;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title).btn-sm, .hero-collapse .btn-group-sm > .hero-collapse-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title).btn-sm:before, .hero-collapse .btn-group-sm > .hero-collapse-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .hero-collapse .hero-collapse-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .hero-collapse .hero-collapse-icon::before {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    width: 10px; }
  .hero-collapse .hero-collapse-icon:not(.collapsed)::before {
    content: ""; }

.hero-collapse .hero-collapse-inner-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 20px;
  padding: 20px; }

.hero-collapse.dark-theme .hero-collapse-icon {
  background-color: #000; }

.hero-collapse.dark-theme .hero-collapse-inner-content {
  background-color: #000;
  color: #FFF; }

.hero-slider-container .hero-slot.container {
  padding-left: 0;
  padding-right: 0; }

@media (max-width: 768.98px) {
  .hero.half-and-half.sm-short {
    min-height: 0; }
    .hero.half-and-half.sm-short .hero-media {
      height: 150px; }
  .hero.half-and-half.sm-medium {
    min-height: 0; }
    .hero.half-and-half.sm-medium .hero-media {
      height: 250px; }
  .hero.half-and-half.sm-tall {
    min-height: 0; }
    .hero.half-and-half.sm-tall .hero-media {
      height: 600px; }
  .hero.half-and-half.sm-screen-height {
    min-height: calc(100vh - 42px - 1px - 22px - 12px - 12px); }
    .transparent-header ~ div .hero.half-and-half.sm-screen-height {
      min-height: calc(100vh - 22px - 12px - 12px); } }

@media (max-width: 768.98px) and (min-width: 769px) {
  .hero.half-and-half.md-short {
    min-height: 0; }
    .hero.half-and-half.md-short .hero-media {
      height: 80px; }
  .hero.half-and-half.md-medium {
    min-height: 0; }
    .hero.half-and-half.md-medium .hero-media {
      height: 300px; }
  .hero.half-and-half.md-tall {
    min-height: 0; }
    .hero.half-and-half.md-tall .hero-media {
      height: 650px; }
  .hero.half-and-half.md-screen-height {
    min-height: calc(100vh - 60px - 1px - 22px - 12px - 12px); }
    .transparent-header ~ div .hero.half-and-half.md-screen-height {
      min-height: calc(100vh - 22px - 12px - 12px); } }

@media (max-width: 768.98px) and (min-width: 1024px) {
  .hero.half-and-half.lg-short {
    min-height: 0; }
    .hero.half-and-half.lg-short .hero-media {
      height: 80px; }
  .hero.half-and-half.lg-medium {
    min-height: 0; }
    .hero.half-and-half.lg-medium .hero-media {
      height: 350px; }
  .hero.half-and-half.lg-tall {
    min-height: 0; }
    .hero.half-and-half.lg-tall .hero-media {
      height: 700px; }
  .hero.half-and-half.lg-screen-height {
    min-height: calc(100vh - 83px - 1px - 22px - 12px - 12px); }
    .transparent-header ~ div .hero.half-and-half.lg-screen-height {
      min-height: calc(100vh - 22px - 12px - 12px); } }

@media (max-width: 768.98px) {
  .hero.half-and-half {
    height: auto; }
    .hero.half-and-half .hero-container {
      flex-flow: column; }
    .hero.half-and-half .hero-media {
      transform: translateX(50%);
      top: 0;
      position: relative;
      width: calc(100% + 24px);
      margin: -12px 0 12px -24px; }
    .hero.half-and-half.short {
      min-height: 0; }
      .hero.half-and-half.short .hero-media {
        height: 150px; }
    .hero.half-and-half.medium {
      min-height: 0; }
      .hero.half-and-half.medium .hero-media {
        height: 250px; }
    .hero.half-and-half.tall, .hero.half-and-half.screen-height {
      min-height: 0; }
      .hero.half-and-half.tall .hero-media, .hero.half-and-half.screen-height .hero-media {
        height: 600px; } }

@media (min-width: 769px) {
  .hero.half-and-half .hero-media {
    transform: translateY(-50%); }
    .hero.half-and-half .hero-media.width-1-4 {
      max-width: 75%; }
    .hero.half-and-half .hero-media.width-1-3 {
      max-width: 66.66666%; }
    .hero.half-and-half .hero-media.width-1-2 {
      max-width: 50%; }
    .hero.half-and-half .hero-media.width-2-3 {
      max-width: 33.3333%; }
    .hero.half-and-half .hero-media.width-3-4 {
      max-width: 25%; }
    .hero.half-and-half .hero-media.width-1 {
      max-width: 50%; }
  .hero.half-and-half .hero-text.width-1 {
    max-width: 50%; }
  .hero.half-and-half .justify-content-md-start .hero-media {
    right: 0;
    left: auto; }
  .hero.half-and-half .justify-content-md-start .hero-text {
    padding-right: 12px; } }
  @media (min-width: 769px) and (min-width: 769px) {
    .hero.half-and-half .justify-content-md-start .hero-text {
      padding-right: 12px; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .hero.half-and-half .justify-content-md-start .hero-text {
      padding-right: 50px; } }

@media (min-width: 769px) {
  .hero.half-and-half .justify-content-md-end .hero-media {
    left: 0;
    right: auto; }
  .hero.half-and-half .justify-content-md-end .hero-text {
    padding-left: 12px; } }
  @media (min-width: 769px) and (min-width: 769px) {
    .hero.half-and-half .justify-content-md-end .hero-text {
      padding-left: 12px; } }
  @media (min-width: 769px) and (min-width: 1024px) {
    .hero.half-and-half .justify-content-md-end .hero-text {
      padding-left: 50px; } }

.experience-component .hero-button .video-container::before,
.experience-component .hero-button .video-container .video-player,
.experience-component .button .video-container::before,
.experience-component .button .video-container .video-player,
.experience-component .image-text-block-button .video-container::before,
.experience-component .image-text-block-button .video-container .video-player {
  display: none; }

.image-text-block {
  display: block;
  position: relative;
  text-align: center;
  text-decoration: none; }
  .image-text-block:hover {
    text-decoration: none; }
  @media (min-width: 769px) {
    .image-text-block.zoom .image-wrapper {
      will-change: transform;
      transition: transform 200ms ease-out; }
    .image-text-block.zoom:hover .image-wrapper {
      transform: scale(1.2); } }
  .image-text-block .image-cropper {
    border-radius: 0px;
    overflow: hidden;
    margin: auto;
    width: 100%;
    max-width: 100%;
    max-height: 100%; }
  .image-text-block .image-wrapper {
    width: 100%;
    position: relative; }
    .image-text-block .image-wrapper .background-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      display: block; }
    .image-text-block .image-wrapper .background-image-tablet,
    .image-text-block .image-wrapper .background-image-desktop,
    .image-text-block .image-wrapper .background-image-hd {
      display: none; }
    @media (min-width: 769px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-desktop {
        display: none; }
      .image-text-block .image-wrapper .background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .image-text-block .image-wrapper .background-image-mobile,
      .image-text-block .image-wrapper .background-image-tablet {
        display: none; }
      .image-text-block .image-wrapper .background-image-desktop {
        display: block; } }
    @media (min-width: 1440px) {
      .image-text-block .image-wrapper .background-image-desktop {
        display: none; }
      .image-text-block .image-wrapper .background-image-hd {
        display: block; } }
    .image-text-block .image-wrapper .video-container .video-aspect-ratio {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: calc(9 / 16 * 100%);
      padding-left: calc(16 / 9 * 100vh);
      width: 0;
      height: 0;
      min-height: 100%;
      min-width: 100%; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio .video-player {
        position: static;
        pointer-events: none; }
      .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
        .image-text-block .image-wrapper .video-container .video-aspect-ratio iframe.video-player {
          position: absolute; }
  .image-text-block .image-text-block-text .primary-text > *,
  .image-text-block .image-text-block-text .secondary-text > *,
  .image-text-block .image-text-block-text .image-text-block-subtitle > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .image-text-block .image-text-block-text .image-text-block-subtitle:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 12px; }
  .image-text-block .image-text-block-text .primary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 16px; }
  .image-text-block .image-text-block-text .secondary-text:not([class*='p--']):not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-size: 16px; }
  .image-text-block .image-text-block-text .image-text-block-subtitle + .primary-text {
    margin-top: 5px; }
  .image-text-block .image-text-block-text .secondary-text + .image-text-block-button {
    margin-top: 12px; }
  .image-text-block .image-text-block-text .image-text-block-button {
    text-shadow: none; }
  .image-text-block.text-placement-below .image-text-block-text {
    margin-top: 12px; }
  .image-text-block.text-placement-inside::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 0px; }
  .image-text-block.text-placement-inside .image-text-block-text {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    color: #FFF;
    z-index: 1;
    text-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
    width: calc(100% - 12px); }
    @media (min-width: 1024px) {
      .image-text-block.text-placement-inside .image-text-block-text {
        width: calc(100% - 24px); } }
  .image-text-block.aspect-ratio-square .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-square .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .image-text-block.aspect-ratio-landscape .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-landscape .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1.5 * 100%); }
  .image-text-block.aspect-ratio-portrait .image-wrapper::after {
    display: block;
    content: "";
    clear: both; }
  .image-text-block.aspect-ratio-portrait .image-wrapper::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1.5 / 1 * 100%); }

.image-text-block.text-placement-inside .image-text-block-text {
  position: initial;
  transform: initial;
  transform: initial;
  z-index: 200; }
  .image-text-block.text-placement-inside .image-text-block-text.text-center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%); }
  .image-text-block.text-placement-inside .image-text-block-text.text-bottom-center {
    position: absolute;
    bottom: 80px;
    right: 0;
    transform: translateY(50%);
    width: 100%;
    top: auto; }
    @media (min-width: 1024px) {
      .image-text-block.text-placement-inside .image-text-block-text.text-bottom-center {
        bottom: 160px; } }

.image-text-block .image-cropper {
  position: relative;
  z-index: 0; }

.image-text-block .image-text-block-buttons {
  font-size: 0; }

.image-text-block .image-text-block-button {
  margin-top: 12px;
  display: inline-block;
  position: relative; }

.image-text-block .image-text-block-text.text-center .image-text-block-button:not(:only-child) {
  margin-left: 6px;
  margin-right: 6px; }

.image-text-block .image-text-block-text.text-left .image-text-block-button:not(:only-child) {
  margin-right: 12px; }

.image-text-block .image-text-block-text.text-right .image-text-block-button:not(:only-child) {
  margin-left: 12px; }

.image-text-block .image-text-block-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100; }

@media (min-width: 769px) {
  .image-text-overlap .image-text-block-text {
    position: absolute;
    width: 100%;
    bottom: 0; } }

@media (max-width: 543.98px) {
  .collapsible-xs {
    border-color: #D6D6D6; }
    .collapsible-xs .card-header {
      padding: 0;
      border-color: #D6D6D6; }
    .collapsible-xs .title {
      background-color: #FFF;
      color: #0C0A08;
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-xs .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000;
        font-weight: normal; }
      .collapsible-xs .title[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-xs .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .collapsible-xs .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-xs .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-xs .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-xs .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-xs .content,
    .collapsible-xs .card-body {
      display: none;
      background-color: #FFF;
      color: #000; }
    .collapsible-xs.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .collapsible-xs.active .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-xs.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xs.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-xs.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xs.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xs.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xs.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xs.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xs.active .content,
    .collapsible-xs.active .card-body {
      display: block; }
    .collapsible-xs:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-xs:not(:last-child) + .collapsible-xs {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-xs:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #D6D6D6; }
    .collapsible-xs.aside .title::after, .collapsible-xs.no-borders .title::after {
      right: 0; }
    .collapsible-xs.aside .title::after {
      top: 20px; }
    .collapsible-xs.no-borders .title::after {
      top: 17px; } }

@media (max-width: 768.98px) {
  .collapsible-sm {
    border-color: #D6D6D6; }
    .collapsible-sm .card-header {
      padding: 0;
      border-color: #D6D6D6; }
    .collapsible-sm .title {
      background-color: #FFF;
      color: #0C0A08;
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-sm .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000;
        font-weight: normal; }
      .collapsible-sm .title[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-sm .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .collapsible-sm .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-sm .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-sm .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-sm .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-sm .content,
    .collapsible-sm .card-body {
      display: none;
      background-color: #FFF;
      color: #000; }
    .collapsible-sm.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .collapsible-sm.active .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-sm.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-sm.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-sm.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-sm.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-sm.active .content,
    .collapsible-sm.active .card-body {
      display: block; }
    .collapsible-sm:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-sm:not(:last-child) + .collapsible-sm {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-sm:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #D6D6D6; }
    .collapsible-sm.aside .title::after, .collapsible-sm.no-borders .title::after {
      right: 0; }
    .collapsible-sm.aside .title::after {
      top: 20px; }
    .collapsible-sm.no-borders .title::after {
      top: 17px; } }

@media (max-width: 1023.98px) {
  .collapsible-md {
    border-color: #D6D6D6; }
    .collapsible-md .card-header {
      padding: 0;
      border-color: #D6D6D6; }
    .collapsible-md .title {
      background-color: #FFF;
      color: #0C0A08;
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-md .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000;
        font-weight: normal; }
      .collapsible-md .title[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-md .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .collapsible-md .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-md .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-md .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-md .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-md .content,
    .collapsible-md .card-body {
      display: none;
      background-color: #FFF;
      color: #000; }
    .collapsible-md.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .collapsible-md.active .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-md.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-md.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-md.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-md.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-md.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-md.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-md.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-md.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-md.active .content,
    .collapsible-md.active .card-body {
      display: block; }
    .collapsible-md:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-md:not(:last-child) + .collapsible-md {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-md:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #D6D6D6; }
    .collapsible-md.aside .title::after, .collapsible-md.no-borders .title::after {
      right: 0; }
    .collapsible-md.aside .title::after {
      top: 20px; }
    .collapsible-md.no-borders .title::after {
      top: 17px; } }

@media (max-width: 1439.98px) {
  .collapsible-lg {
    border-color: #D6D6D6; }
    .collapsible-lg .card-header {
      padding: 0;
      border-color: #D6D6D6; }
    .collapsible-lg .title {
      background-color: #FFF;
      color: #0C0A08;
      padding-top: 17px;
      padding-right: 37px;
      padding-bottom: 22px;
      padding-left: 20px;
      user-select: none;
      border-width: 0;
      cursor: pointer; }
      .collapsible-lg .title:after {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 12px;
        color: #000;
        font-weight: normal; }
      .collapsible-lg .title[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-lg .title[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
          padding-right: 40px; }
          .collapsible-lg .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(10px); }
        .collapsible-lg .title[class*="btn-"]:not(.title) [class*="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) [class^="icon-"],
        .collapsible-lg .title[class*="btn-"]:not(.title) .fa {
          display: none; }
      .collapsible-lg .title::after {
        position: absolute;
        top: 17px;
        right: 20px; }
    .collapsible-lg .content,
    .collapsible-lg .card-body {
      display: none;
      background-color: #FFF;
      color: #000; }
    .collapsible-lg.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .collapsible-lg.active .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-lg.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-lg.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-lg.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-lg.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-lg.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-lg.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-lg.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-lg.active .content,
    .collapsible-lg.active .card-body {
      display: block; }
    .collapsible-lg:not(:last-child) {
      margin-bottom: 0;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }
      .collapsible-lg:not(:last-child) + .collapsible-lg {
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
    .collapsible-lg:not(.active):not(.aside):not(.no-borders) .card-header {
      border-bottom: 1px solid #D6D6D6; }
    .collapsible-lg.aside .title::after, .collapsible-lg.no-borders .title::after {
      right: 0; }
    .collapsible-lg.aside .title::after {
      top: 20px; }
    .collapsible-lg.no-borders .title::after {
      top: 17px; } }

.collapsible-xl {
  border-color: #D6D6D6; }
  .collapsible-xl .card-header {
    padding: 0;
    border-color: #D6D6D6; }
  .collapsible-xl .title {
    background-color: #FFF;
    color: #0C0A08;
    padding-top: 17px;
    padding-right: 37px;
    padding-bottom: 22px;
    padding-left: 20px;
    user-select: none;
    border-width: 0;
    cursor: pointer; }
    .collapsible-xl .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .collapsible-xl .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-xl .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .collapsible-xl .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .collapsible-xl .title[class*="btn-"]:not(.title) [class*="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) [class^="icon-"],
      .collapsible-xl .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    .collapsible-xl .title::after {
      position: absolute;
      top: 17px;
      right: 20px; }
  .collapsible-xl .content,
  .collapsible-xl .card-body {
    display: none;
    background-color: #FFF;
    color: #000; }
  .collapsible-xl.active .title:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 12px;
    color: #000;
    font-weight: normal; }
  .collapsible-xl.active .title[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .collapsible-xl.active .title[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #000;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-sm, .collapsible-xl.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .collapsible-xl.active .title[class*="btn-"]:not(.title).btn-sm:after, .collapsible-xl.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .collapsible-xl.active .title[class*="btn-"]:not(.title) [class*="icon-"],
    .collapsible-xl.active .title[class*="btn-"]:not(.title) [class^="icon-"],
    .collapsible-xl.active .title[class*="btn-"]:not(.title) .fa {
      display: none; }
  .collapsible-xl.active .content,
  .collapsible-xl.active .card-body {
    display: block; }
  .collapsible-xl:not(:last-child) {
    margin-bottom: 0;
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; }
    .collapsible-xl:not(:last-child) + .collapsible-xl {
      border-top-left-radius: 0;
      border-top-right-radius: 0; }
  .collapsible-xl:not(.active):not(.aside):not(.no-borders) .card-header {
    border-bottom: 1px solid #D6D6D6; }
  .collapsible-xl.aside .title::after, .collapsible-xl.no-borders .title::after {
    right: 0; }
  .collapsible-xl.aside .title::after {
    top: 20px; }
  .collapsible-xl.no-borders .title::after {
    top: 17px; }

footer {
  background-color: #000;
  border-top: 0 solid transparent;
  padding-bottom: 24px;
  padding-top: 24px;
  margin-top: 100px; }
  footer ul {
    list-style: none;
    padding-left: 0;
    padding-top: 12px; }
  footer .content {
    font-size: 17px; }
    footer .content li {
      height: 100%; }
      footer .content li a {
        text-decoration: none; }
  footer .store .btn {
    margin-bottom: 10px;
    margin-top: 20px; }
  footer .store .content {
    display: none; }
  @media (min-width: 769px) {
    footer .store {
      border: none; }
      footer .store .btn {
        margin-top: 5px; }
      footer .store .content {
        display: block; } }
  footer .store .social::after {
    display: block;
    content: "";
    clear: both; }
  footer .back-to-top {
    margin: 5px;
    padding: 10px 0 0 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none; }
    footer .back-to-top .fa-stack {
      width: 14px;
      height: 14px;
      line-height: 14px; }
    footer .back-to-top .fa-arrow-up {
      width: 100%;
      height: 100%; }
      footer .back-to-top .fa-arrow-up:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal; }
      footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm:before, footer .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #FFF;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class*="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class^="icon-"],
        footer .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) .fa {
          display: none; }
    footer .back-to-top .fa-circle {
      background-color: #FFF;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  footer .copyright,
  footer .postscript {
    color: #D6D6D6;
    font-size: 12px; }
    footer .copyright a,
    footer .postscript a {
      color: #D6D6D6;
      text-decoration: none; }
  footer hr {
    border-color: transparent;
    margin: 72px 0 10px 0; }
  footer a, footer .text-link {
    color: #FFF; }

.footer-item.collapsible-sm {
  padding-left: 0;
  padding-right: 0; }
  .footer-item.collapsible-sm:last-of-type {
    border-bottom: 1px solid #595959; }
    @media (min-width: 769px) {
      .footer-item.collapsible-sm:last-of-type {
        border: none; } }
  .footer-item.collapsible-sm ~ .collapsible-sm {
    margin-top: 0; }
  .footer-item.collapsible-sm .content {
    margin-bottom: 0; }
  .footer-item.collapsible-sm .title {
    border-top: 1px solid #595959;
    padding: 24px 40px 24px 0; }
    .footer-item.collapsible-sm .title::after {
      color: #FFF;
      font-size: 16px;
      right: 0; }
    @media (min-width: 769px) {
      .footer-item.collapsible-sm .title {
        border: none;
        padding: 0; } }

@media (min-width: 769px) {
  .footer-item.collapsible-sm {
    border: none;
    padding-left: 12px;
    padding-right: 12px; }
    .footer-item.collapsible-sm .content {
      padding-left: 0;
      padding-right: 0; } }

.email-signup-form {
  margin-top: 5px; }
  .email-signup-form .email-description {
    font-weight: bold;
    font-size: 11px;
    line-height: 18px;
    letter-spacing: 0; }
  .email-signup-form .btn:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 16px;
    color: #0C0A08;
    font-weight: normal; }
  .email-signup-form .btn[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .email-signup-form .btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .email-signup-form .btn[class*="btn-"]:not(.title).btn-sm:after, .email-signup-form .btn-group-sm > .btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .email-signup-form .btn[class*="btn-"]:not(.title) [class*="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) [class^="icon-"],
    .email-signup-form .btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  .email-signup-form .btn::after {
    transform: rotate(90deg); }

footer {
  position: relative;
  background-color: #0C0A08;
  padding-bottom: 0; }
  @media (min-width: 769px) {
    footer {
      padding-top: 60px; } }
  footer .title {
    color: #D6D6D6;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-weight: 500; }
  footer .email-title {
    font-family: "archia-medium", sans-serif;
    color: #FFF;
    font-size: 18px;
    font-weight: 600; }
    @media (min-width: 769px) {
      footer .email-title {
        font-size: 14px; } }
  footer .title,
  footer .email-title {
    position: relative;
    line-height: 1.5em;
    text-transform: none; }
  footer .collapsible-sm .title {
    color: #D6D6D6;
    background-color: transparent; }
  footer ul {
    padding-top: 24px;
    padding-bottom: 48px; }
    @media (min-width: 769px) {
      footer ul {
        padding-bottom: 0; } }
  footer .footer-email-signup a:hover,
  footer .footer-email-signup .text-link:hover {
    color: #D6D6D6; }
  footer .footer-about a, footer .footer-about .text-link,
  footer .footer-contact a,
  footer .footer-contact .text-link {
    color: #FFF;
    font-family: "archia-medium", sans-serif;
    font-size: 17px;
    line-height: 2em;
    font-weight: 500;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal; }
  @media (max-width: 768.98px) {
    footer .footer-item.collapsible-sm .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title).btn-sm, footer .footer-item.collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title).btn-sm:after, footer .footer-item.collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title) [class*="icon-"],
      footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title) [class^="icon-"],
      footer .footer-item.collapsible-sm .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    footer .footer-item.collapsible-sm.active .title:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm, footer .footer-item.collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm:after, footer .footer-item.collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) [class*="icon-"],
      footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) [class^="icon-"],
      footer .footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) .fa {
        display: none; }
    footer .footer-item.collapsible-sm .title,
    footer .footer-item.collapsible-sm.active .title {
      border-color: #4D4D4D;
      position: relative; }
      footer .footer-item.collapsible-sm .title::after,
      footer .footer-item.collapsible-sm.active .title::after {
        font-size: 16px;
        -webkit-text-stroke-width: 1px;
        top: 50%;
        transform: translateY(-50%); } }
  footer .footer-item.collapsible-sm .content {
    background-color: transparent; }
  footer .social-links li:not(:last-child) {
    padding-bottom: 12px; }
  footer .instagram-circle::before,
  footer .facebook-circle::before,
  footer .twitter-circle::before,
  footer .youtube-circle::before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50px;
    margin-right: 19px; }
  footer .instagram-circle::before {
    background: linear-gradient(45deg, #FFDD55 14.65%, #FF543E 50%, #C837AB 85.35%); }
  footer .facebook-circle::before {
    background-color: #4267B2; }
  footer .twitter-circle::before {
    background-color: #1DA0F2; }
  footer .youtube-circle::before {
    background-color: #E73127; }
  footer .footer-legal-section {
    order: 1;
    margin-top: auto; }
    footer .footer-legal-section a {
      font-size: 12px;
      font-family: "Roboto", sans-serif; }
    @media (min-width: 769px) {
      footer .footer-legal-section {
        margin-left: auto;
        order: 3; } }
    @media (max-width: 768.98px) {
      footer .footer-legal-section {
        padding-top: 48px;
        padding-bottom: 48px; } }
    footer .footer-legal-section .footer-legal-links-wrapper {
      overflow: hidden; }
    footer .footer-legal-section .footer-legal-links {
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px; }
      footer .footer-legal-section .footer-legal-links > * {
        padding-left: 12px;
        position: relative; }
        footer .footer-legal-section .footer-legal-links > *::before {
          content: '';
          display: inline-block;
          width: 1px;
          height: 1em;
          background-color: #FFF;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 6px; }
    footer .footer-legal-section .footer-one-trust {
      color: #99FF00;
      text-decoration: underline; }
  @media (min-width: 769px) {
    footer .footer-top-row {
      padding-bottom: 96px; } }
  footer .footer-bottom-row {
    padding-bottom: 24px;
    flex-direction: column; }
    @media (min-width: 769px) {
      footer .footer-bottom-row {
        background-color: #000;
        flex-direction: row;
        padding: 48px 0 72px; } }
  footer .alert-danger, footer .alert-success {
    background: unset; }
  footer .alert-danger {
    color: #BF242B; }
  footer .alert-success {
    color: #00865A; }

.footer-logo-section {
  align-self: end;
  order: 3; }
  @media (min-width: 769px) {
    .footer-logo-section {
      order: 1; } }
  @media (min-width: 1440px) {
    .footer-logo-section {
      padding-left: 5px; } }
  .footer-logo-section .footer-logo {
    width: 140px; }
  .footer-logo-section .copyright {
    padding-top: 24px; }

.footer-social-wrapper {
  padding-bottom: 24px; }
  .footer-social-wrapper .footer-social-icons {
    gap: 24px; }
  .footer-social-wrapper .footer-social-icon a {
    font-size: 24px;
    text-decoration: none; }

.email-signup-form {
  margin-top: 24px;
  padding-bottom: 24px; }
  .email-signup-form .email-title {
    padding-bottom: 24px; }
  .email-signup-form .email-input .input-group {
    border: 1px solid #595959;
    margin-top: 24px; }
    .email-signup-form .email-input .input-group.error {
      border-color: #BF242B; }
    .email-signup-form .email-input .input-group input {
      padding: 12px 12px 12px 24px;
      line-height: 1.7;
      display: inline-block;
      height: auto;
      font-size: 14px;
      font-weight: 600; }
    .email-signup-form .email-input .input-group button {
      color: #000; }
  .email-signup-form .gdpr-consent-error {
    margin-top: 24px; }
  .email-signup-form .gdpr-checkbox.form-group {
    margin-top: 24px; }
    .email-signup-form .gdpr-checkbox.form-group .gdpr-label,
    .email-signup-form .gdpr-checkbox.form-group .gdpr-label p {
      font-size: 12px;
      line-height: 1.4; }
    .email-signup-form .gdpr-checkbox.form-group.custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
      border-color: #D6D6D6; }
    .email-signup-form .gdpr-checkbox.form-group.custom-checkbox .custom-control-input ~ .gdpr-label::before {
      border-color: #FFF; }
    .email-signup-form .gdpr-checkbox.form-group.custom-checkbox .custom-control-label:before, .email-signup-form .gdpr-checkbox.form-group.custom-checkbox .custom-control-label:after {
      top: 0;
      width: 20px;
      height: 20px; }
    .email-signup-form .gdpr-checkbox.form-group.custom-checkbox .custom-control-label:before {
      background-color: #000;
      border-radius: 0; }
    .email-signup-form .gdpr-checkbox.form-group.error label:hover::before {
      box-shadow: 0 0 0 1px #BF242B; }
    .email-signup-form .gdpr-checkbox.form-group.error label::before {
      border-color: #BF242B; }
  .email-signup-form .email-description,
  .email-signup-form .gdpr-label {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    color: #FFF;
    font-weight: 400; }
    @media (min-width: 1024px) {
      .email-signup-form .email-description,
      .email-signup-form .gdpr-label {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .email-signup-form .inline-btn {
    border-color: #FFF; }
    .email-signup-form .inline-btn:hover {
      border-color: #FFF; }
    .email-signup-form .inline-btn input,
    .email-signup-form .inline-btn input::placeholder {
      font-family: "archia-medium", sans-serif;
      font-family: 14px;
      color: #FFF; }
    .email-signup-form .inline-btn .subscribe-email {
      height: fit-content;
      background-color: #FFF;
      padding: 6px 20px;
      margin: 12px 12px 12px 0; }
      .email-signup-form .inline-btn .subscribe-email::after {
        display: none; }
  .email-signup-form .email-signup-response {
    padding-top: 24px; }

@media (min-width: 1440px) {
  footer:not(.checkout-footer) .footer-copyright-section {
    transform: rotate(270deg);
    position: absolute;
    left: 0;
    top: 40%; } }

.footer-copyright-section {
  order: 2; }
  @media (min-width: 1440px) {
    .footer-copyright-section .copyright-notice {
      padding-top: 0; } }

.back-to-top {
  position: fixed;
  bottom: 2%;
  right: 10px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  margin: 5px;
  padding: 10px 0 0 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none; }
  .back-to-top.show {
    opacity: 1;
    visibility: visible;
    z-index: 1; }
  .back-to-top .fa-stack {
    width: 40px;
    height: 40px;
    line-height: 40px; }
  .back-to-top .fa-arrow-up {
    width: 100%;
    height: 100%; }
    .back-to-top .fa-arrow-up:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #000;
      font-weight: normal; }
    .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .back-to-top .fa-arrow-up[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm, .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .back-to-top .fa-arrow-up[class*="btn-"]:not(.title).btn-sm:before, .back-to-top .btn-group-sm > .fa-arrow-up.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class*="icon-"],
      .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) [class^="icon-"],
      .back-to-top .fa-arrow-up[class*="btn-"]:not(.title) .fa {
        display: none; }
  .back-to-top .fa-circle {
    background-color: #FFF;
    box-shadow: none;
    border: 1px solid #40403F;
    width: 100%;
    height: 100%;
    border-radius: 50%; }

.mobile-country-selector-container {
  padding-bottom: 48px; }

.desktop-country-selector-container {
  padding-bottom: 24px;
  margin-left: auto;
  min-width: 288px;
  width: fit-content; }

.country-selector-box {
  align-items: center;
  border: 1px solid #D6D6D6;
  display: flex;
  padding: 12px;
  width: 100%; }
  .country-selector-box .flag-icon {
    aspect-ratio: 4 / 3;
    margin-right: 12px;
    min-width: 24px; }
  .header-country-selector .country-selector-box {
    margin-top: 96px; }
    @media (min-width: 1024px) {
      .header-country-selector .country-selector-box {
        min-width: 288px; } }
    .header-country-selector .country-selector-box .current-country,
    .header-country-selector .country-selector-box .icon-arrow-right {
      color: #000; }
  .country-selector-box .current-country {
    color: #FFF;
    margin-right: auto; }
  .country-selector-box .icon-arrow-right {
    color: #FFF;
    font-size: 24px; }

footer .alert-success {
  color: #99FF00; }

.footer-links-title-desktop:visited, .footer-links-title-desktop:hover, .footer-links-title-desktop:active {
  text-decoration: none; }

.footer-links-title-desktop {
  display: block; }
  @media (max-width: 768.98px) {
    .footer-links-title-desktop {
      display: none; } }

.footer-links-title-mobile {
  display: none;
  font-family: "Roboto", sans-serif;
  font-size: 18px; }
  @media (max-width: 768.98px) {
    .footer-links-title-mobile {
      display: block; } }

.footer-link-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0;
  font-style: normal;
  font-weight: 500;
  color: #000;
  text-decoration: none; }

@media (max-width: 768.98px) {
  .footer-links-item.footer-item.collapsible-sm:first-of-type {
    border-top: 1px solid #B9BFA7; } }

.footer-links-item.footer-item.collapsible-sm {
  max-width: 24%; }
  @media (max-width: 768.98px) {
    .footer-links-item.footer-item.collapsible-sm {
      padding-top: 0px;
      padding-bottom: 0px;
      max-width: 100%;
      border-bottom: solid;
      border-bottom-color: #B9BFA7;
      border-width: 1px;
      background: transparent; } }

.footer-site-links {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 96px 0; }
  @media (max-width: 768.98px) {
    .footer-site-links {
      padding: 48px 0; } }

.footer-site-links-background {
  background-color: #ECEEE4; }

.footer-links-item.footer-item.collapsible-sm .title {
  color: #595959;
  text-decoration: none;
  background-color: transparent;
  border-color: transparent; }
  .footer-links-item.footer-item.collapsible-sm .title:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title).btn-sm, .footer-links-item.footer-item.collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title).btn-sm:after, .footer-links-item.footer-item.collapsible-sm .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title) [class*="icon-"],
    .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title) [class^="icon-"],
    .footer-links-item.footer-item.collapsible-sm .title[class*="btn-"]:not(.title) .fa {
      display: none; }

.footer-links-item.footer-item.collapsible-sm.active .title:after {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) {
  padding-right: 40px; }
  .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title):after {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(10px); }
  .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm, .footer-links-item.footer-item.collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title).btn-sm:after, .footer-links-item.footer-item.collapsible-sm.active .btn-group-sm > .title.btn[class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
  .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) [class*="icon-"],
  .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) [class^="icon-"],
  .footer-links-item.footer-item.collapsible-sm.active .title[class*="btn-"]:not(.title) .fa {
    display: none; }

.footer-links-item.footer-item.collapsible-sm .title,
.footer-links-item.footer-item.collapsible-sm.active .title {
  position: relative;
  font-size: 14px;
  font-weight: 500; }
  .footer-links-item.footer-item.collapsible-sm .title::after,
  .footer-links-item.footer-item.collapsible-sm.active .title::after {
    color: #000;
    font-size: 22px;
    top: 50%;
    transform: translateY(-50%); }

.footer-links-item.footer-item.collapsible-sm .content {
  background-color: transparent; }

.menu-footer-links {
  font-family: "archia-medium", sans-serif;
  font-size: 17px;
  line-height: 170%;
  list-style: none;
  padding-top: 24px; }
  @media (max-width: 768.98px) {
    .menu-footer-links {
      padding-top: 0px;
      padding-left: 0px;
      padding-bottom: 20px;
      padding: 24px 0 48px; } }
  .menu-footer-links li a {
    text-decoration: none; }
  .menu-footer-links .collapsible-sm {
    padding-left: 0px; }
  .menu-footer-links a, .menu-footer-links footer .text-link {
    color: #000; }

.footer-link-li-mobile {
  display: none; }
  @media (max-width: 768.98px) {
    .footer-link-li-mobile {
      display: block; } }

.form-group {
  margin: 0 0 20px;
  outline: none;
  border-radius: 8px; }
  .form-group ~ .form-group,
  .form-group ~ .btn-block {
    margin-top: 24px; }
  .form-group.required .form-control-label::before {
    content: "* ";
    color: #BF242B; }
  .form-group label {
    color: #595959; }
  .form-group input:not([type="checkbox"]) ~ label,
  .form-group label:has(~ :not([type="checkbox"])) {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 0 1px;
    padding: 9px 12px 0 12px;
    position: absolute;
    opacity: 0;
    transition: opacity 200ms ease-out; }
    .form-group input:not([type="checkbox"]) ~ label:has(~ .form-control:not(:placeholder-shown)), .form-group input:not([type="checkbox"]) ~ label:has(~ .form-control:autofill:placeholder-shown),
    .form-group label:has(~ :not([type="checkbox"])):has(~ .form-control:not(:placeholder-shown)),
    .form-group label:has(~ :not([type="checkbox"])):has(~ .form-control:autofill:placeholder-shown) {
      opacity: 1; }
    .form-group input:not([type="checkbox"]) ~ label:has(~ textarea.form-control),
    .form-group label:has(~ :not([type="checkbox"])):has(~ textarea.form-control) {
      padding-top: 12px; }
  .form-group .form-text,
  .form-group .invalid-feedback {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    margin-top: 12px;
    display: block; }
  .form-group .form-text {
    color: #595959; }
  .form-group .invalid-feedback {
    color: #BF242B;
    display: none; }
  .form-group .was-validated .form-control:invalid {
    display: block; }

.form-control, select.form-control {
  outline: none;
  border: 1px solid #D6D6D6;
  box-shadow: none;
  color: #000;
  background-color: #FFF;
  border-radius: 8px;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  transition: border-color 200ms ease-out, box-shadow 200ms ease-out, padding 200ms ease-out;
  padding: 24px 12px 8px 12px;
  height: 48px;
  background-clip: border-box; }
  .form-control:placeholder-shown:not(:autofill):not(.search-field), select.form-control:placeholder-shown:not(:autofill):not(.search-field) {
    padding: 12px 12px 12px 12px; }
  @supports (-webkit-touch-callout: none) {
    .form-control, select.form-control {
      font-size: 16px; } }
  @media (min-width: 769px) {
    .form-control, select.form-control {
      font-size: 14px; } }
  .form-control:hover, select.form-control:hover {
    border-color: #2B2B2A;
    box-shadow: 0 0 0 1px #2B2B2A; }
  .form-control:focus,
  .form-control.focus .form-control:focus:hover, select.form-control:focus,
  select.form-control.focus .form-control:focus:hover,
  .form-control.focus select.form-control:focus:hover,
  select.form-control.focus select.form-control:focus:hover {
    outline: none;
    border-color: #40403F;
    box-shadow: none; }
  .form-control:disabled, .form-control.disabled, select.form-control:disabled, select.form-control.disabled {
    pointer-events: none;
    background-color: #FFF; }
  .form-control.form-control-sm, select.form-control.form-control-sm {
    font-size: 11px;
    line-height: 14px;
    padding: 8px 10px 6px 10px;
    height: calc(14px + 8px + 6px + (1px*2)); }
  .form-control.is-invalid, select.form-control.is-invalid {
    background-image: none;
    border-color: #BF242B;
    padding-right: 12px; }
    .form-control.is-invalid:focus, select.form-control.is-invalid:focus {
      border-color: #BF242B;
      box-shadow: none; }
  .form-control::placeholder, select.form-control::placeholder {
    color: #595959; }

textarea.form-control {
  padding-top: 27px; }

.custom-control {
  min-height: 0; }

.custom-radio,
.custom-checkbox {
  user-select: none;
  padding-left: calc(20px + 12px); }
  .custom-radio .custom-control-label,
  .custom-checkbox .custom-control-label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    text-transform: none;
    letter-spacing: 0;
    vertical-align: middle;
    transition: box-shadow 200ms ease-out; }
    .custom-radio .custom-control-label:hover:before,
    .custom-checkbox .custom-control-label:hover:before {
      box-shadow: 0 0 0 1px #000; }
    .custom-radio .custom-control-label:before, .custom-radio .custom-control-label:after,
    .custom-checkbox .custom-control-label:before,
    .custom-checkbox .custom-control-label:after {
      top: 0;
      left: calc(-20px - 12px); }
  .custom-radio .custom-control-input:checked ~ .custom-control-label:before,
  .custom-radio .custom-control-input:checked:disabled ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:checked:disabled ~ .custom-control-label:before {
    background-color: #000;
    border-color: #000; }
  .custom-radio .custom-control-input:disabled ~ .custom-control-label,
  .custom-checkbox .custom-control-input:disabled ~ .custom-control-label {
    color: #595959; }
    .custom-radio .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-checkbox .custom-control-input:disabled ~ .custom-control-label:before {
      box-shadow: none;
      background-color: #D6D6D6;
      border-color: #000; }
  .custom-radio .custom-control-input.is-invalid ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input.is-invalid ~ .custom-control-label:before {
    background-color: #fffdfd;
    border-color: #BF242B; }
  .custom-radio .custom-control-input:focus ~ .custom-control-label:before,
  .custom-checkbox .custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: none;
    border-color: #000; }
  .custom-radio.custom-checkbox-dark .custom-control-label,
  .custom-checkbox.custom-checkbox-dark .custom-control-label {
    color: #FFF; }
    .custom-radio.custom-checkbox-dark .custom-control-label:hover::before,
    .custom-checkbox.custom-checkbox-dark .custom-control-label:hover::before {
      box-shadow: 0 0 0 1px #FFF; }
    .custom-radio.custom-checkbox-dark .custom-control-label::before,
    .custom-checkbox.custom-checkbox-dark .custom-control-label::before {
      background-color: #000;
      border-color: #FFF; }
  .custom-radio.custom-checkbox-dark .custom-control-input:checked ~ .custom-control-label:before,
  .custom-radio.custom-checkbox-dark .custom-control-input:checked:disabled ~ .custom-control-label:before,
  .custom-checkbox.custom-checkbox-dark .custom-control-input:checked ~ .custom-control-label:before,
  .custom-checkbox.custom-checkbox-dark .custom-control-input:checked:disabled ~ .custom-control-label:before {
    background-color: #000;
    border-color: #FFF; }
  .custom-radio.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label,
  .custom-checkbox.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label {
    color: #D6D6D6; }
    .custom-radio.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label:hover::before,
    .custom-checkbox.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label:hover::before {
      box-shadow: none; }
    .custom-radio.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label:before,
    .custom-checkbox.custom-checkbox-dark .custom-control-input:disabled ~ .custom-control-label:before {
      border-color: #FFF; }
  .custom-radio.custom-checkbox-dark .custom-control-input.is-invalid ~ .custom-control-label,
  .custom-checkbox.custom-checkbox-dark .custom-control-input.is-invalid ~ .custom-control-label {
    color: #BF242B; }
    .custom-radio.custom-checkbox-dark .custom-control-input.is-invalid ~ .custom-control-label:before,
    .custom-checkbox.custom-checkbox-dark .custom-control-input.is-invalid ~ .custom-control-label:before {
      background-color: #000; }
  .custom-radio.custom-checkbox-dark .custom-control-input:focus ~ .custom-control-label:before,
  .custom-checkbox.custom-checkbox-dark .custom-control-input:focus ~ .custom-control-label:before {
    border-color: #FFF; }

.custom-checkbox .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  background-color: #FFF;
  border-radius: 5px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #000;
  border: 1px solid #000;
  border-radius: 5px; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  width: 20px;
  height: 20px;
  line-height: calc(20px + 2px);
  background: none;
  font-family: "icomoon";
  font-size: 13.33333px;
  text-align: center;
  color: #FFF; }

.custom-checkbox + .custom-checkbox {
  margin-top: 12px; }

.custom-radio .custom-control-label:before {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  background-color: #FFF; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #FFF;
  border: 1px solid #000; }

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
  content: "";
  background: none;
  width: 10px;
  height: 10px;
  top: calc(10px/ 2);
  left: calc(-32px + 10px/ 2);
  background-color: #000;
  border-radius: 50%; }

.custom-radio + .custom-radio {
  margin-top: 12px; }

.custom-select, select.form-control,
select {
  background-image: url(../images/icons/icon-arrow-down.svg);
  background-size: 10px;
  padding-right: 27px; }
  .custom-select.form-control-sm,
  select.form-control-sm {
    background-size: 10px;
    padding-right: 25px; }
  .custom-select.is-invalid,
  select.is-invalid {
    background-image: url(../images/icons/icon-arrow-down.svg);
    background-size: 10px;
    padding-right: 27px; }
    .custom-select.is-invalid.form-control-sm,
    select.is-invalid.form-control-sm {
      background-size: 10px;
      padding-right: 25px; }

.quantity-stepper {
  display: flex;
  padding: 0; }
  .quantity-stepper input,
  .quantity-stepper button {
    border: none;
    padding: 0; }
  .quantity-stepper input {
    width: calc(100% - 20px - 12px - 12px - 6px);
    text-align: center;
    height: 100%;
    padding-bottom: 12px;
    padding-top: 12px; }
    .quantity-stepper input:focus {
      outline: none; }
  .quantity-stepper button {
    font-size: 10px;
    padding-top: 12px;
    padding-bottom: 12px; }
    .quantity-stepper button:before {
      font-weight: bold; }
    .quantity-stepper button.icon-minus {
      padding-left: 12px;
      padding-right: 3px; }
    .quantity-stepper button.icon-plus {
      padding-right: 12px;
      padding-left: 3px; }
  .quantity-stepper.form-control-sm {
    padding: 0; }
    .quantity-stepper.form-control-sm input {
      width: calc(100% - 16px - 10px - 10px - 6px);
      padding-top: 8px;
      padding-bottom: 6px; }
    .quantity-stepper.form-control-sm button {
      font-size: 8px;
      padding-top: 8px;
      padding-bottom: 6px; }
      .quantity-stepper.form-control-sm button.icon-minus {
        padding-left: 10px;
        padding-right: 2px; }
      .quantity-stepper.form-control-sm button.icon-plus {
        padding-right: 10px;
        padding-left: 2px; }

.inline-btn {
  background-color: transparent;
  border-color: #2B2B2A;
  border-radius: 0;
  border-style: solid;
  border-width: 0 0 1px 0; }
  .inline-btn:hover, .inline-btn:focus {
    border-color: #000;
    box-shadow: none; }
  .inline-btn input {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: #000;
    padding-left: 0; }
    .inline-btn input:hover {
      border: none;
      box-shadow: none; }
    .inline-btn input:focus {
      background-color: transparent;
      outline: none; }
    .inline-btn input::placeholder {
      color: #595959; }
  .inline-btn .btn {
    border: none;
    padding: 0 5px 0 10px; }

.form-group:has(.show-password) {
  display: flex;
  margin: 0;
  height: 0; }
  .form-group:has(.show-password) .show-password {
    margin-left: auto; }
    .form-group:has(.show-password) .show-password label {
      margin-bottom: 0;
      letter-spacing: 0;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0;
      color: inherit; }
      @media (min-width: 1024px) {
        .form-group:has(.show-password) .show-password label {
          font-size: 12px;
          line-height: 1.5em;
          letter-spacing: 0; } }

.validator-container {
  width: 100%;
  padding: 12px 12px 0 12px; }
  .validator-container .password-validation-helper {
    margin: 0 auto;
    padding: 0; }
    .validator-container .password-validation-helper li {
      font-family: inherit;
      list-style-type: none;
      padding: calc(12px/3) 0;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      color: #000;
      margin-left: 12px;
      position: relative; }
      .validator-container .password-validation-helper li.valid::before {
        content: "•";
        fill: var(--Primary-brand-01, #0C0A08);
        position: absolute;
        left: -12px;
        top: calc(12px/4);
        color: #0C0A08;
        height: 4px;
        width: 4px; }
      .validator-container .password-validation-helper li.error {
        color: #BF242B; }
        .validator-container .password-validation-helper li.error::before {
          content: "•";
          fill: var(--Primary-brand-01, #0C0A08);
          position: absolute;
          left: -12px;
          top: calc(12px/4);
          color: #0C0A08;
          height: 4px;
          width: 4px; }
      .validator-container .password-validation-helper li:not(.valid):not(.error)::before {
        content: "•";
        fill: var(--Primary-brand-01, #0C0A08);
        position: absolute;
        left: -12px;
        top: calc(12px/4);
        color: #0C0A08;
        height: 4px;
        width: 4px; }
      .validator-container .password-validation-helper li::before {
        content: "•";
        fill: var(--Primary-brand-01, #0C0A08);
        position: absolute;
        left: -12px;
        top: calc(12px/4);
        color: #0C0A08;
        height: 4px;
        width: 4px; }
  .order-confirm-create-account .validator-container {
    padding-left: 0;
    padding-right: 0; }
    .order-confirm-create-account .validator-container .password-validation-helper li {
      padding-left: 0;
      padding-right: 0; }

.validator-container .password-validation-helper li:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: 13px;
  color: #595959;
  font-weight: normal; }

.validator-container .password-validation-helper li[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .validator-container .password-validation-helper li[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #595959;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .validator-container .password-validation-helper li[class*="btn-"]:not(.title).btn-sm:before, .validator-container .password-validation-helper .btn-group-sm > li.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #595959;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class*="icon-"],
  .validator-container .password-validation-helper li[class*="btn-"]:not(.title) [class^="icon-"],
  .validator-container .password-validation-helper li[class*="btn-"]:not(.title) .fa {
    display: none; }

.form-control.is-invalid, select.form-control.is-invalid {
  border-width: 2px; }

label .form-group ~ .isinvalid {
  color: #BF242B; }

/*
* PAGE DESIGNER LAYOUT GRID SPACING
*/
[class*='experience-commerce_layouts'] + [class*='experience-commerce_layouts'] > div {
  margin-top: 24px; }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-12']:not(.order-1), [class*='experience-commerce_layouts'] .region[class*='col-md'].order-2[class*='col-12'] {
    margin-top: 24px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
    margin-top: 24px; }
  [class*='experience-commerce_layouts'] .region[class*='col-md'] + .region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
    margin-top: 24px; } }

[class*='experience-commerce_layouts'] .region[class*='col-md-6'] .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) {
  height: 100%; }

[class*='experience-commerce_layouts'] .no-gutters .row {
  padding-right: 12px;
  padding-left: 12px; }
  @media (min-width: 1440px) {
    [class*='experience-commerce_layouts'] .no-gutters .row {
      padding-right: 24px;
      padding-left: 24px; } }
  [class*='experience-commerce_layouts'] .no-gutters .row > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0; }

[class*='experience-commerce_layouts'] .has-background-image {
  position: relative; }
  [class*='experience-commerce_layouts'] .has-background-image > .container {
    position: relative; }
    [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
      width: calc(100% - 24px); }
      @media (min-width: 1440px) {
        [class*='experience-commerce_layouts'] .has-background-image > .container .column-background {
          width: calc(100% - 48px); } }
  [class*='experience-commerce_layouts'] .has-background-image .column-background {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .full-bleed [class*='experience-commerce_layouts'] .has-background-image .column-background {
      width: calc(100vw + 2px); }
    [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
      display: none; }
    @media (min-width: 769px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-mobile, [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-tablet {
        display: none; }
      [class*='experience-commerce_layouts'] .has-background-image .column-background.column-background-desktop {
        display: block; } }

/*
* LAYOUT GRID ASPECT RATIOS
*/
.region-square,
.region-landscape,
.region-portrait {
  position: relative; }
  .region-square .experience-component,
  .region-landscape .experience-component,
  .region-portrait .experience-component {
    position: absolute;
    top: 0;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    max-width: calc(100% - 24px); }
    @media (min-width: 1440px) {
      .region-square .experience-component,
      .region-landscape .experience-component,
      .region-portrait .experience-component {
        max-width: calc(100% - 48px); } }

.region-square::after {
  display: block;
  content: "";
  clear: both; }

.region-square::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1 / 1 * 100%); }

.region-landscape::after {
  display: block;
  content: "";
  clear: both; }

.region-landscape::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1 / 1.5 * 100%); }

.region-portrait::after {
  display: block;
  content: "";
  clear: both; }

.region-portrait::before {
  content: "";
  display: block;
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: calc(1.5 / 1 * 100%); }

div.sf-interactions-proxyHoverContainer a {
  overflow: visible; }

.hover-switch--hover {
  display: none; }

.hover-switch:hover .hover-switch--load {
  display: none; }

.hover-switch:hover .hover-switch--hover {
  display: block; }

.product-grid .experience-region,
.product-grid .experience-component,
.product-grid .chromeless {
  height: 100%; }

.product-grid .hero {
  min-height: calc(100% - 45px);
  margin-bottom: 45px; }

.product-grid .experience-region,
.product-grid .experience-component {
  max-width: 288px;
  margin: 0 auto; }

.product-grid .product {
  height: 100%; }

.background-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover; }

[class*='experience-commerce_layouts'] .small-gutters .container {
  padding: 0; }

[class*='experience-commerce_layouts'] .small-gutters .row {
  margin-left: -6px;
  margin-right: -6px; }
  [class*='experience-commerce_layouts'] .small-gutters .row > [class*="col-"] {
    padding-left: 6px;
    padding-right: 6px; }

@media (max-width: 768.98px) {
  [class*='experience-commerce_layouts'] .small-gutters .region[class*='col-md'] + .region[class*='col-12']:not(.order-1),
  [class*='experience-commerce_layouts'] .small-gutters .region[class*='col-md'].order-2[class*='col-12'] {
    margin-top: 12px; } }

.minicart-open {
  overflow: hidden; }
  @media (min-width: 1024px) {
    .minicart-open {
      overflow: visible; } }

.minicart {
  position: relative; }
  .minicart .popover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: auto;
    right: 0;
    width: 100vw;
    max-width: 100vw;
    min-height: 400px;
    display: none; }
    @media (min-width: 769px) {
      .minicart .popover {
        max-width: 400px; } }
    .minicart .popover.show {
      display: block; }
    .minicart .popover > .container.cart {
      display: flex;
      flex-direction: column;
      height: 100vh;
      padding-left: 24px;
      padding-right: 24px; }
      @media (max-width: 1023.98px) {
        .minicart .popover > .container.cart {
          padding-bottom: 24px; } }
      .minicart .popover > .container.cart .minicart-body {
        flex-grow: 1; }
    .minicart .popover .minicart-body {
      overflow-y: auto; }
  .minicart .minicart-label {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 0 1px;
    padding: 9px 12px 0 12px;
    position: absolute;
    opacity: 0;
    transition: opacity 200ms ease-out;
    display: inline; }
    .minicart .minicart-label:has(~ .form-control:not(:placeholder-shown)), .minicart .minicart-label:has(~ .form-control:autofill:placeholder-shown) {
      opacity: 1; }
    .minicart .minicart-label:has(~ textarea.form-control) {
      padding-top: 12px; }
  .minicart .cart {
    padding-top: 12px;
    background-color: #FFF; }
  .minicart .remove-btn {
    font-family: "Roboto", sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
    margin: 5px;
    left: 0;
    right: auto; }
    .minicart .remove-btn:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .minicart .remove-btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .minicart .remove-btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .minicart .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .minicart .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .minicart .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
      .minicart .remove-btn[class*="btn-"]:not(.title) .fa {
        display: none; }
  .minicart .product-summary {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 12px;
    padding-top: 12px;
    width: 100%; }
    .minicart .product-summary .line-item-name {
      width: calc(100% - 11px - 12px/2); }
  .minicart .minicart-item {
    padding-right: 12px;
    padding-right: 12px; }
    .minicart .minicart-item + .minicart-item {
      margin-top: 6px;
      padding-top: 12px;
      border-top: 1px solid #D6D6D6;
      border-radius: 0px; }
    .minicart .minicart-item:first-child .product-line-item {
      margin-top: 0; }
  .minicart .product-line-item {
    position: relative; }
  .minicart .item-price-qty .col {
    flex-direction: column;
    align-items: flex-start; }
  .minicart .quantity,
  .minicart .quantity-stepper {
    width: 100%;
    max-width: 90px; }
  .minicart .quantity {
    min-width: 50px; }
  .minicart .line-item-promo > div {
    padding: 0 0 6px; }
  .minicart .minicart-footer {
    padding-top: 12px;
    border-top: 1px solid #D6D6D6;
    padding-left: 0;
    padding-right: 0; }
  .minicart .estimated-total {
    font-family: "archia-medium", sans-serif;
    font-weight: bold;
    text-transform: none;
    height: unset; }
    .minicart .estimated-total p {
      letter-spacing: 0;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0; }
      @media (min-width: 1024px) {
        .minicart .estimated-total p {
          font-size: 12px;
          line-height: 1.5em;
          letter-spacing: 0; } }

.scroll-direction-down.sticky-header .fixed-header-enhanced .minicart .minicart-backdrop, .scroll-direction-down.sticky-header .fixed-header-enhanced .minicart .minicart-pd {
  top: var(--header-height); }

.fixed-header .popover .minicart-body {
  display: block; }
  @media (min-width: 769px) {
    .fixed-header .popover .minicart-body {
      max-height: 300px; } }

.minicart-open .header-utility,
.minicart-open .header-column-1,
.minicart-open .header-column-2,
.minicart-open #maincontent,
.minicart-open .header-banner,
.minicart-open #footercontent {
  filter: blur(4px); }

.minicart .cart {
  padding-top: 18px; }

.minicart .minicart-link {
  cursor: pointer; }

.minicart .line-item-availability {
  display: none; }

.minicart .line-item-name {
  white-space: normal !important; }

.minicart .item-attributes {
  padding-bottom: 0px;
  width: fit-content !important; }

.minicart .item-image {
  margin: 0 !important; }

.minicart .product-card-image-wrapper, .minicart .product-card-price-wrapper {
  flex-shrink: 0; }

.minicart .product-card-image-wrapper {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center; }

.minicart .product-card-text-wrapper {
  flex-grow: 1; }

.minicart .dropdown-divider {
  border-top: 1px solid #D6D6D6;
  left: -24px;
  position: relative;
  width: calc(100vw); }

.minicart .minicart-header {
  margin-bottom: 12px; }
  .minicart .minicart-header .container {
    padding-left: 0;
    padding-right: 0; }
  .minicart .minicart-header .minicart-header-text {
    letter-spacing: -1px;
    font-family: "archia-medium", sans-serif;
    padding-left: 6px;
    font-size: 18px; }

.minicart .minicart-item {
  display: none; }

.minicart .minicart-item:last-child {
  display: block;
  padding: 12px 0;
  margin: 0;
  border: 0; }
  .minicart .minicart-item:last-child .container, .minicart .minicart-item:last-child .row, .minicart .minicart-item:last-child .col, .minicart .minicart-item:last-child .col-auto {
    padding-left: 0;
    padding-right: 0; }

.minicart .minicart-checkmark {
  background-color: #99FF00;
  border-radius: 50%;
  height: 16px;
  width: 16px; }
  .minicart .minicart-checkmark ::before {
    font-size: 9px;
    left: 3px;
    bottom: 1px; }

.minicart .product-summary {
  padding: 0; }
  .minicart .product-summary .line-item-name {
    width: unset; }
  .minicart .product-summary .line-item-total-text {
    font-size: 14px; }
  .minicart .product-summary .line-item-total-text, .minicart .product-summary .unit-price {
    display: inline-block; }
  .minicart .product-summary .unit-price .strike-through .value {
    margin-right: 0; }

.minicart .popover {
  height: fit-content; }
  .minicart .popover > .container.cart {
    height: auto; }
    .minicart .popover > .container.cart .minicart-body {
      overflow-y: initial; }
      .minicart .popover > .container.cart .minicart-body .line-item-total-text {
        margin-bottom: 0; }
    .minicart .popover > .container.cart .minicart-footer {
      border-top: 0; }

.minicart .continue-shopping-cart-btn {
  margin-bottom: 12px; }

.minicart .minicart-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.5;
  z-index: 300; }

.minicart .drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: -100%;
  height: 100vh;
  display: block;
  transition: left 0.5s cubic-bezier(0, 1, 0.5, 1);
  z-index: 300;
  background-color: #FFF;
  overflow-y: auto; }
  @media (max-width: 543.98px) {
    .minicart .drawer {
      width: 100%; } }
  .minicart .drawer.show {
    transition: right 0.5s cubic-bezier(0, 1, 0.5, 1);
    right: 0; }
  .minicart .drawer.hide {
    transition: right 1.5s cubic-bezier(0, 1, 0.5, 1);
    right: -100%; }
  .minicart .drawer .cart {
    position: fixed;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; }
    @media (min-width: 769px) {
      .minicart .drawer .cart {
        height: auto; } }
  .minicart .drawer .minicart-body {
    max-height: 250px;
    overflow-y: auto;
    display: block; }
    @media (max-width: 1023.98px) {
      .minicart .drawer .minicart-body {
        max-height: unset; } }
  .minicart .drawer .product-summary {
    max-height: none;
    overflow-y: none; }

.minicart .minicart-footer button.dw-apple-pay-button {
  width: 100%;
  border-radius: 50px;
  margin-bottom: 12px;
  padding: 12px 20px 12px 20px; }

.minicart .minicart-footer .estimated-total p {
  font-size: 12px; }

.minicart .approaching-discounts .single-approaching-discount:last-of-type {
  margin-bottom: 6px; }

.minicart .approaching-discounts .single-approaching-discount {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px; }
  .minicart .approaching-discounts .single-approaching-discount .threshold-message {
    padding-bottom: 12px; }
  .minicart .approaching-discounts .single-approaching-discount .threshold-bar {
    height: 16px;
    border: 1px solid #D8D8D8;
    border-radius: 6px;
    background-color: #ECEEE4; }
    .minicart .approaching-discounts .single-approaching-discount .threshold-bar .threshold-bar-progress {
      height: 100%;
      width: 0%;
      border-radius: 14px;
      background-color: #000;
      transition: width .2s; }

.minicart .approaching-discounts .bonus-product-button-wrapper {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px; }
  .minicart .approaching-discounts .bonus-product-button-wrapper .circle {
    background-image: url("../images/icons/gift.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 0;
    height: 36px;
    width: 36px; }
  .minicart .approaching-discounts .bonus-product-button-wrapper .bonus-product-button button {
    font-size: 14px;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 24px; }

.minicart klarna-placement {
  display: block; }
  @media (min-width: 769px) {
    .minicart klarna-placement {
      margin-bottom: 24px; } }

.modal {
  z-index: 1050; }
  .modal .modal-content {
    background-color: transparent;
    border: none;
    border-radius: 0px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .modal .modal-header {
    padding: 10px 20px 9px;
    background-color: #2B2B2A;
    color: #FFF;
    border-bottom-color: transparent;
    border-top-left-radius: calc(0px - 1px);
    border-top-right-radius: calc(0px - 1px); }
    .modal .modal-header .modal-title {
      font-family: "archia-medium", sans-serif;
      font-size: 20px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; }
      @media (min-width: 1024px) {
        .modal .modal-header .modal-title {
          font-size: 24px;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
          font-style: normal; } }
    .modal .modal-header .close {
      margin: 0;
      padding: 0; }
      .modal .modal-header .close [aria-hidden=true] {
        display: none; }
    .modal .modal-header a {
      color: #FFF; }
  .modal .modal-body {
    flex: 0 0 auto;
    padding: 20px;
    color: #000;
    background-color: #FFF; }
  .modal .modal-footer {
    padding: 0 20px 20px;
    color: #000;
    background-color: #FFF;
    border-top-color: transparent;
    border-bottom-left-radius: calc(0px - 1px);
    border-bottom-right-radius: calc(0px - 1px); }
    .modal .modal-footer .button-wrapper .btn + .btn {
      margin-left: 12px; }
    .modal .modal-footer > .btn + .btn {
      margin-left: 12px; }
  .modal.show ~ .modal.show {
    z-index: 1052; }

.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.6); }
  .modal-backdrop.show {
    opacity: 1;
    z-index: 1000; }
    .modal-backdrop.show ~ .modal-backdrop.show {
      z-index: 1051; }

.modal-dialog {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-dialog {
      margin: 24px auto; } }

.modal-sm {
  max-width: none;
  margin: 8px;
  max-width: 500px; }
  @media (min-width: 544px) {
    .modal-sm {
      margin: 24px auto; } }
  @media (min-width: 544px) {
    .modal-sm {
      max-width: 300px; } }

.modal-lg {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-lg {
      max-width: 900px;
      margin: 24px auto; } }

.modal-xl {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .modal-xl {
      max-width: 900px;
      margin: 24px auto; } }
  @media (min-width: 1440px) {
    .modal-xl {
      max-width: 1140px; } }

.modal-scrollbody {
  overflow: hidden; }
  .modal-scrollbody .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }

.modal-backdrop {
  background-color: #ffffff4f;
  backdrop-filter: blur(3px); }

.notify {
  position: fixed;
  top: 0; }

.page-header {
  padding-top: 12px;
  padding-bottom: 12px; }
  @media (min-width: 769px) {
    .page-header {
      padding-top: 44px;
      padding-bottom: 44px; } }
  .page-header.blog-detail-header .page-header-title {
    font-family: "archia-medium", sans-serif;
    font-size: 29px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 1024px) {
      .page-header.blog-detail-header .page-header-title {
        font-size: 35px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }
  @media (max-width: 1023.98px) {
    .page-header.search-results-header {
      padding-top: 0px;
      padding-bottom: 0px; } }
  .page-header.search-results-header .page-header-title {
    font-family: "archia-medium", sans-serif;
    font-size: 29px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal;
    margin-top: 24px;
    margin-bottom: 0px; }
    @media (min-width: 1024px) {
      .page-header.search-results-header .page-header-title {
        font-size: 35px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }

.hero-slider-container + .page-header .page-header-title, .hero-slot + .page-header .page-header-title {
  display: none; }

.page-header {
  padding-top: 0;
  padding-bottom: 0; }
  .page-header.search-results-header .page-header-title {
    font-family: "archia-medium", sans-serif;
    font-size: 29px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal;
    margin-top: 12px;
    padding-top: 24px;
    padding-bottom: 24px; }
    @media (min-width: 1024px) {
      .page-header.search-results-header .page-header-title {
        font-size: 35px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }
    .page-header.search-results-header .page-header-title:empty {
      padding-bottom: 0; }
    .plp .page-header.search-results-header .page-header-title {
      margin-top: 0; }
      @media (max-width: 768.98px) {
        .plp .page-header.search-results-header .page-header-title {
          padding-top: 24px;
          padding-bottom: 24px; } }
      @media (min-width: 769px) {
        .plp .page-header.search-results-header .page-header-title {
          padding-top: 48px;
          padding-bottom: 24px; } }
  .page-header.search-results-header .has-subtext.page-header-title {
    margin-bottom: -24px; }
  .page-header.search-results-header .subtext-container {
    padding-bottom: 48px;
    line-height: 0;
    margin-bottom: 0px; }

.category-links {
  display: inline-block;
  text-transform: none;
  font-size: 15px;
  padding: 10px 12px;
  border-radius: 10px;
  border: solid 1px #0C0A08; }

@media (max-width: 1023.98px) {
  .category-links-container {
    overflow-x: scroll;
    margin-right: -24px;
    margin-left: -24px;
    width: 100vw;
    padding-left: 24px; } }

.category-links-container::-webkit-scrollbar {
  display: none; }

.circle-links-component {
  margin: 0;
  padding: 0 18px 12px 0;
  height: auto;
  background: #FFF;
  list-style: none;
  display: flex;
  width: max-content;
  gap: 12px; }
  @media (min-width: 769px) {
    .circle-links-component {
      padding: 0;
      margin-bottom: 24px;
      width: auto;
      flex-wrap: wrap; } }

.circle-link {
  display: inline-block;
  height: auto;
  width: auto;
  min-width: 100px;
  border-radius: 4px;
  padding: 12px 24px;
  border: solid 1px #0C0A08;
  text-align: center;
  flex: 0 0 auto;
  line-height: 1; }
  .circle-link a, .circle-link .text-link {
    text-decoration: none; }
  .circle-link a {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0; }
    @media (min-width: 1024px) {
      .circle-link a {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }

.circle-links-component .circle-link:last-child {
  margin-right: 18px; }

.photo-hotspots {
  position: relative; }
  .photo-hotspots .hotspot {
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    margin-top: -10px;
    margin-left: -10px;
    box-shadow: none;
    transition: all 200ms ease-out;
    text-decoration: none; }
    .photo-hotspots .hotspot:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: #FFF;
      font-weight: normal; }
    .photo-hotspots .hotspot[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .photo-hotspots .hotspot[class*="btn-"]:not(.title).btn-sm:before, .photo-hotspots .btn-group-sm > .hotspot.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class*="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) [class^="icon-"],
      .photo-hotspots .hotspot[class*="btn-"]:not(.title) .fa {
        display: none; }
    .photo-hotspots .hotspot::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      width: 10px; }
    .photo-hotspots .hotspot .hotspot-border-inner,
    .photo-hotspots .hotspot .hotspot-border-outer {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      border-radius: 50%;
      transition: all 200ms ease-out; }
    .photo-hotspots .hotspot .hotspot-border-inner {
      font-size: 10px;
      line-height: 20px;
      width: 26px;
      height: 26px;
      border: 3px solid transparent;
      z-index: 1; }
    .photo-hotspots .hotspot .hotspot-border-outer {
      content: '';
      width: 32px;
      height: 32px;
      border: 3px solid #FFF; }
    .photo-hotspots .hotspot:hover {
      width: 40px;
      height: 40px;
      margin-top: -20px;
      margin-left: -20px; }
      .photo-hotspots .hotspot:hover .hotspot-border-inner {
        line-height: 40px;
        width: 46px;
        height: 46px;
        border: 3px solid transparent; }
      .photo-hotspots .hotspot:hover .hotspot-border-outer {
        width: 52px;
        height: 52px;
        border: 3px solid #FFF; }
    .photo-hotspots .hotspot.dark {
      background: rgba(0, 0, 0, 0.3); }
      .photo-hotspots .hotspot.dark::before {
        color: #000; }
      .photo-hotspots .hotspot.dark .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark .hotspot-border-outer {
        border-color: #000; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-inner {
        border-color: transparent; }
      .photo-hotspots .hotspot.dark:hover .hotspot-border-outer {
        border-color: #000; }

.photo-hotspots-tooltip.tooltip {
  z-index: 999; }
  .photo-hotspots-tooltip.tooltip .tooltip-inner {
    padding: 0;
    max-width: 300px; }

.photo-hotspots-tooltip-text {
  text-align: left;
  padding: 8px; }
  .photo-hotspots-tooltip-text > * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-transform: inherit;
    letter-spacing: inherit;
    color: inherit;
    margin-bottom: 0; }
  .photo-hotspots-tooltip-text + .secondary-text {
    padding-top: 0; }
  .photo-hotspots-tooltip-text + .photo-hotspots-tooltip-product {
    border-top: 1px solid #D6D6D6; }

.photo-hotspots-tooltip-product {
  text-decoration: none; }
  .photo-hotspots-tooltip-product:hover {
    text-decoration: none; }
  .photo-hotspots-tooltip-product .product-image {
    width: 75px;
    height: 75px;
    background-size: cover;
    background-repeat: no-repeat; }
  .photo-hotspots-tooltip-product .product-attributes {
    max-width: calc(300px - 75px - 5px);
    padding: 8px;
    text-align: left; }
  .photo-hotspots-tooltip-product .product-name {
    font-size: 14px;
    color: #000;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .photo-hotspots-tooltip-product .product-price {
    font-size: 14px;
    color: #595959;
    margin-top: 4px; }
    .photo-hotspots-tooltip-product .product-price .strike-through {
      margin-right: 5px; }
    .photo-hotspots-tooltip-product .product-price .sales {
      color: #BF242B; }
  .photo-hotspots-tooltip-product .product-message {
    display: block;
    font-size: 10px;
    text-transform: uppercase;
    color: #595959;
    margin-top: 4px; }

.popover {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  background-color: #FFF;
  border-color: transparent;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  max-width: 300px; }
  .popover .popover-header {
    font-family: "archia-medium", sans-serif;
    font-size: 17px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal;
    background-color: #595959;
    color: #FFF;
    border-top-left-radius: calc(0px - 1px);
    border-top-right-radius: calc(0px - 1px); }
    @media (min-width: 1024px) {
      .popover .popover-header {
        font-size: 20px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }
  .popover .popover-body {
    padding: 12px;
    font-size: 12px; }
    .popover .popover-body p {
      font-size: 12px; }
    .popover .popover-body ul:only-child {
      margin-bottom: 0; }
  .popover.bs-popover-bottom .popover-header:before, .popover.bs-popover-auto[x-placement^="bottom"] .popover-header:before {
    display: none; }
  .popover.bs-popover-bottom .arrow:after, .popover.bs-popover-auto[x-placement^="bottom"] .arrow:after {
    border-bottom-color: transparent; }

.product-detail .price,
.quick-view-dialog .price {
  color: #000; }
  .product-detail .price .strike-through,
  .quick-view-dialog .price .strike-through {
    text-decoration: line-through;
    color: #595959; }
  .product-detail .price del ~ .sales,
  .quick-view-dialog .price del ~ .sales {
    float: left;
    margin-right: 12px; }
  .product-detail .price .tiered,
  .quick-view-dialog .price .tiered {
    color: #000; }
    .product-detail .price .tiered table .table-header,
    .quick-view-dialog .price .tiered table .table-header {
      display: none; }
    .product-detail .price .tiered td,
    .product-detail .price .tiered span.price,
    .quick-view-dialog .price .tiered td,
    .quick-view-dialog .price .tiered span.price {
      font-size: paragraph-font-size(small);
      font-weight: bold; }
    .product-detail .price .tiered td,
    .quick-view-dialog .price .tiered td {
      padding: 6px; }
      .product-detail .price .tiered td.quantity,
      .quick-view-dialog .price .tiered td.quantity {
        font-weight: normal;
        text-align: right; }
      .product-detail .price .tiered td.value,
      .quick-view-dialog .price .tiered td.value {
        text-align: left; }
    .product-detail .price .tiered .table-header,
    .quick-view-dialog .price .tiered .table-header {
      padding: 6px; }
    .product-detail .price .tiered .column-header,
    .quick-view-dialog .price .tiered .column-header {
      padding: 6px;
      font-weight: normal; }
    .product-detail .price .tiered .column-header.quantity,
    .quick-view-dialog .price .tiered .column-header.quantity {
      text-align: right; }

.product-detail .price,
.quick-view-dialog .price,
.cart-page .price,
.minicart .price,
.order-product-summary .price,
.product-tile .price,
.suggestions-wrapper .price {
  font-weight: bold; }
  .product-detail .price del ~ .sales,
  .quick-view-dialog .price del ~ .sales,
  .cart-page .price del ~ .sales,
  .minicart .price del ~ .sales,
  .order-product-summary .price del ~ .sales,
  .product-tile .price del ~ .sales,
  .suggestions-wrapper .price del ~ .sales {
    color: #BF242B; }

.product-detail .price del ~ .sales,
.quick-view-dialog .price del ~ .sales,
.product-tile .price del ~ .sales {
  float: none;
  margin-right: 0;
  margin-left: 12px; }

.product-info .product-line-item + .product-line-item,
.product-info .product-line-item + .bonus-product-line-item,
.product-info .product-line-item + .shipment-block,
.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-info .bonus-product-line-item + .shipment-block,
.product-info .multi-shipping + .product-line-item,
.product-info .multi-shipping + .bonus-product-line-item,
.product-info .multi-shipping + .shipment-block,
.product-info .shipment-block + .product-line-item,
.product-info .shipment-block + .bonus-product-line-item,
.product-info .shipment-block + .shipment-block,
.product-summary .product-line-item + .product-line-item,
.product-summary .product-line-item + .bonus-product-line-item,
.product-summary .product-line-item + .shipment-block,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .shipment-block,
.product-summary .multi-shipping + .product-line-item,
.product-summary .multi-shipping + .bonus-product-line-item,
.product-summary .multi-shipping + .shipment-block,
.product-summary .shipment-block + .product-line-item,
.product-summary .shipment-block + .bonus-product-line-item,
.product-summary .shipment-block + .shipment-block {
  margin-top: 6px;
  padding-top: 12px;
  border-top: 1px solid #D6D6D6;
  border-radius: 0px; }

.product-info .bonus-product-line-item + .product-line-item,
.product-info .bonus-product-line-item + .bonus-product-line-item,
.product-summary .bonus-product-line-item + .product-line-item,
.product-summary .bonus-product-line-item + .bonus-product-line-item {
  border-top: none; }

.product-info .shipment-block + .shipment-block,
.product-summary .shipment-block + .shipment-block {
  margin-top: 24px;
  padding-top: 24px; }

.order-product-summary .product-info, .order-product-summary
.product-summary, .order-product-summary
.multi-shipping {
  padding: 0;
  margin: 0; }
  .order-product-summary .product-info h3:not(:first-child), .order-product-summary
  .product-summary h3:not(:first-child), .order-product-summary
  .multi-shipping h3:not(:first-child) {
    margin-top: 12px; }

.product-info .product-summary-block > .card,
.product-summary .product-summary-block > .card,
.multi-shipping .product-summary-block > .card {
  border: none; }
  .product-info .product-summary-block > .card .card-body,
  .product-summary .product-summary-block > .card .card-body,
  .multi-shipping .product-summary-block > .card .card-body {
    padding: 0; }

.product-info .item-image,
.product-summary .item-image,
.multi-shipping .item-image {
  width: 100px;
  margin-right: 12px;
  flex-grow: 0;
  flex-shrink: 0; }
  .product-info .item-image img.product-image,
  .product-summary .item-image img.product-image,
  .multi-shipping .item-image img.product-image {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    height: auto; }

.product-info .line-item-name,
.product-summary .line-item-name,
.multi-shipping .line-item-name {
  display: block;
  font-family: "archia-medium", sans-serif;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0;
  text-transform: none;
  font-weight: normal;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 12px); }
  @media (min-width: 1024px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  @media (min-width: 769px) {
    .product-info .line-item-name,
    .product-summary .line-item-name,
    .multi-shipping .line-item-name {
      width: calc(100% - 24px); } }

.product-info .item-attributes,
.product-summary .item-attributes,
.multi-shipping .item-attributes {
  color: #595959;
  padding: 0 0 6px;
  width: calc(100% - 100px); }

.product-info .item-attributes,
.product-info .item-attributes p,
.product-info .item-price-qty,
.product-info .line-item-availability,
.product-summary .item-attributes,
.product-summary .item-attributes p,
.product-summary .item-price-qty,
.product-summary .line-item-availability,
.multi-shipping .item-attributes,
.multi-shipping .item-attributes p,
.multi-shipping .item-price-qty,
.multi-shipping .line-item-availability {
  font-size: 14px;
  margin: 0; }

.product-info .line-item-availability .line-item-attributes,
.product-summary .line-item-availability .line-item-attributes,
.multi-shipping .line-item-availability .line-item-attributes {
  display: inline; }
  .product-info .line-item-availability .line-item-attributes:first-child:after,
  .product-summary .line-item-availability .line-item-attributes:first-child:after,
  .multi-shipping .line-item-availability .line-item-attributes:first-child:after {
    content: ': '; }

.product-info .line-item-pricing-info,
.product-summary .line-item-pricing-info,
.multi-shipping .line-item-pricing-info {
  font-size: 14px;
  margin-bottom: 0; }

.product-info .item-price-qty,
.product-info .product-card-footer,
.product-summary .item-price-qty,
.product-summary .product-card-footer,
.multi-shipping .item-price-qty,
.multi-shipping .product-card-footer {
  padding-top: 6px;
  padding-bottom: 6px; }
  .product-info .item-price-qty.row > .col,
  .product-info .product-card-footer.row > .col,
  .product-summary .item-price-qty.row > .col,
  .product-summary .product-card-footer.row > .col,
  .multi-shipping .item-price-qty.row > .col,
  .multi-shipping .product-card-footer.row > .col {
    padding: 0; }
  .product-info .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-info .item-price-qty .col-4 + .line-item-quantity,
  .product-info .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-info .product-card-footer .col-4 + .line-item-quantity,
  .product-summary .item-price-qty .line-item-unit-price + .line-item-quantity,
  .product-summary .item-price-qty .col-4 + .line-item-quantity,
  .product-summary .product-card-footer .line-item-unit-price + .line-item-quantity,
  .product-summary .product-card-footer .col-4 + .line-item-quantity,
  .multi-shipping .item-price-qty .line-item-unit-price + .line-item-quantity,
  .multi-shipping .item-price-qty .col-4 + .line-item-quantity,
  .multi-shipping .product-card-footer .line-item-unit-price + .line-item-quantity,
  .multi-shipping .product-card-footer .col-4 + .line-item-quantity {
    align-items: center;
    text-align: center; }
  .product-info .item-price-qty .line-item-total-price,
  .product-info .product-card-footer .line-item-total-price,
  .product-summary .item-price-qty .line-item-total-price,
  .product-summary .product-card-footer .line-item-total-price,
  .multi-shipping .item-price-qty .line-item-total-price,
  .multi-shipping .product-card-footer .line-item-total-price {
    align-items: flex-end;
    text-align: right; }

.product-info .quantity-label,
.product-summary .quantity-label,
.multi-shipping .quantity-label {
  display: none; }

.product-info del,
.product-info .strike-through,
.product-summary del,
.product-summary .strike-through,
.multi-shipping del,
.multi-shipping .strike-through {
  text-decoration: line-through;
  color: #595959; }
  .product-info del .value,
  .product-info .strike-through .value,
  .product-summary del .value,
  .product-summary .strike-through .value,
  .multi-shipping del .value,
  .multi-shipping .strike-through .value {
    margin-right: 4px; }

.product-info .bonus-line-item-msg,
.product-summary .bonus-line-item-msg,
.multi-shipping .bonus-line-item-msg {
  margin-top: 6px; }

.product-info .nested-line-item,
.product-summary .nested-line-item,
.multi-shipping .nested-line-item {
  margin: 0;
  border: 1px solid #D6D6D6;
  padding: 6px; }
  .product-info .nested-line-item ~ .nested-line-item,
  .product-summary .nested-line-item ~ .nested-line-item,
  .multi-shipping .nested-line-item ~ .nested-line-item {
    border-top: 0; }

.product-info .bonus-line-item-msg,
.product-info .unit-price-label,
.product-info .qty-card-quantity-label,
.product-info .quantity-label,
.product-info .line-item-total-price-label,
.product-summary .bonus-line-item-msg,
.product-summary .unit-price-label,
.product-summary .qty-card-quantity-label,
.product-summary .quantity-label,
.product-summary .line-item-total-price-label,
.multi-shipping .bonus-line-item-msg,
.multi-shipping .unit-price-label,
.multi-shipping .qty-card-quantity-label,
.multi-shipping .quantity-label,
.multi-shipping .line-item-total-price-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0;
  opacity: 1; }

.product-info .non-adjusted-price,
.product-summary .non-adjusted-price,
.multi-shipping .non-adjusted-price {
  display: none; }

.product-info .line-item-promo,
.product-summary .line-item-promo,
.multi-shipping .line-item-promo {
  color: #00865A;
  font-size: 14px; }

.product-info .remove-product, .product-info .change-bonus-product,
.product-summary .remove-product,
.product-summary .change-bonus-product,
.multi-shipping .remove-product,
.multi-shipping .change-bonus-product {
  text-decoration: underline;
  color: #000; }

.product-info .line-item-availability .line-item-attributes:first-child:after,
.product-summary .line-item-availability .line-item-attributes:first-child:after,
.multi-shipping .line-item-availability .line-item-attributes:first-child:after {
  content: ''; }

.product-info .line-item-availability .line-item-attributes.out-of-stock,
.product-summary .line-item-availability .line-item-attributes.out-of-stock,
.multi-shipping .line-item-availability .line-item-attributes.out-of-stock {
  color: #72060b;
  font-weight: bold;
  background: #f4e9ea;
  padding: 5px 10px;
  margin-top: 7px;
  display: inline-block;
  width: fit-content; }

.product-tile {
  margin-bottom: 45px; }
  .product-tile .image-container {
    position: relative;
    margin-bottom: 9px; }
  .product-tile .tile-image {
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0; }
  .product-tile .product-tile-image-link {
    display: block; }
  .product-tile .quickview:not(.quick-add-button) {
    position: absolute;
    bottom: 10px;
    right: 10px;
    text-decoration: none; }
    .product-tile .quickview:not(.quick-add-button) .quickview-icon {
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: rgba(255, 255, 255, 0.7);
      box-shadow: none;
      border-radius: 50%; }
      .product-tile .quickview:not(.quick-add-button) .quickview-icon:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.7);
        font-weight: normal; }
      .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: rgba(0, 0, 0, 0.7);
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title).btn-sm, .product-tile .quickview:not(.quick-add-button) .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title).btn-sm:before, .product-tile .quickview:not(.quick-add-button) .btn-group-sm > .quickview-icon.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: rgba(0, 0, 0, 0.7);
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title) [class*="icon-"],
        .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title) [class^="icon-"],
        .product-tile .quickview:not(.quick-add-button) .quickview-icon[class*="btn-"]:not(.title) .fa {
          display: none; }
      .product-tile .quickview:not(.quick-add-button) .quickview-icon::before {
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%); }
    .product-tile .quickview:not(.quick-add-button).btn {
      width: calc(100% - 20px);
      opacity: 0; }
  .product-tile .tile-body {
    position: relative; }
    .product-tile .tile-body :last-child {
      margin-bottom: 0; }
  .product-tile .color-swatches {
    margin-top: 9px;
    margin-bottom: 10px;
    font-size: 0; }
    .product-tile .color-swatches .swatches::after {
      display: block;
      content: "";
      clear: both; }
    .product-tile .color-swatches .swatch-link {
      text-decoration: none; }
      .product-tile .color-swatches .swatch-link:first-child .swatch-circle {
        margin-left: 3px; }
    .product-tile .color-swatches .swatch-circle {
      width: 18px;
      height: 18px;
      background-color: #FFF;
      background-size: cover;
      display: block;
      position: relative;
      text-align: center;
      border-radius: 50%;
      margin-right: 8px;
      margin-bottom: 8px; }
      .product-tile .color-swatches .swatch-circle.color-value {
        transition: box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.color-value:hover {
          box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #D6D6D6; }
        .product-tile .color-swatches .swatch-circle.color-value.selected {
          box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #000; }
          .product-tile .color-swatches .swatch-circle.color-value.selected:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 7px;
            color: #FFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) {
            padding-right: 40px; }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title) {
              padding-right: 40px; }
              .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.color-value.selected.btn[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.color-value.selected[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.color-value.selected::after {
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            display: none;
            background: rgba(0, 0, 0, 0.7);
            width: 14px;
            height: 14px;
            line-height: 14px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            z-index: 1; }
        .product-tile .color-swatches .swatch-circle.color-value.unavailable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:before, .product-tile .color-swatches .swatch-circle.color-value.unavailable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:after {
            background-color: #D6D6D6; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:before {
            background-color: #FFF;
            margin-right: 1px;
            box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
            width: 2px; }
        .product-tile .color-swatches .swatch-circle.color-value.unavailable {
          pointer-events: none;
          pointer-events: auto;
          cursor: not-allowed;
          position: relative; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:before, .product-tile .color-swatches .swatch-circle.color-value.unavailable:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:after {
            background-color: #D6D6D6; }
          .product-tile .color-swatches .swatch-circle.color-value.unavailable:before {
            background-color: #FFF;
            margin-right: 1px;
            box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
            width: 2px; }
      .product-tile .color-swatches .swatch-circle.non-color-swatch-value {
        transition: border 200ms ease-out, box-shadow 200ms ease-out; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value:not(.unavailable):hover {
          border-color: transparent;
          box-shadow: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover {
          background: #FFF;
          color: #000;
          border-color: transparent;
          box-shadow: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover:after {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: 8px;
            color: #FFF;
            font-weight: normal; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
            padding-right: 40px; }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: #FFF;
              font-weight: normal;
              position: absolute;
              transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
              padding-right: 40px; }
              .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .product-tile .color-swatches .btn-group-sm > .swatch-circle.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: #FFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
            .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
              display: none; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected::after, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.selected:hover::after {
            position: absolute;
            top: -5.33333px;
            right: -5.33333px;
            background: #0C0A08;
            width: 16px;
            height: 16px;
            line-height: 16px;
            padding-left: 1px;
            text-align: center;
            border-radius: 50%;
            display: none; }
        .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable {
          pointer-events: none;
          pointer-events: auto;
          position: relative; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable:not(.bis-enabled):before, .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable:not(.bis-enabled):after {
            content: "";
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%) rotate(45deg);
            height: calc(100% + 15px);
            width: 1px; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable:not(.bis-enabled):after {
            background-color: #D6D6D6; }
          .product-tile .color-swatches .swatch-circle.non-color-swatch-value.unavailable:not(.bis-enabled):before {
            background-color: #FFF;
            margin-right: 1px;
            box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
            width: 2px; }
      .product-tile .color-swatches .swatch-circle.selected {
        box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #000; }
  .product-tile .pdp-link {
    margin-bottom: 4px; }
    .product-tile .pdp-link .link {
      display: block;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 500;
      color: #000;
      text-decoration: none; }
      .product-tile .pdp-link .link:hover {
        color: #000;
        text-decoration: none; }
  .product-tile .price {
    margin-bottom: 10px;
    font-size: 11px;
    color: #000; }
    .product-tile .price::after {
      display: block;
      content: "";
      clear: both; }
    .product-tile .price del,
    .product-tile .price .strike-through {
      color: #595959; }
    .product-tile .price del ~ .sales {
      float: left;
      margin-right: 12px;
      color: #BF242B; }
  .product-tile .ratings {
    margin-bottom: 20px;
    font-size: 11px;
    color: #000;
    flex-basis: 100%;
    max-width: 100%; }
    .product-tile .ratings .fa-star:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .product-tile .ratings .fa-star[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-half-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-half-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-half-o[class*="btn-"]:not(.title) .fa {
        display: none; }
    .product-tile .ratings .fa-star-o:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title).btn-sm:before, .product-tile .ratings .btn-group-sm > .fa-star-o.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class*="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) [class^="icon-"],
      .product-tile .ratings .fa-star-o[class*="btn-"]:not(.title) .fa {
        display: none; }
  @media (min-width: 1024px) {
    .product-tile.on-hover .color-swatches .swatches {
      opacity: 0; }
    .product-tile.on-hover:hover .color-swatches .swatches {
      opacity: 1; } }
  .product-tile:hover .product-tile-image-container:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: inset 0px -2px 0px #000; }
  .product-tile:hover .quickview.btn {
    opacity: 1; }
  @media (min-width: 769px) {
    .product-tile .show-secondary-image:hover .product-tile-image {
      visibility: hidden; }
    .product-tile .show-secondary-image:hover .product-tile-image-secondary {
      visibility: visible; } }
  .product-tile .show-secondary-image .product-tile-image {
    display: block;
    visibility: visible; }
  .product-tile .show-secondary-image .product-tile-image-secondary {
    display: block;
    position: absolute;
    visibility: hidden; }

.product-tile {
  position: relative;
  margin-bottom: 0;
  padding-bottom: 45px;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .product-tile .promotion {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    color: #BF242B; }
    @media (min-width: 1024px) {
      .product-tile .promotion {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .product-tile .tile-body {
    flex: 1;
    display: flex;
    flex-direction: column; }
    @media (max-width: 1023.98px) {
      .product-tile .tile-body {
        padding: 0 6px; } }
  .product-tile .product-tile-image-container:after {
    display: none; }
  .product-tile .tile-image {
    background-color: #F2F2F2; }
  .product-tile .quickview:not(.quick-add-button) {
    background-color: #F8F8F8;
    border-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 6px 12px; }
    .product-tile .quickview:not(.quick-add-button).btn {
      width: 100%;
      left: 0; }
  .product-tile .quick-add-button {
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 6px 12px; }
  .product-tile .color-swatches {
    font-size: inherit; }
    .product-tile .color-swatches .swatch-link {
      width: calc(20% - 3px);
      margin-right: 3px;
      display: inherit; }
      .product-tile .color-swatches .swatch-link.empty {
        padding-top: calc(20% - 3px); }
      .product-tile .color-swatches .swatch-link:first-child .swatch-circle {
        margin-left: 0; }
    .product-tile .color-swatches .swatch-color-count {
      font-family: "Roboto", sans-serif;
      font-size: 11px;
      color: #2B2B2A;
      text-decoration: none;
      margin-left: 0;
      width: calc(20% - 3px);
      background-color: #F8F8F8;
      display: flex;
      justify-content: center;
      align-items: center; }
    .product-tile .color-swatches .swatch-circle {
      width: 1.714em;
      height: 1.714em;
      margin-right: 0;
      margin-bottom: 0; }
      @media (min-width: 544px) {
        .product-tile .color-swatches .swatch-circle {
          width: 1.929em;
          height: 1.929em; } }
      @media (min-width: 769px) {
        .product-tile .color-swatches .swatch-circle {
          width: 2.571em;
          height: 2.571em; } }
    .product-tile .color-swatches .swatch-thumbnail {
      background-color: #F8F8F8;
      padding: 5px;
      display: inline-block; }
      .product-tile .color-swatches .swatch-thumbnail img {
        display: block;
        width: 100%;
        height: auto; }
  .product-tile .pdp-link {
    flex: 1; }
    .product-tile .pdp-link .link {
      font-weight: 500;
      line-height: 20px; }
  .product-tile .price {
    font-family: "Roboto", sans-serif;
    font-size: 11px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 12px; }
    .product-tile .price del ~ .sales {
      float: none;
      margin-right: 0;
      margin-left: 12px; }
  .product-tile .product-rating {
    height: 15px; }
  .product-tile .product-rating-stars {
    display: flex; }
    .product-tile .product-rating-stars .rating-icon {
      display: block;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat; }
    .product-tile .product-rating-stars .rating-icon-star {
      background-image: url("../images/icons/icon-star.svg"); }
    .product-tile .product-rating-stars .rating-icon-star-half {
      background-image: url("../images/icons/icon-star-half.svg"); }
    .product-tile .product-rating-stars .rating-icon-star-empty {
      background-image: url("../images/icons/icon-star-empty.svg"); }
  .product-tile .product-rating-count {
    font-size: 11px;
    line-height: 1; }
  .product-tile .ratings {
    margin-bottom: 10px; }
    .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline {
      float: none;
      justify-content: center !important; }
      .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-star,
      .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-half-star,
      .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-empty-star {
        background: none !important; }
        .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-star::before,
        .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-half-star::before,
        .product-tile .ratings .yotpo-display-wrapper .yotpo-bottomline .yotpo-stars .yotpo-icon-empty-star::before {
          font-size: 16px; }
  @media (min-width: 1024px) {
    .product-tile.on-hover .color-swatches .swatches, .product-tile.on-hover .color-swatches .swatch-color-count {
      opacity: 1; }
    .product-tile.on-hover:hover .color-swatches .swatches, .product-tile.on-hover:hover .color-swatches .swatch-color-count {
      opacity: 1; } }

.quick-view-dialog,
.choose-bonus-product-dialog {
  max-width: none;
  margin: 8px; }
  @media (min-width: 1024px) {
    .quick-view-dialog,
    .choose-bonus-product-dialog {
      max-width: 900px;
      margin: 24px auto; } }
  .quick-view-dialog .modal-body,
  .choose-bonus-product-dialog .modal-body {
    overflow-x: hidden;
    overflow-y: auto; }
  .quick-view-dialog .product-quickview,
  .choose-bonus-product-dialog .product-quickview {
    padding: 0; }
    .quick-view-dialog .product-quickview .availability,
    .choose-bonus-product-dialog .product-quickview .availability {
      margin-left: 0;
      margin-right: 0; }
    .quick-view-dialog .product-quickview .product-availability,
    .choose-bonus-product-dialog .product-quickview .product-availability {
      display: none; }
    .quick-view-dialog .product-quickview .pdp-in-store-inventory,
    .choose-bonus-product-dialog .product-quickview .pdp-in-store-inventory {
      margin-top: 12px;
      width: 100%; }
  .quick-view-dialog .product-detail,
  .choose-bonus-product-dialog .product-detail {
    margin-top: 0;
    margin-bottom: 0; }
    .quick-view-dialog .product-detail .product-name,
    .choose-bonus-product-dialog .product-detail .product-name {
      margin-bottom: 0; }
  .quick-view-dialog .primary-images,
  .choose-bonus-product-dialog .primary-images {
    margin: 0 0 12px; }
    @media (min-width: 769px) {
      .quick-view-dialog .primary-images,
      .choose-bonus-product-dialog .primary-images {
        margin-bottom: 0; } }
  .quick-view-dialog .selectable-bonus-product-line-item,
  .choose-bonus-product-dialog .selectable-bonus-product-line-item {
    margin-top: 6px;
    margin-bottom: 6px;
    padding-top: 6px;
    padding-bottom: 6px; }
  .quick-view-dialog .beenSelected,
  .choose-bonus-product-dialog .beenSelected {
    background-color: #D6D6D6; }
  .quick-view-dialog .availability-container,
  .choose-bonus-product-dialog .availability-container {
    padding: 0; }
  .quick-view-dialog .modal-footer,
  .choose-bonus-product-dialog .modal-footer {
    padding-top: 12px;
    border-top: 1px solid #D6D6D6; }
    .quick-view-dialog .modal-footer .prices,
    .choose-bonus-product-dialog .modal-footer .prices {
      text-align: center;
      margin-bottom: 6px; }
      .quick-view-dialog .modal-footer .prices .price,
      .quick-view-dialog .modal-footer .prices .sales,
      .choose-bonus-product-dialog .modal-footer .prices .price,
      .choose-bonus-product-dialog .modal-footer .prices .sales {
        font-size: 15px;
        font-family: "archia-medium", sans-serif; }
    .quick-view-dialog .modal-footer label,
    .quick-view-dialog .modal-footer .non-input-label,
    .choose-bonus-product-dialog .modal-footer label,
    .choose-bonus-product-dialog .modal-footer .non-input-label {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      margin-bottom: 6px;
      text-transform: none;
      letter-spacing: 0;
      font-size: 12px;
      display: inline-block;
      margin: 0 0 0 1px;
      padding: 9px 12px 0 12px;
      position: absolute;
      opacity: 0;
      transition: opacity 200ms ease-out; }
      .quick-view-dialog .modal-footer label:has(~ .form-control:not(:placeholder-shown)), .quick-view-dialog .modal-footer label:has(~ .form-control:autofill:placeholder-shown),
      .quick-view-dialog .modal-footer .non-input-label:has(~ .form-control:not(:placeholder-shown)),
      .quick-view-dialog .modal-footer .non-input-label:has(~ .form-control:autofill:placeholder-shown),
      .choose-bonus-product-dialog .modal-footer label:has(~ .form-control:not(:placeholder-shown)),
      .choose-bonus-product-dialog .modal-footer label:has(~ .form-control:autofill:placeholder-shown),
      .choose-bonus-product-dialog .modal-footer .non-input-label:has(~ .form-control:not(:placeholder-shown)),
      .choose-bonus-product-dialog .modal-footer .non-input-label:has(~ .form-control:autofill:placeholder-shown) {
        opacity: 1; }
      .quick-view-dialog .modal-footer label:has(~ textarea.form-control),
      .quick-view-dialog .modal-footer .non-input-label:has(~ textarea.form-control),
      .choose-bonus-product-dialog .modal-footer label:has(~ textarea.form-control),
      .choose-bonus-product-dialog .modal-footer .non-input-label:has(~ textarea.form-control) {
        padding-top: 12px; }
  .quick-view-dialog .set-item,
  .choose-bonus-product-dialog .set-item {
    padding: 0; }
    .quick-view-dialog .set-item .product-name,
    .choose-bonus-product-dialog .set-item .product-name {
      font-family: "archia-medium", sans-serif;
      font-size: 24px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal;
      margin-bottom: 6px; }
      @media (min-width: 1024px) {
        .quick-view-dialog .set-item .product-name,
        .choose-bonus-product-dialog .set-item .product-name {
          font-size: 29px;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
          font-style: normal; } }
  .quick-view-dialog .bonus-product-item,
  .choose-bonus-product-dialog .bonus-product-item {
    padding: 0; }
    .quick-view-dialog .bonus-product-item ~ .bonus-product-item,
    .choose-bonus-product-dialog .bonus-product-item ~ .bonus-product-item {
      margin-top: 12px;
      padding-top: 12px;
      border-top: 1px solid #D6D6D6; }
    .quick-view-dialog .bonus-product-item .product-quickview + hr,
    .choose-bonus-product-dialog .bonus-product-item .product-quickview + hr {
      display: none; }
    .quick-view-dialog .bonus-product-item .bonus-quantity,
    .quick-view-dialog .bonus-product-item .bonus-option,
    .quick-view-dialog .bonus-product-item .select-bonus-product,
    .quick-view-dialog .bonus-product-item .bonus-product-price:not(:empty),
    .choose-bonus-product-dialog .bonus-product-item .bonus-quantity,
    .choose-bonus-product-dialog .bonus-product-item .bonus-option,
    .choose-bonus-product-dialog .bonus-product-item .select-bonus-product,
    .choose-bonus-product-dialog .bonus-product-item .bonus-product-price:not(:empty) {
      margin-top: 12px; }
    .quick-view-dialog .bonus-product-item .attribute label,
    .choose-bonus-product-dialog .bonus-product-item .attribute label {
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 11px;
      line-height: 12px;
      margin-bottom: 6px;
      text-transform: none;
      letter-spacing: 0;
      font-size: 12px;
      display: inline-block;
      margin: 0 0 0 1px;
      padding: 9px 12px 0 12px;
      position: absolute;
      opacity: 0;
      transition: opacity 200ms ease-out; }
      .quick-view-dialog .bonus-product-item .attribute label:has(~ .form-control:not(:placeholder-shown)), .quick-view-dialog .bonus-product-item .attribute label:has(~ .form-control:autofill:placeholder-shown),
      .choose-bonus-product-dialog .bonus-product-item .attribute label:has(~ .form-control:not(:placeholder-shown)),
      .choose-bonus-product-dialog .bonus-product-item .attribute label:has(~ .form-control:autofill:placeholder-shown) {
        opacity: 1; }
      .quick-view-dialog .bonus-product-item .attribute label:has(~ textarea.form-control),
      .choose-bonus-product-dialog .bonus-product-item .attribute label:has(~ textarea.form-control) {
        padding-top: 12px; }
    .quick-view-dialog .bonus-product-item .quantity,
    .choose-bonus-product-dialog .bonus-product-item .quantity {
      margin-bottom: 0; }
      .quick-view-dialog .bonus-product-item .quantity label,
      .choose-bonus-product-dialog .bonus-product-item .quantity label {
        display: block;
        margin-right: 0; }
        .quick-view-dialog .bonus-product-item .quantity label:after,
        .choose-bonus-product-dialog .bonus-product-item .quantity label:after {
          display: none; }
  .quick-view-dialog .selected-bonus-products ~ .col-sm-6,
  .choose-bonus-product-dialog .selected-bonus-products ~ .col-sm-6 {
    text-align: right; }
  .quick-view-dialog .selected-bonus-products .bonus-summary,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 0 1px;
    padding: 9px 12px 0 12px;
    position: absolute;
    opacity: 0;
    transition: opacity 200ms ease-out; }
    .quick-view-dialog .selected-bonus-products .bonus-summary:has(~ .form-control:not(:placeholder-shown)), .quick-view-dialog .selected-bonus-products .bonus-summary:has(~ .form-control:autofill:placeholder-shown),
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary:has(~ .form-control:not(:placeholder-shown)),
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary:has(~ .form-control:autofill:placeholder-shown) {
      opacity: 1; }
    .quick-view-dialog .selected-bonus-products .bonus-summary:has(~ textarea.form-control),
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary:has(~ textarea.form-control) {
      padding-top: 12px; }
    .quick-view-dialog .selected-bonus-products .bonus-summary.alert-danger,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary.alert-danger {
      background: transparent;
      border: none; }
    .quick-view-dialog .selected-bonus-products .bonus-summary .pre-cart-products,
    .choose-bonus-product-dialog .selected-bonus-products .bonus-summary .pre-cart-products {
      font-weight: bold; }
  .quick-view-dialog .selected-bonus-products .bonus-summary-products-container,
  .choose-bonus-product-dialog .selected-bonus-products .bonus-summary-products-container {
    position: relative;
    padding: 0 12px 0 12px;
    border: 1px solid #D6D6D6;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0;
    min-height: 48px; }
  .quick-view-dialog .selected-bonus-products .selected-pid,
  .choose-bonus-product-dialog .selected-bonus-products .selected-pid {
    position: relative;
    line-height: 48px; }
    .quick-view-dialog .selected-bonus-products .selected-pid .bonus-product-name,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .bonus-product-name {
      padding: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .quick-view-dialog .selected-bonus-products .selected-pid + .selected-pid::before,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid + .selected-pid::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: #D6D6D6; }
    .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product,
    .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product {
      font-family: "Roboto", sans-serif;
      border: none;
      border-radius: 50%;
      font-size: 0;
      position: absolute;
      top: 0;
      right: 0;
      margin: 0 20px 20px 20px;
      cursor: pointer;
      z-index: 1;
      background-color: #FFF;
      padding: 4px 6px;
      height: 22px;
      width: 22px;
      background-color: transparent;
      margin: 0;
      padding: 0; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before,
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 11px;
        color: inherit;
        font-weight: normal; }
      .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title),
      .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title),
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before, .quick-view-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title).btn-sm:before,
          .choose-bonus-product-dialog .selected-bonus-products .selected-pid .btn-group-sm > .remove-bonus-product.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: inherit;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .quick-view-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa,
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class*="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) [class^="icon-"],
        .choose-bonus-product-dialog .selected-bonus-products .selected-pid .remove-bonus-product[class*="btn-"]:not(.title) .fa {
          display: none; }

.choose-bonus-product-dialog {
  height: 100vh;
  margin: 0;
  margin-left: auto;
  max-width: 100vw; }
  @media (min-width: 769px) {
    .choose-bonus-product-dialog {
      max-width: 400px; } }
  .choose-bonus-product-dialog .modal-content {
    height: 100vh;
    background-color: #FFF; }
    .choose-bonus-product-dialog .modal-content .modal-header {
      padding: 24px 24px;
      font-size: 18px;
      letter-spacing: -1px;
      border-bottom: 1px #D6D6D6 solid;
      font-family: "archia-medium", sans-serif; }
    .choose-bonus-product-dialog .modal-content .modal-body {
      padding-top: 0;
      padding: 24px; }
      .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product {
        width: 100%;
        border: 1px #ECEEE4 solid;
        border-radius: 12px;
        padding: 12px;
        margin: 0;
        margin-bottom: 12px; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .primary-images {
          margin-bottom: 30px; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .primary-images .primary-images-main {
            aspect-ratio: 1;
            display: block;
            margin: 0;
            padding: 0;
            width: 100%; }
            .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .primary-images .primary-images-main img:first-of-type {
              display: block !important; }
            .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .primary-images .primary-images-main img,
            .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .primary-images .primary-images-main video {
              padding: 0;
              display: none !important; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product.bonus-product-item {
          border: 0;
          padding: 0;
          margin: 0; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .product-image {
          height: 75px; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .product-name {
          letter-spacing: -1.92px; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .col, .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .col-auto {
          display: flex;
          align-items: center; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product button.select-product {
          height: auto;
          padding-top: 6px;
          padding-bottom: 6px; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch {
          margin-bottom: 24px; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch .attribute {
            display: flex;
            flex-wrap: wrap;
            gap: 4px; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch .non-input-label {
            font-size: 14px;
            line-height: 1em;
            margin-bottom: 24px;
            width: 100%; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch button .swatch {
            margin-bottom: 0; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch span.non-input-label {
            display: block; }
          .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch .swatch-tile-container {
            gap: 4px; }
            .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .swatch .swatch-tile-container button {
              color: #000;
              margin-bottom: 0; }
        .choose-bonus-product-dialog .modal-content .modal-body .choice-of-bonus-product .select-bonus-product {
          background-color: #99FF00;
          border-radius: 9px; }
    .choose-bonus-product-dialog .modal-content .modal-footer {
      display: none; }

#quickViewModal {
  background: rgba(0, 0, 0, 0.5); }
  #quickViewModal .modal-header {
    background-color: #FFF;
    border-bottom: 1px solid #D6D6D6;
    padding: 24px;
    position: sticky; }
    @media (min-width: 1024px) {
      #quickViewModal .modal-header {
        padding: 24px 48px; } }
    #quickViewModal .modal-header .modal-title {
      font-family: "archia-medium", sans-serif;
      font-size: 17px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal;
      color: #000; }
      @media (min-width: 1024px) {
        #quickViewModal .modal-header .modal-title {
          font-size: 20px;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
          font-style: normal; } }
  #quickViewModal.show .modal-content {
    right: 0; }
  #quickViewModal .modal-dialog {
    margin: 0;
    max-width: unset;
    transform: none; }
  #quickViewModal .modal-content {
    position: fixed;
    height: 100dvh;
    right: 0;
    right: -438px;
    margin-left: auto;
    max-width: 100%;
    overflow: unset;
    transition: right 0.3s linear; }
    @media (min-width: 1024px) {
      #quickViewModal .modal-content {
        max-width: 438px; } }
  #quickViewModal .modal-body {
    padding: 0 24px 24px;
    overflow-y: scroll;
    scroll-behavior: smooth; }
    @media (min-width: 1024px) {
      #quickViewModal .modal-body {
        padding: 0 48px 24px; } }
    #quickViewModal .modal-body .product-detail {
      display: flex;
      flex-direction: column;
      gap: 24px; }
    #quickViewModal .modal-body .prices {
      padding-bottom: 24px;
      border-bottom: 1px solid #D6D6D6; }
    #quickViewModal .modal-body .attributes > :not(:last-child) {
      margin-bottom: 24px; }
    #quickViewModal .modal-body .attributes .non-input-label {
      margin-bottom: 12px; }
    #quickViewModal .modal-body .attributes span.non-input-label {
      display: block; }
    #quickViewModal .modal-body .attributes .attribute .size-chart {
      float: inline-end; }
    #quickViewModal .modal-body .swatch-tile-container {
      /* Reduce swatch size to show 5 in row, accounting for smaller quickview modal width */ }
      @media (min-width: 769px) {
        #quickViewModal .modal-body .swatch-tile-container {
          display: grid;
          gap: 6px;
          grid-template-columns: repeat(5, 1fr); }
          #quickViewModal .modal-body .swatch-tile-container .swatch.color-value,
          #quickViewModal .modal-body .swatch-tile-container .swatch.size-value {
            aspect-ratio: 1;
            height: auto;
            width: inherit; } }
    #quickViewModal .modal-body .quickview-product-image {
      background-color: #F8F8F8; }
  #quickViewModal .update-cart-product-global {
    width: 100%; }
  #quickViewModal .product-name,
  #quickViewModal .prices,
  #quickViewModal .primary-images,
  #quickViewModal .primary-images-main,
  #quickViewModal .cart-and-ipay {
    margin-bottom: 0; }
  #quickViewModal .product-wrapper.product-detail .primary-images .primary-images-main {
    overflow-x: scroll;
    display: flex;
    width: calc(100% + (12px * 8));
    margin: 0px -48px;
    padding: 0px 48px; }
    #quickViewModal .product-wrapper.product-detail .primary-images .primary-images-main img {
      margin-left: auto;
      margin-right: auto; }
  #quickViewModal .primary-images {
    max-width: unset; }
  #quickViewModal .image-arrow,
  #quickViewModal .video-play-button {
    display: none; }
  #quickViewModal .cart-and-ipay {
    margin-top: -24px; }
  #quickViewModal .modal-footer {
    display: none;
    padding: 0;
    margin: 0;
    border: 0; }
  #quickViewModal .quickview-viewdetails {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: 1px;
    border-style: solid;
    font-family: "archia-medium", sans-serif;
    text-transform: none;
    transition: all 200ms ease-out;
    text-decoration: none;
    border-radius: 50px;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: -0.42px;
    line-height: 1.5em;
    padding: 12px 20px 12px 20px;
    color: #0C0A08;
    background-color: #FFF;
    border-color: #D6D6D6;
    border-radius: 8px;
    border-width: 1px;
    margin-top: -12px; }
    #quickViewModal .quickview-viewdetails:hover, #quickViewModal .quickview-viewdetails:focus, #quickViewModal .quickview-viewdetails[aria-describedby*="popover"] {
      border-color: #0C0A08; }
    #quickViewModal .quickview-viewdetails[class*="icon-"], #quickViewModal .quickview-viewdetails[class^="icon-"] {
      padding-left: 40px; }
      #quickViewModal .quickview-viewdetails[class*="icon-"]:before, #quickViewModal .quickview-viewdetails[class^="icon-"]:before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }

#quickViewModal #static-size-chart .metric-tabs-container,
#quickViewModal #static-size-chart .size-chart-container,
#quickViewModal #static-size-chart .tab-pane,
#quickViewModal #quickadd-static-size-chart .metric-tabs-container,
#quickViewModal #quickadd-static-size-chart .size-chart-container,
#quickViewModal #quickadd-static-size-chart .tab-pane {
  padding: 0;
  margin: 0;
  background-color: initial; }

#quickViewModal #static-size-chart h2,
#quickViewModal #static-size-chart h3,
#quickViewModal #static-size-chart h4,
#quickViewModal #static-size-chart h5,
#quickViewModal #static-size-chart h6,
#quickViewModal #static-size-chart .h2,
#quickViewModal #static-size-chart .h3,
#quickViewModal #static-size-chart .h4,
#quickViewModal #static-size-chart .h5,
#quickViewModal #static-size-chart .h6,
#quickViewModal #quickadd-static-size-chart h2,
#quickViewModal #quickadd-static-size-chart h3,
#quickViewModal #quickadd-static-size-chart h4,
#quickViewModal #quickadd-static-size-chart h5,
#quickViewModal #quickadd-static-size-chart h6,
#quickViewModal #quickadd-static-size-chart .h2,
#quickViewModal #quickadd-static-size-chart .h3,
#quickViewModal #quickadd-static-size-chart .h4,
#quickViewModal #quickadd-static-size-chart .h5,
#quickViewModal #quickadd-static-size-chart .h6 {
  margin: 0 !important;
  padding: 24px 0 !important;
  font-family: "archia-medium", sans-serif;
  font-size: 17px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    #quickViewModal #static-size-chart h2,
    #quickViewModal #static-size-chart h3,
    #quickViewModal #static-size-chart h4,
    #quickViewModal #static-size-chart h5,
    #quickViewModal #static-size-chart h6,
    #quickViewModal #static-size-chart .h2,
    #quickViewModal #static-size-chart .h3,
    #quickViewModal #static-size-chart .h4,
    #quickViewModal #static-size-chart .h5,
    #quickViewModal #static-size-chart .h6,
    #quickViewModal #quickadd-static-size-chart h2,
    #quickViewModal #quickadd-static-size-chart h3,
    #quickViewModal #quickadd-static-size-chart h4,
    #quickViewModal #quickadd-static-size-chart h5,
    #quickViewModal #quickadd-static-size-chart h6,
    #quickViewModal #quickadd-static-size-chart .h2,
    #quickViewModal #quickadd-static-size-chart .h3,
    #quickViewModal #quickadd-static-size-chart .h4,
    #quickViewModal #quickadd-static-size-chart .h5,
    #quickViewModal #quickadd-static-size-chart .h6 {
      font-size: 20px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

#quickViewModal #static-size-chart thead,
#quickViewModal #static-size-chart tbody,
#quickViewModal #quickadd-static-size-chart thead,
#quickViewModal #quickadd-static-size-chart tbody {
  border-bottom: 1px solid #D6D6D6; }

#quickViewModal #static-size-chart thead tr,
#quickViewModal #quickadd-static-size-chart thead tr {
  border-left: 1px solid #ECEEE4;
  border-right: 1px solid #ECEEE4; }

#quickViewModal #static-size-chart tbody tr,
#quickViewModal #quickadd-static-size-chart tbody tr {
  border-left: 1px solid #D6D6D6;
  border-right: 1px solid #D6D6D6; }

#quickViewModal #static-size-chart th,
#quickViewModal #quickadd-static-size-chart th {
  background-color: #ECEEE4;
  color: #000;
  padding: 12px;
  font-family: "archia-medium", sans-serif;
  font-size: 16px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal; }
  @media (min-width: 1024px) {
    #quickViewModal #static-size-chart th,
    #quickViewModal #quickadd-static-size-chart th {
      font-size: 16px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }
  #quickViewModal #static-size-chart th + th,
  #quickViewModal #quickadd-static-size-chart th + th {
    border-left: 1px solid #D6D6D6; }

#quickViewModal #static-size-chart td,
#quickViewModal #quickadd-static-size-chart td {
  padding: 12px;
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0; }
  @media (min-width: 1024px) {
    #quickViewModal #static-size-chart td,
    #quickViewModal #quickadd-static-size-chart td {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  #quickViewModal #static-size-chart td + td,
  #quickViewModal #quickadd-static-size-chart td + td {
    border-left: 1px solid #D6D6D6; }

#quickViewModal #static-size-chart tr,
#quickViewModal #quickadd-static-size-chart tr {
  background-color: #FFF; }
  #quickViewModal #static-size-chart tr + tr,
  #quickViewModal #quickadd-static-size-chart tr + tr {
    border-top: 1px solid #D6D6D6; }

#quickViewModal .product-bundle .prices {
  border-bottom: 0;
  padding-bottom: 0; }

#quickViewModal .product-bundle .bundle-description,
#quickViewModal .product-bundle .bundle-description p:last-of-type {
  margin-bottom: 0; }

#quickViewModal .product-bundle .detail-panel,
#quickViewModal .product-bundle .hidden-xs-down,
#quickViewModal .product-bundle > hr {
  display: none; }

.slider-title {
  text-align: center; }
  .slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
    font-family: "archia-medium", sans-serif;
    font-size: 24px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal; }
    @media (min-width: 1024px) {
      .slider-title:not(.h1):not(.h2):not(.h3):not(.h4):not(.h5):not(.h6) {
        font-size: 29px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }

.slider-container {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: 0; }
  .slider-container:not(.slider-bullets-inside):not(.slider-bullets-hidden) {
    margin-bottom: 31px; }
  .slider-container .slider {
    opacity: 0; }
    .slider-container .slider.tns-slider {
      opacity: 1; }
    .slider-container .slider.tns-gallery .tns-slide-active {
      z-index: 1; }
  .slider-container .slide {
    transform: translateZ(0); }
  .slider-container .tns-controls button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    user-select: none;
    z-index: 2;
    width: 40px;
    height: 100%;
    background-color: #000;
    border: none;
    display: flex;
    text-align: center;
    transition: background-color 200ms ease-out;
    font-size: 0;
    padding: 0; }
    .slider-container .tns-controls button:before {
      width: 100%;
      align-self: center;
      transition: color 200ms ease-out; }
    .slider-container .tns-controls button:focus {
      outline: none; }
    .slider-container .tns-controls button:hover {
      background-color: #E6FF00; }
      .slider-container .tns-controls button:hover:before {
        color: #000; }
  .slider-container [data-controls='prev'] {
    left: 0; }
    .slider-container [data-controls='prev']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 15px;
      color: #FFF;
      font-weight: normal; }
    .slider-container [data-controls='prev'][class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container [data-controls='next'] {
    right: 0; }
    .slider-container [data-controls='next']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 15px;
      color: #FFF;
      font-weight: normal; }
    .slider-container [data-controls='next'][class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container .tns-nav {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    display: flex;
    bottom: 0;
    z-index: 2;
    bottom: -31px; }
    .slider-container .tns-nav button {
      display: block;
      background: rgba(0, 0, 0, 0.5);
      border: none;
      width: 11px;
      height: 11px;
      margin: 10px 5px 10px 5px;
      padding: 0;
      cursor: pointer;
      transition: transform 200ms ease-out; }
      .slider-container .tns-nav button:focus {
        outline: none; }
      .slider-container .tns-nav button.tns-nav-active {
        background: #000;
        border: none;
        transform: scale(1.09091); }
    .slider-container .tns-nav.with-pagination-btn button {
      order: 1; }
    .slider-container .tns-nav.with-pagination-btn .with-pagination {
      order: 0; }
  .slider-container.pagination-style-dots .tns-nav {
    align-items: center; }
    .slider-container.pagination-style-dots .tns-nav button {
      border-radius: 50%; }
  .slider-container.pagination-style-shortLines .tns-nav {
    align-items: flex-end; }
    .slider-container.pagination-style-shortLines .tns-nav button {
      background-color: #40403F;
      border: none;
      width: 20px;
      height: 2px; }
      .slider-container.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
        background-color: #0C0A08;
        height: 4px;
        transition: background-color 200ms ease-in-out, height 200ms ease-in-out; }
  .slider-container.light .tns-controls button {
    background-color: #FFF; }
    .slider-container.light .tns-controls button::before {
      color: #000; }
    .slider-container.light .tns-controls button:hover {
      background-color: #E6FF00; }
      .slider-container.light .tns-controls button:hover::before {
        color: #FFF; }
  .slider-container.light .tns-nav button {
    background: transparent;
    border: 1px solid #FFF; }
    .slider-container.light .tns-nav button.tns-nav-active {
      background: #FFF;
      border: 1px solid #FFF; }
  .slider-container.light .tns-nav-controller::before {
    color: #FFF; }
  .slider-container.light .tns-nav-controller.pause::before {
    color: #FFF; }
  .slider-container.light.pagination-style-shortLines .tns-nav button {
    background-color: rgba(255, 255, 255, 0.5); }
    .slider-container.light.pagination-style-shortLines .tns-nav button.tns-nav-active, .slider-container.light.pagination-style-shortLines .tns-nav button:hover:not(.tns-nav-active) {
      background-color: #FFF; }
  .slider-container [data-action='stop'],
  .slider-container [data-action='start'] {
    display: none; }
  @media (max-width: 768.98px) {
    .slider-container.slider-buttons-hidden {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden .tns-nav {
      display: none; }
    .slider-container.slider-buttons-inside {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside .tns-nav {
      bottom: 0; }
    .slider-container.next-slide-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.next-slide-1.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.prev-slide-1.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.next-slide-2.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.prev-slide-2.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.next-slide-3.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.prev-slide-3.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.next-slide-4.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.prev-slide-4.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.next-slide-5.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.prev-slide-5.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.next-slide-6.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.prev-slide-6.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.next-slide-7.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.prev-slide-7.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.next-slide-8.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.prev-slide-8.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.next-slide-9.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.prev-slide-9.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.next-slide-10.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.prev-slide-10.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.next-slide-11.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.prev-slide-11.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.next-slide-12.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.prev-slide-12.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.next-slide-13.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.prev-slide-13.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.next-slide-14.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.prev-slide-14.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.next-slide-15.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.prev-slide-15.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.next-slide-16.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.prev-slide-16.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.next-slide-17.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.prev-slide-17.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.next-slide-18.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.prev-slide-18.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.next-slide-19.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.prev-slide-19.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.next-slide-20.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.prev-slide-20.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.next-slide-21.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.prev-slide-21.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.next-slide-22.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.prev-slide-22.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.next-slide-23.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.prev-slide-23.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.next-slide-24.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.prev-slide-24.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.next-slide-25.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.prev-slide-25.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.next-slide-26.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.prev-slide-26.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.next-slide-27.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.prev-slide-27.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.next-slide-28.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.prev-slide-28.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.next-slide-29.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.prev-slide-29.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.next-slide-30.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.prev-slide-30.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.next-slide-31.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.prev-slide-31.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.next-slide-32.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.prev-slide-32.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.next-slide-33.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.prev-slide-33.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.next-slide-34.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.prev-slide-34.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.next-slide-35.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.prev-slide-35.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.next-slide-36.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.prev-slide-36.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.next-slide-37.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.prev-slide-37.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.next-slide-38.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.prev-slide-38.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.next-slide-39.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.prev-slide-39.slider-buttons-hidden {
      margin-left: -12px; }
    .slider-container.next-slide-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.next-slide-40.slider-buttons-hidden {
      margin-right: -12px; }
    .slider-container.prev-slide-40 .tns-inner {
      padding-left: 40%; }
    .slider-container.prev-slide-40.slider-buttons-hidden {
      margin-left: -12px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-container.slider-buttons-hidden-md {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-md .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-md .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-md {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside-md {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-md .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-md-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-md-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-md-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-md-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-md-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-md-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-md-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-md-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-md-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-md-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-md-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-md-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-md-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-md-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-md-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-md-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-md-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-md-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-md-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-md-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-md-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-md-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-md-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-md-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-md-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-md-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-md-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-md-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-md-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-md-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-md-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-md-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-md-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-md-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-md-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-md-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-md-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-md-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-md-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-md-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-md-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-md-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-md-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-md-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-md-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-md-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-md-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-md-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-md-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-md-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-md-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-md-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-md-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-md-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-md-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-md-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-md-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-md-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-md-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-md-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-md-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-md-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-md-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-md-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-md-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-md-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-md-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-md-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-md-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-md-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-md-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-md-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-md-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-md-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-md-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-md-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-md-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-md-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-md-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-md-40 .tns-inner {
      padding-left: 40%; } }
  @media (min-width: 1024px) {
    .slider-container.slider-buttons-hidden-lg {
      padding-left: 0;
      padding-right: 0; }
      .slider-container.slider-buttons-hidden-lg .tns-controls {
        display: none; }
    .slider-container.slider-bullets-hidden-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-hidden-lg .tns-nav {
        display: none; }
    .slider-container.slider-buttons-inside-lg {
      padding-left: 0;
      padding-right: 0; }
    .slider-container.slider-bullets-inside-lg {
      margin-bottom: 0; }
      .slider-container.slider-bullets-inside-lg .tns-nav {
        bottom: 0; }
    .slider-container.next-slide-lg-1 .tns-inner {
      padding-right: 1%; }
    .slider-container.prev-slide-lg-1 .tns-inner {
      padding-left: 1%; }
    .slider-container.next-slide-lg-2 .tns-inner {
      padding-right: 2%; }
    .slider-container.prev-slide-lg-2 .tns-inner {
      padding-left: 2%; }
    .slider-container.next-slide-lg-3 .tns-inner {
      padding-right: 3%; }
    .slider-container.prev-slide-lg-3 .tns-inner {
      padding-left: 3%; }
    .slider-container.next-slide-lg-4 .tns-inner {
      padding-right: 4%; }
    .slider-container.prev-slide-lg-4 .tns-inner {
      padding-left: 4%; }
    .slider-container.next-slide-lg-5 .tns-inner {
      padding-right: 5%; }
    .slider-container.prev-slide-lg-5 .tns-inner {
      padding-left: 5%; }
    .slider-container.next-slide-lg-6 .tns-inner {
      padding-right: 6%; }
    .slider-container.prev-slide-lg-6 .tns-inner {
      padding-left: 6%; }
    .slider-container.next-slide-lg-7 .tns-inner {
      padding-right: 7%; }
    .slider-container.prev-slide-lg-7 .tns-inner {
      padding-left: 7%; }
    .slider-container.next-slide-lg-8 .tns-inner {
      padding-right: 8%; }
    .slider-container.prev-slide-lg-8 .tns-inner {
      padding-left: 8%; }
    .slider-container.next-slide-lg-9 .tns-inner {
      padding-right: 9%; }
    .slider-container.prev-slide-lg-9 .tns-inner {
      padding-left: 9%; }
    .slider-container.next-slide-lg-10 .tns-inner {
      padding-right: 10%; }
    .slider-container.prev-slide-lg-10 .tns-inner {
      padding-left: 10%; }
    .slider-container.next-slide-lg-11 .tns-inner {
      padding-right: 11%; }
    .slider-container.prev-slide-lg-11 .tns-inner {
      padding-left: 11%; }
    .slider-container.next-slide-lg-12 .tns-inner {
      padding-right: 12%; }
    .slider-container.prev-slide-lg-12 .tns-inner {
      padding-left: 12%; }
    .slider-container.next-slide-lg-13 .tns-inner {
      padding-right: 13%; }
    .slider-container.prev-slide-lg-13 .tns-inner {
      padding-left: 13%; }
    .slider-container.next-slide-lg-14 .tns-inner {
      padding-right: 14%; }
    .slider-container.prev-slide-lg-14 .tns-inner {
      padding-left: 14%; }
    .slider-container.next-slide-lg-15 .tns-inner {
      padding-right: 15%; }
    .slider-container.prev-slide-lg-15 .tns-inner {
      padding-left: 15%; }
    .slider-container.next-slide-lg-16 .tns-inner {
      padding-right: 16%; }
    .slider-container.prev-slide-lg-16 .tns-inner {
      padding-left: 16%; }
    .slider-container.next-slide-lg-17 .tns-inner {
      padding-right: 17%; }
    .slider-container.prev-slide-lg-17 .tns-inner {
      padding-left: 17%; }
    .slider-container.next-slide-lg-18 .tns-inner {
      padding-right: 18%; }
    .slider-container.prev-slide-lg-18 .tns-inner {
      padding-left: 18%; }
    .slider-container.next-slide-lg-19 .tns-inner {
      padding-right: 19%; }
    .slider-container.prev-slide-lg-19 .tns-inner {
      padding-left: 19%; }
    .slider-container.next-slide-lg-20 .tns-inner {
      padding-right: 20%; }
    .slider-container.prev-slide-lg-20 .tns-inner {
      padding-left: 20%; }
    .slider-container.next-slide-lg-21 .tns-inner {
      padding-right: 21%; }
    .slider-container.prev-slide-lg-21 .tns-inner {
      padding-left: 21%; }
    .slider-container.next-slide-lg-22 .tns-inner {
      padding-right: 22%; }
    .slider-container.prev-slide-lg-22 .tns-inner {
      padding-left: 22%; }
    .slider-container.next-slide-lg-23 .tns-inner {
      padding-right: 23%; }
    .slider-container.prev-slide-lg-23 .tns-inner {
      padding-left: 23%; }
    .slider-container.next-slide-lg-24 .tns-inner {
      padding-right: 24%; }
    .slider-container.prev-slide-lg-24 .tns-inner {
      padding-left: 24%; }
    .slider-container.next-slide-lg-25 .tns-inner {
      padding-right: 25%; }
    .slider-container.prev-slide-lg-25 .tns-inner {
      padding-left: 25%; }
    .slider-container.next-slide-lg-26 .tns-inner {
      padding-right: 26%; }
    .slider-container.prev-slide-lg-26 .tns-inner {
      padding-left: 26%; }
    .slider-container.next-slide-lg-27 .tns-inner {
      padding-right: 27%; }
    .slider-container.prev-slide-lg-27 .tns-inner {
      padding-left: 27%; }
    .slider-container.next-slide-lg-28 .tns-inner {
      padding-right: 28%; }
    .slider-container.prev-slide-lg-28 .tns-inner {
      padding-left: 28%; }
    .slider-container.next-slide-lg-29 .tns-inner {
      padding-right: 29%; }
    .slider-container.prev-slide-lg-29 .tns-inner {
      padding-left: 29%; }
    .slider-container.next-slide-lg-30 .tns-inner {
      padding-right: 30%; }
    .slider-container.prev-slide-lg-30 .tns-inner {
      padding-left: 30%; }
    .slider-container.next-slide-lg-31 .tns-inner {
      padding-right: 31%; }
    .slider-container.prev-slide-lg-31 .tns-inner {
      padding-left: 31%; }
    .slider-container.next-slide-lg-32 .tns-inner {
      padding-right: 32%; }
    .slider-container.prev-slide-lg-32 .tns-inner {
      padding-left: 32%; }
    .slider-container.next-slide-lg-33 .tns-inner {
      padding-right: 33%; }
    .slider-container.prev-slide-lg-33 .tns-inner {
      padding-left: 33%; }
    .slider-container.next-slide-lg-34 .tns-inner {
      padding-right: 34%; }
    .slider-container.prev-slide-lg-34 .tns-inner {
      padding-left: 34%; }
    .slider-container.next-slide-lg-35 .tns-inner {
      padding-right: 35%; }
    .slider-container.prev-slide-lg-35 .tns-inner {
      padding-left: 35%; }
    .slider-container.next-slide-lg-36 .tns-inner {
      padding-right: 36%; }
    .slider-container.prev-slide-lg-36 .tns-inner {
      padding-left: 36%; }
    .slider-container.next-slide-lg-37 .tns-inner {
      padding-right: 37%; }
    .slider-container.prev-slide-lg-37 .tns-inner {
      padding-left: 37%; }
    .slider-container.next-slide-lg-38 .tns-inner {
      padding-right: 38%; }
    .slider-container.prev-slide-lg-38 .tns-inner {
      padding-left: 38%; }
    .slider-container.next-slide-lg-39 .tns-inner {
      padding-right: 39%; }
    .slider-container.prev-slide-lg-39 .tns-inner {
      padding-left: 39%; }
    .slider-container.next-slide-lg-40 .tns-inner {
      padding-right: 40%; }
    .slider-container.prev-slide-lg-40 .tns-inner {
      padding-left: 40%; } }

.tns-nav-controller {
  cursor: pointer; }
  .tns-nav-controller:not(.with-pagination) {
    position: absolute;
    padding: 10px;
    z-index: 2; }
  .tns-nav-controller.top-right {
    top: 0;
    right: 0; }
  .tns-nav-controller.top-left {
    top: 0;
    left: 0; }
  .tns-nav-controller.bottom-right {
    bottom: 0;
    right: 0; }
  .tns-nav-controller.bottom-left {
    bottom: 0;
    left: 0; }
  .tns-nav-controller.with-pagination {
    padding-right: 5px; }

.slider-thumbnails {
  padding-left: 24px;
  padding-right: 24px;
  user-select: none; }
  .slider-thumbnails .slider {
    overflow: auto;
    text-align: center;
    padding-bottom: 50px;
    margin-bottom: -50px; }
  .slider-thumbnails .slide {
    min-width: 63px;
    max-width: 63px;
    flex-basis: 63px; }
    @media (min-width: 769px) {
      .slider-thumbnails .slide {
        min-width: 63px;
        max-width: 63px;
        flex-basis: 63px; } }
    @media (min-width: 1024px) {
      .slider-thumbnails .slide {
        min-width: 63px;
        max-width: 63px;
        flex-basis: 63px; } }
    .slider-thumbnails .slide > * {
      width: 100%; }
    .slider-thumbnails .slide::after {
      content: '';
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-style: solid;
      border-width: 0;
      border-color: transparent;
      transition: border 200ms ease-out; }
    .slider-thumbnails .slide.tns-nav-active::after {
      border-width: 0 0 2px 0;
      border-color: #000; }
  .slider-thumbnails .tns-horizontal > .tns-item {
    vertical-align: middle; }
  .slider-thumbnails .slider-thumbnail-controls .prev,
  .slider-thumbnails .slider-thumbnail-controls .next {
    position: absolute;
    top: 0;
    height: 100%;
    width: 11px;
    cursor: pointer;
    user-select: none;
    background-color: #FFF;
    transition: opacity 200ms ease-out; }
    .slider-thumbnails .slider-thumbnail-controls .prev::before,
    .slider-thumbnails .slider-thumbnail-controls .next::before {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); }
    .slider-thumbnails .slider-thumbnail-controls .prev:hover,
    .slider-thumbnails .slider-thumbnail-controls .next:hover {
      background-color: #FFF; }
      .slider-thumbnails .slider-thumbnail-controls .prev:hover::before,
      .slider-thumbnails .slider-thumbnail-controls .next:hover::before {
        color: #000; }
    .slider-thumbnails .slider-thumbnail-controls .prev[aria-disabled=true],
    .slider-thumbnails .slider-thumbnail-controls .next[aria-disabled=true] {
      opacity: 0; }
  .slider-thumbnails .slider-thumbnail-controls .prev {
    left: 0; }
    .slider-thumbnails .slider-thumbnail-controls .prev:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .prev.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .prev[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .slider-thumbnail-controls .next {
    right: 0; }
    .slider-thumbnails .slider-thumbnail-controls .next:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 12px;
      color: #000;
      font-weight: normal; }
    .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title).btn-sm:before, .slider-thumbnails .slider-thumbnail-controls .btn-group-sm > .next.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class*="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) [class^="icon-"],
      .slider-thumbnails .slider-thumbnail-controls .next[class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-thumbnails .tns-horizontal.tns-no-subpixel {
    white-space: nowrap; }
    .slider-thumbnails .tns-horizontal.tns-no-subpixel > .tns-item {
      float: none;
      display: inline-block;
      margin: 0;
      margin-left: 0 !important; }
  .slider-thumbnails .video-container .video-thumbnail-button::before {
    font-size: 26px; }
  @media (max-width: 768.98px) {
    .slider-thumbnails.slider-thumbnails-vertical {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (max-width: 768.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (max-width: 768.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (max-width: 768.98px) {
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
    .slider-thumbnails.slider-thumbnails-vertical-md {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical-md .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (min-width: 769px) and (max-width: 1023.98px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (min-width: 769px) and (max-width: 1023.98px) {
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-md .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }
  @media (min-width: 1024px) {
    .slider-thumbnails.slider-thumbnails-vertical-lg {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-outer,
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important;
        overflow: auto;
        max-height: 100%; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important; }
        .slider-thumbnails.slider-thumbnails-vertical-lg .slide + .slide {
          margin-top: 1px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        left: 0;
        text-align: center;
        height: 11px;
        width: 63px; } }
    @media (min-width: 1024px) and (min-width: 769px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 63px; } }
    @media (min-width: 1024px) and (min-width: 1024px) {
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev,
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        width: 63px; } }
  @media (min-width: 1024px) {
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev::before,
        .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next::before {
          transform: rotate(90deg);
          top: auto;
          bottom: 0;
          left: calc(50% - 6px);
          width: 11px;
          height: 12px; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .prev {
        top: 0; }
      .slider-thumbnails.slider-thumbnails-vertical-lg .slider-thumbnail-controls .next {
        top: auto;
        bottom: 0; } }

.slider-container {
  padding-left: 0;
  padding-right: 0; }
  .slider-container .slider-bullets-inside .tns-nav {
    bottom: 15px; }
  .slider-container .tns-controls {
    z-index: 100; }
  .slider-container .tns-controls button {
    position: absolute;
    top: initial;
    bottom: 35px;
    transform: translateY(50%);
    border: 1px solid #FFF;
    border-radius: 50px;
    width: 40px;
    height: 40px; }
    .slider-container .tns-controls button:hover {
      border: 1px solid #E6FF00; }
  .slider-container [data-controls='prev'] {
    left: auto;
    right: 75px; }
    .slider-container [data-controls='prev']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 15px;
      color: #FFF;
      font-weight: normal; }
    .slider-container [data-controls='prev'][class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-container [data-controls='prev'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='prev'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='prev'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container [data-controls='next'] {
    right: 10px; }
    .slider-container [data-controls='next']:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 15px;
      color: #FFF;
      font-weight: normal; }
    .slider-container [data-controls='next'][class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .slider-container [data-controls='next'][class*="btn-"]:not(.title).btn-sm:before, .slider-container .btn-group-sm > .btn[data-controls='next'][class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class*="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) [class^="icon-"],
      .slider-container [data-controls='next'][class*="btn-"]:not(.title) .fa {
        display: none; }
  .slider-container.light .tns-controls button {
    border: 1px solid #000; }
    .slider-container.light .tns-controls button:hover {
      border: 1px solid #E6FF00; }

@media (min-width: 769px) {
  .custom-three-block-slider .tns-inner {
    margin: 0 auto !important;
    max-width: 850px;
    padding-bottom: 96px; } }

@media (max-width: 1023.98px) {
  .custom-three-block-slider .tns-inner .tns-item:not(.tns-slide-active) {
    opacity: .5; }
    .custom-three-block-slider .tns-inner .tns-item:not(.tns-slide-active) .image-text-block-text {
      visibility: hidden; } }

@media (max-width: 1023.98px) {
  .custom-three-block-slider .tns-inner .tns-slide-active {
    opacity: 1; }
    .custom-three-block-slider .tns-inner .tns-slide-active .image-text-block-text {
      visibility: visible; } }

@media (min-width: 769px) {
  .custom-three-block-slider .tns-inner div:nth-of-type(3).tns-slide-active {
    transform: translateY(-10%);
    transition: 1s ease-in-out; } }

@media (min-width: 769px) {
  .custom-three-block-slider .tns-inner .btn {
    transition: none; } }

.slider-transition-opacity .tns-item:not(.tns-slide-active) {
  opacity: .5; }
  .slider-transition-opacity .tns-item:not(.tns-slide-active) .image-text-block-text {
    visibility: hidden; }

.slider-transition-opacity .tns-slide-active {
  opacity: 1;
  transition: opacity 1s ease-in-out; }
  .slider-transition-opacity .tns-slide-active .image-text-block-text {
    visibility: visible; }

.slider-transition-opacity .btn {
  transition: none; }

.slider-title-bottom-left {
  position: absolute;
  bottom: 0;
  left: 12px;
  z-index: 1;
  margin-bottom: 0; }

.slider-container:not(.slider-buttons-inside):not(.slider-buttons-hidden) .tns-controls button {
  bottom: -31px; }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .slider-container:not(.slider-buttons-inside-md):not(.slider-buttons-hidden-md) .tns-controls button {
    bottom: -31px; } }

@media (min-width: 1024px) {
  .slider-container:not(.slider-buttons-inside-lg):not(.slider-buttons-hidden-lg) .tns-controls button {
    bottom: -31px; } }

@media (max-width: 768.98px) {
  .slider-container.slider-buttons-inside-no-border .tns-controls {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: -2.8em;
    width: inherit; }
    .slider-container.slider-buttons-inside-no-border .tns-controls button {
      position: unset;
      border: 0px;
      top: unset;
      bottom: unset; }
      .slider-container.slider-buttons-inside-no-border .tns-controls button:hover {
        background-color: unset;
        border: none; }
        .slider-container.slider-buttons-inside-no-border .tns-controls button:hover::before {
          color: #D6D6D6; } }

@media (min-width: 769px) and (max-width: 1023.98px) {
  .slider-container.slider-buttons-inside-no-border-md .tns-controls {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -2.8em;
    width: inherit; }
    .slider-container.slider-buttons-inside-no-border-md .tns-controls button {
      position: unset;
      border: 0px;
      top: unset;
      bottom: unset; }
      .slider-container.slider-buttons-inside-no-border-md .tns-controls button:hover {
        background-color: unset;
        border: none; }
        .slider-container.slider-buttons-inside-no-border-md .tns-controls button:hover::before {
          color: #D6D6D6; } }

@media (min-width: 1024px) {
  .slider-container.slider-buttons-inside-no-border-lg .tns-controls {
    display: flex;
    justify-content: space-around;
    position: absolute;
    top: -2.8em;
    width: inherit; }
    .slider-container.slider-buttons-inside-no-border-lg .tns-controls button {
      position: unset;
      border: 0px;
      top: unset;
      bottom: unset; }
      .slider-container.slider-buttons-inside-no-border-lg .tns-controls button:hover {
        background-color: unset;
        border: none; }
        .slider-container.slider-buttons-inside-no-border-lg .tns-controls button:hover::before {
          color: #D6D6D6; } }

.header-banner .slider-container {
  width: 100%;
  max-height: 17px; }
  .header-banner .slider-container .tns-outer {
    width: 100%; }

.slider-container.product-slider {
  padding-top: 74px; }
  @media (max-width: 1023.98px) {
    .slider-container.product-slider {
      padding-bottom: 40px; } }
  .slider-container.product-slider .tns-outer .tns-controls {
    position: absolute;
    top: 0;
    right: 0;
    padding-top: 20px; }
    .slider-container.product-slider .tns-outer .tns-controls button {
      bottom: 0;
      background: #FFF;
      border-color: #000; }
      .slider-container.product-slider .tns-outer .tns-controls button::before {
        color: #000; }
      .slider-container.product-slider .tns-outer .tns-controls button:hover {
        background: #000; }
        .slider-container.product-slider .tns-outer .tns-controls button:hover::before {
          color: #FFF; }
    .slider-container.product-slider .tns-outer .tns-controls [data-controls='next'] {
      right: 0; }
    .slider-container.product-slider .tns-outer .tns-controls [data-controls='prev'] {
      right: 48px; }
    @media (max-width: 768.98px) {
      .slider-container.product-slider .tns-outer .tns-controls {
        display: none; } }
  .slider-container.product-slider .tns-ovh {
    overflow: visible; }
  .slider-container.product-slider .tns-nav {
    display: none; }

.product-slider-headline {
  position: absolute;
  top: 10px;
  display: inline-block; }

.experience-commerce_layouts-productSlider {
  position: relative; }

.product-slider-button-1:not(.hidden-md-up) {
  position: absolute;
  top: 0;
  right: 100px;
  margin-top: 0 !important;
  z-index: 1; }
  .product-slider-button-1:not(.hidden-md-up) .btn {
    line-height: 14px; }

@media (max-width: 768.98px) {
  .product-slider-button-1 {
    margin-top: -72px !important; }
    .product-slider-button-1 .btn {
      width: 100%; } }

@media (min-width: 769px) {
  .product-slider-button-1.hidden-md-up {
    display: none; } }

.enhanced-slider-container {
  display: flex;
  flex-wrap: wrap;
  max-width: 1920px;
  margin: 0 auto;
  padding: 48px 0;
  position: relative; }
  @media (min-width: 769px) and (max-width: 1439.98px) {
    .enhanced-slider-container {
      padding: 24px 0 0 0; } }
  .enhanced-slider-container .enhanced-slider-title {
    align-items: center;
    display: flex;
    order: 1;
    padding: 0 24px;
    flex-grow: 1;
    flex-basis: min-content; }
    @media (min-width: 1440px) {
      .enhanced-slider-container .enhanced-slider-title {
        padding: 0 0 0 48px; } }
    .enhanced-slider-container .enhanced-slider-title > * {
      margin: 0;
      padding: 0;
      line-height: 1.2; }
  .enhanced-slider-container .enhanced-slider-button {
    order: 3;
    padding-left: 24px;
    margin-right: auto; }
    @media (min-width: 769px) {
      .enhanced-slider-container .enhanced-slider-button {
        margin-left: auto;
        margin-right: unset;
        order: 2;
        padding: 0 12px; } }
    .enhanced-slider-container .enhanced-slider-button a {
      line-height: 1.12; }
    .enhanced-slider-container .enhanced-slider-button .btn-secondary {
      color: #0C0A08; }
  .enhanced-slider-container .enhanced-slider-navigation {
    align-items: center;
    display: flex;
    order: 4;
    padding: 0 24px;
    font-size: 0; }
    @media (min-width: 769px) {
      .enhanced-slider-container .enhanced-slider-navigation {
        order: 3;
        padding: 0 24px 0 0; }
        .enhanced-slider-container .enhanced-slider-navigation:has(> .d-md-none) {
          margin-left: -12px; } }
    @media (min-width: 1024px) {
      .enhanced-slider-container .enhanced-slider-navigation:has(> .d-lg-none) {
        margin-left: -12px; } }
    @media (min-width: 1440px) {
      .enhanced-slider-container .enhanced-slider-navigation {
        padding: 0 48px 0 0; } }
  .enhanced-slider-container .enhanced-slider-slides-container {
    order: 2;
    flex-basis: 100%;
    padding: 24px 0; }
    @media (min-width: 769px) {
      .enhanced-slider-container .enhanced-slider-slides-container {
        order: 4; } }
    @media (min-width: 1440px) {
      .enhanced-slider-container .enhanced-slider-slides-container {
        padding: 48px 0 0; } }
    @media (min-width: 1024px) {
      .enhanced-slider-container .enhanced-slider-slides-container.large-desktop-slides .experience-slides > * {
        flex: 0 0 calc((100% - 12px) / 2); } }
    @media (min-width: 769px) and (max-width: 1023.98px) {
      .enhanced-slider-container .enhanced-slider-slides-container.large-tablet-slides .experience-slides > * {
        flex: 0 0 calc((100% - 12px) / 2); } }
  .enhanced-slider-container .btn-slider-left {
    margin-right: 12px; }
  .enhanced-slider-container .experience-slides {
    display: flex;
    gap: 12px;
    overflow-x: auto;
    padding: 0 24px 24px;
    scroll-padding: 24px;
    scroll-snap-type: x mandatory;
    max-width: 100vw; }
    @media (min-width: 1440px) {
      .enhanced-slider-container .experience-slides {
        padding: 0 48px 24px;
        scroll-padding: 48px; } }
  .enhanced-slider-container .experience-slides > * {
    display: flex;
    flex: 1 0 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
    justify-content: center; }
    @media (min-width: 544px) and (max-width: 768.98px) {
      .enhanced-slider-container .experience-slides > * {
        flex: 0 0 calc((100% - 12px) / 2); } }
    @media (min-width: 769px) and (max-width: 1023.98px) {
      .enhanced-slider-container .experience-slides > * {
        flex: 0 0 calc((100% - (12px * 2)) / 3); } }
    @media (min-width: 1024px) {
      .enhanced-slider-container .experience-slides > * {
        flex: 0 0 calc((100% - (12px * 3)) / 4); } }
    .enhanced-slider-container .experience-slides > * > * {
      width: 100%; }
    .enhanced-slider-container .experience-slides > * .product-tile {
      padding-bottom: 0; }
  .enhanced-slider-container .slider-moving {
    user-select: none;
    cursor: grab; }
    .enhanced-slider-container .slider-moving a, .enhanced-slider-container .slider-moving img {
      pointer-events: none; }
  .enhanced-slider-container .no-scroll-snap {
    scroll-snap-type: none; }
  .enhanced-slider-container .product-tile .pdp-link {
    flex-grow: 0; }

.social-links {
  list-style: none;
  display: inline-flex;
  flex-flow: wrap;
  padding-left: 0px;
  margin-bottom: 0px; }
  .social-links li {
    margin: 10px 0; }
  .social-links a {
    font-size: 14px;
    line-height: 14px;
    text-decoration: none; }
    .social-links a:hover {
      text-decoration: none; }
  .social-links .fa-facebook-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-facebook-square[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-links .fa-facebook-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-facebook-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-facebook-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-twitter-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-twitter-square[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-links .fa-twitter-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-twitter-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-twitter-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-youtube-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-youtube-square[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-links .fa-youtube-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-youtube-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-youtube-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .fa-instagram-square:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-links .fa-instagram-square[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-links .fa-instagram-square[class*="btn-"]:not(.title).btn-sm:before, .social-links .btn-group-sm > .fa-instagram-square.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class*="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) [class^="icon-"],
    .social-links .fa-instagram-square[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-links .share-link {
    font-size: initial; }

.social-icons {
  list-style-type: none;
  padding: 0;
  margin: 0; }
  .social-icons li {
    padding: 0; }
  .social-icons .fa-facebook-official:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-facebook-official[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-icons .fa-facebook-official[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-facebook-official.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-facebook-official[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .fa-twitter:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .social-icons .fa-twitter[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .social-icons .fa-twitter[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .social-icons .fa-twitter[class*="btn-"]:not(.title).btn-sm:before, .social-icons .btn-group-sm > .fa-twitter.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class*="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) [class^="icon-"],
    .social-icons .fa-twitter[class*="btn-"]:not(.title) .fa {
      display: none; }
  .social-icons .share-link {
    text-decoration: none;
    display: block; }

.social-links {
  padding-top: 25px; }
  .social-links li {
    margin: 0; }
    .social-links li a {
      line-height: 41px; }
      .social-links li a::before {
        padding-right: 19px; }
      .social-links li a:hover {
        color: #FFF; }
      .social-links li a::after {
        content: "";
        width: 10px;
        display: inline-block;
        height: 1px;
        background-color: #B9BFA7;
        margin-left: 10px;
        vertical-align: middle; }
      @media (min-width: 1024px) {
        .social-links li a {
          font-size: 18px;
          line-height: 40px; } }

/*
    Copy SFRA Veil Styling
    Prevents the need to include autobahn/_spinner.scss
    Would otherwise include unused and unecessary styles
*/
.veil {
  position: fixed;
  z-index: 1000;
  text-align: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .veil .underlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-color: #000; }

.asg-preloader {
  width: 150px;
  height: 150px;
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -75px;
  margin-left: -75px; }

.fox-preloader--frame-0,
.fox-preloader--frame-1 {
  align-self: center;
  background-position: center;
  background-repeat: no-repeat;
  display: inline-flex;
  height: inherit;
  width: inherit; }

.fox-preloader--frame-0 {
  background-image: url("../../images/preloader/fox-preloader-0.svg"); }

.fox-preloader--frame-1 {
  background-image: url("../../images/preloader/fox-preloader-1.svg");
  left: 0;
  position: absolute;
  animation-name: fox-preloader-animation;
  animation-timing-function: ease-out;
  animation-iteration-count: infinite;
  animation-duration: 100ms;
  animation-direction: alternate; }

@keyframes fox-preloader-animation {
  0% {
    opacity: 1; }
  25% {
    opacity: 1; }
  75% {
    opacity: 0; }
  100% {
    opacity: 0; } }

.nav-tabs {
  border-bottom: none; }
  .nav-tabs .nav-link {
    font: 20px "archia-medium", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0;
    padding: 15px 12px 11px 12px;
    border-radius: 0 0 0 0;
    background-color: transparent;
    color: #000;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none; }
    .nav-tabs .nav-link:hover {
      text-decoration: none;
      background-color: transparent;
      color: #000;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #000;
      border-left: none; }
    .nav-tabs .nav-link.active {
      text-decoration: none;
      background-color: transparent;
      color: #000;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #000;
      border-left: none; }
  .nav-tabs .nav-item:only-child .nav-link {
    border: none;
    background: transparent; }

.nav-tabs .nav-item {
  flex: none; }
  .nav-tabs .nav-item:not(:first-child):not(:last-child) {
    margin: 0 20px; }
  .nav-tabs .nav-item:first-child {
    margin: 0 0 0 20px; }
  .nav-tabs .nav-item:last-child {
    margin: 0; }

.nav-tabs .nav-link {
  line-height: 20px;
  text-transform: uppercase; }
  .nav-tabs .nav-link:not(.active):not(:hover) {
    opacity: 0.5; }

.info-icon,
.tooltip-icon {
  border: none;
  padding: 0;
  position: relative;
  top: -2px;
  background: inherit;
  display: inline-block;
  cursor: pointer;
  font: inherit; }
  .info-icon:before,
  .tooltip-icon:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 120%;
    color: #0C0A08;
    font-weight: normal; }
  .info-icon[class*="btn-"]:not(.title),
  .tooltip-icon[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .info-icon[class*="btn-"]:not(.title):before,
    .tooltip-icon[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #0C0A08;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .info-icon[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title),
    .tooltip-icon[class*="btn-"]:not(.title).btn-sm,
    .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .info-icon[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .info-icon.btn[class*="btn-"]:not(.title):before,
      .tooltip-icon[class*="btn-"]:not(.title).btn-sm:before,
      .btn-group-sm > .tooltip-icon.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #0C0A08;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .info-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .info-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .info-icon[class*="btn-"]:not(.title) .fa,
    .tooltip-icon[class*="btn-"]:not(.title) [class*="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) [class^="icon-"],
    .tooltip-icon[class*="btn-"]:not(.title) .fa {
      display: none; }
  .info-icon .icon,
  .tooltip-icon .icon {
    display: none; }
  .info-icon:hover:before, .info-icon[aria-describedby*="tooltip"]:before,
  .tooltip-icon:hover:before,
  .tooltip-icon[aria-describedby*="tooltip"]:before {
    color: #2B2B2A; }

.custom-tooltip {
  cursor: pointer;
  position: relative;
  padding: 0.4rem 0; }
  .custom-tooltip.repositioned > ::after {
    display: none; }
  .custom-tooltip.repositioned::after {
    opacity: 0;
    visibility: hidden;
    border: solid transparent;
    border-top-color: #FFF;
    border-width: 0.4rem 0.4rem 0;
    content: '';
    left: 50%;
    position: absolute;
    top: 0;
    transform: translateX(-50%);
    transition: visibility 200ms, opacity ease 200ms;
    z-index: 1070; }
  .custom-tooltip .custom-tooltip-content {
    background-color: #FFF;
    border-radius: 0;
    bottom: 100%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    cursor: auto;
    font-size: 11px;
    left: 50%;
    opacity: 0;
    padding: 10px 15px;
    position: absolute;
    transform: translateX(-50%);
    transition: visibility 200ms, opacity ease 200ms;
    visibility: hidden;
    z-index: 1070; }
    .custom-tooltip .custom-tooltip-content[data-width] {
      width: max-content; }
    .custom-tooltip .custom-tooltip-content[data-width='small'] {
      max-width: 100px; }
    .custom-tooltip .custom-tooltip-content[data-width='medium'] {
      max-width: 200px; }
    .custom-tooltip .custom-tooltip-content[data-width='large'] {
      max-width: 400px; }
    .custom-tooltip .custom-tooltip-content[data-width='xlarge'] {
      max-width: 600px; }
    .custom-tooltip .custom-tooltip-content::after {
      border: solid transparent;
      border-top-color: #FFF;
      border-width: 0.4rem 0.4rem 0;
      content: '';
      left: 50%;
      position: absolute;
      top: 100%;
      transform: translateX(-50%); }
  .custom-tooltip.visible .custom-tooltip-content {
    opacity: 1;
    visibility: visible; }
  .custom-tooltip.visible::after {
    opacity: 1;
    visibility: visible; }

.tooltip.bs-tooltip-right .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="right"] .arrow:before {
  border-right-color: #FFF; }

.tooltip.bs-tooltip-left .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="left"] .arrow:before {
  border-left-color: #FFF; }

.tooltip.bs-tooltip-top .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before {
  border-top-color: #FFF; }

.tooltip.bs-tooltip-bottom .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="bottom"] .arrow:before {
  border-bottom-color: #FFF; }

.tooltip .tooltip-inner {
  border-radius: 0;
  padding: 10px 15px;
  text-align: center;
  max-width: 200px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1); }
  .tooltip .tooltip-inner:not([class*='background--']) {
    background: #FFF; }
  .tooltip .tooltip-inner:not([class*='text--']) {
    color: #000; }
  .tooltip .tooltip-inner:not([class*='font-size--']) {
    font-size: 11px; }

.tooltip.show {
  opacity: 1; }

.video-container {
  position: relative;
  max-width: 100%;
  min-width: 100%;
  max-height: 100%; }
  .video-container.aspect-ratio-16-9::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-16-9 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(9 / 16 * 100%); }
  .video-container.aspect-ratio-9-16::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-9-16 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(16 / 9 * 100%); }
  .video-container.aspect-ratio-4-3::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-4-3 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(3 / 4 * 100%); }
  .video-container.aspect-ratio-3-4::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-3-4 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(4 / 3 * 100%); }
  .video-container.aspect-ratio-1-1::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::after {
    display: block;
    content: "";
    clear: both; }
  .video-container.aspect-ratio-1-1 .video-thumbnail:not(.btn)::before {
    content: "";
    display: block;
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: calc(1 / 1 * 100%); }
  .video-container .video-player,
  .video-container .video-player iframe,
  .video-container video.vjs-tech {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .video-container .video-thumbnail:not(.btn) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    opacity: 1;
    transition: opacity 200ms;
    z-index: 1; }
    .video-container .video-thumbnail:not(.btn).faded {
      opacity: 0;
      transition: opacity 200ms; }
      .video-container .video-thumbnail:not(.btn).faded .video-thumbnail-image {
        opacity: 0;
        pointer-events: none; }
      .video-container .video-thumbnail:not(.btn).faded:hover {
        opacity: 1; }
  .video-container.has-controls .video-thumbnail.faded {
    pointer-events: none; }
    .video-container.has-controls .video-thumbnail.faded .video-thumbnail-button {
      opacity: 0; }
  .video-container.has-controls .video-js .vjs-big-play-button,
  .video-container.has-controls .video-js .vjs-poster {
    display: none; }
  .video-container .video-thumbnail-button {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%); }
    .video-container .video-thumbnail-button:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 50px;
      color: #FFF;
      font-weight: normal; }
    .video-container .video-thumbnail-button[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #FFF;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .video-container .video-thumbnail-button[class*="btn-"]:not(.title).btn-sm:before, .video-container .btn-group-sm > .video-thumbnail-button.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #FFF;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class*="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) [class^="icon-"],
      .video-container .video-thumbnail-button[class*="btn-"]:not(.title) .fa {
        display: none; }
  .video-container .video-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center; }
    .video-container .video-thumbnail-image.background-image-tablet, .video-container .video-thumbnail-image.background-image-desktop {
      display: none; }
    @media (min-width: 769px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-desktop {
        display: none; }
      .video-container .video-thumbnail-image.background-image-tablet {
        display: block; } }
    @media (min-width: 1024px) {
      .video-container .video-thumbnail-image.background-image-mobile, .video-container .video-thumbnail-image.background-image-tablet {
        display: none; }
      .video-container .video-thumbnail-image.background-image-desktop {
        display: block; } }

.vimeo-video-modal,
.youtube-video-modal {
  display: none;
  background-color: rgba(0, 0, 0, 0.7); }
  .vimeo-video-modal .modal-dialog,
  .youtube-video-modal .modal-dialog {
    max-width: none;
    margin: 8px; }
    @media (min-width: 1024px) {
      .vimeo-video-modal .modal-dialog,
      .youtube-video-modal .modal-dialog {
        max-width: 900px;
        margin: 24px auto; } }
    @media (min-width: 1440px) {
      .vimeo-video-modal .modal-dialog,
      .youtube-video-modal .modal-dialog {
        max-width: 1140px; } }
  .vimeo-video-modal .modal-body,
  .youtube-video-modal .modal-body {
    padding: 0; }
  .vimeo-video-modal .video-player,
  .youtube-video-modal .video-player {
    width: 100%; }

.video-modal-content {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 0; }

.add-to-wishlist-messages {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.wishlist {
  white-space: nowrap;
  text-decoration: none; }
  .wishlist:hover {
    text-decoration: none; }
  .wishlist:not(.move) {
    position: absolute;
    top: 5px;
    right: 5px;
    border: none;
    z-index: 50; }
    @media (min-width: 544px) {
      .wishlist:not(.move) {
        top: 10px;
        right: 10px; } }
  .wishlist .wishlist-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background-color: rgba(255, 255, 255, 0.7);
    box-shadow: none;
    border-radius: 50%; }
    .wishlist .wishlist-icon.before::before {
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
      padding-top: 1px;
      padding-left: 1px; }

.wishlistItemCards .select-attributes-btn {
  white-space: nowrap; }

.wishlistItemCards .line-item-availability .line-item-attributes {
  white-space: normal; }

.btn-update-wishlist-product {
  width: 100%; }

.wishlist-remove-btn {
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 20px 20px 20px;
  cursor: pointer;
  z-index: 1;
  background-color: #FFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  margin: 5px 0px 0px 20px;
  left: 0; }
  .wishlist-remove-btn:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .wishlist-remove-btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .wishlist-remove-btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .wishlist-remove-btn[class*="btn-"]:not(.title).btn-sm:before, .btn-group-sm > .wishlist-remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .wishlist-remove-btn[class*="btn-"]:not(.title) .fa {
      display: none; }
  @media (min-width: 1440px) {
    .wishlist-remove-btn {
      margin-left: 28px; } }

.consent-drawer {
  position: fixed;
  display: block;
  align-items: center;
  bottom: 0;
  width: 100%;
  min-height: 85px;
  z-index: 2147483601;
  background-color: #F8F8F8;
  border-top: 1px solid #40403F; }
  @media (min-width: 769px) {
    .consent-drawer {
      display: flex; } }
  .consent-drawer p {
    margin: 4px;
    font-size: 12px;
    line-height: 12px; }
  .consent-drawer .consent-text-wrapper {
    display: block;
    width: 100%;
    padding: 10px 0 10px 14px; }
    @media (min-width: 769px) {
      .consent-drawer .consent-text-wrapper {
        width: auto;
        display: inline-block;
        padding: 12px; } }
  .consent-drawer .consent-button-wrapper {
    display: block;
    width: 100%;
    text-align: center;
    padding-bottom: 10px; }
    .consent-drawer .consent-button-wrapper button {
      width: 50%; }
    @media (min-width: 769px) {
      .consent-drawer .consent-button-wrapper {
        display: inline-block;
        width: auto;
        padding: 0 12px;
        margin-left: auto; }
        .consent-drawer .consent-button-wrapper button {
          width: auto; } }

.geo-redirect-container {
  font-weight: 600;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto; }
  .geo-redirect-container .modal-outline {
    padding: 2px;
    background-color: #FFF; }
  .geo-redirect-container .modal-content {
    border: solid 2px; }
    .geo-redirect-container .modal-content .modal-header {
      display: block;
      text-align: right; }
    .geo-redirect-container .modal-content .modal-title {
      text-decoration: underline;
      text-underline-offset: 10px; }
    .geo-redirect-container .modal-content .geopopin-flag-icon {
      width: 120px;
      height: 90px;
      border: 1px solid #D6D6D6;
      background-size: cover; }
    .geo-redirect-container .modal-content .sites-container .geopopin-flag:not(:last-child) {
      margin-right: 24px; }
      @media (max-width: 300px) {
        .geo-redirect-container .modal-content .sites-container .geopopin-flag:not(:last-child) {
          margin-right: 0px; } }
    .geo-redirect-container .modal-content .modal-body {
      text-align: center; }
      .geo-redirect-container .modal-content .modal-body a {
        text-decoration: none; }
        .geo-redirect-container .modal-content .modal-body a .flag-icon {
          font-size: 5em;
          padding-bottom: 12px; }
      .geo-redirect-container .modal-content .modal-body .link-close {
        border-bottom: solid 1px #000;
        padding-bottom: 2px;
        cursor: pointer; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9bo02z");
  src: url("../fonts/icomoon.eot?9bo02z#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9bo02z") format("truetype"), url("../fonts/icomoon.woff?9bo02z") format("woff"), url("../fonts/icomoon.svg?9bo02z#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-nav:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-box:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-cog:before {
  content: ""; }

.icon-drop:before {
  content: ""; }

.icon-key:before {
  content: ""; }

.icon-location-pin:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-magnifing-glass:before {
  content: ""; }

.icon-ruler:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-truck:before {
  content: ""; }

.icon-notification:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-check-circle:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-tiktok:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-play:before {
  content: ""; }

/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BUTTONS
*/
/*
* FORMs
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* TABS - PDP
*/
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
/*
* PRELOADER
*/
.gc-order-container .card-body-header {
  margin-top: 24px;
  margin-bottom: 24px; }

.gc-order-container input::-webkit-outer-spin-button,
.gc-order-container input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

.gc-order-container input[type=number] {
  -moz-appearance: textfield; }

.gc-order-container .gc-current-balance {
  margin-top: 12px; }

.gc-order-container .gift-card-image {
  width: 100%;
  padding-bottom: 24px; }

.gc-order-container .image-slider-container {
  width: 100%;
  overflow-x: auto;
  padding-top: 24px; }

.gc-order-container .image-slider-container::-webkit-scrollbar {
  display: none; }

.gc-order-container .image-slider {
  display: flex;
  gap: 6px; }

.gc-order-container .image-slider img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto; }

.gc-order-container .gc-image-slide {
  background: #F8F8F8;
  flex: 0 0 calc(20% - 6px);
  padding: 16px 9px 16px 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  box-sizing: border-box; }
  @media (min-width: 769px) {
    .gc-order-container .gc-image-slide {
      padding: 28px 16px 28px 16px; } }

.gc-order-container .gc-image-slide.selected {
  border-color: #000; }

.gc-order-container .select-card {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; }

.gc-order-container .gc-title {
  padding-top: 24px;
  padding-bottom: 24px; }

.gc-order-container .giftcard-upper {
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6;
  margin: 24px 0;
  padding: 24px 0; }
  .gc-order-container .giftcard-upper ul {
    margin-bottom: 0px; }

.gc-order-container .gc-button-container {
  margin-bottom: 24px; }
  .gc-order-container .gc-button-container .general-error {
    margin-top: 12px;
    color: #BF242B; }

.countdown-timer {
  background-color: #0C0A08;
  color: #FFF; }
  .countdown-timer a {
    color: #FFF; }
  @media (max-width: 1023.98px) {
    .countdown-timer .countdown-callout,
    .countdown-timer .countdown-divider,
    .countdown-timer .countdown {
      flex-basis: 100%; } }
  .countdown-timer .countdown-container {
    display: flex;
    flex-wrap: wrap;
    opacity: 0; }
    @media (max-width: 1023.98px) {
      .countdown-timer .countdown-container {
        text-align: center; } }
  .countdown-timer .countdown-callout,
  .countdown-timer .countdown {
    padding-top: 12px;
    padding-bottom: 12px; }
  .countdown-timer .countdown-callout {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    align-content: center; }
    @media (min-width: 1024px) {
      .countdown-timer .countdown-callout {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .countdown-timer .countdown {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    justify-content: center;
    align-items: center; }
  .countdown-timer .time-shown {
    opacity: 1; }
  .countdown-timer [class^="countdown-value-"] {
    letter-spacing: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    letter-spacing: 0;
    font-weight: bold; }
    @media (min-width: 1024px) {
      .countdown-timer [class^="countdown-value-"] {
        font-size: 16px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .countdown-timer [class^="countdown-label-"] {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0; }
    @media (min-width: 1024px) {
      .countdown-timer [class^="countdown-label-"] {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  @media (max-width: 1023.98px) {
    .countdown-timer .countdown-divider {
      border-top: 1px solid #40403F; } }
  @media (min-width: 1024px) {
    .countdown-timer .countdown-divider {
      border-left: 1px solid #40403F;
      margin: 0 24px; } }

.login-page {
  padding-top: 12px; }
  .login-page .login-nav-tabs {
    background: #FFF;
    padding: 0;
    border-bottom: none;
    margin: -1px -1px 20px -1px; }
    .login-page .login-nav-tabs .nav-link {
      padding-top: 15px;
      padding-bottom: 11px; }
      .login-page .login-nav-tabs .nav-link:hover {
        color: #000; }
      .login-page .login-nav-tabs .nav-link.active {
        background: #FFF;
        color: #000;
        border-color: #D6D6D6;
        border-width: 1px; }
      .login-page .login-nav-tabs .nav-link:not(.active) {
        border-bottom: 1px solid #D6D6D6;
        color: #000;
        margin-bottom: 1px; }
  .login-page .login-form-nav .login-oauth {
    margin-top: 24px; }
    .login-page .login-form-nav .login-oauth .oauth-facebook:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: inherit;
      color: inherit;
      font-weight: normal; }
    .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm, .login-page .login-form-nav .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title).btn-sm:before, .login-page .login-form-nav .login-oauth .btn-group-sm > .oauth-facebook.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class*="icon-"],
      .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title) [class^="icon-"],
      .login-page .login-form-nav .login-oauth .oauth-facebook[class*="btn-"]:not(.title) .fa {
        display: none; }
    .login-page .login-form-nav .login-oauth .oauth-facebook .fa-facebook-official {
      display: none; }
  .login-page .login-form-nav .remember-me {
    margin-top: 20px; }
    .login-page .login-form-nav .remember-me .custom-control-label {
      margin-bottom: 0; }
  .login-page .login-form-nav .forgot-password {
    margin-top: 20px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0;
    font-size: 12px;
    display: inline-block;
    margin: 0 0 0 1px;
    padding: 9px 12px 0 12px;
    position: absolute;
    opacity: 0;
    transition: opacity 200ms ease-out; }
    .login-page .login-form-nav .forgot-password:has(~ .form-control:not(:placeholder-shown)), .login-page .login-form-nav .forgot-password:has(~ .form-control:autofill:placeholder-shown) {
      opacity: 1; }
    .login-page .login-form-nav .forgot-password:has(~ textarea.form-control) {
      padding-top: 12px; }
  .login-page .request-password-body {
    margin-bottom: 12px; }
  .login-page .privacy-policy {
    margin-top: 12px; }
  .login-page .login-nav-tabs .nav-link,
  .login-page .card-header.track-order-header {
    font-family: "archia-medium", sans-serif;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
    text-transform: none; }

.account-page {
  padding-top: 12px;
  padding-bottom: 12px; }
  @media (min-width: 1024px) {
    .account-page .col-12 > .card:only-child .card-body > form {
      width: 750px;
      margin-left: auto;
      margin-right: auto; } }
  .account-page .card:not(:last-of-type) {
    margin-bottom: 0; }
    .account-page .card:not(:last-of-type) .card-body,
    .account-page .card:not(:last-of-type) .card-footer {
      border-bottom: 0; }
  .account-page .card-header + .card-footer a {
    color: inherit; }
  .account-page .dashboard-info,
  .account-page dl dt,
  .account-page .dashboard-cards-block-title,
  .account-page .dashboard-info {
    color: #000; }
  .account-page .dashboard-cards-block-title {
    margin-bottom: 12px; }
  .account-page dl {
    margin-bottom: 24px; }
    .account-page dl dd {
      color: #595959;
      margin-bottom: 0;
      margin-top: 1px; }
    .account-page dl:last-child {
      margin-bottom: 0; }
  .account-page .card-body.card-info-group p,
  .account-page .card-body .card-info-group p {
    color: #595959; }
  .account-page .card-body p {
    color: #000; }
  .account-page .address-card .card-body div {
    color: #595959; }
  .account-page .card-info-group p {
    margin-bottom: 5px; }
    .account-page .card-info-group p:last-child {
      margin-bottom: 0; }
  .account-page .card-info-group .wishlist-row {
    position: relative; }
    .account-page .card-info-group .wishlist-row:not(:last-child) {
      border-bottom: 1px solid #D6D6D6;
      margin-bottom: 20px;
      padding-bottom: 20px; }
  .account-page .tracking-consent {
    color: #0C0A08;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
    display: inline-block; }
    .account-page .tracking-consent:hover {
      color: #2B2B2A;
      text-decoration: underline; }
    .dark-theme .account-page .tracking-consent {
      color: #FFF; }
      .dark-theme .account-page .tracking-consent:hover {
        color: #FFF; }
  .account-page .change-password-form .row,
  .account-page [name=change-password-form] .row,
  .account-page .address-form .row,
  .account-page .payment-form .row {
    margin-top: 24px; }
  .account-page .col-12 > .address-card:only-child .card-body,
  .account-page .col-12 > .payment-card:only-child .card-body {
    position: relative; }
    @media (min-width: 1024px) {
      .account-page .col-12 > .address-card:only-child .card-body,
      .account-page .col-12 > .payment-card:only-child .card-body {
        width: 100%; } }
  .account-page .card-make-default-link,
  .account-page .make-default-payment {
    margin-top: 12px; }
  .account-page .remove-btn {
    font-family: "Roboto", sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px; }
    .account-page .remove-btn:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .account-page .remove-btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .account-page .remove-btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .account-page .remove-btn[class*="btn-"]:not(.title).btn-sm, .account-page .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .account-page .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .account-page .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .account-page .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
      .account-page .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
      .account-page .remove-btn[class*="btn-"]:not(.title) .fa {
        display: none; }
  .account-page .product-summary-block .shipment-block span.ship-to-name,
  .account-page .product-summary-block .shipment-block span.ship-to-address1,
  .account-page .product-summary-block .shipment-block span.ship-to-address2,
  .account-page .product-summary-block .shipment-block span.ship-to-phone,
  .account-page .product-summary-block .shipment-block span.ship-to-city-st-zip {
    display: block; }
  .account-page .product-summary-block .shipment-block h3:not(:first-child) {
    margin-top: 12px; }

.order-card .order-image-col {
  min-width: 120px;
  max-width: 120px; }

.order-card .order-details-col {
  max-width: calc(100% - 120px);
  padding-left: 0; }
  @media (min-width: 544px) {
    .order-card .order-details-col {
      padding-left: 12px; } }

.account-page .card-body {
  border-bottom: none; }
  .account-page .card-body p.not-returnable-text {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #BF242B; }

.account-page .card-footer {
  border-top: 1px solid #D6D6D6; }

.account-page .order-card .card-footer {
  border: none; }

.account-page .confirm-details .card-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.account-page .card-footer {
  padding: 0 0 0 0;
  text-align: left !important;
  border: 0; }

.account-page .order-history-buttons {
  padding: 20px; }
  .account-page .order-history-buttons .btn-primary {
    width: 100%;
    margin-bottom: 6px; }

.account-page .view-order-btns {
  margin-top: 20px; }
  .account-page .view-order-btns .btn {
    width: 100%;
    color: #fff;
    margin-bottom: 5px; }

.account-page .oh-header {
  padding-left: 0px; }

.account-page .order-list-container {
  padding-left: 7px; }

.account-page .loyalty-opt-in-banner-wrapper {
  position: relative;
  z-index: 1; }

.account-page .loyalty-opt-in-banner {
  background: #ECEEE4;
  position: relative;
  border-radius: calc(8px - 3px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  margin: 24px 0;
  padding: 24px;
  gap: 24px; }
  .account-page .loyalty-opt-in-banner::after {
    content: '';
    position: absolute;
    top: calc(-1 * 3px);
    left: calc(-1 * 3px);
    height: calc(100% + (3px * 2));
    width: calc(100% + (3px * 2));
    background: linear-gradient(60deg, #0C0A08, #99FF00, #0C0A08);
    border-radius: 8px;
    z-index: -1;
    animation: animatedgradient 3s linear infinite;
    background-size: 300% 300%; }

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0% 50%; } }
  @media (min-width: 769px) {
    .account-page .loyalty-opt-in-banner {
      flex-direction: row;
      align-items: center;
      margin-bottom: 48px; } }
  .account-page .loyalty-opt-in-banner p {
    margin-bottom: 0;
    font-weight: 500; }
  .account-page .loyalty-opt-in-banner .podium-club-logo,
  .account-page .loyalty-opt-in-banner button {
    flex-shrink: 0; }

@media (max-width: 768.98px) {
  .login-page h2 {
    font-size: 20px; } }

@media (min-width: 769px) {
  .login-page .track-order-header-text {
    line-height: 1.6;
    font-size: 11px; } }

@media (min-width: 1024px) {
  .login-page .track-order {
    padding-top: 18px; }
  .login-page .card .track-order-header {
    padding-bottom: 0; }
  .login-page .track-order-header-text {
    margin-bottom: -1px;
    line-height: 1.4;
    font-size: 12px; }
  .login-page .login-form .btn-primary {
    margin-top: 76px; } }

.container.returns h1 {
  padding: 20px 0 0 20px; }

.container.returns h3 {
  margin-bottom: 24px; }

.container.returns .product-info .item-image img.product-image {
  width: 90%; }

@media (min-width: 1024px) {
  .container.returns .product-card-footer {
    padding-left: 30%; } }

.container.returns .product-card-footer .col-lg-3 {
  padding: 0; }

.container.returns .line-item {
  padding: 30px 0;
  border-top: 1px solid #eee; }
  .container.returns .line-item:first-child {
    border-top: none; }

.container.returns .js-button-returns-next, .container.returns .js-button-returns-back, .container.returns .return-label-download, .container.returns .order-history-back {
  width: 250px; }
  @media (max-width: 768.98px) {
    .container.returns .js-button-returns-next, .container.returns .js-button-returns-back, .container.returns .return-label-download, .container.returns .order-history-back {
      width: 100%; } }

@media (max-width: 768.98px) {
  .container.returns .return-progress-next {
    width: 100%; } }

@media (min-width: 769px) {
  .container.returns .below-cta {
    padding-right: 16px; } }

.container.returns .returns-body p {
  font-size: 14px; }

.container.returns select.quantity {
  border-radius: 0; }

.container.returns .js-select-return-reasons {
  padding-top: 20px; }

@media (min-width: 544px) {
  .container.returns .return-labels-header .return-labels-headline {
    justify-content: flex-start;
    width: 50%; }
  .container.returns .return-labels-header .return-labels-btn {
    justify-content: flex-end;
    width: 50%; } }

@media (max-width: 543.98px) {
  .container.returns .return-labels-header .return-labels-headline {
    justify-content: center;
    width: 100%; }
  .container.returns .return-labels-header .return-labels-btn {
    justify-content: center;
    width: 100%; } }

.container.returns .return-labels .line-item-name {
  position: relative;
  left: 9.8%;
  top: 20px; }
  @media (max-width: 768.98px) {
    .container.returns .return-labels .line-item-name {
      left: 33.8%; } }

.container.returns .item-data .col-4 {
  padding-right: 0; }
  @media (min-width: 769px) {
    .container.returns .item-data .col-4 {
      flex: 0 0 16.66667%;
      max-width: 16.66667%; } }
  .container.returns .item-data .col-4 img {
    width: 100%; }

.container.returns .item-data .col-8 {
  padding-top: 22px; }
  .container.returns .item-data .col-8 p {
    margin-bottom: 6px; }

.container.returns .returns-download {
  visibility: hidden; }
  @media (max-width: 768.98px) {
    .container.returns .returns-download {
      display: none; } }

@media (min-width: 769px) {
  .container.returns .action-links {
    padding-top: 80px; } }

@media (max-width: 768.98px) {
  .container.returns .action-links {
    width: 100%; } }

.container.returns .card .border, .container.returns .card .container {
  padding: 20px !important; }

#passwordResetSentModal .subtitle, #registerSuccessModal .success-message {
  color: #00865A; }

.login-partial,
.register-partial,
#loginModal,
#countrySelectorModal,
#registerModal,
#registerSuccessModal,
#forgotPasswordModal,
#passwordResetSentModal {
  background: rgba(0, 0, 0, 0.5);
  padding: 0 !important; }
  .login-partial.show .modal-dialog,
  .register-partial.show .modal-dialog,
  #loginModal.show .modal-dialog,
  #countrySelectorModal.show .modal-dialog,
  #registerModal.show .modal-dialog,
  #registerSuccessModal.show .modal-dialog,
  #forgotPasswordModal.show .modal-dialog,
  #passwordResetSentModal.show .modal-dialog {
    right: 0; }
  .login-partial .modal-dialog,
  .register-partial .modal-dialog,
  #loginModal .modal-dialog,
  #countrySelectorModal .modal-dialog,
  #registerModal .modal-dialog,
  #registerSuccessModal .modal-dialog,
  #forgotPasswordModal .modal-dialog,
  #passwordResetSentModal .modal-dialog {
    transition: right 0.3s linear;
    right: -564px;
    margin: 0;
    margin-left: auto;
    max-width: 100dvw; }
    @media (min-width: 1024px) {
      .login-partial .modal-dialog,
      .register-partial .modal-dialog,
      #loginModal .modal-dialog,
      #countrySelectorModal .modal-dialog,
      #registerModal .modal-dialog,
      #registerSuccessModal .modal-dialog,
      #forgotPasswordModal .modal-dialog,
      #passwordResetSentModal .modal-dialog {
        max-width: 564px; } }
  .login-partial .modal-content,
  .register-partial .modal-content,
  #loginModal .modal-content,
  #countrySelectorModal .modal-content,
  #registerModal .modal-content,
  #registerSuccessModal .modal-content,
  #forgotPasswordModal .modal-content,
  #passwordResetSentModal .modal-content {
    height: 100dvh;
    background-color: #FFF; }
  .login-partial .row,
  .register-partial .row,
  #loginModal .row,
  #countrySelectorModal .row,
  #registerModal .row,
  #registerSuccessModal .row,
  #forgotPasswordModal .row,
  #passwordResetSentModal .row {
    border-bottom: 1px solid #D6D6D6;
    margin: 0;
    padding: 24px; }
    @media (min-width: 1024px) {
      .login-partial .row,
      .register-partial .row,
      #loginModal .row,
      #countrySelectorModal .row,
      #registerModal .row,
      #registerSuccessModal .row,
      #forgotPasswordModal .row,
      #passwordResetSentModal .row {
        padding: 24px 48px; } }
    .login-partial .row .col,
    .register-partial .row .col,
    #loginModal .row .col,
    #countrySelectorModal .row .col,
    #registerModal .row .col,
    #registerSuccessModal .row .col,
    #forgotPasswordModal .row .col,
    #passwordResetSentModal .row .col {
      padding: 0; }
    .login-partial .row:last-of-type,
    .register-partial .row:last-of-type,
    #loginModal .row:last-of-type,
    #countrySelectorModal .row:last-of-type,
    #registerModal .row:last-of-type,
    #registerSuccessModal .row:last-of-type,
    #forgotPasswordModal .row:last-of-type,
    #passwordResetSentModal .row:last-of-type {
      border-bottom: 0; }
  .login-partial .modal-header,
  .register-partial .modal-header,
  #loginModal .modal-header,
  #countrySelectorModal .modal-header,
  #registerModal .modal-header,
  #registerSuccessModal .modal-header,
  #forgotPasswordModal .modal-header,
  #passwordResetSentModal .modal-header {
    background-color: #FFF;
    margin: 0;
    padding: 24px;
    position: sticky;
    top: 0;
    z-index: 1051; }
    @media (min-width: 1024px) {
      .login-partial .modal-header,
      .register-partial .modal-header,
      #loginModal .modal-header,
      #countrySelectorModal .modal-header,
      #registerModal .modal-header,
      #registerSuccessModal .modal-header,
      #forgotPasswordModal .modal-header,
      #passwordResetSentModal .modal-header {
        padding: 24px 48px; } }
    .login-partial .modal-header .header-text,
    .register-partial .modal-header .header-text,
    #loginModal .modal-header .header-text,
    #countrySelectorModal .modal-header .header-text,
    #registerModal .modal-header .header-text,
    #registerSuccessModal .modal-header .header-text,
    #forgotPasswordModal .modal-header .header-text,
    #passwordResetSentModal .modal-header .header-text {
      margin: 0;
      padding: 0;
      color: #000; }
  .login-partial .modal-body,
  .register-partial .modal-body,
  #loginModal .modal-body,
  #countrySelectorModal .modal-body,
  #registerModal .modal-body,
  #registerSuccessModal .modal-body,
  #forgotPasswordModal .modal-body,
  #passwordResetSentModal .modal-body {
    padding: 0; }
  .login-partial .form-group, .login-partial .add-bottom-margin,
  .register-partial .form-group,
  .register-partial .add-bottom-margin,
  #loginModal .form-group,
  #loginModal .add-bottom-margin,
  #countrySelectorModal .form-group,
  #countrySelectorModal .add-bottom-margin,
  #registerModal .form-group,
  #registerModal .add-bottom-margin,
  #registerSuccessModal .form-group,
  #registerSuccessModal .add-bottom-margin,
  #forgotPasswordModal .form-group,
  #forgotPasswordModal .add-bottom-margin,
  #passwordResetSentModal .form-group,
  #passwordResetSentModal .add-bottom-margin {
    margin: 0 0 24px; }
  .login-partial .form-group .custom-control-label, .login-partial .form-group .custom-form-label,
  .register-partial .form-group .custom-control-label,
  .register-partial .form-group .custom-form-label,
  #loginModal .form-group .custom-control-label,
  #loginModal .form-group .custom-form-label,
  #countrySelectorModal .form-group .custom-control-label,
  #countrySelectorModal .form-group .custom-form-label,
  #registerModal .form-group .custom-control-label,
  #registerModal .form-group .custom-form-label,
  #registerSuccessModal .form-group .custom-control-label,
  #registerSuccessModal .form-group .custom-form-label,
  #forgotPasswordModal .form-group .custom-control-label,
  #forgotPasswordModal .form-group .custom-form-label,
  #passwordResetSentModal .form-group .custom-control-label,
  #passwordResetSentModal .form-group .custom-form-label {
    color: #000; }
  .login-partial .form-group .custom-form-label, .login-partial .form-group .custom-control-label p,
  .register-partial .form-group .custom-form-label,
  .register-partial .form-group .custom-control-label p,
  #loginModal .form-group .custom-form-label,
  #loginModal .form-group .custom-control-label p,
  #countrySelectorModal .form-group .custom-form-label,
  #countrySelectorModal .form-group .custom-control-label p,
  #registerModal .form-group .custom-form-label,
  #registerModal .form-group .custom-control-label p,
  #registerSuccessModal .form-group .custom-form-label,
  #registerSuccessModal .form-group .custom-control-label p,
  #forgotPasswordModal .form-group .custom-form-label,
  #forgotPasswordModal .form-group .custom-control-label p,
  #passwordResetSentModal .form-group .custom-form-label,
  #passwordResetSentModal .form-group .custom-control-label p {
    letter-spacing: 0;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0; }
    @media (min-width: 1024px) {
      .login-partial .form-group .custom-form-label, .login-partial .form-group .custom-control-label p,
      .register-partial .form-group .custom-form-label,
      .register-partial .form-group .custom-control-label p,
      #loginModal .form-group .custom-form-label,
      #loginModal .form-group .custom-control-label p,
      #countrySelectorModal .form-group .custom-form-label,
      #countrySelectorModal .form-group .custom-control-label p,
      #registerModal .form-group .custom-form-label,
      #registerModal .form-group .custom-control-label p,
      #registerSuccessModal .form-group .custom-form-label,
      #registerSuccessModal .form-group .custom-control-label p,
      #forgotPasswordModal .form-group .custom-form-label,
      #forgotPasswordModal .form-group .custom-control-label p,
      #passwordResetSentModal .form-group .custom-form-label,
      #passwordResetSentModal .form-group .custom-control-label p {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .login-partial .form-group .custom-form-label,
  .register-partial .form-group .custom-form-label,
  #loginModal .form-group .custom-form-label,
  #countrySelectorModal .form-group .custom-form-label,
  #registerModal .form-group .custom-form-label,
  #registerSuccessModal .form-group .custom-form-label,
  #forgotPasswordModal .form-group .custom-form-label,
  #passwordResetSentModal .form-group .custom-form-label {
    margin-top: 6px; }
  .login-partial .form-group label, .login-partial .form-group .custom-control-label, .login-partial .form-group .custom-control-label p,
  .register-partial .form-group label,
  .register-partial .form-group .custom-control-label,
  .register-partial .form-group .custom-control-label p,
  #loginModal .form-group label,
  #loginModal .form-group .custom-control-label,
  #loginModal .form-group .custom-control-label p,
  #countrySelectorModal .form-group label,
  #countrySelectorModal .form-group .custom-control-label,
  #countrySelectorModal .form-group .custom-control-label p,
  #registerModal .form-group label,
  #registerModal .form-group .custom-control-label,
  #registerModal .form-group .custom-control-label p,
  #registerSuccessModal .form-group label,
  #registerSuccessModal .form-group .custom-control-label,
  #registerSuccessModal .form-group .custom-control-label p,
  #forgotPasswordModal .form-group label,
  #forgotPasswordModal .form-group .custom-control-label,
  #forgotPasswordModal .form-group .custom-control-label p,
  #passwordResetSentModal .form-group label,
  #passwordResetSentModal .form-group .custom-control-label,
  #passwordResetSentModal .form-group .custom-control-label p {
    margin-bottom: 0; }
  .login-partial .form-group .invalid-feedback,
  .register-partial .form-group .invalid-feedback,
  #loginModal .form-group .invalid-feedback,
  #countrySelectorModal .form-group .invalid-feedback,
  #registerModal .form-group .invalid-feedback,
  #registerSuccessModal .form-group .invalid-feedback,
  #forgotPasswordModal .form-group .invalid-feedback,
  #passwordResetSentModal .form-group .invalid-feedback {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    margin-top: 24px; }
    @media (min-width: 1024px) {
      .login-partial .form-group .invalid-feedback,
      .register-partial .form-group .invalid-feedback,
      #loginModal .form-group .invalid-feedback,
      #countrySelectorModal .form-group .invalid-feedback,
      #registerModal .form-group .invalid-feedback,
      #registerSuccessModal .form-group .invalid-feedback,
      #forgotPasswordModal .form-group .invalid-feedback,
      #passwordResetSentModal .form-group .invalid-feedback {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .login-partial .alert,
  .register-partial .alert,
  #loginModal .alert,
  #countrySelectorModal .alert,
  #registerModal .alert,
  #registerSuccessModal .alert,
  #forgotPasswordModal .alert,
  #passwordResetSentModal .alert {
    background-color: #BF242B;
    margin-bottom: 24px; }
    .login-partial .alert .icon-close,
    .register-partial .alert .icon-close,
    #loginModal .alert .icon-close,
    #countrySelectorModal .alert .icon-close,
    #registerModal .alert .icon-close,
    #registerSuccessModal .alert .icon-close,
    #forgotPasswordModal .alert .icon-close,
    #passwordResetSentModal .alert .icon-close {
      aspect-ratio: 1;
      background-color: #FFF;
      border-radius: 50%;
      padding: 3px;
      margin: 4px 12px auto 0; }
      .login-partial .alert .icon-close:before,
      .register-partial .alert .icon-close:before,
      #loginModal .alert .icon-close:before,
      #countrySelectorModal .alert .icon-close:before,
      #registerModal .alert .icon-close:before,
      #registerSuccessModal .alert .icon-close:before,
      #forgotPasswordModal .alert .icon-close:before,
      #passwordResetSentModal .alert .icon-close:before {
        left: 0; }

#register-form .email-confirm {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1; }

.login-partial, .register-partial {
  background: inherit; }
  @media (min-width: 1024px) {
    .login-partial, .register-partial {
      max-width: 50dvw;
      margin: 0 auto; } }

.icon-checkmark:before,
.icon-close:before {
  color: #000;
  font-weight: 700;
  position: relative;
  left: 1px; }

p {
  line-height: 24px; }

.bundle-return-wrapper {
  margin-top: 48px; }
  .bundle-return-wrapper p {
    display: inline; }

.bundle-return-text {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 0px; }

.bundle-return-text-sub {
  color: #000;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; }

.change-country-container {
  border-top: 1px solid #D6D6D6;
  padding: 0px;
  width: 100%; }
  @media (max-width: 1023.98px) {
    .change-country-container {
      padding: 24px 18px 24px 24px; } }
  .change-country-container.custom-control.custom-radio .custom-control-label:before {
    left: calc(100% - 72px);
    top: 36%; }
    @media (max-width: 1023.98px) {
      .change-country-container.custom-control.custom-radio .custom-control-label:before {
        left: calc(100% - 32px);
        top: 22%; } }
  .change-country-container.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
    top: 36%; }
    @media (max-width: 1023.98px) {
      .change-country-container.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
        top: 22%; } }
  .change-country-container.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    left: calc(100% - 67px);
    top: 42%; }
    @media (max-width: 1023.98px) {
      .change-country-container.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
        left: calc(100% - 27px);
        top: 35%; } }
  .change-country-container .custom-control-label {
    width: 100%;
    padding: 24px 42px 24px 48px; }
    @media (max-width: 1023.98px) {
      .change-country-container .custom-control-label {
        padding: 0px; } }

.custom-radio-country-selector {
  margin-left: auto;
  padding-top: 6px; }

.country-selector-flag {
  border: 1px solid #D6D6D6;
  width: 48px;
  height: 36px;
  line-height: 36px;
  background-size: cover; }

.county-selector-text {
  padding-left: 24px;
  display: inline-block;
  height: 36px;
  line-height: 36px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px; }

.cart-page .cart-error-messaging.cart-error {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.cart-page .valid-cart-error {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center; }
  .cart-page .valid-cart-error.show {
    display: block; }
  .cart-page .valid-cart-error:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .cart-page .valid-cart-error.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .cart-page .valid-cart-error.alert-dismissible .close {
      padding: 12px; }

.cart-page .card {
  margin-bottom: -1px; }

.cart-page .cart-header {
  align-items: center;
  margin-bottom: 24px; }
  .cart-page .cart-header .number-of-items {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
    letter-spacing: 0;
    margin: 0; }

.cart-page .product-card-footer {
  padding-top: 0; }

.cart-page .product-info .remove-product {
  text-decoration: underline; }

.cart-page .product-info .product-edit {
  float: left; }

.cart-page .product-info .edit-remove-container {
  width: calc(100% + 10px); }

.cart-page .product-info .remove-edit-border {
  transform: translateY(4px);
  height: 16px;
  width: 1px;
  background: #000;
  float: left;
  margin: 0 12px; }

.cart-page .product-info .remove-btn {
  font-family: "Roboto", sans-serif;
  border: none;
  border-radius: 50%;
  font-size: 0;
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 20px 20px 20px;
  cursor: pointer;
  z-index: 1;
  background-color: #FFF;
  padding: 4px 6px;
  height: 22px;
  width: 22px;
  margin: 25px 25px 25px 25px;
  left: 0;
  right: auto; }
  .cart-page .product-info .remove-btn:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 11px;
    color: inherit;
    font-weight: normal; }
  .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title).btn-sm, .cart-page .product-info .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .cart-page .product-info .remove-btn[class*="btn-"]:not(.title).btn-sm:before, .cart-page .product-info .btn-group-sm > .remove-btn.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .cart-page .product-info .remove-btn[class*="btn-"]:not(.title) .fa {
      display: none; }

.cart-page .product-info label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0;
  font-size: 12px;
  display: inline-block;
  margin: 0 0 0 1px;
  padding: 9px 12px 0 12px;
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-out;
  color: #595959; }
  .cart-page .product-info label:has(~ .form-control:not(:placeholder-shown)), .cart-page .product-info label:has(~ .form-control:autofill:placeholder-shown) {
    opacity: 1; }
  .cart-page .product-info label:has(~ textarea.form-control) {
    padding-top: 12px; }

.cart-page .product-info .line-item-promo {
  margin-top: 5px; }
  .cart-page .product-info .line-item-promo a {
    display: block; }
  .cart-page .product-info .line-item-promo a + a {
    margin-top: 5px; }

.cart-page .product-info .bonus-product-button {
  margin: 12px 0 0; }

.cart-page .product-info .item-image {
  width: 100px; }
  @media (min-width: 1024px) {
    .cart-page .product-info .item-image {
      width: 176px; } }

@media (min-width: 1024px) {
  .cart-page .item-attributes {
    width: calc(100% - 176px); } }

.cart-page .line-item-name,
.cart-page .line-item-attributes {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%; }

.cart-page .single-approaching-discount {
  background-color: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 0px;
  margin-bottom: 12px;
  padding: 20px 20px 20px 20px;
  color: #00865A; }

.cart-page .bonus-line-item-msg {
  margin-top: 12px;
  margin-left: 0; }

.cart-page .bundled-line-item:not(:empty):not(.mystery-line-item) {
  border-top: 1px solid #D6D6D6;
  padding: 12px 0px; }

.cart-page .bundled-line-item .item-attributes {
  margin-left: 0; }

@media (min-width: 1024px) {
  .cart-page .bundled-line-item .line-item-total-price {
    padding-right: 12px; } }

@media (min-width: 1440px) {
  .cart-page .bundled-line-item .line-item-total-price {
    padding-right: 24px; } }

.cart-page .bundled-line-item .bundled-item-label {
  margin-right: 6px;
  font-weight: 500; }

.cart-page .coupon-price-adjustment {
  margin-top: 12px;
  background-color: #FFF;
  border: 1px solid #D6D6D6;
  border-radius: 0px;
  padding: 12px 12px 12px 12px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 0;
  position: relative; }
  .cart-page .coupon-price-adjustment .coupon-applied,
  .cart-page .coupon-price-adjustment .coupon-not-applied {
    letter-spacing: 0;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0; }
    @media (min-width: 1024px) {
      .cart-page .coupon-price-adjustment .coupon-applied,
      .cart-page .coupon-price-adjustment .coupon-not-applied {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .cart-page .coupon-price-adjustment .coupon-applied {
    color: #00865A; }
  .cart-page .coupon-price-adjustment .coupon-not-applied {
    color: #BF242B; }
  .cart-page .coupon-price-adjustment .remove-coupon {
    font-family: "Roboto", sans-serif;
    border: none;
    border-radius: 50%;
    font-size: 0;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0 20px 20px 20px;
    cursor: pointer;
    z-index: 1;
    background-color: #FFF;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
    padding: 12px 12px 12px 12px; }
    .cart-page .coupon-price-adjustment .remove-coupon:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 11px;
      color: inherit;
      font-weight: normal; }
    .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title).btn-sm, .cart-page .coupon-price-adjustment .btn-group-sm > .remove-coupon.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title).btn-sm:before, .cart-page .coupon-price-adjustment .btn-group-sm > .remove-coupon.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) [class*="icon-"],
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) [class^="icon-"],
      .cart-page .coupon-price-adjustment .remove-coupon[class*="btn-"]:not(.title) .fa {
        display: none; }
  .cart-page .coupon-price-adjustment .coupon-promotion-relationship {
    letter-spacing: 0;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 1.5em;
    letter-spacing: 0;
    margin-bottom: 0; }
    @media (min-width: 1024px) {
      .cart-page .coupon-price-adjustment .coupon-promotion-relationship {
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0; } }

.cart-page .coupon-missing-error {
  display: none; }

.cart-page .promotion-information {
  margin-bottom: 6px;
  margin-top: 6px; }
  .cart-page .promotion-information::after {
    display: block;
    content: "";
    clear: both; }

.cart-page .applied-promotion-discount {
  color: #00865A;
  float: right; }

.cart-page .totals.card .card-body {
  border-bottom: 1px solid #D6D6D6;
  flex: none; }

.cart-page .totals .shipping-method {
  margin-bottom: 24px; }

.cart-page .totals .estimated-total {
  margin-bottom: 0; }
  .cart-page .totals .estimated-total p {
    font-size: 16px;
    font-family: "archia-medium", sans-serif;
    font-weight: normal;
    text-transform: none; }

.cart-page .checkout-btn:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: normal; }

.cart-page .checkout-btn[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .cart-page .checkout-btn[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: inherit;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .cart-page .checkout-btn[class*="btn-"]:not(.title).btn-sm, .cart-page .btn-group-sm > .checkout-btn.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .cart-page .checkout-btn[class*="btn-"]:not(.title).btn-sm:before, .cart-page .btn-group-sm > .checkout-btn.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .cart-page .checkout-btn[class*="btn-"]:not(.title) [class*="icon-"],
  .cart-page .checkout-btn[class*="btn-"]:not(.title) [class^="icon-"],
  .cart-page .checkout-btn[class*="btn-"]:not(.title) .fa {
    display: none; }

@media (max-width: 543.98px) {
  .cart-page .checkout-continue {
    position: fixed;
    bottom: 0;
    border: 0;
    background-color: rgba(255, 255, 255, 0.8);
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
    width: 100%;
    z-index: 100;
    margin-right: -12px;
    margin-left: -12px; }
    .cart-page .checkout-continue .checkout-btn {
      margin-top: 12px;
      margin-bottom: 12px; } }

.cart-page .cart ~ .cart-recommendations {
  border-top: 1px solid #D6D6D6;
  margin-top: 130px;
  padding-top: 95px; }

.delete-coupon-confirmation-body .coupon-to-remove {
  margin: 12px 0 0;
  font-weight: bold; }

.delete-confirmation-body .product-to-remove {
  margin: 12px 0 0;
  font-weight: bold; }

#chooseBonusProductModal {
  padding-right: 0 !important; }

.cart-page .bonus-product-line-item .bonus-line-item-msg {
  padding-top: 6px;
  border-top: 1px solid #D6D6D6; }
  .cart-page .bonus-product-line-item .bonus-line-item-msg + .bundled-line-item {
    border-top: none; }

.cart-page .bundled-line-item .line-item-total-price {
  padding: 0 6px; }

.cart-page .bundled-line-item .bonus-product-button {
  margin-top: 0; }
  .cart-page .bundled-line-item .bonus-product-button button {
    color: #000;
    text-decoration: underline; }

.cart-page .page-title {
  line-height: 100%; }

@media (max-width: 768.98px) {
  .cart-page .totals.card .card-body.checkout-continue {
    position: relative;
    background-color: transparent;
    box-shadow: none;
    border-bottom: none;
    margin-right: 0;
    margin-left: 0;
    padding-right: 0px; } }

.cart-page .continue-shopping-cart {
  display: none; }
  @media (max-width: 543.98px) {
    .cart-page .continue-shopping-cart {
      display: block; } }

.cart-page .continue-shopping-link {
  width: 245px; }
  .cart-page .continue-shopping-link:before {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: inherit;
    color: inherit;
    font-weight: normal; }
  .cart-page .continue-shopping-link[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .cart-page .continue-shopping-link[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
    .cart-page .continue-shopping-link[class*="btn-"]:not(.title).btn-sm, .cart-page .btn-group-sm > .continue-shopping-link.btn[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .cart-page .continue-shopping-link[class*="btn-"]:not(.title).btn-sm:before, .cart-page .btn-group-sm > .continue-shopping-link.btn[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(-32px); }
    .cart-page .continue-shopping-link[class*="btn-"]:not(.title) [class*="icon-"],
    .cart-page .continue-shopping-link[class*="btn-"]:not(.title) [class^="icon-"],
    .cart-page .continue-shopping-link[class*="btn-"]:not(.title) .fa {
      display: none; }
  @media (max-width: 1023.98px) {
    .cart-page .continue-shopping-link {
      width: 185px; } }

.cart-page .continue-shopping-link[class*="btn-"]:not(.title):before {
  font-size: 14px;
  margin-left: 12px; }

.cart-page .continue-shopping-link[class*="btn-"]:not(.title) {
  padding-left: 32px; }

.cart-page .coupon-price-adjustment {
  font-size: 12px;
  border: 1px solid transparent;
  padding: 12px 0 12px 0; }
  .cart-page .coupon-price-adjustment .coupon-applied {
    color: #BF242B; }
  .cart-page .coupon-price-adjustment .remove-coupon {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: flex-end; }

.cart-page .promo-code-section .title {
  padding-left: 0; }
  .cart-page .promo-code-section .title::after {
    right: 0; }

.cart-page .discount-top-border {
  border-bottom: 1px solid #D6D6D6; }

@media (max-width: 1023.98px) {
  .cart-page .approaching-discounts-mobile .approaching-discounts .single-approaching-discount:last-of-type {
    margin-bottom: 12px; } }

.cart-page .approaching-discounts .single-approaching-discount:last-of-type {
  margin-bottom: 0; }

.cart-page .approaching-discounts .single-approaching-discount {
  /* rvw_autobahn_core override */
  background-color: unset;
  border: unset;
  border-radius: unset;
  margin-bottom: unset;
  padding: unset;
  color: unset;
  /* rvw_autobahn_core override */
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px; }
  .cart-page .approaching-discounts .single-approaching-discount .threshold-bar {
    height: 16px;
    border: 1px solid #D8D8D8;
    border-radius: 6px;
    background-color: #ECEEE4; }
    .cart-page .approaching-discounts .single-approaching-discount .threshold-bar .threshold-bar-progress {
      height: 100%;
      width: 0%;
      border-radius: 14px;
      background-color: #000;
      transition: width .2s; }
  .cart-page .approaching-discounts .single-approaching-discount .threshold-message {
    padding-bottom: 12px;
    color: #00865A; }

.cart-page .approaching-discounts .bonus-product-button-wrapper {
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 12px;
  margin-bottom: 12px; }
  .cart-page .approaching-discounts .bonus-product-button-wrapper div[class^=col] {
    margin-top: auto;
    margin-bottom: auto; }
  .cart-page .approaching-discounts .bonus-product-button-wrapper .circle {
    background-image: url("../images/icons/gift.svg");
    background-position: center;
    background-repeat: no-repeat;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 0;
    height: 36px;
    width: 36px; }
  .cart-page .approaching-discounts .bonus-product-button-wrapper .bonus-product-button button {
    font-size: 14px;
    height: auto;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 24px; }

.cart-page .dw-apple-pay-button,
.cart-page .dw-apple-pay-button:hover {
  border-radius: 50px; }

.cart-page .dw-apple-pay-button:hover {
  opacity: .8; }

.cart-page klarna-placement {
  margin-top: 15px;
  display: block; }

.cart-page .compliance-callout {
  letter-spacing: 0;
  font-size: 14px;
  line-height: 1.5em;
  letter-spacing: 0;
  background-color: #FC0;
  font-weight: bold;
  margin-top: 12px;
  padding: 12px 24px; }
  @media (min-width: 1024px) {
    .cart-page .compliance-callout {
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0; } }

.cart-page .totals .estimated-total p {
  white-space: nowrap; }

.esw-cart-exceeded {
  background: #BF242B;
  padding-top: 22px;
  padding-bottom: 22px;
  color: #FFF;
  display: flex;
  align-items: center; }
  .esw-cart-exceeded h6 {
    display: inline-block;
    line-height: 1;
    padding-left: 24px;
    margin: 0; }
  .esw-cart-exceeded p {
    display: inline-block;
    line-height: 1;
    padding-left: 24px;
    margin: 0; }
  .esw-cart-exceeded .icon-info {
    font-size: 24px; }
  @media (max-width: 1023.98px) {
    .esw-cart-exceeded {
      display: block; }
      .esw-cart-exceeded p {
        padding-left: 36px;
        padding-top: 12px; }
      .esw-cart-exceeded h6 {
        padding-left: 12px; } }
  .esw-cart-exceeded .esw-exceeded-icon-container {
    display: flex;
    align-items: center; }

.esw-exceeded {
  background-color: #BF242B;
  color: #FFF;
  padding-bottom: 22px;
  padding-top: 22px;
  padding-left: 24px;
  padding-right: 24px; }
  .esw-exceeded .esw-exceeded-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap; }
    .esw-exceeded .esw-exceeded-container h6 {
      padding-left: 12px;
      line-height: 1;
      flex: 1;
      min-width: 0;
      word-wrap: break-word;
      white-space: normal; }
    .esw-exceeded .esw-exceeded-container .icon-info {
      font-size: 24px;
      flex-shrink: 0; }
  .esw-exceeded p {
    padding-left: 36px;
    line-height: 1;
    padding-top: 12px; }

.esw-cart-message {
  padding-bottom: 2px;
  padding-top: 12px; }

.customer-information-block .btn-link {
  color: #0C0A08;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
  vertical-align: baseline;
  margin-top: 10px;
  border: none; }
  .customer-information-block .btn-link:hover {
    color: #2B2B2A;
    text-decoration: underline; }
  .dark-theme .customer-information-block .btn-link {
    color: #FFF; }
    .dark-theme .customer-information-block .btn-link:hover {
      color: #FFF; }

.customer-information-block .submit-customer {
  margin-top: 10px; }

.customer-information-block .btn-block + .btn-block {
  margin-top: 10px; }

.customer-information-block .password-form {
  margin-bottom: 20px; }

.data-checkout-stage {
  /*
    * Show/Hide behavior for various checkout states
    */ }
  .data-checkout-stage .row > .col-12 .text-center {
    margin-bottom: 10px; }
  .data-checkout-stage .row [class*='col-'] .card {
    margin-bottom: 0; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card .card-header {
      border-top: 0; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card.customer-section, .data-checkout-stage .row [class*='col-'] .card ~ .card.customer-summary {
      border: solid #D6D6D6;
      border-width: 1px 0 0 0; }
    .data-checkout-stage .row [class*='col-'] .card ~ .card.ghost.payment {
      border: solid #D6D6D6;
      border-width: 0 0 1px 0; }
  .data-checkout-stage .card .card-header,
  .data-checkout-stage .card .card-header h2 {
    padding-left: 0;
    padding-right: 0; }
  .data-checkout-stage .card .card-header .edit-button {
    color: #0C0A08;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
    color: #0C0A08; }
    .data-checkout-stage .card .card-header .edit-button:hover {
      color: #2B2B2A;
      text-decoration: underline; }
    .dark-theme .data-checkout-stage .card .card-header .edit-button {
      color: #FFF; }
      .dark-theme .data-checkout-stage .card .card-header .edit-button:hover {
        color: #FFF; }
    .data-checkout-stage .card .card-header .edit-button:hover {
      color: #0C0A08; }
  .data-checkout-stage .card .card-body {
    padding-left: 0;
    padding-right: 0; }
  .data-checkout-stage .shipping-summary .leading-lines,
  .data-checkout-stage .payment-summary .leading-lines {
    margin-bottom: 0; }
  .data-checkout-stage .shipping-summary .summary-details,
  .data-checkout-stage .payment-summary .summary-details {
    padding-bottom: 41px; }
    .data-checkout-stage .shipping-summary .summary-details .address-summary,
    .data-checkout-stage .shipping-summary .summary-details .shipping-phone,
    .data-checkout-stage .shipping-summary .summary-details .shipping-method,
    .data-checkout-stage .shipping-summary .summary-details .gift-message-summary,
    .data-checkout-stage .shipping-summary .summary-details .order-summary-email,
    .data-checkout-stage .shipping-summary .summary-details .order-summary-phone,
    .data-checkout-stage .shipping-summary .summary-details .payment-details,
    .data-checkout-stage .payment-summary .summary-details .address-summary,
    .data-checkout-stage .payment-summary .summary-details .shipping-phone,
    .data-checkout-stage .payment-summary .summary-details .shipping-method,
    .data-checkout-stage .payment-summary .summary-details .gift-message-summary,
    .data-checkout-stage .payment-summary .summary-details .order-summary-email,
    .data-checkout-stage .payment-summary .summary-details .order-summary-phone,
    .data-checkout-stage .payment-summary .summary-details .payment-details {
      color: #595959;
      margin-bottom: 1px; }
  .data-checkout-stage .payment-summary .addressInformation.hideAddressInformationForPayment {
    display: none; }
  .data-checkout-stage .view-address-block h3:not(:first-child),
  .data-checkout-stage .view-address-block .multi-shipping,
  .data-checkout-stage .shipment-block h3:not(:first-child),
  .data-checkout-stage .shipment-block .multi-shipping,
  .data-checkout-stage .product-summary-block h3:not(:first-child),
  .data-checkout-stage .product-summary-block .multi-shipping {
    margin-top: 12px; }
  .data-checkout-stage .view-address-block address,
  .data-checkout-stage .view-address-block .store-details,
  .data-checkout-stage .view-address-block .shipping-header-text + p,
  .data-checkout-stage .shipment-block address,
  .data-checkout-stage .shipment-block .store-details,
  .data-checkout-stage .shipment-block .shipping-header-text + p,
  .data-checkout-stage .product-summary-block address,
  .data-checkout-stage .product-summary-block .store-details,
  .data-checkout-stage .product-summary-block .shipping-header-text + p {
    margin-left: 12px; }
    .data-checkout-stage .view-address-block address address,
    .data-checkout-stage .view-address-block .store-details address,
    .data-checkout-stage .view-address-block .shipping-header-text + p address,
    .data-checkout-stage .shipment-block address address,
    .data-checkout-stage .shipment-block .store-details address,
    .data-checkout-stage .shipment-block .shipping-header-text + p address,
    .data-checkout-stage .product-summary-block address address,
    .data-checkout-stage .product-summary-block .store-details address,
    .data-checkout-stage .product-summary-block .shipping-header-text + p address {
      margin-left: 0; }
  .data-checkout-stage .shipping-method-block h5 {
    margin-bottom: 12px; }
  .data-checkout-stage .shipping-method-block .shipping-method-list {
    border: 1px solid #D6D6D6;
    border-radius: 8px; }
    .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 12px 24px; }
      .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item:has(input:checked), .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item.available-shipping-methods, .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item.no-shipping-methods {
        background-color: #F8F8F8; }
      .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item:not(:last-of-type) {
        border-bottom: 1px solid #D6D6D6; }
      .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-list-item label {
        font-weight: 500; }
    .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-option {
      background-color: transparent; }
    .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-days {
      letter-spacing: 0;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0;
      flex-basis: 100%;
      padding-left: calc(20px + 12px); }
      @media (min-width: 1024px) {
        .data-checkout-stage .shipping-method-block .shipping-method-list .shipping-method-days {
          font-size: 12px;
          line-height: 1.5em;
          letter-spacing: 0; } }
    .data-checkout-stage .shipping-method-block .shipping-method-list .available-shipping-methods {
      letter-spacing: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
      color: #595959;
      padding: 12px; }
      @media (min-width: 1024px) {
        .data-checkout-stage .shipping-method-block .shipping-method-list .available-shipping-methods {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0; } }
    .data-checkout-stage .shipping-method-block .shipping-method-list .no-shipping-methods {
      letter-spacing: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
      color: #BF242B;
      padding: 12px; }
      @media (min-width: 1024px) {
        .data-checkout-stage .shipping-method-block .shipping-method-list .no-shipping-methods {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0; } }
    .data-checkout-stage .shipping-method-block .shipping-method-list:has(> .available-shipping-methods), .data-checkout-stage .shipping-method-block .shipping-method-list:has(> .no-shipping-methods) {
      border: none; }
  .data-checkout-stage .shipping-method-block span.display-name {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    padding-right: 0;
    vertical-align: inherit; }
    @media (min-width: 1024px) {
      .data-checkout-stage .shipping-method-block span.display-name {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
  .data-checkout-stage .shipping-method-block .text-muted.arrival-time {
    padding-top: 3px;
    margin-top: -3px;
    top: -1px;
    padding-left: 3px;
    left: -3px;
    padding-right: 0; }
  .data-checkout-stage .shipping-method-block .shipping-cost {
    letter-spacing: 0;
    font-size: 14px;
    line-height: 1.5em;
    letter-spacing: 0;
    font-weight: 500;
    float: right;
    margin-bottom: auto;
    white-space: nowrap; }
    @media (min-width: 1024px) {
      .data-checkout-stage .shipping-method-block .shipping-cost {
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0; } }
    .data-checkout-stage .shipping-method-block .shipping-cost.shipping-discount {
      color: #000; }
    .data-checkout-stage .shipping-method-block .shipping-cost span {
      white-space: nowrap; }
  .data-checkout-stage .gift-message-block {
    margin-bottom: 12px; }
    .data-checkout-stage .gift-message-block .gift-message {
      margin-top: 12px; }
  .data-checkout-stage .gift-message-summary {
    margin-bottom: 12px; }
  .data-checkout-stage .shipment-selector-block .btn-show-details,
  .data-checkout-stage .shipment-selector-block .btn-add-new,
  .data-checkout-stage .address-selector-block .btn-show-details,
  .data-checkout-stage .address-selector-block .btn-add-new {
    color: #0C0A08;
    font-weight: normal;
    text-decoration: underline;
    cursor: pointer;
    margin-top: 24px;
    padding-right: 24px;
    padding-left: 0px;
    border: none; }
    .data-checkout-stage .shipment-selector-block .btn-show-details:hover,
    .data-checkout-stage .shipment-selector-block .btn-add-new:hover,
    .data-checkout-stage .address-selector-block .btn-show-details:hover,
    .data-checkout-stage .address-selector-block .btn-add-new:hover {
      color: #2B2B2A;
      text-decoration: underline; }
    .dark-theme .data-checkout-stage .shipment-selector-block .btn-show-details, .dark-theme
    .data-checkout-stage .shipment-selector-block .btn-add-new, .dark-theme
    .data-checkout-stage .address-selector-block .btn-show-details, .dark-theme
    .data-checkout-stage .address-selector-block .btn-add-new {
      color: #FFF; }
      .dark-theme .data-checkout-stage .shipment-selector-block .btn-show-details:hover, .dark-theme
      .data-checkout-stage .shipment-selector-block .btn-add-new:hover, .dark-theme
      .data-checkout-stage .address-selector-block .btn-show-details:hover, .dark-theme
      .data-checkout-stage .address-selector-block .btn-add-new:hover {
        color: #FFF; }
  .data-checkout-stage .contact-info-block .info-icon {
    font-size: 12px; }
  .data-checkout-stage .giftcertificate-information {
    margin-top: 12px; }
    .data-checkout-stage .giftcertificate-information .applybalancebutton {
      margin-top: auto;
      margin-bottom: 12px; }
  .data-checkout-stage .payment-information .payment-options .nav-item {
    margin-bottom: 24px; }
    .data-checkout-stage .payment-information .payment-options .nav-item:only-child {
      display: none; }
    .data-checkout-stage .payment-information .payment-options .nav-item:not(:only-child) {
      margin-top: calc(24px - 12px); }
  .data-checkout-stage .form-control.shippingZipCode ~ span,
  .data-checkout-stage .form-control.shippingPhoneNumber ~ span,
  .data-checkout-stage .form-control.billingZipCode ~ span {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0;
    margin-top: 12px;
    display: block; }
    .data-checkout-stage .form-control.shippingZipCode ~ span:not(.invalid-feedback),
    .data-checkout-stage .form-control.shippingPhoneNumber ~ span:not(.invalid-feedback),
    .data-checkout-stage .form-control.billingZipCode ~ span:not(.invalid-feedback) {
      color: #595959; }
  .data-checkout-stage .credit-card-selection-new .info-icon {
    font-size: 12px; }
  .data-checkout-stage .credit-card-selection-new .paypal-content {
    max-width: 350px;
    margin-left: auto;
    margin-right: auto; }
  .data-checkout-stage .credit-card-selection-new .saved-payment-information {
    margin-top: 12px; }
    @media (min-width: 544px) {
      .data-checkout-stage .credit-card-selection-new .saved-payment-information {
        margin-top: 0; } }
  .data-checkout-stage .credit-card-selection-new .card-image {
    max-width: 100%; }
  .data-checkout-stage .credit-card-selection-new .saved-payment-instrument {
    padding-top: 12px;
    padding-bottom: 12px;
    align-items: center; }
  .data-checkout-stage .credit-card-selection-new .add-payment {
    margin-top: 12px; }
  .data-checkout-stage .credit-card-selection-new .cancel-new-payment {
    margin-top: 24px; }
  .data-checkout-stage .credit-card-selection-new .selected-payment {
    background-color: #F8F8F8;
    border: 1px solid #0C0A08;
    color: #0C0A08; }
  .data-checkout-stage .credit-card-selection-new .save-credit-card {
    margin-top: 5px; }
  .data-checkout-stage .credit-card-form select:invalid {
    color: #595959; }
  .data-checkout-stage .next-step-button {
    margin-top: 24px; }
    @media (max-width: 768.98px) {
      .data-checkout-stage .next-step-button {
        position: fixed;
        bottom: 0;
        left: 0;
        padding-right: 0;
        padding-left: 0;
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
        z-index: 100; }
        .data-checkout-stage .next-step-button .btn {
          margin-top: 24px;
          margin-bottom: 24px; } }
  .data-checkout-stage [data-customer-type=guest] .single-shipping .shipment-selector-block,
  .data-checkout-stage option[value=new],
  .data-checkout-stage .single-shipping .shipping-form:not(.in-store-pickup) .multi-ship-action-buttons,
  .data-checkout-stage .single-shipping .view-address-block,
  .data-checkout-stage .multi-ship .single-shipping .shipping-content,
  .data-checkout-stage .multi-ship .shipping-summary .single-shipping,
  .data-checkout-stage .multi-ship .confirm-details .single-shipping,
  .data-checkout-stage .multi-shipping,
  .data-checkout-stage .error-message,
  .data-checkout-stage .checkout-hidden {
    display: none; }
  .data-checkout-stage.multi-ship .multi-shipping,
  .data-checkout-stage span.ship-to-name,
  .data-checkout-stage span.ship-to-address1,
  .data-checkout-stage span.ship-to-address2,
  .data-checkout-stage span.ship-to-phone,
  .data-checkout-stage span.ship-to-city-st-zip {
    display: block; }
  .data-checkout-stage[data-checkout-stage] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage] .shipping-summary,
  .data-checkout-stage[data-checkout-stage] button.place-order,
  .data-checkout-stage[data-checkout-stage] button.submit-payment,
  .data-checkout-stage[data-checkout-stage] button.submit-shipping {
    display: none; }
  .data-checkout-stage[data-checkout-stage] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage] button.alipay, .data-checkout-stage[data-checkout-stage] button.sof, .data-checkout-stage[data-checkout-stage] button.eps, .data-checkout-stage[data-checkout-stage] button.idl, .data-checkout-stage[data-checkout-stage] button.gpy, .data-checkout-stage[data-checkout-stage] button.mch, .data-checkout-stage[data-checkout-stage] button.klarna, .data-checkout-stage[data-checkout-stage] button.credit_card, .data-checkout-stage[data-checkout-stage] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage] button.sa_flex {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .shipping-address-block:not(.d-none) + .shipping-method-block {
    margin-top: 41px; }
  .data-checkout-stage[data-checkout-stage=customer] .card.ghost.customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .card.customer-summary {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] .card.payment-form {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] button.submit-customer {
    display: block; }
  .data-checkout-stage[data-checkout-stage=customer] .card.ghost.shipping {
    display: none; }
  .data-checkout-stage[data-checkout-stage=customer] button.submit-shipping {
    display: block; }
  .data-checkout-stage[data-checkout-stage=shipping] .card.customer-section {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] button.submit-customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] .card.ghost.customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping].multi-ship .order-product-summary,
  .data-checkout-stage[data-checkout-stage=shipping] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=shipping] .card.ghost,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=customer] .shipping-address-block,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=shipment] .shipping-address-form,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=edit] .shipping-address-block,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=new] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=shipping] [data-address-mode=new] .btn-add-new {
    display: none; }
  .data-checkout-stage[data-checkout-stage=shipping] button.submit-shipping {
    display: block; }
  .data-checkout-stage[data-checkout-stage=shipping] .shipping-address-block:not(.d-none) + .shipping-method-block {
    margin-top: 41px; }
  .data-checkout-stage[data-checkout-stage=shipping] .shipment-selector-block {
    padding-top: 24px; }
  .data-checkout-stage[data-checkout-stage=shipping] .multi-shipping .card .card-header {
    border-top: 0; }
  .data-checkout-stage[data-checkout-stage=shipping] .multi-shipping .card .card-title {
    border-bottom: 0; }
  .data-checkout-stage[data-checkout-stage=payment] button.submit-customer {
    display: none; }
  .data-checkout-stage[data-checkout-stage=payment] .customer-section,
  .data-checkout-stage[data-checkout-stage=payment] .shipping-section,
  .data-checkout-stage[data-checkout-stage=payment] .card.ghost,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=customer] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=shipment] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=edit] .billing-address,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=new] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=new] .btn-add-new,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=details] .btn-show-details,
  .data-checkout-stage[data-checkout-stage=payment] [data-address-mode=details] .btn-add-new,
  .data-checkout-stage[data-checkout-stage=payment] button#hideSubmitPayment,
  .data-checkout-stage[data-checkout-stage=payment] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage=payment] button.alipay, .data-checkout-stage[data-checkout-stage=payment] button.sof, .data-checkout-stage[data-checkout-stage=payment] button.eps, .data-checkout-stage[data-checkout-stage=payment] button.idl, .data-checkout-stage[data-checkout-stage=payment] button.gpy, .data-checkout-stage[data-checkout-stage=payment] button.mch, .data-checkout-stage[data-checkout-stage=payment] button.klarna, .data-checkout-stage[data-checkout-stage=payment] button.credit_card, .data-checkout-stage[data-checkout-stage=payment] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage=payment] button.sa_flex {
    display: none; }
  .data-checkout-stage[data-checkout-stage=payment] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=payment] .shipping-summary,
  .data-checkout-stage[data-checkout-stage=payment] button.submit-payment,
  .data-checkout-stage[data-checkout-stage=payment] button#showSubmitPayment {
    display: block; }
  .data-checkout-stage[data-checkout-stage=payment] .contact-info-block > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .billing-address > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .billingAddressFields > .row .form-group,
  .data-checkout-stage[data-checkout-stage=payment] .credit-card-form > .row .form-group {
    margin-top: calc(24px - 12px); }
  .data-checkout-stage[data-checkout-stage=placeOrder] button.submit-customer,
  .data-checkout-stage[data-checkout-stage=placeOrder] .customer-section,
  .data-checkout-stage[data-checkout-stage=placeOrder] .shipping-section,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.ghost,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sasilentpost,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.secure-acceptance-iframe {
    display: none; }
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage=placeOrder] .shipping-summary,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.place-order,
  .data-checkout-stage[data-checkout-stage=placeOrder] .card.secure-acceptance-iframe,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_redirect,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_iframe,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_silentpost,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.alipay, .data-checkout-stage[data-checkout-stage=placeOrder] button.sof, .data-checkout-stage[data-checkout-stage=placeOrder] button.eps, .data-checkout-stage[data-checkout-stage=placeOrder] button.idl, .data-checkout-stage[data-checkout-stage=placeOrder] button.gpy, .data-checkout-stage[data-checkout-stage=placeOrder] button.mch, .data-checkout-stage[data-checkout-stage=placeOrder] button.klarna, .data-checkout-stage[data-checkout-stage=placeOrder] button.credit_card, .data-checkout-stage[data-checkout-stage=placeOrder] button.dw_google_pay,
  .data-checkout-stage[data-checkout-stage=placeOrder] button.sa_flex {
    display: block; }
  .data-checkout-stage[data-checkout-stage=submitted] .shipping-form,
  .data-checkout-stage[data-checkout-stage=submitted] .card.payment-form,
  .data-checkout-stage[data-checkout-stage=submitted] button.submit-customer,
  .data-checkout-stage[data-checkout-stage=submitted] .card.ghost,
  .data-checkout-stage[data-checkout-stage=submitted] .summary-section-label.shipping-addr-label,
  .data-checkout-stage[data-checkout-stage=submitted] button.place-order {
    display: none; }
  .data-checkout-stage[data-checkout-stage=submitted] .card.payment-summary,
  .data-checkout-stage[data-checkout-stage=submitted] .shipping-summary {
    display: block; }

/*
* Shared styles for account order detail and checkout confirmation pages
*/
.data-checkout-stage .order-total-summary .grand-total,
.data-checkout-stage .order-total-summary .grand-total .order-receipt-label,
.data-checkout-stage .order-total-summary .grand-total-sum,
.data-checkout-stage .order-product-summary .grand-total,
.data-checkout-stage .order-product-summary .grand-total .order-receipt-label,
.data-checkout-stage .order-product-summary .grand-total-sum,
.receipt .order-total-summary .grand-total,
.receipt .order-total-summary .grand-total .order-receipt-label,
.receipt .order-total-summary .grand-total-sum,
.receipt .order-product-summary .grand-total,
.receipt .order-product-summary .grand-total .order-receipt-label,
.receipt .order-product-summary .grand-total-sum,
.account-page .order-total-summary .grand-total,
.account-page .order-total-summary .grand-total .order-receipt-label,
.account-page .order-total-summary .grand-total-sum,
.account-page .order-product-summary .grand-total,
.account-page .order-product-summary .grand-total .order-receipt-label,
.account-page .order-product-summary .grand-total-sum {
  font-family: "archia-medium", sans-serif;
  font-size: 16px;
  font-weight: normal;
  text-transform: none; }

.data-checkout-stage .summary-section-label,
.data-checkout-stage .shipping-section h3,
.data-checkout-stage .product-summary-block .shipment-block h3,
.data-checkout-stage .product-summary-block .multi-shipping h3,
.receipt .summary-section-label,
.receipt .shipping-section h3,
.receipt .product-summary-block .shipment-block h3,
.receipt .product-summary-block .multi-shipping h3,
.account-page .summary-section-label,
.account-page .shipping-section h3,
.account-page .product-summary-block .shipment-block h3,
.account-page .product-summary-block .multi-shipping h3 {
  display: block;
  color: #0C0A08; }

.data-checkout-stage .order-total-summary .grand-total,
.receipt .order-total-summary .grand-total,
.account-page .order-total-summary .grand-total {
  margin-bottom: 5px; }

.data-checkout-stage .order-product-summary .product-summary-block,
.receipt .order-product-summary .product-summary-block,
.account-page .order-product-summary .product-summary-block {
  padding-top: 20px; }

.data-checkout-stage .order-product-summary .card-header,
.receipt .order-product-summary .card-header,
.account-page .order-product-summary .card-header {
  border-bottom: 1px solid #D6D6D6;
  padding: 24px 0; }

.data-checkout-stage .order-product-summary .leading-lines,
.receipt .order-product-summary .leading-lines,
.account-page .order-product-summary .leading-lines {
  margin-bottom: 0; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-name,
.data-checkout-stage .order-product-summary .product-line-item .line-item-pricing-info,
.receipt .order-product-summary .product-line-item .line-item-name,
.receipt .order-product-summary .product-line-item .line-item-pricing-info,
.account-page .order-product-summary .product-line-item .line-item-name,
.account-page .order-product-summary .product-line-item .line-item-pricing-info {
  text-decoration: none; }

.data-checkout-stage .order-product-summary .product-line-item .item-attributes,
.receipt .order-product-summary .product-line-item .item-attributes,
.account-page .order-product-summary .product-line-item .item-attributes {
  padding: 0; }
  .data-checkout-stage .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .data-checkout-stage .order-product-summary .product-line-item .item-attributes .item-options,
  .receipt .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .receipt .order-product-summary .product-line-item .item-attributes .item-options,
  .account-page .order-product-summary .product-line-item .item-attributes .line-item-attributes,
  .account-page .order-product-summary .product-line-item .item-attributes .item-options {
    color: #595959;
    margin-top: 1px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 100%; }

.data-checkout-stage .order-product-summary .product-line-item .item-price-qty,
.receipt .order-product-summary .product-line-item .item-price-qty,
.account-page .order-product-summary .product-line-item .item-price-qty {
  border-top: none;
  padding-top: 24px; }
  .data-checkout-stage .order-product-summary .product-line-item .item-price-qty > .col,
  .receipt .order-product-summary .product-line-item .item-price-qty > .col,
  .account-page .order-product-summary .product-line-item .item-price-qty > .col {
    padding: 0; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-total-text,
.receipt .order-product-summary .product-line-item .line-item-total-text,
.account-page .order-product-summary .product-line-item .line-item-total-text {
  color: #595959; }

.data-checkout-stage .order-product-summary .product-line-item .line-item-total-price-amount,
.receipt .order-product-summary .product-line-item .line-item-total-price-amount,
.account-page .order-product-summary .product-line-item .line-item-total-price-amount {
  color: #000; }

.data-checkout-stage .order-product-summary .product-line-item ~ .store-details,
.receipt .order-product-summary .product-line-item ~ .store-details,
.account-page .order-product-summary .product-line-item ~ .store-details {
  margin-left: 12px;
  margin-bottom: 12px; }

.data-checkout-stage .order-product-summary .product-line-item ~ address,
.receipt .order-product-summary .product-line-item ~ address,
.account-page .order-product-summary .product-line-item ~ address {
  margin-left: 12px; }
  .data-checkout-stage .order-product-summary .product-line-item ~ address span,
  .receipt .order-product-summary .product-line-item ~ address span,
  .account-page .order-product-summary .product-line-item ~ address span {
    display: block; }

.data-checkout-stage .confirm-details .summary-section-label,
.receipt .confirm-details .summary-section-label,
.account-page .confirm-details .summary-section-label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0;
  opacity: 1;
  display: block;
  color: #0C0A08; }
  .data-checkout-stage .confirm-details .summary-section-label:not(.order-number-label),
  .receipt .confirm-details .summary-section-label:not(.order-number-label),
  .account-page .confirm-details .summary-section-label:not(.order-number-label) {
    margin-top: 24px; }

.data-checkout-stage .confirm-details .shipping-method-arrival-time,
.receipt .confirm-details .shipping-method-arrival-time,
.account-page .confirm-details .shipping-method-arrival-time {
  letter-spacing: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 1.5em;
  letter-spacing: 0;
  padding-top: 3px;
  margin-top: -3px;
  top: -1px;
  padding-left: 3px;
  left: -3px; }
  @media (min-width: 1024px) {
    .data-checkout-stage .confirm-details .shipping-method-arrival-time,
    .receipt .confirm-details .shipping-method-arrival-time,
    .account-page .confirm-details .shipping-method-arrival-time {
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0; } }

.data-checkout-stage .confirm-details .summary-details,
.data-checkout-stage .confirm-details .shipping-method,
.data-checkout-stage .confirm-details .pricing,
.receipt .confirm-details .summary-details,
.receipt .confirm-details .shipping-method,
.receipt .confirm-details .pricing,
.account-page .confirm-details .summary-details,
.account-page .confirm-details .shipping-method,
.account-page .confirm-details .pricing {
  color: #595959; }

.data-checkout-stage .confirm-details .card-body > .summary-details:last-child,
.receipt .confirm-details .card-body > .summary-details:last-child,
.account-page .confirm-details .card-body > .summary-details:last-child {
  padding-bottom: 41px; }

.receipt .confirm-details,
.receipt .confirm-details .card-header-custom,
.receipt .order-confirm-create-account .card-header-custom,
.account-page .confirm-details,
.account-page .confirm-details .card-header-custom,
.account-page .order-confirm-create-account .card-header-custom {
  margin-bottom: 0; }

.receipt .checkout-order-total-summary .order-total-summary {
  border-top: none; }

.additional-privacy-checkbox,
.additional-privacy-content {
  display: none; }

.checkout-registration .additional-privacy-checkbox {
  display: block; }

.payment-wrapper .paymentMethod {
  margin-bottom: 0; }

.btn-show-details {
  padding-right: 24px; }

#lb_klarna_account {
  font-weight: bold; }

#lb_paywithgoogle {
  font-weight: bold; }

#gift-card-block {
  background: #F8F8F8;
  padding: 0px 0px 0px 12px;
  border-top: 1px solid #D6D6D6; }
  @media only screen and (max-width: 960px) {
    #gift-card-block {
      padding-right: 12px; } }

#add-gift-card-btn {
  width: auto;
  height: 40px; }
  @media only screen and (max-width: 960px) {
    #add-gift-card-btn {
      width: 100%;
      padding-right: 12px; } }

.data-checkout-stage .order-product-summary .bonus-line-item-msg,
.receipt .order-product-summary .bonus-line-item-msg,
.account-page .order-product-summary .bonus-line-item-msg {
  padding-top: 6px;
  border-top: 1px solid #D6D6D6; }
  .data-checkout-stage .order-product-summary .bonus-line-item-msg + .nested-line-item,
  .receipt .order-product-summary .bonus-line-item-msg + .nested-line-item,
  .account-page .order-product-summary .bonus-line-item-msg + .nested-line-item {
    border: unset; }

.data-checkout-stage .bonus-product-line-item + .product-line-item,
.receipt .bonus-product-line-item + .product-line-item,
.account-page .bonus-product-line-item + .product-line-item {
  border-top: 1px solid #D6D6D6; }

.data-checkout-stage .card .card-body .edit-button {
  color: #0C0A08;
  font-weight: normal;
  text-decoration: underline;
  cursor: pointer;
  color: #0C0A08; }
  .data-checkout-stage .card .card-body .edit-button:hover {
    color: #2B2B2A;
    text-decoration: underline; }
  .dark-theme .data-checkout-stage .card .card-body .edit-button {
    color: #FFF; }
    .dark-theme .data-checkout-stage .card .card-body .edit-button:hover {
      color: #FFF; }
  .data-checkout-stage .card .card-body .edit-button:hover {
    color: #0C0A08; }

.data-checkout-stage .payment-options-block input[type=checkbox] {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  background-color: #FFF;
  border-radius: 5px; }
  .data-checkout-stage .payment-options-block input[type=checkbox]:checked:before {
    content: "";
    width: 20px;
    height: 20px;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 5px;
    position: absolute; }
  .data-checkout-stage .payment-options-block input[type=checkbox]:checked:after {
    content: "";
    width: 20px;
    height: 20px;
    line-height: calc(20px + 2px);
    background: none;
    font-family: "icomoon";
    font-size: 13.33333px;
    text-align: center;
    color: #FFF;
    background-color: #000;
    position: absolute; }

.data-checkout-stage .payment-options-block .paymentMethod input[type=radio] {
  content: "";
  width: 20px;
  height: 20px;
  border: 1px solid #000;
  border-radius: 50%;
  background-color: #FFF;
  vertical-align: middle; }
  @supports not (-webkit-touch-callout: none) {
    .data-checkout-stage .payment-options-block .paymentMethod input[type=radio] {
      accent-color: #000; } }
  .data-checkout-stage .payment-options-block .paymentMethod input[type=radio]:checked:before, .data-checkout-stage .payment-options-block .paymentMethod input[type=radio]:checked:after {
    content: "";
    background: none;
    border-radius: 50%;
    position: absolute; }
  .data-checkout-stage .payment-options-block .paymentMethod input[type=radio]:checked:before {
    width: 12px;
    height: 12px;
    background-color: #000;
    transform: translate(4px, 4px); }
  .data-checkout-stage .payment-options-block .paymentMethod input[type=radio]:checked:after {
    width: 20px;
    height: 20px;
    border: 1px solid #000; }

.data-checkout-stage .order-product-summary .product-line-item .price del ~ .sales {
  color: #BF242B; }

.data-checkout-stage .order-product-summary .remove-product {
  display: none; }

.data-checkout-stage[data-checkout-stage=placeOrder] .additional-privacy-checkbox,
.data-checkout-stage[data-checkout-stage=placeOrder] .additional-privacy-content {
  display: block; }

.data-checkout-stage[data-checkout-stage=payment] .shipping-summary .title::after,
.data-checkout-stage[data-checkout-stage=payment] .payment-summary .title::after, .data-checkout-stage[data-checkout-stage=placeOrder] .shipping-summary .title::after,
.data-checkout-stage[data-checkout-stage=placeOrder] .payment-summary .title::after {
  font-style: normal;
  font-size: 16px;
  right: 0; }

.data-checkout-stage .reset-password-form .request-password-body {
  margin-bottom: 12px; }

.data-checkout-stage klarna-placement {
  margin-top: 15px;
  display: block; }

/* Adyen form customization start **/
#adyenModalDialog {
  pointer-events: auto;
  background: white; }

.paymentMethod {
  list-style-type: none; }

.paymentMethod_img {
  margin: 0 0.25em 0 0.25em; }

.additionalFields {
  display: inline-block;
  margin: 0.5em;
  width: 100%; }

.adyen-checkout__input-wrapper .adyen-checkout__dropdown__list {
  z-index: 10; }

#paymentMethodsUl {
  width: 80%;
  padding-inline-start: 0;
  margin: auto; }

.paymentMethod .adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
  background-color: #000;
  border: 1px solid #000; }

/* Adyen form customization end **/
.paymentMethod a {
  text-decoration: none; }

.paymentMethod img.logo:first-of-type {
  margin-left: 18px; }

.payment-form .form-nav {
  display: none; }

.payment-form .tab-content > .tab-pane {
  display: block; }

.payment-form .tab-pane.tab-pane-form {
  display: none; }

.payment-form .tab-pane.active.tab-pane-form {
  display: block; }

.payment-form .applepay-radio {
  display: none; }

.payment-form #rb_scheme {
  margin-right: 18px; }

.payment-form #rb_scheme ~ img.paymentMethod_img {
  display: none; }

.payment-form #lb_scheme {
  display: none; }

.payment-form img.paymentMethod_img {
  margin-left: 18px;
  max-width: 50px; }

.checkout-progress-bar::before {
  content: '';
  width: 64%;
  border-bottom: 1px solid #D6D6D6;
  position: absolute;
  top: 8px; }

.checkout-progress-bar-step {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0; }
  @media (min-width: 1024px) {
    .checkout-progress-bar-step {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; } }
  .checkout-progress-bar-step .checkout-progress-bar-indicator {
    width: 18px;
    height: 18px;
    background-color: #D6D6D6;
    border-radius: 50px;
    z-index: 1; }
  .checkout-progress-bar-step.active .checkout-progress-bar-indicator {
    background-color: #000; }

.card.customer-section .card-body.checkout-customer-block {
  padding: 0; }
  .card.customer-section .card-body.checkout-customer-block .customer-information-block {
    margin-bottom: 12px; }
  .card.customer-section .card-body.checkout-customer-block .form-group.custom-checkbox {
    margin: 0 0 48px; }
  .card.customer-section .card-body.checkout-customer-block p,
  .card.customer-section .card-body.checkout-customer-block label {
    margin: 0; }
  .card.customer-section .card-body.checkout-customer-block .registered-form .remember-me {
    margin-top: 12px; }
    .card.customer-section .card-body.checkout-customer-block .registered-form .remember-me label {
      letter-spacing: 0;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 1.5em;
      letter-spacing: 0;
      color: inherit; }
      @media (min-width: 1024px) {
        .card.customer-section .card-body.checkout-customer-block .registered-form .remember-me label {
          font-size: 12px;
          line-height: 1.5em;
          letter-spacing: 0; } }
  .card.customer-section .card-body.checkout-customer-block .registered-form .forgot-password {
    margin-top: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    margin-bottom: 6px;
    text-transform: none;
    letter-spacing: 0;
    opacity: 1; }
  .card.customer-section .card-body.checkout-customer-block .registered-form .submit-customer-login {
    margin-top: 24px; }

.receipt .order-product-summary .product-line-item .price del ~ .sales {
  color: #BF242B; }

.customer-signin-container {
  border-bottom: 1px solid #C5C5C5; }
  .customer-signin-container strong {
    font-weight: 500; }
  .customer-signin-container .customer-signin-btn {
    color: #0C0A08;
    text-decoration: underline;
    cursor: pointer;
    vertical-align: baseline;
    border: none;
    padding: 24px 0 24px 0; }

.card.customer-section .card-header h2.card-header-custom,
.card.customer-section .card-header h5.card-header-custom {
  padding: 48px 0 24px 0; }

.checkout-customer-container {
  border-bottom: 1px solid #C5C5C5;
  padding-bottom: 16px; }
  @media (min-width: 769px) {
    .checkout-customer-container {
      border-bottom: none;
      padding-bottom: 0; } }

.custom-checkbox-container a, .custom-checkbox-container .text-link {
  font-weight: bold; }

footer {
  margin-top: 0; }

#checkout-main.container {
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 0 !important;
  max-width: 1440px; }

.checkout-progress-bar {
  margin: -32px -24px;
  width: 100vw;
  padding: 24px 0;
  background: #f7f7f7;
  margin-bottom: 24px;
  border: 1px solid #dfdfdf;
  border-top: 0; }

.checkout-progress-bar::before {
  content: '';
  width: 67%;
  top: 32px; }

.card .card-header ~ .card-body {
  padding: 0 0 20px 0; }

.customer-information-block .sign-in,
.customer-information-block .sign-in button {
  font-size: 14px;
  font-weight: 500; }

.customer-information-block .form-group.required {
  margin-bottom: 0; }

h1.checkout-page-header {
  margin-bottom: 24px; }

.card h2.card-header-custom,
.card h5.card-header-custom {
  padding: 24px 0; }

.custom-radio .custom-control-label,
.custom-checkbox .custom-control-label {
  vertical-align: top; }

.info-icon,
.tooltip-icon {
  top: 0px; }

.card .card-header .pull-right {
  padding-right: 0; }

/* Shipping Block */
.data-checkout-stage[data-checkout-stage=customer] .shipping-address-block:not(.d-none) + .shipping-method-block {
  margin-top: 24px; }

.data-checkout-stage[data-checkout-stage=shipping] .shipping-address-block:not(.d-none) + .shipping-method-block {
  border-top: 1px solid #dedede;
  margin-top: 24px;
  padding-top: 24px; }

.shipping-method-block > h5 {
  margin-bottom: 12px; }

.shipping-address-block {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 24px; }

.gc-button-wrapper {
  padding-bottom: 12px; }

/* Order Info */
#checkout-main > .row > .col-md-5 {
  margin-left: -18px;
  margin-right: -18px;
  width: 100vw;
  padding: 0 24px 24px;
  border: 1px solid #dfdfdf; }

.shipping-content {
  border-bottom: 0 !important; }

#checkout-main > .row > .col-md-5,
#checkout-main > .row > .col-md-5 .card .card-body,
#checkout-main > .row > .col-md-5 .card .card-header,
#checkout-main > .row > .col-md-5 .leading-lines .start-lines span,
#checkout-main > .row > .col-md-5 .leading-lines .end-lines span {
  background: #f7f7f7; }

#checkout-main > .row > .col-md-5 .card-header-custom {
  padding-top: 24px; }

#checkout-customer-service .container {
  padding: 0; }

#checkout-customer-service .container .experience-component > div {
  padding: 24px 0 0 0 !important; }

/* Payment Method */
#paymentMethodsList {
  padding-left: 0;
  margin: 0; }
  #paymentMethodsList .additionalFields {
    margin: 12px 0; }
    #paymentMethodsList .additionalFields .adyen-checkout__card-input {
      background: #F8F8F8;
      padding: 12px;
      margin: 0 -24px -36px;
      border-top: 1px solid #F8F8F8; }
  #paymentMethodsList label {
    font-size: 14px;
    margin-bottom: 0; }
  #paymentMethodsList .paymentMethod {
    padding: 24px;
    border-bottom: 1px solid #D6D6D6; }

.payment-instrument-wrapper {
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  order: 2;
  margin-top: 10px; }
  .payment-instrument-wrapper > ul {
    margin: 0; }
  .payment-instrument-wrapper .paymentMethod.applepay, .payment-instrument-wrapper .paymentMethod.paypal {
    padding: 24px; }

.payment-method-container {
  display: flex;
  flex-direction: column; }

.gift-card-billing {
  border: 1px solid #D6D6D6;
  border-radius: 10px;
  overflow: hidden; }
  .data-checkout-stage .gift-card-billing .card-header {
    padding: 15px 12px;
    display: flex;
    position: relative; }
    .data-checkout-stage .gift-card-billing .card-header::after {
      top: 50%;
      transform: translateY(-50%); }
  .gift-card-billing.collapsible-xl .title:not(.active):not(.aside):not(.no-borders) {
    border-bottom: none; }
  .gift-card-billing .gc-fox-logo {
    background: black;
    width: 52px;
    height: 28px;
    border-radius: 3px;
    display: flex;
    flex-grow: 0;
    align-items: center; }
    .gift-card-billing .gc-fox-logo .foxhead {
      height: 20px; }
  .gift-card-billing .gc-header {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    padding-left: 12px; }
  .gift-card-billing.payment-form.active .card-body {
    border-bottom: none; }

.gift-cert-input {
  width: 50%;
  height: 40px; }
  @media only screen and (max-width: 960px) {
    .gift-cert-input {
      width: 100%;
      padding-right: 12px; } }

.cc-header {
  display: inline-block;
  font-size: 14px;
  font-weight: bold; }

.cc-types {
  display: inline-block;
  vertical-align: top;
  margin-left: 12px; }

.adyen-option {
  max-width: 30px;
  height: auto; }

.credit-card-selection-new ul {
  padding-left: 0; }

fieldset.address-selector-block .btn {
  text-align: left !important; }

.card .card-header .pull-right.edit-button {
  padding-top: 24px;
  padding-right: 0; }

@media screen and (min-width: 769px) {
  #checkout-main.container {
    padding: 24px; }
  #checkout-main > .row {
    margin-right: 0; }
  .checkout-progress-bar {
    margin: -32px -24px 48px;
    width: 100vw;
    max-width: 1920px; }
  #checkout-main > .row > .col-md-7 {
    padding-right: 48px; }
  #checkout-main > .row > .col-md-5 {
    margin-left: 0;
    align-self: flex-start;
    margin-right: 0;
    padding: 0 24px 24px; }
  .next-step-button {
    margin-bottom: 24px; } }

@media screen and (min-width: 1024px) {
  #checkout-main.container {
    padding: 48px; }
  .checkout-progress-bar {
    margin: -32px 0 48px;
    width: 100vw;
    max-width: 100%; } }

.pfas-error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  background: #FC0;
  margin-bottom: 24px;
  padding: 24px 0; }
  @media (min-width: 769px) {
    .pfas-error-container {
      padding: 48px 0; } }
  .pfas-error-container .pfas-message-container {
    max-width: 1440px; }
    @media (min-width: 1024px) {
      .pfas-error-container .pfas-message-container {
        padding: 0 48px; } }
  .pfas-error-container .pfas-error-text {
    color: #000;
    margin-bottom: 12px; }
  .pfas-error-container .pfas-error-buttons {
    display: flex;
    gap: 12px;
    margin-top: 24px;
    white-space: nowrap; }
  .pfas-error-container .btn-pfas-cart {
    height: 30px;
    padding: 6px 20px;
    line-height: 18px; }
  .pfas-error-container .btn-change-address {
    background-color: transparent;
    border-color: #000;
    height: 30px;
    padding: 6px 20px;
    line-height: 18px; }
  .pfas-error-container .pfas-error-underlined {
    text-decoration: underline; }
  .pfas-error-container .pfas-error-subtitle {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: normal; }

.pfas-product-error-container {
  padding: 12px 24px;
  background: #FC0; }
  .pfas-product-error-container .pfas-product-card-message {
    color: #000;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 140%; }

button.dw-apple-pay-button.applePaySpecificityOverride {
  width: 100%;
  display: block;
  padding: 12px 20px 12px 20px;
  margin-bottom: 12px;
  border-radius: 50px; }

.contact-us-landing-page {
  padding-top: 12px;
  padding-bottom: 12px; }
  @media (min-width: 1024px) {
    .contact-us-landing-page .col-12 > .card:only-child .card-body > form {
      width: 750px; } }
  .contact-us-landing-page .card {
    margin-top: 12px; }
  .contact-us-landing-page .subscribe-contact-us {
    margin-top: 10px; }

.contact-us-signup-message {
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  position: fixed;
  top: 0;
  padding: 50px 12px 12px;
  max-height: calc(100vh - 50px);
  overflow-y: auto;
  z-index: 1100; }

.contact-us-signup-alert {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  text-align: center;
  padding: 20px 30px; }
  .contact-us-signup-alert.show {
    display: block; }
  .contact-us-signup-alert:not(.alert-dismissible) {
    animation: fadeIn 200ms, fadeOut 200ms linear calc(5s - 200ms); }
  .contact-us-signup-alert.alert-dismissible {
    animation: fadeIn 200ms;
    padding-right: 50px; }
    .contact-us-signup-alert.alert-dismissible .close {
      padding: 12px; }

.product-wrapper.product-detail {
  margin-bottom: 0; }
  @media (min-width: 769px) {
    .product-wrapper.product-detail .product-container-wrapper {
      display: flex;
      gap: 48px;
      justify-content: center; } }
  .product-wrapper.product-detail .product-data {
    margin-bottom: 12px; }
    @media (min-width: 769px) {
      .product-wrapper.product-detail .product-data {
        width: 350px;
        max-width: 350px;
        min-width: 350px; } }
    @media (min-width: 1024px) {
      .product-wrapper.product-detail .product-data {
        width: 421px;
        max-width: 421px;
        min-width: 350px; } }
  .product-wrapper.product-detail .badge-product-container {
    margin-bottom: 24px; }
    .product-wrapper.product-detail .badge-product-container .badge-product.badge_sale {
      border-color: #000; }
  .product-wrapper.product-detail .product-detail-review-container {
    margin-bottom: 18px; }
    .product-wrapper.product-detail .product-detail-review-container a {
      display: flex;
      text-decoration: none; }
    .product-wrapper.product-detail .product-detail-review-container.lower:has(~ .product-explicit-recommendations) {
      margin-bottom: 0; }
    .product-wrapper.product-detail .product-detail-review-container.lower a {
      padding: 24px 0;
      border-bottom: 1px solid #D6D6D6;
      justify-content: space-between; }
  .product-wrapper.product-detail .product-rating-count {
    display: none; }
  .product-wrapper.product-detail .product-rating-stars {
    display: flex;
    margin-left: 6px; }
    .product-wrapper.product-detail .product-rating-stars .rating-icon {
      display: block;
      width: 15px;
      height: 15px;
      background-size: contain;
      background-repeat: no-repeat; }
    .product-wrapper.product-detail .product-rating-stars .rating-icon-star {
      background-image: url("../images/icons/icon-star.svg"); }
    .product-wrapper.product-detail .product-rating-stars .rating-icon-star-half {
      background-image: url("../images/icons/icon-star-half.svg"); }
    .product-wrapper.product-detail .product-rating-stars .rating-icon-star-empty {
      background-image: url("../images/icons/icon-star-empty.svg"); }
  .product-wrapper.product-detail .product-name {
    color: #000;
    margin-bottom: 24px; }
    .product-wrapper.product-detail .product-name a {
      text-decoration: none; }
  .product-wrapper.product-detail .prices {
    margin-top: 0;
    margin-bottom: 24px; }
    .product-wrapper.product-detail .prices .price {
      font-family: "archia-medium", sans-serif;
      font-size: 16px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal;
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (min-width: 1024px) {
        .product-wrapper.product-detail .prices .price {
          font-size: 16px;
          line-height: 1.5em;
          font-weight: 400;
          letter-spacing: 0;
          text-transform: none;
          font-style: normal; } }
    .product-wrapper.product-detail .prices .price-discount {
      letter-spacing: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
      border: 1px solid #BF242B;
      background-color: #BF242B;
      color: #FFF;
      font-weight: 400;
      padding: 4px 16px;
      border-radius: 3px;
      text-transform: uppercase; }
      @media (min-width: 1024px) {
        .product-wrapper.product-detail .prices .price-discount {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0; } }
  .product-wrapper.product-detail .product-number-rating {
    line-height: 1;
    margin-bottom: 12px; }
  .product-wrapper.product-detail .ratings {
    margin-left: 0;
    display: flex;
    align-items: center; }
    .product-wrapper.product-detail .ratings .ratings-link {
      padding-right: 7px; }
      @media (min-width: 769px) {
        .product-wrapper.product-detail .ratings .ratings-link {
          padding-right: 17px; } }
      .product-wrapper.product-detail .ratings .ratings-link:hover {
        opacity: .5; }
  .product-wrapper.product-detail .product-number {
    font-family: "archia-medium", sans-serif;
    color: #595959;
    margin-bottom: 24px;
    font-weight: 500; }
  .product-wrapper.product-detail .product-detail-attributes,
  .product-wrapper.product-detail .bundle-items .attributes {
    border-top: 1px solid #D6D6D6;
    padding-top: 24px; }
    .product-wrapper.product-detail .product-detail-attributes .attribute,
    .product-wrapper.product-detail .bundle-items .attributes .attribute {
      margin-bottom: 24px; }
    .product-wrapper.product-detail .product-detail-attributes .non-input-label,
    .product-wrapper.product-detail .bundle-items .attributes .non-input-label {
      margin-bottom: 12px; }
      .product-wrapper.product-detail .product-detail-attributes .non-input-label.size,
      .product-wrapper.product-detail .bundle-items .attributes .non-input-label.size {
        display: flex;
        width: 100%;
        justify-content: space-between; }
        .product-wrapper.product-detail .product-detail-attributes .non-input-label.size .non-color-display-value,
        .product-wrapper.product-detail .bundle-items .attributes .non-input-label.size .non-color-display-value {
          flex-grow: 2; }
  .product-wrapper.product-detail .swatch-tile-container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px; }
    .product-wrapper.product-detail .swatch-tile-container.color-tile {
      width: calc(100% + (12px * 4));
      margin: 0 -24px;
      padding: 0 24px;
      overflow-x: scroll;
      flex-wrap: nowrap; }
      @media (min-width: 769px) {
        .product-wrapper.product-detail .swatch-tile-container.color-tile {
          overflow-x: unset;
          flex-wrap: wrap; } }
  .product-wrapper.product-detail .swatch.color-value, .product-wrapper.product-detail .swatch.size-value {
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc((100vw / 5) - 14px);
    height: calc((100vw / 5) - 14px);
    border: 1px solid #D6D6D6;
    border-radius: 3px;
    background-size: 50px 50px;
    background-repeat: no-repeat;
    background-position: 50% 50%; }
    @media (min-width: 769px) {
      .product-wrapper.product-detail .swatch.color-value, .product-wrapper.product-detail .swatch.size-value {
        width: 66px;
        height: 66px; } }
    .product-wrapper.product-detail .swatch.color-value.selected, .product-wrapper.product-detail .swatch.size-value.selected {
      border-color: #000; }
    .product-wrapper.product-detail .swatch.color-value.bis-enabled.unavailable, .product-wrapper.product-detail .swatch.size-value.bis-enabled.unavailable {
      position: relative; }
      .product-wrapper.product-detail .swatch.color-value.bis-enabled.unavailable::before, .product-wrapper.product-detail .swatch.size-value.bis-enabled.unavailable::before {
        display: inline-block;
        content: '';
        position: absolute;
        top: 3px;
        left: 4px;
        width: 17px;
        height: 17px;
        background-image: url("../images/icons/bell.svg");
        background-size: contain;
        background-repeat: no-repeat; }
      .product-wrapper.product-detail .swatch.color-value.bis-enabled.unavailable::after, .product-wrapper.product-detail .swatch.size-value.bis-enabled.unavailable::after {
        display: inline-block;
        content: '';
        border-top: 1px solid #D6D6D6;
        width: calc(((100vw / 5) - 18px) * 1.44);
        position: absolute;
        transform: rotate(-45deg); }
        @media (min-width: 769px) {
          .product-wrapper.product-detail .swatch.color-value.bis-enabled.unavailable::after, .product-wrapper.product-detail .swatch.size-value.bis-enabled.unavailable::after {
            width: 90.04px; } }
  .product-wrapper.product-detail .swatch.color-value {
    background-color: #F8F8F8; }
  .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::before, .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::after {
    display: inline-block;
    content: '';
    border-top: 1px solid #D6D6D6;
    width: calc(((100vw / 5) - 18px) * 1.44);
    position: absolute; }
    @media (min-width: 769px) {
      .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::before, .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::after {
        width: 90.04px; } }
  .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::before {
    transform: rotate(45deg); }
  .product-wrapper.product-detail .swatch.size-value.unavailable:not(.bis-enabled)::after {
    transform: rotate(-45deg); }
  .product-wrapper.product-detail .size-chart a.size-chart-launcher,
  .product-wrapper.product-detail .size-chart a.size-chart-anchor {
    position: relative;
    color: #595959;
    padding-bottom: 3px; }
    .product-wrapper.product-detail .size-chart a.size-chart-launcher::before,
    .product-wrapper.product-detail .size-chart a.size-chart-anchor::before {
      display: inline-block;
      content: '';
      width: 12px;
      height: 12px;
      background-image: url("../images/icons/ruler.svg");
      background-repeat: no-repeat;
      background-size: contain; }
  .product-wrapper.product-detail .shipping-returns-callout-bottom {
    border-bottom: 1px solid #D6D6D6;
    margin-bottom: 24px; }
  .product-wrapper.product-detail .shipping-return-callout-wrapper {
    border-top: 1px solid #D6D6D6;
    padding: 24px 0; }
    .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-icon-wrapper {
      display: inline-block;
      width: 30px;
      flex-shrink: 0;
      margin-bottom: auto; }
    .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-message-wrapper {
      display: inline-block; }
    .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-shipping-wrapper, .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-returns-wrapper {
      display: flex; }
    .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-shipping-wrapper {
      margin-bottom: 12px; }
    .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-text {
      color: #000;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-style: normal;
      font-weight: 400; }
      @media (min-width: 769px) {
        .product-wrapper.product-detail .shipping-return-callout-wrapper .callout-text {
          font-size: 14px; } }
  .product-wrapper.product-detail .cart-and-ipay {
    margin-bottom: 24px; }
    .product-wrapper.product-detail .cart-and-ipay .add-to-cart,
    .product-wrapper.product-detail .cart-and-ipay .add-to-cart-global,
    .product-wrapper.product-detail .cart-and-ipay .select-size {
      width: 100%; }
  .product-wrapper.product-detail .bis-container {
    background-color: #ECEEE4;
    border: 1px solid #D8DDC9;
    padding: 24px 12px;
    margin-bottom: 24px; }
    .product-wrapper.product-detail .bis-container .form-group {
      margin: 0; }
    .product-wrapper.product-detail .bis-container .bis-headline,
    .product-wrapper.product-detail .bis-container .email-description,
    .product-wrapper.product-detail .bis-container .bis-email-input,
    .product-wrapper.product-detail .bis-container .bis-consent-label {
      margin-bottom: 12px; }
    .product-wrapper.product-detail .bis-container button.notify-back-in-stock {
      width: 100%; }
    .product-wrapper.product-detail .bis-container .bis-signup-success i {
      font-size: 16px;
      vertical-align: text-bottom; }
  .product-wrapper.product-detail .promotions-wrapper {
    margin-bottom: 24px; }
  .product-wrapper.product-detail .promotion {
    font-family: "archia-medium", sans-serif;
    text-align: center;
    border: 1px solid #D6D6D6;
    padding: 24px; }
    .product-wrapper.product-detail .promotion + .promotion {
      margin-top: 24px; }
  .product-wrapper.product-detail .primary-images {
    position: relative;
    max-width: 852px;
    width: 100%; }
    .product-wrapper.product-detail .primary-images .primary-images-main {
      width: calc(100% + (12px * 4));
      margin: 0 -24px 24px;
      padding: 0 24px;
      overflow-x: scroll;
      display: flex;
      flex-wrap: nowrap;
      column-gap: 6px; }
      @media (min-width: 769px) {
        .product-wrapper.product-detail .primary-images .primary-images-main {
          width: calc(100% - 12px);
          margin: 0;
          padding: 0;
          overflow: unset;
          display: grid;
          grid-template-columns: repeat(1, 100%);
          gap: 12px; } }
      @media (min-width: 1024px) {
        .product-wrapper.product-detail .primary-images .primary-images-main {
          grid-template-columns: repeat(2, 50%); }
          .product-wrapper.product-detail .primary-images .primary-images-main.image-count-1, .product-wrapper.product-detail .primary-images .primary-images-main.image-count-2, .product-wrapper.product-detail .primary-images .primary-images-main.image-count-3 {
            grid-template-columns: repeat(1, 100%); } }
      .product-wrapper.product-detail .primary-images .primary-images-main .image-wrapper {
        max-width: 100%;
        max-height: 100%;
        overflow: hidden; }
        .product-wrapper.product-detail .primary-images .primary-images-main .image-wrapper.zoomed-out {
          cursor: url("../images/icons/minus.svg") 16 16, pointer; }
        .product-wrapper.product-detail .primary-images .primary-images-main .image-wrapper.zoomed-in {
          cursor: url("../images/icons/plus.svg") 16 16, pointer; }
        .product-wrapper.product-detail .primary-images .primary-images-main .image-wrapper img {
          transition: transform 0.4s ease-out 0s; }
    .product-wrapper.product-detail .primary-images img,
    .product-wrapper.product-detail .primary-images video {
      background-color: #F8F8F8; }
    .product-wrapper.product-detail .primary-images .image-arrow {
      position: absolute;
      top: 6px;
      right: 6px;
      width: 22px;
      height: 22px;
      background-image: url("../images/icons/arrow.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center center; }
    .product-wrapper.product-detail .primary-images video.video-player {
      width: 100%;
      height: 100%; }
    .product-wrapper.product-detail .primary-images .video-play-button {
      position: absolute;
      bottom: 12px;
      right: 12px;
      z-index: 2; }
      .product-wrapper.product-detail .primary-images .video-play-button .video-play-text {
        display: inline-block;
        height: 32px;
        border-radius: 50px;
        background-color: #FFF;
        color: #595959;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 500;
        padding: 5px 20px 5px 40px; }
      .product-wrapper.product-detail .primary-images .video-play-button .video-play-icon {
        position: relative; }
      .product-wrapper.product-detail .primary-images .video-play-button .video-play-icon::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 3px;
        left: 15px;
        z-index: 2;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 5px 0 5px 9px;
        border-color: transparent transparent transparent #FFF; }
      .product-wrapper.product-detail .primary-images .video-play-button .video-play-icon::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: -2px;
        left: 8px;
        height: 21px;
        width: 21px;
        background-color: #595959;
        border-radius: 50%; }
    .product-wrapper.product-detail .primary-images .tns-slider .zoomImg {
      background-color: #FFF; }
  .product-wrapper.product-detail .stock-indicator {
    display: none;
    color: #595959;
    margin-bottom: 12px; }
    .product-wrapper.product-detail .stock-indicator.visible {
      display: block; }
    .product-wrapper.product-detail .stock-indicator.low-stock .icon-hurry {
      display: inline-block; }
    .product-wrapper.product-detail .stock-indicator .icon-hurry {
      display: none; }
  .product-wrapper.product-detail .error {
    color: #BF242B;
    font-style: italic; }
  .product-wrapper.product-detail .accordion {
    border-top: 1px solid #D6D6D6; }
    .product-wrapper.product-detail .accordion .accordion-item {
      border-bottom: 1px solid #D6D6D6; }
    .product-wrapper.product-detail .accordion .accordion-header {
      margin-bottom: 0; }
      .product-wrapper.product-detail .accordion .accordion-header a {
        display: flex;
        align-items: center;
        padding: 24px 0;
        text-decoration: none;
        width: 100%;
        position: relative; }
        .product-wrapper.product-detail .accordion .accordion-header a::after {
          display: inline-block;
          content: '-';
          position: absolute;
          right: 6px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 22px; }
        .product-wrapper.product-detail .accordion .accordion-header a.collapsed::after {
          content: '+'; }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-gear, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-info, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-ruler, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-drop, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-box, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-meter, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-check, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-key, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-shield, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-puzzle {
        padding-left: 32px;
        position: relative; }
        .product-wrapper.product-detail .accordion .accordion-header.tab-icon-gear::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-info::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-ruler::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-drop::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-box::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-meter::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-check::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-key::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-shield::before, .product-wrapper.product-detail .accordion .accordion-header.tab-icon-puzzle::before {
          content: '';
          display: inline-block;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 17px;
          height: 17px;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center; }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-gear::before {
        background-image: url("../images/icons/cog.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-info::before {
        background-image: url("../images/icons/info.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-ruler::before {
        background-image: url("../images/icons/ruler.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-drop::before {
        background-image: url("../images/icons/drop.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-box::before {
        background-image: url("../images/icons/box.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-meter::before {
        background-image: url("../images/icons/meter.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-check::before {
        background-image: url("../images/icons/check.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-key::before {
        background-image: url("../images/icons/key.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-shield::before {
        background-image: url("../images/icons/shield.svg"); }
      .product-wrapper.product-detail .accordion .accordion-header.tab-icon-puzzle::before {
        background-image: url("../images/icons/puzzle.svg"); }
    .product-wrapper.product-detail .accordion .accordion-content {
      background-color: #f6f6f6;
      padding: 24px 12px; }
  .product-wrapper.product-detail .bundle-description {
    margin-bottom: 24px; }
  .product-wrapper.product-detail.product-bundle .prices {
    border-bottom: none;
    padding-bottom: 0; }
  .product-wrapper.product-detail.product-bundle .primary-images img {
    padding: 0; }
  .product-wrapper.product-detail .bundle-items {
    margin-bottom: 24px; }
    .product-wrapper.product-detail .bundle-items .attributes {
      border-top: none; }
    .product-wrapper.product-detail .bundle-items .checkmark {
      width: 25px;
      height: 25px;
      border: 1px solid #D6D6D6;
      border-radius: 50%;
      display: inline-block;
      position: relative;
      margin-right: 12px; }
      .product-wrapper.product-detail .bundle-items .checkmark.active {
        background-color: #000;
        border-color: #000; }
        .product-wrapper.product-detail .bundle-items .checkmark.active::before {
          content: '';
          display: inline-block;
          width: 12px;
          height: 6px;
          border: 2px solid transparent;
          border-left-color: #FFF;
          border-bottom-color: #FFF;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -75%) rotate(-45deg); }
    .product-wrapper.product-detail .bundle-items .add-to-cart-global,
    .product-wrapper.product-detail .bundle-items .select-size {
      width: 100%; }
  .product-wrapper.product-detail .bundle-item .primary-images .primary-images-main {
    display: block;
    margin-bottom: 0; }
  .product-wrapper.product-detail .bundle-item .mystery-item-image {
    width: calc(100% + (12px * 4));
    display: flex;
    background-color: #D8DDC9; }
    .product-wrapper.product-detail .bundle-item .mystery-item-image img {
      width: 100%; }
    @media (min-width: 769px) {
      .product-wrapper.product-detail .bundle-item .mystery-item-image {
        width: 350px;
        height: 350px; } }
    @media (min-width: 1024px) {
      .product-wrapper.product-detail .bundle-item .mystery-item-image {
        width: 421px;
        height: 421px; } }
  .product-wrapper.product-detail .bundle-item .product-name {
    margin: 24px 0 0; }
  .product-wrapper.product-detail .bundle-item .size.non-input-label {
    margin-bottom: 12px;
    display: inline-block; }
  .product-wrapper.product-detail .vlt-container {
    border: 1px solid #F8F8F8;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
    padding: 12px; }
    .product-wrapper.product-detail .vlt-container .vlt-title {
      letter-spacing: 0;
      font-size: 14px;
      line-height: 1.5em;
      letter-spacing: 0;
      font-family: "archia-bold", sans-serif;
      border-bottom: 1px solid #F8F8F8;
      padding-bottom: 12px; }
      @media (min-width: 1024px) {
        .product-wrapper.product-detail .vlt-container .vlt-title {
          font-size: 14px;
          line-height: 1.5em;
          letter-spacing: 0; } }
    .product-wrapper.product-detail .vlt-container .vlt-tiles {
      display: flex;
      flex-direction: column;
      gap: 12px; }
    .product-wrapper.product-detail .vlt-container .vlt-tile {
      padding: 6px 0;
      align-items: center;
      display: flex;
      flex-direction: row;
      gap: 12px; }
      .product-wrapper.product-detail .vlt-container .vlt-tile .vlt-tile-type {
        margin-right: auto; }
      .product-wrapper.product-detail .vlt-container .vlt-tile .vlt-tile-value {
        letter-spacing: 0;
        font-size: 14px;
        line-height: 1.5em;
        letter-spacing: 0;
        align-content: center;
        border: 1px solid #000;
        border-radius: 50%;
        height: 24px;
        margin-bottom: 0;
        text-align: center;
        width: 24px; }
        @media (min-width: 1024px) {
          .product-wrapper.product-detail .vlt-container .vlt-tile .vlt-tile-value {
            font-size: 14px;
            line-height: 1.5em;
            letter-spacing: 0; } }
      .product-wrapper.product-detail .vlt-container .vlt-tile .vlt-tile-text {
        letter-spacing: 0;
        font-family: "Roboto", sans-serif;
        font-size: 12px;
        line-height: 1.5em;
        letter-spacing: 0;
        margin-bottom: 0;
        text-align: center; }
        @media (min-width: 1024px) {
          .product-wrapper.product-detail .vlt-container .vlt-tile .vlt-tile-text {
            font-size: 12px;
            line-height: 1.5em;
            letter-spacing: 0; } }
  .product-wrapper.product-detail .compliance-callout {
    font-weight: bold;
    margin-bottom: 24px;
    text-decoration: underline; }

.product-details-tabs {
  background-color: #000; }
  .product-details-tabs .nav-link {
    font: 20px "archia-medium", sans-serif;
    text-transform: uppercase;
    font-weight: bold;
    text-decoration: none;
    letter-spacing: 0;
    padding: 15px 12px 11px 12px;
    border-radius: 0 0 0 0;
    background-color: transparent;
    color: #FFF;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-color: #E6FF00; }
    .product-details-tabs .nav-link:hover {
      text-decoration: none;
      background-color: transparent;
      color: #FFF;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #E6FF00;
      border-left: none; }
    .product-details-tabs .nav-link.active {
      text-decoration: none;
      background-color: transparent;
      color: #FFF;
      border-top: none;
      border-right: none;
      border-bottom: 2px solid #E6FF00;
      border-left: none; }
  .product-details-tabs .product-has-thumbnails {
    padding-left: 0; }
  .product-details-tabs .tabs {
    padding-top: 75px;
    padding-bottom: 75px;
    margin-bottom: 0; }
    .product-details-tabs .tabs .nav-tabs {
      justify-content: center;
      padding-bottom: 45px; }
      @media (min-width: 769px) {
        .product-details-tabs .tabs .nav-tabs {
          padding-bottom: 80px; } }
      .product-details-tabs .tabs .nav-tabs .nav-item:first-child {
        margin: 0; }
      .product-details-tabs .tabs .nav-tabs .nav-link {
        text-transform: uppercase; }
        .product-details-tabs .tabs .nav-tabs .nav-link.active {
          border-bottom: 2px solid #E6FF00; }
    .product-details-tabs .tabs .tab-content {
      color: #FFF;
      margin: 0 auto;
      max-width: 1100px; }
      .product-details-tabs .tabs .tab-content p {
        font-size: 14px; }

.product-reviews-section {
  padding-top: 40px; }

.klarna-callout {
  margin-bottom: 24px; }

@media (max-width: 768.98px) {
  .modal-footer.row {
    margin: 0; }
  .global-availability {
    flex: 100%;
    text-align: center; }
    .global-availability label {
      display: none; } }

.set-items .product-number-rating {
  display: none; }

.quick-view-dialog .attribute.quantity {
  margin-top: 16px; }
  .quick-view-dialog .attribute.quantity .quantity-select {
    width: 60px; }

.set-items .select-size {
  padding-top: 12px; }

.not-returnable-text {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #BF242B;
  margin-top: 16px; }

.product-explicit-recommendations {
  padding: 24px 0; }
  .product-explicit-recommendations .recommendations-title {
    margin: 0;
    padding: 24px 0; }
  .product-explicit-recommendations .recommendations-container {
    border: 1px solid #D6D6D6;
    border-bottom: 0; }
  .product-explicit-recommendations .recommendation {
    border-bottom: 1px solid #D6D6D6;
    display: flex; }
    .product-explicit-recommendations .recommendation .recommendation-image {
      align-self: center;
      aspect-ratio: 1;
      height: 124px;
      width: 124px;
      background-color: #F8F8F8; }
      .product-explicit-recommendations .recommendation .recommendation-image img {
        height: 100%;
        aspect-ratio: 1; }
    .product-explicit-recommendations .recommendation .recommendation-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      padding: 12px; }
    .product-explicit-recommendations .recommendation .recommendation-name {
      flex-grow: 1; }
    .product-explicit-recommendations .recommendation .recommendation-colors {
      color: #595959; }
    .product-explicit-recommendations .recommendation .recommendation-row {
      display: flex; }
    .product-explicit-recommendations .recommendation .recommendation-price {
      align-self: center;
      flex-grow: 1; }
      .product-explicit-recommendations .recommendation .recommendation-price .price {
        color: #2B2B2A; }
      .product-explicit-recommendations .recommendation .recommendation-price .price > span {
        display: flex; }
        .product-explicit-recommendations .recommendation .recommendation-price .price > span del {
          order: 1; }
        .product-explicit-recommendations .recommendation .recommendation-price .price > span span.sales {
          margin-left: 0;
          margin-right: 12px;
          order: 0; }
    .product-explicit-recommendations .recommendation .recommendation-cta {
      align-self: center; }
      .product-explicit-recommendations .recommendation .recommendation-cta .recommendation-button {
        border-radius: 4px;
        padding: 6px 12px; }

.search-nav {
  margin-top: 12px;
  margin-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid #D6D6D6; }
  .search-nav.hide-search-tabs {
    display: none; }
  .search-nav .nav-tabs-wrapper {
    padding: 0;
    width: 100%; }
    .search-nav .nav-tabs-wrapper .nav-link.active {
      background-color: #FFF; }
    @media (min-width: 544px) {
      .search-nav .nav-tabs-wrapper {
        width: auto; } }

.search-results .show-more,
.search-results .show-more-content {
  padding: 12px 24px;
  clear: both; }

.search-results .search-keywords {
  font-weight: bold; }

.search-results .category-item {
  margin-bottom: 12px; }

.search-results .tab-pane.active:focus {
  outline: none; }

.grid-header,
.content-grid-header {
  margin-bottom: 12px; }
  @media (max-width: 1023.98px) {
    .grid-header,
    .content-grid-header {
      margin-bottom: 24px; } }
  @media (min-width: 1024px) {
    .grid-header,
    .content-grid-header {
      margin-bottom: 0; } }
  .grid-header .breadcrumb-container > .row,
  .content-grid-header .breadcrumb-container > .row {
    align-items: center;
    height: 100%; }
  @media (min-width: 1024px) {
    .grid-header .breadcrumb,
    .content-grid-header .breadcrumb {
      margin-bottom: 0; } }
  .grid-header .result-count p,
  .content-grid-header .result-count p {
    margin-bottom: 12px; }
    @media (min-width: 544px) {
      .grid-header .result-count p,
      .content-grid-header .result-count p {
        margin-bottom: 0; } }
  .grid-header select,
  .content-grid-header select {
    width: 100%; }

.filter-header {
  margin-left: -12px;
  margin-right: -12px; }

.header-bar {
  background-color: #2B2B2A;
  border-bottom: 1px solid transparent;
  margin-top: 12px; }
  .header-bar .result-count {
    font-family: "archia-medium", sans-serif;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 400;
    letter-spacing: 0;
    text-transform: none;
    font-style: normal;
    color: #FFF; }
    @media (min-width: 1024px) {
      .header-bar .result-count {
        font-size: 24px;
        line-height: 1.5em;
        font-weight: 400;
        letter-spacing: 0;
        text-transform: none;
        font-style: normal; } }

.filter-bar .result-count {
  padding: 6px 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 12px; }
  .filter-bar .result-count:not(:only-child) {
    margin-right: 12px; }
    @media (min-width: 1440px) {
      .filter-bar .result-count:not(:only-child) {
        margin-right: 24px; } }
  .filter-bar .result-count p {
    margin-bottom: 0; }

.filter-bar .filter-value {
  margin-bottom: 8px; }
  .filter-bar .filter-value button {
    width: 100%;
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    position: relative;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: #6a6a6a;
    padding: 10px;
    cursor: default; }
    .filter-bar .filter-value button .close:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 16px;
      color: inherit;
      font-weight: normal; }
    .filter-bar .filter-value button .close[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .filter-bar .filter-value button .close[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: inherit;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .filter-bar .filter-value button .close[class*="btn-"]:not(.title).btn-sm, .filter-bar .filter-value button .btn-group-sm > .close.btn[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .filter-bar .filter-value button .close[class*="btn-"]:not(.title).btn-sm:after, .filter-bar .filter-value button .btn-group-sm > .close.btn[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: inherit;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .filter-bar .filter-value button .close[class*="btn-"]:not(.title) [class*="icon-"],
      .filter-bar .filter-value button .close[class*="btn-"]:not(.title) [class^="icon-"],
      .filter-bar .filter-value button .close[class*="btn-"]:not(.title) .fa {
        display: none; }
    .filter-bar .filter-value button .close::after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 5px;
      cursor: pointer; }

@media (min-width: 1024px) {
  .refinement-bar:not([class*="refinement-bar-horizontal"]) {
    margin-top: 12px; }
    .refinement-bar:not([class*="refinement-bar-horizontal"]) + div {
      margin-top: 12px; } }

.refinement-bar ul {
  padding: 0;
  margin: 0;
  line-height: 1; }

.refinement-bar .secondary-bar {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 12px;
  margin-bottom: 12px; }
  .refinement-bar .secondary-bar li {
    padding: 0; }
  @media (min-width: 1024px) {
    .refinement-bar .secondary-bar {
      margin-top: 0; } }
  .refinement-bar .secondary-bar .secondary-bar-links {
    text-decoration: none;
    line-height: 1.5em;
    padding: 8px 0;
    display: block;
    width: 100%; }
  .refinement-bar .secondary-bar li:last-child .secondary-bar-links {
    text-align: right; }

@media (max-width: 1023.98px) {
  .refinement-bar {
    position: fixed;
    transform: translateX(-100%);
    transition: transform 200ms ease-out;
    top: 0;
    left: 0;
    display: block;
    height: calc(100vh);
    background-color: #FFF;
    z-index: 250;
    overflow-x: hidden;
    overflow-y: auto;
    padding-bottom: 200px; }
    .refinement-bar.in {
      transform: translateX(0);
      z-index: 401; } }

.refinements > .card:last-child {
  border-bottom: none; }

.refinements .values.content {
  font-size: 0; }
  .refinements .values.content li {
    padding-left: 0;
    padding-bottom: 12px;
    display: block;
    max-width: 100%; }
    .refinements .values.content li.col-sm-4 {
      width: 100%;
      flex-basis: 100%; }
  .refinements .values.content button {
    border: none;
    background: none;
    padding: 0;
    text-align: left;
    height: 20px; }
    .refinements .values.content button:hover i.fa {
      box-shadow: 0 0 0 1px #000; }
    .refinements .values.content button:hover .swatch-circle {
      box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #D6D6D6; }
    .refinements .values.content button span {
      font-size: 14px;
      display: inline-block;
      vertical-align: middle; }
      .refinements .values.content button span:not(.color-value) {
        margin-right: 12px; }
      .refinements .values.content button span.unselectable.swatch-circle:hover {
        box-shadow: none; }
      .refinements .values.content button span.non-color-swatch-value {
        position: relative;
        color: #000;
        background: #FFF;
        border: 1px solid transparent;
        border-radius: 0;
        padding: 0 9px;
        display: block;
        min-width: 44px;
        line-height: 42px;
        margin-right: 0;
        padding: 0; }
        .refinements .values.content button span.non-color-swatch-value.color-value {
          transition: box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.color-value:hover {
            box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #D6D6D6; }
          .refinements .values.content button span.non-color-swatch-value.color-value.selected {
            box-shadow: 0 0 0 calc(3px - 1px) #FFF, 0 0 0 3px #000; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 7px;
              color: #FFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) {
              padding-right: 40px; }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.color-value.selected.btn[class*="btn-"]:not(.title) {
                padding-right: 40px; }
                .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.color-value.selected.btn[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.color-value.selected[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.color-value.selected::after {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              display: none;
              background: rgba(0, 0, 0, 0.7);
              width: 14px;
              height: 14px;
              line-height: 14px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              z-index: 1; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unavailable {
            pointer-events: none;
            pointer-events: auto;
            cursor: not-allowed;
            position: relative; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:before, .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:after {
              background-color: #D6D6D6; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:before {
              background-color: #FFF;
              margin-right: 1px;
              box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
              width: 2px; }
          .refinements .values.content button span.non-color-swatch-value.color-value.unavailable {
            pointer-events: none;
            pointer-events: auto;
            cursor: not-allowed;
            position: relative; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:before, .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:after {
              background-color: #D6D6D6; }
            .refinements .values.content button span.non-color-swatch-value.color-value.unavailable:before {
              background-color: #FFF;
              margin-right: 1px;
              box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
              width: 2px; }
        .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value {
          transition: border 200ms ease-out, box-shadow 200ms ease-out; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value:not(.unavailable):hover {
            border-color: transparent;
            box-shadow: none; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover {
            background: #FFF;
            color: #000;
            border-color: transparent;
            box-shadow: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover:after {
              content: "";
              font-family: "icomoon";
              display: inline-block;
              font-size: 8px;
              color: #FFF;
              font-weight: normal; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) {
              padding-right: 40px; }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title):after {
                font-family: "icomoon";
                display: inline-block;
                font-size: 20px;
                color: #FFF;
                font-weight: normal;
                position: absolute;
                transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title), .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title) {
                padding-right: 40px; }
                .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn[class*="btn-"]:not(.title):after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title).btn-sm:after, .refinements .values.content button .btn-group-sm > span.non-color-swatch-value.non-color-swatch-value.selected.btn:hover[class*="btn-"]:not(.title):after {
                  font-family: "icomoon";
                  display: inline-block;
                  font-size: 22px;
                  color: #FFF;
                  font-weight: normal;
                  position: absolute;
                  transform: translateX(10px); }
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected[class*="btn-"]:not(.title) .fa, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class*="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) [class^="icon-"],
              .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover[class*="btn-"]:not(.title) .fa {
                display: none; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected::after, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.selected:hover::after {
              position: absolute;
              top: -5.33333px;
              right: -5.33333px;
              background: #0C0A08;
              width: 16px;
              height: 16px;
              line-height: 16px;
              padding-left: 1px;
              text-align: center;
              border-radius: 50%;
              display: none; }
          .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable {
            pointer-events: none;
            pointer-events: auto;
            position: relative; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable:not(.bis-enabled):before, .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable:not(.bis-enabled):after {
              content: "";
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%) rotate(45deg);
              height: calc(100% + 15px);
              width: 1px; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable:not(.bis-enabled):after {
              background-color: #D6D6D6; }
            .refinements .values.content button span.non-color-swatch-value.non-color-swatch-value.unavailable:not(.bis-enabled):before {
              background-color: #FFF;
              margin-right: 1px;
              box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
              width: 2px; }
    .refinements .values.content button i.fa {
      position: relative;
      vertical-align: middle;
      margin-right: 12px;
      transition: box-shadow 200ms ease-out; }
    .refinements .values.content button i.fa-circle-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      border-radius: 50%;
      background-color: #FFF; }
    .refinements .values.content button i.fa-check-circle {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #FFF;
      border: 1px solid #000; }
      .refinements .values.content button i.fa-check-circle:after {
        content: "";
        background: none;
        width: 10px;
        height: 10px;
        top: calc(10px/ 2);
        left: calc(-32px + 10px/ 2);
        background-color: #000;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
    .refinements .values.content button i.fa-square-o {
      content: "";
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      background-color: #FFF;
      border-radius: 5px; }
    .refinements .values.content button i.fa-check-square {
      content: "";
      width: 20px;
      height: 20px;
      background-color: #000;
      border: 1px solid #000;
      border-radius: 5px; }
      .refinements .values.content button i.fa-check-square:after {
        content: "";
        width: 20px;
        height: 20px;
        line-height: calc(20px + 2px);
        background: none;
        font-family: "icomoon";
        font-size: 13.33333px;
        text-align: center;
        color: #FFF;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        left: auto; }
  .refinements .values.content .swatches-grid {
    padding-bottom: 10px; }

.refinements .refinement-category .values.content {
  flex-direction: column; }

.refinements .refinement-category .values li {
  display: block; }

.refinements .refinement-category .values .values {
  margin-left: 32px;
  margin-top: 12px; }
  .refinements .refinement-category .values .values li {
    padding-top: 12px;
    padding-bottom: 0; }

.refinements .refinement-color .values.content {
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px; }
  .refinements .refinement-color .values.content .swatch-button {
    border: 1px solid #D6D6D6;
    border-radius: 5px;
    padding: 8px 10px;
    height: auto; }
    .refinements .refinement-color .values.content .swatch-button.selected {
      border-color: #000;
      border-width: 2px; }

.refinements .swatch-color-text {
  min-width: 0;
  padding-left: 12px; }

@media (min-width: 1024px) {
  .refinement-bar-horizontal .horizontal-filter-btn.custom-select, .refinement-bar-horizontal select.horizontal-filter-btn.form-control,
  .refinement-bar-horizontal select.horizontal-filter-btn {
    width: auto; }
  .horizontal-multipledropdowns-filter-refinement.card.aside {
    height: calc(16px + 12px + 14px + (1px*2));
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    border-left: 1px solid #D6D6D6;
    border-right: 1px solid #D6D6D6;
    min-width: 150px;
    margin-right: 10px;
    margin-bottom: 0; }
    .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content {
      margin-left: 0;
      margin-top: 0; }
      .horizontal-multipledropdowns-filter-refinement.card.aside.refinement-category .values.content.values li {
        padding-top: 0; }
  .refinements .horizontal-multipledropdowns-filter-dropdown-menu .refinement-category .values.content button {
    padding-bottom: 20px; }
  .horizontal-multipledropdowns-filter-dropdown-menu {
    min-width: 300px;
    margin-top: 0;
    padding: 16px; }
    .horizontal-multipledropdowns-filter-dropdown-menu .card-body {
      min-height: 0; }
    .horizontal-multipledropdowns-filter-dropdown-menu .values.content {
      margin-left: 0;
      margin-top: 0;
      min-height: 0;
      max-width: 300px; }
    .horizontal-multipledropdowns-filter-dropdown-menu.show {
      position: absolute;
      transform: translate3d(0px, 43px, 0px);
      top: 0px;
      left: 0px;
      will-change: transform;
      display: block; }
  .horizontal-multipledropdowns-filter-btn {
    border: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 10px; }
    .horizontal-multipledropdowns-filter-btn:after {
      float: right; }
  .refinement-bar-horizontal-one-dropdown .desktop-search-refinements {
    position: relative; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements:not(.sticky-filters) .refinements-container {
      padding: 0; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper {
      display: none; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-filters {
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-left: none;
        margin-top: 15px; }
      .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter {
        display: flex;
        border-top: none;
        border-bottom: none;
        border-right: none;
        border-left: none;
        max-width: 1416px;
        margin-top: 15px; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .horizontal-onedropdown-filter-refinement {
          width: 33%;
          padding-top: 15px;
          padding-bottom: 15px;
          border: none; }
        .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown {
          display: flex; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown:before {
            content: "";
            font-family: "icomoon";
            display: inline-block;
            font-size: inherit;
            color: inherit;
            font-weight: normal; }
          .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) {
            padding-left: 40px; }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title):before {
              font-family: "icomoon";
              display: inline-block;
              font-size: 20px;
              color: inherit;
              font-weight: normal;
              position: absolute;
              transform: translateX(-30px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title) {
              padding-left: 40px; }
              .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title).btn-sm:before, .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .btn-group-sm > .close-one-refinements-dropdown.btn[class*="btn-"]:not(.title):before {
                font-family: "icomoon";
                display: inline-block;
                font-size: 22px;
                color: inherit;
                font-weight: normal;
                position: absolute;
                transform: translateX(-32px); }
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class*="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) [class^="icon-"],
            .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements .collapse-filters-wrapper .collapse-one-dropdown-filter .close-one-refinements-dropdown[class*="btn-"]:not(.title) .fa {
              display: none; }
    .refinement-bar-horizontal-one-dropdown .desktop-search-refinements .refinements.sticky-filters .horizontal-onedropdown-sortBy {
      position: relative; }
  .horizontal-onedropdown-filter-btn.title {
    width: 150px;
    background-color: transparent;
    padding-right: 10px;
    padding-left: 12px;
    padding-top: 6px;
    padding-bottom: 6px;
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    border-right: 1px solid #D6D6D6;
    border-left: 1px solid #D6D6D6;
    text-align: left; }
  .collapse-filters-wrapper .horizontal-onedropdown-filter-btn.title::after,
  .collapse-filters-wrapper.active .horizontal-onedropdown-filter-btn.title::after {
    content: '';
    display: none; }
  .horizontal-onedropdown-filter select[name="sort-order"] {
    position: absolute;
    right: 0;
    width: 150px; }
  .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn {
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .horizontal-onedropdown-filter-group .horizontal-onedropdown-filter-btn .horizontal-onedropdown-filter-btn-arrow {
      background-image: url(../images/icons/icon-arrow-down.svg);
      background-size: 10px;
      width: 12px;
      height: 12px; }
  .horizontal-onedropdown-filter-group.active .horizontal-onedropdown-filter-btn-arrow {
    transform: scaleY(-1); } }

.horizontal-filter-refinements-bar {
  padding-top: 12px;
  padding-bottom: 12px; }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container {
      padding: 0; } }
  @media (min-width: 1024px) {
    .horizontal-filter-refinements-bar .refinements-container select[name="sort-order"] {
      font-size: 14px; } }
  @media (min-width: 1440px) {
    .horizontal-filter-refinements-bar.sticky-filters .refinements-container {
      padding: 0 24px; } }

.sticky-filters {
  position: fixed;
  left: 0;
  z-index: 300;
  background: #FFF;
  width: 100%;
  border-bottom: 1px solid #D6D6D6;
  padding-left: 12px;
  padding-right: 12px; }
  .sticky-header .sticky-filters {
    transition: transform 400ms ease-out; }
  .sticky-header.scroll-direction-up .sticky-filters {
    transform: translateY(var(--header-height));
    transition: transform 400ms ease-out 50ms; }

.search-nav {
  border-bottom: 1px solid #595959; }

@media (max-width: 1023.98px) {
  .product-grid .product-tile-full-width .experience-region {
    max-width: 100%; }
    .product-grid .product-tile-full-width .experience-region .hero {
      min-height: 398px; } }

@media (max-width: 1023.98px) {
  .search-results .product-grid {
    margin-left: -30px;
    margin-right: -30px; } }

@media (max-width: 1023.98px) {
  .search-results .grid-header {
    border-top: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;
    margin-left: 0px;
    margin-right: 0px; } }

.search-results .grid-header .grid-header-filter-group {
  border-top: 1px solid #D6D6D6;
  border-bottom: 1px solid #D6D6D6; }
  .search-results .grid-header .grid-header-filter-group .grid-header-filter {
    border-right: 1px solid #D6D6D6; }

.search-results .grid-header .filter-results [data-asset-id="FilterIconSvg"] {
  display: inline-block;
  transform: translateY(-2px); }

@media (max-width: 1023.98px) {
  .search-results .grid-header .filter-sort-container {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0px;
    padding-right: 0px; } }

.search-results .grid-header .filter-sort-button {
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #D6D6D6;
  border-radius: 50px;
  padding: 0 20px; }
  .search-results .grid-header .filter-sort-button svg {
    height: 18px; }
  @media (max-width: 1023.98px) {
    .search-results .grid-header .filter-sort-button {
      width: 100%;
      background: #000;
      color: #FFF;
      padding: 6px;
      margin-top: 24px;
      margin-bottom: 24px;
      font-size: 14px; }
      .search-results .grid-header .filter-sort-button svg {
        display: none; } }

.search-results .result-count {
  font-size: 12px;
  line-height: 1;
  color: #595959; }

.search-results .grid-header-filter-btn {
  border: none;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  line-height: 40px;
  color: #595959;
  text-transform: initial;
  border-radius: 0;
  display: flex; }
  @media (min-width: 769px) {
    .search-results .grid-header-filter-btn {
      font-size: 14px; } }

.search-results .grid-header-filter-btn,
.search-results .custom-select[name="sort-order"],
.search-results select.form-control[name="sort-order"],
.search-results select[name="sort-order"] {
  position: relative; }
  @media (min-width: 1024px) {
    .search-results .grid-header-filter-btn,
    .search-results .custom-select[name="sort-order"],
    .search-results select.form-control[name="sort-order"],
    .search-results select[name="sort-order"] {
      border: 1px solid #D6D6D6; } }
  .search-results .grid-header-filter-btn:after,
  .search-results .custom-select[name="sort-order"]:after,
  .search-results select.form-control[name="sort-order"]:after,
  .search-results select[name="sort-order"]:after {
    content: "";
    font-family: "icomoon";
    display: inline-block;
    font-size: 10px;
    color: #595959;
    font-weight: normal; }
  .search-results .grid-header-filter-btn[class*="btn-"]:not(.title),
  .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title),
  .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title),
  .search-results select[name="sort-order"][class*="btn-"]:not(.title) {
    padding-right: 40px; }
    .search-results .grid-header-filter-btn[class*="btn-"]:not(.title):after,
    .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title):after,
    .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title):after,
    .search-results select[name="sort-order"][class*="btn-"]:not(.title):after {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: #595959;
      font-weight: normal;
      position: absolute;
      transform: translateX(10px); }
    .search-results .grid-header-filter-btn[class*="btn-"]:not(.title).btn-sm, .search-results .btn-group-sm > .grid-header-filter-btn.btn[class*="btn-"]:not(.title),
    .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title).btn-sm,
    .search-results .btn-group-sm > .custom-select.btn[name="sort-order"][class*="btn-"]:not(.title),
    .search-results .btn-group-sm > select.btn[name="sort-order"][class*="btn-"]:not(.title),
    .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title).btn-sm,
    .search-results select[name="sort-order"][class*="btn-"]:not(.title).btn-sm {
      padding-right: 40px; }
      .search-results .grid-header-filter-btn[class*="btn-"]:not(.title).btn-sm:after, .search-results .btn-group-sm > .grid-header-filter-btn.btn[class*="btn-"]:not(.title):after,
      .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title).btn-sm:after,
      .search-results .btn-group-sm > .custom-select.btn[name="sort-order"][class*="btn-"]:not(.title):after,
      .search-results .btn-group-sm > select.btn[name="sort-order"][class*="btn-"]:not(.title):after,
      .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title).btn-sm:after,
      .search-results select[name="sort-order"][class*="btn-"]:not(.title).btn-sm:after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 22px;
        color: #595959;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
    .search-results .grid-header-filter-btn[class*="btn-"]:not(.title) [class*="icon-"],
    .search-results .grid-header-filter-btn[class*="btn-"]:not(.title) [class^="icon-"],
    .search-results .grid-header-filter-btn[class*="btn-"]:not(.title) .fa,
    .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title) [class*="icon-"],
    .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title) [class*="icon-"],
    .search-results select[name="sort-order"][class*="btn-"]:not(.title) [class*="icon-"],
    .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title) [class^="icon-"],
    .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title) [class^="icon-"],
    .search-results select[name="sort-order"][class*="btn-"]:not(.title) [class^="icon-"],
    .search-results .custom-select[name="sort-order"][class*="btn-"]:not(.title) .fa,
    .search-results select.form-control[name="sort-order"][class*="btn-"]:not(.title) .fa,
    .search-results select[name="sort-order"][class*="btn-"]:not(.title) .fa {
      display: none; }
  .search-results .grid-header-filter-btn::after,
  .search-results .custom-select[name="sort-order"]::after,
  .search-results select.form-control[name="sort-order"]::after,
  .search-results select[name="sort-order"]::after {
    right: 0;
    padding-right: 20px;
    position: absolute; }

.search-results .grid-header-filter-btn {
  background-color: transparent; }
  .search-results .grid-header-filter-btn:open {
    transform: rotate(180deg); }
    .search-results .grid-header-filter-btn:open:after {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 10px;
      color: #595959;
      font-weight: normal; }
    .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title) {
      padding-right: 40px; }
      .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title):after {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #595959;
        font-weight: normal;
        position: absolute;
        transform: translateX(10px); }
      .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title).btn-sm, .search-results .btn-group-sm > .grid-header-filter-btn.btn:open[class*="btn-"]:not(.title) {
        padding-right: 40px; }
        .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title).btn-sm:after, .search-results .btn-group-sm > .grid-header-filter-btn.btn:open[class*="btn-"]:not(.title):after {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #595959;
          font-weight: normal;
          position: absolute;
          transform: translateX(10px); }
      .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title) [class*="icon-"],
      .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title) [class^="icon-"],
      .search-results .grid-header-filter-btn:open[class*="btn-"]:not(.title) .fa {
        display: none; }

.search-results .category-seocontent {
  margin-top: 40px; }

.search-results .sort-order-menu .custom-select, .search-results .sort-order-menu select.form-control,
.search-results .sort-order-menu select {
  letter-spacing: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5em;
  letter-spacing: 0;
  padding: 10px 35px 12px 20px; }
  @media (min-width: 1024px) {
    .search-results .sort-order-menu .custom-select, .search-results .sort-order-menu select.form-control,
    .search-results .sort-order-menu select {
      font-size: 16px;
      line-height: 1.5em;
      letter-spacing: 0; } }

@media (max-width: 1023.98px) {
  .row [class*="col-"] .refinement-bar {
    padding-left: 24px;
    padding-right: 24px; }
  .refinement-bar .refinements-container .card-title {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 500; }
  .refinement-bar .refinements-container .card-body {
    padding: 0; } }

.refinement-bar .desktop-search-refinements {
  padding-right: 42px; }

.refinement-bar .header-bar-title {
  padding: 24px 0;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 301;
  background-color: #FFF; }
  .refinement-bar .header-bar-title .h3 {
    margin-bottom: 0; }
  .refinement-bar .header-bar-title::after {
    content: '';
    display: inline-block;
    width: calc(100% + 48px);
    height: 1px;
    background-color: #D6D6D6;
    position: absolute;
    bottom: 0;
    left: -24px; }

.refinement-bar .header-bar {
  background-color: #FFF;
  color: #000; }

.refinement-bar .secondary-bar .secondary-bar-links {
  font-size: 12px; }

.refinement-bar .search-result-count {
  margin-bottom: 12px; }

.refinement-bar .card.aside {
  border: none; }

.refinement-bar button {
  color: #000; }

.filter-limit-more {
  color: #595959;
  cursor: pointer; }
  .refinement-color .filter-limit-more {
    flex-basis: 100%; }

.filter-sort-button {
  color: #000; }

.filter-apply-message {
  transition: 0.25s;
  position: fixed;
  bottom: 0;
  background-color: #00865A;
  z-index: 301;
  left: 0;
  right: 0;
  text-align: center;
  color: #FFF;
  padding: 20px;
  font-size: 16px;
  font-weight: 700; }
  .filter-apply-message span.icon-checkmark {
    background-color: #FFF;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 20px; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  overflow-y: scroll;
  height: 100vh;
  padding-right: 6px; }

#desktop-vertical-search-refinements .refinements .refinement-categories .values.content {
  display: block; }

.mobile-search-refinements .refinements .mobile-sort-body {
  line-height: 24px; }

.category-refinement-item {
  margin-left: 12px; }

.refinement-category-scroll {
  padding-bottom: 24px;
  position: relative; }
  @media (min-width: 769px) {
    .refinement-category-scroll {
      padding-right: 150px;
      padding-top: 10px;
      overflow: hidden; } }
  .refinement-category-scroll:not(.swiper-container-initialized) .controls .swiper-button-prev,
  .refinement-category-scroll:not(.swiper-container-initialized) .controls .swiper-button-next {
    display: none; }
  .refinement-category-scroll .category-scroll-values {
    list-style: none;
    display: flex;
    padding-left: 0; }
    .refinement-category-scroll .category-scroll-values .category-slide-link {
      letter-spacing: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5em;
      letter-spacing: 0;
      width: 100%; }
      @media (min-width: 1024px) {
        .refinement-category-scroll .category-scroll-values .category-slide-link {
          font-size: 16px;
          line-height: 1.5em;
          letter-spacing: 0; } }
      .refinement-category-scroll .category-scroll-values .category-slide-link .selected {
        font-weight: 700; }
    .refinement-category-scroll .category-scroll-values a {
      text-decoration: none; }
      .refinement-category-scroll .category-scroll-values a span {
        white-space: nowrap; }
    .refinement-category-scroll .category-scroll-values .category-slide-link:hover span:first-child {
      position: relative; }
      .refinement-category-scroll .category-scroll-values .category-slide-link:hover span:first-child::after {
        content: '';
        width: 100%;
        height: 0;
        border-bottom: 1px solid #000;
        position: absolute;
        left: 0;
        bottom: 0; }
    .refinement-category-scroll .category-scroll-values .selected {
      position: relative; }
      .refinement-category-scroll .category-scroll-values .selected::after {
        content: '';
        width: 100%;
        height: 0;
        border-bottom: 1px solid #000;
        position: absolute;
        left: 0;
        bottom: 0; }
  .refinement-category-scroll .controls {
    position: absolute;
    top: 0;
    right: 0;
    display: none; }
    @media (min-width: 769px) {
      .refinement-category-scroll .controls {
        display: flex; } }
    .refinement-category-scroll .controls .swiper-button-prev,
    .refinement-category-scroll .controls .swiper-button-next {
      width: 40px;
      height: 40px;
      border-radius: 50px;
      background-color: #FFF;
      border: 1px solid #000;
      display: flex;
      text-align: center;
      transition: background-color 200ms ease-out;
      font-size: 0;
      padding: 0;
      user-select: none;
      z-index: 1; }
      .refinement-category-scroll .controls .swiper-button-prev.swiper-button-disabled,
      .refinement-category-scroll .controls .swiper-button-next.swiper-button-disabled {
        border: 1px solid #D6D6D6; }
        .refinement-category-scroll .controls .swiper-button-prev.swiper-button-disabled::before,
        .refinement-category-scroll .controls .swiper-button-next.swiper-button-disabled::before {
          color: #D6D6D6; }
      .refinement-category-scroll .controls .swiper-button-prev:not(.swiper-button-disabled):hover,
      .refinement-category-scroll .controls .swiper-button-next:not(.swiper-button-disabled):hover {
        border: 1px solid #E6FF00;
        background-color: #E6FF00; }
        .refinement-category-scroll .controls .swiper-button-prev:not(.swiper-button-disabled):hover::before,
        .refinement-category-scroll .controls .swiper-button-next:not(.swiper-button-disabled):hover::before {
          color: #FFF; }
      .refinement-category-scroll .controls .swiper-button-prev::before,
      .refinement-category-scroll .controls .swiper-button-next::before {
        width: 100%;
        align-self: center;
        transition: color 200ms ease-out; }
    .refinement-category-scroll .controls .swiper-button-prev:before {
      content: "";
      font-family: "icomoon";
      display: inline-block;
      font-size: 15px;
      color: #000;
      font-weight: normal; }
    .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title) {
      padding-left: 40px; }
      .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title):before {
        font-family: "icomoon";
        display: inline-block;
        font-size: 20px;
        color: #000;
        font-weight: normal;
        position: absolute;
        transform: translateX(-30px); }
      .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title).btn-sm, .refinement-category-scroll .controls .btn-group-sm > .swiper-button-prev.btn[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title).btn-sm:before, .refinement-category-scroll .controls .btn-group-sm > .swiper-button-prev.btn[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 22px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-32px); }
      .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title) [class*="icon-"],
      .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title) [class^="icon-"],
      .refinement-category-scroll .controls .swiper-button-prev[class*="btn-"]:not(.title) .fa {
        display: none; }
    .refinement-category-scroll .controls .swiper-button-next {
      margin-left: 20px; }
      .refinement-category-scroll .controls .swiper-button-next:before {
        content: "";
        font-family: "icomoon";
        display: inline-block;
        font-size: 15px;
        color: #000;
        font-weight: normal; }
      .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title) {
        padding-left: 40px; }
        .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title):before {
          font-family: "icomoon";
          display: inline-block;
          font-size: 20px;
          color: #000;
          font-weight: normal;
          position: absolute;
          transform: translateX(-30px); }
        .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title).btn-sm, .refinement-category-scroll .controls .btn-group-sm > .swiper-button-next.btn[class*="btn-"]:not(.title) {
          padding-left: 40px; }
          .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title).btn-sm:before, .refinement-category-scroll .controls .btn-group-sm > .swiper-button-next.btn[class*="btn-"]:not(.title):before {
            font-family: "icomoon";
            display: inline-block;
            font-size: 22px;
            color: #000;
            font-weight: normal;
            position: absolute;
            transform: translateX(-32px); }
        .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title) [class*="icon-"],
        .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title) [class^="icon-"],
        .refinement-category-scroll .controls .swiper-button-next[class*="btn-"]:not(.title) .fa {
          display: none; }

.filter-header {
  margin-left: 0;
  margin-right: 0; }
  .filter-header .result-count {
    color: #595959; }

@media (max-width: 1023.98px) {
  .sticky-filters {
    left: 0; } }

.store-locator-container .btn-storelocator-search {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: "archia-medium", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.42px;
  line-height: 1.5em;
  padding: 12px 20px 12px 20px;
  font-family: "archia-medium", sans-serif;
  text-transform: none;
  color: #FFF;
  background-color: #000;
  border-width: 1px;
  border-radius: 50px;
  border-color: #000;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  padding: 12px 20px 12px 20px; }
  .store-locator-container .btn-storelocator-search:hover, .store-locator-container .btn-storelocator-search:focus, .store-locator-container .btn-storelocator-search[aria-describedby*="popover"] {
    color: #FFF;
    background-color: #000;
    border-color: #FFF;
    text-decoration: none; }
  .store-locator-container .btn-storelocator-search[class*="icon-"], .store-locator-container .btn-storelocator-search[class^="icon-"] {
    padding-left: 40px; }
    .store-locator-container .btn-storelocator-search[class*="icon-"]:before, .store-locator-container .btn-storelocator-search[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }

.store-locator-container .detect-location-spacer {
  margin-bottom: 12px; }

.store-locator-container .search-form {
  width: 100%; }

.store-locator-container .results-card > .card-body {
  padding-right: 0;
  padding-bottom: 0; }

.store-locator-container .filter-results {
  margin-bottom: 24px;
  padding-right: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  .store-locator-container .filter-results .btn {
    margin-bottom: 5px;
    margin-right: 5px; }

@media (min-width: 769px) {
  .store-locator-container .results {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: 0;
    max-height: 350px; } }

.store-locator-container .results-card > .card-header label {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 12px;
  margin-bottom: 6px;
  text-transform: none;
  letter-spacing: 0;
  font-size: 12px;
  display: inline-block;
  margin: 0 0 0 1px;
  padding: 9px 12px 0 12px;
  position: absolute;
  opacity: 0;
  transition: opacity 200ms ease-out; }
  .store-locator-container .results-card > .card-header label:has(~ .form-control:not(:placeholder-shown)), .store-locator-container .results-card > .card-header label:has(~ .form-control:autofill:placeholder-shown) {
    opacity: 1; }
  .store-locator-container .results-card > .card-header label:has(~ textarea.form-control) {
    padding-top: 12px; }

.store-locator-container .results .store-result {
  display: flex;
  margin-bottom: 24px; }
  .store-locator-container .results .store-result .map-marker {
    flex-shrink: 0; }

.store-details .store-name a {
  display: block;
  margin-bottom: 5px; }

.store-details .store-name p {
  font-style: italic;
  font-weight: normal;
  margin-bottom: 5px; }

.store-details .store-map {
  display: block; }

.store-details .store-map,
.store-details .store-hours {
  margin-bottom: 5px; }

.store-details ul,
.store-details address {
  margin-bottom: 0; }

.data-checkout-stage .shipping-section .store-details .store-name a,
.product-summary-block .shipment-block .store-details .store-name a,
.product-summary-block .multi-shipping .store-details .store-name a,
.cart-page .line-item-store .store-details .store-name a {
  font-size: inherit;
  letter-spacing: 0; }

.cart-page .line-item-store .store-details {
  margin-top: 5px; }

.map-marker {
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  position: relative;
  letter-spacing: 0;
  margin-right: 12px; }
  .map-marker span {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
    color: #FFF;
    top: 0;
    width: 32px; }

.gm-style .gm-style-iw,
.gm-style .gm-style-iw-c {
  border-color: transparent;
  border-radius: 0px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  max-width: 300px; }
  .gm-style .gm-style-iw .store-details,
  .gm-style .gm-style-iw-c .store-details {
    margin-left: 0;
    font-family: "archia-medium", sans-serif;
    font-size: 12px;
    line-height: 1.5; }
    .gm-style .gm-style-iw .store-details address,
    .gm-style .gm-style-iw-c .store-details address {
      line-height: 1.75; }
    .gm-style .gm-style-iw .store-details .store-hours,
    .gm-style .gm-style-iw-c .store-details .store-hours {
      margin-bottom: 5px; }
  .gm-style .gm-style-iw .title,
  .gm-style .gm-style-iw-c .title {
    font-size: 18px; }

.store-locator-no-results {
  padding-right: 20px;
  display: none; }

.store-locator-no-apiKey {
  display: none; }

.map-canvas {
  height: 250px;
  display: block; }
  @media (min-width: 769px) {
    .map-canvas {
      height: 450px; } }

.store-detail-page .store-name {
  font-family: "archia-medium", sans-serif;
  font-size: 24px;
  line-height: 1.5em;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: none;
  font-style: normal;
  color: #000; }
  @media (min-width: 1024px) {
    .store-detail-page .store-name {
      font-size: 29px;
      line-height: 1.5em;
      font-weight: 400;
      letter-spacing: 0;
      text-transform: none;
      font-style: normal; } }

.store-detail-page .store-attribute + .store-attribute {
  margin-top: 8px; }
  .store-detail-page .store-attribute + .store-attribute.store-additional {
    margin-top: 30px; }
  .store-detail-page .store-attribute + .store-attribute.store-image {
    margin-top: 30px; }

.store-detail-page .store-attribute label {
  font-weight: bold; }

.store-detail-page .store-map-link {
  display: block;
  margin-top: 12px; }

.site-offline {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .site-offline,
  .site-offline body {
    background-color: #000;
    color: #D8DDC9;
    height: 100%;
    min-height: 100%; }
  .site-offline .site-offline-container {
    width: 454px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 18px; }
    .site-offline .site-offline-container .site-offline-logo,
    .site-offline .site-offline-container .site-offline-header,
    .site-offline .site-offline-container .site-offline-message {
      margin-bottom: 24px; }
    .site-offline .site-offline-container .site-offline-logo {
      height: 85px;
      width: 85px;
      background: url("../../images/logo-scuff02.svg") no-repeat center;
      background-size: contain; }
    .site-offline .site-offline-container .site-offline-footer {
      font-size: 13px; }
    .site-offline .site-offline-container .site-offline-mailto {
      color: #99FF00;
      text-decoration: none; }

/* ALL FILES KEPT LOCAL, PREVENTS ACCIDENTIAL BASE STYLES INCLUSION */
.salesforce-payments-errors {
  font-size: 0.8em;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border: 1px solid lightblue;
  border-radius: 0px;
  background: #fee url(../../images/exclamation.svg) 0.5rem 0.5rem/1rem no-repeat; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-paymentrequest-element-errors {
  margin-top: 0.75rem; }

.salesforce-paymentrequest-element[disabled] {
  display: none; }

.salesforce-secure-payment {
  margin-left: 1.2rem;
  font-size: 0.8em;
  padding: 0.3rem 0.5rem 0.5rem 1.5rem;
  background: url(../../images/padlock.svg) no-repeat; }

.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.cart-and-ipay .add-to-cart {
  width: 100%;
  display: block; }

@media (max-width: 543.98px) {
  .cart-and-ipay .add-to-cart {
    width: 100%;
    margin: 0; } }

.salesforce-buynow-element {
  width: 100%; }

.salesforce-buynow-element:not(:empty) {
  margin-top: 1em; }

.salesforce-buynow-element-errors {
  margin-top: 0.75rem;
  text-align: left; }

.salesforce-buynow-element[disabled] {
  display: none; }

.card.payment-method .card-header {
  border-bottom: 0; }

.card.payment-method .card-body {
  padding: 0; }

.salesforce-payments-element-errors {
  margin-bottom: 0.75rem; }

.salesforce-payments-errors:empty {
  display: none; }

.salesforce-payments-element .sfpp-payment-method-choice,
.salesforce-payments-element .sfpp-payment-method-choice .sfpp-payment-method-body,
.sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125); }

.sfpp-payment-method-card-number-label::before,
.sfpp-payment-method-card-expiry-label::before,
.sfpp-payment-method-card-cvc-label::before,
.sfpp-payment-method-iban-label::before {
  content: "*";
  color: #BF242B; }

.sfpp-payment-method-body-card .sfpp-payment-method-card-number-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-expiry-element,
.sfpp-payment-method-body-card .sfpp-payment-method-card-cvc-element,
.sfpp-payment-method-body-ideal .sfpp-payment-method-ideal-bank-element,
.sfpp-payment-method-body-sepa_debit .sfpp-payment-method-iban-element {
  border-color: rgba(0, 0, 0, 0.125);
  border-radius: 0px; }

.sfpp-payment-method-body .sfpp-payment-method-element--invalid {
  border-color: #BF242B; }

.data-checkout-stage[data-checkout-stage] button.submit-billing {
  display: none; }

.data-checkout-stage[data-checkout-stage] button.hidden {
  display: none !important; }

.data-checkout-stage[data-checkout-stage=payment] button.submit-billing {
  display: block; }

.salesforce-paymentrequest-element:not(:empty) {
  margin-bottom: 1rem; }

.in-store-inventory-dialog .store-locator-container,
.pickup-in-store .store-locator-container {
  padding-left: 0;
  padding-right: 0; }
  .in-store-inventory-dialog .store-locator-container .store-result label::before,
  .in-store-inventory-dialog .store-locator-container .store-result label::after,
  .pickup-in-store .store-locator-container .store-result label::before,
  .pickup-in-store .store-locator-container .store-result label::after {
    margin-top: 2px;
    margin-left: 2px; }

.pickup-in-store {
  margin-bottom: 12px; }
  .pickup-in-store .select-store,
  .pickup-in-store ~ .change-store {
    margin-top: 12px; }
  .pickup-in-store .results-card .card-title {
    border-top: none; }

.cart-page .line-item-store {
  margin-top: 12px; }
  .cart-page .line-item-store .store-details {
    margin-left: 12px; }

button.dw-apple-pay-button.applePaySpecificityOverride {
  width: 100%;
  display: block;
  padding: 12px 20px 12px 20px;
  margin-bottom: 12px; }

.account-image {
  background-image: url(../../images/account.jpg);
  background-position-y: 40%; }

#sp-my-subscriptions {
  min-height: 30em; }

#sp-my-subscriptions .sp-field.custom-select select, #sp-my-subscriptions select.sp-field.form-control select, #sp-my-subscriptions select.sp-field select {
  border: none; }

.delivery-interval-group #delivery-periods {
  max-width: 50px; }

.trackingpage img.track_logo {
  height: 25px; }

.paymentmethodwarning {
  font-weight: bold;
  color: #BF242B; }

/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 * Note: media breakpoint at 960px and some specificities are kept to override Yotpo's styles
 */
.yotpo-small .yotpo-icon-star:before,
.yotpo.yotpo-main-widget .yotpo-icon-star:before,
.yotpo.mobile-filters-modal .yotpo-icon-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #000;
  font-weight: normal; }

.yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title),
.yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title),
.yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title):before,
  .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title):before,
  .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #000;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm, .yotpo-small .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title),
  .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title),
  .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo-small .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #000;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo-small .yotpo-icon-star[class*="btn-"]:not(.title) .fa,
  .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-star[class*="btn-"]:not(.title) .fa,
  .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo-small .yotpo-icon-half-star:before,
.yotpo.yotpo-main-widget .yotpo-icon-half-star:before,
.yotpo.mobile-filters-modal .yotpo-icon-half-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #000;
  font-weight: normal; }

.yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title),
.yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title),
.yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title):before,
  .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title):before,
  .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #000;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm, .yotpo-small .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title),
  .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title),
  .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo-small .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-half-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #000;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo-small .yotpo-icon-half-star[class*="btn-"]:not(.title) .fa,
  .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-half-star[class*="btn-"]:not(.title) .fa,
  .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-half-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo-small .yotpo-icon-empty-star:before,
.yotpo.yotpo-main-widget .yotpo-icon-empty-star:before,
.yotpo.mobile-filters-modal .yotpo-icon-empty-star:before {
  content: "";
  font-family: "icomoon";
  display: inline-block;
  font-size: inherit;
  color: #000;
  font-weight: normal; }

.yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title),
.yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title),
.yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title) {
  padding-left: 40px; }
  .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title):before,
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title):before,
  .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title):before {
    font-family: "icomoon";
    display: inline-block;
    font-size: 20px;
    color: #000;
    font-weight: normal;
    position: absolute;
    transform: translateX(-30px); }
  .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm, .yotpo-small .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title),
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title),
  .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm,
  .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title) {
    padding-left: 40px; }
    .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm:before, .yotpo-small .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.yotpo-main-widget .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before,
    .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title).btn-sm:before,
    .yotpo.mobile-filters-modal .btn-group-sm > .yotpo-icon-empty-star.btn[class*="btn-"]:not(.title):before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 22px;
      color: #000;
      font-weight: normal;
      position: absolute;
      transform: translateX(-32px); }
  .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo-small .yotpo-icon-empty-star[class*="btn-"]:not(.title) .fa,
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.yotpo-main-widget .yotpo-icon-empty-star[class*="btn-"]:not(.title) .fa,
  .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class*="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title) [class^="icon-"],
  .yotpo.mobile-filters-modal .yotpo-icon-empty-star[class*="btn-"]:not(.title) .fa {
    display: none; }

.yotpo.yotpo-main-widget div, .yotpo.yotpo-main-widget span, .yotpo.yotpo-main-widget p, .yotpo.yotpo-main-widget a, .yotpo.yotpo-main-widget img, .yotpo.yotpo-main-widget i, .yotpo.yotpo-main-widget strong, .yotpo.yotpo-main-widget sup, .yotpo.yotpo-main-widget ul, .yotpo.yotpo-main-widget li, .yotpo.yotpo-main-widget form, .yotpo.yotpo-main-widget label,
.yotpo.mobile-filters-modal div,
.yotpo.mobile-filters-modal span,
.yotpo.mobile-filters-modal p,
.yotpo.mobile-filters-modal a,
.yotpo.mobile-filters-modal img,
.yotpo.mobile-filters-modal i,
.yotpo.mobile-filters-modal strong,
.yotpo.mobile-filters-modal sup,
.yotpo.mobile-filters-modal ul,
.yotpo.mobile-filters-modal li,
.yotpo.mobile-filters-modal form,
.yotpo.mobile-filters-modal label {
  font-family: "Roboto", sans-serif !important;
  line-height: 1.5em; }

.yotpo.yotpo-main-widget .write-first-review-button,
.yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn {
  display: inline-block;
  position: relative;
  cursor: pointer;
  user-select: none;
  border-width: 1px;
  border-style: solid;
  font-family: "archia-medium", sans-serif;
  text-transform: none;
  transition: all 200ms ease-out;
  text-decoration: none;
  border-radius: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.42px;
  line-height: 1.5em;
  padding: 12px 20px 12px 20px;
  font-family: "archia-medium", sans-serif;
  text-transform: none;
  color: #FFF;
  background-color: #000;
  border-width: 1px;
  border-radius: 50px;
  border-color: #000;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  padding: 12px 20px 12px 20px;
  background-color: #000 !important;
  border: 1px solid #000 !important;
  height: auto;
  font-family: "Roboto", sans-serif; }
  .yotpo.yotpo-main-widget .write-first-review-button:hover, .yotpo.yotpo-main-widget .write-first-review-button:focus, .yotpo.yotpo-main-widget .write-first-review-button[aria-describedby*="popover"],
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn:hover,
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn:focus,
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn[aria-describedby*="popover"] {
    color: #FFF;
    background-color: #000;
    border-color: #FFF;
    text-decoration: none; }
  .yotpo.yotpo-main-widget .write-first-review-button[class*="icon-"], .yotpo.yotpo-main-widget .write-first-review-button[class^="icon-"],
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn[class*="icon-"],
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn[class^="icon-"] {
    padding-left: 40px; }
    .yotpo.yotpo-main-widget .write-first-review-button[class*="icon-"]:before, .yotpo.yotpo-main-widget .write-first-review-button[class^="icon-"]:before,
    .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn[class*="icon-"]:before,
    .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn[class^="icon-"]:before {
      font-family: "icomoon";
      display: inline-block;
      font-size: 20px;
      color: inherit;
      font-weight: normal;
      position: absolute;
      transform: translateX(-30px); }
  .yotpo.yotpo-main-widget .write-first-review-button:hover,
  .yotpo.yotpo-main-widget input.yotpo-default-button.primary-color-btn:hover {
    background-color: #000 !important;
    background: #000 !important;
    border: 1px solid #FFF !important; }

.yotpo-small .standalone-bottomline {
  display: flex; }

.yotpo-small .yotpo-bottomline .yotpo-stars {
  display: flex;
  align-items: center;
  cursor: default; }
  .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-star,
  .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-half-star,
  .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-empty-star {
    background-image: none !important; }
    .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-star::before,
    .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-half-star::before,
    .yotpo-small .yotpo-bottomline .yotpo-stars .yotpo-icon-empty-star::before {
      font-size: 16px; }

.yotpo-small .yotpo-bottomline .text-m {
  display: none;
  font-size: 13px;
  margin-left: 5px;
  color: #D6D6D6 !important; }
  .yotpo-small .yotpo-bottomline .text-m:hover {
    color: #595959 !important; }

.product-tile .yotpo-small .yotpo-bottomline .text-m {
  display: none;
  font-size: 11px;
  color: #D6D6D6 !important; }
  .product-tile .yotpo-small .yotpo-bottomline .text-m:hover {
    color: #595959 !important; }

.yotpo-small .yotpo-bottomline.pull-left.star-clickable {
  float: none;
  margin: 0 auto; }

.yotpo-small .yotpo-icon {
  font-family: "icomoon" !important; }
  .yotpo-small .yotpo-icon:before {
    font-family: "icomoon" !important; }

.yotpo-small .rating-star {
  height: auto;
  line-height: normal;
  vertical-align: middle;
  font-size: 14px !important; }
  .yotpo-small .rating-star:before {
    font-family: "icomoon" !important; }
  .product-tile .yotpo-small .rating-star {
    font-size: 11px !important; }

.yotpo-widget-instance #yotpo-reviews-main-widget, .yotpo-widget-instance #yotpo-app {
  --primary-font-weight: 500; }

.yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-title-text, .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-title, .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-new-review-btn, .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-score {
  font-weight: 500; }

.yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-score {
  font-family: "Roboto", sans-serif; }

.yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-base-layout {
  width: 100% !important; }

@media screen and (min-width: 480px) and (max-width: 768px) {
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-layout-header-wrapper, .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-bottom-line {
    flex-direction: column; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-bottom-line {
    margin-bottom: 48px;
    margin-right: 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-summary-section {
    align-items: center; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-bottom-line .yotpo-bottom-line-right-panel {
    padding-right: 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-star-distribution {
    border-right: 0 !important;
    margin-top: 12px; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-filters-top-panel {
    flex-wrap: wrap;
    gap: 12px; }
    .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-filters-top-panel > * {
      margin-right: 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-filters-container-inner {
    gap: 12px; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-left-panel, .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-right-panel {
    flex-basis: 50%;
    order: 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-right-panel {
    margin-top: 6px; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-center-panel {
    flex-basis: 100%;
    margin-top: 12px;
    order: 1;
    padding: 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-rating-title {
    display: unset; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-title {
    margin: 12px 0; }
  .yotpo-widget-instance #yotpo-reviews-main-widget .yotpo-review-star-rating {
    margin-bottom: 12px; } }

.yotpo-reviews-main-widget #newReviewFormModal {
  z-index: 400; }

.yotpo-reviews-main-widget #yotpo-modal {
  margin: 24px;
  overflow-x: unset; }
  @media screen and (min-width: 480px) and (max-width: 768px) {
    .yotpo-reviews-main-widget #yotpo-modal {
      max-width: 100%; } }
  .yotpo-reviews-main-widget #yotpo-modal .yotpo-modal-header, .yotpo-reviews-main-widget #yotpo-modal .yotpo-form-label, .yotpo-reviews-main-widget #yotpo-modal .yotpo-file-input-headline, .yotpo-reviews-main-widget #yotpo-modal .yotpo-upload-label {
    font-weight: 500; }
  .yotpo-reviews-main-widget #yotpo-modal .yotpo-uploaded-images {
    width: 100%; }

.returns-progress-container {
  padding-bottom: 10px; }

.returns-progress {
  text-align: center;
  height: 50px; }

.return-progress-next {
  clear: both; }

.progress-rectangle {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #eee;
  padding-top: 12px;
  border-radius: 10px 0px 0px 10px; }
  .progress-rectangle.active {
    background-color: #0C0A08;
    color: #fff; }
  .progress-rectangle.final {
    border-radius: 10px 10px 10px 10px; }

.progress-triangle {
  width: 0px;
  height: 0px;
  display: inline;
  border-style: solid;
  border-width: 25px 0 25px 15px;
  border-color: transparent transparent transparent #eee;
  position: absolute; }
  .progress-triangle.active {
    border-color: transparent transparent transparent #0C0A08; }
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) {
      .progress-triangle {
        top: 0;
        right: 1px; } } }

.nearest-loc-address {
  padding-top: 15px; }

.return-map-container {
  margin-top: 20px; }

.show-more-locations {
  margin-top: 20px; }

.location-address {
  border: solid 1px #0C0A08;
  padding: 10px;
  margin-bottom: 10px; }

.more-address-results {
  margin-top: 30px; }

.label-image img {
  width: 100%;
  border: solid 1px;
  margin-bottom: 20px; }

.sub-reasons {
  margin-top: 20px;
  margin-bottom: 20px; }

.nearest-location, .location-progress {
  margin-top: 20px; }

.previous-label {
  margin-top: 20px; }

.js-select-return-items .line-item-header .remove-line-item.d-lg-none {
  display: none !important; }

.line-item-disabled * {
  color: rgba(190, 190, 190, 0.849) !important; }

.label-image .sample-label-image {
  width: 100px; }

.returns-progress-container {
  padding-bottom: 10px; }

.returns-progress {
  text-align: center;
  height: 50px; }

.return-progress-next {
  clear: both; }

.progress-rectangle {
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: #eee;
  padding-top: 12px;
  border-radius: 10px 0px 0px 10px; }
  .progress-rectangle.active {
    background-color: #0C0A08;
    color: #fff; }
  .progress-rectangle.final {
    border-radius: 10px 10px 10px 10px; }

.progress-triangle {
  width: 0px;
  height: 0px;
  display: inline;
  border-style: solid;
  border-width: 25px 0 25px 15px;
  border-color: transparent transparent transparent #eee;
  position: absolute; }
  .progress-triangle.active {
    border-color: transparent transparent transparent #0C0A08; }

.nearest-loc-address {
  padding-top: 15px; }

.return-map-container {
  margin-top: 20px; }

.show-more-locations {
  margin-top: 20px; }

.location-address {
  border: solid 1px #0C0A08;
  padding: 10px;
  margin-bottom: 10px; }

.more-address-results {
  margin-top: 30px; }

.label-image img {
  width: 100%;
  border: solid 1px;
  margin-bottom: 20px; }

.sub-reasons {
  margin-top: 20px;
  margin-bottom: 20px; }

.nearest-location, .location-progress {
  margin-top: 20px; }

.previous-label {
  margin-top: 20px; }

.js-select-return-items .line-item-header .remove-line-item.d-lg-none {
  display: none !important; }

.line-item-disabled * {
  color: rgba(190, 190, 190, 0.849) !important; }

.label-image .sample-label-image {
  width: 100px; }

.track-checkmarks ul.checkmark {
  position: relative;
  display: flex;
  max-width: 1000px;
  z-index: 1;
  padding-inline-start: 0px;
  margin-bottom: 1.25rem;
  font-size: 0; }
  .track-checkmarks ul.checkmark li {
    width: auto;
    height: 3em;
    line-height: 3em;
    margin: 0;
    border-radius: 0;
    background: transparent;
    color: #999;
    vertical-align: top;
    position: relative;
    display: inline-block;
    font-size: 12px;
    flex-grow: 1;
    text-align: right; }
    .track-checkmarks ul.checkmark li .title {
      display: inline-block;
      font-weight: normal;
      color: #999;
      line-height: normal;
      position: absolute;
      bottom: auto;
      top: 120%;
      transform: translateX(-50%);
      width: auto;
      margin-top: 0px;
      margin-left: 0px;
      font-size: 11px;
      letter-spacing: normal;
      white-space: nowrap; }
    .track-checkmarks ul.checkmark li span {
      width: 3em;
      height: 3em;
      text-align: center;
      line-height: 3em;
      background: #ebebeb;
      border: 2px solid #cecece;
      border-radius: 50%;
      margin: 0;
      display: inline-block;
      color: white;
      position: relative; }
    .track-checkmarks ul.checkmark li:before {
      display: block; }
    .track-checkmarks ul.checkmark li:after {
      content: none; }
    .track-checkmarks ul.checkmark li:first-child {
      width: 3em;
      flex-grow: 0; }
      .track-checkmarks ul.checkmark li:first-child:before {
        content: none; }
    .track-checkmarks ul.checkmark li:not(:first-child):before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: .2em;
      background: #cecece;
      z-index: -1; }
    .track-checkmarks ul.checkmark li:not(:first-child).active:before {
      background: #0C0A08; }
    .track-checkmarks ul.checkmark li.active span {
      background-color: #0C0A08;
      border-color: #0C0A08; }
      .track-checkmarks ul.checkmark li.active span:after {
        content: " ";
        display: block;
        width: .4em;
        height: .8em;
        border: solid #fff;
        border-width: 0 .1em .1em 0;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-top: -.2em;
        transform: translateX(-50%) translateY(-50%) rotate(45deg); }

.action-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }
  @media (min-width: 769px) {
    .action-container {
      flex-direction: row; } }
  .action-container .action-links {
    width: 100%; }
    @media (min-width: 769px) {
      .action-container .action-links {
        width: 250px; } }

.card .returns-body.card-body {
  padding-bottom: 0; }
  .card .returns-body.card-body:last-child {
    border: none; }
  .card .returns-body.card-body .return-progress-next .btn-primary {
    margin-bottom: 20px; }

.card-tracking .card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px; }
  @media (min-width: 1024px) {
    .card-tracking .card-header {
      padding-top: 20px; } }
  .card-tracking .card-header > h2.pull-left {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0; }
  .card-tracking .card-header > .pull-right {
    display: flex;
    align-items: center;
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0; }
    .card-tracking .card-header > .pull-right > img {
      margin-left: 8px; }

.card-tracking .card-body {
  padding: 0 12px; }

.card-tracking .track-checkmarks {
  padding-top: 0; }

.card-tracking-travelhistory {
  border-top: 1px solid #D6D6D6; }

.trackingpage {
  padding-top: 16px; }
  .trackingpage .track-status-top {
    font-size: 12px;
    line-height: 1.1; }
  .trackingpage .card-product-list .card {
    margin-bottom: -1px; }

.track-status-conainer {
  margin-left: 0; }
  @media (min-width: 544px) {
    .track-status-conainer {
      margin-left: 120px; } }
  @media (min-width: 769px) {
    .track-status-conainer {
      margin-left: 0; } }
  @media (min-width: 1024px) {
    .track-status-conainer {
      margin-left: 120px; } }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?9bo02z");
  src: url("../fonts/icomoon.eot?9bo02z#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?9bo02z") format("truetype"), url("../fonts/icomoon.woff?9bo02z") format("woff"), url("../fonts/icomoon.svg?9bo02z#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-down:before {
  content: ""; }

.icon-arrow-left:before {
  content: ""; }

.icon-arrow-right:before {
  content: ""; }

.icon-arrow-up:before {
  content: ""; }

.icon-close:before {
  content: ""; }

.icon-plus:before {
  content: ""; }

.icon-minus:before {
  content: ""; }

.icon-nav:before {
  content: ""; }

.icon-account:before {
  content: ""; }

.icon-box:before {
  content: ""; }

.icon-cart:before {
  content: ""; }

.icon-clock:before {
  content: ""; }

.icon-cog:before {
  content: ""; }

.icon-drop:before {
  content: ""; }

.icon-key:before {
  content: ""; }

.icon-location-pin:before {
  content: ""; }

.icon-lock:before {
  content: ""; }

.icon-magnifing-glass:before {
  content: ""; }

.icon-ruler:before {
  content: ""; }

.icon-star-empty:before {
  content: ""; }

.icon-star-full:before {
  content: ""; }

.icon-star-half:before {
  content: ""; }

.icon-truck:before {
  content: ""; }

.icon-notification:before {
  content: ""; }

.icon-question:before {
  content: ""; }

.icon-info:before {
  content: ""; }

.icon-check-circle:before {
  content: ""; }

.icon-instagram:before {
  content: ""; }

.icon-facebook:before {
  content: ""; }

.icon-tiktok:before {
  content: ""; }

.icon-twitter:before {
  content: ""; }

.icon-youtube:before {
  content: ""; }

.icon-check:before {
  content: ""; }

.icon-filter:before {
  content: ""; }

.icon-play:before {
  content: ""; }

/*
* GRID SYSTEM
*/
/*
* Z Indexes
*/
/*
* ASPECT RATIOS
*/
/*
* MISC
*/
/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
/*
* TEXT
*/
/*
* HEADERS
*/
/*
* BUTTONS
*/
/*
* FORMs
*/
/*
* BREADCRUMBS
*/
/*
* TOOLTIPS
*/
/*
* POPOVERS
*/
/*
* MODALS
*/
/*
* ALERTS (also affects toast messages)
*/
/*
* TOAST MESSAGES
*/
/*
* CARDS
*/
/*
* COLLAPSIBLE
*/
/*
* TABS - PDP
*/
/*
* SLIDER
*/
/*
* DROPDOWNS
*/
/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
/*
* HEADER
*/
/*
* FOOTER
*/
/*
* PAGE-LEVEL SETTINGS
*/
/*
* PRODUCT
*/
/*
* MINICART
*/
/*
* CART AND CHECKOUT
*/
/*
* PAGE DESIGNER MODULES
*/
/*
* HERO ASSETS
*/
/*
* CATEGORY TILE ASSETS
*/
/*
* IMAGE TEXT BLOCK ASSETS
*/
/*
* PHOTO HOTSPOTS ASSETS
*/
/*
* VIDEO ASSETS
*/
/*
* BLOG LANDING
*/
/*
* BLOG DETAIL
*/
/*
* CONTENT TILES
*/
/*
* STORE LOCATOR
*/
/*
* STORE DETAIL PAGE
*/
/*
* ACCOUNT
*/
/*
* GIFTCERTIFICATES
*/
/*
* PAYMENTMETHODS
*/
/*
* ACCESSIBILITY
*/
/*
* CONSENT DRAWER
*/
/*
* PRELOADER
*/
.help-center .sidebar-wrapper .card {
  background-color: #F8F8F8;
  border-color: #D6D6D6; }
  @media (min-width: 1024px) {
    .help-center .sidebar-wrapper .card {
      border: 1px solid #F8F8F8;
      padding: 16px; } }
  @media (max-width: 1023.98px) {
    .help-center .sidebar-wrapper .card .card-title {
      position: relative;
      padding-top: 16px;
      padding-bottom: 16px;
      font-style: normal; }
      .help-center .sidebar-wrapper .card .card-title:after {
        content: '';
        width: 100%;
        height: 1px;
        background-color: #F8F8F8;
        position: absolute;
        bottom: 0;
        left: 0; } }
  @media (min-width: 1024px) {
    .help-center .sidebar-wrapper .card .card-title {
      font-size: 36px; } }
  .help-center .sidebar-wrapper .card .card-body {
    background-color: inherit;
    overflow: hidden;
    transition: all .3s ease; }
    @media (max-width: 1023.98px) {
      .help-center .sidebar-wrapper .card .card-body {
        max-height: 0; } }
    .help-center .sidebar-wrapper .card .card-body li:first-child {
      margin-top: 16px; }
    .help-center .sidebar-wrapper .card .card-body li:last-child {
      margin-bottom: 16px; }
    .help-center .sidebar-wrapper .card .card-body a {
      text-decoration: none;
      display: block;
      background-color: #FFF;
      border: 1px solid #F8F8F8;
      padding: 8px 16px;
      margin-bottom: 8px; }
      .help-center .sidebar-wrapper .card .card-body a.active {
        border-color: #000;
        background-color: #000;
        color: #FFF; }
        .help-center .sidebar-wrapper .card .card-body a.active svg path {
          fill: #FFF; }
      @media (min-width: 1024px) {
        .help-center .sidebar-wrapper .card .card-body a {
          padding: 16px; } }
      .help-center .sidebar-wrapper .card .card-body a svg {
        max-width: 20px;
        max-height: 23px;
        margin-right: 16px; }

@media (max-width: 1023.98px) {
  .help-center .sidebar-wrapper {
    padding-left: 0 !important;
    padding-right: 0 !important; } }

.help-center .accordion .accordion__item .accordion__item__label {
  padding: 24px 0;
  border-bottom: 1px solid #D6D6D6; }
  @media (min-width: 1024px) {
    .help-center .accordion .accordion__item .accordion__item__label {
      padding: 48px 0; } }
  .help-center .accordion .accordion__item .accordion__item__label a {
    text-decoration: none;
    position: relative;
    display: block;
    font-size: 16px;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    padding-right: 20px; }
    @media (min-width: 1024px) {
      .help-center .accordion .accordion__item .accordion__item__label a {
        font-family: "archia-medium", sans-serif;
        font-style: italic;
        text-transform: uppercase;
        font-size: 30px; } }
    .help-center .accordion .accordion__item .accordion__item__label a:after {
      content: "\E906";
      font-family: "icomoon";
      display: inline-block;
      position: absolute;
      top: 0;
      right: 0;
      font-size: 16px;
      font-weight: 700;
      font-style: normal; }
      @media (min-width: 1024px) {
        .help-center .accordion .accordion__item .accordion__item__label a:after {
          font-size: 24px; } }
    .help-center .accordion .accordion__item .accordion__item__label a.collapsed:after {
      content: "\E905"; }

.help-center .accordion .accordion {
  position: relative;
  padding-left: 8px; }
  @media (min-width: 1024px) {
    .help-center .accordion .accordion {
      padding-left: 24px; } }
  .help-center .accordion .accordion:before {
    content: '';
    width: 8px;
    background-color: #F8F8F8;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
    @media (min-width: 1024px) {
      .help-center .accordion .accordion:before {
        width: 24px; } }
  .help-center .accordion .accordion .accordion__item {
    margin-left: 24px;
    border-bottom: 1px solid #D6D6D6; }
    .help-center .accordion .accordion .accordion__item:last-child {
      margin-left: 0;
      padding-left: 24px; }
  .help-center .accordion .accordion .accordion__item__label {
    border-bottom: none; }
    @media (min-width: 1024px) {
      .help-center .accordion .accordion .accordion__item__label {
        padding: 32px 0; } }
    .help-center .accordion .accordion .accordion__item__label a {
      color: #000;
      font-weight: normal;
      font-size: 14px; }
      @media (min-width: 1024px) {
        .help-center .accordion .accordion .accordion__item__label a {
          font-family: "Roboto", sans-serif;
          text-transform: none; } }
  .help-center .accordion .accordion .accordion__item__content {
    padding-top: 12px;
    padding-bottom: 16px; }

.help-center .grid {
  grid-template-columns: 1fr 1fr;
  gap: 32px; }
  @media (min-width: 1024px) {
    .help-center .grid {
      grid-template-columns: 1fr 1fr 1fr 1fr; } }
  .help-center .grid .grid-cell {
    border: 1px solid #D6D6D6;
    text-decoration: none;
    padding-top: 32px;
    padding-bottom: 32px; }
    @media (min-width: 1024px) {
      .help-center .grid .grid-cell {
        padding-top: 64px;
        padding-bottom: 64px; } }
    .help-center .grid .grid-cell .icon {
      margin-bottom: 32px; }

.help-center .bc h4, .help-center .bc .h4 {
  line-height: normal;
  font-style: italic; }

.help-center .bc a {
  text-decoration: none;
  margin-bottom: 0; }
  .help-center .bc a:hover {
    color: #FFF; }
  .help-center .bc a .icon-arrow-left-thin {
    top: 2px;
    position: relative; }
    .help-center .bc a .icon-arrow-left-thin:before {
      color: #FFF; }

.help-center .form-control,
.help-center textarea {
  border-radius: 8px; }

@media (max-width: 1023.98px) {
  .help-center .action,
  .help-center .purchase-date {
    margin-top: 24px; } }

@media (min-width: 1024px) {
  .help-center .store-locator-form {
    border-top: 1px solid #D6D6D6; } }

.help-center .store-locator-form button {
  font-family: "Roboto", sans-serif;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 500; }

.help-center .input-hidden {
  height: 0;
  width: 0;
  visibility: hidden;
  padding: 0;
  margin: 0;
  border: none; }

.help-center .is-invalid {
  border-color: #BF242B !important;
  border-width: 2px !important; }

.table .thead-dark th {
  background-color: #0C0A08;
  border-color: #0C0A08; }

.help-center .warranty-policy {
  border-radius: 16px;
  padding: 25px 16px;
  background-color: #F8F8F8; }
  @media (min-width: 1024px) {
    .help-center .warranty-policy {
      padding: 32px 24px; } }
  .help-center .warranty-policy h3 {
    font-style: normal;
    margin-bottom: 32px; }

.help-center #attachments {
  border-radius: 8px;
  height: 48px;
  line-height: 48px;
  border: 1px solid #D6D6D6;
  cursor: pointer; }
  .help-center #attachments > * {
    pointer-events: none; }

.help-center #upload-template {
  display: none; }

.help-center .file-upload-outer .file-upload-inner {
  border-radius: 4px; }
  .help-center .file-upload-outer .file-upload-inner:hover {
    background-color: #F8F8F8; }

.help-center .file-upload-outer .progress {
  height: 4px; }

@supports (-webkit-touch-callout: none) {
  .help-center input[type=date] {
    padding: 0; }
    @media (max-width: 1023.98px) {
      .help-center input[type=date] {
        width: calc(100dvw - (12px * 4)); } }
  .help-center ::-webkit-date-and-time-value {
    padding-left: 4px;
    padding-top: 24px;
    text-align: left; } }

div#zendeskInitBtnContainer {
  z-index: 1;
  right: 0;
  left: unset; }

.map-canvas {
  height: 400px;
  width: 100vw;
  margin-left: -16px; }
  @media (min-width: 1024px) {
    .map-canvas {
      height: 875px;
      width: auto;
      margin-left: initial; } }

@media (min-width: 1024px) {
  .store-results {
    max-height: 498px;
    overflow-y: scroll; } }

.result-store {
  border-bottom: 1px solid #D6D6D6;
  padding: 2rem 0; }
  .result-store .map-marker {
    width: 38px;
    height: 38px; }
    .result-store .map-marker span {
      width: 38px;
      transform: translate(50%, -50%);
      top: 50%; }
  .result-store .store-name {
    text-transform: uppercase;
    font-weight: 700;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    letter-spacing: normal;
    font-size: 16px; }

.bottom-fade__white {
  display: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  height: 100px;
  background: linear-gradient(0, white, transparent);
  z-index: 99;
  pointer-events: none; }
  @media (min-width: 1024px) {
    .bottom-fade__white {
      display: block; } }

.size-chart {
  font-family: "Roboto", sans-serif;
  /****** tabs ******/
  /***** end of tabs ******/
  /***** accordions ******/
  /***** end of accordions ******/ }
  .size-chart .tabs {
    margin-top: 1px;
    padding-top: 0px;
    border-bottom: 1px solid;
    border-bottom-color: #D6D6D6; }
  .size-chart .pill-tabs {
    border-bottom-color: #D6D6D6;
    margin-right: -16px;
    margin-left: -16px;
    width: 100vw; }
    @media (min-width: 1024px) {
      .size-chart .pill-tabs {
        margin-right: 0px;
        margin-left: 0px;
        width: 100%; } }
    .size-chart .pill-tabs .nav-item {
      flex-basis: 0; }
      @media (min-width: 1024px) {
        .size-chart .pill-tabs .nav-item {
          border: 1px solid #D6D6D6; } }
      @media (max-width: 1023.98px) {
        .size-chart .pill-tabs .nav-item + .nav-item {
          border-left: 1px solid #D6D6D6; } }
    .size-chart .pill-tabs .nav-link {
      font-family: "archia-medium", sans-serif;
      font-size: 13px;
      line-height: 22px;
      font-weight: 700; }
      .size-chart .pill-tabs .nav-link.active {
        background-color: #000; }
  @media (max-width: 1023.98px) {
    .size-chart .underline-tabs {
      margin-bottom: 26px; } }
  .size-chart .underline-tabs .nav-item {
    margin: 0px; }
    @media (max-width: 1023.98px) {
      .size-chart .underline-tabs .nav-item {
        flex: 1 1 0; } }
  .size-chart .underline-tabs .nav-link {
    font-size: 14px; }
    .size-chart .underline-tabs .nav-link.active {
      border-bottom: 2px solid #000; }
  @media (max-width: 1023.98px) {
    .size-chart .underline-tabs + .tab-content {
      margin-left: -16px;
      margin-right: -16px; } }
  .size-chart .nav {
    justify-content: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    flex-wrap: nowrap; }
    @media (min-width: 1024px) {
      .size-chart .nav {
        width: 30%; } }
  .size-chart .nav-link {
    border-radius: initial;
    text-decoration: none; }
  .size-chart .accordion__item {
    border-bottom: none;
    padding-bottom: 0px; }
    @media (min-width: 1024px) {
      .size-chart .accordion__item {
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 25px; } }
  @media (max-width: 1023.98px) {
    .size-chart .accordion__item__label {
      margin-left: 16px;
      margin-right: 16px; } }
  .size-chart .accordion .accordion__item:not(.nested-accordion) .accordion__item__label {
    padding: 18px 0px; }
    @media (min-width: 1024px) {
      .size-chart .accordion .accordion__item:not(.nested-accordion) .accordion__item__label {
        border: none;
        padding: 50px 0px 24px; } }
  @media (min-width: 1024px) {
    .size-chart .collapse {
      margin-bottom: 20px; } }
  @media (max-width: 1023.98px) {
    .size-chart .accordion .accordion {
      padding-left: 0px;
      margin-left: 16px;
      background-color: #F8F8F8; } }
  @media (max-width: 1023.98px) {
    .size-chart .accordion .accordion:before {
      content: none; } }
  @media (max-width: 1023.98px) {
    .size-chart .accordion .accordion .accordion__item__content {
      background-color: #F8F8F8; } }
  @media (max-width: 1023.98px) {
    .size-chart .accordion .accordion .accordion__item:last-child {
      margin-left: 12px;
      padding-left: 0px; } }
  .size-chart .accordion .accordion .accordion__item {
    background-color: #FFF;
    margin-left: 12px; }
    @media (min-width: 1024px) {
      .size-chart .accordion .accordion .accordion__item {
        margin-left: 24px; } }
  .size-chart .accordion .accordion .accordion__item__label a {
    color: #000;
    font-size: 16px;
    font-weight: 700; }
    @media (min-width: 1024px) {
      .size-chart .accordion .accordion .accordion__item__label a {
        font-family: "archia-medium", sans-serif;
        font-style: italic;
        text-transform: uppercase;
        font-size: 30px; } }
  .size-chart .nested-accordion {
    border: none;
    padding-bottom: 0px; }
  .size-chart h1 {
    margin-top: 30px;
    margin-bottom: 56px; }
    @media (min-width: 1024px) {
      .size-chart h1 {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
        line-height: 72px; } }

.metric-tabs-container {
  padding-top: 30px; }
  @media (max-width: 1023.98px) {
    .metric-tabs-container {
      padding: 30px 15px;
      background-color: #F8F8F8; } }
  .metric-tabs-container .tabs {
    border: none; }
  .metric-tabs-container .nav {
    border: solid 1px #000;
    border-radius: 31px;
    margin: 0;
    width: 6em; }
    @media (min-width: 1024px) {
      .metric-tabs-container .nav {
        width: 9em; } }
  .metric-tabs-container .metric-navs {
    margin-bottom: 36px;
    flex-wrap: nowrap;
    justify-content: flex-start; }
  .metric-tabs-container .nav-item {
    margin: -1px;
    flex-basis: 0; }
  .metric-tabs-container .nav-link {
    border-radius: 31px;
    font-size: 10px;
    line-height: 14px;
    padding: 1em; }
    @media (min-width: 1024px) {
      .metric-tabs-container .nav-link {
        font-size: 12px; } }
    .metric-tabs-container .nav-link.active {
      background-color: #000; }
  .metric-tabs-container .tab-content {
    overflow-y: auto; }
  .metric-tabs-container .tooltip-icon {
    vertical-align: top;
    font-size: 15px;
    top: 0; }
    @media (max-width: 1023.98px) {
      .metric-tabs-container .tooltip-icon {
        display: none; } }
  .metric-tabs-container table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
    min-width: 622px; }
    @media (min-width: 1024px) {
      .metric-tabs-container table {
        font-size: 16px;
        line-height: 18.75px; } }
  .metric-tabs-container tr {
    background-color: #F8F8F8; }
  .metric-tabs-container th {
    background-color: #000;
    color: #FFF;
    font-weight: 700; }
    .metric-tabs-container th:not(:first-child) {
      text-transform: uppercase; }
  .metric-tabs-container td {
    font-weight: 400; }
    @media (max-width: 1023.98px) {
      .metric-tabs-container td {
        background-color: #FFF; } }
  .metric-tabs-container th,
  .metric-tabs-container td {
    font-size: 13px;
    line-height: 15.23px;
    padding: 1.5em;
    text-align: center; }
    @media (max-width: 1023.98px) {
      .metric-tabs-container th,
      .metric-tabs-container td {
        min-width: 20vw;
        width: 20vw;
        max-width: 20vw; } }
    @media (min-width: 1024px) {
      .metric-tabs-container th,
      .metric-tabs-container td {
        font-size: 16px;
        line-height: 18.75px; } }
    .metric-tabs-container th:first-child,
    .metric-tabs-container td:first-child {
      text-transform: capitalize; }
  .metric-tabs-container th + th,
  .metric-tabs-container td + td {
    border-left: 3px solid #F8F8F8; }
    @media (min-width: 1024px) {
      .metric-tabs-container th + th,
      .metric-tabs-container td + td {
        border-left: 3px solid #FFF; } }
  .metric-tabs-container tr + tr {
    border-top: 3px solid #F8F8F8; }
    @media (min-width: 1024px) {
      .metric-tabs-container tr + tr {
        border-top: 3px solid #FFF; } }

.tooltip {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 21px; }
  .tooltip .tooltip-inner {
    text-align: left; }

/*Size Chart Mobile */
.size-chart-container {
  padding: 48px 0;
  margin-right: -24px;
  margin-left: -24px; }

.size-chart-container .tab-pane {
  padding-left: 24px; }

.size-chart-container > h4 {
  padding: 0 24px; }

@media screen and (min-width: 1024px) {
  /*Size Chart Desktop */
  .size-chart-container {
    padding: 48px 0;
    margin-right: 0;
    margin-left: 0; }
  .size-chart-container .tab-pane {
    padding-left: 0; }
  .size-chart-container > h4 {
    padding: 0; } }

.help-center .d-grid {
  display: grid; }
