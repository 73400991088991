

.info-icon,
.tooltip-icon {
    @include icon($tooltip-icon, before, $tooltip-icon-size, $brand-01);
    border: none;
    padding: 0;
    position: relative;
    top: -2px;
    background: inherit;
    display: inline-block;
    cursor: pointer;
    font: inherit;
    .icon {
        display: none;
    }
    &:hover:before,
    &[aria-describedby*="tooltip"]:before {
        color: $grey-40;
    }
}

.custom-tooltip {
    cursor: pointer;
    position: relative;
    padding: $tooltip-arrow-height 0;
    &.repositioned > ::after {
        display: none;
    }
    &.repositioned::after {
        opacity: 0;
        visibility: hidden;
        border: solid transparent;
        border-top-color: $tooltip-background-color;
        border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
        content: '';
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        transition: visibility $transition-speed, opacity ease $transition-speed;
        z-index: 1070;
    }
    .custom-tooltip-content {
        background-color: $tooltip-background-color;
        border-radius: $tooltip-border-radius;
        bottom: 100%;
        box-shadow: $tooltip-box-shadow;
        cursor: auto;
        font-size: $tooltip-font-size;
        left: 50%;
        opacity: 0;
        padding: $tooltip-padding;
        position: absolute;
        transform: translateX(-50%);
        transition: visibility $transition-speed, opacity ease $transition-speed;
        visibility: hidden;
        z-index: $zindex-tooltip;
        &[data-width] {
            width: max-content;
        }
        &[data-width='small'] {
            max-width: 100px;
        }
        &[data-width='medium'] {
            max-width: 200px;
        }
        &[data-width='large'] {
            max-width: 400px;
        }
        &[data-width='xlarge'] {
            max-width: 600px;
        }
        &::after {
            border: solid transparent;
            border-top-color: $tooltip-background-color;
            border-width: $tooltip-arrow-height ($tooltip-arrow-width / 2) 0;
            content: '';
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translateX(-50%);
        }
    }
    &.visible {
        .custom-tooltip-content {
            opacity: 1;
            visibility: visible;
        }
        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

.tooltip {
    &.bs-tooltip-right .arrow:before {
        border-right-color: $tooltip-background-color;
    }
    &.bs-tooltip-left .arrow:before {
        border-left-color: $tooltip-background-color;
    }
    &.bs-tooltip-top .arrow:before {
        border-top-color: $tooltip-background-color;
    }
    &.bs-tooltip-bottom .arrow:before {
        border-bottom-color: $tooltip-background-color;
    }
    .tooltip-inner {
        border-radius: $tooltip-border-radius;
        padding: $tooltip-padding;
        text-align: $tooltip-text-align;
        max-width: $tooltip-max-width;
        box-shadow: $tooltip-box-shadow;
        &:not([class*='background--']) {
            background: $tooltip-background-color;
        }
        &:not([class*='text--']) {
            color: $tooltip-text-color;
        }
        &:not([class*='font-size--']) {
            font-size: $tooltip-font-size;
        }
    }
    &.show {
        opacity: $tooltip-opacity;
    }
}