.product-wrapper.product-detail {
    margin-bottom: 0;
    
    .product-container-wrapper {
        @include media-breakpoint-up(md) {
            display: flex;
            gap: $spacer * 4;
            justify-content: center;
        }
    }

    .product-data {
        margin-bottom: $spacer;

        @include media-breakpoint-up(md) {
            width: 350px;
            max-width: 350px;
            min-width: 350px;
        }

        @include media-breakpoint-up(lg) {
            width: 421px;
            max-width: 421px;
            min-width: 350px;
        }
    }

    .badge-product-container {
        margin-bottom: $spacer * 2;

        .badge-product.badge_sale {
            border-color: $black;
        }
    }
    
    .product-detail-review-container {
        margin-bottom: 18px;

        a {
            display: flex;
            text-decoration: none;
        }

        &.lower:has(~ .product-explicit-recommendations) {
            margin-bottom: 0;
        }

        &.lower a {
            padding: #{($spacer * 2)} 0;
            border-bottom: 1px solid $grey-20;
            justify-content: space-between;
        }
    }

    .product-rating-count {
        display: none;
    }

    .product-rating-stars {
        display: flex;
        margin-left: $spacer / 2;

        .rating-icon {
            display: block;
            width: 15px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
        }
        
        .rating-icon-star {
            background-image: url('../images/icons/icon-star.svg');
        }

        .rating-icon-star-half {
            background-image: url('../images/icons/icon-star-half.svg');
        }

        .rating-icon-star-empty {
            background-image: url('../images/icons/icon-star-empty.svg');
        }
    }

    .product-name {
        color: $pdp-name-header-color;
        margin-bottom: $spacer * 2;

        a {
            text-decoration: none;
        }
    }

    .prices {
        margin-top: 0;
        margin-bottom: $spacer * 2;

        .price {
            @include header(6);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .price-discount {
            @include paragraph-font-size(normal);
            @include price-discount($red, $red, $white);
            font-weight: 400; // Prevent h6 .price font-weight from applying
            padding: ($spacer / 3) ($spacer + ($spacer / 3));
            border-radius: $spacer / 4;
            text-transform: uppercase;
        }
    }

    .product-number-rating {
        line-height: 1;
        margin-bottom: $spacer;
    }

    .ratings {
        margin-left: 0;
        display: flex;
        align-items: center;

        .ratings-link {
            padding-right: 7px;

            @include media-breakpoint-up(md) {
                padding-right: 17px;
            }

            &:hover {
                opacity: .5;
            }
        }
    }

    .product-number {
        font-family: $primary-font;
        color: $grey-30;
        margin-bottom: $spacer * 2;
        font-weight: 500;
    }

    .product-detail-attributes,
    .bundle-items .attributes {
        border-top: 1px solid $grey-20;
        padding-top: $spacer * 2;

        .attribute {
            margin-bottom: $spacer * 2;
        }

        .non-input-label {
            margin-bottom: $spacer;

            &.size {
                display: flex;
                width: 100%;
                justify-content: space-between;

                .non-color-display-value {
                    flex-grow: 2;
                }
            }
        }
    }

    .swatch-tile-container {
        display: flex;
        flex-wrap: wrap;
        gap: 5px;

        &.color-tile {
            width: calc(100% + (#{$spacer} * 4));
            margin: 0 (#{-$spacer * 2});
            padding: 0 (#{$spacer * 2});
            overflow-x: scroll;
            flex-wrap: nowrap;

            @include media-breakpoint-up(md) {
                overflow-x: unset;
                flex-wrap: wrap;
            }
        }
    }

    .swatch {
        &.color-value,
        &.size-value {
            color: $black;
            display: flex;
            justify-content: center;
            align-items: center;
            width: calc((100vw / 5) - 14px);
            height: calc((100vw / 5) - 14px);
            border: 1px solid $grey-20;
            border-radius: 3px;
            background-size: ($pdp-swatch-size - 16px) ($pdp-swatch-size - 16px);
            background-repeat: no-repeat;
            background-position: 50% 50%;

            @include media-breakpoint-up(md) {
                width: $pdp-swatch-size;
                height: $pdp-swatch-size;
            }

            &.selected {
                border-color: $black;
            }

            &.bis-enabled.unavailable {
                position: relative;
                &::before {
                    display: inline-block;
                    content: '';
                    position: absolute;
                    top: 3px;
                    left: 4px;
                    width: 17px;
                    height: 17px;
                    background-image: url('../images/icons/bell.svg');
                    background-size: contain;
                    background-repeat: no-repeat;
                }
                &::after {
                    display: inline-block;
                    content: '';
                    border-top: 1px solid $grey-20;
                    width: calc(((100vw / 5) - 18px) * 1.44);
                    position: absolute;
                    transform: rotate(-45deg);
                    @include media-breakpoint-up(md) {
                        width: ($pdp-swatch-size * 1.44) - 5px;
                    }
                }
            }
        }

        &.color-value {
            background-color: $grey-10;
        }

        &.size-value {
            &.unavailable:not(.bis-enabled) {
                &::before,
                &::after {
                    display: inline-block;
                    content: '';
                    border-top: 1px solid $grey-20;
                    width: calc(((100vw / 5) - 18px) * 1.44);
                    @include media-breakpoint-up(md) {
                        width: ($pdp-swatch-size * 1.44) - 5px;
                    }
                    position: absolute;
                }
                &::before {
                    transform: rotate(45deg);
                }

                &::after {
                    transform: rotate(-45deg);
                }
            }
        }
    }

    .size-chart {
        a.size-chart-launcher,
        a.size-chart-anchor {
            position: relative;
            color: $grey-30;
            padding-bottom: 3px;

            &::before {
                display: inline-block;
                content: '';
                width: 12px;
                height: 12px;
                background-image: url('../images/icons/ruler.svg');
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
    .shipping-returns-callout-bottom {
        border-bottom: 1px solid $grey-20;
        margin-bottom: $spacer *2;
    }
    .shipping-return-callout-wrapper {
        border-top: 1px solid $grey-20;
        padding: $spacer *2 0;
  
        .callout-icon-wrapper {
            display: inline-block;
            width: 30px;
            flex-shrink: 0;
            margin-bottom: auto;
        }
        .callout-message-wrapper {
            display: inline-block;
        }
        .callout-shipping-wrapper, .callout-returns-wrapper {
            display: flex;
        }
        .callout-shipping-wrapper {
            margin-bottom: $spacer
        }
        .callout-text {
            color: #000;
            font-family: $secondary-font;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
        }
    }

    .cart-and-ipay {
        margin-bottom: $spacer * 2;

        .add-to-cart,
        .add-to-cart-global,
        .select-size {
            width: 100%;
        }
    }

    .bis-container {
        background-color: $secondary-01;
        border: 1px solid $secondary-02;
        padding: ($spacer * 2) $spacer;
        margin-bottom: $spacer * 2;

        .form-group {
            margin: 0;
        }

        .bis-headline,
        .email-description,
        .bis-email-input,
        .bis-consent-label {
            margin-bottom: $spacer;
        }

        button.notify-back-in-stock {
            width: 100%;
        }

        .bis-signup-success i {
            font-size: 16px;
            vertical-align: text-bottom;
        }

    }

    .promotions-wrapper {
        margin-bottom: $spacer * 2;
    }

    .promotion {
        font-family: $primary-font;
        text-align: center;
        border: 1px solid $grey-20;
        padding: $spacer * 2;

        & + .promotion {
            margin-top: $spacer * 2;
        }
    }

    .primary-images {
        position: relative;
        max-width: (420px * 2) + $spacer;
        width: 100%;

        .primary-images-main {
            width: calc(100% + (#{$spacer} * 4));
            margin: 0 -#{($spacer * 2)} #{($spacer * 2)};
            padding: 0 #{($spacer * 2)};
            overflow-x: scroll;
            display: flex;
            flex-wrap: nowrap;
            column-gap: ($spacer / 2);

            @include media-breakpoint-up(md) {
                width: calc(100% - #{$spacer});
                margin: 0;
                padding: 0;
                overflow: unset;
                display: grid;
                grid-template-columns: repeat(1, 100%);
                gap: $spacer;
            }

            @include media-breakpoint-up(lg) {
                grid-template-columns: repeat(2, 50%);

                &.image-count-1,
                &.image-count-2,
                &.image-count-3 {
                    grid-template-columns: repeat(1, 100%);
                }
            }

            .image-wrapper {
                max-width: 100%;
                max-height: 100%;
                overflow: hidden;
                &.zoomed-out {
                    cursor: url('../images/icons/minus.svg') 16 16, pointer;
                }
                &.zoomed-in {
                    cursor: url('../images/icons/plus.svg') 16 16, pointer;
                }
                img {
                    transition: transform 0.4s ease-out 0s;
                }
            }
        }

        img,
        video {
            background-color: $grey-10;
        }

        .image-arrow {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 22px;
            height: 22px;
            background-image: url('../images/icons/arrow.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
        }

        video.video-player {
            width: 100%;
            height: 100%;
        }

        .video-play-button {
            position: absolute;
            bottom: $spacer;
            right: $spacer;
            z-index: 2;

            .video-play-text {
                display: inline-block;
                height: 32px;
                border-radius: 50px;
                background-color: $white;
                color: $grey-30;
                text-transform: uppercase;
                font-size: 10px;
                font-weight: 500;
                padding: 5px 20px 5px 40px;
            }

            .video-play-icon {
                position: relative;
            }
            
            .video-play-icon::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 3px;
                left: 15px;
                z-index: 2;
                width: 0px;
                height: 0px;
                border-style: solid;
                border-width: 5px 0 5px 9px;
                border-color: transparent transparent transparent $white;
            }

            .video-play-icon::after {
                content: '';
                display: inline-block;
                position: absolute;
                top: -2px;
                left: 8px;
                height: 21px;
                width: 21px;
                background-color: $grey-30;
                border-radius: 50%;
            }
        }

        .tns-slider .zoomImg {
            background-color: $white;
        }
    }

    .stock-indicator {
        display: none;
        color: $grey-30;
        margin-bottom: $spacer;

        &.visible {
            display: block;
        }

        &.low-stock .icon-hurry {
            display: inline-block;
        }

        .icon-hurry {
            display: none;
        }
    }

    .error {
        color: $red;
        font-style: italic;
    }

    .accordion {
        border-top: 1px solid $grey-20;

        .accordion-item {
            border-bottom: 1px solid $grey-20;
        }

        .accordion-header {
            margin-bottom: 0;

            a {
                display: flex;
                align-items: center;
                padding: #{($spacer * 2)} 0;
                text-decoration: none;
                width: 100%;
                position: relative;

                &::after {
                    display: inline-block;
                    content: '-';
                    position: absolute;
                    right: 6px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 22px;
                }

                &.collapsed::after {
                    content: '+';
                }
            }

            &.tab-icon-gear,
            &.tab-icon-info,
            &.tab-icon-ruler,
            &.tab-icon-drop,
            &.tab-icon-box,
            &.tab-icon-meter,
            &.tab-icon-check,
            &.tab-icon-key,
            &.tab-icon-shield,
            &.tab-icon-puzzle
            {
                padding-left: 32px;
                position: relative;

                &::before {
                    content: '';
                    display: inline-block;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%);
                    width: 17px;
                    height: 17px;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }

            &.tab-icon-gear::before {
                background-image: url('../images/icons/cog.svg');
            }

            &.tab-icon-info::before {
                background-image: url('../images/icons/info.svg');
            }

            &.tab-icon-ruler::before {
                background-image: url('../images/icons/ruler.svg');
            }

            &.tab-icon-drop::before {
                background-image: url('../images/icons/drop.svg');
            }

            &.tab-icon-box::before {
                background-image: url('../images/icons/box.svg');
            }

            &.tab-icon-meter::before {
                background-image: url('../images/icons/meter.svg');
            }

            &.tab-icon-check::before {
                background-image: url('../images/icons/check.svg');
            }

            &.tab-icon-key::before {
                background-image: url('../images/icons/key.svg');
            }

            &.tab-icon-shield::before {
                background-image: url('../images/icons/shield.svg');
            }

            &.tab-icon-puzzle::before {
                background-image: url('../images/icons/puzzle.svg');
            }
        }

        .accordion-content {
            background-color: #f6f6f6;
            padding: #{($spacer * 2) $spacer};
        }
    }

    .bundle-description {
        margin-bottom: $spacer * 2;
    }

    &.product-bundle {
        .prices {
            border-bottom: none;
            padding-bottom: 0;
        }

        .primary-images {
            img {
                padding: 0;
            }
        }
    }

    .bundle-items {
        margin-bottom: $spacer * 2;

        .attributes {
            border-top: none;
        }

        .checkmark {
            @include round-checkmark;
            margin-right: $spacer;
        }

        .add-to-cart-global,
        .select-size {
            width: 100%;
        }
    }

    .bundle-item {
        .primary-images {
            .primary-images-main {
                display: block;
                margin-bottom: 0;
            }
        }

        .mystery-item-image {
            width: calc(100% + (#{$spacer} * 4));
            display: flex;
            background-color: $secondary-02;

            img {
                width: 100%;
            }

            @include media-breakpoint-up(md) {
                width: 350px;
                height: 350px;
            }
    
            @include media-breakpoint-up(lg) {
                width: 421px;
                height: 421px;
            }
        }

        .product-name {
            margin: $spacer * 2 0 0;
        }

        .size.non-input-label {
            margin-bottom: $spacer;
            display: inline-block;
        }
    }

    .vlt-container {
        border: 1px solid $grey-10;
        border-radius: $spacer / 4;
        display: flex;
        flex-direction: column;
        gap: $spacer;
        margin-bottom: $spacer * 2;
        padding: $spacer;
        .vlt-title {
            @include paragraph-font-size(normal);
            font-family: $primary-font-bold;
            border-bottom: 1px solid $grey-10;
            padding-bottom: $spacer;
        }
        .vlt-tiles {
            display: flex;
            flex-direction: column;
            gap: $spacer;
        }
        .vlt-tile {
            padding: ($spacer / 2) 0;
            align-items: center;
            display: flex;
            flex-direction: row;
            gap: $spacer;
            .vlt-tile-type {
                margin-right: auto;
            }
            .vlt-tile-value {
                @include paragraph-font-size(normal);
                align-content: center;
                border: 1px solid $black;
                border-radius: 50%;
                height: $spacer * 2;
                margin-bottom: 0;
                text-align: center;
                width: $spacer * 2;
            }
            .vlt-tile-text {
                @include paragraph-font-size(small);
                margin-bottom: 0;
                text-align: center;
            }
        }
    }

    .compliance-callout {
        font-weight: bold; // 700
        margin-bottom: $spacer * 2;
        text-decoration: underline;
    }
}

// PDP Tabs - Different from Sitewide tabs
.product-details-tabs {
    background-color: $black;

    .nav-link {
        font: $tab-font;
        text-transform: $tab-text-transform;
        font-weight: $tab-font-weight;
        text-decoration: $tab-text-decoration;
        letter-spacing: $tab-letter-spacing;
        padding: $tab-padding-top $tab-padding-right $tab-padding-bottom $tab-padding-left;
        border-radius: $tab-border-radius-top-left $tab-border-radius-top-right $tab-border-radius-bottom-right $tab-border-radius-bottom-left;
        background-color: $tab-background;
        color: $white;
        border-top: $tab-border-top;
        border-right: $tab-border-right;
        border-bottom: $tab-border-bottom;
        border-left: $tab-border-left;
        border-color: $yellow-green;

        &:hover {
            text-decoration: $tab-text-decoration-hover;
            background-color: $tab-background-hover;
            color: $white;
            border-top: $tab-border-top-hover;
            border-right: $tab-border-right-hover;
            border-bottom: $tab-border-width solid $yellow-green;
            border-left: $tab-border-left-hover;
        }

        &.active {
            text-decoration: $tab-text-decoration-active;
            background-color: $tab-background-active;
            color: $white;
            border-top: $tab-border-top-active;
            border-right: $tab-border-right-active;
            border-bottom: $tab-border-width solid $yellow-green;
            border-left: $tab-border-left-active;
        }
    }

    .product-has-thumbnails {
        padding-left: 0;
    }

    .tabs {
        padding-top: 75px;
        padding-bottom: 75px;
        margin-bottom: 0;

        .nav-tabs {
            justify-content: center;
            padding-bottom: 45px;

            @include media-breakpoint-up(md) {
                padding-bottom: 80px;
            }

            .nav-item:first-child {
                margin: 0;
            }

            .nav-link {
                text-transform: uppercase;

                &.active {
                    border-bottom: 2px solid $yellow-green;
                }
            }
        }

        .tab-content {
            color: $white;
            margin: 0 auto;
            max-width: 1100px;

            p {
                font-size: $default-text-font-size;
            }
        }
    }
}

.product-reviews-section {
    padding-top: 40px;
}

.klarna-callout {
    margin-bottom: $spacer * 2;
}

// Quantity too high notification for mobile edit cart product modal
@include media-breakpoint-down(sm) {
    .modal-footer.row {
        margin: 0;
    }

    .global-availability {
        flex: 100%;
        text-align: center;

        label {
            display: none;
        }
    }
}

//Product Set
.set-items {
    .product-number-rating {
        display: none; // hide product reviews link since reviews section is not shown
    }
}

// Quantity select on quickview
.quick-view-dialog .attribute.quantity {
    margin-top: 16px;

    .quantity-select {
        width: 60px;
    }
}

.set-items .select-size {
    padding-top: 12px;
}

.not-returnable-text {
    font-family: $secondary-font;
    font-size: $default-text-font-size;
    color: $red;
    margin-top: 16px;
}


.product-explicit-recommendations {
    padding: ($spacer * 2) 0;
    .recommendations-title {
        margin: 0;
        padding: ($spacer * 2) 0;
    }
    .recommendations-container {
        border: 1px solid $grey-20;
        border-bottom: 0;
    }
    .recommendation {
        border-bottom: 1px solid $grey-20;
        display: flex;
        .recommendation-image {
            align-self: center;
            aspect-ratio: 1;
            height: 124px;
            width: 124px;
            background-color: $grey-10;
            img {
                height: 100%;
                aspect-ratio: 1;
            }
        }
        .recommendation-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            padding: $spacer;
        }
        .recommendation-name{
            flex-grow: 1;
        }
        .recommendation-colors {
            color: $grey-30;
        }
        .recommendation-row {
            display: flex;
        }
        .recommendation-price {
            align-self: center;
            flex-grow: 1;
            .price {
                color: $grey-40;
            }
            // swap List and Sale price order
            .price > span {
                display: flex;
                del {
                    order: 1;
                }
                span.sales {
                    margin-left: 0;
                    margin-right: $spacer;
                    order: 0;
                }
            }
        }
        .recommendation-cta {
            align-self: center;
            .recommendation-button {
                border-radius: $spacer / 3;
                padding: ($spacer / 2) $spacer;
            }
        }
    }
}

