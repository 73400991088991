@import "~core/components/miniCart";

// enhanced sticky header fix
.scroll-direction-down.sticky-header .fixed-header-enhanced {
    .minicart {
        .minicart-backdrop, .minicart-pd {
            top: var(--header-height);
        }
    }
}

.fixed-header {
    .popover .minicart-body {
        display: block;

        @include media-breakpoint-up(md) {
            max-height: 300px;
        }
    }
}

.minicart-open {
    .header-utility,
    .header-column-1,
    .header-column-2,
    #maincontent,
    .header-banner,
    #footercontent {
        filter: blur(4px);
    }
}

.minicart {
    .cart {
        padding-top: $spacer + ($spacer / 2);
    }
    .minicart-link {
        cursor: pointer;
    }
    .line-item-availability {
        display: none;
    }
    .line-item-name {
        white-space: normal !important;
    }
    .item-attributes {
        padding-bottom: 0px;
        width: fit-content !important;
    }
    .item-image{
        margin: 0 !important;
    }
    .product-card-image-wrapper, .product-card-price-wrapper {
        flex-shrink: 0;
    }
    .product-card-image-wrapper {
        max-width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-card-text-wrapper {
        flex-grow: 1;
    }
    .dropdown-divider {
        border-top: 1px solid $grey-20;
        left: -($spacer * 2);
        position: relative;
        width: calc(100vw);
    }
    .minicart-header {
        margin-bottom: $spacer;
        .container{
            padding-left: 0;
            padding-right: 0;
        }
        .minicart-header-text {
            letter-spacing: -1px;
            font-family: $header-font;
            padding-left: $spacer / 2;
            font-size: 18px;
        }
    }
    .minicart-item {
        display: none;
    }
    .minicart-item:last-child {
        display: block;
        padding: $spacer 0;
        margin: 0;
        border: 0;
        .container, .row, .col, .col-auto {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .minicart-checkmark {
        background-color: $brand-03;
        border-radius: 50%;
        height: 16px;
        width: 16px;
        ::before {
            font-size: 9px;
            left: 3px;
            bottom: 1px;
        }
    }
    .product-summary{
        padding: 0;
        .line-item-name {
            width: unset;
        }
        .line-item-total-text {
            font-size: $product-card-attribute-size;
        }
        .line-item-total-text, .unit-price {
            display: inline-block;
        }
        .unit-price {
            .strike-through .value {
                margin-right: 0;
            }
        }
    }
    .popover {
        height: fit-content;
        & > .container.cart {
            height: auto;
            .minicart-body {
                overflow-y: initial;
                .line-item-total-text {
                    margin-bottom: 0;
                }
            }
            .minicart-footer {
                border-top: 0;
            }
        }
    }
    .continue-shopping-cart-btn {
        margin-bottom: $spacer;
    }
    .minicart-backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
        background-color: $black;
        opacity: 0.5;
        z-index: 300;
    }
    .drawer {
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100%;
        height: 100vh;
        display: block;
        transition: left .5s cubic-bezier(0,1,.5,1);
        z-index: $z-index-header;
        background-color: $white;
        overflow-y: auto;

        @include media-breakpoint-down(xs) {
            width: 100%;
        }

        &.show {
            transition: right 0.5s cubic-bezier(0,1,.5,1);
            right: 0;
        }
        &.hide {
            transition: right 1.5s cubic-bezier(0,1,.5,1);
            right: -100%;
        }
        .cart {
            position: fixed;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            @include media-breakpoint-up(md) {
                height: auto;
            }
        }
        .minicart-body {
            max-height: 250px;
            overflow-y: auto;
            display: block;

            @include media-breakpoint-down(md) {
                max-height: unset;
            }
        }
        .product-summary {
            max-height: none;
            overflow-y: none;
        }
    }
    .minicart-footer {
        button.dw-apple-pay-button{
            width: 100%;
            border-radius: $button-border-radius;
            margin-bottom: $spacer;
            padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
        }
        .estimated-total {
            p {
                font-size: $spacer;
            }
        }
    }
    .approaching-discounts {
        .single-approaching-discount:last-of-type{
            margin-bottom: $spacer / 2;
        }
        .single-approaching-discount{
            border: 1px solid #ccc;
            border-radius: $spacer / 2;
            padding: $spacer;
            margin-bottom: $spacer;
            .threshold-message {
                padding-bottom: $spacer;
            }
            .threshold-bar {
                height: 16px;
                border: 1px solid #D8D8D8;
                border-radius: $spacer / 2;
                background-color: $secondary-01;
                .threshold-bar-progress {
                    height: 100%;
                    width: 0%;
                    border-radius: 14px;
                    background-color: $black;
                    transition: width .2s;
                }
            }
        }
        .bonus-product-button-wrapper {
            border: 1px solid #ccc;
            border-radius: $spacer / 2;
            padding: $spacer;
            margin-bottom: $spacer;
            .circle {
                background-image: url('../images/icons/gift.svg');
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid #ccc;
                border-radius: 50%;
                padding: 0;
                height: $spacer * 3;
                width: $spacer * 3;
            }
            .bonus-product-button button {
                font-size: 14px;
                height: auto;
                padding-top: $spacer / 2;
                padding-bottom: $spacer / 2;
                padding-right: $spacer * 2;
            }
        }
    }
    klarna-placement {
        @include media-breakpoint-up(md) {
            margin-bottom: $spacer * 2;
        }
        display: block;
    }
}
