@import '~base_foxhead/variables';
@import 'sidebar';
@import 'accordion';
@import 'landing';
@import 'breadcrumbs';
@import 'forms';
@import 'tables';
@import 'warranty';
@import 'storeLocator';
@import 'sizeChart';

// global styles scoped to help-center
.help-center {
    .d-grid {
        display: grid;
    }
}
