@import "~core/helperClasses";

// Create button classes, e.g. .btn-primary
.btn {
    @include button;
    &-primary {
        @include button(primary);
    }
    &-secondary,
    &-outline-primary {
        @include button(secondary);
    }
    &-tertiary {
        @include button(tertiary);
    }
    &-addtocart {
        @include button(addtocart);

        &.disabled,
        &:disabled,
        &[disabled] {
            @include button(addtocartdisabled);
        }
    }
    &-addtocartdisabled {
        @include button(addtocartdisabled);
    }
    &-viewdetails {
        @include button(viewdetails);
    }
    &-primary-sm {
        @include button(primarysmall);
    }
    &-secondary-sm {
        @include button(secondarysmall);
    }
    &-slider-left {
        @include button(sliderleft);
        &.disabled {
            @include button(sliderdisabled);
        }
    }
    &-slider-right {
        @include button(sliderright);
        &.disabled {
            @include button(sliderdisabled);
        }
    }
    &-block {
        @include full-width;
    }
}

.subtitle {
    &-heavy {
        @include subtitle(heavy);
    }
    &-light {
        @include subtitle(light);
    }
}

.action-text {
    @include action-text;
}

.bottom-short-line-light::after {
    @include bottom-short-line(light);
}

.bottom-short-line-dark::after {
    @include bottom-short-line(dark);
}

// Removes margin when multiple columns should be flush on mobile
.no-vertical-gutters-mobile {
    .region {
        @include media-breakpoint-down(sm) {
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }
}

.list-style-dash ul {
    list-style: none;
    li::before {
        content: '\2014';
        position: absolute;
        margin-left: -30px;
    }
}

// ada compliance helper classes
.text-stroke-black-1px {
    -webkit-text-stroke: 1px #000000;
}

.text-stroke-black-2px {
    -webkit-text-stroke: 2px #000000;
}

.text-link{
    &.text-link--light {
        color: $grey-30;
    }
}

.klarna-prefooter {
    margin-bottom: -48px;
}