@import "~core/utilities/swatch";

.swatch-circle-multi {
    background: $product-miscellaneous-swatch-background;
    background-repeat: repeat-y, repeat;
    background-size: 50% 100%, 100% 100%;
    border: 1px solid $border-color;
    border-radius: 50%;
    display: inline-block;
    height: $product-refinement-swatch-size;
    width: $product-refinement-swatch-size;
    position: relative;
    transform: rotate(35deg);
    &.swatch-mark.color-value.selected::after {
        transform: translate(50%, -50%) rotate(-35deg);
    }
}

@mixin swatch-select($style: '') {
    &.color-value {
        transition: box-shadow $transition-speed ease-out;
        @if $style == 'thumbnail' {
            &:hover:not(.unavailable) {
                box-shadow: $color-swatch-thumbnail-hover-shadow;
            }
            &.selected {
                box-shadow: $color-swatch-thumbnail-selected-shadow;
                @include icon($color-swatch-thumbnail-selected-icon, after, $color-swatch-thumbnail-selected-icon-size/2, $color-swatch-thumbnail-selected-icon-color);
                &::after {
                    position: absolute;
                    top: -$color-swatch-thumbnail-selected-icon-size/3;
                    right: -$color-swatch-thumbnail-selected-icon-size/3;
                    display: $color-swatch-thumbnail-selected-icon-display;
                    background: $color-swatch-thumbnail-selected-icon-background;
                    width: $color-swatch-thumbnail-selected-icon-size;
                    height: $color-swatch-thumbnail-selected-icon-size;
                    line-height: $color-swatch-thumbnail-selected-icon-size;
                    padding-left: 1px;
                    text-align: center;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            &.unavailable {
                @include disabled;
                @include strike-through-swatch(calc(100% + 15px), $grey-20, $white);
                pointer-events: auto;
                position: relative;
            }
        } @else {
            &:hover {
                box-shadow: $color-swatch-hover-shadow;
            }
            &.selected {
                box-shadow: $color-swatch-selected-shadow;
                @include icon($color-swatch-selected-icon, after, $color-swatch-selected-icon-size/2, $color-swatch-selected-icon-color);
                &::after {
                    @include absolute-center;
                    display: $color-swatch-selected-icon-display;
                    background: $color-swatch-selected-icon-background;
                    width: $color-swatch-selected-icon-size;
                    height: $color-swatch-selected-icon-size;
                    line-height: $color-swatch-selected-icon-size;
                    padding-left: 1px;
                    text-align: center;
                    border-radius: 50%;
                    z-index: 1;
                }
            }
            &.unavailable {
                @include disabled;
                @include strike-through-swatch(calc(100% + 15px), $grey-20, $white);
                pointer-events: auto;
                cursor: not-allowed;
                position: relative;
            }
        }
        &.unavailable {
            @include disabled;
            @include strike-through-swatch(calc(100% + 15px), $grey-20, $white);
            pointer-events: auto;
            cursor: not-allowed;
            position: relative;
        }
    }
    &.non-color-swatch-value {
        transition: border $transition-speed ease-out, box-shadow $transition-speed ease-out;
        &:not(.unavailable):hover {
            border-color: $non-color-swatch-hover-border-color;
            box-shadow: $non-color-swatch-hover-box-shadow;
        }
        &.selected,
        &.selected:hover {
            background: $non-color-swatch-selected-background;
            color: $non-color-swatch-selected-color;
            border-color: $non-color-swatch-selected-border-color;
            box-shadow: $non-color-swatch-selected-box-shadow;
            @include icon($non-color-swatch-selected-icon, after, $non-color-swatch-selected-icon-size/2, $non-color-swatch-selected-icon-color);
            &::after {
                position: absolute;
                top: -$non-color-swatch-selected-icon-size/3;
                right: -$non-color-swatch-selected-icon-size/3;
                background: $non-color-swatch-selected-icon-background-color;
                width: $non-color-swatch-selected-icon-size;
                height: $non-color-swatch-selected-icon-size;
                line-height: $non-color-swatch-selected-icon-size;
                padding-left: 1px;
                text-align: center;
                border-radius: 50%;
                display: $non-color-swatch-selected-icon-display;
            }
        }
        &.unavailable {
            @include disabled;
            pointer-events: auto;
            position: relative;

            &:not(.bis-enabled) {
                @include strike-through-swatch(calc(100% + 15px), $grey-20, $white);
            }
        }
    }
}