.container.container-primary {
    width: 100%;
    max-width: 1824px;
    padding: 0 24px;
    margin: 0 auto;

    @include media-breakpoint-up(lg) {
        padding: 0 48px;
    }
}
