@import "~core/components/productCard";

.product-info,
.product-summary,
.multi-shipping {
    .remove-product, .change-bonus-product {
        text-decoration: underline;
        color: $black;
    }
    .line-item-availability {
        .line-item-attributes {
            &:first-child:after {
                content: '';
            }
            &.out-of-stock{
                color: #72060b;
                font-weight: bold;
                background: #f4e9ea;
                padding: 5px 10px;
                margin-top: 7px;
                display: inline-block;
                width: fit-content;
            }
        }
    }
}

