
.geo-redirect-container {
    font-weight: 600;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: auto;

    .modal-outline {
        padding: 2px;
        background-color: $white;    
    }

    .modal-content {
        border: solid 2px;

        .modal-header {
            display: block;
            text-align: right;
        }

        .modal-title {
            text-decoration: underline;
            text-underline-offset: 10px;
        }
        .geopopin-flag-icon {
            width: 120px;
            height: 90px;
            border: 1px solid $grey-20;
            background-size: cover;
        }
        .sites-container .geopopin-flag:not(:last-child) {
            margin-right: $spacer *2;
            @media (max-width: 300px) {
                margin-right: 0px;
            }
        }
        .modal-body {
            text-align: center;

            a {
                text-decoration: none;

                .flag-icon {
                    font-size: 5em;
                    padding-bottom: 12px;
                }
            } 

            .link-close {
                border-bottom: solid 1px $black;
                padding-bottom: 2px;
                cursor: pointer;
            }
        }
    }
}
