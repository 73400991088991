.athlete-container {
    .athlete-content {
        padding-top: 48px;
        img {
            width: 100%;
        }
    }
    .page-header-author {
        width: 100%;
        padding-top: $spacer * 2;
        border-top: 1px solid $grey-20;
        font-size: 16px;
        display: block;
        color: $grey-40;
        line-height: 100%;
        padding-bottom: $spacer;
    }
    .athlete-label {
        color: #8F8F8F;
        line-height: 100%;
        font-size: 16px;
        padding-bottom: $spacer;
    }
    .athlete-description {
        font-weight: bold;
        line-height: 100%;
        margin-bottom: 0px;
    }
    .athlete-name {
        font-family: $body-font;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 0;
        line-height: $spacer * 2;
        color: $black;
        padding-bottom: $spacer * 2;
        border-bottom: 1px solid $grey-20;
    }
    .athlete-desc {
        padding-bottom: $spacer * 2;
        border-bottom: 1px solid $grey-20;
    }
    .athlete-flag {
        padding: 18px 0 $spacer 0;
    }
    .athlete-flag-icon {
        border: 1px solid $grey-20;
        width: 30px;
        height: 100%;
        line-height: inherit;
    }
    .discipline-box {
        display: inline-block;
        background-color: $black;
        margin-right: 10px;
        padding-left: $spacer;
        padding-right: $spacer;
        border: 1px solid $black;
        font-size: 16px;
        border-radius: 6px;
        margin-bottom: $spacer;
    }
    .discipline-box-black {
        color: $white;
    }
    .social-icon-container {
        width: 30px;
        height: 30px;
        border-radius: 50px;
        background: $secondary-01;
        text-align: center;
        display: inline-block;
        margin-top: $spacer * 2;
        a {
            font-weight: 500;
        }
    }
    .social-icon-left {
        margin-left: $spacer * 2;
    }
    .social-icon {
        padding-top: 4px;
    }
    .discipline-box-white {
        background-color: white;
    }
    .discipline-container {
        padding-bottom: $spacer * 2;
        padding-top: $spacer * 2;
    }
    .athlete-image {
        width: 100%;
        padding-bottom: $spacer * 2;
    }
    .athlete-tablet,
    .athlete-desktop,
    .athlete-hd {
        display: none;
    }

    @include media-breakpoint-up(md) {
        .athlete-mobile,
        .athlete-desktop,
        .athlete-hd {
            display: none;
        }
        .athlete-tablet {
            display: block;
        }
    }
    @include media-breakpoint-up(lg) {
        .athlete-mobile,
        .athlete-tablet,
        .athlete-hd {
            display: none;
        }
        .athlete-desktop {
            display: block;
        }
    }
    @include media-breakpoint-up(xl) {
        .athlete-desktop {
            display: none;
        }
        .athlete-hd {
            display: block;
        }
    }
}
