@import "~core/components/pageHeader";

.page-header {
    padding-top: 0;
    padding-bottom: 0;

    &.search-results-header {
        .page-header-title {
            @include header(2);
            margin-top: $spacer;
            padding-top: $spacer *2;
            padding-bottom: $spacer *2;
            &:empty {
                padding-bottom: 0;
            }
            // PLP Override
            .plp & {
                margin-top: 0;
                @include media-breakpoint-down(sm) {
                    padding-top: $spacer * 2;
                    padding-bottom: $spacer * 2;
                }
                @include media-breakpoint-up(md) {
                    padding-top: $spacer * 4;
                    padding-bottom: $spacer * 2;
                }
            }
        }
        .has-subtext.page-header-title {
            margin-bottom:-$spacer*2;     
        }
        .subtext-container {
            padding-bottom: $spacer*4;
            line-height: 0;
            margin-bottom: 0px;
        }
    }
}

.category-links {
    display: inline-block;
    text-transform: none;
    font-size: 15px;
    padding: 10px 12px;
    border-radius: 10px;  
    border: solid 1px $brand-01;
}

.category-links-container {
    @include media-breakpoint-down(md) {
        overflow-x: scroll;
        margin-right: -$spacer *2;
        margin-left: -$spacer *2;
        width: 100vw;
        padding-left: $spacer *2;
    }
}

.category-links-container::-webkit-scrollbar {
    display: none;
}

.circle-links-component {
    margin: 0;
    padding: 0 18px 12px 0;
    height: auto;
    background: $white;
    list-style: none;
    display: flex;
    width: max-content;
    gap: $spacer;

    @include media-breakpoint-up(md) {
        padding: 0;
        margin-bottom: $spacer * 2;
        width: auto;
        flex-wrap: wrap;
    }
}

.circle-link {
    display: inline-block;
    height: auto;
    width: auto;
    min-width: 100px;
    border-radius: 4px;
    padding: $spacer ($spacer * 2);
    border: solid 1px $brand-01;
    text-align: center;
    flex: 0 0 auto;
    line-height: 1;

    a, .text-link {
        text-decoration: none;
    }

    a {
        @include paragraph-font-size(normal);
    }
}

.circle-links-component .circle-link:last-child {
    margin-right: 18px;
}