@import "~core/components/headerSearch";

.site-search {
    form {
        @include media-breakpoint-up(lg) {
            height: 100%;
        }
    }

    // Increase specificity to override .form-control padding
    .search-field-wrapper .search-field {
        padding-left: 32px;
        padding-right: 32px;
    }

    .search-field {
        @include paragraph-font-size(small);
        color: $grey-30;
        font-family: $secondary-font;
        font-weight: 400;
        background-color: $grey-10;
        padding-left: 32px;
        padding-right: 32px;
        width: $header-search-desktop-width;

        @include media-breakpoint-down(md) {
            left: 6px;
            right: 0;
            top: $header-height;
            padding: 0;
        }
    }
    .fa-close-icon {
        background: url(#{$header-close-image}) no-repeat center ;
        @include media-breakpoint-down(md) {
            width: 41px;
            height: 41px;
            right: 0;
            position: absolute;
            margin-right: 22px;
        }
    }
    .fa-search,
    .fa-close {
        @include media-breakpoint-up(lg) {
         top: 54%;
         left: 6px;
         transform: translateY(-50%);
        }
        
        @include media-breakpoint-down(md) {
            width: 41px;
            height: 41px;
            right: 0;
            left: unset;
            padding-right: 55px;  
        }
    }
} 

.sticky-search {
    padding: 12px 0;
    background: $white;
    height: 64px;

    &.row {
        height: auto;
    }

    .search-field {
        border: 1px solid $grey-20;
        border-radius: 20px;
        background: transparent;
    }

}

.header .navbar-toggler-search {
    @include icon($icon-magnifing-glass, before, inherit, $header-search-icon-color);
    padding-left: 0;
}

.mobile-menu-in .header .navbar-toggler.navbar-toggler-search {
    @include icon($icon-magnifing-glass, before, inherit, $header-search-icon-color);
}

.search-mobile .fa-search::before {
    font-size: 22px;
    margin-top: 4px;
}

.main-menu.menu-toggleable-left {
    .search-mobile .form-control.search-field {
        @include media-breakpoint-down(lg) {
            border-color: $grey-20;
            border-top: 1px solid $grey-20;
        }
    }
}

.form-control.search-field {
    font-size: $base-font-size;
    color: $grey-30;
    background-color: $white;
    @include media-breakpoint-down(lg) {
        padding-right: 44px;
    }
}

.search-modal-header, .suggestions-header {
    font-family: $header-suggestions-header-font;
    font-size: $header-suggestions-header-font-size;
    text-transform: $header-suggestions-header-text-transform;
    font-size: 17px;
    font-weight: 600;
}

.search-modal-header {
    position: fixed;
    top: 0;
    right: -$header-suggestions-results-width;
    width: 100dvw;
    padding: $spacer * 2;
    border-bottom: 1px solid $grey-20;
    background-color: $white;
    z-index: 1051;
    transition: right 0.3s linear;

    @include media-breakpoint-up(lg) {
        width: $header-suggestions-results-width;
        padding: ($spacer * 2) ($spacer * 4);
    }

    &.active {
        right: 0;
    }
}

.search-modal {
    overflow-y: scroll;
    margin-top: 72px;
    width: 100dvw;
    height: calc(100dvh - 72px);
    right: -$header-suggestions-results-width;
    transition: right 0.3s linear;

    @include media-breakpoint-up(lg) {
        width: $header-suggestions-results-width;
    }

    &.active {
        right: 0;
    }

    .form-control.search-field {
        width: 100%;
        border: 1px solid $grey-20;
        border-radius: 20px;
        padding-right: $spacer * 4;
    }
    
    .search-field-wrapper {
        padding: $spacer ($spacer * 2);
        border-bottom: 1px solid $grey-20;
        input.search-field::placeholder {
            color: $grey-30;
            opacity: 1;
        }

        @include media-breakpoint-up(lg) {
            padding: $spacer ($spacer * 4);
        }
    }
    .fa-search {
        top: 50%;
        left: unset;
        @include media-breakpoint-down(md) {
            padding-right: $spacer * 5.5;
        }
        @include media-breakpoint-up(lg) {
            top: 54%;
            right: $spacer * 6;
        }
        transform: translateY(-50%);
    }
    .suggestions-header {
        &:not(:first-child) {
            border-top: $header-suggestions-border;
        }

        padding: ($spacer * 2) ($spacer * 2) $spacer ($spacer * 2);
        margin: 0;

        @include media-breakpoint-up(lg) {
            padding: ($spacer * 2) ($spacer * 4) $spacer;
        }

        .search-show-more,
        .search-show-less,
        .suggestions-view-all {
            font-family: $secondary-font;
            font-weight: 400;
            font-size: 14px;
        }
    }
    a.name {
        text-decoration: none;
    }
    .search-show-more, .search-show-less {
        cursor: pointer;
        font-size: $spacer;
        user-select: none;
    }
    .suggestions-submit {
        color: $black;
        font-size: 14px;
        font-weight: 400;
        text-decoration: underline;
    }
    .suggestions-items {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        padding: ($spacer / 3) ($spacer * 2) ($spacer * 2);
        border-bottom: $header-suggestions-border;

        @include media-breakpoint-up(lg) {
            padding: ($spacer / 3) ($spacer * 4) ($spacer * 2);
        }

        &.row{
            margin: 0;
            padding: $spacer ($spacer / 2);
        }

        &.category-suggestions {
            overflow: hidden;
            height: calc(1.5em + (#{$spacer} * 3));

            // Account for reduced padding when suggestions are collapsed
            margin-bottom: ($spacer) + ($spacer / 2);
            border-bottom: unset;

            &.expanded {
                height: auto;
                margin-bottom: 0;
            }
        }

        // Add Gap Between Category Suggestions
        &.suggestions-gap {
            gap: 2/3 * $spacer;
        }

        &.suggestions-tiles {
            display: grid;
            grid-template-columns: 50% 50%;
            gap: ($spacer * 2) ($spacer / 2);
            padding-top: $spacer;

            .suggestions-item {
                flex-basis: 45%;
                flex-grow: 1;
                flex-shrink: 1;
            }

            [id^="product"] img {
                padding: $spacer;
            }
        }

        div.suggestions-item {
            transition: opacity 0.3s linear;
            opacity: 1;
            // Category Suggestions - Irregular Width Pills
            &[id^="phrase"], &[id^="category"], &.pill {
                padding: $spacer;
                border: 1px solid $grey-20;
                border-radius: $spacer / 3;
            }
            &[id^="category"] {
                &.transparent{
                    opacity: 0;
                }
                &.hidden{
                    height: 0;
                    padding: 0;
                }
            }
        }
        a {
            padding: 0;
            display: block;
            .product-name, .content-name {
                white-space: normal;
                padding: $spacer 0;
            }
        }
        .suggestion-image {
            background-color: $grey-10;
            width: 100%;
            text-align: center;
        }
        .price {
            font-family: $secondary-font;
            font-weight: bold;
            line-height: 20px;
            margin-bottom: $spacer;
            &:last-child {
                margin-bottom: 0; // Avoids extra margin when .available-colors !shown
            }
        }
        .available-colors {
            color: $grey-30;
        }
    }
    .no-results {
        display: none;
        padding: $spacer * 2;

        @include media-breakpoint-up(lg) {
            padding: ($spacer * 2) ($spacer * 4);
        }
    }
}

.suggestions {
    padding: 0;
}