@import "~core/components/tabs";

.nav-tabs {
    .nav-item {
        flex: none;
        &:not(:first-child):not(:last-child) {
            margin: 0 $tab-nav-item-margin;
        }
        &:first-child {
            margin: 0 0 0 $tab-nav-item-margin;
        }
        &:last-child {
            margin: 0;
        }
    }

    .nav-link {
        line-height: $tab-line-height;
        text-transform: uppercase;
        &:not(.active):not(:hover) {
            opacity: $tab-opacity;
        }
    }
}
