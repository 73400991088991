
/*
* PAGE DESIGNER LAYOUT GRID SPACING
*/
[class*='experience-commerce_layouts'] {

    // vertical spacing between layout containers
    &+[class*='experience-commerce_layouts'] > div {
        margin-top: $page-designer-layout-spacing;
    }

    // vertical spacing between layout items on mobile
    .region[class*='col-md'] {
        @include media-breakpoint-down(sm) {
            // single column in mobile
            &+.region[class*='col-12']:not(.order-1),
            &.order-2[class*='col-12'] {
                margin-top: $spacer*2;
            }
            // 2 column in mobile
            &+.region[class*='col-6']:not(:nth-child(1)):not(:nth-child(2)) {
                margin-top: $spacer*2;
            }
            // 3 column in mobile
            &+.region[class*='col-4']:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
                margin-top: $spacer*2;
            }
        }
    }

    // make sure contents of 2 column fill container vertically
    .region[class*='col-md-6'] {
        .experience-component:not(.experience-commerce_assets-html):not([class*='experience-commerce_layouts']) {
            height: 100%;
        }
    }

    //enables setting class on layout's custom class 
    .no-gutters  {
        .row {
            padding-right: $spacer;
            padding-left: $spacer;
            @include media-breakpoint-up(xl) {
                padding-right: $spacer-xl;
                padding-left: $spacer-xl;
            }
            & > [class*=" col-"] {
                padding-right: 0;
                padding-left: 0;
            }
        }
    }

    .has-background-image {
        position: relative;
        &>.container {
            position: relative;
            .column-background {
                width: calc(100% - #{$spacer*2});
                @include media-breakpoint-up(xl) {
                    width: calc(100% - #{$spacer-xl*2});
                }
            }
        }
        .column-background {
            @include absolute-center;
            height: 100%;
            width: 100%;
            overflow: hidden;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            .full-bleed & {
                width: calc(100vw + 2px); // 2px for Chrome bug where you can sometimes see a gap at the browser edges
            }
            &.column-background-tablet,
            &.column-background-desktop {
                display: none;
            }
            @include media-breakpoint-up(md) {
                &.column-background-mobile,
                &.column-background-desktop {
                    display: none;
                }
                &.column-background-tablet {
                    display: block;
                }
            }
            @include media-breakpoint-up(lg) {
                &.column-background-mobile,
                &.column-background-tablet {
                    display: none;
                }
                &.column-background-desktop {
                    display: block;
                }
            }
        }
    }
}

/*
* LAYOUT GRID ASPECT RATIOS
*/
.region-square,
.region-landscape,
.region-portrait {
    position: relative;
    .experience-component {
        position: absolute;
        top: 0;
        overflow: hidden;
        padding-left: 0;
        padding-right: 0;
        max-width: calc(100% - #{$spacer*2});
        @include media-breakpoint-up(xl) {
            max-width: calc(100% - #{$spacer-xl*2});
        }
    }
}
.region-square {
    @include aspect-ratio($aspect-ratio-square);
}
.region-landscape {
    @include aspect-ratio($aspect-ratio-landscape);
}
.region-portrait {
    @include aspect-ratio($aspect-ratio-portrait);
}
div.sf-interactions-proxyHoverContainer a {
    overflow: visible;
}

// Hover Switch layout component
.hover-switch {
    &--hover {
        display: none;
    }
    &:hover {
        .hover-switch {
            &--load {
                display: none;
            }
            &--hover {
                display: block;
            }
        }
    }
}

// Make sure promo tiles on PLP are sized appropriately
.product-grid {
    .experience-region,
    .experience-component,
    .chromeless {
        height: 100%;
    }
    // hero as a promo tile
    .hero {
        min-height: calc(100% - #{$product-tile-grid-margin-bottom});
        margin-bottom: $product-tile-grid-margin-bottom;
    }
}
