@import "~core/components/quickView";

// .quick-view-dialog,
.choose-bonus-product-dialog {
    height: 100vh;
    margin: 0;
    margin-left: auto;
    max-width: 100vw;
    @include media-breakpoint-up(md) {
        max-width: $minicart-size; // 400px;
    }
    .modal-content {
        height: 100vh;
        background-color: $modal-body-background-color; // white
        .modal-header {
            padding: ($spacer * 2) ($spacer * 2);
            font-size: 18px;
            letter-spacing: -1px;
            border-bottom: 1px $grey-20 solid;
            font-family: $header-font;
        }
        .modal-body {
            padding-top: 0;
            padding: $spacer * 2;
            .choice-of-bonus-product {
                width: 100%;
                border: 1px $secondary-01 solid;
                border-radius: $spacer;
                padding: $spacer;
                margin: 0;
                margin-bottom: $spacer;
                .primary-images {
                    margin-bottom: 30px;
                    .primary-images-main {
                        aspect-ratio: 1;
                        display: block;
                        margin: 0;
                        padding: 0;
                        width: 100%;
                        img:first-of-type {
                            display: block !important;
                        }
                        img,
                        video {
                            padding: 0;
                            display: none !important;
                        }
                    }
                }
                &.bonus-product-item {
                    border: 0;
                    padding: 0;
                    margin: 0;
                }
                .product-image {
                    height: 75px;
                }
                .product-name {
                    letter-spacing: -1.92px;
                }
                .col, .col-auto {
                    display: flex;
                    align-items: center;
                }
                button.select-product {
                    height: auto;
                    padding-top: $spacer / 2;
                    padding-bottom: $spacer / 2;
                }
                .swatch {
                    margin-bottom: $spacer * 2;
                    .attribute {
                        display: flex;
                        flex-wrap: wrap;
                        gap: $spacer / 3;
                    }
                    .non-input-label {
                        font-size: 14px;
                        line-height: 1em;
                        margin-bottom: $spacer * 2;
                        width: 100%;
                    }
                    button .swatch {
                        margin-bottom: 0;
                    }
                    span.non-input-label {
                        display: block;
                    }
                    .swatch-tile-container {
                        gap: $spacer / 3;
                        button {
                            color: $black;
                            margin-bottom: 0;
                        }
                    }
                }
                .select-bonus-product {
                    background-color: $brand-03;
                    border-radius: 9px;
                }
            }
        }
        .modal-footer {
            display: none;
        }
    }
}

#quickViewModal {
    background: rgba($black, 0.5);
    .modal-header {
        background-color: $white;
        border-bottom: 1px solid $grey-20;
        padding: $spacer * 2;
        position: sticky;
        @include media-breakpoint-up(lg) {
            padding: ($spacer * 2) ($spacer * 4);
        }
        .modal-title {
            @include header(5);
            color: $black;
        }
    }
    &.show .modal-content {
        right: 0;
    }
    .modal-dialog {
        margin: 0;
        max-width: unset;
        transform: none;
    }
    .modal-content {
        position: fixed;
        height: 100dvh;
        right: 0;
        right: -438px;
        margin-left: auto;      
        max-width: 100%;
        overflow: unset;
        transition: right 0.3s linear;
        @include media-breakpoint-up(lg) {
            max-width: 438px;
        }
    }
    .modal-body {
        padding: 0 ($spacer * 2) ($spacer * 2);
        overflow-y: scroll;
        scroll-behavior: smooth;
        @include media-breakpoint-up(lg) {
            padding: 0 ($spacer * 4) ($spacer * 2);
        }
        .product-detail {
            display: flex;
            flex-direction: column;
            gap: $spacer * 2;
        }
        .prices {
            padding-bottom: $spacer * 2;
            border-bottom: 1px solid $grey-20;
        }
        .attributes {
            > :not(:last-child){
                margin-bottom: $spacer * 2;
            }
            .non-input-label {
                margin-bottom: $spacer;
            }
            span.non-input-label {
                display: block;
            }
            .attribute .size-chart {
                float: inline-end;
            }
        }
        .swatch-tile-container {
            /* Reduce swatch size to show 5 in row, accounting for smaller quickview modal width */
            @include media-breakpoint-up(md) {
                display: grid;
                gap: $spacer / 2;
                grid-template-columns: repeat(5, 1fr);
                .swatch.color-value,
                .swatch.size-value {
                    aspect-ratio: 1;
                    height: auto;
                    width: inherit;
                }
            }
        }
        .quickview-product-image {
            background-color: $grey-10;
        }
    }
    .update-cart-product-global {
        width: 100%;
    }
    .product-name,
    .prices,
    .primary-images,
    .primary-images-main,
    .cart-and-ipay {
        margin-bottom: 0;
    }
    .product-wrapper.product-detail .primary-images .primary-images-main {
        overflow-x: scroll;
        display: flex;
        width: calc(100% + (#{$spacer} * 8));
        margin: 0px (-$spacer * 4);
        padding: 0px ($spacer * 4);
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .primary-images {
        max-width: unset;
    }
    .image-arrow,
    .video-play-button {
        display: none;
    }
    .cart-and-ipay {
        margin-top: (-$spacer * 2);
    }
    .modal-footer {
        display: none;
        padding: 0;
        margin: 0;
        border: 0;
    }
    .quickview-viewdetails {
        @include button(viewdetails);
        margin-top: (-$spacer);
    }
}

// Quick View Size Chart
#quickViewModal #static-size-chart,
#quickViewModal #quickadd-static-size-chart {
    .metric-tabs-container,
    .size-chart-container,
    .tab-pane {
        padding: 0;
        margin: 0;
        background-color: initial;
    }
    h2,
    h3,
    h4,
    h5,
    h6,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
        margin: 0 !important; // Override inline content asset styling
        padding: ($spacer * 2) 0 !important; // Override inline content asset styling
        @include header(5);
    }
    thead,
    tbody {
        border-bottom: 1px solid $grey-20;
    }
    thead tr {
        border-left: 1px solid $secondary-01;
        border-right: 1px solid $secondary-01;
    }
    tbody tr {
        border-left: 1px solid $grey-20;
        border-right: 1px solid $grey-20;
    }
    th {
        background-color: $secondary-01;
        color: $black;
        padding: $spacer;
        @include header(6);
        & + th {
            border-left: 1px solid $grey-20;
        }
    }
    td {
        padding: $spacer;
        @include paragraph-font-size;
        & + td {
            border-left: 1px solid $grey-20;
        }
    }
    tr {
        background-color: $white;
        & + tr {
            border-top: 1px solid $grey-20;
        }
    }
}

// Quick View - Product Bundles
#quickViewModal .product-bundle {
    .prices {
        border-bottom: 0;
        padding-bottom: 0;
    }
    .bundle-description,
    .bundle-description p:last-of-type {
        margin-bottom: 0;
    }
    .detail-panel,
    .hidden-xs-down,
    > hr {
        display: none;
    }
}