@import "~core/components/blogTile";

// Blog Page
.blog {
    // Blog Content Page
    &.page-designer {
        .blog-title-container {
            display: flex;
            justify-content: left;
            align-items: left;
            .page-header {
                padding-bottom: 0px;
            }
        }   
        .blog-title {
            max-width: 600px;
            margin-bottom: $spacer * 2;
        }
        .blog-detail-header {
            width: 696px; 
            padding: 0 $spacer*2;
            .page-header-subtitle {
                display: flex;
            }
            &.text-center {
                .page-header-subtitle {
                    justify-content: center;
                }
            }
            .page-header-title {
                margin-top: $blog-detail-title-margin-top;
                line-height: 100%;
            }
            .blog-category {
                color: $blog-detail-category-color;
                &~.blog-creation-date {
                    &::before {
                        content: $blog-detail-category-date-separator;
                        color: $blog-detail-category-date-separator-color;
                        padding-left: $blog-detail-category-date-separator-spacer-left;
                        padding-right: $blog-detail-category-date-separator-spacer-right;
                    }
                }
            }
            .blog-creation-date {
                color: $blog-detail-date-color;
            }
            .page-header-author {
                margin-top: $blog-detail-author-margin-top;
                color: $blog-detail-author-color;
            }
        }
        .editorial-container {
            max-width: 696px;
            padding-left: $spacer*2;
            padding-right: $spacer*2;
            .editorial-content {
                padding-top: $spacer * 2;
                img {
                    width: 100%;
                }
            }
            .page-header-author {
                width: 100%;
                padding-top: $spacer * 2;
                border-top: 1px solid $grey-20;
                font-size: 16px;
                display: block;
                color: $grey-40;
                line-height: 100%;
                padding-bottom: $spacer;
            }
            .editorial-date {
                // color: #8F8F8F;
                // padding-bottom: $spacer * 2;
                // border-bottom: 1px solid $grey-20;
                // line-height: 100%;
                // font-size: 16px;
                display: none;
            }
        }
    }

    // Category Grid Page
    .blog-body:has(.grid){
        padding-top: 0;
    }
    .grid {
        .grid-items {
            display: flex;
            flex-wrap: wrap;
            gap: ($spacer * 2) $spacer;
            padding: 0;
            @include media-breakpoint-up(lg) {
                gap: ($spacer * 4) $spacer;
                padding: ($spacer * 4) 0 ($spacer * 6);
            }
        }
        .grid-items .item {
            flex: 0 0 100%;
            @include media-breakpoint-only(sm) {
                flex-basis: calc((100% - #{$spacer}) / 2);
            }
            @include media-breakpoint-only(md) {
                flex-basis: calc((100% - (#{$spacer} * 2)) / 3);
            }
            @include media-breakpoint-up(lg) {
                flex-basis: calc((100% - (#{$spacer} * 3)) / 4);
            }
        }
    }

    // Landing Page & Shared Styling
    .blog-header {
        position: relative;
        display: block;
        // Set content position inside image when its present
        .blog-image ~ .blog-header-content {
            position: absolute;
        }
        .blog-header-content {
            align-content: end;
            bottom: 0;
            height: 100%;
            padding: ($spacer * 4) 0;
            width: 100dvw;
            &.light {
                color: $black;
                .blog-filter {
                    border-color: $grey-20;
                    color: $black;
                    a {
                        color: $black;
                    }
                }
            }
            &.dark {
                color: $white;
                .blog-filter {
                    border-color: $white;
                    color: $white;
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
    .blog-body {
        padding-top: $spacer * 4;
        @include media-breakpoint-up(lg) {
            padding-top: $spacer * 8;
        }
        .enhanced-slider-container {
            padding: 0 0 ($spacer * 10);
            &:last-of-type {
                padding-bottom: $spacer * 8;
            }
        }
    }
    .blog-image img {
        width: 100dvw;
    }
    .blog-category {
        margin-bottom: $spacer * 2;
        @include media-breakpoint-up(lg) {
            margin-bottom: $spacer * 4;
        }
    }
    .blog-filter {
        background-color: transparent;
        margin-bottom: 0;
    }
    // Mobile Filter
    select.blog-filter {
        padding: $spacer ($spacer * 2);
    }
    // Desktop filter
    div.blog-filter {
        border: 1px solid $grey-20;
        width: fit-content;
        .btn {
            border: 0;
            padding: $spacer ($spacer * 2);
        }
        .blog-active {
            text-decoration: underline;
        }
    }
}
