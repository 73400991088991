.help-center {
    .grid {
        grid-template-columns: 1fr 1fr;

        @include media-breakpoint-up(lg) {
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }

        gap: 32px;

        .grid-cell {
            border: 1px solid $grey-20;
            text-decoration: none;
            padding-top: 32px;
            padding-bottom: 32px;

            @include media-breakpoint-up(lg) {
                padding-top: 64px;
                padding-bottom: 64px;
            }

            .icon {
                margin-bottom: 32px;
            }
        }
    }
}
