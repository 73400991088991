.site-search {
    position: relative;
    width: 100%;

    form {
        @include media-breakpoint-up(lg) {
            display: flex;
            align-items: center;
        }
    }

    .search-field {
        border-top: none;
    }
    .fa-close,
    .fa-search {
        position: absolute;
        border: none;
        padding: 0;
        background-color: transparent;
    }
    .fa-close {
        @include icon($icon-close, before, inherit, $header-search-icon-color);
    }
    .fa-search {
        @include icon($icon-magnifing-glass, before, inherit, $header-search-icon-color);
    }
    .reset-button {
        @include icon($icon-close, before, 12px, $header-search-icon-color);
        @include vertical-center;
        border: none;
        right: 3px;
        padding: 0 5px;
        z-index: 1;
    }
    input {
        height: 40px;
        padding-right: 40px;
        appearance: none; // prevents unintentional box-shadows in iOS
        @include media-breakpoint-up(lg) {
            border-radius: 0;
        }
        &:hover {
            box-shadow: none;
        }
    }
    @include media-breakpoint-up(lg) {
        float: right;
        display: block;
        height: $header-height-lg;
        border-width: $header-search-desktop-border-width;
        border-color: $header-search-desktop-expanded-border-color;
        border-style: solid;
        form {
            float: right;
        }
        .search-field {
            @include navigation-link;
            border: none;
            text-align: left;
            color: $header-link-color;
            padding: 8px 10px;
            transition: width $transition-speed ease-out;
            width: $header-search-desktop-width;
            background: transparent;
            text-decoration: none;
            &::placeholder {
                color: $header-link-color;
            }
            &:focus {
                box-shadow: none;
            }
            &:hover {
                text-decoration: none;
            }
        }
        .fa-close {
            @include vertical-center;
        }
        .fa-close::before,
        .fa-search::before,
        .reset-button::before {
            font-size: $header-search-icon-font-size;
        }
    }
    @include media-breakpoint-up(xl) {
        .search-field:focus {
            width: $header-search-desktop-width-focus;
        }
    }
}
.search-icon-only {
    .site-search {
        border-color: transparent;
        border-style: solid;
        border-width: $header-search-desktop-border-width;
        transition: border $transition-speed ease-out;
        width: auto;
        .search-field {
            width: 0;
            display: none;
        }
        padding-left: 18px;
    }

    .site-search {
        .search-field {
            width: 100%;
            display: inline-block;
            @include media-breakpoint-down(md) {
                width: calc(100vw - 36px);
                padding: 0 20px;
                background: $white;
            }
        }
    }

    @include media-breakpoint-up(lg) {
        .site-search {
            &.expanded {
                border-color: $header-search-desktop-expanded-border-color;
                .search-field {
                    width: $header-search-desktop-width;
                }
                button {
                    color: $header-search-icon-color-hover;
                }
            }
        }
        .logo-center & {
            .site-search {
                height: $header-height-lg;
                border-bottom: 0;
                border-top: 0;
            }
        }
    }
    @include media-breakpoint-up(xl) {
        .site-search.expanded {
            .search-field {
                width: $header-search-desktop-width-focus;
            }
        }
    }
}

// Search suggestions
.search-modal {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
    height: 100vh;
    background-color: $white;
    width: $header-suggestions-results-width;
}

.search-modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: $black;
    opacity: 0.5;
    z-index: 300;
}

.search-field-wrapper {
    position: relative;
}

.suggestions-wrapper {
    .suggestions-header {
        font-family: $header-suggestions-header-font;
        font-size: $header-suggestions-header-font-size;
        margin-bottom: $spacer-sm;
        color: $header-suggestions-header-color;
        text-transform: $header-suggestions-header-text-transform;
        padding-top: $spacer;
        background: transparent;
        &:not(:first-child) {
            border-top: $header-suggestions-border;
        }
    }
    .suggestions-items {
        font-family: $header-suggestions-item-font;
        font-size: $header-suggestions-item-font-size;
        padding-bottom: $spacer;
    }
    .suggestions-item {
        a {
            display: flex;
            align-items: center;
            text-decoration: none;
            padding-top: 3px;
            padding-bottom: 3px;
        }
        .swatch-thumbnail {
            @include swatch($header-suggestions-image-size, $white, 'thumbnail');
            margin-right: $spacer;
        }
        .name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
        .category-parent {
            color: $header-suggestions-item-category-parent-color;
        }
        &.selected {
            background-color: $dropdown-item-hover-background;
        }
    }
}
