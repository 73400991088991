.nav-tabs {
    border-bottom: none;
    .nav-link {
        font: $tab-font;
        text-transform: $tab-text-transform;
        font-weight: $tab-font-weight;
        text-decoration: $tab-text-decoration;
        letter-spacing: $tab-letter-spacing;
        padding: $tab-padding-top $tab-padding-right $tab-padding-bottom $tab-padding-left;
        border-radius: $tab-border-radius-top-left $tab-border-radius-top-right $tab-border-radius-bottom-right $tab-border-radius-bottom-left;
        background-color: $tab-background;
        color: $tab-color;
        border-top: $tab-border-top;
        border-right: $tab-border-right;
        border-bottom: $tab-border-bottom;
        border-left: $tab-border-left;
        &:hover {
            text-decoration: $tab-text-decoration-hover;
            background-color: $tab-background-hover;
            color: $tab-color-hover;
            border-top: $tab-border-top-hover;
            border-right: $tab-border-right-hover;
            border-bottom: $tab-border-bottom-hover;
            border-left: $tab-border-left-hover;
        }
        &.active {
            text-decoration: $tab-text-decoration-active;
            background-color: $tab-background-active;
            color: $tab-color-active;
            border-top: $tab-border-top-active;
            border-right: $tab-border-right-active;
            border-bottom: $tab-border-bottom-active;
            border-left: $tab-border-left-active;
        }
    }

    // remove styles if there is only one tab
    .nav-item:only-child .nav-link {
        border: none;
        background: transparent;
    }
}
