.content-tile {
    @include zoom-image('.content-tile-image', $content-tile-image-zoom-amount);
    display: block;
    text-decoration: none;
    margin-bottom: 0px;
    .content-tile-image-wrapper {
        display: block;
        overflow: hidden;
        position: relative;
        margin-bottom: $content-tile-image-margin-bottom;
    }
    .content-tile-full-image-wrapper {
        float: left;
        width: 675px;
        height: 400px;
        display: block;
        overflow: hidden;
        position: relative;
        margin-bottom: $content-tile-image-margin-bottom;
    }
    .content-tile-full-body {
        margin-left: $spacer *2;
        height: 400px;
        display: flex;
        align-items: center;
        padding-left: 24px;
    }
    .content-tile-image {
        @include aspect-ratio($content-tile-image-aspect-ratio);
        display: block;
    }
    .content-tile-body {
        display: block;
    }
    .content-tile-category {
        display: block;
        font-family: $content-tile-category-font;
        font-size: $content-tile-category-font-size;
        font-weight: $content-tile-category-font-weight;
        text-decoration: $content-tile-category-text-decoration;
        color: $content-tile-category-color;
        margin-bottom: $content-tile-category-margin-bottom;
    }
    .content-tile-name {
        font-family: $content-tile-name-font;
        font-size: 20px;
        font-weight: 500;
        text-decoration: $content-tile-name-text-decoration;
        color: $content-tile-name-color;
        margin-bottom:
         $content-tile-name-margin-bottom;
    }
    .content-tile-snippet {
        display: block;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: $spacer;
        color: $black;
    }
    .content-tile-date {
        display: inline-block;
        font-family: $content-tile-date-font;
        font-size: 16px;
        font-weight: 400;
        color: $content-tile-date-color;
        margin-bottom: $content-tile-date-margin-bottom;
        color: #8F8F8F;

    }
    .blog-article-tag {
        display: inline-block;
        border: 1px solid $border-color;
        padding-left: 6px;
        padding-right: 6px;
        line-height: 17px;
        text-align: center;
        margin-bottom: $spacer *2;
        border-radius: 6px;
        font-size: 10px;
        background-color: $black;
        color: $white;
    }
    .contentTileFull {
        width: 100%;
    }
    .content-tile-description {
        font-family: $content-tile-description-font;
        font-size: $content-tile-description-font-size;
        font-weight: $content-tile-description-font-weight;
        text-decoration: $content-tile-description-text-decoration;
        color: $content-tile-description-color;
    }
    &:hover {
        text-decoration: none;
        .content-tile-image-wrapper::after {
            content: '';
            @include absolute-cover;
            pointer-events: none;
            box-shadow: $content-tile-image-hover-shadow;
        }
        .content-tile-name {
            text-decoration: $content-tile-name-text-decoration-hover;
            color: $content-tile-name-color-hover;
        }
        .content-tile-description {
            text-decoration: $content-tile-description-text-decoration-hover;
            color: $content-tile-description-color-hover;
        }
    }
}
.content-tile-blog-wrapper {
    max-width: 100%;
    height: 400px;
    margin-bottom: $spacer *2;
    @include media-breakpoint-up(md) {
        display: none;
    }
    @include media-breakpoint-up(lg) {
        display: block;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.blog-top-article {
    @include media-breakpoint-up(lg) {
        display: none;
    }
    @include media-breakpoint-down(md) {
        display: block;
        max-width: 100%;
        padding: 0px;
    }
}