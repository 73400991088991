$yotpo-spacer: $spacer-sm;

// rating stars under pricing
$yotpo-rating-stars-icon-font: $product-rating-star-icon-font;
$yotpo-rating-stars-color: $product-rating-star-color;
$yotpo-rating-stars-size: $pdp-rating-star-size;
$yotpo-rating-star-icon: "\e60e";
$yotpo-rating-star-icon-half: "\e61a";
$yotpo-rating-star-icon-empty: "\e61b";

$yotpo-pdp-reviews-link-display: $pdp-reviews-link-display;
$yotpo-pdp-reviews-link-font-size: $pdp-reviews-link-font-size;
$yotpo-pdp-reviews-link-color: $pdp-reviews-link-color;
$yotpo-pdp-reviews-link-color-hover: $pdp-reviews-link-color-hover;
$yotpo-product-tile-rating-star-size: $product-tile-rating-star-size;
$yotpo-product-tile-reviews-link-display: $product-tile-reviews-link-display;
$yotpo-product-tile-reviews-link-font-size: $product-tile-reviews-link-font-size;
$yotpo-product-tile-reviews-link-color: $product-tile-reviews-link-color;
$yotpo-font-family: "Roboto", sans-serif;
$yotpo-button-icon-color: $black;
$yotpo-pagination-color: #bf2429;
$yotpo-user-name-color: $black;

/*
 * Based off of Yotpo CSS Widget Version: 2020-04-26_10-52-32
 * Note: media breakpoint at 960px and some specificities are kept to override Yotpo's styles
 */

// common styles
.yotpo-small,
.yotpo.yotpo-main-widget,
.yotpo.mobile-filters-modal {
    .yotpo-icon-star {
        @include icon($yotpo-rating-star-icon, before, inherit, $yotpo-rating-stars-color);
    }

    .yotpo-icon-half-star {
        @include icon($yotpo-rating-star-icon-half, before, inherit, $yotpo-rating-stars-color);
    }

    .yotpo-icon-empty-star {
        @include icon($yotpo-rating-star-icon-empty, before, inherit, $yotpo-rating-stars-color);
    }
}

.yotpo.yotpo-main-widget,
.yotpo.mobile-filters-modal {
    div, span, p, a, img, i, strong, sup, ul, li, form, label {
        font-family: $yotpo-font-family !important;
        line-height: $base-line-height;
    }
}

// styles for buttons
.yotpo.yotpo-main-widget {
    .write-first-review-button,
    input.yotpo-default-button.primary-color-btn {
        @include button(primary);
        background-color: $button-primary-background !important;
        border: $button-border-width solid $button-primary-border-color !important;
        height: auto;
        font-family: $yotpo-font-family;
        &:hover {
            background-color: $button-primary-background-hover !important;
            background: $button-primary-background-hover !important;
            border: $button-border-width solid $button-primary-border-color-hover !important;
        }
    }
}

// Autobahn styles + Fox specific styles for the small widget that displays the ratings stars under pricing.
.yotpo-small {
    .standalone-bottomline {
        display: flex;
    }

    .yotpo-bottomline {
        .yotpo-stars {
            display: flex;
            align-items: center;
            cursor: default;

            .yotpo-icon-star,
            .yotpo-icon-half-star,
            .yotpo-icon-empty-star {
                background-image: none !important;
                &::before {
                    font-size: 16px;
                }
            }
        }

        .text-m {
            display: $yotpo-pdp-reviews-link-display;
            font-size: $yotpo-pdp-reviews-link-font-size;
            margin-left: $yotpo-spacer;
            color: $yotpo-pdp-reviews-link-color !important;
            &:hover {
                color: $yotpo-pdp-reviews-link-color-hover !important;
            }
        }
        .product-tile & {
            .text-m {
                display: $yotpo-product-tile-reviews-link-display;
                font-size: $yotpo-product-tile-reviews-link-font-size;
                color: $yotpo-product-tile-reviews-link-color !important;
                &:hover {
                    color: $product-tile-reviews-link-color-hover !important;
                }
            }
        }

        &.pull-left.star-clickable {
            float: none;
            margin: 0 auto;
        }
    }

    .yotpo-icon {
        font-family: $yotpo-rating-stars-icon-font !important;

        &:before {
            font-family: $yotpo-rating-stars-icon-font !important;
        }
    }

    .rating-star {
        height: auto;
        line-height: normal;
        vertical-align: middle;
        font-size: $yotpo-rating-stars-size !important;
        &:before {
            font-family: $yotpo-rating-stars-icon-font !important;
        }
        .product-tile & {
            font-size: $yotpo-product-tile-rating-star-size !important;
        }
    }
}

// Override Yotpo Bold Font Weight
.yotpo-widget-instance #yotpo-reviews-main-widget, .yotpo-widget-instance #yotpo-app {
    --primary-font-weight: 500;
}
// PDP Review Widget - Container
.yotpo-widget-instance #yotpo-reviews-main-widget {
    .yotpo-title-text, .yotpo-review-title, .yotpo-new-review-btn, .yotpo-score {
        font-weight: 500;
    }
    .yotpo-score {
        font-family: $yotpo-font-family;
    }
    .yotpo-base-layout {
        width: 100% !important; // Important needed to override inline css on class
    }
    // Maintain mobile styling
    // Above 480px, Yotpo applies its medium breakpoint 'yotpo-display-m' styling.
    @media screen and (min-width: 480px) and (max-width: #{map-get($grid-breakpoints, md) - 1}){
        .yotpo-layout-header-wrapper, .yotpo-bottom-line {
            flex-direction: column;
        }
        .yotpo-bottom-line {
            margin-bottom: $spacer * 4;
            margin-right: 0;
        }
        .yotpo-summary-section {
            align-items: center;
        }
        .yotpo-bottom-line .yotpo-bottom-line-right-panel {
            padding-right: 0;
        }
        .yotpo-star-distribution {
            border-right: 0 !important; // Important needed to override inline css on class;
            margin-top: $spacer;
        }
        .yotpo-filters-top-panel {
            flex-wrap: wrap;
            gap: $spacer;
            & > * {
                margin-right: 0;
            }
        }
        .yotpo-filters-container-inner {
            gap: $spacer;
        }
        .yotpo-review-left-panel, .yotpo-review-right-panel {
            flex-basis: 50%;
            order: 0;
        }
        .yotpo-review-right-panel {
            margin-top: $spacer / 2;
        }
        .yotpo-review-center-panel {
            flex-basis: 100%;
            margin-top: $spacer;
            order: 1;
            padding: 0;
        }
        .yotpo-review-rating-title {
            display: unset;
        }
        .yotpo-review-title {
            margin: $spacer 0;
        }
        .yotpo-review-star-rating {
            margin-bottom: $spacer;
        }
    }
    
}
// PDP Review Widget - Write A Review Popup
.yotpo-reviews-main-widget {
    #newReviewFormModal {
        z-index: 400;
    }
    #yotpo-modal {
        margin: $spacer * 2;
        overflow-x: unset;
        @media screen and (min-width: 480px) and (max-width: #{map-get($grid-breakpoints, md) - 1}){
            max-width: 100%;
        }
        .yotpo-modal-header, .yotpo-form-label, .yotpo-file-input-headline, .yotpo-upload-label {
            font-weight: 500;
        }
        .yotpo-uploaded-images {
            width: 100%;
        }
    }
}