.blog-tile {
    display: block;
    height: 100%;
    position: relative;
    .blog-tile-image {
        width: 100%;
    }
    .blog-tile-content {
        bottom: 0;
        color: $white;
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        padding: $spacer * 2;
        position: absolute;
        width: 100%;
        &::before,
        &::after {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            transition: all $transition-speed;
        }
        // Content Hub Tile Default State - Gradient
        &::before {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 11.5%, rgba(0, 0, 0, 0.17) 51%, rgba(0, 0, 0, 0.60) 80%);
            opacity: 1;
        }
        // Content Hub Tile Hover State - Solid Color
        &::after {
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.60) 80%, rgba(0, 0, 0, 0.60) 80%, rgba(0, 0, 0, 0.60) 80%);
            opacity: 0;
        }
        @include media-breakpoint-up(md) {
            &:hover::before {
                opacity: 0;
            }
            &:hover::after {
                opacity: 1;
            }
        }
        .blog-tile-header {
            display: flex;
            flex-basis: 100%;
            height: fit-content;
        }
        .blog-tile-text {
            flex-basis: 100%;
            margin-bottom: 0;
            margin-top: auto;
        }
        .blog-tile-title {
            margin-bottom: 0;
            position: relative;
        }
        .blog-flag-icon {
            margin-left: auto;
        }
        .blog-tile-title,
        .blog-tile-folder,
        .blog-tile-excerpt,
        .blog-flag-icon {
            z-index: 2;
        }
        .blog-tile-folder,
        .blog-tile-excerpt {
            bottom: 0;
            max-width: calc(100% - (#{$spacer} * 4)); // Prevent absolute positioned text from overflowing parent
            padding-bottom: $spacer * 2;
            position: absolute;
            transition: opacity $transition-speed; // text hover transition 
        }
        .blog-tile-folder {
            opacity: 1;
        }
        .blog-tile-excerpt {
            opacity: 0;
        }
    }
    @include media-breakpoint-up(md) {
        &:hover {
            .blog-tile-content {
                // Only transition text when excerpt present
                &:has(.blog-tile-excerpt) {
                    .blog-tile-excerpt {
                        opacity: 1;
                    }
                    .blog-tile-folder {
                        opacity: 0;
                    }
                }
            }
        }
    }
}