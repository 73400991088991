@import "bootstrap/scss/functions";
@import "utilities/icons";

// Brand Colors
$brand-01: #0C0A08;
$brand-02: #40403F;
$brand-03: #99FF00;

$secondary-01: #ECEEE4;
$secondary-02: #D8DDC9;
$secondary-03: #B9BFA7;

// Grey Scale
$white: #FFF;
$grey-10: #F8F8F8;
$grey-20: #D6D6D6;
$grey-30: #595959;
$grey-40: #2B2B2A;
$black: #000;

// Other Colors
$green: #00865A;
$yellow: #FC0;
$red: #BF242B;
$blue: #3091E7;
$yellow-green: #E6FF00;

$success: $green;
$warning: $yellow;
$danger: $red;
$info: $blue;

$colors: (
    brand-01: $brand-01,
    brand-02: $brand-02,
    brand-03: $brand-03,
    secondary-01: $secondary-01,
    secondary-02: $secondary-02,
    secondary-03: $secondary-03,
    white: $white,
    grey-10: $grey-10,
    grey-20: $grey-20,
    grey-30: $grey-30,
    grey-40: $grey-40,
    black: $black,
    green: $green,
    yellow: $yellow,
    red: $red,
    blue: $blue,
    success: $success,
    warning: $warning,
    danger: $danger,
    info: $info
);

$swatch-colors: (
    'beige': #f5f5dc,
    'black': $black,
    'blue': #00559c,
    'brown': #9a8d7a,
    'green': #5d624e,
    'grey': #606263,
    'navy': #000080,
    'orange': #f05829,
    'pink': #ff7263,
    'purple': #592b37,
    'red': #ff1027,
    'white': $white,
    'yellow': #d7ff2f
);

/*
* GRID SYSTEM
*/
// These values should match the Breakpoints values in scripts/constants/SiteConstants.js
$grid-breakpoints: (
    xs: 0,
    sm: 544px,
    md: 769px,
    lg: 1024px,
    xl: 1440px
);

// Containers are set to higher values than corresponding breakpoints to allow them to be full width
$container-max-widths: (
    md: 1024px,
    lg: 1440px,
    xl: 1920px
);

$spacer-sm: 5px;
$spacer: 12px; // This value should match the Spacer value in scripts/constants/SiteConstants.js
$spacer-xl: 24px;
$negative-spacer-sm: -$spacer-sm;
$negative-spacer: -$spacer;
$negative-spacer-xl: -$spacer-xl;

$grid-gutter-widths: (
  xs: $spacer,
  md: $spacer,
  lg: $spacer,
  xl: $spacer-xl // Note: changing this will update all column and container spacing, but will not affect uses of $spacer variable
);

/*
* Z Indexes
*/
$z-index-lv5: 1001 !default;
$z-index-lv4: 1000 !default;
$z-index-lv3: 500 !default;
$z-index-lv2: 200 !default;
$z-index-lv1: 100 !default;
$z-index-base: 0 !default;

$z-index-toast: 1100;
$z-index-modal: 1050;
$z-index-modal-backdrop: 1000;
$z-index-header-fixed: 400;
$z-index-header-promo-banner: 350;
$z-index-header: 300;
$z-index-refinement-drawer: 250;
$z-index-pdp-sticky-add-to-cart: 200;
$z-index-sticky-footer: 100;
$z-index-wishlist-icon: 50;
$z-index-consent-drawer: 2147483601; //This is set so high due to integrations like FreshChat that use z-index of 2147483600


/*
* ASPECT RATIOS
*/
$aspect-ratio-square: calc(1 / 1 * 100%);
$aspect-ratio-landscape: calc(1 / 1.5 * 100%);
$aspect-ratio-portrait: calc(1.5 / 1 * 100%);
$aspect-ratio-one-one: calc(1 / 1 * 100%);
$aspect-ratio-sixteen-nine: calc(9 / 16 * 100%);
$aspect-ratio-nine-sixteen: calc(16 / 9 * 100%);
$aspect-ratio-four-three: calc(3 / 4 * 100%);
$aspect-ratio-three-four: calc(4 / 3 * 100%);
// horizontal versions used for enforcing horizontal width in hero videos
$aspect-ratio-one-one-horizontal: calc(1 / 1 * 100vh);
$aspect-ratio-sixteen-nine-horizontal: calc(16 / 9 * 100vh);
$aspect-ratio-nine-sixteen-horizontal: calc(9 / 16 * 100vh);
$aspect-ratio-four-three-horizontal: calc(4 / 3 * 100vh);
$aspect-ratio-three-four-horizontal: calc(3 / 4 * 100vh);

/*
* MISC
*/
$transition-speed: 200ms; // This value should match the TransitionSpeed value in scripts/constants/SiteConstants.js
$hero-transition-speed-long: 1000ms;
$hero-transition-speed-medium: 600ms;
$hero-transition-speed-short: 200ms;
$base-font-size: 16px;
$base-line-height: 1.5em;
$base-font-color: $black;
$border-radius: 0px;
$border-color: $grey-20;
$page-background-color: $white;
$leading-lines-border-color: transparent;
$leading-lines-border-width: 0;


/*
* FONTS (setup is handled in utilities/_fonts.scss)
*/
$primary-font: 'archia-medium', sans-serif;
$primary-font-bold: 'archia-bold', sans-serif;
$secondary-font: 'Roboto', sans-serif;
$tertiary-font: 'Roboto', sans-serif;
$header-font: $primary-font;
$body-font: $secondary-font;

$fonts: (
    primary: $primary-font,
    secondary: $secondary-font,
    tertiary: $tertiary-font,
    header: $header-font,
    body: $body-font
);


/*
* TEXT
*/
// Stand Alone Text Defaults
$default-text-font: $secondary-font;
$default-text-font-size: 14px;
$default-text-line-height: 1.5em;
$default-text-font-weight: normal;
$default-text-letter-spacing: 0;

// Paragraphs
$paragraph-font-size: 14px; // paragraph size on mobile
$paragraph-line-height: 1.5em;
$paragraph-letter-spacing: 0;
$paragraph-md-font-size: $paragraph-font-size; // paragraph size on desktop
$paragraph-md-line-height: $paragraph-line-height;
$paragraph-md-letter-spacing: $paragraph-letter-spacing;

$paragraph-font-size-large: 16px;
$paragraph-line-height-large: 1.5em;
$paragraph-letter-spacing-large: 0;
$paragraph-font-weight-large: 400;
$paragraph-md-font-size-large: 16px;
$paragraph-md-line-height-large: $paragraph-line-height-large;
$paragraph-md-letter-spacing-large: $paragraph-letter-spacing-large;

$paragraph-font-small: $default-text-font;
$paragraph-font-size-small: 12px; // small paragraph size on mobile
$paragraph-line-height-small: 1.5em;
$paragraph-letter-spacing-small: 0;
$paragraph-md-font-size-small: $paragraph-font-size-small; // small paragraph size on desktop
$paragraph-md-line-height-small: $paragraph-line-height-small;
$paragraph-md-letter-spacing-small: $paragraph-letter-spacing-small;

$paragraph-bottom-margin: $spacer;

// Subtitles
$subtitle-font-family: $primary-font;
$subtitle-font-size: 12px;
$subtitle-line-height: 17px;
$subtitle-letter-spacing: 0;
$subtitle-text-transform: uppercase;
$subtitle-font-style: normal;

$subtitle-lg-font-family: $subtitle-font-family;
$subtitle-lg-font-size: 15px;
$subtitle-lg-line-height: 17px;
$subtitle-lg-letter-spacing: 0;
$subtitle-lg-text-transform: $subtitle-text-transform;
$subtitle-lg-font-style: $subtitle-font-style;

// Links
$link-color: $brand-01;
$link-color-hover: $grey-40;
$link-dark-theme-color: $white;
$link-dark-theme-color-hover: $white;
$link-font-weight: normal;
$link-text-decoration: underline;
$link-text-decoration-hover: underline;


/*
* HEADERS
*/
// Mobile header values
$h1-font-family: $primary-font;
$h1-font-size: 35px;
$h1-line-height: 1.5em;
$h1-font-weight: 400;
$h1-letter-spacing: 0;
$h1-text-transform: none;
$h1-font-style: normal;

$h2-font-family: $primary-font;
$h2-font-size: 29px;
$h2-line-height: 1.5em;
$h2-font-weight: 400;
$h2-letter-spacing: 0;
$h2-text-transform: none;
$h2-font-style: normal;

$h3-font-family: $primary-font;
$h3-font-size: 24px;
$h3-line-height: 1.5em;
$h3-font-weight: 400;
$h3-letter-spacing: 0;
$h3-text-transform: none;
$h3-font-style: normal;

$h4-font-family: $primary-font;
$h4-font-size: 20px;
$h4-line-height: 1.5em;
$h4-font-weight: 400;
$h4-letter-spacing: 0;
$h4-text-transform: none;
$h4-font-style: normal;

$h5-font-family: $primary-font;
$h5-font-size: 17px;
$h5-line-height: 1.5em;
$h5-font-weight: 400;
$h5-letter-spacing: 0;
$h5-text-transform: none;
$h5-font-style: normal;

$h6-font-family: $primary-font;
$h6-font-size: 16px;
$h6-line-height: 1.5em;
$h6-font-weight: 400;
$h6-letter-spacing: 0;
$h6-text-transform: none;
$h6-font-style: normal;

// Large desktop header values
$h1-lg-font-size: 42px;
$h1-lg-line-height: $h1-line-height;
$h1-lg-font-weight: $h1-font-weight;
$h1-lg-letter-spacing: $h1-letter-spacing;
$h1-lg-text-transform: $h1-text-transform;
$h1-lg-font-style: normal;

$h2-lg-font-size: 35px;
$h2-lg-line-height: $h2-line-height;
$h2-lg-font-weight: $h2-font-weight;
$h2-lg-letter-spacing: $h2-letter-spacing;
$h2-lg-text-transform: $h2-text-transform;
$h2-lg-font-style: normal;

$h3-lg-font-size: 29px;
$h3-lg-line-height: $h3-line-height;
$h3-lg-font-weight: $h3-font-weight;
$h3-lg-letter-spacing: $h3-letter-spacing;
$h3-lg-text-transform: $h3-text-transform;
$h3-lg-font-style: normal;

$h4-lg-font-size: 24px;
$h4-lg-line-height: $h4-line-height;
$h4-lg-font-weight: $h4-font-weight;
$h4-lg-letter-spacing: $h4-letter-spacing;
$h4-lg-text-transform: $h4-text-transform;
$h4-lg-font-style: normal;

$h5-lg-font-size: 20px;
$h5-lg-line-height: $h5-line-height;
$h5-lg-font-weight: $h5-font-weight;
$h5-lg-letter-spacing: $h5-letter-spacing;
$h5-lg-text-transform: $h5-text-transform;
$h5-lg-font-style: normal;

$h6-lg-font-size: 16px;
$h6-lg-line-height: $h6-line-height;
$h6-lg-font-weight: $h6-font-weight;
$h6-lg-letter-spacing: $h6-letter-spacing;
$h6-lg-text-transform: $h6-text-transform;
$h6-lg-font-style: normal;

$headers: (
    h1 $h1-font-family $h1-font-size $h1-line-height $h1-font-weight $h1-letter-spacing $h1-text-transform $h1-font-style $h1-lg-font-size $h1-lg-line-height
        $h1-lg-font-weight $h1-lg-letter-spacing $h1-lg-text-transform $h1-lg-font-style,
    h2 $h2-font-family $h2-font-size $h1-line-height $h2-font-weight $h2-letter-spacing $h2-text-transform $h2-font-style $h2-lg-font-size $h2-lg-line-height
        $h2-lg-font-weight $h2-lg-letter-spacing $h2-lg-text-transform $h2-lg-font-style,
    h3 $h3-font-family $h3-font-size $h3-line-height $h3-font-weight $h3-letter-spacing $h3-text-transform $h3-font-style $h3-lg-font-size $h3-lg-line-height
        $h3-lg-font-weight $h3-lg-letter-spacing $h3-lg-text-transform $h3-lg-font-style,
    h4 $h4-font-family $h4-font-size $h4-line-height $h4-font-weight $h4-letter-spacing $h4-text-transform $h4-font-style $h4-lg-font-size $h4-lg-line-height
        $h4-lg-font-weight $h4-lg-letter-spacing $h4-lg-text-transform $h4-lg-font-style,
    h5 $h5-font-family $h5-font-size $h5-line-height $h5-font-weight $h5-letter-spacing $h5-text-transform $h5-font-style $h5-lg-font-size $h5-lg-line-height
        $h5-lg-font-weight $h5-lg-letter-spacing $h5-lg-text-transform $h5-lg-font-style,
    h6 $h6-font-family $h6-font-size $h6-line-height $h6-font-weight $h6-letter-spacing $h6-text-transform $h6-font-style $h6-lg-font-size $h6-lg-line-height
        $h6-lg-font-weight $h6-lg-letter-spacing $h6-lg-text-transform $h6-lg-font-style
);


/*
* BUTTONS
*/
$button-font: $primary-font;
$button-font-size: 14px;
$button-line-height: 1.5em;
$button-font-weight: 500;
$button-padding-top: 12px;
$button-padding-bottom: 12px;
$button-padding-left: 20px;
$button-padding-right: 20px;
$button-letter-spacing: -0.42px;
$button-text-transform: none;
$button-border-radius: 50px;
$button-border-width: 1px;
$button-icon-size: 20px;
$button-text-align: center;

// Primary button
$button-primary-font: $primary-font;
$button-primary-font-weight: $button-font-weight;
$button-primary-text-transform: $button-text-transform;
$button-primary-background: $black;
$button-primary-background-hover: $black;
$button-primary-border-radius: $button-border-radius;
$button-primary-color: $white;
$button-primary-color-hover: $white;
$button-primary-border-color: $black;
$button-primary-border-color-hover: $white;
$button-primary-text-opacity-hover: 1;
$button-primary-border-width: $button-border-width;
$button-primary-text-decoration: none;
$button-primary-text-decoration-hover: none;
$button-primary-padding-top: $button-padding-top;
$button-primary-padding-bottom: $button-padding-bottom;
$button-primary-padding-left: $button-padding-left;
$button-primary-padding-right: $button-padding-right;
$button-primary-text-align: $button-text-align;
$button-primary-small-background-color: $black;
$button-primary-small-background-color-hover: $brand-02;
$button-primary-small-border-color: $black;
$button-primary-small-border-color-hover: $brand-02;
$button-primary-small-font: $primary-font;

// Secondary button
$button-secondary-font: $primary-font;
$button-secondary-font-weight: $button-font-weight;
$button-secondary-text-transform: $button-text-transform;
$button-secondary-color: $black;
$button-secondary-color-hover: $black;
$button-secondary-background: $white;
$button-secondary-background-hover: $white;
$button-secondary-border-radius: $button-border-radius;
$button-secondary-border-color: $grey-20;
$button-secondary-border-color-hover: $black;
$button-secondary-border-width: $button-border-width;
$button-secondary-text-opacity-hover: 1;
$button-secondary-text-decoration: none;
$button-secondary-text-decoration-hover: none;
$button-secondary-padding-top: $button-padding-top;
$button-secondary-padding-bottom: $button-padding-bottom;
$button-secondary-padding-left: $button-padding-left;
$button-secondary-padding-right: $button-padding-right;
$button-secondary-text-align: $button-text-align;

// Tertiary Button
$button-tertiary-font: $secondary-font;
$button-tertiary-font-weight: $button-font-weight;
$button-tertiary-text-transform: $button-text-transform;
$button-tertiary-color: $brand-01;
$button-tertiary-color-hover: $brand-02;
$button-tertiary-background: transparent;
$button-tertiary-background-hover: transparent;
$button-tertiary-border-radius: 0;
$button-tertiary-border-width: 0;
$button-tertiary-border-color: transparent;
$button-tertiary-border-color-hover: transparent;
$button-tertiary-text-decoration: underline;
$button-tertiary-text-decoration-hover: underline;
$button-tertiary-padding-top: 0;
$button-tertiary-padding-bottom: 7px;
$button-tertiary-padding-left: 0;
$button-tertiary-padding-right: 0;
$button-tertiary-text-align: $button-text-align;

// Add To Cart Button
$button-addtocart-background-color: $brand-03;
$button-addtocart-background-color-hover: $brand-03;
$button-addtocart-border-color: $grey-40;
$button-addtocart-border-radius: 9px;
$button-addtocart-border-width: 1px;
$button-addtocart-text-color: $brand-01;
$button-addtocart-disabled-text-color: $grey-30;
$button-addtocart-disabled-background-color: $white;
$button-addtocart-disabled-border-color: $grey-20;

// View Details Button
$button-viewdetails-background-color: $white;
$button-viewdetails-border-width: 1px;
$button-viewdetails-border-color: $grey-20;
$button-viewdetails-border-color-hover: $brand-01;
$button-viewdetails-border-radius: 8px;
$button-viewdetails-text-color: $brand-01;

// Small Button
$button-sm-font-family: $primary-font;
$button-sm-font-size: 14px;
$button-sm-line-height: 1.5em;
$button-sm-padding-top: 8px;
$button-sm-padding-bottom: 8px;
$button-sm-padding-left: 20px;
$button-sm-padding-right: 20px;
$button-sm-letter-spacing: 0;
$button-sm-icon-size: 22px;
$button-sm-border: none;

// Small Primary Button
$button-sm-primary-color: $white;
$button-sm-primary-color-hover: $white;
$button-sm-primary-background: $black;
$button-sm-primary-background-hover: $brand-02;
$button-sm-primary-border-color: $black;
$button-sm-primary-border-color-hover: $brand-02;
$button-sm-primary-border-radius: $button-border-radius;

// Small Secondary Button
$button-sm-secondary-color: $brand-01;
$button-sm-secondary-color-hover: $brand-02;
$button-sm-secondary-background: $white;
$button-sm-secondary-background-hover: $white;
$button-sm-secondary-border-color: $white;
$button-sm-secondary-border-color-hover: $white;
$button-sm-secondary-border-radius: $button-border-radius;

// Slider Buttons
$button-slider-size: 40px;
$button-slider-color-disabled: $grey-20;
$button-slider-border-color-disabled: $grey-20;

/*
* FORMs
*/
// Form Group
$form-group-margin-top: $spacer-xl;

// Inputs
$input-border-color: $grey-20;
$input-border-color-focus: $brand-02;
$input-border-color-hover: $grey-40;
$input-background-color: $white;
$input-outline: none;
$input-outline-focus: none;
$input-outline-hover: none;
$input-box-shadow: none;
$input-box-shadow-focus: none;
$input-box-shadow-hover: 0 0 0 1px $grey-40;
$input-text-color: $base-font-color;
$input-placeholder-color: $grey-30;
$input-border-width: 1px;
$input-border-radius: 8px;
$input-font: $body-font;
$input-font-size: 14px; // this must be 16px or more to prevent automatic zooming on iPhones
$input-font-size-md: $input-font-size;
$input-font-line-height: 14px;
$input-font-letter-spacing: 0;
$input-padding-top: $spacer;
$input-padding-bottom: $spacer;
$input-padding-left: $spacer;
$input-padding-right: $spacer;
$input-with-text-padding-top: $spacer * 2;
$input-with-text-padding-bottom: 8px;
$input-with-text-label-padding-top: 9px;
$input-label-padding-top: 12px;
$input-with-text-label-padding-bottom: 0;

$input-height: $spacer * 4;
//$input-height: calc(#{$input-font-line-height} + #{$input-padding-top} + #{$input-padding-bottom} + (#{$input-border-width}*2));

// Small input
$input-sm-font-size: 11px;
$input-sm-font-line-height: 14px;
$input-sm-padding-top: 8px;
$input-sm-padding-bottom: 6px;
$input-sm-padding-left: 10px;
$input-sm-padding-right: 10px;
$input-sm-height: calc(#{$input-sm-font-line-height} + #{$input-sm-padding-top} + #{$input-sm-padding-bottom} + (#{$input-border-width}*2));

// Labels
$text-label-font-size: 11px;
$text-label-margin-bottom: 6px;
$form-label-color: $grey-30;
$form-label-font: $secondary-font;
$form-label-font-weight: 400;
$form-label-font-size: 12px;
$form-label-line-height: 12px;
$form-label-margin-bottom: 0;
$form-label-text-transform: none;
$form-label-letter-spacing: 0;

// Help text
$form-help-text-font: $secondary-font;
$form-help-text-color: $grey-30;
$form-help-text-font-size: 12px;
$form-help-text-line-height: 12px;
$form-help-text-letter-spacing: 0;
$form-help-text-margin-top: 12px;

// Validation
$form-validation-color: map-get($colors, danger);
$form-validation-font-size: $form-label-font-size;

// Radio buttons and check boxes
$radiocheck-label-color: $grey-30;
$radiocheck-label-font: $form-label-font;
$radiocheck-label-font-weight: $form-label-font-weight;
$radiocheck-label-font-size: $form-label-font-size;
$radiocheck-label-text-transform: none;
$radiocheck-label-letter-spacing: $form-label-letter-spacing;
$radiocheck-background-color: $white;
$radiocheck-background-color-selected: $black;
$radiocheck-background-color-disabled: $grey-20;
$radiocheck-background-color-selected: $black;
$radiocheck-background-color-error: lighten($danger, 55%);
$radiocheck-border-color: $black;
$radiocheck-border-color-selected: $black;
$radiocheck-border-color-disabled: $black;
$radiocheck-border-color-error: $danger;
$radiocheck-icon-color: $white;
$radiocheck-button-size: 20px;
$radiocheck-button-margin: 12px; // spacing between icon and text label
$radiocheck-vertical-spacing: 12px; // the margin between items when more than one are listed
$radiobutton-selected-inset-amount: 10px;
$radiocheck-dark-label-color: $white;
$radiocheck-dark-label-disabled: $grey-20;
$radiocheck-dark-label-error: $danger;
$radiocheck-dark-background-color: $black;
$radiocheck-dark-background-color-selected: $black;
$radiocheck-dark-background-color-error: $black;
$radiocheck-dark-border-color: $white;
$radiocheck-dark-border-color-selected: $white;
$radiocheck-dark-border-color-disabled: $white;
$radiocheck-dark-hover-box-shadow: 0 0 0 1px $white;


// Select dropdowns
$select-icon-size: 10px;
$select-border-secondary: 1px solid $grey-20;
$select-padding-right: $input-padding-right + $select-icon-size + $spacer-sm;
// Small dropdown
$select-sm-icon-size: 10px;
$select-sm-padding-right: $input-sm-padding-right + $select-sm-icon-size + $spacer-sm;

// Quantity stepper
$quantity-stepper-icon-size: 10px;
$quantity-stepper-icon-size-small: 8px;

// Inline Button + Input group
$inline-input-button-border-color: $grey-40;
$inline-input-button-border-color-hover: $black;
$inline-input-button-background-color: transparent;
$inline-input-button-box-shadow: none;
$inline-input-button-box-shadow-hover: none;
$inline-input-button-text-color: $input-text-color;
$inline-input-button-placeholder-color: $input-placeholder-color;
$inline-input-button-border-width: 0 0 1px 0;
$inline-input-button-border-radius: 0;
$inline-input-button-font-size: $input-font-size;
$inline-input-button-font-line-height: $input-font-line-height;
$inline-input-button-font-letter-spacing: $input-font-letter-spacing;
$inline-input-button-padding-top: $input-padding-top;
$inline-input-button-padding-bottom: $input-padding-bottom;
$inline-input-button-padding-left: $input-padding-left;
$inline-input-button-padding-right: $input-padding-right;



/*
* BREADCRUMBS
*/
$breadcrumb-font-size: $default-text-font-size;
$breadcrumb-line-height: 1.5em;
$breadcrumb-letter-spacing: 0;
$breadcrumb-font-weight: 500;
$breadcrumb-text-decoration: none;
$breadcrumb-text-decoration-hover: underline;
$breadcrumb-divider: '/';
$breadcrumb-divider-color: $link-color;
$breadcrumb-item-padding: $spacer/2;
$breadcrumb-item-color: $link-color;
$breadcrumb-item-color-hover: $link-color-hover;


/*
* TOOLTIPS
*/
$tooltip-background-color: $white;
$tooltip-text-color: $black;
$tooltip-font-size: 11px;
$tooltip-border-radius: 0;
$tooltip-box-shadow: 0 2px 6px rgba($black, 0.1);
$tooltip-padding: 10px 15px;
$tooltip-max-width: 200px;
$tooltip-text-align: center;
$tooltip-opacity: 1;
$tooltip-icon: $icon-info;
$tooltip-icon-size: 120%;


/*
* POPOVERS
*/
$popover-font-size: 12px;
$popover-border-color: transparent;
$popover-box-shadow: $tooltip-box-shadow;
$popover-header-background-color: $grey-30;
$popover-header-text-color: $white;
$popover-header-padding: $spacer-sm $spacer;
$popover-body-background-color: $white;
$popover-body-padding: $spacer;
$popover-border-radius: $border-radius;
$popover-max-width: 300px;


/*
* MODALS
*/
$modal-backdrop-background-color: rgba($black, 0.6);
$modal-background-color: transparent;
$modal-border: none;
$modal-border-radius: $border-radius;
$modal-box-shadow: 0 2px 6px rgba($black, 0.1);
$modal-max-width: 500px;
$modal-sm-max-width: 300px;
$modal-lg-max-width: 900px;
$modal-xl-max-width: 1140px;
$modal-margins-mobile: 8px;
$modal-margins-desktop: $spacer*2 auto;
// Header
$modal-header-padding: 10px 20px 9px;
$modal-header-background-color: $grey-40;
$modal-header-text-color: $white;
$modal-header-border-color: transparent;
$modal-header-title-size: 4; // This number should be 1-6, corresponding with h1-h6 headers
$modal-header-close-icon-size: 14px;
// Body
$modal-body-padding: 20px;
$modal-body-background-color: $white;
$modal-body-text-color: $base-font-color;
// Footer
$modal-footer-padding: 0 20px 20px;
$modal-footer-background-color: $white;
$modal-footer-text-color: $base-font-color;
$modal-footer-border-color: transparent;



/*
* ALERTS (also affects toast messages)
*/
$alert-border-radius: $border-radius;
$alert-padding: $spacer;
$alert-success-color: $success;
$alert-success-background: desaturate(lighten($alert-success-color, 67%), 67%);
$alert-success-text: darken($alert-success-color, 10%);
$alert-warning-color: $warning;
$alert-warning-background: desaturate(lighten($alert-warning-color, 45%), 25%);
$alert-warning-text: darken($alert-warning-color, 20%);
$alert-danger-color: $danger;
$alert-danger-background: desaturate(lighten($alert-danger-color, 60%), 60%);
$alert-danger-text: darken($alert-danger-color, 10%);
$alert-info-color: $info;
$alert-info-background: desaturate(lighten($alert-info-color, 35%), 12%);
$alert-info-text: darken($alert-info-color, 35%);


/*
* TOAST MESSAGES
*/
$toast-padding: $spacer;
$toast-box-shadow: 0 2px 4px rgba($black, 0.4);


/*
* CARDS
*/
$card-border-color: $grey-20;
$card-padding-left: 0px;
$card-border-radius: $border-radius;
$card-header-font-family: $primary-font;
$card-header-font-size: 20px;
$card-header-line-height: 20px;
$card-header-letter-spacing: 0;
$card-header-border-top: none;
$card-header-border-right: none;
$card-header-border-bottom: none;
$card-header-border-left: none;
$card-header-background: $white;
$card-header-color: $brand-01;
$card-header-border-color: $grey-20;
$card-header-padding-top: 17px;
$card-header-padding-right: 20px;
$card-header-padding-bottom: 22px;
$card-header-padding-left: 20px;
$card-header-text-transform: none;
$card-header-font-weight: normal;
$card-body-font-size: 12px;
$card-body-line-height: 24px;
$card-body-letter-spacing: 0;
$card-body-border-top: 1px solid $card-header-border-color;
$card-body-border-right: none;
$card-body-border-bottom: 1px solid $card-header-border-color;
$card-body-border-left: none;
$card-body-background: $white;
$card-body-border-color: $grey-20;
$card-body-color: $base-font-color;
$card-body-border-first-child-top: 1px solid $card-body-border-color;
$card-body-border-last-child-bottom: 1px solid $card-body-border-color;
$card-body-padding-bottom: 20px;
$card-body-padding-left: 20px;
$card-body-padding-right: 20px;
$card-body-padding-top: 0;
$card-no-header-body-padding-top: 20px;
$card-footer-background: $white;
$card-footer-color: $card-header-color;
$card-footer-border-color: $card-header-border-color;
$card-footer-padding: $card-header-padding-top $card-header-padding-right $card-header-padding-bottom $card-header-padding-left;
$card-ghost-opacity: 0.5;
$card-form-value-color: $grey-30;
$card-header-border-bottom-width: 0;
$card-margin-bottom: $spacer;
$card-wishlist-remove-btn-margin-top: 5px;
$card-wishlist-remove-btn-margin-right: 0px;
$card-wishlist-remove-btn-margin-bottom: 0px;
$card-wishlist-remove-btn-margin-left: 20px;
$card-wishlist-border-bottom: 1px solid $grey-20;
$card-wishlist-margin-bottom: 20px;
$card-wishlist-padding-bottom: 20px;

// 'aside' card variation - used on PLP refinements
$card-aside-header-padding-top: 20px;
$card-aside-header-padding-right: $card-header-padding-right;
$card-aside-header-padding-bottom: 20px;
$card-aside-header-padding-left: 0;
$card-aside-header-font-size: 13px;
$card-aside-header-font-weight: bold;
$card-aside-header-line-height: 18px;
$card-aside-body-padding: 0;
$card-aside-border-width: 1px 0;
$card-aside-border-color: $card-border-color;
$card-aside-body-border: none;
$card-aside-footer-border: none;

/*
* COLLAPSIBLE
*/
$collapsible-border-color: $card-header-border-color;
$collapsible-header-background: $card-header-background;
$collapsible-header-color: $card-header-color;
$collapsible-header-icon-color: $black;
$collapsible-header-icon-size: 12px;
$collapsible-body-background: $card-body-background;
$collapsible-body-color: $card-body-color;
$collapsible-header-icon-expand: $icon-plus;
$collapsible-header-icon-close: $icon-minus;

/*
* TABS - PDP
*/
$tab-font: 20px $primary-font;
$tab-text-transform: uppercase;
$tab-font-weight: bold;
$tab-letter-spacing: 0;
$tab-line-height: 20px;
$tab-opacity: 0.5;
$tab-border-color: $black;
$tab-border-width: 2px;
$tab-border-radius-top-left: 0;
$tab-border-radius-top-right: 0;
$tab-border-radius-bottom-left: 0;
$tab-border-radius-bottom-right: 0;
$tab-padding-top: 15px;
$tab-padding-right: $spacer;
$tab-padding-bottom: 11px;
$tab-padding-left: $spacer;
$tab-text-decoration: none;
$tab-background: transparent;
$tab-color: $black;
$tab-border-top: none;
$tab-border-right: none;
$tab-border-bottom: none;
$tab-border-left: none;
// hover state
$tab-text-decoration-hover: none;
$tab-background-hover: transparent;
$tab-color-hover: $black;
$tab-border-top-hover: none;
$tab-border-right-hover: none;
$tab-border-bottom-hover: $tab-border-width solid $tab-border-color;
$tab-border-left-hover: none;
// active state
$tab-text-decoration-active: none;
$tab-background-active: transparent;
$tab-color-active: $black;
$tab-border-top-active: none;
$tab-border-right-active: none;
$tab-border-bottom-active: $tab-border-width solid $tab-border-color;
$tab-border-left-active: none;

//FOX Additional
$tab-nav-item-margin: 20px;

/*
* SLIDER
*/
// title
$slider-heading-size: 3; // This number should be 1-6, corresponding with h1-h6 headers
$slider-heading-text-align: center;
// nav buttons
$slider-button-color: $white;
$slider-button-prev-icon: $icon-arrow-left;
$slider-button-next-icon: $icon-arrow-right;
$slider-button-background: $black;
$slider-button-color-hover: $black;
$slider-button-background-hover: $yellow-green;
$slider-button-icon-size: 15px;
$slider-button-border: 1px solid $white;
$slider-button-border-hover: 1px solid $yellow-green;
$slider-button-border-radius: 50px;
$slider-button-width: 40px;
$slider-button-height: 40px;
// pagination bullets
$slider-bullet-size: 11px;
$slider-bullet-margin-top: $spacer-sm * 2;
$slider-bullet-margin-right: $spacer-sm;
$slider-bullet-margin-bottom: $spacer-sm * 2;
$slider-bullet-margin-left: $spacer-sm;
$slider-bullet-background: rgba($black, 0.5);
$slider-bullet-border: none;
$slider-bullet-background-active: $black;
$slider-bullet-border-active: none;
$slider-bullet-size-active: 12px;
$bullets-outside-size: $slider-bullet-size + $slider-bullet-margin-top + $slider-bullet-margin-bottom;

//pagination bullets - short lines
$slider-short-lines-bullet-background-color: $brand-02;
$slider-short-lines-bullet-active-background-color: $brand-01;
$slider-short-lines-bullet-size-width: 20px;
$slider-short-lines-bullet-size-height: 2px;
$slider-short-lines-bullet-active-size-height: 4px;

// pagination bullets - pause/play button
$slider-bullet-controller-size: 25px;
$slider-bullet-pause-icon-color: $brand-01;
$slider-bullet-play-icon-color: $grey-20;
$slider-bullet-controller-inset-amount: 0;
$slider-bullet-controller-padding: 10px;
$slider-bullet-with-pagination-padding-right: 5px;

// thumbnail nav
$slider-thumbnail-prev-icon: $slider-button-prev-icon;
$slider-thumbnail-next-icon: $slider-button-next-icon;
$slider-thumbnail-button-color: $black;
$slider-thumbnail-button-color-hover: $black;
$slider-thumbnail-button-background-color: $page-background-color;
$slider-thumbnail-button-background-color-hover: $page-background-color;
$slider-thumbnail-button-size: 11px;
$slider-thumbnail-button-icon-size: 12px;
$slider-thumbnail-border-width: 0;
$slider-thumbnail-border-color: transparent;
$slider-thumbnail-border-active-width: 0 0 2px 0;
$slider-thumbnail-border-active-color: $black;
$slider-thumbnail-spacing: 1px;
$slider-thumbnail-width: 63px;
$slider-thumbnail-width-md: 63px;
$slider-thumbnail-width-lg: 63px;
$slider-thumbnail-play-icon-size: 26px;

// light theme
$slider-light-button-color: $black;
$slider-light-button-background: $white;
$slider-light-button-color-hover: $white;
$slider-light-button-background-hover: $yellow-green;
$slider-light-button-border: 1px solid $black;
$slider-light-button-border-hover: 1px solid $yellow-green;
$slider-light-bullet-background: transparent;
$slider-light-bullet-border: 1px solid $white;
$slider-light-bullet-background-active: $white;
$slider-light-bullet-border-active: 1px solid $white;
$slider-light-short-lines-bullet-background-color: rgba($white, 0.5);
$slider-light-short-lines-bullet-active-background-color: $white;
$slider-light-bullet-pause-icon-color: $white;
$slider-light-bullet-play-icon-color: $white;

// flag icons location in relation to target location of the css file.
$flag-icon-css-path: "../fonts/flags/";

/*
* DROPDOWNS
*/
$dropdown-menu-border: 1px solid transparent;
$dropdown-menu-border-radius: 0;
$dropdown-menu-shadow: 0 2px 2px rgba($black, 0.2);
$dropdown-megamenu-border: none;
$dropdown-icon: $icon-arrow-down;
$dropdown-icon-size: 10px;
$dropdown-item-color: $link-color;
$dropdown-item-border-color: transparent;
$dropdown-item-hover-background: transparent;
$dropdown-item-hover-color: $link-color-hover;
$dropdown-item-padding: $spacer;
$dropdown-fadein-speed: $transition-speed; // set to zero to disable fade effect
$dropdown-font-size: 14px;

/*
* NAVIGATION LINKS // generic navigation links styling (header, footer, etc)
*/
$nav-link-font-family: $secondary-font;
$nav-link-font-size: 22px;
$nav-link-font-size-lg: 16px;
$nav-link-font-weight: 500;
$nav-link-line-height: 20px;
$nav-link-letter-spacing: 0;
$nav-link-text-transform: none;
$nav-link-color: $brand-01;
$nav-link-text-decoration: none;
$nav-link-text-decoration-hover: none;
$nav-sub-link-font-size: 14px;

/*
* HEADER
*/
// Promo bar
$header-promo-bar-background: $white;
$header-promo-bar-color: $brand-02;
$header-promo-bar-padding-top: 12px;
$header-promo-bar-padding-bottom: 12px;
$header-promo-bar-font-size: 11px;
$header-promo-bar-link-font-size: 11px;
$header-promo-bar-line-height: 22px;

// Main header
$header-background: $white;
$header-fixed-shadow: $dropdown-menu-shadow;
$header-height: 42px;
$header-height-md: 60px;
$header-height-lg: 83px;
$header-height-new: 67px;
$header-height-new-md: $header-height-new;
$header-height-new-lg: $header-height-new;
$header-logo-image: '../images/logo.svg';
$header-logo-stacked-image: '../images/logo-stacked.svg';
$header-logo-horizontal-image: '../images/logo-horizontal.svg';
$header-logo-vertical-image: '../images/logo-vertical.svg';
$header-logo-small-image: '../images/logo-small.svg';
$header-logo-type-image: '../images/logo-type.svg';
$header-close-image: '../images/close-icon.svg';
$header-logo-image-fixed: $header-logo-image;
$header-logo-image-md: $header-logo-image;
$header-logo-image-md-fixed: $header-logo-image;
$header-logo-image-lg: $header-logo-image;
$header-logo-image-lg-fixed: $header-logo-image;
$header-logo-height: 100%;
$header-logo-width: 21px;
$header-logo-width-md: 25px;
$header-logo-width-lg: 25px;
$header-logo-width-new: 34px;
$header-logo-width-md-new: $header-logo-width-new;
$header-logo-width-lg-new: $header-logo-width-new;
$header-logo-transparent-nav-brightness: 0;
$header-logo-transparent-nav-invert: 1;
$header-transition-speed: 400ms;
$header-link-color: $nav-link-color;
$header-link-color-hover: $black;
$header-link-highlight-color: $red;
$header-link-highlight-color-hover: $grey-40;
$header-link-border-color: transparent;
$header-link-font: $nav-link-font-family;
$header-link-font-size: 16px;
$header-link-top-level-font-size: $header-link-font-size;
$header-link-font-weight: $nav-link-font-weight;
$header-link-line-height: $nav-link-line-height;
$header-link-letter-spacing: 0;
$header-link-underline-position-top: 100%;
$header-link-underline-color: $brand-01; // hover state for left-align logo desktop view only, set to transparent to disable
$header-link-underline-height: 1px;
$header-link-text-transform: $nav-link-text-transform;
$header-link-font-size-logo-center: 14px;
$header-search-desktop-width: 164px;
$header-search-desktop-width-focus: 164px;
$header-search-desktop-expanded-border-color: transparent;
$header-search-desktop-border-width: 0 1px 0 1px;
$header-search-icon-font-size: 22px;
$header-search-icon-color: $header-link-color;
$header-search-icon-color-hover: $grey-40;
$header-search-text-font-size: 16px; //minimum of 16px required to prevent zooming on iphones
$header-minicart-count-background: transparent;
$header-minicart-count-color: $black;
$header-link-dropdown-text-align: center; // used for account + support
$header-link-dropdown-menu-border: none;
$header-link-dropdown-menu-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
$header-link-dropdown-menu-padding: 16px 0;
$header-link-dropdown-menu-font-size: 11px;
$header-link-dropdown-menu-li-padding: 8px;

$header-transparent-background: transparent;
$header-transparent-border-bottom: transparent;
$header-transparent-link-color: transparent;
$header-transparent-link-color-hover: transparent;
$header-transparent-search-desktop-expanded-border-color: transparent;

$header-transparent-text-color-light: $white;
$header-transparent-text-color-dark: $black;
$header-transparent-minicart-count-background-color-light: $black;
$header-transparent-minicart-count-background-color-dark: $red;

// Checkout header
$header-checkout-locale-display: block;
$header-checkout-account-display: block;
$header-checkout-support-display: block;
$header-checkout-minicart-display: block;
// Main navbar
$header-navbar-border-bottom-width: 1px;
$header-navbar-border-bottom-color: $grey-10;
$header-navbar-link-spacing: 36px; // space between top-level menu items
$header-navbar-dropdown-icon: none; // desktop only - mobile falls back to standard dropdown icon ($dropdown-icon)
$header-navbar-dropdown-icon-size: 10px;
$header-navbar-account-icon: $icon-account;
$header-navbar-account-icon-size: 22px;
$header-navbar-account-font-size: 0; // set to 0 if you only want an icon to show for account desktop only
$header-navbar-account-margin: 8px 0 0;
$header-navbar-background: $grey-40; // center-logo desktop layout only
$header-navbar-color: $white; // center-logo desktop layout only
$header-navbar-background-hover: $white; // center-logo desktop layout only
$header-navbar-color-hover: $grey-40; // center-logo desktop layout only
$header-navbar-toggler-icon-size: 18px; // hamburger menu icon size, mobile only
$header-navbar-highlight-color: $brand-01; // center-logo desktop layout only
$header-navbar-highlight-background-hover: $white; // center-logo desktop layout only
$header-navbar-highlight-color-hover: $grey-40; // center-logo desktop layout only
// Megamenu
$megamenu-background: $white;
$megamenu-box-shadow: none;
$megamenu-padding-top-bottom: 30px;
$megamenu-padding: $megamenu-padding-top-bottom $spacer;
$megamenu-header-font: $header-link-font;
$megamenu-header-font-size: 16px;
$megamenu-header-font-weight: 500;
$megamenu-header-line-height: $nav-link-line-height;
$megamenu-header-text-transform: none;
$megamenu-header-color: $brand-01;
$megamenu-header-color-hover: $brand-01;
$megamenu-title-margin-bottom: 30px;
$megamenu-title-text-decoration: none;
$megamenu-secondary-color: $grey-40;
$megamenu-secondary-color-hover: $brand-02;
$megamenu-secondary-font: $body-font;
$megamenu-secondary-font-size: 14px;
$megamenu-secondary-font-weight: 500;
$megamenu-secondary-text-transform: none;
$megamenu-secondary-indentation: 2px;
$megamenu-link-padding-top: 10px;
$megamenu-link-padding-bottom: $megamenu-link-padding-top;
$megamenu-link-padding-left: $spacer-sm;
$megamenu-link-padding-right: $megamenu-link-padding-left;
$megamenu-asset-link-list-font-family: $megamenu-header-font;
$megamenu-asset-link-list-font-size: 18px;
$megamenu-asset-link-list-text-transform: none;
$megamenu-asset-link-list-padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom $megamenu-link-padding-left;
$megamenu-asset-link-list-text-decoration: none;
//Megamenu Left nav — Styles for mega-left megamenu only
$megamenu-left-nav-width: 15%;
$megamenu-left-nav-border-width: 1px;
$megamenu-left-nav-border-color: $grey-10;
$megamenu-left-nav-border-color-hover: $grey-40;
$megamenu-left-nav-font-size: $megamenu-asset-link-list-font-size;
$megamenu-left-nav-text-transform: uppercase;
$megamenu-left-nav-tertiary-title-font-size: $megamenu-header-font-size;
$megamenu-left-nav-tertiary-title-font-weight: $megamenu-header-font-weight;
$megamenu-left-nav-tertiary-title-text-transform: uppercase;
// Search suggestions
$header-suggestions-results-width: 564px;
$header-suggestions-image-size: 30px;
$header-suggestions-header-font: $header-font;
$header-suggestions-header-font-size: 15px;
$header-suggestions-header-font-weight: 500;
$header-suggestions-header-color: $black;
$header-suggestions-header-text-transform: none;
$header-suggestions-border: 1px solid $grey-20;
$header-suggestions-item-font: $body-font;
$header-suggestions-item-font-size: $default-text-font-size;
$header-suggestions-item-category-parent-color: $grey-30;

/*
* FOOTER
*/
$footer-background: $black;
$footer-border-top-color: transparent;
$footer-border-top-width: 0;
$footer-title-font-color: $white;
$footer-title-font-family: $primary-font;
$footer-title-text-transform: $nav-link-text-transform;
$footer-link-color: $white;
$footer-link-height: 100%;
$footer-padding-bottom: $spacer * 2;
$footer-padding-top: $spacer * 2;
$footer-margin-top: 100px;
$footer-title-font-size: 16px;
$footer-title-line-height: 22px;
$footer-title-font-weight: bold;
$footer-link-font-family: $primary-font;
$footer-link-font-size: 17px;
$footer-link-line-height: 2em;
$footer-link-font-weight: 500;
$footer-link-letter-spacing: 0;
$footer-link-text-transform: none;
$footer-link-font-style: normal;
$footer-copyright-font-size: $spacer;
$footer-hr-margin: 72px 0 10px 0;
$footer-hr-color: transparent;
$footer-copyright-color: $grey-20;
$footer-backtotop-color: $white;
$footer-icon-size: 14px;
$footer-social-links-margin: 10px 0;
$footer-signup-title-padding-bottom: $spacer * 2;
$footer-signup-font-size: 11px;
$footer-signup-line-height: 18px;
$footer-signup-letter-spacing: 0;

/*
* PAGE-LEVEL SETTINGS
*/
$page-header-padding-top: $spacer;
$page-header-padding-bottom: $spacer;
$page-header-padding-top-md: 44px;
$page-header-padding-bottom-md: 44px;


/*
* PRODUCT
*/
// Price
$product-strike-through-price-color: $grey-30;

// Ratings
$product-rating-star-icon-font: $icomoon-font-family;
$product-rating-star-color: $black;

// Color swatches - Default type (patterns and colors)
$color-swatch-border-radius: 50%;
$color-swatch-hover-shadow-size: 3px;
$color-swatch-hover-shadow-color: $grey-20;
$color-swatch-hover-shadow: 0 0 0 calc(#{$color-swatch-hover-shadow-size} - 1px) $page-background-color, 0 0 0 $color-swatch-hover-shadow-size $color-swatch-hover-shadow-color;
$color-swatch-selected-shadow-color: $black;
$color-swatch-selected-shadow-size: 3px;
$color-swatch-selected-shadow: 0 0 0 calc(#{$color-swatch-selected-shadow-size} - 1px) $page-background-color, 0 0 0 $color-swatch-selected-shadow-size $color-swatch-selected-shadow-color;
$color-swatch-selected-icon-display: none; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-selected-icon: $icon-check;
$color-swatch-selected-icon-size: 14px;
$color-swatch-selected-icon-color: $white;
$color-swatch-selected-icon-background: rgba($black, 0.7);

// Color swatches - Thumbnail type (product image)
$color-swatch-thumbnail-border-radius: 0;
$color-swatch-thumbnail-hover-shadow-color: $black;
$color-swatch-thumbnail-hover-shadow-size: 2px;
$color-swatch-thumbnail-hover-shadow: inset 0px -#{$color-swatch-thumbnail-hover-shadow-size} 0px $color-swatch-thumbnail-hover-shadow-color;
$color-swatch-thumbnail-selected-shadow-color: $black;
$color-swatch-thumbnail-selected-shadow-size: 2px;
$color-swatch-thumbnail-selected-shadow: inset 0px -#{$color-swatch-thumbnail-selected-shadow-size} 0px $color-swatch-thumbnail-selected-shadow-color;
$color-swatch-thumbnail-selected-icon-display: none; // use 'block' to show icon when swatch is selected, 'none' to hide
$color-swatch-thumbnail-selected-icon: $icon-check;
$color-swatch-thumbnail-selected-icon-size: 22px;
$color-swatch-thumbnail-selected-icon-color: $white;
$color-swatch-thumbnail-selected-icon-background: rgba($black, 0.7);

// Non-color swatches (size, width, etc.)
$non-color-swatch-background: $white;
$non-color-swatch-color: $black;
$non-color-swatch-border-radius: 0;
$non-color-swatch-border-width: 1px;
$non-color-swatch-border-color: transparent;
$non-color-swatch-padding: 9px;
$non-color-swatch-hover-border-color: transparent;
$non-color-swatch-hover-box-shadow: none;
$non-color-swatch-selected-border-color: transparent;
$non-color-swatch-selected-box-shadow: none;
$non-color-swatch-description-font-size: 12px;
$non-color-swatch-description-font-color: $grey-30;
$non-color-swatch-selected-background: $white;
$non-color-swatch-selected-color: $black;
$non-color-swatch-selected-icon-display: none;
$non-color-swatch-selected-icon: $icon-check;
$non-color-swatch-selected-icon-size: 16px;
$non-color-swatch-selected-icon-background-color: $brand-01;
$non-color-swatch-selected-icon-color: $white;

// PDP
$pdp-name-header-value: 3;
$pdp-name-header-color: $black;
$pdp-price-font-size: 15px;
$pdp-price-color: $black;
$pdp-price-font: $primary-font;
// $pdp-add-to-cart-button-icon: $icon-content-blank;
$pdp-swatch-size: 66px;
$pdp-product-number-font-size: 13px;
$pdp-product-number-color: $grey-30;
$pdp-rating-star-size: 14px;
$pdp-rating-star-size-large: 20px;
$pdp-rating-star-size-large-md: $pdp-rating-star-size-large;
$pdp-reviews-link-display: none;
$pdp-reviews-link-font-size: 13px;
$pdp-reviews-link-color: $grey-20;
$pdp-reviews-link-color-hover: $grey-30;
$pdp-right-column-spacing-md: $spacer; // desktop only (additional space between images and data
$pdp-right-column-spacing-lg: 40px; // ""
$pdp-right-column-spacing-xl: 80px; // ""
$pdp-attribute-vertical-spacing: 24px;
$pdp-attribute-vertical-spacing-md: 24px;
$pdp-attribute-vertical-spacing-lg: 44px;
$pdp-color-swatch-size: 18px;
$pdp-color-swatch-spacing: 20px;
$pdp-color-swatch-thumbnail-size: 44px;
$pdp-color-swatch-thumbnail-spacing: 1px;
$pdp-non-color-swatch-min-width: 44px;
$pdp-non-color-swatch-spacing: 8px;
// $pdp-add-to-cart-button-icon: $icon-content-blank;
$pdp-promo-color: $base-font-color;
$pdp-social-icon-color: $link-color;
$pdp-social-icon-color-hover: $link-color-hover;
$pdp-social-icon-size: 30px;
$pdp-social-icon-spacing: 30px;
$pdp-description-detail-header-value: 6;
$pdp-description-detail-divider: 1px solid $border-color; // only applies when these are collapsible
$pdp-description-detail-vertical-spacing: $spacer;
$pdp-description-detail-vertical-spacing-md: 30px;
$pdp-description-detail-vertical-spacing-lg: 60px;
$pdp-tabs-vertical-spacing: $spacer;
$pdp-tabs-vertical-spacing-md: 30px;
$pdp-tabs-vertical-spacing-lg: 60px;
$pdp-asset-max-width: 700px;
$pdp-tabs-header-value: 6;

// PDP STICKY ADD TO CART
$pdp-sticky-addtocart-background: $white;
$pdp-sticky-addtocart-opacity: 1;
$pdp-sticky-addtocart-open-transition: transform $transition-speed linear;
$pdp-sticky-addtocart-shadow: $dropdown-menu-shadow;
$pdp-sticky-addtocart-name-header-value: 4;
$pdp-sticky-addtocart-color-swatch-size: $pdp-color-swatch-size;
$pdp-sticky-addtocart-color-swatch-spacing: 10px;
$pdp-sticky-addtocart-color-swatch-thumbnail-size: $input-sm-height;
$pdp-sticky-addtocart-color-swatch-thumbnail-spacing: $pdp-color-swatch-thumbnail-spacing;
$pdp-sticky-addtocart-attribute-spacing: $spacer;
$pdp-sticky-addtocart-attribute-select-font-size: $input-sm-font-size;
$pdp-sticky-addtocart-attribute-select-line-height: $input-sm-font-line-height;
$pdp-sticky-addtocart-attribute-select-padding: $input-sm-padding-top $select-sm-padding-right $input-sm-padding-bottom $input-sm-padding-left;
$pdp-sticky-addtocart-attribute-select-height: $input-sm-height;
$pdp-sticky-addtocart-button-font-size: $button-sm-font-size;
$pdp-sticky-addtocart-button-line-height: $button-sm-line-height;
$pdp-sticky-addtocart-button-padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
$pdp-sticky-addtocart-button-letter-spacing: $button-sm-letter-spacing;

// QUICKVIEW
$quickview-selected-bonus-product-font-size: 10px;
$quickview-selected-bonus-product-font-size-sm: 16px;

// PRODUCT CARDS (order detail, checkout summary, order confirmation)
$product-card-image-size-sm: 100px;
$product-card-image-size-lg: 176px; // used on cart page large screens
$product-card-product-name-font-family: $primary-font;
$product-card-product-name-font-size: normal; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$product-card-product-name-text-transform: none;
$product-card-product-name-font-weight: normal;
$product-card-product-name-text-decoration: none;
$product-card-attribute-size: $default-text-font-size;
$product-card-attribute-color: $grey-30;
$product-card-border-color: $card-body-border-color;
$product-card-item-border-width: 1px;
$product-card-price-qty-row-background: $white;
$product-card-price-qty-row-border-color: $card-body-border-color;
$product-card-remove-icon: $icon-close;
$product-card-remove-icon-size: 11px;
$product-card-remove-icon-background: $white;
$product-card-promo-color: $green;
$product-card-promo-font-size: $default-text-font-size;

// PLP - PRODUCT GRID
$plp-color-swatch-size: 2.571em; //36px based on a 14px body font-size
$plp-color-swatch-size-md: 1.929em; //27px based on a 14px body font-size
$plp-color-swatch-size-sm: 1.714em; //24px based on a 14px body font-size

$plp-name-header-display-with-slot: none;
$product-refinement-font-size: $default-text-font-size;
$product-refinement-swatch-size: 20px;
$product-refinement-swatch-img-size: 30px;
$product-refinement-swatch-border: 1px solid rgba($black, 0.3);
$product-refinement-swatch-spacing: 12px;
$product-refinement-non-color-swatch-min-width: 44px;
$product-refinement-non-color-swatch-spacing: 0;
$product-refinements-drawer-width: calc(100% - 50px);
$product-refinements-drawer-max-width: $header-suggestions-results-width; // mobile only
$product-refinements-drawer-padding-bottom: 200px;
$product-refinement-secondary-link-text-decoration: none;
$product-refinement-secondary-link-padding: 8px 0;
$product-refinement-secondary-link-line-height: $default-text-line-height;
$product-refinement-header-padding: 20px;
$product-refinement-header-background-color: $modal-header-background-color;
$product-refinement-header-border-color: $modal-header-border-color;
$product-refinement-header-color: $white;
$product-refinement-header-title-size: 4;
$product-refinement-value-padding-bottom: 12px;
$product-refinement-value-grid-padding-bottom: 10px;
$product-filter-font-size: 13px;
$product-filter-font-weight: normal;
$product-filter-color: $black;
$product-filter-border: 1px solid $grey-20;
$product-filter-border-radius: $border-radius;
$product-filter-padding-top: 8px;
$product-filter-padding-right: 25px;
$product-filter-padding-bottom: 7px;
$product-filter-padding-left: 10px;
$product-filter-background: $white;
$product-filter-icon-size: 10px;
$product-filter-spacing: $spacer-sm;
$product-filter-swatch-size: $product-filter-font-size + $product-filter-padding-top + $product-filter-padding-bottom + 2px;
$product-miscellaneous-swatch-background: linear-gradient(0deg, rgba(130, 30, 145, 1) 0, rgba(130, 30, 145, 1) 25%, rgba(237, 209, 52, 1) 25%, rgba(255, 255, 0, 1) 50%, rgba(237, 209, 52, 1) 50%, #59ba00 50%, #59ba00 76%, #111 76%, #111 100%), linear-gradient(0deg, rgba(14, 92, 209, 1) 0, rgba(14, 92, 209, 1) 50%, rgba(226, 11, 11, 1) 50%, rgba(226, 11, 11, 1) 100%);

$product-refinement-horizontal-padding-top: $spacer;
$product-refinement-horizontal-padding-bottom: $spacer;
$product-refinement-horizontal-dropdown-menu-padding: 16px;
$product-refinement-horizontal-dropdown-menu-max-width: 300px;

$product-refinement-horizontal-onedropdown-border-top:none;
$product-refinement-horizontal-onedropdown-border-bottom: none;
$product-refinement-horizontal-onedropdown-border-right: none;
$product-refinement-horizontal-onedropdown-border-left: none;
$product-refinement-horizontal-onedropdown-margin-top: 15px;
$product-refinement-horizontal-onedropdown-max-width: map-get($grid-breakpoints, xl) - map-get($grid-gutter-widths, xl);
$product-refinement-horizontal-onedropdown-refinement-padding-top: 15px;
$product-refinement-horizontal-onedropdown-refinement-padding-bottom: 15px;
$product-refinement-horizontal-onedropdown-btn-padding-top: 6px;
$product-refinement-horizontal-onedropdown-btn-padding-bottom: 6px;
$product-refinement-horizontal-onedropdown-btn-padding-right: 10px;
$product-refinement-horizontal-onedropdown-btn-padding-left: 12px;
$product-refinement-horizontal-onedropdown-btn-border-top: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-bottom: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-right: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-border-left: 1px solid $border-color;
$product-refinement-horizontal-onedropdown-btn-background-color: transparent;
$product-refinement-horizontal-onedropdown-btn-width: 150px;
$product-refinement-horizontal-onedropdown-sortby-btn-width: 150px;
$product-refinement-horizontal-onedropdown-filterby-btn-width: 12px;
$product-refinement-horizontal-onedropdown-filterby-btn-height: 12px;
$product-refinement-horizontal-onedropdown-sticky-bar-left: 0;
$product-refinement-horizontal-onedropdown-sticky-bar-zindex: $z-index-header;
$product-refinement-horizontal-onedropdown-sticky-bar-padding: $spacer;
$product-refinement-horizontal-onedropdown-sticky-bar-background-color: $white;
$product-refinement-horizontal-onedropdown-sticky-bar-border-bottom: 1px solid $border-color;

$product-refinement-horizontal-multipledropdowns-btn-border-top: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-bottom: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-left: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-border-right: 1px solid $border-color;
$product-refinement-horizontal-multipledropdowns-btn-padding-top: 6px;
$product-refinement-horizontal-multipledropdowns-btn-padding-bottom: 6px;
$product-refinement-horizontal-multipledropdowns-btn-padding-left: 12px;
$product-refinement-horizontal-multipledropdowns-btn-padding-right: 10px;
$product-refinement-horizontal-multipledropdowns-height: calc(16px + 12px + 14px + (1px*2));
$product-refinement-horizontal-multipledropdowns-margin-right: 10px;
$product-refinement-horizontal-multipledropdowns-margin-bottom: 0;
$product-refinement-horizontal-multipledropdowns-min-width: 150px;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-min-width: 300px;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-margin-top: 0;
$product-refinement-horizontal-multipledropdowns-dropdown-menu-btn-padding-bottom: 20px;

// PRODUCT TILES (product grid, recommended sliders)
$product-tile-max-width: 288px;
$product-tile-grid-margin-bottom: 45px;
$product-tile-image-hover-shadow-color: $black;
$product-tile-image-hover-shadow-size: 2px;
$product-tile-image-hover-shadow: inset 0px -#{$product-tile-image-hover-shadow-size} 0px $product-tile-image-hover-shadow-color;
$product-tile-image-margin-bottom: 9px;
$product-tile-swatch-size: 18px;
$product-tile-swatch-spacing: 8px;
$product-tile-swatch-thumbnail-size: 30px;
$product-tile-swatch-thumbnail-spacing: 1px;
$product-tile-swatch-margin-bottom: 10px;
$product-tile-swatch-color-count-color: $grey-40;
$product-tile-title-font: $secondary-font;
$product-tile-title-font-size: 12px;
$product-tile-title-font-weight: 500;
$product-tile-title-text-decoration: none;
$product-tile-title-text-decoration-hover: none;
$product-tile-title-color: $base-font-color;
$product-tile-title-color-hover: $base-font-color;
$product-tile-title-margin-bottom: 4px;
$product-tile-price-font-size: 11px;
$product-tile-price-color: $base-font-color;
$product-tile-price-strike-through-color: $product-strike-through-price-color;
$product-tile-price-margin-bottom: 10px;
$product-tile-sale-color: $red;
$product-tile-rating-margin-bottom: 20px;
$product-tile-rating-star-color: $product-rating-star-color;
$product-tile-rating-star-size: 11px;
$product-tile-reviews-link-display: none;
$product-tile-reviews-link-font-size: 11px;
$product-tile-reviews-link-color: $grey-20;
$product-tile-reviews-link-color-hover: $grey-30;
$product-tile-quickview-link-icon: $icon-plus;
$product-tile-quickview-link-size: 30px; // Quickview link appears on desktop only
$product-tile-quickview-link-icon-size: 14px;
$product-tile-quickview-link-color: rgba($black, 0.7);
$product-tile-quickview-link-background-color: rgba($white, 0.7);
$product-tile-quickview-link-box-shadow: none;
// $product-tile-wishlist-link-icon: $icon-favorite;
// $product-tile-wishlist-link-icon-selected: $icon-favorite-selected;
$product-tile-wishlist-link-size: 30px;
$product-tile-wishlist-link-icon-size: 14px;
$product-tile-wishlist-link-color: rgba($black, 0.7);
$product-tile-wishlist-link-background-color: rgba($white, 0.7);
$product-tile-wishlist-link-box-shadow: none;
$product-tile-wishlist-link-border-radius: 50%;

// PRODUCT TILES (badges)
$product-tile-badges-margin: 1px;
$product-tile-badge-padding: 2px 3px;
$product-tile-badge-padding-lg: 5px 6px;
$product-tile-badge-margin-bottom: 1px;
$product-tile-badge-font-weight: bold;
$product-tile-badge-font-size: 10px;
$product-tile-badge-font-size-lg: $default-text-font-size;
$product-tile-badge-text-align: left;
$product-tile-badge-line-height: $default-text-line-height;
$product-tile-badge-font-family: $body-font;
$product-tile-badge-background: $white;
$product-tile-badge-color: $base-font-color;
$product-tile-badge-border-radius: $border-radius;
$product-tile-badge-border-width: 1px;
$product-tile-badge-border-style: solid;
$product-tile-badge-border-color: transparent;

/*
* MINICART
*/
$minicart-size: 400px;
$minicart-totals-font-family: $primary-font;
$minicart-totals-font-size: small; // use small, normal, or large, corresponding with the paragraph-font-size mixin params
$minicart-totals-font-weight: bold;
$minicart-totals-text-transform: none;


/*
* CART AND CHECKOUT
*/
$cart-card-margin-bottom: -1px;
$cart-number-of-items-font-size: $default-text-font-size;
$cart-product-name-text-overflow: ellipsis;
$cart-product-name-white-space: nowrap;
$cart-product-quantity-label-display: none;
$cart-recommended-products-border-top: 1px solid $border-color;
$cart-recommended-products-margin-top: 130px; // container margin
$cart-recommended-products-padding-top: 95px; // container padding
$checkout-mobile-footer-background: rgba($white, 0.8);
$checkout-mobile-footer-box-shadow: 0 2px 6px rgba($black, 0.6);
$checkout-approaching-discount-color: $green;
$checkout-promo-message-color: $green;
$checkout-totals-font-family: $primary-font;
$checkout-totals-font-size: 16px;
$checkout-totals-font-weight: normal;
$checkout-totals-text-transform: none;
$checkout-spacer: 41px;
$checkout-selected-payment-background: $grey-10;
$checkout-selected-payment-color: $brand-01;
$checkout-selected-payment-border: 1px solid $brand-01;
$checkout-shipping-method-label-font-weight: 500;


/*
* PAGE DESIGNER MODULES
*/
$page-designer-layout-spacing: $spacer*2; // This sets the default spacing between layout containers


/*
* HERO ASSETS
*/
$hero-title-size: 1; // This number should be 1-6, corresponding with h1-h6 headers
$hero-subtitle-font: $header-font;
$hero-subtitle-font-size: $paragraph-font-size-small;
$hero-subtitle-text-transform: none;
$hero-textblock-outer-padding: $spacer;
$hero-textblock-outer-padding-md: $spacer;
$hero-textblock-outer-padding-lg: 50px;
$hero-textblock-inner-padding: $spacer;
$hero-textblock-inner-padding-md: $spacer;
$hero-textblock-inner-padding-lg: $spacer;
$hero-textblock-background-opacity: 0.7;
$hero-textblock-border-width: 1px;
$hero-textblock-items-vertical-spacing: $spacer;
$hero-min-height-short: 150px;
$hero-min-height-short-md: 80px;
$hero-min-height-short-lg: 80px;
$hero-min-height-medium: 250px;
$hero-min-height-medium-md: 300px;
$hero-min-height-medium-lg: 350px;
$hero-min-height-tall: 600px;
$hero-min-height-tall-md: 650px;
$hero-min-height-tall-lg: 700px;
$hero-colortheme-dark: $black;
$hero-colortheme-light: $white;
$hero-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$hero-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;
$hero-animation-delay: 0ms; // This value should match the ComponentAnimationDelay value in scripts/constants/SiteConstants.js


/*
* CATEGORY TILE ASSETS
*/
$category-tile-border-radius: $border-radius;
$category-tile-primary-font-size: $paragraph-font-size-large;
$category-tile-secondary-font-size: $paragraph-font-size-large;
$category-tile-text-shadow: 0 0 2px rgba($black, 0.6); // When text is in image area only
$category-tile-text-background: rgba($black, 0.3); // When text is in image area only
$category-tile-text-inside-color: $white; // When text is in image area only
$category-tile-hover-zoom-amount: 1.2; // Only applicable if zoom is enabled
$category-tile-aspect-ratio-square: $aspect-ratio-square;
$category-tile-aspect-ratio-landscape: $aspect-ratio-landscape;
$category-tile-aspect-ratio-portrait: $aspect-ratio-portrait;
$category-tile-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$category-tile-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;


/*
* IMAGE TEXT BLOCK ASSETS
*/
$image-text-block-border-radius: $category-tile-border-radius;
$image-text-block-subtitle-font-size: $paragraph-font-size-small;
$image-text-block-primary-font-size: $category-tile-primary-font-size;
$image-text-block-secondary-font-size: $category-tile-secondary-font-size;
$image-text-block-text-shadow: $category-tile-text-shadow; // When text is in image area only
$image-text-block-text-background: $category-tile-text-background; // When text is in image area only
$image-text-block-text-inside-color: $category-tile-text-inside-color; // When text is in image area only
$image-text-block-hover-zoom-amount: $category-tile-hover-zoom-amount; // Only applicable if zoom is enabled
$image-text-block-aspect-ratio-square: $category-tile-aspect-ratio-square;
$image-text-block-aspect-ratio-landscape: $category-tile-aspect-ratio-landscape;
$image-text-block-aspect-ratio-portrait: $category-tile-aspect-ratio-portrait;
$image-text-block-video-aspect-ratio: $aspect-ratio-sixteen-nine;
$image-text-block-video-horizontal-aspect-ratio: $aspect-ratio-sixteen-nine-horizontal;


/*
* PHOTO HOTSPOTS ASSETS
*/
$photo-hotspot-size: 20px;
$photo-hotspot-background: rgba($white, 0.6);
$photo-hotspot-background-dark: rgba($black, 0.3);
$photo-hotspot-border-color-inner: transparent;
$photo-hotspot-border-color-inner-dark: transparent;
$photo-hotspot-border-size-inner: 3px;
$photo-hotspot-border-color-outer: $white;
$photo-hotspot-border-color-outer-dark: $black;
$photo-hotspot-border-size-outer: 3px;
$photo-hotspot-border-radius: 50%;
$photo-hotspot-icon: $icon-plus;
$photo-hotspot-icon-color: $white;
$photo-hotspot-icon-color-dark: $black;
$photo-hotspot-icon-size: 10px;
$photo-hotspot-shadow: none;
$photo-hotspot-size-hover: 40px;
$photo-hotspot-background-hover: rgba($white, 0.8);
$photo-hotspot-background-hover-dark: rgba($black, 0.5);
$photo-hotspot-border-color-inner-hover: transparent;
$photo-hotspot-border-color-inner-hover-dark: transparent;
$photo-hotspot-border-size-inner-hover: 3px;
$photo-hotspot-border-color-outer-hover: $white;
$photo-hotspot-border-color-outer-hover-dark: $black;
$photo-hotspot-border-size-outer-hover: 3px;
$photo-hotspot-hover-icon: $icon-plus;
$photo-hotspot-tooltip-max-width: 300px;
$photo-hotspot-text-spacing: 8px;
$photo-hotspot-text-product-divider: 1px solid $border-color;
$photo-hotspot-attribute-spacing: 8px;
$photo-hotspot-tooltip-image-size: 75px;
$photo-hotspot-tooltip-product-name-font-size: $default-text-font-size;
$photo-hotspot-tooltip-product-name-color: $black;
$photo-hotspot-tooltip-product-name-text-overflow: ellipsis;
$photo-hotspot-tooltip-product-name-whitespace: nowrap;
$photo-hotspot-tooltip-price-font-size: $default-text-font-size;
$photo-hotspot-tooltip-price-color: $grey-30;
$photo-hotspot-tooltip-message-display: block;
$photo-hotspot-tooltip-message-font-size: 10px;
$photo-hotspot-tooltip-message-text-transform: uppercase;
$photo-hotspot-tooltip-message-color: $grey-30;
$photo-hotspot-tooltip-sale-price-color: $red;


/*
* VIDEO ASSETS
*/
$video-play-icon: $icon-play;
$video-play-icon-color: $white;
$video-play-icon-size: 50px;
// $video-pause-icon: $icon-pause;
$video-pause-icon-color: $white;
$video-pause-icon-size: 50px;
$video-modal-size: 'xl'; // sm, lg, or xl

/*
* BLOG LANDING
*/
$blog-nav-background-color: $grey-10;
$blog-nav-border-color: $border-color;
$blog-nav-item-text-decoration: none;
$blog-nav-item-active-font-weight: bold;

/*
* BLOG DETAIL
*/
$blog-detail-category-color: $grey-30;
$blog-detail-date-color: $grey-30;
$blog-detail-category-date-separator-color: $grey-30;
$blog-detail-category-date-separator: '|';
$blog-detail-category-date-separator-spacer-left: 10px;
$blog-detail-category-date-separator-spacer-right: 10px;
$blog-detail-title-size: 2; // This number should be 1-6, corresponding with h1-h6 headers
$blog-detail-title-margin-top: 10px;
$blog-detail-author-color: $grey-30;
$blog-detail-author-margin-top: 10px;
$blog-detail-social-icons-border-top: none;
$blog-detail-social-icons-margin-top: 80px;
$blog-detail-social-icons-padding-top: 0;
$blog-detail-social-icon-color: $link-color;
$blog-detail-social-icon-color-hover: $link-color-hover;
$blog-detail-social-icon-size: 30px;
$blog-detail-social-icon-spacing: 30px;
$blog-detail-footer-text-align: center;
$blog-detail-search-words-border-top: 1px solid $border-color;
$blog-detail-search-words-margin-top: 20px;
$blog-detail-search-words-padding-top: 20px;
$blog-detail-search-words-separator: ',';
$blog-detail-search-words-spacer-left: 0;
$blog-detail-search-words-spacer-right: 7px;

/*
* CONTENT TILES
*/
$content-tile-image-aspect-ratio: $aspect-ratio-square;
$content-tile-image-zoom-amount: 1.2; // set to 1 to turn zooming off
$content-tile-grid-margin-bottom: $product-tile-grid-margin-bottom;
$content-tile-image-hover-shadow-color: $product-tile-image-hover-shadow-color;
$content-tile-image-hover-shadow-size: $product-tile-image-hover-shadow-size;
$content-tile-image-hover-shadow: $product-tile-image-hover-shadow;
$content-tile-image-margin-bottom: $product-tile-image-margin-bottom;
$content-tile-category-font: $default-text-font;
$content-tile-category-font-size: $paragraph-font-size-small;
$content-tile-category-font-weight: $product-tile-title-font-weight;
$content-tile-category-text-decoration: $product-tile-title-text-decoration;
$content-tile-category-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-category-color: $grey-30;
$content-tile-category-color-hover: $grey-30;
$content-tile-category-margin-bottom: 7px;
$content-tile-name-font: $header-font;
$content-tile-name-font-size: 18px;
$content-tile-name-font-weight: $product-tile-title-font-weight;
$content-tile-name-text-decoration: $product-tile-title-text-decoration;
$content-tile-name-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-name-color: $product-tile-title-color;
$content-tile-name-color-hover: $product-tile-title-color-hover;
$content-tile-name-margin-bottom: 10px;
$content-tile-date-font: $header-font;
$content-tile-date-font-size: $paragraph-font-size-small;
$content-tile-date-font-weight: $product-tile-title-font-weight;
$content-tile-date-text-decoration: $product-tile-title-text-decoration;
$content-tile-date-text-decoration-hover: $product-tile-title-text-decoration-hover;
$content-tile-date-text-transform: uppercase;
$content-tile-date-color: $product-tile-title-color;
$content-tile-date-color-hover: $product-tile-title-color-hover;
$content-tile-date-margin-bottom: 7px;
$content-tile-description-font: $default-text-font;
$content-tile-description-font-size: $default-text-font-size;
$content-tile-description-font-weight: $default-text-font-weight;
$content-tile-description-text-decoration: none;
$content-tile-description-text-decoration-hover: none;
$content-tile-description-color: $base-font-color;
$content-tile-description-color-hover: $base-font-color;


/*
* STORE LOCATOR
*/
$store-map-canvas-height-sm: 250px;
$store-map-canvas-height: 450px;
$store-map-canvas-marker-font-family: $primary-font;
$store-map-canvas-marker-title-font-size: 18px;
$store-map-canvas-marker-font-size: $popover-font-size;
$store-map-canvas-marker-line-height: 1.5;
$store-map-marker-size: 32px;
$store-results-container-max-height: 350px;
$store-type-map-marker-default-label-color: $white;


/*
* STORE DETAIL PAGE
*/
$store-detail-name-header-value: 3;
$store-detail-name-header-color: $black;
$store-detail-attribute-vertical-spacing: 8px;
$store-detail-attribute-label-font-weight: bold;
$store-detail-additional-margin-top: 30px;
$store-detail-image-margin-top: 30px;
$store-detail-map-link-margin-top: $spacer;

/*
* ACCOUNT
*/
$account-login-tabs-margin-bottom: 20px;
$account-wishlist-social-link-size: 30px;
$account-wishlist-social-link-icon-size: 30px;
$account-wishlist-social-link-color: rgba($black, 0.7);
$account-wishlist-social-link-background-color: rgba($white, 0.7);
$account-wishlist-social-link-box-shadow: none;
$account-wishlist-social-link-border-radius: 50%;

/*
* GIFTCERTIFICATES
*/
$giftcertificate-balancemsg-font-size: 18px;
$giftcertificate-balancemsg-font-weight: bold;

/*
* PAYMENTMETHODS
*/
$paymentmethod-error-font-weight: bold;

/*
* ACCESSIBILITY
*/
$outline-focus-width: 2px;
$outline-focus-style: solid;
$outline-focus-color: $grey-30;

/*
* CONSENT DRAWER
*/
$consent-min-height: 85px;
$consent-border-top-width: 1px;
$consent-border-top-style: solid;
$consent-border-top-color: $brand-02;
$consent-background-color: $grey-10;
$consent-paragraph-margin: 4px;
$consent-paragraph-font-size: 12px;
$consent-paragraph-line-height: 12px;

/*
* PRELOADER
*/
$preloader-size: 150px;
$preloader-veil-opacity: 0.7;