.size-chart {
    font-family: $tertiary-font;

    /****** tabs ******/
    .tabs {
        margin-top: 1px;
        padding-top: 0px;
        border-bottom: 1px solid;
        border-bottom-color: $grey-20;
    }

    .pill-tabs {
        border-bottom-color: $grey-20;
        margin-right: -16px;
        margin-left: -16px;
        width: 100vw;

        @include media-breakpoint-up(lg) {
            margin-right: 0px;
            margin-left: 0px;
            width: 100%;
        }

        .nav-item {
            flex-basis: 0;

            @include media-breakpoint-up(lg) {
                border: 1px solid $grey-20;
            }

            + .nav-item {
                @include media-breakpoint-down(md) {
                    border-left: 1px solid $grey-20;
                }
            }
        }
        .nav-link {
            font-family: $primary-font;
            font-size: 13px;
            line-height: 22px;
            font-weight: 700;

            &.active {
                background-color: $black;
            }
        }
    }

    .underline-tabs {
        @include media-breakpoint-down(md) {
            margin-bottom: 26px;
        }

        .nav-item {
            margin: 0px;

            @include media-breakpoint-down(md) {
                flex: 1 1 0;
            }
        }

        .nav-link {
            font-size: 14px;

            &.active {
                border-bottom: 2px solid $black;
            }
        }

        + .tab-content {
            @include media-breakpoint-down(md) {
                margin-left: -16px;
                margin-right: -16px;
            }
        }
    }

    .nav {
        justify-content: center;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        flex-wrap: nowrap;

        @include media-breakpoint-up(lg) {
            width: 30%;
        }
    }

    .nav-link {
        border-radius: initial;
        text-decoration: none;
    }
    /***** end of tabs ******/

    /***** accordions ******/
    .accordion__item {
        border-bottom: none;
        padding-bottom: 0px;

        @include media-breakpoint-up(lg) {
            border-bottom: 1px solid #d6d6d6;
            padding-bottom: 25px;
        }
    }

    .accordion__item__label {
        @include media-breakpoint-down(md) {
            margin-left: 16px;
            margin-right: 16px;
        }
    }

    .accordion .accordion__item:not(.nested-accordion) .accordion__item__label {
        padding: 18px 0px;

        @include media-breakpoint-up(lg) {
            border: none;
            padding: 50px 0px 24px;
        }
    }

    .collapse {
        @include media-breakpoint-up(lg) {
            margin-bottom: 20px;
        }
    }

    .accordion .accordion {
        @include media-breakpoint-down(md) {
            padding-left: 0px;
            margin-left: 16px;
            background-color: $grey-10;
        }

        &:before {
            @include media-breakpoint-down(md) {
                content: none;
            }
        }

        .accordion__item__content {
            @include media-breakpoint-down(md) {
                background-color: $grey-10;
            }
        }

        .accordion__item:last-child {
            @include media-breakpoint-down(md) {
                margin-left: 12px;
                padding-left: 0px;
            }
        }

        .accordion__item {
            background-color: $white;
            margin-left: 12px;

            @include media-breakpoint-up(lg) {
                margin-left: 24px;
            }
        }

        .accordion__item__label a {
            color: $black;
            font-size: 16px;
            font-weight: 700;

            @include media-breakpoint-up(lg) {
                font-family: $primary-font;
                font-style: italic;
                text-transform: uppercase;
                font-size: 30px;
            }
        }
    }

    .nested-accordion {
        border: none;
        padding-bottom: 0px;
    }

    /***** end of accordions ******/

    h1 {
        margin-top: 30px;
        margin-bottom: 56px;

        @include media-breakpoint-up(lg) {
            text-align: center;
            margin-top: 60px;
            margin-bottom: 60px;
            line-height: 72px;
        }
    }
}

.metric-tabs-container {
    padding-top: 30px;

    @include media-breakpoint-down(md) {
        padding: 30px 15px;
        background-color: $grey-10;
    }

    .tabs {
        border: none;
    }

    .nav {
        border: solid 1px $black;
        border-radius: 31px;
        margin: 0;
        width: 6em;

        @include media-breakpoint-up(lg) {
            width: 9em;
        }
    }

    .metric-navs {
        margin-bottom: 36px;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .nav-item {
        margin: -1px;
        flex-basis: 0;
    }

    .nav-link {
        border-radius: 31px;
        font-size: 10px;
        line-height: 14px;
        padding: 1em;

        @include media-breakpoint-up(lg) {
            font-size: 12px;
        }

        &.active {
            background-color: $black;
        }
    }

    .tab-content {
        overflow-y: auto;
    }

    .tooltip-icon {
        vertical-align: top;
        font-size: 15px;
        top: 0;

        @include media-breakpoint-down(md) {
            display: none;
        }
    }

    table {
        border-collapse: collapse;
        margin: 0;
        padding: 0;
        width: 100%;
        table-layout: fixed;
        min-width: 622px;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 18.75px;
        }
    }

    tr {
        background-color: $grey-10;
    }

    th {
        background-color: $black;
        color: $white;
        font-weight: 700;

        &:not(:first-child) {
            text-transform: uppercase;
        }
    }

    td {
        font-weight: 400;

        @include media-breakpoint-down(md) {
            background-color: $white;
        }
    }

    th,
    td {
        font-size: 13px;
        line-height: 15.23px;
        padding: 1.5em;
        text-align: center;

        @include media-breakpoint-down(md) {
            min-width: 20vw;
            width: 20vw;
            max-width: 20vw;
        }

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 18.75px;
        }

        &:first-child {
            text-transform: capitalize;
        }
    }

    th + th,
    td + td {
        border-left: 3px solid $grey-10;

        @include media-breakpoint-up(lg) {
            border-left: 3px solid $white;
        }
    }
    tr + tr {
        border-top: 3px solid $grey-10;

        @include media-breakpoint-up(lg) {
            border-top: 3px solid $white;
        }
    }
}

.tooltip {
    font-family: $tertiary-font;
    font-weight: 400;
    font-size: 11px;
    line-height: 21px;

    .tooltip-inner {
        text-align: left;
    }
}

/*Size Chart Mobile */
.size-chart-container {
    padding: 48px 0;
    margin-right: -24px;
    margin-left: -24px;
}

.size-chart-container .tab-pane {
    padding-left: 24px;
}
.size-chart-container > h4 {
    padding: 0 24px;
}

@media screen and (min-width: 1024px){
  /*Size Chart Desktop */
  .size-chart-container {
    padding: 48px 0;
    margin-right: 0;
    margin-left: 0;
  }

  .size-chart-container .tab-pane {
    padding-left: 0;
  }
  .size-chart-container > h4 {
    padding: 0;
  }
}
