.product-tile {
    margin-bottom: $product-tile-grid-margin-bottom;

    // Tile Images
    .image-container {
        position: relative;
        margin-bottom: $product-tile-image-margin-bottom;
    }
    .tile-image {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    .product-tile-image-link {
        display: block;
    }
    .quickview:not(.quick-add-button) {
        position: absolute;
        bottom: 10px;
        right: 10px;
        text-decoration: none;
        .quickview-icon {
            width: $product-tile-quickview-link-size;
            height: $product-tile-quickview-link-size;
            line-height: $product-tile-quickview-link-size;
            background-color: $product-tile-quickview-link-background-color;
            box-shadow: $product-tile-quickview-link-box-shadow;
            border-radius: 50%;
            @include icon($product-tile-quickview-link-icon, before, $product-tile-quickview-link-icon-size, $product-tile-quickview-link-color);
            &::before {
                @include absolute-center;
            }
        }
        &.btn {
            width: calc(100% - 20px);
            opacity: 0;
        }
    }

    // Tile Body
    .tile-body {
        position: relative;
        :last-child {
            margin-bottom: 0;
        }
    }
    .color-swatches {
        margin-top: 9px;
        margin-bottom: $product-tile-swatch-margin-bottom;
        font-size: 0;
        .swatches {
            @include clearfix;
        }
        .swatch-link {
            text-decoration: none;
            &:first-child .swatch-circle {
                margin-left: $color-swatch-hover-shadow-size;
            }
        }
        .swatch-circle {
            @include swatch($product-tile-swatch-size, $white);
            margin-right: $product-tile-swatch-spacing;
            margin-bottom: $product-tile-swatch-spacing;
            &.selected {
                box-shadow: $color-swatch-selected-shadow;
            }
        }
    }
    .pdp-link {
        margin-bottom: $product-tile-title-margin-bottom;
        .link {
            display: block;
            font-family: $product-tile-title-font;
            font-size: $product-tile-title-font-size;
            font-weight: $product-tile-title-font-weight;
            color: $product-tile-title-color;
            text-decoration: $product-tile-title-text-decoration;
            &:hover {
                color: $product-tile-title-color-hover;
                text-decoration: $product-tile-title-text-decoration;
            }
        }
    }
    .price {
        margin-bottom: $product-tile-price-margin-bottom;
        font-size: $product-tile-price-font-size;
        color: $product-tile-price-color;
        @include clearfix;
        del,
        .strike-through {
            color: $product-tile-price-strike-through-color;
        }
        del ~ .sales {
            float: left;
            margin-right: $spacer;
            color: $product-tile-sale-color;
        }
    }
    .ratings {
        margin-bottom: $product-tile-rating-margin-bottom;
        font-size: $product-tile-rating-star-size;
        color: $product-tile-rating-star-color;
        flex-basis: 100%;
        max-width: 100%;
        .fa-star {
            @include icon($icon-star-full);
        }
        .fa-star-half-o {
            @include icon($icon-star-half);
        }
        .fa-star-o {
            @include icon($icon-star-empty);
        }
    }
    &.on-hover {
        @include media-breakpoint-up(lg) {
            .color-swatches {
                .swatches {
                    opacity: 0;
                }
            }
            &:hover .color-swatches .swatches {
                opacity: 1;
            }
        }
    }
    &:hover {
        .product-tile-image-container:after {
            content: '';
            @include absolute-cover;
            pointer-events: none;
            box-shadow: $product-tile-image-hover-shadow;
        }
        .quickview.btn {
            opacity: 1;
        }
    }

    // using "visibility:hidden;" so lazysizes.js can also lazy load secondary images
    .show-secondary-image {
        @include media-breakpoint-up(md) {
            &:hover {
                .product-tile-image {
                    visibility: hidden;
                }

                .product-tile-image-secondary {
                    visibility: visible;
                }
            }
        }
        .product-tile-image {
            display: block;
            visibility: visible;
        }

        .product-tile-image-secondary {
            display: block;
            position: absolute;
            visibility: hidden;
        }
    }
}

