// Base variables
@import "~base_foxhead/variables";

// Brand Colors
$brand-01: #0C0A08;
$brand-02: #40403F;
$brand-03: #99FF00;

$secondary-01: #ECEEE4;
$secondary-02: #D8DDC9;
$secondary-03: #B9BFA7;

// Grey Scale
$white: #FFF;
$grey-10: #F8F8F8;
$grey-20: #D6D6D6;
$grey-30: #595959;
$black: #000;

// Other Colors
$green: #00865A;
$yellow: #FC0;
$red: #BF242B;
$blue: #3091E7;

$success: $green;
$warning: $yellow;
$danger: $red;
$info: $blue;

$colors: (
    brand-01: $brand-01,
    brand-02: $brand-02,
    brand-03: $brand-03,
    secondary-01: $secondary-01,
    secondary-02: $secondary-02,
    secondary-03: $secondary-03,
    white: $white,
    grey-10: $grey-10,
    grey-20: $grey-20,
    grey-30: $grey-30,
    grey-40: $grey-40,
    black: $black,
    green: $green,
    yellow: $yellow,
    red: $red,
    blue: $blue,
    success: $success,
    warning: $warning,
    danger: $danger,
    info: $info,
    // Legacy colors mapped to current colors (content helper class support)
    brand-primary: $brand-01,
    brand-secondary: $grey-40,
    brand-tertiary: $brand-02,
    brand-goat-green: $brand-03,
    off-white: $grey-10,
    light-gray: $grey-20,
    dark-gray: $grey-30,
    scuff01: $secondary-01,
    scuff02: $secondary-02,
    scuff03: $secondary-03
);