$icomoon-font-family: "icomoon";

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?9bo02z');
  src:  url('../fonts/icomoon.eot?9bo02z#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?9bo02z') format('truetype'),
    url('../fonts/icomoon.woff?9bo02z') format('woff'),
    url('../fonts/icomoon.svg?9bo02z#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


$icon-arrow-down: "\e900";
$icon-arrow-left: "\e901";
$icon-arrow-right: "\e902";
$icon-arrow-up: "\e903";
$icon-close: "\e904";
$icon-plus: "\e905";
$icon-minus: "\e906";
$icon-nav: "\e907";
$icon-account: "\e908";
$icon-box: "\e909";
$icon-cart: "\e90a";
$icon-clock: "\e90b";
$icon-cog: "\e90c";
$icon-drop: "\e90d";
$icon-key: "\e90e";
$icon-location-pin: "\e90f";
$icon-lock: "\e910";
$icon-magnifing-glass: "\e911";
$icon-ruler: "\e912";
$icon-star-empty: "\e913";
$icon-star-full: "\e914";
$icon-star-half: "\e915";
$icon-truck: "\e916";
$icon-notification: "\e917";
$icon-question: "\e918";
$icon-info: "\e919";
$icon-check-circle: "\e91a";
$icon-instagram: "\e91b";
$icon-facebook: "\e91c";
$icon-tiktok: "\e91d";
$icon-twitter: "\e91e";
$icon-youtube: "\e91f";
$icon-check: "\e920";
$icon-filter: "\e921";
$icon-play: "\e922";

.icon-arrow-down:before {
  content: $icon-arrow-down;
}
.icon-arrow-left:before {
  content: $icon-arrow-left;
}
.icon-arrow-right:before {
  content: $icon-arrow-right;
}
.icon-arrow-up:before {
  content: $icon-arrow-up;
}
.icon-close:before {
  content: $icon-close;
}
.icon-plus:before {
  content: $icon-plus;
}
.icon-minus:before {
  content: $icon-minus;
}
.icon-nav:before {
  content: $icon-nav;
}
.icon-account:before {
  content: $icon-account;
}
.icon-box:before {
  content: $icon-box;
}
.icon-cart:before {
  content: $icon-cart;
}
.icon-clock:before {
  content: $icon-clock;
}
.icon-cog:before {
  content: $icon-cog;
}
.icon-drop:before {
  content: $icon-drop;
}
.icon-key:before {
  content: $icon-key;
}
.icon-location-pin:before {
  content: $icon-location-pin;
}
.icon-lock:before {
  content: $icon-lock;
}
.icon-magnifing-glass:before {
  content: $icon-magnifing-glass;
}
.icon-ruler:before {
  content: $icon-ruler;
}
.icon-star-empty:before {
  content: $icon-star-empty;
}
.icon-star-full:before {
  content: $icon-star-full;
}
.icon-star-half:before {
  content: $icon-star-half;
}
.icon-truck:before {
  content: $icon-truck;
}
.icon-notification:before {
  content: $icon-notification;
}
.icon-question:before {
  content: $icon-question;
}
.icon-info:before {
  content: $icon-info;
}
.icon-check-circle:before {
  content: $icon-check-circle;
}
.icon-instagram:before {
  content: $icon-instagram;
}
.icon-facebook:before {
  content: $icon-facebook;
}
.icon-tiktok:before {
  content: $icon-tiktok;
}
.icon-twitter:before {
  content: $icon-twitter;
}
.icon-youtube:before {
  content: $icon-youtube;
}
.icon-check:before {
  content: $icon-check;
}
.icon-filter:before {
  content: $icon-filter;
}
.icon-play:before {
  content: $icon-play;
}
