.help-center {
    .accordion {
        .accordion__item {

            .accordion__item__label {
                padding: 24px 0;

                @include media-breakpoint-up(lg) {
                    padding: 48px 0;
                }

                a {
                    text-decoration: none;
                    position: relative;
                    display: block;
                    font-size: 16px;
                    font-weight: 700;
                    font-family: $tertiary-font;
                    padding-right: 20px;
                    @include media-breakpoint-up(lg) {
                        font-family: $primary-font;
                        font-style: italic;
                        text-transform: uppercase;
                        font-size: 30px;
                    }

                    &:after {
                        content: "\E906";
                        font-family: "icomoon";
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 0;
                        font-size: 16px;
                        font-weight: 700;
                        font-style: normal;

                        @include media-breakpoint-up(lg) {
                            font-size: 24px;
                        }
                    }

                    &.collapsed:after {
                        content: "\E905";
                    }
                }

                border-bottom: 1px solid $grey-20;
            }
        }

        // nested accordion
        .accordion {
            position: relative;
            padding-left: 8px;

            @include media-breakpoint-up(lg) {
                padding-left: 24px;
            }

            &:before {
                content: '';
                width: 8px;
                background-color: $grey-10;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;

                @include media-breakpoint-up(lg) {
                    width: 24px;
                }
            }

            .accordion__item {
                margin-left: 24px;
                border-bottom: 1px solid $grey-20;

                &:last-child {
                    margin-left: 0;
                    padding-left: 24px;
                }
            }

            .accordion__item__label {
                border-bottom: none;

                @include media-breakpoint-up(lg) {
                    padding: 32px 0;
                }

                a {
                    color: $black;
                    font-weight: normal;
                    font-size: 14px;

                    @include media-breakpoint-up(lg) {
                        font-family: $tertiary-font;
                        text-transform: none;
                    }
                }
            }

            .accordion__item__content {
                padding-top: 12px;
                padding-bottom: 16px;
            }
        }
    }
}
