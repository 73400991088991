.help-center {
    .sidebar-wrapper {
        .card {
            background-color: $grey-10;
            border-color: $grey-20;

            @include media-breakpoint-up(lg) {
                border: 1px solid $grey-10;
                padding: 16px;
            }

            .card-title {
                @include media-breakpoint-down(md) {
                    position: relative;
                    padding-top: 16px;
                    padding-bottom: 16px;
                    font-style: normal;

                    // add horizontal divider between menu button and menu body
                    &:after {
                        content: '';
                        width: 100%;
                        height: 1px;
                        background-color: $grey-10;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                    }
                }

                @include media-breakpoint-up(lg) {
                    font-size: 36px;
                }
            }

            .card-body {
                background-color: inherit;
                overflow: hidden;
                transition: all .3s ease;

                @include media-breakpoint-down(md) {
                    max-height: 0;
                }

                li:first-child {
                    margin-top: 16px;
                }

                li:last-child {
                    margin-bottom: 16px;
                }

                a {
                    text-decoration: none;
                    display: block;
                    background-color: $white;
                    border: 1px solid $grey-10;
                    padding: 8px 16px;
                    margin-bottom: 8px;

                    &.active {
                        border-color: $black;
                        background-color: $black;
                        color: $white;

                        svg path {
                            fill: $white;
                        }
                    }

                    @include media-breakpoint-up(lg) {
                        padding: 16px;
                    }

                    svg {
                        max-width: 20px;
                        max-height: 23px;
                        margin-right: 16px;
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            // make full width on mobile
            padding-left: 0!important;
            padding-right: 0!important;
        }
    }
}
