@import "~core/components/header";

// New Header Experience
.header-new#top-header {
    .sticky-search {
        height: auto;
        padding: $spacer ($spacer * 2);
    }
    .logo-home {
        margin: 0;
    }
    .brand {
        width: auto;
    }
    .search-modal form {
        float: initial;
        display: initial;
    }
    .search-header {
        cursor: pointer;
    }
    .header {
        @include media-breakpoint-down(md) {
            border-bottom: 1px solid $grey-10;
        }
        height: $header-height-new;
        .brand,
        .navbar-toggler {
            border: 0;
        }
        .navbar-toggler {
            aspect-ratio: 1;
            background-color: $white;
            order: 0;
            height: $spacer * 2;
            width: $spacer * 2;
            @include icon($icon-nav, before, $header-navbar-toggler-icon-size, $header-transparent-text-color-dark);
            &::after,
            &::before {
                border-color: $black;
            }
            // Mobile Navbar - Closed State - Nav Icon Styling
            border: 0;
            &::before {
                border: 0;
                font-size: 23px;
                height: initial;
                width: initial;
                display: block;
            }
            // Mobile Navbar - Open State - Close Icon Styling
            .mobile-menu-in & {
                background-color: $white;
                border-radius: 50%;
                @include icon($icon-close, before, 16px, $header-transparent-text-color-dark);
                &::before{
                    align-content: center;
                    position: initial;
                    transform: initial;
                    display: block;
                    font-size: 23px;
                }
                &::after {
                    display: none;
                }
            }
        }
        &.container {
            @include media-breakpoint-down(md) {
                padding: 0 ($spacer * 2); 
            }
        }
        .brand .logo-home {
            @include media-breakpoint-down(sm) {
                width: $header-logo-width-new;
            }
            @include media-breakpoint-only(md) {
                width: $header-logo-width-md-new;
            }
            @include media-breakpoint-up(lg) {
                width: $header-logo-width-lg-new;
            }
        }
    }
    .search-mobile .site-search {
        border: 0;
        padding: 0;
    }
    .site-search .search-field {
        top: $header-height-new;
    }
    .header-nav-row .search-header,
    .search-mobile .search-facade {
        background-color: $white;
        border: 1px solid $grey-20;
        border-radius: $spacer / 6;
        display: flex;
        height: auto;
        padding: $spacer;
        width: 100%;
        @include media-breakpoint-up(lg) {
            width: fit-content;
        }
        .fa-search {
            height: $spacer * 2;
            padding: 0;
            position: initial;
            transform: initial;
            width: $spacer * 2;
            &::before {
                font-size: 20px;
                margin: 0;
                padding: ($spacer / 4) ($spacer / 6);
                vertical-align: middle;
            }
        }
        .search-placeholder {
            align-self: center;
            color: $black;
            flex-grow: 1;
            flex-shrink: 0;
            @include media-breakpoint-up(lg) {
                padding-right: $spacer * 4;
            }
        }
    }
    .header-nav-row {
        border: 0;
        // Equal Width Columns
        .col {
            flex-grow: 1; 
            margin: 0;
            padding: 0;
        }
        .header-column-1 {
            flex: 0 1 0;
            order: 3;
            padding-left: $spacer * 2;
            @include media-breakpoint-up(md) {
                order: 1;
                flex: 1 1 0;
                padding-left: 0;
            }
        }
        // Center Logo
        .header-column-2 {
            flex-grow: 0;
            justify-content: center;
            min-width: unset;
            order: 1;
            @include media-breakpoint-up(md) {
                order: 2;
            }
        }
        .header-column-3 {
            gap: $spacer * 2;
            order: 2;
            @include media-breakpoint-up(md) {
                order: 3;
            }
        }
        .site-search {
            height: auto;
        }
        // Account Icon
        .header-account-link {
            display: block;
            text-decoration: none;
        }
        .nav-column-1 .account-link {
            display: none; // Hide on Fox and Bell
            padding-top: $spacer * 2;
            a {
                color: $grey-30;
            }
            .icon-account {
                padding: 0;
            }
            .account-text {
                margin: auto 0;
                padding-left: $spacer;
                text-decoration: underline;
            }
        }
        .icon-account {
            font-size: 26px;
        }
        .minicart .minicart-quantity {
            right: 4px;
            top: 9px;
        }
        .minicart-icon {
            display: block;
            line-height: initial;
            padding: 0.25px 0.5px;
            width: $spacer * 2;
            height: $spacer * 2;
            &::before {
                font-size: 23px;
                line-height: 23px;
                vertical-align: middle;
                width: $spacer * 2;
                height: $spacer * 2;
            }
        }
    }
    .nav-column.nav-column-1 {
        overflow-y: auto;
        padding-top: $spacer * 4;
        .nav-items {
            display: flex;
            flex-direction: column;
            gap: $spacer;
        }
    }
    .nav-column.nav-column-2 {
        overflow-y: auto;
        max-height: 100dvh;
        @include media-breakpoint-down(md) {
            height: calc(100dvh - #{$header-height-new} - 70px);
        }
    }
    // Nav Column Padding
    .nav-column {
        height: 100%;
        padding: ($spacer * 4) ($spacer * 2);
        @include media-breakpoint-up(lg) {
            padding: $spacer * 4;
            // Add right border to dropdown menu on desktop
            border-right: 1px solid $grey-20;
            width: 385px;
        }
    }
    .nav-column > .dropdown-item {
        &:not(.featured-item) {
            border-bottom: 1px solid $grey-20;
            padding: ($spacer * 2) 0;
        }
        &:first-of-type {
            padding-top: 0;
        }
        &:last-of-type {
            border-bottom: 0;
            padding-bottom: 0;
        }
        &.direct-category-link {
            border: 0;
            padding: 0;
            @include media-breakpoint-up(lg) {
                padding-bottom: $spacer;
            }
        }
        &.featured-item + .direct-category-link {
            padding-top: $spacer * 2;
        }
    }
    .dropdown-toggle::after {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
    // Force Nav Bar to Left - Desktop
    .dropdown-menu {
        top: 0;
        margin: 0;
        max-width: fit-content;
        min-width: unset;
        right: initial;
        display: flex;
        overflow: auto; // Mobile fix
        @include media-breakpoint-down(md) {
            .dropdown-menu {
                display: flex;
                flex-direction: column;
                position: relative;
                left: 100%;
                &.show {
                    transform: translateX(-100%);
                    .nav-menu {
                        display: none;
                    }
                }
            }
            .dropdown-link {
                padding-left: 0;
                padding-right: 0;
            }
            .dropdown-toggle {
                padding-bottom: 0;
            }
        }
        @include media-breakpoint-up(lg) {
            border: 0;
            gap: $spacer;
            visibility: hidden;
            justify-content: start;
            transition: transform 200ms ease-in-out;
            height: calc(100dvh - #{$header-height-new});
            &:not(.show){
                left: 0;
                transform: translateX(-100%);
            }
            // 2nd column all parent. Won't apply to children
            &.show {
                visibility: initial;
                left: 100%;
                transform: translateX(0);
                z-index: -1;
                // Children
                .dropdown-menu {
                    visibility: initial;
                }
            }
            // Child Always
            .dropdown-menu {
                height: auto;
                transform: translateX(0);
            }
        }
        .featured-item .dropdown-toggle + .dropdown-menu {
            padding-top: $spacer;
            @include media-breakpoint-up(lg) {
                padding-top: $spacer * 2;
            }
            .dropdown-link {
                padding-bottom: 0;
                @include paragraph-font-size(normal);
            }
        }
        &::before {
            width: auto;
        }
    }
    .dropdown-link {
        @include media-breakpoint-up(lg) {
            padding: 0;
        }
        text-wrap: nowrap;
    }
    .featured-item > .dropdown-link {
        @include header(5);
    }
    .custom-dropdown:not(.featured-item) > .dropdown-link {
        margin-bottom: $spacer * 2;
    }
    .nav-link.highlight,
    .nav-item.highlight > .dropdown-toggle {
        color: $header-link-highlight-color;
        .current-category-arrow {
            color: initial;
        }
    }
    .nav-item a {
        margin-bottom: 0;
        &:not(.shop-all) {
            text-decoration: none;
        }
    }
    .shop-all {
        text-decoration: underline;
    }
    .extra-links {
        padding-top: $spacer * 8;
        a {
            color: $grey-30;
        }
    }
    .category-thumbnails {
        display: flex;
        flex-direction: column;
        gap: ($spacer / 3) 0;
        padding-top: $spacer * 4;
        &:only-child {
            padding-top: 0;
        }
        .category-thumbnail .category-name {
            text-wrap: nowrap;
        }
        .category-image img {
            aspect-ratio: 1;
            height: $spacer * 4;
            margin-right: $spacer;
            width: $spacer * 4;
        }
    }
    .nav-item.show .current-category-arrow {
        visibility: visible;
    }
    .current-category-arrow {
        margin: auto 0;
        visibility: hidden;
        &::before {
            font-size: $spacer * 2;
        }
    }

    .top-category {
        display: none;
    }

    .dropdown-item.top-category,
    .nav-menu {
        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .featured-item {
        border-right: 0;
        .dropdown-menu {
            gap: $spacer + ($spacer / 3);
        }
        .dropdown-link:not(.dropdown-toggle) {
            color: $grey-30;
        }
    }
    .featured-item:has(+ .dropdown-item:not(.d-lg-none)) {
        padding-bottom: $spacer * 2;
    }
    .dropdown-menu,
    .main-menu {
        @include media-breakpoint-down(md) {
            max-width: initial;
            width: 100%;
        }
    }
    .main-menu {
        @include media-breakpoint-up(lg) {
            .menu-group {
                border-top: 1px solid $grey-20;
                height: calc(100dvh - #{$header-height-new});
                transition: transform 200ms ease-in-out;
                position: fixed;
                transform: translateX(-100%);
                top: $header-height-new;
                left: 0;
                max-width: fit-content;
            }
            &.in {
                .menu-group {
                    transform: translateX(0);
                }
                .navbar-toggler {
                    display: block;
                }
            }
        }
    }
    .navbar-nav {
        background-color: $white;
        flex-direction: column;
    }
    .mobile-nav-content-wrapper {
        @include media-breakpoint-down(md) {
            padding: 0;
        }
    }
    .desktop-category-links {
        display: none;
        @include media-breakpoint-up(lg) {
            cursor: pointer;
            display: flex;
            margin: auto;
            order: 1;
            padding-left: $spacer * 2;
            height: 100%;
            .category {
                margin-bottom: 0;
                padding: 0 24px;
                // Category Hover Border Transition
                .category-name {
                    position: relative;
                    height: 100%;
                    align-content: center;
                    &:before {
                        content: '';
                        display: block;
                        height: $header-link-underline-height;
                        background-color: $header-link-underline-color;
                        position: absolute;
                        transition: width $transition-speed ease-out;
                        z-index: 1001;
                        bottom: -1px;
                        width: 0;
                    }
                }
                &:hover .category-name:before {
                    width: 100%;
                }
                &:first-of-type {
                    margin-left: (-$spacer * 2);
                }
                &:last-of-type {
                    margin-right: (-$spacer * 2);
                }
            }
        }
        @include media-breakpoint-up(xl) {
            padding-left: $spacer * 4;
        }
    }
    .menu-subcategories {
        padding: 0;
        @include media-breakpoint-down(md){
            border-top: 0;
        }
        @include media-breakpoint-up(lg) {
            height: 100%;
            width: min-content;
        }
    }
    .close-menu button {
        display: flex;
        @include paragraph-font-size(large);
        @include media-breakpoint-down(md){
            padding: $spacer * 2;
        }
        .icon-arrow-left {
            align-self: center;
            float: initial;
            margin: 0;
            padding-right: $spacer * 2;
        }
    }
    .header-utility-mobile {
        padding-top: 0;
        margin: 0;
        &::before {
            display: none; // Hide border on header utility text on desktop
        }
        .additional-link {
            margin-bottom: 16px;
        }
        a {
            font-size: unset;
        }
    }
    .main-menu.menu-toggleable-left {
        @include media-breakpoint-down(md){
            height: calc(100vh - #{$header-height-new});
            top: $header-height-new;
            &.in + .navbar-toggler {
                &::after,
                &::before {
                    left: 0;
                    border-bottom: 0;
                }
            }
            &.in .show > .dropdown-menu .dropdown-menu {
                .nav-menu {
                    display: none;
                }
                transform: translateX(-100%);
            }
        }
    }
}

// Top Header Bar
.header-utility {
    background-color: $grey-10;

    .header-account-container,
    .header-utility-links a {
        color: $grey-30;
        text-decoration: none;
        font-size: 10px;
        font-weight: 400;
    }

    .header-utility-links {
        display: flex;

        .utility-link {
            padding: 4px 20px;
            border-right: 1px solid #d2d2d2;
        }
    }
}

.header-utility-mobile {
    position: relative;
    padding-top: $spacer;
    margin: $spacer 0;

    &::before {
        content: '';
        display: inline-block;
        width: 100%;
        height: 1px;
        background-color: $grey-20;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }

    .header-utility-links .utility-link,
    .mobile-menu-additional-links .additional-link {
        margin-bottom: 12px
    }

    a {
        text-decoration: none;
        font-size: 13px;
    }
}

.header-banner {
    background-color: $grey-10;
    &:has(.countdown-timer){
        // Override 100px max-height from .slide-up
        max-height: 100%;
    }
}

// Override .header-banner padding on .countdown-timer
.header-banner .countdown-timer {
    margin-bottom: (-$spacer);
    margin-top: (-$spacer);
}

.header-account-container {
    .dropdown-toggle::after {
        display: none;
    }
}

.header .minicart .minicart-quantity {
    top: 8px;
    right: 3px;
    font-family: $secondary-font;
    font-weight: 400;
    font-size: 8px;
    line-height: 10px;
}

.header-support-container a {
    text-decoration: none;
    color: $grey-30;
}

.header-nav {
    border-bottom: 1px solid $grey-20;

    @include media-breakpoint-down(md) {
        .header.container {
            padding: 0;
        }
    }

    .header-nav-row {
        border-bottom: $header-navbar-border-bottom-width solid $header-navbar-border-bottom-color;
    }
}

#maincontent {
    margin-bottom: 48px;
}

.megamenu-open #maincontent {
    @include media-breakpoint-up(md) {
        opacity: .5;
    }
}

.dropdown-country-selector {
    box-shadow: $header-link-dropdown-menu-box-shadow;

    .dropdown-item:last-child {
        padding-bottom: 48px;
        margin-bottom: 48px;
        @include media-breakpoint-up(lg) {
            padding-bottom: 0;
            margin-bottom: 0;
        }
    }
}

.header-account-container {
    display: inline-flex;
    margin: auto 0;
    a {
        color: inherit;
        text-decoration: inherit;
    }
    .account-link {
        padding: 4px 20px;
    }
    .logout {
        border-left: 1px solid #d2d2d2;
    }
}

.header .navbar-toggler {
    padding: 0;
    width: 41px;
    height: 41px;
    border-left: 1px solid $grey-20;
    border-radius: 0;
    position: relative;

    &::before,
    &::after {
        content: '';
        display: inline-block;
        width: 21px;
        height: 6px;
        border-top: 1px solid $black;
        border-bottom: 1px solid $black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &::after {
        display: none;
    }

    .mobile-menu-in & {
        background-color: $black;

        &::before,
        &::after {
            display: inline-block;
            height: 1px;
            border-color: $white;
            left: 25%;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }
}

@include media-breakpoint-down(sm){
    #dropdownCountrySelector:after,#dropdownCountrySelectorMobile:after {
        content: "";
    }

    .dropdown-country-selector {
        overflow: scroll;
        max-height: 90vh;
    }

    .header .header-column-3 > .pull-right {
        margin-right: 4px;
    }
}

@include media-breakpoint-down(md) {
    .checkout-header .header-nav {
        height: unset;
    }
}

.locator {
    margin-left: 0;
}

.locator-icon {
    height: 20px;
    position: relative;
    top: -2px;
}

// enhanced sticky header fix
.fixed-header-enhanced {
    .sticky-header & {
        .header-nav.header-near-top {
            animation: slide-down $header-transition-speed ease-out forwards;
        }
    }
}