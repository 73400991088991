@import "~core/components/common";

.modal-background.geo-modal {
    z-index: 1025;
}

.mobile-menu-in .modal-background {
    // prevent PLP slider buttons from showing on top of overlay
    z-index: 2;
}
.order-discount {
    color: $danger;
}

.giftcertificate-discount {
    color: $danger;
}

.shipping-discount {
    color: $danger;
    flex: 1;
    width: 100%;
}
.total-shipping-line-through {
    text-decoration: line-through;
}
.cart-shipping-total {
    flex: 1;
}
.shipping-discount-cart {
    color: $danger;
    width: 100%;
}
.shipping-cart-padding {
    margin-right: 6px;
}
.esw-shipping-message {
    padding-left: $spacer/2;
}

button.close-button,
.close-button {
    @include close-button($black, $white);

    .alert & {
        @include close-button($white, $black);
    }
}