@import "~core/cart/cart";

#chooseBonusProductModal {
    padding-right: 0 !important;
}

.cart-page {
    .bonus-product-line-item .bonus-line-item-msg {
        padding-top: $spacer / 2;
        border-top: 1px solid $card-body-border-color;
        & +.bundled-line-item {
            border-top: none;
        }
    }
    .bundled-line-item {
        .line-item-total-price {
            padding: 0 ($spacer / 2);
        }
        .bonus-product-button {
            margin-top: 0;
            button {
                color: $black;
                text-decoration: underline;
            }
        }
    }
    .page-title {
        line-height: 100%;
    }
    .totals.card {
        .card-body.checkout-continue {
            @include media-breakpoint-down(sm) {
                position: relative;
                background-color: transparent;
                box-shadow: none;
                border-bottom: none;
                margin-right: 0;
                margin-left: 0;
                padding-right: 0px;
            }
        }
    }
    .continue-shopping-cart {
        @include media-breakpoint-down(xs) {
            display: block;
        }
        display: none;
    }
    .continue-shopping-link {
        @include icon($icon-arrow-left, before);
        width: 245px;
        @include media-breakpoint-down(md) {
            width: 185px;
        }
    }
    .continue-shopping-link[class*="btn-"]:not(.title):before {
        font-size: 14px;
        margin-left: $spacer;
    }
    .continue-shopping-link[class*="btn-"]:not(.title) {
        padding-left: 32px;
    }
    .coupon-price-adjustment {
        font-size: 12px;
        border: 1px solid transparent;
        padding: $input-padding-top 0 $input-padding-bottom 0;

        .coupon-applied {
            color: $red;
        }

        .remove-coupon {
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: flex-end;
        }
    }

    .promo-code-section .title {
        padding-left: 0;

        &::after {
            right: 0;
        }
    }

    .discount-top-border{
        border-bottom: 1px solid $card-body-border-color
    }
    .approaching-discounts-mobile .approaching-discounts {
        @include media-breakpoint-down(md) {
            .single-approaching-discount:last-of-type{
                margin-bottom: $spacer;
            }
        }
    }
    .approaching-discounts {
        .single-approaching-discount:last-of-type{
            margin-bottom: 0;
        }
        .single-approaching-discount {
            /* rvw_autobahn_core override */
            background-color: unset;
            border: unset;
            border-radius: unset;
            margin-bottom: unset;
            padding: unset;
            color: unset;
            /* rvw_autobahn_core override */

            border: 1px solid #ccc;
            border-radius: $spacer / 2;
            padding: $spacer;
            margin-bottom: $spacer;
            .threshold-bar {
                height: 16px;
                border: 1px solid #D8D8D8;
                border-radius: $spacer / 2;
                background-color: $secondary-01;
                .threshold-bar-progress {
                    height: 100%;
                    width: 0%;
                    border-radius: 14px;
                    background-color: $black;
                    transition: width .2s;
                }
            }
            .threshold-message {
                padding-bottom: $spacer;
                color: $checkout-approaching-discount-color;
            }
        }
        .bonus-product-button-wrapper {
            border: 1px solid #ccc;
            border-radius: $spacer / 2;
            padding: $spacer;
            margin-bottom: $spacer;
            div[class^=col] {
                margin-top: auto;
                margin-bottom: auto;
            }
            .circle {
                background-image: url('../images/icons/gift.svg');
                background-position: center;
                background-repeat: no-repeat;
                border: 1px solid #ccc;
                border-radius: 50%;
                padding: 0;
                height: $spacer * 3;
                width: $spacer * 3;
            }
            .bonus-product-button button {
                font-size: 14px;
                height: auto;
                padding-top: $spacer / 2;
                padding-bottom: $spacer / 2;
                padding-right: $spacer * 2;
            }
        }
    }

    .dw-apple-pay-button,
    .dw-apple-pay-button:hover {
        border-radius: $button-border-radius;
    }

    .dw-apple-pay-button:hover {
        opacity: .8;
    }

    klarna-placement {
        margin-top: 15px;
        display: block;
    }

    .compliance-callout {
        @include paragraph-font-size(normal);
        background-color: $warning;
        font-weight: bold; // 700
        margin-top: $spacer;
        padding: $spacer ($spacer * 2);
    }
} 

.cart-page .totals .estimated-total p {
    white-space: nowrap;
}

.esw-cart-exceeded {
    background: $red;
    padding-top: 22px;
    padding-bottom: 22px;
    color: $white;
    display: flex;
    align-items: center;

    h6 {
        display: inline-block;
        line-height: 1;
        padding-left: $spacer*2;
        margin: 0;
    }
    p {
        display: inline-block;
        line-height: 1;
        padding-left: $spacer*2;
        margin: 0;
    }
    .icon-info {
        font-size: $spacer*2;
    }
    @include media-breakpoint-down(md) {
        display: block;
        p {
            padding-left: $spacer*3;
            padding-top: $spacer;
        }
        h6 {
            padding-left: $spacer;

        }
    }
    .esw-exceeded-icon-container {
        display: flex;
        align-items: center;
    }
}

.esw-exceeded {
    background-color: $red;
    color: $white;
    padding-bottom: 22px;
    padding-top: 22px;
    padding-left: $spacer*2;
    padding-right: $spacer*2;

    .esw-exceeded-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;

        h6 {
            padding-left: $spacer;
            line-height: 1;
            flex: 1;
            min-width: 0;
            word-wrap: break-word;
            white-space: normal;
        }

        .icon-info {
            font-size: $spacer*2;
            flex-shrink: 0;
        }
    }

    p {
        padding-left: $spacer * 3;
        line-height: 1;
        padding-top: $spacer;
    }
}

.esw-cart-message {
    padding-bottom: 2px;
    padding-top: $spacer;
}