
.minicart-open {
    overflow: hidden;
    @include media-breakpoint-up(lg) {
        overflow: visible;
    }
}

.minicart {
    position: relative;

    .popover {
        position: fixed;
        top: 0;
        bottom: 0;
        left: auto;
        right: 0;
        width: 100vw;
        max-width: 100vw;
        min-height: $minicart-size;
        display: none;

        @include media-breakpoint-up(md) {
            max-width: $minicart-size;
        }

        &.show {
            display: block;
        }

        & > .container.cart {
            display: flex;
            flex-direction: column;
            height: 100vh;
            padding-left: 24px;
            padding-right: 24px;

            @include media-breakpoint-down(md) {
                padding-bottom: 24px;
            }

            .minicart-body {
                flex-grow: 1;
            }
        }

        .minicart-body {
            overflow-y: auto;
        }
    }
    .minicart-label {
        @include label-styles;
        display: inline;
    }

    .cart {
        padding-top: $spacer;
        background-color: $body-bg;
    }
    .remove-btn {
        @include delete-card-button;
        margin: 5px;
        left: 0;
        right: auto;
    }
    .product-summary {
        padding-left: 0;
        padding-right: 0;
        padding-bottom: $spacer;
        padding-top: $spacer;
        width: 100%;

        .line-item-name {
            width: calc(100% - #{$product-card-remove-icon-size} - #{$spacer}/2);
        }
    }
    .minicart-item {
        padding-right: $spacer;
        padding-right: $spacer;

        &+.minicart-item {
            margin-top: $spacer - $spacer/2;
            padding-top: $spacer;
            border-top: $product-card-item-border-width solid $product-card-border-color;
            border-radius: $card-border-radius;
        }
        &:first-child {
            .product-line-item {
                margin-top: 0;
            }
        }
    }
    .product-line-item {
        position: relative;
    }
    .item-price-qty {
        .col {
            flex-direction: column;
            align-items: flex-start;
        }
    }
    .quantity,
    .quantity-stepper {
        width: 100%;
        max-width: 90px; // fix for bug in IE11
    }
    .quantity {
        min-width: 50px;
    }
    .line-item-promo > div {
        padding: 0 0 $spacer/2;
    }
    .minicart-footer {
        padding-top: $spacer;
        border-top: 1px solid $card-body-border-color;
        padding-left: 0;
        padding-right: 0;
    }
    .estimated-total {
        font-family: $minicart-totals-font-family;
        font-weight: $minicart-totals-font-weight;
        text-transform: $minicart-totals-text-transform;
        height: unset;
        p {
            @include paragraph-font-size($minicart-totals-font-size);
        }
    }
}
