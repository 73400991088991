@import "~core/components/layout";

.product-grid {
    .experience-region,
    .experience-component {
        max-width: $product-tile-max-width;
        margin: 0 auto;
    }

    .product {
        height: 100%;
    }
}

.background-image {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

// small-gutters helper class - layout over writes for PD components
[class*='experience-commerce_layouts'] .small-gutters {
    .container {
        padding: 0;
    }

    .row {
        margin-left: -6px;
        margin-right: -6px;

        > [class*="col-"] {
            padding-left: 6px;
            padding-right: 6px;
        }
    }

    @include media-breakpoint-down(sm) {
        .region[class*='col-md'] + .region[class*='col-12']:not(.order-1), 
        .region[class*='col-md'].order-2[class*='col-12'] {
            margin-top: 12px;
        }
    }
}
