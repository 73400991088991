@import "~core/components/imageTextBlock";

.image-text-block {
    &.text-placement-inside {
        .image-text-block-text {
            position: initial;
            transform: initial;
            transform: initial;
            z-index: $z-index-lv2;
            &.text-center {
                @include absolute-center;
            }
            &.text-bottom-center {
                position: absolute;
                bottom: 80px;
                right: 0;
                transform: translateY(50%);
                width: 100%;
                top: auto;
                @include media-breakpoint-up(lg) {
                    bottom: 160px;
                }
            }
        }
    }

    // background image
    .image-cropper {
        position: relative;
        z-index: $z-index-base;
    }

    // buttons
    .image-text-block-buttons {
        font-size: 0; // prevent extra space for inline-block elements
    }
    .image-text-block-button {
        margin-top: $spacer;
        display: inline-block;
        position: relative;
    }

    // text container
    .image-text-block-text {
        &.text-center .image-text-block-button:not(:only-child) {
            margin-left: $spacer/2;
            margin-right: $spacer/2;
        }
        &.text-left .image-text-block-button:not(:only-child) {
            margin-right: $spacer;
        }
        &.text-right .image-text-block-button:not(:only-child) {
            margin-left: $spacer;
        }
    }

    // element link
    .image-text-block-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $z-index-lv1;
    }
}

// Add custom class to overlap text over image when this component is used
.image-text-overlap {
    .image-text-block-text {
        @include media-breakpoint-up(md) {
            position: absolute;
            width: 100%;
            bottom: 0;
        }
    }
}
