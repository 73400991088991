/*
    Copy SFRA Veil Styling
    Prevents the need to include autobahn/_spinner.scss
    Would otherwise include unused and unecessary styles
*/
.veil {
    position: fixed;
    z-index: $z-index-modal-backdrop;
    text-align: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .underlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: $preloader-veil-opacity;
        background-color: $black;
    }
}

// Center Preloader Container
.asg-preloader {
    width: $preloader-size;
    height: $preloader-size;
    text-align: center;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: $preloader-size / 2 * -1;
    margin-left: $preloader-size / 2 * -1;
}

// Center Preloader Animation Frames in Container
.fox-preloader--frame-0,
.fox-preloader--frame-1 {
    align-self: center;
    background-position: center;
    background-repeat: no-repeat;
    display: inline-flex;
    height: inherit;
    width: inherit;
}

// Fox Logo
.fox-preloader--frame-0 {
    background-image: url('../../images/preloader/fox-preloader-0.svg');
}

// Fox Logo Glow
.fox-preloader--frame-1 {
    background-image: url('../../images/preloader/fox-preloader-1.svg');
    // Overlap Frame with Sibiling
    left: 0;
    position: absolute;

    // Animation - Fade Image
    animation-name: fox-preloader-animation;
    animation-timing-function: ease-out;
    animation-iteration-count: infinite;
    animation-duration: 100ms;
    animation-direction: alternate;
}

// Animate Opacity
@keyframes fox-preloader-animation {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}