.category-slider-wrapper {
    display: flex;
    padding: 0 ($spacer * 4) ($spacer * 2);
    align-items: flex-start;
    gap: 4px;
    overflow-y: auto;

    @include media-breakpoint-down(md) {
        padding-left: $spacer *2
    }

    a {
        display: flex;
        height: 100%;
        text-decoration: none;
    }
    .enhanced-category-link {
        text-decoration: underline;
    }
    .slide {
        width: 100%;
    }
    .enhanced-category-image {
        aspect-ratio: 1;
        height: 100%;
        object-fit: cover;
    }
    .enhanced-category-text {
        padding: ($spacer * 2) $spacer;
        display: flex;
        flex-direction: column;
        width: 200px;
    }
    .category-slider-container {
        align-self: stretch;
        background: $secondary-01;
    }
    .display-type-0 { // "Image, Title, Subtitle, Callout"
        .enhanced-category-image {
            width: 150px;
        }
        .enhanced-category-text {
            justify-content: space-between;
        }
    }
    .display-type-1 { // "Image, Title"
        .enhanced-category-subtitle,
        .enhanced-category-link {
            display: none;
        }
        .enhanced-category-image {
            width: 100px;
        }
        .enhanced-category-text {
            justify-content: center;
        }
    }
    .display-type-2 { // "Title, Subtitle"
        .enhanced-category-image,
        .enhanced-category-link {
            display: none;
        }
        .enhanced-category-text {
            justify-content: space-between;
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }
    }
    .display-type-3 { // "Only Title"
        background-color: transparent;
        border: 1px solid $grey-20;
        border-radius: $spacer / 4;
        .enhanced-category-image,
        .enhanced-category-subtitle, 
        .enhanced-category-link {
            display: none;
        }
        .enhanced-category-text {
            justify-content: center;
            padding-left: $spacer * 2;
            padding-right: $spacer * 2;
        }
        
    }
    .enhanced-category-title,
    .enhanced-category-subtitle,
    .enhanced-category-link {
        margin-bottom: 0;
    }
}