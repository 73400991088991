@import "~core/components/formFields";

.validator-container {
    .password-validation-helper {
        li {
            @include icon($icon-arrow-right, before, 13px, $grey-30);
        }
    }
}

.form-control, select.form-control{
    &.is-invalid {
        border-width: 2px;
    }
}

.form-group {
    label &~.isinvalid {
        color: $red;
    }
}