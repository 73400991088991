@import "~core/account/account";

.account-page {
    .card-body {
        border-bottom: none;

        p {
            &.not-returnable-text {
                font-family: $secondary-font;
                font-size: $default-text-font-size;
                color: $red;
            }
        }
    }
    .card-footer {
        border-top: 1px solid $card-footer-border-color;
    }
    .order-card {
        .card-footer {
            border: none;
        }
    }
    .confirm-details {
        .card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    .card-footer {
        padding: 0 0 0 0;
        text-align: left !important;
        border: 0;
    }

    .order-history-buttons {
        padding: $card-body-padding-left;

        .btn-primary {
            width: 100%;
            margin-bottom: 6px;
        }
    }

    .view-order-btns {
        margin-top: 20px;

        .btn {
            width: 100%;
            color: #fff;
            margin-bottom: 5px;
        }
    }

    .oh-header {
        padding-left: $card-padding-left;
    }

    .order-list-container {
        padding-left: 7px;
    }

    .loyalty-opt-in-banner-wrapper {
        position: relative;
        z-index: 1;
    }

    .loyalty-opt-in-banner {
        @include gradient-border($background: $secondary-01);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: 100%;
        margin: ($spacer * 2) 0;
        padding: $spacer * 2;
        gap: $spacer * 2;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            align-items: center;
            margin-bottom: $spacer * 4;
        }

        p {
            margin-bottom: 0;
            font-weight: 500;
        }

        .podium-club-logo,
        button {
            flex-shrink: 0;
        }
    }
}

// Login Page
.login-page{
    @include media-breakpoint-down(sm){
        h2 {
            font-size: $h4-font-size;
        }
    }

    @include media-breakpoint-up(md) {
        .track-order-header-text {
            line-height: 1.6;
            font-size: 11px;
        }
    }

    @include media-breakpoint-up(lg){
        .track-order {
            padding-top: 18px;
        }

        .card .track-order-header{
            padding-bottom: 0;
        }

        .track-order-header-text {
            margin-bottom: -1px;
            line-height: 1.4;
            font-size: 12px;
        }

        .login-form .btn-primary {
            margin-top: 76px;
        }
    }
}

// Returns
.container.returns {
    h1 {
        padding: 20px 0 0 20px;
    }

    h3 {
        margin-bottom: $spacer-xl;
    }

    .product-info .item-image img.product-image {
        width: 90%;
    }

    .product-card-footer {
        @include media-breakpoint-up(lg){
            padding-left: 30%;
        }
        .col-lg-3 {
            padding: 0;
        }
    }

    .line-item {
        padding: 30px 0;
        border-top: 1px solid #eee;

        &:first-child {
            border-top: none;
        }
    }

    .js-button-returns-next,.js-button-returns-back,.return-label-download,.order-history-back {
        width: 250px;
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }

    @include media-breakpoint-down(sm){
        .return-progress-next {
            width: 100%;
        }
    }

    @include media-breakpoint-up(md){
        .below-cta {
            padding-right: 16px;
        }
    }

    .returns-body p {
        font-size: 14px;
    }

    select.quantity {
        border-radius: 0;
    }

    .js-select-return-reasons {
        padding-top: 20px;
    }

    .return-labels-header {
        @include media-breakpoint-up(sm){
            .return-labels-headline {
                justify-content: flex-start;
                width: 50%;
            }
            .return-labels-btn {
                justify-content: flex-end;
                width: 50%;
            }
        }
        @include media-breakpoint-down(xs){
            .return-labels-headline {
                justify-content: center;
                width: 100%;
            }
            .return-labels-btn {
                justify-content: center;
                width: 100%;
            }
        }
    }

    .return-labels {
        .line-item-name {
            position: relative;
            left: 9.8%;
            top: 20px;
            @include media-breakpoint-down(sm){
                left: 33.8%;
            }
        }
    }

    .item-data {
        .col-4 {
            @include media-breakpoint-up(md){
                flex: 0 0 16.66667%;
                max-width: 16.66667%;
            }
            padding-right: 0;
            img {
                width: 100%;
            }
        }
        .col-8 {
            padding-top: 22px;
            p {
                margin-bottom: 6px;
            }
        }
    }

    .returns-download {
        visibility: hidden;
        @include media-breakpoint-down(sm){
            display: none;
        }
    }

    .action-links {
        @include media-breakpoint-up(md){
            padding-top: 80px;
        }
        @include media-breakpoint-down(sm){
            width: 100%;
        }
    }

    .card .border, .card .container {
        padding: 20px !important;
    }
}


#passwordResetSentModal .subtitle, #registerSuccessModal .success-message {
    color: $green;
}

.login-partial,
.register-partial,
#loginModal,
#countrySelectorModal,
#registerModal,
#registerSuccessModal,
#forgotPasswordModal,
#passwordResetSentModal {
    background: rgba($black, 0.5);
    padding: 0 !important;
    &.show .modal-dialog {
        right: 0;
    }
    .modal-dialog {
        transition: right 0.3s linear;
        right: -$header-suggestions-results-width;
        margin: 0;
        margin-left: auto;
        max-width: 100dvw;
        @include media-breakpoint-up(lg) {
            max-width: $header-suggestions-results-width;
        }
    }

    .modal-content {
        height: 100dvh;
        background-color: $white;
    }

    .row {
        border-bottom: 1px solid $grey-20;
        margin: 0;
        padding: $spacer * 2;
        @include media-breakpoint-up(lg) {
            padding: ($spacer * 2) ($spacer * 4);
        }
        .col {
            padding: 0;
        }
        &:last-of-type {
            border-bottom: 0;
        }
    }

    .modal-header {
        background-color: $white;
        margin: 0;
        padding: $spacer * 2;
        @include media-breakpoint-up(lg) {
            padding: ($spacer * 2) ($spacer * 4);
        }
        position: sticky;
        top: 0;
        z-index: 1051;
        .header-text {
            margin: 0;
            padding: 0;
            color: $black;
        }
    }

    .modal-body {
        padding: 0;
    }

    .form-group, .add-bottom-margin {
        margin: 0 0 ($spacer * 2);
    }

    .form-group {
        .custom-control-label, .custom-form-label {
            color: $black;
        }
        .custom-form-label, .custom-control-label p {
            @include paragraph-font-size(small);
        }
        .custom-form-label {
            margin-top: $spacer / 2;
        }
        label, .custom-control-label, .custom-control-label p {
            margin-bottom: 0;
        }
        .invalid-feedback {
            @include paragraph-font-size(normal);
            margin-top: $spacer * 2;
        }
    }
    .alert {
        background-color: $danger;
        margin-bottom: $spacer * 2;
        .icon-close {
            aspect-ratio: 1;
            background-color: $white;
            border-radius: 50%;
            padding: $spacer / 4;
            margin: ($spacer / 3) $spacer auto 0;
            &:before {
                left: 0;
            }
        }
    }
}

#register-form {
    .email-confirm {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
        z-index: -1;
    }
}

.login-partial, .register-partial {
    background: inherit;
    @include media-breakpoint-up(lg){
        max-width: 50dvw;
        margin: 0 auto;
    }
}

.icon-checkmark,
.icon-close {
    &:before {
        color: $black;
        font-weight: 700;
        position: relative;
        left: 1px;
    }
}

p {
    line-height: 24px;
}

.bundle-return-wrapper {
    margin-top: $spacer *4;
    p {
        display: inline;
    }
}

.bundle-return-text {
    color: $black;
    font-family: $secondary-font;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
    margin-bottom: 0px;
}

.bundle-return-text-sub {
    color: $black;
    font-family: $secondary-font;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

.change-country-container {
    border-top: 1px solid $grey-20;
    padding: 0px;
    @include media-breakpoint-down(md) {
        padding: $spacer*2 18px $spacer*2 $spacer*2;
    }

    width: 100%;

    &.custom-control.custom-radio .custom-control-label:before {
        left: calc(100% - 72px);
        top: 36%;
        @include media-breakpoint-down(md) {
            left: calc(100% - 32px);
            top: 22%;
        }
    }

    &.custom-radio .custom-control-input:checked ~ .custom-control-label:before {
        top: 36%;
        @include media-breakpoint-down(md) {
            top: 22%;
        }
    }

    &.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
        left: calc(100% - 67px);
        top: 42%;
        @include media-breakpoint-down(md) {
            left: calc(100% - 27px);
            top: 35%;
        }
    }

    .custom-control-label {
        width: 100%;
        padding: $spacer*2 42px $spacer*2 $spacer*4;

        @include media-breakpoint-down(md) {
            padding: 0px;
        }
    }
}

.custom-radio-country-selector {
    margin-left: auto;
    padding-top: $spacer/2;
}
.country-selector-flag {
    border: 1px solid $grey-20;
    width: $spacer*4;
    height: 36px;
    line-height: 36px;
    background-size: cover;
 }
 .county-selector-text {
    padding-left: $spacer * 2;
    display: inline-block;
    height: 36px;
    line-height: 36px;
    text-align: center;
    vertical-align: middle;
    font-size: 14px;
 }