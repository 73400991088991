.hero {
    position: relative;
    overflow: hidden;
    display: flex;
    .hero-container {
        display: flex;
        padding: $hero-textblock-outer-padding;
        height: 100%;
        width: 100%;
        @include media-breakpoint-up(md) {
            padding: $hero-textblock-outer-padding-md;
        }
        @include media-breakpoint-up(lg) {
            padding: $hero-textblock-outer-padding-lg;
        }
        @include media-breakpoint-up(xl) {
            .full-bleed & {
                max-width: map-get($container-max-widths, xl);
                margin-left: auto;
                margin-right: auto;
            }
        }
        .hero-background-link {
            z-index: $z-index-base;
            @include absolute-cover;
            .video-container {
                @include absolute-cover;
                .video-player {
                    display: none;
                }
            }
        }
    }
    .embedded-component {
        width: 100%;
        &:empty {
            display: none;
        }
        .experience-region {
            &:empty {
                display:none;
            }
        }
    }

    &.sm- {
        &short {
            min-height: $hero-min-height-short;
        }
        &medium {
            min-height: $hero-min-height-medium;
        }
        &tall {
            min-height: $hero-min-height-tall;
        }
        &screen-height {
            min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            .transparent-header~div & {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            }
        }
    }

    &.md- {
        @include media-breakpoint-up(md) {
            &short {
                min-height: $hero-min-height-short-md;
            }
            &medium {
                min-height: $hero-min-height-medium-md;
            }
            &tall {
                min-height: $hero-min-height-tall-md;

            }
            &screen-height {
                min-height: calc(100vh - #{$header-height-md} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                .transparent-header~div & {
                    min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                }
            }
        }
    }

    &.lg- {
        @include media-breakpoint-up(lg) {
            &short {
                min-height: $hero-min-height-short-lg;
            }
            &medium {
                min-height: $hero-min-height-medium-lg;
            }
            &tall {
                min-height: $hero-min-height-tall-lg;
            }
            &screen-height {
                min-height: calc(100vh - #{$header-height-lg} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                .transparent-header~div & {
                    min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                }
            }
        }
    }

    // Height settings
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 0; // fix for IE11 flexbox align-items bug
        &.no-image {
            height: auto;
        }
    }
    &.short {
        min-height: $hero-min-height-short;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-short-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-short-lg;
        }
    }
    &.medium {
        min-height: $hero-min-height-medium;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-medium-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-medium-lg;
        }
    }
    &.tall {
        min-height: $hero-min-height-tall;
        @include media-breakpoint-up(md) {
            min-height: $hero-min-height-tall-md;
        }
        @include media-breakpoint-up(lg) {
            min-height: $hero-min-height-tall-lg;
        }
    }
    &.screen-height {
        min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        @include media-breakpoint-up(md) {
            min-height: calc(100vh - #{$header-height-md} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        }
        @include media-breakpoint-up(lg) {
            min-height: calc(100vh - #{$header-height-lg} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
        }
        .transparent-header~div & {
            min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            @include media-breakpoint-up(md) {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            }
            @include media-breakpoint-up(lg) {
                min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
            }
        }
    }
}

.hero-media {
    @include absolute-center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .full-bleed & {
        width: calc(100vw + 2px); // 2px for Chrome bug where you can sometimes see a gap at the browser edges
    }
    &.hero-media-tablet,
    &.hero-media-desktop,
    &.hero-media-hd {
        display: none;
    }
    @include media-breakpoint-up(md) {
        &.hero-media-mobile
        &.hero-media-desktop {
            display: none;
        }
        &.hero-media-tablet {
            display: block;
        }
    }
    @include media-breakpoint-up(lg) {
        &.hero-media-mobile,
        &.hero-media-tablet {
            display: none;
        }
        &.hero-media-desktop {
            display: block;
        }
    }
    @include media-breakpoint-up(xl) {
        &.hero-media-desktop {
            display: none;
        }
        &.hero-media-hd {
            display: block;
        }
    }
    &.video-container {
        @include absolute-cover;
        overflow: hidden;
        right: auto;
        transform: none;
        transition: opacity $transition-speed ease-out;
        .video-aspect-ratio {
            @include absolute-center;
            width: 0;
            height: 0;
            min-height: 100%;
            min-width: 100%;
            &.aspect-ratio-16-9 {
                padding-top: $aspect-ratio-sixteen-nine;
                padding-left: $aspect-ratio-sixteen-nine-horizontal;
            }
            &.aspect-ratio-9-16 {
                padding-top: $aspect-ratio-nine-sixteen;
                padding-left: $aspect-ratio-nine-sixteen-horizontal;
            }
            &.aspect-ratio-4-3 {
                padding-top: $aspect-ratio-four-three;
                padding-left: $aspect-ratio-four-three-horizontal;
            }
            &.aspect-ratio-3-4 {
                padding-top: $aspect-ratio-three-four;
                padding-left: $aspect-ratio-three-four-horizontal;
            }
            &.aspect-ratio-1-1 {
                padding-top: $aspect-ratio-one-one;
                padding-left: $aspect-ratio-one-one-horizontal;
            }
            .video-player {
                position: static;
                pointer-events: none;
            }
            iframe,
            video {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                &.video-player {
                    position: absolute; // override for youtube, since they use .video-player for iframe
                }
            }
        }
        &~.replay-video {
            z-index: 1;
            position: absolute;
            opacity: 0;
            transition: opacity $transition-speed ease-out;
            .align-items-start &,
            .align-items-center & {
                bottom: $spacer;
            }
            .align-items-end & {
                top: $spacer;
                .transparent-header~div & {
                    top: $header-height + $spacer;
                    @include media-breakpoint-up(md) {
                        top: $header-height-md + $spacer;
                    }
                    @include media-breakpoint-up(lg) {
                        top: $header-height-lg + $spacer;
                    }
                }
            }
            .justify-content-md-start & {
                right: $spacer;
            }
            .justify-content-md-end &,
            .justify-content-md-center & {
                left: $spacer;
            }
        }
        &.video-stopped {
            opacity: 0;
            &~.replay-video {
                transition: opacity $transition-speed ease-out $transition-speed*3;
                opacity: 1;
            }
        }
    }

    .hero-banner-image {
        width: 100%;
    }

}

.hero-text {
    overflow: visible;
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    z-index: $z-index-lv1;

    // text in card layout styles
    &.border-left-white-desktop {
        @include media-breakpoint-up(md) {
            padding-left: 86px;
            border-left: 1px solid $white;
        }
    }

    // text in card layout styles
    .text-in-card & {
        padding: $hero-textblock-inner-padding;
        border-width: $hero-textblock-border-width;
        border-style: solid;
        @include media-breakpoint-up(md) {
            padding: $hero-textblock-inner-padding-md;
        }
        @include media-breakpoint-up(lg) {
            padding: $hero-textblock-inner-padding-lg;
        }
        &:not([class*='border--']) {
            border-color: transparent;
        }
        &.semitransparent:not([class*='background--']) {
            &.dark-theme {
                background: rgba($hero-colortheme-dark, $hero-textblock-background-opacity);
            }
            &.light-theme {
                background: rgba($hero-colortheme-light, $hero-textblock-background-opacity);
            }
        }
        &.opaque:not([class*='background--']) {
            &.dark-theme {
                background: $hero-colortheme-dark;
            }
            &.light-theme {
                background: $hero-colortheme-light;
            }
        }
    }

    // text alignment
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        &.text-center {
            align-items: center; // fix for IE11 not horizontally centering
        }
        &.text-right {
            align-items: flex-end; // fix for IE11 not right aligning
        }
    }

    // text widths
    @include media-breakpoint-up(md) {
        &.width-1-4 {
            max-width: 25%;
        }
        &.width-1-3 {
            max-width: 33.3333%;
        }
        &.width-1-2 {
            max-width: 50%;
        }
        &.width-2-3 {
            max-width: 66.6666%;
        }
        &.width-3-4 {
            max-width: 75%;
        }
        &.width-1 {
            max-width: 100%;
        }
    }


    // text elements
    .hero-subtitle,
    .hero-title,
    .hero-body {
        // override styles from page designer injected tags
        &>* {
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit;
            line-height: inherit;
            text-transform: inherit;
            letter-spacing: inherit;
            color: inherit;
            margin-bottom: 0;
        }
    }

    .hero-body {
        ul:not(.list-style-dash) {
            list-style: disc;
            list-style-position: outside;
            margin-left: 0;
        }

        &.list-style-dash ul {
            list-style: none;
            li::before {
                content: '\2014';
                position: absolute;
                margin-left: -30px;
            }
        }

        @include media-breakpoint-up(sm) {
            ul {
                columns: 1;
            }
        }
    }
    .hero-subtitle + .hero-title,
    .hero-title + .hero-body,
    .hero-embedded-component + .hero-body,
    .hero-body + .hero-buttons .hero-button,
    .hero-embedded-component .experience-region > * {
        margin-top: $hero-textblock-items-vertical-spacing;
    }

    // buttons
    .hero-buttons {
        font-size: 0; // prevent extra space for inline-block elements
    }
    .hero-button {
        margin-top: $spacer;
        display: inline-block;
        position: relative;
    }
    &.text-center .hero-button:not(:only-child) {
        margin-left: $spacer/2;
        margin-right: $spacer/2;
    }
    &.text-left .hero-button:not(:only-child) {
        margin-right: $spacer;
    }
    &.text-right .hero-button:not(:only-child) {
        margin-left: $spacer;
    }

    .megamenu-link-list ul {
        @include remove-list-styling();
        columns: 1;
        li a {
            @include navigation-link;
            display: block;
            padding: $megamenu-asset-link-list-padding;
        }
    }
}

.hero-collapse {
    .hero-collapse-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        border: 1px solid $white;
        border-radius: $photo-hotspot-border-radius;
        box-shadow: $photo-hotspot-shadow;
        transition: all $transition-speed ease-out;
        text-decoration: none;
        @include icon($photo-hotspot-icon, before, $photo-hotspot-icon-size, $photo-hotspot-icon-color);
        bottom: 0;
        right: 0;
        margin-right: 16px;
        margin-bottom: 16px;
        background-color: $white;
        &::before {
            @include absolute-center;
            width: $photo-hotspot-icon-size;
        }
        &:not(.collapsed)::before {
            content: $icon-minus;
        }
    }

    .hero-collapse-inner-content {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: 20px;
        padding: 20px;
    }

    &.dark-theme {
        .hero-collapse-icon {
            background-color: $black;
        }

        .hero-collapse-inner-content {
            background-color: $black;
            color: $white;
        }
    }
}

.hero-slider-container {
    .hero-slot.container {
        padding-left: 0;
        padding-right: 0;
    }
}

// hero half and half layout
.hero.half-and-half {
    @include media-breakpoint-down(sm) {
        &.sm- {
            &short {
                min-height: 0;
                .hero-media {
                    height: $hero-min-height-short;
                }
            }
            &medium {
                min-height: 0;
                .hero-media {
                    height: $hero-min-height-medium;
                }
            }
            &tall {
                min-height: 0;
                .hero-media {
                    height: $hero-min-height-tall;
                }
            }
            &screen-height {
                min-height: calc(100vh - #{$header-height} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                .transparent-header~div & {
                    min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                }
            }
        }

        &.md- {
            @include media-breakpoint-up(md) {
                &short {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-short-md;
                    }
                }
                &medium {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-medium-md;
                    }
                }
                &tall {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-tall-md;
                    }
                }
                &screen-height {
                    min-height: calc(100vh - #{$header-height-md} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    .transparent-header~div & {
                        min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    }
                }
            }
        }

        &.lg- {
            @include media-breakpoint-up(lg) {
                &short {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-short-lg;
                    }
                }
                &medium {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-medium-lg;
                    }
                }
                &tall {
                    min-height: 0;
                    .hero-media {
                        height: $hero-min-height-tall-lg;
                    }
                }
                &screen-height {
                    min-height: calc(100vh - #{$header-height-lg} - #{$header-navbar-border-bottom-width} - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    .transparent-header~div & {
                        min-height: calc(100vh - #{$header-promo-bar-line-height} - #{$header-promo-bar-padding-top} - #{$header-promo-bar-padding-bottom});
                    }
                }
            }
        }
    }

    @include media-breakpoint-down(sm) {
        height: auto;
        .hero-container {
            flex-flow: column;
        }
        .hero-media {
            transform: translateX(50%);
            top: 0;
            position: relative;
            width: calc(100% + #{$hero-textblock-outer-padding * 2});
            margin: (-$hero-textblock-outer-padding) 0 $hero-textblock-outer-padding (-$hero-textblock-outer-padding * 2);
        }
        &.short {
            min-height: 0;
            .hero-media {
                height: $hero-min-height-short;
            }
        }
        &.medium {
            min-height: 0;
            .hero-media {
                height: $hero-min-height-medium;
            }
        }
        &.tall,
        &.screen-height {
            min-height: 0;
            .hero-media {
                height: $hero-min-height-tall;
            }
        }
    }
    @include media-breakpoint-up(md) {
        .hero-media {
            transform: translateY(-50%);
            &.width-1-4 {
                max-width: 75%;
            }
            &.width-1-3 {
                max-width: 66.66666%;
            }
            &.width-1-2 {
                max-width: 50%;
            }
            &.width-2-3 {
                max-width: 33.3333%;
            }
            &.width-3-4 {
                max-width: 25%;
            }
            &.width-1 {
                max-width: 50%;
            }
        }
        .hero-text {
            &.width-1 {
                max-width: 50%;
            }
        }
        .justify-content-md-start {
            .hero-media {
                right: 0;
                left: auto;
            }
            .hero-text {
                padding-right: $hero-textblock-outer-padding;
                @include media-breakpoint-up(md) {
                    padding-right: $hero-textblock-outer-padding-md;
                }
                @include media-breakpoint-up(lg) {
                    padding-right: $hero-textblock-outer-padding-lg;
                }
            }
        }
        .justify-content-md-end {
            .hero-media {
                left: 0;
                right: auto;
            }
            .hero-text {
                padding-left: $hero-textblock-outer-padding;
                @include media-breakpoint-up(md) {
                    padding-left: $hero-textblock-outer-padding-md;
                }
                @include media-breakpoint-up(lg) {
                    padding-left: $hero-textblock-outer-padding-lg;
                }
            }
        }
    }
}

// hide video containers when inside buttons
.experience-component {
    .hero-button,
    .button,
    .image-text-block-button {
        .video-container {
            &::before,
            .video-player {
                display: none ;
            }
        }
    }
}
