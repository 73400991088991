.map-canvas {
    height: 400px;
    width: 100vw;
    margin-left: -16px;

    @include media-breakpoint-up(lg) {
        height: 875px;
        width: auto;
        margin-left: initial;
    }
}

.store-results {
    @include media-breakpoint-up(lg) {
        max-height: 498px;
        overflow-y: scroll;
    }
}

.result-store {
    border-bottom: 1px solid $grey-20;
    padding: 2rem 0;

    .map-marker {
        width: 38px;
        height: 38px;

        span {
            width: 38px;
            transform: translate(50%, -50%);
            top: 50%;
        }
    }

    .store-name {
        text-transform: uppercase;
        font-weight: 700;
        font-family: $tertiary-font;
        text-decoration: none;
        letter-spacing: normal;
        font-size: 16px;
    }
}

.bottom-fade__white {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
    height: 100px;
    background: linear-gradient(0, white, transparent);
    z-index: 99;
    pointer-events: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}
