.image-block {
    display: block;
    position: relative;
    text-align: center;
    text-decoration: none;

    &:hover {
        text-decoration: none;
    }
    
    .image-cropper {
        overflow: hidden;
        margin: auto;
        width: 100%;
        max-width: 100%;
        max-height: 100%;
    }

    .image-wrapper {
        width: 100%;
        position: relative;

        .background-image {
            @include absolute-cover;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: block;
        }

        .background-image-tablet,
        .background-image-desktop,
        .background-image-hd {
            display: none;
        }

        @include media-breakpoint-up(md) {
            .background-image-mobile,
            .background-image-desktop {
                display: none;
            }

            .background-image-tablet {
                display: block;
            }
        }

        @include media-breakpoint-up(lg) {
            .background-image-mobile,
            .background-image-tablet {
                display: none;
            }

            .background-image-desktop {
                display: block;
            }
        }

        @include media-breakpoint-up(xl) {
            .background-image-desktop {
                display: none;
            }

            .background-image-hd {
                display: block;
            }
        }
    }

    .image-block-text {
        text-align: left;
        position: absolute;
        bottom: 0;
        padding: 24px;

        @include media-breakpoint-up(lg) {
            padding: 48px;
        }

        .primary-text {
            // override styles from page designer injected tags
            &>* {
                font-family: inherit;
                font-size: inherit;
                font-weight: inherit;
                line-height: inherit;
                text-transform: inherit;
                letter-spacing: inherit;
                color: inherit;
                margin-bottom: 0;
            }
        }

        .primary-text {
            color: inherit;
            @include header(4);

            &:not(:only-child) {
                margin-bottom: $spacer * 2;
            }
        }

        // re-align container in relation to child button margin-bottom value
        .image-text-block-buttons {
            margin-bottom: -8px;
        }

        .image-text-block-button {
            text-shadow: none;
            font-family: $tertiary-font;
            
            &:first-child {
                margin-right: 8px;
            }

            .btn,
            .btn-primary,
            .btn-secondary {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 1em;
                text-align: center;
                padding-top: 8px;
                padding-bottom: 8px;
                margin-bottom: 8px;
            }

            .btn-secondary {
                background-color: transparent;
            }
        }
    }

    .image-block-image-link {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.aspect-ratio-square .image-wrapper {
        @include aspect-ratio($image-text-block-aspect-ratio-square);
    }

    &.dark-theme {
        .image-wrapper {
            background-color: $black;
        }

        .image-block-text {
            .primary-text {
                color: $white;
            }
        }

        .image-text-block-button {
            .btn-primary {
                background-color: $white;
                border-color: $white;
                color: $black;
            }
            .btn-secondary {
                color: $white;
                border-color: $grey-30;
            }
        }
    }

    &.light-theme {
        .image-block-text {
            .primary-text {
                color: $black;
            }
        }

        .image-text-block-button {
            .btn-primary {
                background-color: $black;
                color: $white;
            }

            .btn-secondary {
                border-color: $black;
            }
        }
    }
}
