// apply paragraph styles, usage: @include paragraph-font-size(large)
@mixin paragraph-font-size($size: normal) {
    letter-spacing: $paragraph-letter-spacing;
    @if $size == normal {
        font-size: $paragraph-font-size;
        line-height: $paragraph-line-height;
        letter-spacing: $paragraph-letter-spacing;
        @include media-breakpoint-up(lg) {
            font-size: $paragraph-md-font-size;
            line-height: $paragraph-line-height;
            letter-spacing: $paragraph-md-letter-spacing;
        }
    }
    @if $size == large {
        font-size: $paragraph-font-size-large;
        font-weight: $paragraph-font-weight-large;
        line-height: $paragraph-line-height-large;
        letter-spacing: $paragraph-letter-spacing-large;
        @include media-breakpoint-up(lg) {
            font-size: $paragraph-md-font-size-large;
            line-height: $paragraph-md-line-height-large;
            letter-spacing: $paragraph-md-letter-spacing-large;
        }
    }
    @if $size == small {
        font-family: $paragraph-font-small;
        font-size: $paragraph-font-size-small;
        line-height: $paragraph-line-height-small;
        letter-spacing: $paragraph-letter-spacing-small;
        @include media-breakpoint-up(lg) {
            font-size: $paragraph-md-font-size-small;
            line-height: $paragraph-md-line-height-small;
            letter-spacing: $paragraph-md-letter-spacing-small;
        }
    }
}

@mixin footer-link-font {
    color: $footer-link-color;
    font-family: $footer-link-font-family;
    font-size: $footer-link-font-size;
    line-height: $footer-link-line-height;
    font-weight: $footer-link-font-weight;
    letter-spacing: $footer-link-letter-spacing;
    text-transform: $footer-link-text-transform;
    font-style: $footer-link-font-style;
}

@mixin checkbox-icon-checked-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    background-color: $radiocheck-background-color-selected;
    border: 1px solid $radiocheck-border-color-selected;
    border-radius: 5px;
}

@mixin checkbox-icon-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border: 1px solid $radiocheck-border-color;
    background-color: $radiocheck-background-color;
    border-radius: 5px;
}

@mixin radio-icon-checked-background {
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border-radius: 50%;
    background-color: $radiocheck-icon-color;
    border: 1px solid $radiocheck-border-color-selected;
}

@mixin round-checkmark {
    width: 25px;
    height: 25px;
    border: 1px solid $grey-20;
    border-radius: 50%;
    display: inline-block;
    position: relative;

    &.active {
        background-color: $black;
        border-color: $black;

        &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 6px;
            border: 2px solid transparent;
            border-left-color: $white;
            border-bottom-color: $white;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -75%) rotate(-45deg);
        }
    }
}

@mixin disabled {
    pointer-events: none;
}

@mixin button($style: "", $customBackground: "", $customText: "", $customBorder: "") {
    display: inline-block;
    position: relative;
    cursor: pointer;
    user-select: none;
    border-width: $button-border-width;
    border-style: solid;
    font-family: $button-font;
    text-transform: $button-text-transform;
    transition: all $transition-speed ease-out;
    text-decoration: none;
    border-radius: $button-border-radius;
    text-align: $button-text-align;
    font-weight: $button-font-weight;
    font-size: $button-font-size;
    letter-spacing: $button-letter-spacing;
    line-height: $button-line-height;
    padding: $button-padding-top $button-padding-right $button-padding-bottom $button-padding-left;
    @if $style == primary {
        font-family: $button-primary-font;
        text-transform: $button-primary-text-transform;
        color: $button-primary-color;
        background-color: $button-primary-background;
        border-width: $button-primary-border-width;
        border-radius: $button-primary-border-radius;
        border-color: $button-primary-border-color;
        text-decoration: $button-primary-text-decoration;
        text-align: $button-primary-text-align;
        font-weight: $button-primary-font-weight;
        padding: $button-primary-padding-top $button-primary-padding-right $button-primary-padding-bottom $button-primary-padding-left;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-primary-color-hover;
            background-color: $button-primary-background-hover;
            border-color: $button-primary-border-color-hover;
            text-decoration: $button-primary-text-decoration-hover;
        }
    }
    @if $style == secondary {
        font-family: $button-secondary-font;
        text-transform: $button-secondary-text-transform;
        color: $button-secondary-color;
        border-width: $button-secondary-border-width;
        border-radius: $button-secondary-border-radius;
        background-color: $button-secondary-background;
        border-color: $button-secondary-border-color;
        text-decoration: $button-secondary-text-decoration;
        text-align: $button-secondary-text-align;
        font-weight: $button-secondary-font-weight;
        padding: $button-secondary-padding-top $button-secondary-padding-right $button-secondary-padding-bottom $button-secondary-padding-left;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-secondary-color-hover;
            background-color: $button-secondary-background-hover;
            border-color: $button-secondary-border-color-hover;
            text-decoration: $button-secondary-text-decoration-hover;
            opacity: $button-secondary-text-opacity-hover;
        }
    }
    @if $style == tertiary {
        font-family: $button-tertiary-font;
        text-transform: $button-tertiary-text-transform;
        color: $button-tertiary-color;
        background-color: $button-tertiary-background;
        border-radius: $button-tertiary-border-radius;
        border-width: $button-tertiary-border-width;
        border-color: $button-tertiary-border-color;
        text-decoration: $button-tertiary-text-decoration;
        text-align: $button-tertiary-text-align;
        font-weight: $button-tertiary-font-weight;
        padding: $button-tertiary-padding-top $button-tertiary-padding-right $button-tertiary-padding-bottom $button-tertiary-padding-left;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-tertiary-color-hover;
            background-color: $button-tertiary-background-hover;
            border-color: $button-tertiary-border-color-hover;
            text-decoration: $button-tertiary-text-decoration-hover;
        }
    }
    @if $style == primarysmall {
        color: $button-sm-primary-color;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        background-color: $button-sm-primary-background;
        border-color: $button-sm-primary-border-color;
        border-radius: $button-sm-primary-border-radius;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-sm-primary-color-hover;
            background-color: $button-sm-primary-background-hover;
            border-color: $button-sm-primary-border-color-hover;
        }
    }
    @if $style == secondarysmall {
        color: $button-sm-secondary-color;
        padding: $button-sm-padding-top $button-sm-padding-right $button-sm-padding-bottom $button-sm-padding-left;
        background-color: $button-sm-secondary-background;
        border-color: $button-sm-secondary-border-color;
        border-radius: $button-sm-secondary-border-radius;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            color: $button-sm-secondary-color-hover;
            background-color: $button-sm-secondary-background-hover;
            border-color: $button-sm-secondary-border-color-hover;
        }
    }
    @if $style == custom {
        background: $customBackground;
        color: $customText;
        border-color: $customBorder;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background: darken($customBackground, 5%);
            border-color: darken($customBorder, 5%);
        }
    }
    @if $style == addtocart {
        color: $button-addtocart-text-color;
        background-color: $button-addtocart-background-color;
        border-color: $button-addtocart-border-color;
        border-radius: $button-addtocart-border-radius;
        border-width: $button-addtocart-border-width;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            background-color: $button-addtocart-background-color-hover;
        }
    }
    @if $style == addtocartdisabled {
        color: $button-addtocart-disabled-text-color;
        background-color: $button-addtocart-disabled-background-color;
        border-color: $button-addtocart-disabled-border-color;
        border-radius: $button-addtocart-border-radius;
        border-width: $button-addtocart-border-width;
        opacity: 1;
    }
    @if $style == viewdetails {
        color: $button-viewdetails-text-color;
        background-color: $button-viewdetails-background-color;
        border-color: $button-viewdetails-border-color;
        border-radius: $button-viewdetails-border-radius;
        border-width: $button-viewdetails-border-width;
        &:hover,
        &:focus,
        &[aria-describedby*="popover"] {
            border-color: $button-viewdetails-border-color-hover;
        }
    }
    @if $style == sliderleft {
        font-family: "icomoon";
        line-height: $button-slider-size;
        border-radius: $button-slider-size;
        width: $button-slider-size;
        height: $button-slider-size;
        padding: 0;
        &::after {
            display: inline-block;
            content: $icon-arrow-left;
            font-size: $button-slider-size / 2;
            text-align: center;
        }
    }
    @if $style == sliderright {
        font-family: "icomoon";
        line-height: $button-slider-size;
        border-radius: $button-slider-size;
        width: $button-slider-size;
        height: $button-slider-size;
        padding: 0;
        &::after {
            display: inline-block;
            content: $icon-arrow-right;
            font-size: $button-slider-size / 2;
            text-align: center;
        }
    }
    @if $style == sliderdisabled {
        font-family: "icomoon";
        line-height: $button-slider-size;
        border-radius: $button-slider-size;
        border-color: $button-slider-border-color-disabled;
        padding: 0;
        &::after {
            color: $button-slider-color-disabled;
            font-size: $button-slider-size / 2;
            text-align: center;
        }
    }

    // If icon class is applied directly to button
    &[class*="icon-"],
    &[class^="icon-"] {
        padding-left: $button-padding-left * 2;
        @if $style == small {
            padding-left: $button-sm-padding-left * 2;
            &:before {
                @include button-icon-styles(before, $button-sm-icon-size, inherit);
            }
        } @else {
            &:before {
                @include button-icon-styles(before, $button-icon-size, inherit);
            }
        }
    }
}

@mixin subtitle($weight: light) {
    font-family: $subtitle-font-family;
    font-size: $subtitle-font-size;
    line-height: $subtitle-line-height;
    letter-spacing: $subtitle-letter-spacing;
    text-transform: $subtitle-text-transform;
    font-style: $subtitle-font-style;
    @include media-breakpoint-up(lg) {
        font-family: $subtitle-lg-font-family;
        font-size: $subtitle-lg-font-size;
        line-height: $subtitle-lg-line-height;
        letter-spacing: $subtitle-lg-letter-spacing;
        text-transform: $subtitle-lg-text-transform;
        font-style: $subtitle-lg-font-style;
    }

    @if $weight == light {
        font-weight: 500;
    }

    @if $weight == heavy {
        font-weight: 700;
    }
}

@mixin secondary-navigation-text {
    font-family: $secondary-font;
    font-size: 11px;
    font-weight: normal;
    font-style: normal;
    line-height: 28px;
    text-transform: normal;
}

@mixin action-text {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
}

@mixin bottom-short-line($color: light) {
    @include media-breakpoint-up(lg) {
        content: "";
        width: 50px;
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        vertical-align: bottom;
        margin-top: 20px;
        @if $color == light {
            background-color: $white;
        }
        @if $color == dark {
            background-color: $black;
        }
    }
}

// Adds a diagonal line through a swatch, half white and half black to increase visibility on different backgrounds
@mixin strike-through-swatch($size, $dark: transparent, $light: $white) {
    &:before,
    &:after {
        content: "";
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%) rotate(45deg);
        height: $size;
        width: 1px;
    }
    &:after {
        background-color: $dark;
    }
    &:before {
        background-color: $light;
        margin-right: 1px;
        box-shadow: 0px 0px 2px rgba(8, 8, 8, 0.5);
        width: 2px;
    }
}

@mixin text-link-light {
    @include text-link;
    color: $grey-30;
}

// generate color classnames, e.g. ".color--red"
@mixin color-classes($attribute) {
    @each $name, $hex in $colors {
        &--#{"" + $name} {
            #{$attribute}: $hex;
        }
    }
}

// apply header styles, usage: @include header(2) // h2 styles
@mixin header($headerIndex) {
    $value: nth($headers, $headerIndex);

    font-family: nth($value, 2);
    font-size: nth($value, 3);
    line-height: nth($value, 4);
    font-weight: nth($value, 5);
    letter-spacing: nth($value, 6);
    text-transform: nth($value, 7);
    font-style: nth($value, 8);

    @include media-breakpoint-up(lg) {
        font-size: nth($value, 9);
        line-height: nth($value, 10);
        font-weight: nth($value, 11);
        letter-spacing: nth($value, 12);
        text-transform: nth($value, 13);
        font-style: nth($value, 14);
    }
}

@mixin clearfix() {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

// This mixin should only be called from within _mixins.scss
@mixin icon-styles($size, $color) {
    font-family: $icomoon-font-family;
    display: inline-block;
    font-size: $size;
    color: $color;
    font-weight: normal;
}

// This mixin should only be called from within _mixins.scss
@mixin button-icon-styles($position, $size, $color) {
    @include icon-styles($size, $color);
    position: absolute;
    @if $position == after {
        $afterIconButtonOffset: 10px;
        transform: translateX($afterIconButtonOffset);
    }
    @if $position == before {
        $beforeIconButtonOffset: $size + 10px;
        transform: translateX(-$beforeIconButtonOffset);
    }
}

// This mixin applies the styles for a set of tabs.
// $tabSelector is used to set which child elements are considered a tab.
// $tabActiveSelector is used to set which tab should receive the active styling.
// $stretchTabs is a boolean that controls whether the tabs are stretched full-width using
// flexbox, or whether they will collapse. If the tabs aren't stretched, a bottom-border and
// negative bottom margin are used to stretch the border full-width.
@mixin tab-container($tabSelector: '.nav-item', $tabActiveSelector: '.active', $stretchTabs: true) {
    width: 100%;
    @if $stretchTabs {
        display: flex;
        flex-wrap: wrap;
    } @else {
        display: block;
        border-bottom: $tab-border-bottom;
    }

    #{$tabSelector} {
        @include tab($tabActiveSelector);

        @if $stretchTabs {
            flex: 1 1 auto;
        } @else {
            margin-bottom: #{-$tab-border-width};

            &#{$tabActiveSelector} {
                background-color: $page-background-color;
            }
        }
    }
}

// This mixin applies the styles for a tab. To apply the styles
// to a set of tabs, use the tab-container mixin above.
@mixin tab($activeSelector: '.active') {
    font: $tab-font;
    text-transform: $tab-text-transform;
    font-weight: $tab-font-weight;
    letter-spacing: $tab-letter-spacing;
    padding-top: $tab-padding-top;
    padding-right: $tab-padding-right;
    padding-bottom: $tab-padding-bottom;
    padding-left: $tab-padding-left;
    text-decoration: $tab-text-decoration;
    background: $tab-background;
    color: $tab-color;
    border-top: $tab-border-top;
    border-right: $tab-border-right;
    border-bottom: $tab-border-bottom;
    border-left: $tab-border-left;
    margin: 0;

    &:hover {
        text-decoration: $tab-text-decoration-hover;
        background: $tab-background-hover;
        color: $tab-color-hover;
        border-top: $tab-border-top-hover;
        border-right: $tab-border-right-hover;
        border-bottom: $tab-border-bottom-hover;
        border-left: $tab-border-left-hover;
    }

    &#{$activeSelector} {
        text-decoration: $tab-text-decoration-active;
        background: $tab-background-active;
        color: $tab-color-active;
        border-top: $tab-border-top-active;
        border-right: $tab-border-right-active;
        border-bottom: $tab-border-bottom-active;
        border-left: $tab-border-left-active;
    }
}

@mixin full-width() {
    display: block;
    width: 100%;
}

@mixin icon($icon, $position: before, $size: inherit, $color: inherit) {
    @if $icon != '' {
        &:#{$position} {
            content: $icon;
            @include icon-styles($size, $color);
        }

        // Handle mixin being applied to button, rather than a span inside it
        // also hides any existing font-awesome icon to avoid template changes
        // does not apply to buttons with a class of 'title', since those are collapsible items
        &[class*="btn-"]:not(.title) {
            &:#{$position} {
                @include button-icon-styles($position, $button-icon-size, $color);
            }
            @if $position == before {
                padding-left: $button-padding-left * 2;
            }
            @if $position == after {
                padding-right: $button-padding-right * 2;
            }
            &.btn-sm {
                &:#{$position} {
                    @include button-icon-styles($position, $button-sm-icon-size, $color);
                }
                @if $position == before {
                    padding-left: $button-sm-padding-left * 2;
                }
                @if $position == after {
                    padding-right: $button-sm-padding-right * 2;
                }
            }
            [class*="icon-"],
            [class^="icon-"],
            .fa {
                display: none;
            }
        }
    }
}

// vertically center the element inside of its first non-static parent
@mixin vertical-center {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

// horizontally center the element inside of its first non-static parent
@mixin horizontal-center {
    position: absolute;
    right: 50%;
    transform: translateX(50%);
}

// vertically and horizontally centers the element inside of its first non-static parent
@mixin absolute-center {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
}

// position absolutely on top of container, using the container's dimensions
// container needs to be position relative or absolute
@mixin absolute-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// apply to body in order to prevent background scrolling on mobile
@mixin lock-scroll {
    overflow: hidden;
    top: 0;
    width: 100%;
    height: 100vh;
}

@mixin delete-card-button($absolute: true) {
    @include icon($product-card-remove-icon, before, $product-card-remove-icon-size);
    font-family: $body-font;
    border: none;
    border-radius: 50%;
    font-size: 0;
    @if $absolute {
        position: absolute;
        top: 0;
        right: 0;
        margin: $card-body-padding-top $card-body-padding-right $card-body-padding-bottom $card-body-padding-left;
    }
    cursor: pointer;
    z-index: 1;
    background-color: $product-card-remove-icon-background;
    padding: 4px 6px;
    height: 22px;
    width: 22px;
}

@mixin text-link {
    color: $link-color;
    font-weight: $link-font-weight;
    text-decoration: $link-text-decoration;
    cursor: pointer;
    &:hover {
        color: $link-color-hover;
        text-decoration: $link-text-decoration-hover;
    }
    .dark-theme & {
        color: $link-dark-theme-color;
        &:hover {
            color: $link-dark-theme-color-hover;
        }
    }
}

@mixin label-styles($label-in-form: true) {
    font-family: $form-label-font;
    font-weight: $form-label-font-weight;
    font-size: $text-label-font-size;
    line-height: $form-label-line-height;
    margin-bottom: $text-label-margin-bottom;
    text-transform: $form-label-text-transform;
    letter-spacing: $form-label-letter-spacing;
    
    @if $label-in-form == false {
        opacity: 1;
    }

    @if $label-in-form == true {
        font-size: $form-label-font-size;
        display: inline-block;
        margin: 0 0 0 1px; // Account for input border
        padding: $input-with-text-label-padding-top $input-padding-right $input-with-text-label-padding-bottom $input-padding-left;
        position: absolute;
        opacity: 0;
        transition: opacity $transition-speed ease-out;
        &:has(~ .form-control:not(:placeholder-shown)),
        &:has(~ .form-control:autofill:placeholder-shown) {
            opacity: 1;
        }
        &:has(~ textarea.form-control) {
            padding-top: $input-label-padding-top;
        }
    }
}

@mixin modal-size($size: "") {
    max-width: none;
    margin: $modal-margins-mobile;
    @if $size == "" or $size == sm {
        max-width: $modal-max-width;
        @include media-breakpoint-up(sm) {
            margin: $modal-margins-desktop;
        }
    }
    @if $size == sm {
        @include media-breakpoint-up(sm) {
            max-width: $modal-sm-max-width;
        }
    }
    @if $size == lg or $size == xl {
        @include media-breakpoint-up(lg) {
            max-width: $modal-lg-max-width;
            margin: $modal-margins-desktop;
        }
    }
    @if $size == xl {
        @include media-breakpoint-up(xl) {
            max-width: $modal-xl-max-width;
        }
    }
}

@mixin toast-message() {
    @include horizontal-center;
    position: fixed;
    top: 0;
    padding: 50px $spacer $spacer;
    max-height: calc(100vh - 50px);
    overflow-y: auto;
    z-index: $z-index-toast;
}

@mixin toast-alert() {
    box-shadow: $toast-box-shadow;
    text-align: center;

    &.show {
        display: block;
    }
    &:not(.alert-dismissible) {
        animation: fadeIn $transition-speed, fadeOut $transition-speed linear calc(5s - #{$transition-speed});
    }
    &.alert-dismissible {
        animation: fadeIn $transition-speed;
        padding-right: 50px;
        .close {
            padding: $spacer;
        }
    }
}

// This mixin applies the form label styles.
@mixin form-label {
    color: $form-label-color;
    font-family: $form-label-font;
    font-weight: $form-label-font-weight;
    font-size: $form-label-font-size;
    margin-bottom: $form-label-margin-bottom;
    text-transform: $form-label-text-transform;
    letter-spacing: $form-label-letter-spacing;
}

// This mixin applies the text input styles.
@mixin form-input {
    border-color: $input-border-color;
    background-color: $input-background-color;
    outline: $input-outline;
    box-shadow: $input-box-shadow;
    color: $input-text-color;
    border-width: $input-border-width;
    border-radius: $input-border-radius;
    font-family: $input-font;
    font-size: $input-font-size;
    line-height: $input-font-line-height;
    letter-spacing: $input-font-letter-spacing;
    padding-top: $input-padding-top;
    padding-bottom: $input-padding-bottom;
    padding-left: $input-padding-left;
    padding-right: $input-padding-right;
    height: $input-height;
    outline: 0;

    @include media-breakpoint-up(md) {
        font-size: $input-font-size-md;
    }
    &::placeholder {
        color: $input-placeholder-color;
    }

    &:hover {
        border-color: $input-border-color-hover;
        outline: $input-outline-hover;
        box-shadow: $input-box-shadow-hover;
    }

    &:focus {
        border-color: $input-border-color-focus;
        outline: $input-outline-focus;
        box-shadow: $input-box-shadow-focus;
    }
}

@mixin checkbox-icon-checked {
    content: $icon-check;
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    line-height: calc(#{$radiocheck-button-size} + 2px);
    background: none;
    font-family: $icomoon-font-family;
    font-size: $radiocheck-button-size/1.5;
    text-align: center;
    color: $radiocheck-icon-color;
}

@mixin radio-icon-background {
    content: "";
    width: $radiocheck-button-size;
    height: $radiocheck-button-size;
    border: 1px solid $radiocheck-border-color;
    border-radius: 50%;
    background-color: $radiocheck-background-color;
}

@mixin radio-icon-checked {
    content: "";
    background: none;
    width: $radiocheck-button-size - $radiobutton-selected-inset-amount;
    height: $radiocheck-button-size - $radiobutton-selected-inset-amount;
    top: calc(#{$radiobutton-selected-inset-amount}/ 2);
    left: calc(-#{$radiocheck-button-size + $radiocheck-button-margin} + #{$radiobutton-selected-inset-amount}/ 2);
    background-color: $radiocheck-background-color-selected;
    border-radius: 50%;
}

@mixin radio-check-label {
    font-family: $radiocheck-label-font;
    font-weight: $radiocheck-label-font-weight;
    font-size: $radiocheck-label-font-size;
    text-transform: $radiocheck-label-text-transform;
    letter-spacing: $radiocheck-label-letter-spacing;
    vertical-align: middle;
    transition: box-shadow $transition-speed ease-out;
    &:hover:before {
        box-shadow: 0 0 0 1px $radiocheck-border-color;
    }
}

@mixin vertical-slider-thumbnails {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .tns-outer,
    .tns-ovh {
        max-height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
    }
    .tns-inner {
        width: calc(100% + 50px);
        margin-right: -50px !important; // hide scrollbars
        overflow: auto;
        max-height: 100%;
    }
    .slider {
        padding-bottom: 0;
        margin-bottom: 0;
        width: 100% !important;
    }
    .slide {
        display: block;
        width: 100% !important;
        padding-right: 0 !important;
        &+.slide {
            margin-top: $slider-thumbnail-spacing;
        }
    }
    .slider-thumbnail-controls {
        .prev,
        .next {
            left: 0;
            text-align: center;
            height: $slider-thumbnail-button-size;
            width: $slider-thumbnail-width;
            @include media-breakpoint-up(md) {
                width: $slider-thumbnail-width-md;
            }
            @include media-breakpoint-up(lg) {
                width: $slider-thumbnail-width-lg;
            }
            &::before {
                transform: rotate(90deg);
                top: auto;
                bottom: 0;
                left: calc(50% - #{$slider-thumbnail-button-icon-size/2});
                width: $slider-thumbnail-button-size;
                height: $slider-thumbnail-button-icon-size;
            }
        }
        .prev {
            top: 0;
        }
        .next {
            top: auto;
            bottom: 0;
        }
    }
}

@mixin header-link {
    font-family: $header-link-font;
    text-decoration: none;
    text-transform: $header-link-text-transform;
    font-size: $header-link-font-size;
    font-weight: $header-link-font-weight;
    line-height: $header-link-line-height;
    letter-spacing: $header-link-letter-spacing;
    &:hover {
        text-decoration: none;
    }
}

@mixin visually-hidden {
    opacity: 0;
    visibility: hidden;
    z-index: -1;
    height: 1px;
    width: 1px;
}

// Used to visually connect left and right values. Apply mixin to container, which needs to include:
// a .start-lines div with a span inside it, and an .end-lines div with a span inside it
@mixin leading-lines($backgroundColor: $card-body-background, $size: $leading-lines-border-width, $color: $leading-lines-border-color) {
    overflow: hidden;
    margin: 0 0 $spacer;
    label {
        background-color: $backgroundColor;
    }
    p {
        margin-bottom: 0;
    }
    .start-lines {
        padding: 0;
        align-self: center;
        span {
            position: relative;
            background-color: $backgroundColor;
            z-index: 2;
            display: inline-block;
            &:only-child,
            &:last-child {
                padding-right: $spacer;
            }
        }
        &::before {
            @include vertical-center;
            width: 100vw;
            white-space: nowrap;
            content: "";
            border-bottom: $size dotted $color;
            margin-top: 1px;
            z-index: 1;
        }
    }
    .end-lines {
        padding: 0;
        align-self: center;
        span {
            position: relative;
            background-color: $backgroundColor;
            z-index: 2;
            display: inline-block;
            &:only-child,
            &:last-child {
                padding-left: $spacer;
                padding-right: $spacer;
                margin-right: -$spacer;
            }
        }
    }
}

// Use to zoom an image when you hover its container. Pass in the selector of the
// element that should be zoomed, e.g. '.background-image' and the amount to zoom it, e.g. 1.2
@mixin zoom-image($imageSelector, $zoomAmount) {
    @include media-breakpoint-up(md) {
        #{$imageSelector} {
            will-change: transform;
            transition: transform $transition-speed ease-out;
        }
        &:hover {
            #{$imageSelector} {
                transform: scale($zoomAmount);
            }
        }
    }
}

// Apply to a container to give it a forced aspect ratio by passing in a variable, e.g. $aspect-ratio-square
@mixin aspect-ratio($ratio) {
    @include clearfix;
    &::before {
        content: "";
        display: block;
        width: 1px;
        margin-left: -1px;
        float: left;
        height: 0;
        padding-top: $ratio;
    }
}

// Navigation links
@mixin navigation-link {
    font-family: $nav-link-font-family;
    text-decoration: $nav-link-text-decoration;
    text-transform: $nav-link-text-transform;
    font-size: $nav-link-font-size;
    font-weight: $nav-link-font-weight;
    line-height: $nav-link-line-height;
    letter-spacing: $nav-link-letter-spacing;

    &:hover {
        text-decoration: $nav-link-text-decoration-hover;
    }

    @include media-breakpoint-up(md) {
        font-size: $nav-link-font-size-lg;
    }
}

// Remove default list styling
@mixin remove-list-styling() {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        padding: 0;
    }
}

// Set refinements display of swatches to a vertical list or a grid
@mixin refinement-display($type: 'vertical', $padding: $product-refinement-value-grid-padding-bottom) {
    @if $type == 'vertical' {
        flex-basis: 50%;
    }
    @if $type == 'grid' {
        padding-bottom: $padding;
    }
}

@mixin shortComponentAnimations($class, $easing) {
    @if $class == hinge-drop            { perspective: 1000px; }
    @if $class == flip-in-hor-top       { perspective: 2000px; }
    @if $class == flip-in-hor-bottom    { perspective: 2000px; } 
    > [style*="animation-delay"],
    > .container > .row > [style*="animation-delay"] {
        @if $class == hinge-drop { //this animation needs a bit longer to feel smoother
            animation: #{$class}-short $hero-transition-speed-short*2 $easing;
            transform-origin: 0px 0px 0px;
        }
        @else {
            animation: #{$class}-short $hero-transition-speed-short $easing;
        }
    }
}

@mixin mediumComponentAnimations($class, $easing) {
    @if $class == hinge-drop            { perspective: 750px; }
    @if $class == flip-in-hor-top       { perspective: 2000px; }
    @if $class == flip-in-hor-bottom    { perspective: 2000px; } 
    > [style*="animation-delay"],
    > .container > .row > [style*="animation-delay"] {
        @if $class == hinge-drop {
            animation: #{$class}-medium $hero-transition-speed-medium*2 $easing;
            transform-origin: 0px 0px 0px;
        }
        @else {
            animation: #{$class}-medium $hero-transition-speed-medium $easing;
        }
    }
}

@mixin longComponentAnimations($class, $easing) {
    @if $class == hinge-drop            { perspective: 500px; }
    @if $class == flip-in-hor-top       { perspective: 2000px; }
    @if $class == flip-in-hor-bottom    { perspective: 2000px; } 
    > [style*="animation-delay"],
    > .container > .row > [style*="animation-delay"] {
        @if $class == hinge-drop {
            animation: #{$class}-long $hero-transition-speed-long*2 $easing;
            transform-origin: 0px 0px 0px;
        }
        @else {
            animation: #{$class}-long $hero-transition-speed-long $easing;
        }
    }
}

@mixin unsetComponentAnimations($class, $easing) {
    opacity: 0;
    > [style*="animation-delay"],
    > .container > .row > [style*="animation-delay"] {
        opacity: 0; // this is overwritten by the animation after it fires
        animation: unset;
    }
}

@mixin resetComponentAnimations($class, $easing) {
    &.#{$class}--short {
        animation: #{$class}-short $hero-transition-speed-short $easing;
        > [style*="animation-delay"],
        > .container > .row > [style*="animation-delay"] {
            animation: #{$class}-short $hero-transition-speed-short $easing;
        }
    }
    &.#{$class}--medium {
        animation: #{$class}-medium $hero-transition-speed-medium $easing;
        > [style*="animation-delay"],
        > .container > .row > [style*="animation-delay"] {
            animation: #{$class}-medium $hero-transition-speed-medium $easing;
        }
    }
    &.#{$class}--long {
        animation: #{$class}-long $hero-transition-speed-long $easing;
        > [style*="animation-delay"],
        > .container > .row > [style*="animation-delay"] {
            animation: #{$class}-long $hero-transition-speed-long $easing;
        }
    }
}

// apply colors on .price-discount, usage @price-discount($red, $red, $white);
@mixin price-discount($border-color: $red, $background-color: $red, $text-color: $white) {
    border: 1px solid $border-color;
    background-color: $background-color;
    color: $text-color;
}

@mixin close-button($background-color: $black, $text-color: $white) {
    // Circle Container
    display: flex;
    width: 24px;
    height: 24px;
    background-color: $background-color;
    border-radius: 50%;
    padding: 0;
    margin: 0;
    border: 0;
    align-self: center;
    opacity: 1;
    text-shadow: none;

    // Hide container when hidden
    &[aria-hidden=true] {
        display: none;
    }
    
    // X Button Styling
    @include icon($icon-close, before, 12px, $text-color);
    &::before {
        height: inherit;
        width: inherit;
        align-content: center;
        text-align: center;
        line-height: 24px;
    }

    // Animate opacity on hover/focus
    &:not(:disabled):not(.disabled):hover,
    &:not(:disabled):not(.disabled):focus {
        opacity: 0.75;
        color: $text-color;
    }
}

@mixin gradient-border($border1: $brand-01, $border2: $brand-03, $background: $white, $thickness: 3px, $radius: 8px) {
    background: $background;
    position: relative;
    border-radius: calc(#{$radius} - #{$thickness});

    &::after {
        content: '';
        position: absolute;
        top: calc(-1 * #{$thickness});
        left: calc(-1 * #{$thickness});
        height: calc(100% + (#{$thickness} * 2));
        width: calc(100% + (#{$thickness} * 2));
        background: linear-gradient(60deg, $border1, $border2, $border1);
        border-radius: $radius;
        z-index: -1;
        animation: animatedgradient 3s linear infinite;
        background-size: 300% 300%;
    }

    @keyframes animatedgradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
}