.mt-6 {
    margin-top: 56px!important;
}

.mb-6 {
    margin-bottom: 56px!important;
}

.mb-7 {
    margin-bottom: 72px!important;
}

@include media-breakpoint-up(lg) {
    .px-lg-6 {
        padding-left: 56px!important;
        padding-right: 56px!important;
    }

    .mt-lg-6 {
        margin-top: 56px!important;
    }

    .mb-lg-6 {
        margin-bottom: 56px!important;
    }

    .mt-lg-7 {
        margin-top: 64px!important;
    }

    .mt-lg-8 {
        margin-top: 72px!important;
    }

    .mb-lg-8 {
        margin-bottom: 72px!important;
    }
}
