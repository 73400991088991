.countdown-timer {
    background-color: $brand-01;
    color: $white;

    a {
        color: $white;
    }

    .countdown-callout,
    .countdown-divider,
    .countdown {
        @include media-breakpoint-down(md){
            flex-basis: 100%;
        }
    }

    .countdown-container {
        @include media-breakpoint-down(md){
            text-align: center;
        }
        display: flex;
        flex-wrap: wrap;
        opacity: 0;
    }

    .countdown-callout,
    .countdown {
        padding-top: $spacer;
        padding-bottom: $spacer;
    }

    .countdown-callout {
        @include paragraph-font-size(normal);
        align-content: center;
    }

    .countdown {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer / 2;
        justify-content: center;
        align-items: center;
    }

    .time-shown {
        opacity: 1;
    }

    [class^="countdown-value-"] {
        @include paragraph-font-size(large);
        font-weight: bold;
    }


    [class^="countdown-label-"] {
        @include paragraph-font-size(normal);
    }

    .countdown-divider {
        @include media-breakpoint-down(md){
            border-top: 1px solid $brand-02;
        }
        @include media-breakpoint-up(lg){
            border-left: 1px solid $brand-02;
            margin: 0 ($spacer * 2);
        }
    }
}