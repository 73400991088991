// returns.scss
.returns-progress-container {
    padding-bottom: 10px;
}

.returns-progress {
    text-align: center;
    height: 50px;
}

.return-progress-next {
    clear: both;
}

.progress-rectangle {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: #eee;
    padding-top: 12px;
    border-radius: 10px 0px 0px 10px;

    &.active {
        background-color: $brand-01;
        color: #fff;
    }

    &.final {
        border-radius: 10px 10px 10px 10px;
    }
}

.progress-triangle {
    width: 0px;
    height: 0px;
    display:inline;
    border-style: solid;
    border-width: 25px 0 25px 15px;
    border-color: transparent transparent transparent #eee;
    position: absolute;

    &.active {
        border-color: transparent transparent transparent $brand-01;
    }

    // Safari only positioning
    @media not all and (min-resolution:.001dpcm)
    { @supports (-webkit-appearance:none) {
        top: 0;
        right: 1px;
    }}
}

.nearest-loc-address {
    padding-top: 15px;
}

.return-map-container {
    margin-top: 20px;
}

.show-more-locations {
    margin-top: 20px;
}

.location-address {
    border: solid 1px $brand-01;
    padding: 10px;
    margin-bottom: 10px;
}

.more-address-results {
    margin-top: 30px;
}

.label-image {
    img {
        width: 100%;
        border: solid 1px;
        margin-bottom: 20px;
    }
}

.sub-reasons {
    margin-top: 20px;
    margin-bottom: 20px;
}

.nearest-location, .location-progress {
    margin-top: 20px;
}

.previous-label {
    margin-top: 20px;
}

.js-select-return-items {
    .line-item-header{
        .remove-line-item.d-lg-none {
            display: none !important;
        }
    }
}

.line-item-disabled {
    * {
        color: rgba(190, 190, 190, 0.849) !important;
    }
}

.label-image {
    .sample-label-image {
        width: 100px;
    }
}

.returns-progress-container {
    padding-bottom: 10px;
}

.returns-progress {
    text-align: center;
    height: 50px;
}

.return-progress-next {
    clear: both;
}

.progress-rectangle {
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: #eee;
    padding-top: 12px;
    border-radius: 10px 0px 0px 10px;

    &.active {
        background-color: $brand-01;
        color: #fff;
    }

    &.final {
        border-radius: 10px 10px 10px 10px;
    }
}

.progress-triangle {
    width: 0px;
    height: 0px;
    display:inline;
    border-style: solid;
    border-width: 25px 0 25px 15px;
    border-color: transparent transparent transparent #eee;
    position: absolute;

    &.active {
        border-color: transparent transparent transparent $brand-01;
    }
}

.nearest-loc-address {
    padding-top: 15px;
}

.return-map-container {
    margin-top: 20px;
}

.show-more-locations {
    margin-top: 20px;
}

.location-address {
    border: solid 1px $brand-01;
    padding: 10px;
    margin-bottom: 10px;
}

.more-address-results {
    margin-top: 30px;
}

.label-image {
    img {
        width: 100%;
        border: solid 1px;
        margin-bottom: 20px;
    }
}

.sub-reasons {
    margin-top: 20px;
    margin-bottom: 20px;
}

.nearest-location, .location-progress {
    margin-top: 20px;
}

.previous-label {
    margin-top: 20px;
}

.js-select-return-items {
    .line-item-header{
        .remove-line-item.d-lg-none {
            display: none !important;
        }
    }
}

.line-item-disabled {
    * {
        color: rgba(190, 190, 190, 0.849) !important;
    }
}

.label-image {
    .sample-label-image {
        width: 100px;
    }
}

// checkmarks.scss
.track-checkmarks {
    ul.checkmark {
        position: relative;
        display: flex;
        max-width: 1000px;
        z-index: 1;
        padding-inline-start: 0px;
        margin-bottom: 1.25rem;
        font-size: 0;

        li {
            width: auto;
            height: 3em;
            line-height: 3em;
            margin: 0;
            border-radius: 0;
            background: transparent;
            color: #999;
            vertical-align: top;
            position: relative;
            display: inline-block;
            font-size: 12px;
            flex-grow: 1;
            text-align: right;
        

            .title {
                display: inline-block;
                font-weight: normal;
                color: #999;
                line-height: normal;
                position: absolute;
                bottom: auto;
                top: 120%;
                transform: translateX(-50%);
                width: auto;
                margin-top: 0px;
                margin-left: 0px;
                font-size: 11px;
                letter-spacing: normal;

                white-space: nowrap;
            }

            span {
                width: 3em;
                height: 3em;
                text-align: center;
                line-height: 3em;
                background: #ebebeb;
                border: 2px solid #cecece;
                border-radius: 50%;
                margin: 0;
                display: inline-block;
                color: white;
                position: relative;
            }

            &:before {
                display: block;
            }

            &:after {
                content: none;
            }

            &:first-child {
                width: 3em;
                flex-grow: 0;

                &:before {
                    content: none;
                }
            }

            &:not(:first-child) {
                &:before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 100%;
                    height: .2em;
                    background: #cecece;
                    z-index: -1;
                }

                &.active {
                    &:before {
                        background: $brand-01;
                    }
                }
            }
                
            &.active {
                span {
                    background-color: $brand-01;
                    border-color: $brand-01;

                    &:after {
                        content: " ";
                        display: block;
                        width: .4em;
                        height: .8em;
                        border: solid #fff;
                        border-width: 0 .1em .1em 0;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -.2em;
                        transform: translateX(-50%) translateY(-50%) rotate(45deg);
                    }
                }
            }
        }
    }
}

.action-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-breakpoint-up(md) {
        flex-direction: row;
    }

    .action-links {
        width: 100%;
        @include media-breakpoint-up(md) {
            width: 250px;
        }
    }
}

.card {
    .returns-body {
        &.card-body {
            padding-bottom: 0;

            &:last-child {
                border: none;
            }

            .return-progress-next {
                .btn-primary {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.card-tracking { 
    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0 12px;

        @include media-breakpoint-up(lg) {
            padding-top: 20px;
        }

        > h2.pull-left {
            padding-left: 0;
            padding-top: 0;
            padding-bottom: 0;
        }
    
        > .pull-right {
            display: flex;
            align-items: center;
            padding-right: 0;
            padding-top: 0;
            padding-bottom: 0;

            > img {
                margin-left: 8px;
            }
        }
    }

    .card-body {
        padding: 0 12px;
    }

    .track-checkmarks {
        padding-top: 0;
    }
}

.card-tracking-travelhistory {
    border-top: 1px solid $card-header-border-color;
}

.trackingpage {
    padding-top: 16px;

    .track-status-top {
        font-size: 12px;
        line-height: 1.1;
    }

    .card-product-list {
        .card {
            margin-bottom: -1px;
        }
    }
}

.track-status-conainer {
    margin-left: 0;
    @include media-breakpoint-up(sm) {
        margin-left: 120px;
    }
    @include media-breakpoint-up(md) {
        margin-left: 0;
    }
    @include media-breakpoint-up(lg) {
        margin-left: 120px;
    }
}