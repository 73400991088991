@import "~core/components/menu";

// Megamenu
.navbar {
    align-items: flex-start;
}

.navbar-nav .megamenu {
    @include media-breakpoint-up(lg) {
        &>.dropdown-menu {
            justify-content: center;

            .left-nav {
                padding-left: 48px;
                margin-right: 65px;

                li {
                    border-top: none;
                    padding: $spacer-sm*2 0;
                    &:hover {
                        background: unset;
                    }
                }
                a {
                    padding: 0 15px;
                    font-size: $megamenu-left-nav-font-size;
                    text-transform: $megamenu-left-nav-text-transform !important;
                    border-right: none;
                    margin-right: -#{$megamenu-left-nav-border-width}; // negative so the anchor border overlaps the li border
                    &.active {
                        color: $megamenu-left-nav-border-color-hover;
                        border-right: none;
                        span {
                            border-bottom: $megamenu-left-nav-border-width solid $megamenu-left-nav-border-color-hover;
                           
                        }
                    }
                }
            }
        }

        .menu-subcategories.mega-left {
            flex: 1 1 calc((100% - 15%)/2);

            .nav-link {
                font-weight: $megamenu-header-font-weight;
            }

            .dropdown-link {
                padding: $megamenu-link-padding-top $megamenu-link-padding-right $megamenu-link-padding-bottom 0;
                &:not(.mega-left-title) {
                    line-height: 28px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

            .dropdown-menu {
                display: flex;
                flex-flow: row wrap;
                align-content: flex-start;

                .dropdown-item {
                    flex-basis: 100%;
                    &.last-item {
                        display: block;
                        order: 1000; // pulls the "all (category)" to the bottom
                        padding-top: $spacer;
                        a {
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                        &:before {
                            content: '';
                            position: absolute;
                            border-top: 1px solid $grey-20;
                            width: $spacer*3;
                            left: $spacer;
                        }
                    }
                    + .dropdown-item.custom-dropdown { //hides any styling on deeper "all (category)" links
                        .dropdown-item.last-item {
                            padding-top: unset;
                            a {
                                font-weight: $megamenu-secondary-font-weight;
                                text-transform: $megamenu-secondary-text-transform;
                            }
                            &:before {
                                display: none; 
                            }
                        }
                        + .dropdown-item.custom-dropdown {
                            .dropdown-item.last-item {
                                padding-top: unset;
                                &:before { 
                                    display: none; 
                                }

                                a {
                                    font-weight: $megamenu-secondary-font-weight;
                                    text-transform: $megamenu-secondary-text-transform;
                                }
                            }
                        } 
                    } 
                }

                .dropdown-link {
                    &.mega-left-title {
                        line-height: 34px;
                    }
                    &:not(.mega-left-title) {
                        padding: 0;
                    }
                }

                .dropdown-menu{
                    flex-flow: column;
                }
            }
            &.mega-left {
                flex: 1 1 auto;
                &.has-sub-asset{
                    + .megamenu-asset-1{
                        display: none; //hide the top level category asset when theres a third level
                    }
                }
                > .dropdown-item { // hide all the tertiary navs until one is active
                    display: none;
                    &.active-list {
                        display: flex;
                    }
                }
            }
        }

        .mega-left {
            list-style: none;
            
            > .dropdown-item { // hide all the tertiary navs until one is active
                display: none;
                &.active-list {
                    display: flex;
                }
            }

            &.show::before {
                border-bottom: 1px solid $grey-20;
            }

            .active-list {
                & + [class*="sub-asset-"] {
                    @include media-breakpoint-up(lg) {
                        display: flex;
                        padding: 0;
                        flex: 0 0 50%;
                    }
                }
            }
            
            .sub-asset-1,
            .sub-asset-2 {
                display: none;
                padding-top: 0;
                padding-right: 0;
                padding-bottom: 0;
                list-style: none;

                .page-designer {
                    flex: 0 0 100%;
                    padding-right: $spacer;
                }
            }

            &.has-asset-1,
            &.has-asset-2 {
                .megamenu-asset-1,
                .megamenu-asset-2 {
                    @include media-breakpoint-up(lg) {
                        flex: 1 1 calc((100% - #{$megamenu-left-nav-width})/2);
                        padding: $megamenu-padding-top-bottom $spacer $megamenu-padding-top-bottom 0;
                    }
                }
            }
            &.has-asset-1.has-asset-2 {
                .megamenu-asset-1,
                .megamenu-asset-2 {
                    @include media-breakpoint-up(lg) {
                        flex: 1 1 calc((100% - #{$megamenu-left-nav-width})/4);
                    }
                }
            }
        }

        .fourth-level-menu:not(:last-child) {
            padding-right: 32px;
        }
    }

    .has-asset-1{
        .megamenu-asset-1 {
            padding: $megamenu-padding-top-bottom $spacer;
            border-top: $dropdown-menu-border;
            list-style: none;
            @include media-breakpoint-up(lg) {
                padding: $megamenu-padding-top-bottom $spacer-xl;
                margin: 0 auto;
                padding-left: $spacer;
                border-top: none;
                border-right: $dropdown-megamenu-border;
                flex-basis: 25%;
            }
        }
        &.mega-left {
            .megamenu-asset-1 {
                @include media-breakpoint-up(lg) {
                    flex-basis: 50%;
                }
            }
        }
    }

    .has-asset-2 .megamenu-asset-2 {
        list-style: none;
        padding: $megamenu-padding-top-bottom $spacer;
        @include media-breakpoint-up(lg) {
            padding: $megamenu-padding-top-bottom $spacer-xl;
            width: initial;
            flex-basis: 25%;
        }
    }

    // Megamenu subcategory columns, dependent on presence of populated content slots
    @include media-breakpoint-up(lg) {
        // 3 column if single asset
        .has-asset-1 .menu-subcategories,
        .has-asset-2 .menu-subcategories {
            flex-basis: 50%;
        }

        // 2 column if both assets
        .has-asset-1.has-asset-2 .menu-subcategories>.dropdown-item {
            flex-basis: 100%;
        }
    }

    .featured-link-container,
    .product-help-container {
        max-width: 100%;

        & > .container {
            align-items: flex-start;
        }

        @include media-breakpoint-up(lg) {
            max-width: 100vw;
        }

        a {
            text-decoration: none;
        }
    }

    .featured-link-container {
        border-top: 1px solid #dfdfdf;
        padding: 18px $spacer-xl;
        @include media-breakpoint-down(md) {
            background: $secondary-02;
        }
        @include media-breakpoint-up(lg) {
            padding: 18px 0;
        }

        .featured-link {
            position: relative;
            @include media-breakpoint-down(md) {
                text-align: left;
                width: 100%;
            }
            & a {
                padding-right: 30px;

                @include icon($icon-arrow-right, after, 16px);

                &::after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .product-help-container {
        border-top: 1px solid #dfdfdf;
        background-color: $grey-10;
        padding: $spacer-xl;

        @include media-breakpoint-down(md) {
            flex-grow: 1;
            padding-bottom: 80px;
        }

        @include media-breakpoint-up(lg) {
            padding: $spacer-xl 0;
        }

        .product-help-header {
            margin-bottom: 20px;
        }

        button.product-help-link {
            display: block;
            margin-bottom: 16px;
            text-align: left;

            @include media-breakpoint-up(lg) {
                display: inline-block;
                border: 1px solid #cdcdcd;
                border-radius: 5px;
                margin-right: $spacer;
                margin-bottom: $spacer;
                padding: 4px $spacer;
                background-color: $white;
            }
        }
    }
}

.multilevel-dropdown .last-item {
    display: block;
}

    // Mobile Only
.main-menu.menu-toggleable-left {
    @include media-breakpoint-down(xs) {
        width: calc(100% - 42px);
    }

    .last-item {
        @include media-breakpoint-down(md) {
            &::before {
                content: '';
                position: absolute;
                border-top: 1px solid $grey-20;
                width: $spacer*3;
                left: $spacer;
            }
        }
    }
    

    ul.dropdown-menu {
        @include media-breakpoint-down(md) {
            height: 100%;
            overflow: visible;

            .last-item {
                order: 1000;
            }

            .third-level-menu {
                display: flex;
                flex-direction: column;
            }

            .third-level-menu-asset-group {
                order: 1001;
                padding-left: 0;

                .third-level-menu-asset {
                    max-width: $header-suggestions-results-width;
                }
            }
            .dropdown-header {
                @include icon($dropdown-icon, after, $dropdown-icon-size, inherit);
                &:after {
                    border: none;
                    vertical-align: top;
                    margin-left: 5px;
                    position: relative;
                }
            }
        }
    }

    .mega-left.thirdCatOpen {
        .dropdown-item.show {
            [class*="sub-asset-"] {
                @include media-breakpoint-down(md) {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    .nav-item .nav-link,
    .dropdown-item .dropdown-link:not(.shop-all-category-link) {
        @include media-breakpoint-down(md) {
            display: flex;
            justify-content: space-between;
        }
    }

    .top-category .mega-left-title {
        @include navigation-link;

        @include media-breakpoint-down(md) {
            margin-top: $spacer;
            margin-bottom: 20px;
        }
    }

    @include media-breakpoint-down(md) {
        .menu-subcategories .dropdown-item {
            .nav-link:not(.mega-left-title),
            .dropdown-link:not(.mega-left-title) {
                font-size: 16px;
                color: $megamenu-secondary-color;
                padding-top: 0;
            }
        }
    
        .second-level-menu-group .container {
            padding: 0;
            display: flex;
            flex-direction: column;
            height: 100%;
        }

        .mobile-nav-content-wrapper {
            padding: 0 24px;
        }
    }

    .dropdown-item:not(.top-category .dropdown-link) {
        @include media-breakpoint-down(md) {
            font-family: $secondary-font;
            font-weight: 400;
            white-space: normal; // override from Bootstrap to prevent mobile menu issues with long translations
            .dropdown-toggle {
                white-space: normal; // override from Bootstrap to prevent mobile menu issues with long translations
            }
            &.fourth-level-menu {
                margin-right: $spacer;
                margin-left: $spacer;
                width: auto;

                .mega-left-title {
                    padding-left: 0;
                }
            }
        }
    }

    .close-menu {
        @include media-breakpoint-down(md) {
            background-color: transparent;
            border-bottom: 1px solid #d6d6d6;

            button {
                font-size: 16px;
                font-weight: 500;
                color: $black;
            }

            .icon-arrow-left-thin {
                font-size: 7.63px;
                margin-top: 0;
                line-height: 22px;
            }
        }
    }

    .mobile-menu-additional-links {

        .nav-link {
            @include secondary-navigation-text;
            color: $grey-30;
            padding: 4px 16px;
        }
    }
}

.navbar-nav .megamenu .mega-left {
    .second-level-menu {
        @include media-breakpoint-down(md) {
            display: flex;
            flex-direction: column;
        }
    }

    .third-level-menu {
        .third-level-menu-asset-group {
            @include media-breakpoint-up(lg) {
                padding-left: 0;
            }
        }
        @include media-breakpoint-down(md) {
            .dropdown-link:not(.mega-left-title) {
                font-family: $secondary-font;
                font-weight: 400;
                font-size: 15px;
                line-height: 33px;
                padding: 5px 0;
            }
    
            .third-level-menu-asset {
                &.sub-asset-1,
                &.sub-asset-2 {
                    padding-top: 30px;
                    padding-right: $spacer;
                    padding-bottom: 30px;
                    padding-left: $spacer;
                    margin-right: $spacer;
                    margin-left: $spacer;
    
                    .page-designer {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}
