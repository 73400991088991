.asg-hero {
    position: relative;
    overflow: hidden;
    display: flex;

    &-container {
        width: 100%;

        > a,
        > picture {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            display: flex;
            height: auto;
        }
    }

    &-image {
        width: 100%;
    }

    &-badge {
        @include header(5);
        border: 1px solid;
        font-size: 14px;
        margin-bottom: $spacer;
        padding: ($spacer / 2) $spacer;
        @include media-breakpoint-up(md) {
            font-size: 16px;
            margin-bottom: $spacer * 2;
            padding: $spacer ($spacer * 2);
        }
        width: fit-content;

        &.highlight:not(:is(.sale, .flashsale)) {
            background-color: $brand-03;
        }

        &.sale,
        &.flashsale {
            background-color: $red;
            border-color: $black;
            color: $white;
            @include media-breakpoint-up(md) {
                border-color: $white;
            }
        }
    }

    &-text {
        padding: 24px;

        @include media-breakpoint-down(sm) {
            .mobile-1r-1c & {
                padding: 24px 0;
            }
        }

        @include media-breakpoint-up(md) {
            padding: 48px;
            position: absolute;
            bottom: 0;
        }
    }

    &-buttons {
        display: inline-flex;
        gap: 10px;

        .video-container {
            position: absolute;
        }

        .video-player {
            z-index: -1;
        }
    }

    &-title {
        > * {
            @include header(3);
        }
        &.title-only {
            margin-bottom: 24px;
        }
    }

    &-subtitle {
        > * {
            @include paragraph-font-size('large');
            margin: ($spacer * 2) 0 ($spacer * 2);
        }
        @include media-breakpoint-up(md) {
            max-width: 500px;
        }
    }

    .dark-theme {
        .btn-primary {
            &:hover {
                border-color: $black;
            }
        }

        .btn-secondary {
            &:hover {
                color: $black;
            }
        }

        .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
            background-color: $black;
            border-color: $grey-20;
            color: $white;
            @include media-breakpoint-up(md) {
                border: none;
            }
        }

        @include media-breakpoint-up(md) {
            .asg-hero-title,
            .asg-hero-subtitle {
                color: $white
            }

            .btn-primary {
                border-color: $white;

                &:hover {
                    color: $black;
                    border-color: $white;
                    background-color: $white;
                }
            }

            .btn-secondary {
                color: $white;
                background-color: transparent;

                &:hover {
                    border-color: $white;
                    background-color: $white;
                }
            }
        }
    }

    .light-theme {
        .btn-primary {
            &:hover {
                border-color: $black;
            }
        }

        .asg-hero-badge.default:not(:is(.sale, .flashsale)) {
            background-color: $white;
            border-color: $grey-30;
            color: $black;
            @include media-breakpoint-up(md) {
                border: none;
            }
        }

        @include media-breakpoint-up(md) {
            .btn-primary {
                border-color: $black;

                &:hover {
                    color: $black;
                    border-color: $white;
                    background-color: $white;
                }
            }

            .btn-secondary {
                color: $black;
                background-color: transparent;

                &:hover {
                    color: $black;
                    border-color: $white;
                    background-color: $white;
                }
            }
        }
    }
}
