@import "~core/components/slider";

.slider-container {
    padding-left: 0;
    padding-right: 0;

    .slider-bullets-inside {
        .tns-nav {
            bottom: 15px;
        }
    }

    // slider nav buttons container
    .tns-controls {
        z-index: $z-index-lv1;
    }

    // slider nav buttons
    .tns-controls button {
        position: absolute;
        top: initial;
        bottom: 35px;
        transform: translateY(50%);
        border: $slider-button-border;
        border-radius: $slider-button-border-radius;
        width: $slider-button-width;
        height: $slider-button-height;
        &:hover {
            border: $slider-button-border-hover;
        }
    }
    [data-controls='prev'] {
        @include icon($slider-button-prev-icon, before, $slider-button-icon-size, $slider-button-color);
        left: auto;
        right: 75px;
    }
    [data-controls='next'] {
        @include icon($slider-button-next-icon, before, $slider-button-icon-size, $slider-button-color);
        right: 10px;
    }

    // themes
    &.light {
        .tns-controls button {
            border: $slider-light-button-border;
            &:hover {
                border: $slider-light-button-border-hover;
            }
        }
    }
}


.custom-three-block-slider .tns-inner {
    @include media-breakpoint-up(md) {
        margin: 0 auto !important;
        max-width: 850px;
        padding-bottom: 96px;
    }

    .tns-item:not(.tns-slide-active) {
        @include media-breakpoint-down(md) {
            opacity: .5;

            .image-text-block-text {
                visibility: hidden;
            }
        }
    }

    .tns-slide-active {
        @include media-breakpoint-down(md) {
            opacity: 1;

            .image-text-block-text {
                visibility: visible;
            }
        }
    }
    

    div:nth-of-type(3).tns-slide-active {
        @include media-breakpoint-up(md) {
            transform: translateY(-10%);
            transition: 1s ease-in-out;
        }
    }

    .btn {
        @include media-breakpoint-up(md) {
            transition: none;
        }
    }
}

.slider-transition-opacity {
    .tns-item:not(.tns-slide-active) {
        opacity: .5;

        .image-text-block-text {
            visibility: hidden;
        }
    }

    .tns-slide-active {
        opacity: 1;
        transition: opacity 1s ease-in-out;

        .image-text-block-text {
            visibility: visible;
        }
    }

    .btn {
        transition: none;
    }
}

// Slider title will be bottom left at all screen sizes
.slider-title-bottom-left {
    position: absolute;
    bottom: 0;
    left: $spacer;
    z-index: 1;
    margin-bottom: 0;
}

.slider-container {
    &:not(.slider-buttons-inside) {
        &:not(.slider-buttons-hidden) {
            .tns-controls button {
                bottom: -$bullets-outside-size;
            }
        }
    }
    &:not(.slider-buttons-inside-md) {
        &:not(.slider-buttons-hidden-md) {
            @include media-breakpoint-only(md) {
                .tns-controls button {
                    bottom: -$bullets-outside-size;
                }
            }
        }
    }
    
    &:not(.slider-buttons-inside-lg) {
        &:not(.slider-buttons-hidden-lg) {
            @include media-breakpoint-up(lg) {
                .tns-controls button {
                    bottom: -$bullets-outside-size;
                }
            }
        }
    }
    @include media-breakpoint-down(sm) {
        &.slider-buttons-inside-no-border {
            .tns-controls {
                display: flex;
                justify-content: space-between;
                position: absolute;
                top: -2.8em;
                width: inherit;
                button {
                    position: unset;
                    border: 0px;
                    top: unset;
                    bottom: unset;
                    &:hover {
                        background-color: unset;
                        border: none;
                        &::before {
                            color: $grey-20;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-only(md) {
        &.slider-buttons-inside-no-border-md {
            .tns-controls {
                display: flex;
                justify-content: space-around;
                position: absolute;
                top: -2.8em;
                width: inherit;
                button {
                    position: unset;
                    border: 0px;
                    top: unset;
                    bottom: unset;
                    &:hover {
                        background-color: unset;
                        border: none;
                        &::before {
                            color: $grey-20;
                        }
                    }
                }
            }
        }
    }
    @include media-breakpoint-up(lg) {
        &.slider-buttons-inside-no-border-lg {
            .tns-controls {
                display: flex;
                justify-content: space-around;
                position: absolute;
                top: -2.8em;
                width: inherit;
                button {
                    position: unset;
                    border: 0px;
                    top: unset;
                    bottom: unset;
                    &:hover {
                        background-color: unset;
                        border: none;
                        &::before {
                            color: $grey-20;
                        }
                    }
                }
            }
        }
    }
}

.header-banner {
    .slider-container {
        width: 100%;
        max-height: 17px;
        
        .tns-outer {
            width: 100%;
        }
    }
}

// Styles for the "Product Slider" custom Fox Component
.slider-container.product-slider{
    padding-top: 74px;

    @include media-breakpoint-down(md) {
        padding-bottom: 40px;
    }

    .tns-outer .tns-controls {
        position: absolute;
        top: 0;
        right: 0;
        padding-top: 20px;
        button {
            bottom: 0;
            background: $white;
            border-color: $black;
            &::before {
                color:$black;
            }
            &:hover {
                background: $black;
                &::before {
                    color:$white;
                }
            }
        }
        [data-controls='next'] {
            right: 0;
        }
        [data-controls='prev'] {
            right: 48px;
        }

        @include media-breakpoint-down(sm){
            display: none;
        }
    }

    .tns-ovh {
        overflow: visible;
    }

    .tns-nav {
        display: none;
    }
}

.product-slider-headline {
    position: absolute;
    top: 10px;
    display: inline-block;
}

.experience-commerce_layouts-productSlider {
    position: relative;
}

.product-slider-button-1 {
    &:not(.hidden-md-up){
        position: absolute;
        top: 0;
        right: 100px;
        margin-top: 0 !important;
        z-index: 1;
        .btn {
            line-height: 14px; 
        }
    }
    @include media-breakpoint-down(sm) {
        margin-top: -72px !important;
        .btn {
            width: 100%;
        }
    }
    @include media-breakpoint-up(md) {
        &.hidden-md-up{
            display: none;
        }
    }
}

.enhanced-slider-container {
    display: flex;
    flex-wrap: wrap;
    max-width: map-get($container-max-widths, xl);
    margin: 0 auto;
    padding: ($spacer * 4) 0;
    position: relative;
    @include media-breakpoint-between(md, lg){
        padding: ($spacer * 2) 0 0 0;
    }
    .enhanced-slider-title {
        align-items: center;
        display: flex;
        order: 1;
        padding: 0 ($spacer * 2);
        flex-grow: 1;
        flex-basis: min-content;
        @include media-breakpoint-up(xl) {
            padding: 0 0 0 ($spacer * 4);
        }
        & > * {
            margin: 0;
            padding: 0;
            line-height: 1.2;
        }
    }
    .enhanced-slider-button {
        order: 3;
        padding-left: ($spacer * 2);
        margin-right: auto;
        @include media-breakpoint-up(md) {
            margin-left: auto;
            margin-right: unset;
            order: 2;
            padding: 0 $spacer;
        }
        a {
            line-height: 1.12;
        }
        .btn-secondary {
            color: #0C0A08;
        }
    }
    .enhanced-slider-navigation {
        align-items: center;
        display: flex;
        order: 4;
        padding: 0 ($spacer * 2);
        font-size: 0;
        @include media-breakpoint-up(md) {
            order: 3;
            padding: 0 ($spacer * 2) 0 0;
            &:has(> .d-md-none) {
                margin-left: -$spacer;
            }
        }
        @include media-breakpoint-up(lg) {
            &:has(> .d-lg-none) {
                margin-left: -$spacer;
            }
        }
        @include media-breakpoint-up(xl) {
            padding: 0 ($spacer * 4) 0 0;
        }
    }
    .enhanced-slider-slides-container {
        order: 2;
        flex-basis: 100%;
        padding: ($spacer * 2) 0;
        @include media-breakpoint-up(md) {
            order: 4;
        }
        @include media-breakpoint-up(xl) {
            padding: ($spacer * 4) 0 0;
        }
        // 2 Slides - Desktop
        &.large-desktop-slides .experience-slides > * {
            @include media-breakpoint-up(lg) {
                flex: 0 0 calc((100% - #{$spacer}) / 2);
            }
        }
        // 2 Slides - Tablet
        &.large-tablet-slides .experience-slides > * {
            @include media-breakpoint-only(md) {
                flex: 0 0 calc((100% - #{$spacer}) / 2);
            }
        }
    }
    .btn-slider-left {
        margin-right: $spacer;
    }
    .experience-slides {
        display: flex;
        gap: $spacer;
        overflow-x: auto;
        padding: 0 ($spacer * 2) ($spacer * 2);
        scroll-padding: ($spacer * 2);
        scroll-snap-type: x mandatory;
        max-width: 100vw;
        @include media-breakpoint-up(xl) {
            padding: 0 ($spacer * 4) ($spacer * 2);
            scroll-padding: ($spacer * 4);
        }
    }
    .experience-slides > * {
        display: flex;
        flex: 1 0 100%;
        scroll-snap-align: start;
        scroll-snap-stop: always;
        // 2 Slides - Small
        @include media-breakpoint-only(sm) {
            flex: 0 0 calc((100% - #{$spacer}) / 2);
        }
        // 3 Slides - Tablet
        @include media-breakpoint-only(md) {
            flex: 0 0 calc((100% - (#{$spacer} * 2)) / 3);
        }
        // 4 Slides - Desktop
        @include media-breakpoint-up(lg) {
            flex: 0 0 calc((100% - (#{$spacer} * 3)) / 4);
        }
        justify-content: center;
        & > * {
            width: 100%;
        }
        .product-tile {
            padding-bottom: 0;
        }
    }
    .slider-moving {
        a, img {
            pointer-events: none;
        }
        user-select: none;
        cursor: grab;
    }
    .no-scroll-snap {
        scroll-snap-type: none;
    }
    .product-tile .pdp-link {
        flex-grow: 0;
    }
}