@import "variables";
@import "mixins";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins/breakpoints";
.gc-order-container {
    .card-body-header {
        margin-top: $spacer-xl;
        margin-bottom: $spacer-xl;
    }
    
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
    
    input[type=number]{
        -moz-appearance: textfield;
    }
    
    .gc-current-balance {
        margin-top: $spacer;
    }
    
    .gift-card-image {
        width: 100%;
        padding-bottom: $spacer *2;
    }
    
    .image-slider-container {
        width: 100%;
        overflow-x: auto;
        padding-top: $spacer *2;
    }
    
    .image-slider-container::-webkit-scrollbar {
        display: none;
    }

    .image-slider {
        display: flex;
        gap: $spacer / 2;
    }
        
    .image-slider img {
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
     }
       
    .gc-image-slide {
        background: $grey-10;
        flex: 0 0 calc(20% - 6px);
        padding: 16px 9px 16px 9px;
        @include media-breakpoint-up(md) {
            padding: 28px 16px 28px 16px;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid $grey-20;
        border-radius: 10px;
        box-sizing: border-box; 
    }
    .gc-image-slide.selected {
        border-color: $black;
    }
    .select-card {
        font-family: $secondary-font;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
    }
    .gc-title {
        padding-top: $spacer-xl;
        padding-bottom: $spacer-xl;
    }
    .giftcard-upper {
        border-top: 1px solid $grey-20;
        border-bottom: 1px solid $grey-20;
        margin: $spacer *2 0;
        padding: $spacer *2 0;
        ul {
            margin-bottom: 0px;
        }
    }
    .gc-button-container {
        margin-bottom: $spacer-xl;

        .general-error {
            margin-top: $spacer;
            color: $red;
        }
    }
}
