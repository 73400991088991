.badge-product-container {
    z-index: 1;
    margin: $product-tile-badges-margin;
    display: flex;
    gap: $spacer / 2;
    align-items: flex-start;
    pointer-events: none;
    top: 6px;
    left: 6px;
    min-width: 91px;
    &.badge-image-over {
        position: absolute;
        flex-direction: column;

        .product-detail & {
            position: static;
            flex-direction: row;
        }
    }
}

.badge-product {
    @include paragraph-font-size(small);
    background: $white;
    border: 1px solid;
    color: $black;
    margin: 0;
    padding: ($spacer / 4) $spacer;
    text-align: center;
    width: 100%;

    .product-detail & {
        color: $white;
        font-family: $secondary-font;
        font-weight: 400;
        text-align: center;
        width: unset;
        font-size: $default-text-font-size;
        margin: 0;
        line-height: $default-text-font-size;
        border: 1px solid $black;
        padding: 4px 16px;
        border-radius: 3px;
        background: $black;

        &.badge_limitedEdition,
        &.badge_backInStock,
        &.badge_sale,
        &.badge_new,
        &.badge_specialEdition,
        &.badge_bestSeller {
            color: $white;
        }

        &.badge_light {
            color: $black;
            background-color: $white;
        }
    }
}

.badge-product.badge_limitedEdition {
    color: #818C82;
    border-color: #818C82;
}

.badge-product.badge_backInStock {
    color: #25497C;
    border-color: #25497C;
}
.badge-product.badge_sale {
    color: #BF242B;
    border-color: #BF242B;
}

.badge-product.badge_new {
    color: $black;
    border-color: $black;
}

.badge-product.badge_specialEdition {
    color: #B1873D;
    border-color: #B1873D;
}

.badge-product.badge_bestSeller {
    color: $black;
    border-color: $black;
}

.badge-product.badge_onlineExclusive {
    color: $brand-03;
    background-color: $black;
    border-color: $grey-10;
    /* Preserve existing PDP border color. Looks wrong, when != black.
    TODO: Remove this override when refactoring badges */
    .product-detail & {
        border-color: $black;
    }
}
