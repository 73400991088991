@import "~core/components/footer";

footer {
    position: relative;
    background-color: $brand-01;
    @include media-breakpoint-up(md) {
        padding-top: $spacer * 5;
    }
    padding-bottom: 0;

    .title {
        color: $grey-20;
        font-family: $secondary-font;
        font-weight: 500;
        font-size: 14px;
        font-weight: 500;
    }

    .email-title {
        font-family: $primary-font;
        color: $white;
        font-size: 18px;
        font-weight: 600;

        @include media-breakpoint-up(md) {
            font-size: 14px;
        }
    }

    .title,
    .email-title {
        position: relative;
        line-height: 1.5em;
        text-transform: none;
    }

    .collapsible-sm .title {
        color: $grey-20;
        background-color: transparent;
    }

    ul {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 4;

        @include media-breakpoint-up(md) {
            padding-bottom: 0;
        }
    }

    .footer-email-signup {
        a,
        .text-link {
            &:hover {
                color: $grey-20;
            }
        }
    }

    .footer-about,
    .footer-contact {
        a, .text-link {
            @include footer-link-font;
        }
    }

    .footer-item.collapsible-sm {
        @include media-breakpoint-down(sm) {
            .title {
                @include icon($icon-arrow-right, after);
            }

            &.active .title {
                @include icon($icon-arrow-down, after);
            }

            .title,
            &.active .title {
                border-color: #4D4D4D;

                position: relative;

                &::after {
                    font-size: 16px;
                    -webkit-text-stroke-width: 1px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }

        .content {
            background-color: transparent;
        }
    }

    .social-links li:not(:last-child) {
        padding-bottom: 12px;
    }

    .instagram-circle,
    .facebook-circle,
    .twitter-circle,
    .youtube-circle {
        &::before {
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50px;
            margin-right: 19px;
        }
    }

    .instagram-circle::before {
        background: linear-gradient(45deg, #FFDD55 14.65%, #FF543E 50%, #C837AB 85.35%);
    }

    .facebook-circle::before {
        background-color: #4267B2;
    }

    .twitter-circle::before {
        background-color: #1DA0F2;
    }

    .youtube-circle::before {
        background-color: #E73127;
    }
    .footer-legal-section {
        order: 1;
        margin-top: auto;

        & a {
            font-size: 12px;
            font-family: $secondary-font;
        }

        @include media-breakpoint-up(md) {
            margin-left: auto;
            order: 3;
        }

        @include media-breakpoint-down(sm) {
            padding-top: $spacer * 4;
            padding-bottom: $spacer * 4;
        }

        .footer-legal-links-wrapper {
            overflow: hidden;
        }

        .footer-legal-links {
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;

            & > * {
                padding-left: 12px;
                position: relative;

                &::before {
                    content: '';
                    display: inline-block;
                    width: 1px;
                    height: 1em;
                    background-color: $white;
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 6px;
                }
            }
        }

        .footer-one-trust {
            color: $brand-03;
            text-decoration: underline;
        }
    }
    .footer-top-row {
        @include media-breakpoint-up(md) {
            padding-bottom: $spacer * 8;
        }
    }
    .footer-bottom-row {
        padding-bottom: $spacer * 2;
        flex-direction: column;
        @include media-breakpoint-up(md) {
            background-color: $black;
            flex-direction: row;
            padding: ($spacer * 4) 0 ($spacer * 6);
        }
    }
    .alert-danger, .alert-success {
        background: unset;
    }

    .alert-danger {
        color: $danger;
    }

    .alert-success {
        color: $success;
    }
}

.footer-logo-section {
    align-self: end;
    order: 3;
    @include media-breakpoint-up(md) {
        order: 1;
    }
    @include media-breakpoint-up(xl) {
        padding-left: $spacer-sm;
    }
    .footer-logo {
        width: 140px;
    }
    .copyright {
        padding-top: $spacer * 2;
    }
}

.footer-social-wrapper {
    padding-bottom: $spacer * 2;

    .footer-social-icons {
        gap: $spacer * 2;
    }

    .footer-social-icon a {
        font-size: 24px;
        text-decoration: none;
    }
}

.email-signup-form {
    margin-top: $spacer * 2;
    padding-bottom: $spacer * 2;

    .email-title {
        padding-bottom: $footer-signup-title-padding-bottom;
    }

    .email-input .input-group {
        border: 1px solid $grey-30;
        margin-top: $spacer * 2;

        &.error {
            border-color: $danger;
        }

        input {
            padding: $spacer $spacer $spacer ($spacer * 2);
            line-height: 1.7;
            display: inline-block;
            height: auto;
            font-size: 14px;
            font-weight: 600;
        }

        button {
            color: $black;
        }
    }

    .gdpr-consent-error {
        margin-top: $spacer * 2;
    }

    .gdpr-checkbox.form-group {
        margin-top: $spacer * 2;
        
        .gdpr-label {
            &,
            p {
                font-size: 12px;
                line-height: 1.4;
            }
        }

        &.custom-checkbox {
            .custom-control-input:focus~.custom-control-label:before {
                border-color: $grey-20;
            }

            .custom-control-input ~ .gdpr-label::before {
                border-color: $radiocheck-dark-border-color;
            }

            .custom-control-label {
                &:before,
                &:after {
                    top: 0;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        &.custom-checkbox .custom-control-label:before {
            background-color: $black;
            border-radius: 0;
        }

        &.error label {
            &:hover::before {
                box-shadow: 0 0 0 1px $danger;
            }

            &::before {
                border-color: $danger;
            }
        }
    }

    .email-description,
    .gdpr-label {
        @include paragraph-font-size(normal);
        color: $white;
        font-weight: 400;
    }

    .inline-btn {
        border-color: $white;
        &:hover {
            border-color: $white;
        }
        input,
        input::placeholder {
            font-family: $primary-font;
            font-family: 14px;
            color: $white;
        }

        .subscribe-email {
            height: fit-content;
            background-color: $white;
            padding: ($spacer / 2) 20px;
            margin: $spacer $spacer $spacer 0;
            &::after {
                display: none;
            }
        }
    }

    .email-signup-response {
        padding-top: ($spacer * 2);
    }
}

footer:not(.checkout-footer) {
    .footer-copyright-section {
        @include media-breakpoint-up(xl) {
            transform: rotate(270deg);
            position: absolute;
            left: 0;
            top: 40%;
        }
    }
}

.footer-copyright-section {
    order: 2;
    .copyright-notice {
        @include media-breakpoint-up(xl) {
            padding-top: 0;
        }
    }
}

.back-to-top {
    position: fixed;
    bottom: 2%;
    right: 10px;
    transition: background-color .3s, opacity .5s, visibility .5s;
    opacity: 0;
    visibility: hidden;

    &.show {
        opacity: 1;
        visibility: visible;
        z-index: 1;
    }


    margin: 5px;
    padding: 10px 0 0 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;

    .fa-stack {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }

    .fa-arrow-up {
        @include icon($icon-arrow-up, before, 20px, $black);
        width: 100%;
        height: 100%;
    }

    .fa-circle {
        background-color: $footer-backtotop-color;
        box-shadow: none;
        border: 1px solid $brand-02;
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

// Country Selector
.mobile-country-selector-container { // Footer - Mobile
    padding-bottom: $spacer * 4;
}
.desktop-country-selector-container { // Footer - Desktop
    padding-bottom: $spacer * 2;
    margin-left: auto; // Right Align
    min-width: $spacer * 24; // 288px
    width: fit-content;
}
.country-selector-box {
    align-items: center;
    border: 1px solid $grey-20;
    display: flex;
    padding: $spacer;
    width: 100%;
    .flag-icon {
        aspect-ratio: 4 / 3;
        margin-right: $spacer;
        min-width: $spacer * 2;
    }
    .header-country-selector & { // Navigation Modal
        margin-top: $spacer * 8;
        @include media-breakpoint-up(lg) {
            min-width: $spacer * 24; // 288px
        }
        .current-country,
        .icon-arrow-right {
            color: $black;
        }
    }
    .current-country {
        color: $white;
        margin-right: auto;
    }
    .icon-arrow-right {
        color: $white;
        font-size: $spacer * 2;
    }
}