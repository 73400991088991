.help-center {
    .warranty-policy {
        border-radius: 16px;
        padding: 25px 16px;
        background-color: $grey-10;

        @include media-breakpoint-up(lg) {
            padding: 32px 24px;
        }

        h3 {
            font-style: normal;
            margin-bottom: 32px;
        }
    }

    #attachments {
        border-radius: $input-border-radius;
        height: $input-height;
        line-height: $input-height;
        border: 1px solid $grey-20;
        cursor: pointer;

        > * {
            pointer-events: none;
        }
    }

    #upload-template {
        display: none;
    }

    .file-upload-outer {
        .file-upload-inner {
            border-radius: 4px;

            &:hover {
                background-color: $grey-10;
            }
        }

        .progress {
            height: 4px;
        }
    }
    // iOS Date Selector Fix
    @supports (-webkit-touch-callout: none) {
        // Fix Width of Date Input
        input[type=date] {
            padding: 0;
            @include media-breakpoint-down(md) {
                width: calc(100dvw - (#{$spacer} * 4));
            }
        }
        // Fix Text Placement of Date Input
        ::-webkit-date-and-time-value {
            padding-left: $spacer / 3;
            padding-top: $spacer * 2;
            text-align: left;
        }
    }

}
div#zendeskInitBtnContainer {
    z-index:1;
    right: 0; 
    left: unset;
}