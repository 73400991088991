@import "~core/components/pricing";

.product-detail,
.quick-view-dialog,
.cart-page,
.minicart,
.order-product-summary,
.product-tile,
.suggestions-wrapper {
    .price {
        font-weight: bold;

        del ~ .sales {
            color: $red;
        }
    }
}

.product-detail,
.quick-view-dialog,
.product-tile {
    .price {
        del ~ .sales {
            float: none;
            margin-right: 0;
            margin-left: $spacer;
        }
    }
}