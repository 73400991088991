.site-offline {

    &,
    body {
        background-color: $black;
        color: $secondary-02;
        height: 100%;
        min-height: 100%;
    }

    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .site-offline-container {
        width: 454px;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 18px;

        .site-offline-logo,
        .site-offline-header,
        .site-offline-message {
            margin-bottom: $spacer * 2;
        }

        .site-offline-logo {
            height: 85px;
            width: 85px;
            background: url('../../images/logo-scuff02.svg') no-repeat center;
            background-size: contain;
        }

        .site-offline-footer {
            font-size: 13px;
        }

        .site-offline-mailto {
            color: $brand-03;
            text-decoration: none;
        }
    }
}
