@import "~core/components/social";

.social-links {
    padding-top: 25px;

    li {
        margin: 0;
        a {
            line-height: 41px;
            &::before {
                padding-right: 19px;
            }
            &:hover {
                color: $white;
            }
            &::after {
                content: "";
                width: 10px;
                display: inline-block;
                height: 1px;
                background-color: $secondary-03;
                margin-left: 10px;
                vertical-align: middle;
            }

            @include media-breakpoint-up(lg) {
                font-size: 18px;
                line-height: 40px;
            }
        }
    }
}