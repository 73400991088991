.help-center {
    .bc {
        h4, .h4 {
            line-height: normal;
            font-style: italic;
        }

        a {
            text-decoration: none;
            margin-bottom: 0;

            &:hover {
                color: $white;
            }

            .icon-arrow-left-thin {
                top: 2px;
                position: relative;

                &:before {
                    color: $white;
                }
            }
        }
    }
}
