// place styleguide css here that should be affected by theme switching
.styleguide {
    .main {
        &__section {
            &>.row {
                background: $page-background-color;
                .brand-logo {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-image});
                }
                .brand-logo-stacked {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-stacked-image});
                }
                .brand-logo-horizontal {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-horizontal-image});
                }
                .brand-logo-vertical {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-vertical-image});
                }
                .brand-logo-small {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-small-image});
                }
                .brand-logo-type {
                    height: 200px;
                    max-width: 200px;
                    background-image: url(#{$header-logo-type-image});
                }
                .sg {
                    &__header1,
                    &__header2,
                    &__header3,
                    &__header4 {
                        color: rgba($base-font-color, 0.75);
                    }
                }
                .font {
                    &--primary {
                        &::after {
                            content: "#{$primary-font}"
                        }
                    }
                    &--secondary {
                        &::after {
                            content: "#{$secondary-font}"
                        }
                    }
                    &--tertiary {
                        &::after {
                            content: "#{$tertiary-font}"
                        }
                    }
                }
                .color-value {
                    @each $name, $hex in $colors {
                        &.background--#{"" + $name}::after {
                            content: "#{$hex}";
                        }
                    }
                }
                .grid-columns {
                    // dynamically add site-specific breakpoint-data
                    &__breakpoint-xs::after {
                        content: "0 - #{map-get($grid-breakpoints, md) - 1}";
                    }
                    &__breakpoint-md::after {
                        content: "#{map-get($grid-breakpoints, md)} - #{map-get($grid-breakpoints, lg) - 1}";
                    }
                    &__breakpoint-lg::after {
                        content: "#{map-get($grid-breakpoints, lg)} - #{map-get($grid-breakpoints, xl) - 1}";
                    }
                    &__breakpoint-xl::after {
                        content: "#{map-get($grid-breakpoints, xl)} +";
                    }
                    &__gutter-size-xs::after {
                        content: "#{map-get($grid-gutter-widths, xs) * 2}";
                    }
                    &__gutter-size-md::after {
                        content: "#{map-get($grid-gutter-widths, md) * 2}";
                    }
                    &__gutter-size-lg::after {
                        content: "#{map-get($grid-gutter-widths, lg) * 2}";
                    }
                    &__gutter-size-xl::after {
                        content: "#{map-get($grid-gutter-widths, xl) * 2}";
                    }
                    &__xs {
                        padding-right: map-get($grid-gutter-widths, xs) / 2;
                        padding-left: map-get($grid-gutter-widths, xs) / 2;
                        .row {
                            margin-right: -#{map-get($grid-gutter-widths, xs) / 2};
                            margin-left: -#{map-get($grid-gutter-widths, xs) / 2};
                        }
                        .col {
                            padding-right: map-get($grid-gutter-widths, xs) / 2;
                            padding-left: map-get($grid-gutter-widths, xs) / 2;
                        }
                    }
                    &__md {
                        padding-right: map-get($grid-gutter-widths, md) / 2;
                        padding-left: map-get($grid-gutter-widths, md) / 2;
                        .row {
                            margin-right: -#{map-get($grid-gutter-widths, md) / 2};
                            margin-left: -#{map-get($grid-gutter-widths, md) / 2};
                        }
                        .col {
                            padding-right: map-get($grid-gutter-widths, md) / 2;
                            padding-left: map-get($grid-gutter-widths, md) / 2;
                        }
                    }
                    &__xl {
                        padding-right: map-get($grid-gutter-widths, xl) / 2;
                        padding-left: map-get($grid-gutter-widths, xl) / 2;
                        .row {
                            margin-right: -#{map-get($grid-gutter-widths, xl) / 2};
                            margin-left: -#{map-get($grid-gutter-widths, xl) / 2};
                        }
                        .col {
                            padding-right: map-get($grid-gutter-widths, xl) / 2;
                            padding-left: map-get($grid-gutter-widths, xl) / 2;
                        }
                    }
                }
            }
        }

        .section {
            &--typography {
                .bp {
                    &--mobile::after {
                        content: "0 - #{map-get($grid-breakpoints, md) - 1}";
                    }
                    &--tablet::after {
                        content: "#{map-get($grid-breakpoints, md)} - #{map-get($grid-breakpoints, lg) - 1}";
                    }
                    &--mobile-tablet::after {
                        content: "0 - #{map-get($grid-breakpoints, lg) - 1}";
                    }
                    &--desktop::after {
                        content: "#{map-get($grid-breakpoints, lg)} +";
                    }
                }
                .h1 {
                    &--mobile .h1 {
                        font-size: $h1-font-size;
                        line-height: $h1-line-height;
                        font-weight: $h1-font-weight;
                        letter-spacing: $h1-letter-spacing;
                        text-transform: $h1-text-transform;
                        font-style: $h1-font-style;
                    }
                    &--desktop .h1 {
                        font-size: $h1-lg-font-size;
                        line-height: $h1-lg-line-height;
                        font-weight: $h1-lg-font-weight;
                        letter-spacing: $h1-lg-letter-spacing;
                        text-transform: $h1-lg-text-transform;
                        font-style: $h1-lg-font-style;
                    }
                }
                .h2 {
                    &--mobile .h2 {
                        font-size: $h2-font-size;
                        line-height: $h2-line-height;
                        font-weight: $h2-font-weight;
                        letter-spacing: $h2-letter-spacing;
                        text-transform: $h2-text-transform;
                        font-style: $h2-font-style;
                    }
                    &--desktop .h2 {
                        font-size: $h2-lg-font-size;
                        line-height: $h2-lg-line-height;
                        font-weight: $h2-lg-font-weight;
                        letter-spacing: $h2-lg-letter-spacing;
                        text-transform: $h2-lg-text-transform;
                        font-style: $h2-lg-font-style;
                    }
                }
                .h3 {
                    &--mobile .h3 {
                        font-size: $h3-font-size;
                        line-height: $h3-line-height;
                        font-weight: $h3-font-weight;
                        letter-spacing: $h3-letter-spacing;
                        text-transform: $h3-text-transform;
                        font-style: $h3-font-style;
                    }
                    &--desktop .h3 {
                        font-size: $h3-lg-font-size;
                        line-height: $h3-lg-line-height;
                        font-weight: $h3-lg-font-weight;
                        letter-spacing: $h3-lg-letter-spacing;
                        text-transform: $h3-lg-text-transform;
                        font-style: $h3-lg-font-style;
                    }
                }
                .h4 {
                    &--mobile .h4 {
                        font-size: $h4-font-size;
                        line-height: $h4-line-height;
                        font-weight: $h4-font-weight;
                        letter-spacing: $h4-letter-spacing;
                        text-transform: $h4-text-transform;
                        font-style: $h4-font-style;
                    }
                    &--desktop .h4 {
                        font-size: $h4-lg-font-size;
                        line-height: $h4-lg-line-height;
                        font-weight: $h4-lg-font-weight;
                        letter-spacing: $h4-lg-letter-spacing;
                        text-transform: $h4-lg-text-transform;
                        font-style: $h4-lg-font-style;
                    }
                }
                .h5 {
                    &--mobile .h5 {
                        font-size: $h5-font-size;
                        line-height: $h5-line-height;
                        font-weight: $h5-font-weight;
                        letter-spacing: $h5-letter-spacing;
                        text-transform: $h5-text-transform;
                        font-style: $h5-font-style;
                    }
                    &--desktop .h5 {
                        font-size: $h5-lg-font-size;
                        line-height: $h5-lg-line-height;
                        font-weight: $h5-lg-font-weight;
                        letter-spacing: $h5-lg-letter-spacing;
                        text-transform: $h5-lg-text-transform;
                        font-style: $h5-lg-font-style;
                    }
                }
                .h6 {
                    &--mobile .h6 {
                        font-size: $h6-font-size;
                        line-height: $h6-line-height;
                        font-weight: $h6-font-weight;
                        letter-spacing: $h6-letter-spacing;
                        text-transform: $h6-text-transform;
                        font-style: $h6-font-style;
                    }
                    &--desktop .h6 {
                        font-size: $h6-lg-font-size;
                        line-height: $h6-lg-line-height;
                        font-weight: $h6-lg-font-weight;
                        letter-spacing: $h6-lg-letter-spacing;
                        text-transform: $h6-lg-text-transform;
                        font-style: $h6-lg-font-style;
                    }
                }
                .p--mobile {
                    .p--small {
                        font-size: $paragraph-font-size-small;
                        line-height: $paragraph-line-height-small;
                    }
                    .p {
                        font-size: $paragraph-font-size;
                        line-height: $paragraph-line-height;
                    }
                    .p--large {
                        font-size: $paragraph-font-size-large;
                        line-height: $paragraph-line-height-large;
                    }
                }
                .p--desktop {
                    .p--small {
                        font-size: $paragraph-md-font-size-small;
                        line-height: $paragraph-md-line-height-small;
                    }
                    .p {
                        font-size: $paragraph-md-font-size;
                        line-height: $paragraph-md-line-height;
                    }
                    .p--large {
                        font-size: $paragraph-md-font-size-large;
                        line-height: $paragraph-md-line-height-large;
                    }
                }
            }
        }
    }
}
