@import "~core/components/card";

.card {
    border: none;
    border-radius: $card-border-radius;
    margin-bottom: $card-margin-bottom;
    font-size: $card-body-font-size;
    letter-spacing: $card-body-letter-spacing;
    line-height: $card-body-line-height;
    .card-header {
        background-color: $card-header-background;
        color: $card-header-color;
        border-top: $card-header-border-top;
        border-right: $card-header-border-right;
        border-bottom: $card-header-border-bottom;
        border-left: $card-header-border-left;
        border-bottom: none;
        padding: 0;
        flex-basis: auto; // IE11 fix
        // links, etc. in the top right corner of card header
        .pull-right {
            color: $card-header-color;
            font-size: $card-body-font-size;
            letter-spacing: $card-body-letter-spacing;
            line-height: $card-body-line-height;
            padding-top: $card-header-padding-top;
            padding-right: $card-header-padding-right;
        }
    }

    .card-body {
        background-color: $card-body-background;
        color: $card-body-color;
        padding: $card-no-header-body-padding-top;
        border-top: none;
        border-right: $card-body-border-right;
        border-bottom: $card-body-border-bottom;
        border-left: $card-body-border-left;
        padding-left: $card-padding-left;
        &:first-child {
            border-top: $card-body-border-first-child-top;
        }
        &:last-child {
            border-radius: 0 0 calc(#{$card-border-radius} - 1px) calc(#{$card-border-radius} - 1px);
        }
        .customer-information-block {
            text-align: left;
        }
    }
}
