@import "~core/checkout/checkout";

.additional-privacy-checkbox,
.additional-privacy-content {
    display: none;
}

.checkout-registration {
    .additional-privacy-checkbox {
        display: block;
    }
}

.payment-wrapper {
    .paymentMethod {
        margin-bottom: 0;
    }
}

.btn-show-details{
    padding-right: 24px;

}

#lb_klarna_account {
    font-weight: bold;
}

#lb_paywithgoogle {
    font-weight: bold;
}

#gift-card-block {
    background: $grey-10;
    padding: 0px 0px 0px 12px;
    border-top: 1px solid $grey-20;
    @media only screen and (max-width: 960px) {
        padding-right: $spacer;
    }
}

#add-gift-card-btn {
    width: auto;
    height: 40px;
    @media only screen and (max-width: 960px) {
        width: 100%;
        padding-right: $spacer;
    }
}

// Bonus Line Item - Order Confirmation - Styling
.data-checkout-stage,
.receipt,
.account-page {
    .order-product-summary .bonus-line-item-msg {
        padding-top: $spacer / 2;
        border-top: $product-card-item-border-width solid $product-card-border-color;
        & + .nested-line-item {
            border: unset;
        }
    }

    .bonus-product-line-item + .product-line-item {
        border-top: $product-card-item-border-width solid $product-card-border-color;
    }
}

.data-checkout-stage {
    .card .card-body .edit-button {
        @include text-link;
        color: $card-header-color;
        &:hover {
            color: $card-header-color;
        }
    }
    .payment-options-block {
        input[type=checkbox] {
            @include checkbox-icon-background;
            &:checked {
                &:before {
                    @include checkbox-icon-checked-background;
                    position: absolute;
                }
                &:after {
                    @include checkbox-icon-checked;
                    background-color: $black;
                    position: absolute;
                }
            }
        }
        .paymentMethod {
            input[type=radio] {
                @include radio-icon-background;
                // Apply Black Accent Color to non iOS devices
                @supports not (-webkit-touch-callout: none) {
                    accent-color: $black;
                }
                vertical-align: middle;

                &:checked {
                    &:before,
                    &:after {
                        content: "";
                        background: none;
                        border-radius: 50%;
                        position: absolute;
                    }
                    &:before {
                        width: $radiocheck-button-size - 8;
                        height: $radiocheck-button-size - 8;
                        background-color: $radiocheck-background-color-selected;
                        transform: translate(4px, 4px);
                    }
                    &:after {
                        width: $radiocheck-button-size;
                        height: $radiocheck-button-size;
                        border: 1px solid $black;
                    }
                }
            }
        }
    }

    .order-product-summary .product-line-item .price del ~ .sales {
        color: $red;
    }
    .order-product-summary .remove-product {
        display: none;
    }
    &[data-checkout-stage=placeOrder] {
        .additional-privacy-checkbox,
        .additional-privacy-content {
            display: block;
        }
    }

    &[data-checkout-stage=payment],
    &[data-checkout-stage=placeOrder] {
        .shipping-summary .title::after,
        .payment-summary .title::after {
            font-style: normal;
            font-size: 16px;
            right: 0;
        }
    }

    .reset-password-form {
        .request-password-body {
            margin-bottom: $spacer;
        }
    }
    
    klarna-placement {
        margin-top: 15px;
        display: block;
    }
}

/* Adyen form customization start **/
#adyenModalDialog {
    pointer-events: auto;
    background: white;
}

.paymentMethod {
    list-style-type: none;
  }
  
  .paymentMethod_img {
    margin: 0 0.25em 0 0.25em;
  }
  
  .additionalFields {
    display: inline-block;
    margin: 0.5em;
    width: 100%;
  }
  
  .adyen-checkout__input-wrapper .adyen-checkout__dropdown__list {
    z-index: 10;
  }
  
  #paymentMethodsUl {
    width: 80%;
    padding-inline-start: 0;
    margin: auto;
  }

// Override Adyen Form Checkbox Blue Color
.paymentMethod .adyen-checkout__checkbox__input:checked + .adyen-checkout__checkbox__label::after {
    background-color: $black;
    border: 1px solid $black;
}
/* Adyen form customization end **/

.paymentMethod {
    a {
        text-decoration: none;
    }

    img.logo:first-of-type {
        margin-left: 18px;
    }
}

.payment-form {
    .form-nav {
        display: none;
    }
    
    .tab-content > .tab-pane {
        display: block;
    }

    .tab-pane.tab-pane-form {
        display: none;
    }

    .tab-pane.active.tab-pane-form {
        display: block;
    }

    .applepay-radio {
        display: none;
    }

    #rb_scheme {
        margin-right: 18px;
    }

    #rb_scheme ~ img.paymentMethod_img {
        display: none;
    }

    #lb_scheme {
        display: none;
    }

    img.paymentMethod_img {
        margin-left: 18px;
        max-width: 50px;
    }
}

.checkout-progress-bar::before {
    content: '';
    width: 64%;
    border-bottom: 1px solid  $grey-20;
    position: absolute;
    top: 8px;
}

.checkout-progress-bar-step {
    @include paragraph-font-size(large);
    
    .checkout-progress-bar-indicator {
        width: 18px;
        height: 18px;
        background-color: $grey-20;
        border-radius: 50px;
        z-index: 1;
    }
    &.active {
        .checkout-progress-bar-indicator {
            background-color: $black;
        }
    }
}

.card.customer-section .card-body.checkout-customer-block {
    padding: 0;

    .customer-information-block {
        margin-bottom: $spacer;
    }
    
    .form-group.custom-checkbox {
        margin: 0 0 ($spacer * 4);
    }

    p,
    label {
        margin: 0;
    }

    .registered-form {
        .remember-me {
            margin-top: $spacer;
            label {
                @include paragraph-font-size(small);
                color: inherit;
            }
        }

        .forgot-password {
            margin-top: $spacer + 4;
            @include label-styles(false);
        }

        .submit-customer-login {
            margin-top: $spacer * 2; 
        }
    }
}

.receipt .order-product-summary .product-line-item .price del ~ .sales {
    color: $red;
}

.customer-signin-container {
    border-bottom: 1px solid #C5C5C5;

    strong {
        font-weight: 500;
    }
    
    .customer-signin-btn {
        color: #0C0A08;
        text-decoration: underline;
        cursor: pointer;
        vertical-align: baseline;
        border: none;
        padding: $spacer-xl 0 $spacer-xl 0;
    }
}

.card.customer-section .card-header h2.card-header-custom,
.card.customer-section .card-header h5.card-header-custom {
    padding: ($spacer-xl * 2) 0 $spacer-xl 0;
}

.checkout-customer-container {
    @include media-breakpoint-up(md) {
        border-bottom: none;
        padding-bottom: 0;
    }
    border-bottom: 1px solid #C5C5C5;
    padding-bottom: 16px;
}

.custom-checkbox-container {
    a, .text-link {
        font-weight: bold;
    }
}

//GDI-149 temporary checkout css fix - remove later 

footer {
    margin-top: 0;
}

#checkout-main.container {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 0 !important;
    max-width: 1440px;
}

.checkout-progress-bar {
    margin: -32px -24px;
    width: 100vw;
    padding: 24px 0;
    background: #f7f7f7;
    margin-bottom: 24px;
    border: 1px solid #dfdfdf;
    border-top: 0;
}

.checkout-progress-bar::before {
    content: '';
    width: 67%;
    top: 32px;
}

.card .card-header~.card-body {
    padding: 0 0 20px 0;
}

.customer-information-block .sign-in {
    &,
    & button {
        font-size: 14px;
        font-weight: 500;
    }
}

.customer-information-block .form-group.required {
    margin-bottom: 0;
}

h1.checkout-page-header {
    margin-bottom: 24px;
}

.card h2.card-header-custom,
.card h5.card-header-custom {
    padding: 24px 0;
}


.custom-radio .custom-control-label, .custom-checkbox .custom-control-label {    
    vertical-align: top;
}
.info-icon, .tooltip-icon {
    top: 0px;
}
.card .card-header .pull-right {
    padding-right: 0;
}

/* Shipping Block */
.data-checkout-stage[data-checkout-stage=customer] .shipping-address-block:not(.d-none)+.shipping-method-block {
    margin-top: 24px;
}
.data-checkout-stage[data-checkout-stage=shipping] .shipping-address-block:not(.d-none)+.shipping-method-block {
    border-top: 1px solid #dedede;
    margin-top: 24px;
    padding-top: 24px;
}

.shipping-method-block > h5 {
    margin-bottom: $spacer;
}

.shipping-address-block {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 24px;
}

.gc-button-wrapper {
    padding-bottom: $spacer;
}

/* Order Info */
#checkout-main > .row > .col-md-5{
    margin-left: -18px;
    margin-right: -18px;
    width: 100vw;
    padding: 0 24px 24px;
    border: 1px solid #dfdfdf;
}

.shipping-content {
    border-bottom: 0 !important;
}

#checkout-main > .row > .col-md-5,
#checkout-main > .row > .col-md-5 .card .card-body,
#checkout-main > .row > .col-md-5 .card .card-header,
#checkout-main > .row > .col-md-5 .leading-lines .start-lines span,
#checkout-main > .row > .col-md-5 .leading-lines .end-lines span{
    background: #f7f7f7;
}

#checkout-main > .row > .col-md-5 .card-header-custom{
    padding-top: 24px;
}
#checkout-customer-service .container {
    padding: 0;
}
#checkout-customer-service .container .experience-component > div {
    padding: 24px 0 0 0 !important;
    
}

/* Payment Method */
#paymentMethodsList {
    padding-left: 0;
    margin: 0;

    .additionalFields {
        margin: 12px 0;
        .adyen-checkout__card-input {
            background: $grey-10;
            padding: $spacer;
            margin: 0 -24px -36px;
            border-top: 1px solid $grey-10;
        }
    }

    label {
        font-size: 14px;
        margin-bottom: 0;
    }

    .paymentMethod {
        padding: $spacer * 2;
        border-bottom: 1px solid $grey-20;
    }
}

.payment-instrument-wrapper {
    border: 1px solid $grey-20;
    border-radius: 10px;
    order:2;
    margin-top: 10px;

    & > ul {
        margin: 0;
    }

    .paymentMethod {
        &.applepay,
        &.paypal {
            padding: 24px;
        }
    }
}

.payment-method-container {
    display: flex;
    flex-direction: column;
}

.gift-card-billing {
    border: 1px solid $grey-20;
    border-radius: 10px;
    overflow: hidden;

    .data-checkout-stage & .card-header {
        padding: 15px 12px;
        display: flex;
        position: relative;

        &::after {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &.collapsible-xl .title:not(.active):not(.aside):not(.no-borders) {
        border-bottom: none;
    }

    .gc-fox-logo {
        background: black;
        width: 52px;
        height: 28px;
        border-radius: 3px;
        display: flex;
        flex-grow: 0;
        align-items: center;

        .foxhead {
            height: 20px;
        }
    }

    .gc-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        padding-left: 12px;
    }

    &.payment-form.active .card-body {
        border-bottom: none;
    }
}

.gift-cert-input {
    width: 50%;
    height: 40px;

    @media only screen and (max-width: 960px) {
        width: 100%;
        padding-right: $spacer;
    }
}

.cc-header {
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
}

.cc-types {
    display: inline-block;
    vertical-align: top;
    margin-left: $spacer;
}

.adyen-option {
    max-width: 30px;
    height: auto;
}

.credit-card-selection-new ul{
    padding-left: 0;
}

fieldset.address-selector-block .btn {
    text-align: left !important;
}

.card .card-header .pull-right.edit-button {
    padding-top: 24px;
    padding-right: 0;
}

@media screen and (min-width: 769px){
    #checkout-main.container {
        padding: 24px;
    }
    #checkout-main > .row {
        margin-right: 0;
    }
    .checkout-progress-bar {
        margin: -32px -24px 48px;
        width: 100vw;
        max-width: 1920px;
    }
    
    #checkout-main > .row > .col-md-7{
        padding-right: 48px;  
    }
    
    #checkout-main > .row > .col-md-5 {
        margin-left: 0;
        align-self: flex-start;
        margin-right: 0;
        padding: 0 24px 24px;
    }
    .next-step-button {
        margin-bottom: 24px;
    }

}

@media screen and (min-width: 1024px){
    #checkout-main.container {
        padding: 48px;
    }
    .checkout-progress-bar {
        margin: -32px 0 48px;
        width: 100vw;
        max-width: 100%;
    }
}
.pfas-error-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: $spacer*2;
    background: $warning;
    margin-bottom: $spacer*2;
    padding: ($spacer * 2) 0;
    @include media-breakpoint-up(md) {
        padding: ($spacer * 4) 0;
    }


    .pfas-message-container {
        max-width: 1440px;
        @include media-breakpoint-up(lg) {
            padding: 0 ($spacer * 4);
        }
    }

    .pfas-error-text {
        color: $black;
        margin-bottom: $spacer;
    }

    .pfas-error-buttons {
        display: flex;
        gap: $spacer;
        margin-top: $spacer*2;
        white-space: nowrap;
    }

    .btn-pfas-cart {
        height: 30px;
        padding: 6px 20px;
        line-height: 18px;
    }

    .btn-change-address {
        background-color: transparent;
        border-color: $black;
        height: 30px;
        padding: 6px 20px;
        line-height: 18px;
    }

    .pfas-error-underlined {
        text-decoration: underline;
    }

    .pfas-error-subtitle {
        font-family: $secondary-font;
        font-size: 16px;
        font-style: italic;
        font-weight: 400;
        line-height: normal;
    }
}

.pfas-product-error-container {
    padding: $spacer $spacer*2;
    background: $warning;

    .pfas-product-card-message {
        color: $black;
        font-family: $secondary-font;
        font-size: 14px; 
        font-weight: 600;
        line-height: 140%;
    }
}