@import "~core/components/productTiles";

.product-tile {
    position: relative;
    margin-bottom: 0;
    padding-bottom: 45px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .promotion {
        @include paragraph-font-size(normal);
        color: $red;
    }
    .tile-body {
        flex: 1;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            padding: 0 $spacer/2;
        }
    }

    .product-tile-image-container:after {
        display: none;
    }
    .tile-image {
        background-color: #F2F2F2;
    }
    .quickview:not(.quick-add-button) {
        background-color: $grey-10;
        border-width: 0;
        border-radius: 0;
        bottom: 0;
        padding: 6px 12px;

        &.btn {
            width: 100%;
            left: 0;
        }
    }
    .quick-add-button {
        border-radius: 8px;
        margin-bottom: $spacer;
        padding: ($spacer / 2) $spacer;
    }
    .color-swatches {
        font-size: inherit;

        .swatch-link {
            width: calc(20% - 3px);
            margin-right: 3px;
            display: inherit;

            &.empty {
                padding-top: calc(20% - 3px);
            }

            &:first-child .swatch-circle {
                margin-left: 0;
            }
        }

        .swatch-color-count {
            font-family: $secondary-font;
            font-size: 11px;
            color: $grey-40;
            text-decoration: none;
            margin-left: 0;
            width: calc(20% - 3px);
            background-color: $grey-10;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .swatch-circle {
            width: $plp-color-swatch-size-sm;
            height: $plp-color-swatch-size-sm;
            margin-right: 0;
            margin-bottom: 0;
            @include media-breakpoint-up(sm) {
                width: $plp-color-swatch-size-md;
                height: $plp-color-swatch-size-md;
            }
            @include media-breakpoint-up(md) {
                width: $plp-color-swatch-size;
                height: $plp-color-swatch-size;
            }
        }

        .swatch-thumbnail {
            background-color: $grey-10;
            padding: 5px;
            display: inline-block;

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
    }

    .pdp-link {
        flex: 1;

        .link {
            font-weight: $product-tile-title-font-weight;
            line-height: 20px;
        }
    }

    .price {
        font-family: $secondary-font;
        font-size: $product-tile-price-font-size;
        font-weight: $product-tile-title-font-weight;
        line-height: 20px;
        margin-bottom: $spacer;

        del ~ .sales {
            float: none;
            margin-right: 0;
            margin-left: $spacer;
        }
    }

    .product-rating {
        height: 15px;
    }

    .product-rating-stars {
        display: flex;

        .rating-icon {
            display: block;
            width: 15px;
            height: 15px;
            background-size: contain;
            background-repeat: no-repeat;
        }
        
        .rating-icon-star {
            background-image: url('../images/icons/icon-star.svg');
        }

        .rating-icon-star-half {
            background-image: url('../images/icons/icon-star-half.svg');
        }

        .rating-icon-star-empty {
            background-image: url('../images/icons/icon-star-empty.svg');
        }
    }

    .product-rating-count {
        font-size: 11px;
        line-height: 1;
    }

    .ratings {
        margin-bottom: 10px;
        .yotpo-display-wrapper {
            .yotpo-bottomline {
                float: none;
                justify-content: center !important;
                .yotpo-stars {
                    .yotpo-icon-star,
                    .yotpo-icon-half-star,
                    .yotpo-icon-empty-star {
                        background: none !important;
                        &::before {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }
    &.on-hover {
        @include media-breakpoint-up(lg) {
            .color-swatches {
                .swatches, .swatch-color-count {
                    opacity: 1;
                }
            }
            &:hover .color-swatches .swatches, &:hover .color-swatches .swatch-color-count {
                opacity: 1;
            }
        }
    }
}