dt {
    color: $gray-700;
    font-weight: normal;
}

a {
    color: inherit;
    &:hover {
        color: inherit;
    }
}

i {
    font-style: normal;
    display: inline-block;
}

button {
    background: transparent;
    border: none;
    padding: 0;
    letter-spacing: inherit;
}

ul {
    padding-left: $spacer;
}

figure {
    margin: 0;
}

.modal-footer > :not(:first-child) {
    margin-left: 0;
}

.modal-footer > :not(:last-child) {
    margin-right: 0;
}

.tooltip {
    font-family: inherit;
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;
    letter-spacing: inherit;
    color: inherit;
}

.text-muted {
    color: $grey-30 !important; // using !important to override bootstrap's use of !important
}

$grid-gutter-half-width: $spacer / 2;
.row:not(.no-gutters) {
  margin-right: -$grid-gutter-half-width;
  margin-left: -$grid-gutter-half-width;
}
.row:not(.no-gutters) > .col,
.row:not(.no-gutters) > [class*="col-"] {
  padding-right: $grid-gutter-half-width;
  padding-left: $grid-gutter-half-width;
}

// updates container padding based on $grid-gutter-widths map
@mixin update-container-padding($gutters: $grid-gutter-widths) {
  @each $breakpoint in map-keys($gutters) {
    @include media-breakpoint-up($breakpoint) {
      $gutter: map-get($gutters, $breakpoint);
      padding-right: ($gutter * 2);
      padding-left:  ($gutter * 2);
    }
  }
}
.container {
    @include update-container-padding;
}
.container-fluid {
    @include update-container-padding;
}

:focus, button:focus {
  outline: $outline-focus-width $outline-focus-style $outline-focus-color;
}

:focus:not(.focus-visible) {
  outline: none;
}
