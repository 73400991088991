.footer-links-title-desktop:visited,.footer-links-title-desktop:hover,.footer-links-title-desktop:active {
    text-decoration:none;
}

.footer-links-title-desktop {
    display: block;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}

.footer-links-title-mobile {
    display: none;
    font-family: $secondary-font;
    font-size: 18px;
    @include media-breakpoint-down(sm) {
        display: block;
    }
}

.footer-link-text {
    font-family: $secondary-font;
    font-size: 14px;
    line-height: 120%;
    letter-spacing: 0;
    font-style: normal;
    font-weight: 500;
    color: $black;
    text-decoration: none;
}

.footer-links-item.footer-item.collapsible-sm:first-of-type {
    @include media-breakpoint-down(sm) {
        border-top: 1px solid $secondary-03;
    }
}
.footer-links-item.footer-item.collapsible-sm {
    max-width: 24%;
    @include media-breakpoint-down(sm) {
        padding-top: 0px;
        padding-bottom: 0px;
        max-width: 100%;
        border-bottom: solid;
        border-bottom-color: $secondary-03;
        border-width: 1px;
        background: transparent;
    }
}

.footer-site-links {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ($spacer * 8) 0;
    @include media-breakpoint-down(sm) {
        padding: ($spacer * 4) 0;
    }
}

.footer-site-links-background {
    background-color: $secondary-01;
}

.footer-links-item.footer-item.collapsible-sm {
    .title {
        @include icon($icon-arrow-right, after);
        color: $grey-30;
        text-decoration: none;
        background-color: transparent;
        border-color: transparent;
    }

    &.active .title {
        @include icon($icon-arrow-down, after);
    }

    .title,
    &.active .title {
        position: relative;
        font-size: 14px;
        font-weight: 500;

        &::after {
            color: $black;
            font-size: 22px;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    .content {
        background-color: transparent;
    }
}

.menu-footer-links {
    font-family: $primary-font;
    font-size: 17px;
    line-height: 170%;
    list-style: none;
    padding-top: $spacer * 2;
    @include media-breakpoint-down(sm) {
        padding-top: 0px;
        padding-left: 0px;
        padding-bottom: 20px;
        padding: ($spacer * 2) 0 ($spacer * 4);
    }
    li a {
        text-decoration: none;
    }
    .collapsible-sm {
        padding-left: 0px;  
    }  
    a, footer .text-link {
        color: $black;
    }
}

.footer-link-li-mobile {
    display: none;
    @include media-breakpoint-down(sm) {
        display: block;
    }
}